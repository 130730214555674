import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, AggregatedPerformanceReview } from "dashboard/miter";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * PERFORMANCE REVIEWS
 */
type PerformanceReviewParams = AggregatedPerformanceReview | AggregatedPerformanceReview[] | undefined;

type PerformanceReviewAction = "create" | "read" | "update" | "delete";
type PerformanceReviewAbilities = {
  can: (action: PerformanceReviewAction, items: PerformanceReviewParams) => boolean;
  cannot: (action: PerformanceReviewAction, items: PerformanceReviewParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: PerformanceReviewAction) => (tm: AggregatedTeamMember) => boolean;
};

export const usePerformanceReviewAbilities = (): PerformanceReviewAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: PerformanceReviewAction, items?: PerformanceReviewParams) => {
      if (!items) return false;

      const reviews = Array.isArray(items) ? items : [items];

      return reviews.every((review) => {
        const isPersonal =
          typeof review.reviewee_id === "string"
            ? review.reviewee_id === activeTeamMember?._id
            : review.reviewee._id === activeTeamMember?._id;

        const teamMemberId = getTeamMemberId(review);

        if (isPersonal) {
          switch (action) {
            case "create":
              return can_("performance:reviews:personal:create");
            case "read":
              return can_("performance:reviews:personal:read");
            case "update":
              return can_("performance:reviews:personal:update");
            case "delete":
              return can_("performance:reviews:personal:delete");
            default:
              return false;
          }
        } else {
          switch (action) {
            case "create":
              return can_("performance:reviews:others:create", { teamMember: teamMemberId });
            case "read":
              return can_("performance:reviews:others:read", { teamMember: teamMemberId });
            case "update":
              return can_("performance:reviews:others:update", { teamMember: teamMemberId });
            case "delete":
              return can_("performance:reviews:others:delete", { teamMember: teamMemberId });
            default:
              return false;
          }
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: PerformanceReviewAction, items: PerformanceReviewParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "performance:reviews:personal",
    othersPermissionPath: "performance:reviews:others",
    teamMemberField: { fieldName: "reviewee._id", fieldType: "_id" },
    appModule: "human_resources",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<PerformanceReviewAction>("performance:reviews");

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};

const getTeamMemberId = (review: AggregatedPerformanceReview): string | undefined => {
  return typeof review.reviewee_id === "string" ? review.reviewee_id : review.reviewee._id;
};
