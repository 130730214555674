import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Formblock } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { Company } from "backend/models";
import { useActiveCompany, useActiveCompanyId, useSetActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui/settings/SettingsCard";
import { TimeOffRequestPolicies } from "./TimeOffRequestPolicies";

type TimeOffSettingUpdateParams = Company["settings"]["time_off"];

export const TimeOffSettings: React.FC = () => {
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const timeOffSetting = activeCompany?.settings?.time_off;
  const form = useForm<TimeOffSettingUpdateParams>({
    defaultValues: timeOffSetting,
    shouldUnregister: false,
  });
  const setActiveCompany = useSetActiveCompany();
  const { handleSubmit } = form;

  const backToTimeOff = () => {
    navigate("/time-off/requests");
  };

  /* Form */

  const updateSettings = async (data: TimeOffSettingUpdateParams) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { "settings.time_off": data },
      });
      if (response.error) throw new Error(response.error);
      setActiveCompany(response);
      Notifier.success("Settings updated successfully.");
    } catch (e) {
      Notifier.error("There was an error updating time off settings. We're looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Time off settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={backToTimeOff}>
          Back to time off
        </div>
        <div className="flex">
          <h1>Time off settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure how time off gets approved.</div>
        <div className="vertical-spacer-small"></div>
        <SettingsCard title={"Notifications"}>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 4 }}>
            <div style={{ marginTop: -6, marginRight: 10 }}>
              <Formblock
                type="checkbox"
                name="send_weekly_time_off_summary_to_supervisors"
                className="modal time-off-request-date"
                form={form}
                sideBySideInput={false}
                editing={true}
                onChange={updateSettingsDebounced}
              />
            </div>
            {`Send weekly digests of upcoming time off to supervisors.`}
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: -8 }}>
            <div style={{ marginTop: -8, marginRight: 10 }}>
              <Formblock
                type="checkbox"
                name="notify_admin_on_time_off_request"
                className="modal time-off-request-date"
                form={form}
                sideBySideInput={false}
                editing={true}
                onChange={updateSettingsDebounced}
              />
            </div>
            {`Notify admins when an unapproved time off request is created.`}
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: -8 }}>
            <div style={{ marginTop: -8, marginRight: 10 }}>
              <Formblock
                type="checkbox"
                name="notify_manager_on_time_off_request"
                className="modal time-off-request-date"
                form={form}
                sideBySideInput={false}
                editing={true}
                onChange={updateSettingsDebounced}
              />
            </div>
            {`Notify managers when their direct reports create unapproved time off requests.`}
          </div>

          <div style={{ display: "flex", flexDirection: "row", marginTop: -8 }}>
            <div style={{ marginTop: -8, marginRight: 10 }}>
              <Formblock
                type="checkbox"
                name="notify_team_members_of_time_off_request_status"
                className="modal time-off-request-date"
                form={form}
                sideBySideInput={false}
                editing={true}
                onChange={updateSettingsDebounced}
              />
            </div>
            {`Notify team members when their time off requests are approved or denied.`}
          </div>
        </SettingsCard>

        <TimeOffRequestPolicies />
      </div>
    </div>
  );
};
