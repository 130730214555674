import React, { FC, useCallback, useMemo } from "react";
import { Wizard } from "ui";
import { AggregatedI9 } from "dashboard/miter";
import { notNullish } from "miter-utils";
import { EmployerI9WizardScreen } from "./EmployerI9WizardScreen";
import { DemoWizardScreen } from "./DemoWizardScreen";
import { taskTypeLabels } from "./utils";
import { OnboardingTaskRow } from "dashboard/components/tables/OnboardingTasksTable";
import { CustomTaskWizardScreen } from "./CustomTaskWizardScreen";
import { TeamMemberCustomFieldsScreen } from "dashboard/components/team-members/TeamMemberCustomFieldsScreen";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  onboardingTasks: OnboardingTaskRow[];
  createTaskUpdater: (checklistId: string) => (task: Partial<OnboardingTaskRow>) => Promise<void>;
  getI9ForTask: (task: OnboardingTaskRow) => Promise<AggregatedI9 | undefined>;
};

export const OnboardingChecklistV2AdminWizard: FC<Props> = ({
  onExit,
  onComplete,
  onboardingTasks,
  createTaskUpdater,
  getI9ForTask,
}) => {
  const mapScreen = useCallback(
    (task: OnboardingTaskRow) => {
      switch (task.type) {
        case "i9_employer":
          return (
            <EmployerI9WizardScreen
              key={task._id}
              name={"I-9"}
              onboardingTask={task}
              teamMember={task.team_member}
              updateOnboardingChecklistTask={createTaskUpdater(task.team_member_onboarding_checklist_id)}
              i9={task?.i9 ?? undefined}
              getI9={() => getI9ForTask(task)}
            />
          );
        case "custom_task":
          return (
            <CustomTaskWizardScreen
              key={task._id}
              name={taskTypeLabels[task.type]}
              onboardingTask={task}
              refetchTeamMember={() => Promise.resolve()} // This doesn't matter for admin view, since we don't show the team member on the Needs Attention page
              updateOnboardingChecklistTask={createTaskUpdater(task.team_member_onboarding_checklist_id)}
            />
          );
        case "custom_field":
          return (
            <TeamMemberCustomFieldsScreen
              key={task._id}
              name={taskTypeLabels[task.type]}
              teamMember={task.team_member}
              setTeamMember={() => {}} // This doesn't matter for admin view, since we don't show the team member on the Needs Attention page
              refetchTeamMember={() => Promise.resolve()} // This doesn't matter for admin view, since we don't show the team member on the Needs Attention page
              onboardingTask={task}
              updateOnboardingChecklistTask={createTaskUpdater(task.team_member_onboarding_checklist_id)}
              isTeamPortal={false}
            />
          );
        default:
          return (
            <DemoWizardScreen
              key={task._id}
              name={taskTypeLabels[task.type]}
              onboardingTask={task}
              refetchTeamMember={() => Promise.resolve()}
              updateOnboardingChecklistTask={createTaskUpdater(task.team_member_onboarding_checklist_id)}
            />
          );
      }
    },
    [createTaskUpdater, getI9ForTask]
  );

  const screens = useMemo(() => {
    return onboardingTasks.map(mapScreen).filter(notNullish);
  }, [onboardingTasks, mapScreen]);

  // Find the index of the first incomplete task in the onboarding checklist
  const defaultIndex = useMemo(() => {
    let renderedTaskCount = 0;
    const firstIncompleteIndex = onboardingTasks.findIndex((task) => {
      if (mapScreen(task) === null) return false;
      if (task.status === "incomplete") return true;
      renderedTaskCount++;
      return false;
    });
    return firstIncompleteIndex !== -1 ? renderedTaskCount : 0;
  }, [onboardingTasks, mapScreen]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete} defaultCanGoNext={false} startIndex={defaultIndex}>
      {screens}
    </Wizard>
  );
};
