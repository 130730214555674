import React, { FC } from "react";
import { Notifier, WizardScreen } from "ui";
import styles from "./Performance.module.css";
import { ReviewScheduleDetails } from "./ReviewScheduleDetails";
import useWizard from "ui/modal/useWizard";
import { PerformanceReviewCycle } from "dashboard/miter";
import { finalizeReviewSettings, startReviewCycle } from "dashboard/utils/performance";

type Props = {
  name: string;
  performanceReviewSchedule: PerformanceReviewCycle;
};

export const ConfirmScheduleDetails: FC<Props> = ({ name, performanceReviewSchedule }) => {
  const { setCurIndex, setNextButtonText, handleComplete, setCanNext } = useWizard();

  setCanNext(true);
  setNextButtonText("Complete");

  const goToDetails = () => {
    setCurIndex(0);
  };

  const onComplete = async () => {
    const finalizeError = await finalizeReviewSettings(performanceReviewSchedule);
    if (finalizeError) {
      Notifier.error(finalizeError);
    }
    const startError = await startReviewCycle(performanceReviewSchedule);
    if (startError) {
      Notifier.error(startError);
    }
    handleComplete();
  };

  return (
    <WizardScreen onNext={onComplete} name={name}>
      <div className={styles["content"]}>
        <div style={{ marginBottom: 15 }}>Your updated schedule!</div>
        <ReviewScheduleDetails performanceReviewSchedule={performanceReviewSchedule} onEdit={goToDetails} />
      </div>
    </WizardScreen>
  );
};
