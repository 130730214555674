import { Pencil } from "phosphor-react";
import React, { useMemo, useState } from "react";

import { BulkSelectorModal, TableV2 } from "ui";
import { BulkSelectOptionValue, BulkSelectorOption } from "ui/bulk-actions/BulkSelector";

type Props = {
  /** The options to display in the bulk selector */
  options: BulkSelectorOption[];
  resource: string;
  onSubmit: (
    newSelections: BulkSelectOptionValue[],
    removedSelections: BulkSelectOptionValue[] // optionally also update removed selections
  ) => Promise<void>;
  selectedOptions: BulkSelectorOption[];
};

const BulkOptionInfo: React.FC<Props> = (props) => {
  const { options, selectedOptions, onSubmit, resource } = props;

  const [showBulkSelector, setShowBulkSelector] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const initalSelections = useMemo(() => {
    return selectedOptions.map((option) => option.value);
  }, [selectedOptions]);

  const handleSubmit = async (newSelections: BulkSelectOptionValue[]) => {
    const removedSelections: BulkSelectOptionValue[] = selectedOptions
      .filter((originallySelectedOption) => newSelections.includes(originallySelectedOption.value) === false)
      .map((selection) => selection.value);

    setSubmitting(true);
    await onSubmit(newSelections, removedSelections);
    setSubmitting(false);
    setShowBulkSelector(false);
  };

  return (
    <div>
      <TableV2
        id={`${resource}-table`}
        resource={`selectable ${resource}`}
        data={selectedOptions.map((option) => {
          return {
            _id: option.value,
            name: option.label,
          };
        })}
        columns={[
          {
            headerName: `Name`,
            field: "name",
            dataType: "string",
          },
        ]}
        staticActions={[
          {
            label: `Edit selectable ${resource}`,
            className: "button-2 no-margin table-button",
            action: () => setShowBulkSelector(true),
            important: true,
            icon: <Pencil weight="bold" style={{ marginRight: 5 }} />,
          },
        ]}
        paginationPageSize={5}
        hideFilters
        hideColumns
      />
      {showBulkSelector && (
        <BulkSelectorModal
          options={options}
          selections={initalSelections}
          resource={resource}
          onSubmit={handleSubmit}
          onCancel={() => setShowBulkSelector(false)}
          submitting={submitting}
        />
      )}
    </div>
  );
};

export default BulkOptionInfo;
