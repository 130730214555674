import { ClassesTable } from "dashboard/components/classes/ClassesTable";
import React from "react";
import { Helmet } from "react-helmet";

type Props = {};

export const Classes: React.FC<Props> = () => {
  return (
    <div className="section-wrapper">
      <Helmet>
        <title>Classes | Miter</title>
      </Helmet>
      <ClassesTable />
    </div>
  );
};
