import { AggregatedMiterEarning, AggregatedPayroll } from "dashboard/miter";
import { ExpenseReimbursementEntry } from "dashboard/pages/expenses/ExpenseReimbursementsTable";
import React from "react";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { GetPayrollQuery } from "backend/controllers/payrolls-controller";
import { PayrollAdjustment } from "../payrollTypes";
import { CheckPaymentWireDetails } from "backend/utils/check/check-types";

type PayrollContextType = {
  payroll?: AggregatedPayroll;
  aggEarnings?: AggregatedMiterEarning[];
  recalculatePayroll: (params?: {
    adjustments?: PayrollAdjustment[];
    tms?: string[] | "all";
  }) => Promise<void>;
  getPayroll: (options?: GetPayrollQuery) => Promise<void>;
  setPayroll: React.Dispatch<React.SetStateAction<AggregatedPayroll | undefined>>;
  isPreviewing: boolean;
  setIsPreviewing: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  stdTogglerItems: TogglerConfigItem[];
  getExpenseReimbursementEntries?: () => Promise<void>;
  expenseReimbursementEntries?: ExpenseReimbursementEntry[];
  expensesLoading: boolean;
  wireDetails: CheckPaymentWireDetails | null;
  setWireDetails: React.Dispatch<React.SetStateAction<CheckPaymentWireDetails | null>>;
  showWireDetails: boolean;
  setShowWireDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

const PayrollContext = React.createContext<PayrollContextType>({
  recalculatePayroll: async () => {},
  getPayroll: async () => {},
  isPreviewing: false,
  setIsPreviewing: () => {},
  setPayroll: () => {},
  wireDetails: null,
  setWireDetails: () => {},
  setShowWireDetails: () => {},
  isLoading: false,
  stdTogglerItems: [],
  expensesLoading: false,
  showWireDetails: false,
});

export default PayrollContext;
