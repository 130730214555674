import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Notifier from "dashboard/utils/notifier";
import { AggregatedTeamMember, MiterAPI, TimeOffPolicy, TimeOffPolicyLevelConfig } from "dashboard/miter";
import { capitalize, roundTo } from "dashboard/utils/utils";
import ReactTooltip from "react-tooltip";

import { Button, Loader } from "ui";

import styles from "./TeamMemberTimeOff.module.css";
import { TimeOffRequestsTable } from "../time-off/TimeOffRequestsTable";
import TimeOffUpdatesTable from "../time-off/TimeOffUpdatesTable";
import { useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { keyBy } from "lodash";
import { notNullish } from "miter-utils";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import LeaveOfAbsenceTable from "../time-off/LeaveOfAbsenceTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  teamMember: AggregatedTeamMember;
};

const TeamMemberTimeOff: React.FC<Props> = ({ teamMember }) => {
  /*********************************************************
   *  Setup states
   **********************************************************/
  const [balanceUpdating, setBalanceUpdating] = useState(false);
  const refetchTeam = useRefetchTeam();
  const [view, setView] = useState<"requests" | "updates" | "leave">("requests");

  const miterAbilities = useMiterAbilities();

  const timeOffPolicies = useMemo(() => {
    const policyLookup = keyBy(teamMember.time_off_policies, "_id");

    return teamMember.time_off.policies
      .map((policy) => {
        const fullPolicy = policyLookup[policy.policy_id];
        if (!fullPolicy) return;

        let cleanedBalance = policy.balance || 0;

        // Round balance to nearest hundredth
        cleanedBalance = roundTo(cleanedBalance);
        const levelConfig = fullPolicy.levels.find((level) => level._id === policy.level_id);

        return {
          ...fullPolicy,
          levelConfig: levelConfig,
          balance: cleanedBalance,
        };
      })
      .filter(notNullish);
  }, [teamMember]);

  /*********************************************************
   *  Handler functions
   **********************************************************/
  const handleBalanceUpdate = async () => {
    setBalanceUpdating((p) => !p);
    refetchTeam(teamMember._id);
  };

  /*********************************************************
   *  Rendering functions
   **********************************************************/

  const renderViewSelector = () => {
    return (
      <div className="buttons-toggler">
        <Button
          text={`Requests`}
          onClick={() => setView("requests")}
          className={`no-margin ` + (view === "requests" ? " button-2" : "button-1")}
        />
        <Button
          text={`Updates`}
          onClick={() => setView("updates")}
          className={`no-margin ` + (view === "updates" ? " button-2" : "button-1")}
        />
        {miterAbilities.can("time_off:leave:manage") &&
          teamMember.on_leave_history &&
          teamMember.on_leave_history.length > 0 && (
            <Button
              text={`Leaves of absence`}
              onClick={() => setView("leave")}
              className={`no-margin ` + (view === "leave" ? " button-2" : "button-1")}
            />
          )}
      </div>
    );
  };

  const renderTimeOffPolicies = () => {
    const timeOffPolicyElements = timeOffPolicies.map((policy) => {
      return (
        <TeamMemberTimeOffPolicy
          key={policy._id}
          id={policy._id}
          teamMember={teamMember}
          name={policy.name}
          type={policy.type}
          balance={policy.balance}
          levelConfig={policy.levelConfig}
          handleUpdate={handleBalanceUpdate}
          timeOffPolicies={timeOffPolicies}
        />
      );
    });

    return (
      <div className={styles["team-member-time-off-policies"]}>
        <h2 className={styles["team-member-time-off-policies-title"]}>Time off policies</h2>
        <p className={styles["team-member-time-off-policies-subtitle"]}>
          <span style={{ opacity: 0.6 }}>
            View time off policies for {teamMember.first_name}. If you would like to manage&nbsp;
            {teamMember.first_name}&apos;s time off policies, go to the&nbsp;
          </span>
          <Link style={{ color: "#4d54b6", opacity: 1 }} to={"/time-off/policies"}>
            time off policies module.
          </Link>
        </p>
        {timeOffPolicyElements.length > 0 && (
          <div className={styles["team-member-time-off-policies-list"]}>{timeOffPolicyElements}</div>
        )}
        {timeOffPolicyElements.length === 0 && (
          <p>{teamMember.full_name} is not enrolled in any time off policies.</p>
        )}
      </div>
    );
  };
  const renderTimeOffRequests = () => {
    return <TimeOffRequestsTable employee={teamMember} showToggler={false} />;
  };
  const renderTimeOffHistory = () => {
    return (
      <div className={styles["team-member-time-off-history"]}>
        <TimeOffUpdatesTable employee={teamMember} trigger={balanceUpdating} />
      </div>
    );
  };

  const renderLeaveOfAbsence = () => {
    return (
      <div className={styles["team-member-time-off-history"]}>
        <LeaveOfAbsenceTable employee={teamMember} />
      </div>
    );
  };

  return (
    <div className={styles["team-member-time-off"]}>
      {renderTimeOffPolicies()}
      <div className="vertical-spacer" />
      <div className="vertical-spacer" />
      {renderViewSelector()}
      {view === "requests" && renderTimeOffRequests()}
      {view === "updates" && renderTimeOffHistory()}
      {view === "leave" && renderLeaveOfAbsence()}
      <ReactTooltip effect={"solid"} className="tooltip" />
    </div>
  );
};

export default TeamMemberTimeOff;

/*********************************************************
 *  TeamMemberTimeOffPolicy
 *
 *  This component is responsible for rendering a single
 *  time off policy for a team member. I kept it in this
 *  file because it is a simple component that most
 *  likely will not be used anywhere else.
 **********************************************************/
type TeamMemberTimeOffPolicyProps = {
  teamMember: AggregatedTeamMember;
  id: string;
  name: string;
  type: string;
  balance: number;
  levelConfig?: TimeOffPolicyLevelConfig;
  handleUpdate: () => void;
  timeOffPolicies: TimeOffPolicy[];
};

const TeamMemberTimeOffPolicy = ({
  teamMember,
  id,
  name,
  type,
  levelConfig,
  handleUpdate,
  timeOffPolicies,
  ...props
}: TeamMemberTimeOffPolicyProps) => {
  const navigate = useNavigate();
  const teamAbilities = useTeamAbilities();

  /*********************************************************
   *  Setup states
   **********************************************************/
  const [loading, setLoading] = useState(false);
  const [curBalance, setCurBalance] = useState(props.balance);
  const [balance, setBalance] = useState(props.balance);
  const [editMode, setEditMode] = useState(false);

  /*********************************************************
   *  Function to update the balance of the time off policy
   **********************************************************/

  const updateBalance = async () => {
    const cleanedBalance = roundTo(Number(balance));
    const maxBalance = levelConfig?.accrual_config?.max_balance;
    if (isNaN(cleanedBalance)) {
      return Notifier.error("Please enter a valid number when updating a time off balance");
    } else if (maxBalance && cleanedBalance > maxBalance) {
      return Notifier.error("The maximum balance for this time off policy is " + maxBalance + " hours");
    }

    if (levelConfig?.disable_negative_balances && cleanedBalance < 0) {
      return Notifier.error(`You cannot set a negative balance for this policy.`);
    }

    setLoading(true);
    try {
      const updatedTimeOffPolicies = teamMember.time_off.policies.map((policy) => {
        if (policy.policy_id === id) {
          return { ...policy, balance: cleanedBalance };
        }
        return policy;
      });
      const payload = { time_off: { policies: updatedTimeOffPolicies } };
      const response = await MiterAPI.team_member.update(teamMember._id, payload);

      if (response.error) throw Error(response.error);

      const responseBalance = response.time_off.policies.find(({ policy_id }) => policy_id === id)?.balance;
      if (responseBalance !== cleanedBalance) throw Error("Response balance not equal to desired balance");

      setCurBalance(cleanedBalance);
      setBalance(cleanedBalance);
      setEditMode(false);
      Notifier.success("You have successfully updated the time off balance.");
      handleUpdate();
    } catch (e: $TSFixMe) {
      console.error("Error updating time off policy balance:", e);
      Notifier.error("Error updating time off policy balance\n" + e);
    }
    setLoading(false);
  };

  // Clear balance and set edit mode to false when the user clicks the cancel button
  const handleCancel = () => {
    setBalance(curBalance);
    setEditMode(false);
  };

  const handleClick = () => {
    if (editMode) return;
    navigate(`/time-off/policies/${id}`);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setEditMode(true);
  };

  /*********************************************************
   *  Rendering functions
   **********************************************************/

  // Ended up not using Formblock here b/c its a super simple form.
  const renderBalance = () => {
    const cleanedBalance = levelConfig?.unlimited ? "Unlimited" : balance;

    if (editMode && !levelConfig?.unlimited) {
      return (
        <>
          <input
            type="number"
            className={"form2-text " + styles["team-member-time-off-policy-balance-input"]}
            defaultValue={cleanedBalance}
            min={0}
            max={levelConfig?.accrual_config?.max_balance || undefined}
            required={true}
            onChange={(e) => setBalance(Number(e.target.value))}
          />
          <span style={{ opacity: 0.6 }}> hrs </span>
        </>
      );
    } else {
      return (
        <span className={styles["team-member-time-off-policy-balance-value"]}>
          {cleanedBalance} {cleanedBalance === 1 ? "hour" : "hours"}
        </span>
      );
    }
  };

  const renderEditSaveButton = () => {
    if (loading) {
      return <Loader className={"time-off-edit-btn-loader"} />;
    } else if (levelConfig && editMode) {
      return (
        <>
          <span
            className={styles["team-member-time-off-policy-edit-link"] + " " + styles["save"]}
            onClick={updateBalance}
          >
            Save
          </span>
          <span
            className={styles["team-member-time-off-policy-edit-link"] + " " + styles["cancel"]}
            onClick={handleCancel}
          >
            Cancel
          </span>
        </>
      );
    } else if (levelConfig && !levelConfig?.unlimited && teamAbilities.can("update_sensitive", teamMember)) {
      return (
        <span className={styles["team-member-time-off-policy-edit-link"]} onClick={handleEdit}>
          Edit Balance
        </span>
      );
    }
  };

  return (
    <div className={styles["team-member-time-off-policy"]} onClick={handleClick}>
      <h3 className={styles["team-member-time-off-policy-name"]}>{name}</h3>
      <p className={styles["team-member-time-off-policy-type"]}>{capitalize(type)}</p>
      <div className={styles["team-member-time-off-policy-footer"]}>
        <div className={styles["team-member-time-off-policy-balance"]}>
          <div>
            <span className={styles["team-member-time-off-policy-balance-title"]}>Balance:</span>
            {renderBalance()}
          </div>
          {renderEditSaveButton()}
        </div>
      </div>
    </div>
  );
};
