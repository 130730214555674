import { MiterAPI } from "dashboard/miter";
import jwt from "jsonwebtoken";
import { DateTime } from "luxon";
import { Notifier } from "ui";

export const goToMiterGuide = async (url: string | null | undefined, sameTab = false): Promise<void> => {
  try {
    let guidesToken = localStorage.getItem("guidesToken");
    if (guidesToken) {
      try {
        const decoded = jwt.decode(guidesToken) as { exp: number; miter: number };
        if (decoded.exp <= DateTime.now().toSeconds()) throw new Error("Expired token");
        const versionNum = 1; // if you change this, change it in the backend code too, it's an escape hatch in case we change the JWT key or something
        if (decoded.miter !== versionNum) throw new Error("Stale token");
      } catch (_err) {
        guidesToken = null;
        localStorage.removeItem("guidesToken");
      }
    }

    if (!guidesToken) {
      const res = await MiterAPI.users.getMiterGuidesJwt();
      if (res.error) throw new Error(res.error);
      guidesToken = res.token;
      localStorage.setItem("guidesToken", guidesToken);
    }

    let guidesUrl = process.env.REACT_APP_MITER_GUIDES_URL;
    if (!guidesUrl) throw new Error("No Miter Guides URL");
    if (guidesUrl.endsWith("/")) guidesUrl = guidesUrl.slice(0, -1);

    if (url && url !== "/") {
      if (!url.startsWith("/")) {
        url = "/" + url;
      }
    } else {
      url = "";
    }

    // There might be a hash, but we have to insert the jwt_token before the hash
    const [location, hash] = url.split("#");
    const [path, query] = location?.split("?") || [];
    const appendedQuery = `${query ? `?${query}&` : "?"}jwt_token=${guidesToken}`;

    const finalUrl = `${guidesUrl}${path || ""}${appendedQuery}${hash ? `#${hash}` : ""}`;

    window.open(finalUrl, sameTab ? "_self" : "_blank");
  } catch (e: $TSFixMe) {
    console.log(`Error opening Miter Guides`, e);
    Notifier.error(`Error opening Miter Guides: ${e.message}`);
    setTimeout(() => window.open(window.location.pathname, "_self"), 1500);
  }
};
