import React, { FC } from "react";
import styles from "./ChecklistTeamMemberLander.module.css";

import { AggregatedTeamMember } from "dashboard/miter";
import { ChartLineUp, PlusCircle, Shield, Users } from "phosphor-react";
import { Button } from "ui";
import { useNavigate } from "react-router-dom";
import { useOnboardingChecklistOptions } from "dashboard/hooks/atom-hooks";

type Props = {
  teamMember: AggregatedTeamMember;
  onExit: () => void;
  setChangeChecklistModal: (value: boolean) => void;
};

export const ChecklistTeamMemberLander: FC<Props> = ({ teamMember, setChangeChecklistModal }) => {
  const navigate = useNavigate();
  const checklistOptions = useOnboardingChecklistOptions();
  const renderFeatures = () => {
    const featureElements = capitalFeatures.map((feature) => {
      return (
        <div className={styles["feature"]} key={feature.label}>
          <div className={styles["feature-icon"]}>{feature.icon}</div>
          <div className={styles["feature-label"]}>
            <p>{feature.label}</p>
          </div>
        </div>
      );
    });

    return <div className={styles["features"] + " " + styles["capital-features"]}>{featureElements}</div>;
  };

  const assignOnboardingChecklist = () => {
    setChangeChecklistModal(true);
  };

  const createOnboardingChecklist = () => {
    navigate(`/team-members/settings`);
  };

  const hasChecklistOptions = checklistOptions.length > 0;

  const renderCTA = () => {
    return (
      <div className={styles["cta"]}>
        <div className="flex">
          {hasChecklistOptions && (
            <Button
              className={styles["cta-button"] + " button-2 no-margin"}
              onClick={assignOnboardingChecklist}
              style={{ marginRight: "10px" }}
            >
              <Users style={{ marginBottom: -2, marginRight: 10 }} />
              Assign onboarding checklist
            </Button>
          )}
          <Button
            className={
              styles["cta-button"] + (hasChecklistOptions ? " button-1 no-margin" : " button-2 no-margin")
            }
            onClick={createOnboardingChecklist}
          >
            <PlusCircle style={{ marginBottom: -2, marginRight: 10 }} />
            New onboarding checklist
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <p className={styles["header-subtitle"]}>
        Onboard {teamMember.full_name} easily with custom onboarding checklists.
      </p>
      {renderFeatures()}
      {renderCTA()}
    </div>
  );
};

const capitalFeatures = [
  {
    icon: <Users color="#4D54B6" weight="duotone" />,
    label: "Assign custom onboarding tasks.",
  },
  {
    icon: <ChartLineUp color="#4D54B6" weight="duotone" />,
    label: "Track progress and send reminders.",
  },
  {
    icon: <Shield color="#4D54B6" weight="duotone" />,
    label: "Ensure payroll runs smoothly!",
  },
];
