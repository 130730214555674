import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React from "react";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { Company } from "backend/models";
import { SettingsCard } from "ui/settings/SettingsCard";
import { useActiveCompany, usePerDiemRateOptions } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { PerDiemRatesTable } from "./PerDiemRatesTable";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";
import { PerDiemMultipleEligibleRatesBehavior } from "backend/models/company/company-settings";

type PerDiemSettingsUpdateParams = Company["settings"]["per_diem_rates"];

type PerDiemRateSettingsForm = {
  default_rates_for_new_job: Option<string>[];
  multiple_eligible_rates_behavior: PerDiemMultipleEligibleRatesBehavior;
};

export const PerDiemRateSettings: React.FC = () => {
  const fetchUserData = useFetchUserData();
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;
  const perDiemSettings = activeCompany?.settings?.per_diem_rates || null;

  const [refreshCount, setRefreshCount] = React.useState(0);

  const perDiemRateOptions = usePerDiemRateOptions();

  const form = useForm<PerDiemRateSettingsForm>();
  const { handleSubmit } = form;

  const cleanData = (data: PerDiemRateSettingsForm): PerDiemSettingsUpdateParams => {
    return {
      default_rates_for_new_job: data.default_rates_for_new_job?.map((option) => option.value) || [],
      multiple_eligible_rates_behavior: data.multiple_eligible_rates_behavior,
    };
  };

  const updateSettings = async (data: PerDiemRateSettingsForm): Promise<void> => {
    try {
      if (!activeCompanyId) throw Error(`Could not update settings for company.`);
      const response = await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.per_diem_rates": cleanData(data) },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Per diem rate settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(`Error updating per diem rate settings for company: ${activeCompanyId!}`, e);
      Notifier.error(
        "There was an error updating per diem rate settings. Our engineers are looking into it!"
      );
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  /* Form */

  return (
    <div>
      <SettingsCard title={"Settings"}>
        <Formblock
          type="multiselect"
          label="Default per diem rates for new job"
          labelInfo="Select the per diem rates that should be applied to every new job. You can adjust these after the job is created."
          name="default_rates_for_new_job"
          editing={true}
          className="modal"
          placeholder="Select default per diem rates for new jobs"
          options={perDiemRateOptions}
          form={form}
          onChange={updateSettingsDebounced}
          defaultValue={perDiemSettings?.default_rates_for_new_job}
        />
        <div className="vertical-spacer-small" />
        <Formblock
          label="Multiple eligible rates behavior"
          labelInfo="Configure what should happen when a team member is eligible for multiple per diem rates on a job."
          type="radio"
          name="multiple_eligible_rates_behavior"
          form={form}
          className="modal wizard"
          editing={true}
          options={[
            { label: "Only create a per diem for the highest eligible rate", value: "highest_rate" },
            { label: "Create per diems for all eligible rates", value: "all_rates" },
          ]}
          onChange={updateSettingsDebounced}
          defaultValue={perDiemSettings?.multiple_eligible_rates_behavior}
        />
      </SettingsCard>
      <SettingsCard title={"Manage all rates"}>
        <PerDiemRatesTable onUpdate={() => setRefreshCount(refreshCount + 1)} />
      </SettingsCard>
    </div>
  );
};
