import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { Company } from "dashboard/miter";
import { Formblock, SettingsCard } from "ui";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { useWatch } from "react-hook-form";
import { GlobalAccessFilterSettingCard } from "dashboard/components/access-filters/GlobalAccessFilterSettingCard";
import { useHasAccessToAccessFilters } from "dashboard/gating";

type SettingsForm = NonNullable<Company["settings"]["activities"]> & {};

const SETTINGS_PATH = "settings.activities";

export const ActivitySettings: React.FC = () => {
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();
  const hasAccessToAccessFilters = useHasAccessToAccessFilters();

  const { form, handleFieldUpdate } = useSettingsForm<SettingsForm>(SETTINGS_PATH);
  useWatch<SettingsForm>({ control: form.control });

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Activity settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/activities")}>
          Back to activities
        </div>
        <div className="flex">
          <h1>Activity settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure activity related settings</div>
        <div className="vertical-spacer-small"></div>
        <SettingsCard title="Selection settings">
          <Formblock
            text="Prefix the activity name with its code in the dashboard"
            labelInfo="Prefix the activity name with its code in the dashboard when selecting an activity for a
              timesheet, expense, etc."
            type="checkbox"
            form={form}
            editing={true}
            onChange={(e) => handleFieldUpdate(e.target.checked, "show_code_in_dashboard")}
            style={{ alignItems: "center" }}
            labelStyle={{ width: "100%" }}
            defaultValue={activeCompany?.settings?.activities?.show_code_in_dashboard}
          />
          <Formblock
            text="Prefix the activity name with its code in the mobile app"
            type="checkbox"
            form={form}
            editing={true}
            onChange={(e) => handleFieldUpdate(e.target.checked, "show_code_in_mobile")}
            style={{ alignItems: "center" }}
            labelStyle={{ width: "100%" }}
            defaultValue={activeCompany?.settings?.activities?.show_code_in_mobile}
          />
        </SettingsCard>
        {hasAccessToAccessFilters && <GlobalAccessFilterSettingCard entityType="activities" />}
      </div>
    </div>
  );
};
