import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedCertificationType, CertificationType } from "dashboard/types/certification-types";

/*
 * CERTIFICATIONS
 */
type CertificationTypeParams =
  | CertificationType
  | AggregatedCertificationType
  | AggregatedCertificationType[]
  | undefined;

type CertificationTypeAction = "create" | "read" | "update" | "delete";
type CertificationTypeAbilities = {
  can: (action: CertificationTypeAction, items: CertificationTypeParams) => boolean;
  cannot: (action: CertificationTypeAction, items: CertificationTypeParams) => boolean;
};

export const useCertificationTypeAbilities = (): CertificationTypeAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: CertificationTypeAction, items?: CertificationTypeParams) => {
      if (!items) return false;
      const certificationTypes = Array.isArray(items) ? items : [items];

      return certificationTypes.every((_ct) => {
        switch (action) {
          case "create":
            return can_("certifications:certification_types:create");
          case "read":
            return can_("certifications:certification_types:read");
          case "update":
            return can_("certifications:certification_types:update");
          case "delete":
            return can_("certifications:certification_types:delete");
          default:
            return false;
        }
      });
    },
    [can_]
  );

  const cannot = useCallback(
    (action: CertificationTypeAction, items: CertificationTypeParams) => {
      return !can(action, items);
    },
    [can]
  );

  return useMemo(() => ({ can, cannot }), [can, cannot]);
};
