import React, { useEffect, useState } from "react";
import { Wizard } from "ui";
import { AggregatedPerformanceReviewCycle, Form, PerformanceReviewCycle } from "dashboard/miter";
import { ReviewCycleCreationScreen } from "./ReviewCycleCreationScreen";
import { FormBuilderScreen } from "../forms/FormBuilderScreen";
import { ReviewCycleRevieweesScreen } from "./ReviewCycleRevieweesScreen";
import { ConfirmScheduleDetails } from "./ConfirmScheduleDetails";
import { PerformanceTemplate } from "../forms/templates/performance";
import { useRefetchPerformanceReviewSchedules } from "dashboard/hooks/atom-hooks";
import { usePageCloseOrRefresh } from "dashboard/hooks/usePageCloseOrRefresh";

export type ReviewCycleMode = "create" | "edit-questions" | "edit-settings";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  performance_review_cycle: PerformanceReviewCycle | AggregatedPerformanceReviewCycle;
  self_review_form: Form;
  direct_report_review_form: Form;
  mode?: "edit_schedule_details";
};

const PerformanceReviewCycleWizard: React.FC<Props> = ({
  onExit,
  onComplete,
  performance_review_cycle,
  self_review_form,
  direct_report_review_form,
  mode,
}) => {
  const [performanceReviewCycle, setPerformanceReviewCycle] =
    useState<PerformanceReviewCycle>(performance_review_cycle);
  const [selfReviewForm, setSelfReviewForm] = useState<Form | undefined>(self_review_form);
  const [directReportReviewForm, setDirectReportReviewForm] = useState<Form | undefined>(
    direct_report_review_form
  );
  const [selfReviewTemplateEnabled, setSelfReviewTemplateEnabled] = useState(true);
  const [managerReviewTemplateEnabled, setManagerReviewTemplateEnabled] = useState(true);
  const refetchPerformanceReviewSchedules = useRefetchPerformanceReviewSchedules();

  useEffect(() => {
    setSelfReviewForm(self_review_form);
  }, [self_review_form]);

  useEffect(() => {
    setDirectReportReviewForm(direct_report_review_form);
  }, [direct_report_review_form]);

  // If the page is closed or refreshed before the wizard is completed, we delete the performance review schedule
  usePageCloseOrRefresh(onExit);

  const title = performanceReviewCycle ? "Edit review schedule" : "Create review schedule";

  const handleComplete = async () => {
    onComplete();
    refetchPerformanceReviewSchedules();
  };

  const notInEditMode = mode !== "edit_schedule_details";

  return (
    <Wizard onExit={onExit} onComplete={handleComplete}>
      <ReviewCycleCreationScreen
        name={title}
        performanceReviewCycle={performanceReviewCycle}
        setPerformanceReviewCycle={setPerformanceReviewCycle}
      />
      {notInEditMode && (
        <ReviewCycleRevieweesScreen
          name={"Select reviewees"}
          performanceReviewCycle={performanceReviewCycle}
          setPerformanceReviewCycle={setPerformanceReviewCycle}
        />
      )}
      {notInEditMode && performanceReviewCycle?.self_review?.enabled && (
        <FormBuilderScreen
          name={"Self review form"}
          formItem={selfReviewForm}
          setFormItem={setSelfReviewForm}
          disableAutoSave={true}
          template={PerformanceTemplate.SELF_EVALUATION}
          templateEnabled={selfReviewTemplateEnabled}
          setTemplateEnabled={setSelfReviewTemplateEnabled}
          parentType="performance_review_cycle"
        />
      )}
      {notInEditMode && performanceReviewCycle?.direct_report_review?.enabled && (
        <FormBuilderScreen
          name={"Manager review form"}
          formItem={directReportReviewForm}
          setFormItem={setDirectReportReviewForm}
          disableAutoSave={true}
          template={PerformanceTemplate.MANAGER_EVALUATION}
          templateEnabled={managerReviewTemplateEnabled}
          setTemplateEnabled={setManagerReviewTemplateEnabled}
          parentType="performance_review_cycle"
        />
      )}
      {notInEditMode && (
        <ConfirmScheduleDetails
          name={"Confirm schedule details"}
          performanceReviewSchedule={performanceReviewCycle}
        />
      )}
    </Wizard>
  );
};

export default PerformanceReviewCycleWizard;
