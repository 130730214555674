import React, { FC } from "react";
import { Formblock } from "ui";
import { FieldRequirement } from "dashboard/miter";
import { useForm } from "react-hook-form";
import { CompanySettings } from "backend/models/company";
import InfoButton from "../information/information";
import { FieldProps } from "../timesheets/TimesheetDashboardFieldRequirements";

type Settings = CompanySettings["daily_reports"];

export type Props = {
  settings: Settings;
  handleChange: (fieldName: string, value: unknown) => Promise<void>;
};

const options = [
  { label: "Required", value: "required" },
  { label: "Optional", value: "optional" },
  { label: "Hidden", value: "hidden" },
];

export const DailyReportMobileFieldRequirements: FC<Props> = ({ settings, handleChange }) => {
  const form = useForm();
  const { control } = form;

  const fields: FieldProps[] = [
    {
      name: "customer_signature",
      label: "Customer E-Signature",
      value: settings.field_requirements.mobile.customer_signature,
    },
  ];

  return (
    <div>
      {fields.map((field) => {
        const { name, label, value, description } = field;
        const update = async (requirement: { label: string; value: FieldRequirement }) => {
          await handleChange(`field_requirements.mobile.${name}`, requirement.value);
        };
        return (
          <div style={{ display: "flex", minWidth: 600, marginBottom: 10 }}>
            <div style={{ width: 200, display: "flex" }}>
              {label}
              {description && (
                <div style={{ marginTop: 5 }}>
                  <InfoButton text={description} />
                </div>
              )}
            </div>

            <Formblock
              name={name}
              type="select"
              options={options}
              defaultValue={value}
              onChange={update}
              editing={true}
              control={control}
              style={{ width: 200, marginTop: -5 }}
            />
          </div>
        );
      })}
    </div>
  );
};
