import { Stack } from "phosphor-react";
import React from "react";
import { Button } from "ui";

export type FlatfileImportButtonProps = {
  launch: () => void;
  resource: string;
  title?: string;
};

export const FlatfileImportButton: React.FC<FlatfileImportButtonProps> = ({ launch, resource, title }) => {
  return (
    <>
      <Button className="button-1" onClick={launch} style={{ height: 32, marginRight: 0 }}>
        <Stack weight="bold" style={{ marginBottom: -2, marginRight: 7 }} />
        {title || `Import ${resource}`}
      </Button>
    </>
  );
};
