import {
  useActiveCompanyId,
  useRejectionReasons,
  useRefetchRejectionReasons,
  useLookupMessageTemplate,
} from "dashboard/hooks/atom-hooks";
import { RejectionReason } from "dashboard/types/rejection-reason-types";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { Plus, Trash } from "phosphor-react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { RejectionReasonModal } from "./RejectionReasonModal";
import { Notifier } from "dashboard/utils";
import { MiterAPI } from "dashboard/miter";
import { REJECTION_REASONS_CATEGORY_MAP } from "dashboard/utils/ats";

export const RejectionReasonsTable: FC = () => {
  const rejectionReasons = useRejectionReasons();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const refetchRejectionReasons = useRefetchRejectionReasons();
  const companyId = useActiveCompanyId();
  const [selectedRows, setSelectedRows] = useState<RejectionReason[]>([]);
  const [selectedRow, setSelectedRow] = useState<RejectionReason>();
  const lookupMessageTemplate = useLookupMessageTemplate();

  const rejectionReasonColumns: ColumnConfig<RejectionReason>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        dataType: "string" as const,
      },
      {
        field: "category",
        headerName: "Category",
        width: 150,
        displayType: "badge",
        valueGetter: (row) => REJECTION_REASONS_CATEGORY_MAP[row?.data?.category || ""] || "-",
        colors: {
          "Rejected by employer": "orange",
          "Candidate not interested": "red",
        },
      },
      {
        field: "message_template_id",
        headerName: "Message template",
        valueFormatter: (row) => lookupMessageTemplate(row?.data?.message_template_id)?.name || "-",
        width: 250,

        dataType: "string" as const,
      },

      {
        field: "updated_at",
        headerName: "Last edited",
        dateType: "timestamp",
        dataType: "date" as const,
      },
    ],
    [lookupMessageTemplate]
  );

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(undefined);
  };

  const rowClick = (row: RejectionReason) => {
    setSelectedRow(row);
    setIsModalOpen(true);
  };

  const deleteRejectionReasons = useCallback(async () => {
    try {
      if (!companyId) {
        throw new Error("Company ID is not set");
      }
      const res = await MiterAPI.rejection_reasons.archive({
        company_id: companyId,
        ids: selectedRows.map((row) => row._id),
      });
      if (res.error) {
        throw res.error;
      }
      setSelectedRows([]);
      refetchRejectionReasons();
      Notifier.success("Rejection reasons deleted!");
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to delete rejection reasons: ${e.message}`);
    }
  }, [companyId, selectedRows, refetchRejectionReasons]);

  const staticActions = useMemo(() => {
    return [
      {
        label: "Create reason",
        action: openModal,
        important: true,
        className: "button-2 table-button",
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [openModal]);

  const dynamicActions = useMemo(() => {
    if (selectedRows.length === 0) return [];
    return [
      {
        label: "Delete",
        action: deleteRejectionReasons,
        className: "button-3 table-button",
        icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [selectedRows.length, deleteRejectionReasons]);

  return (
    <div>
      <TableV2
        id="rejection-reasons-table"
        columns={rejectionReasonColumns}
        data={rejectionReasons || []}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        resource="rejection reasons"
        onSelect={setSelectedRows}
        hideSecondaryActions={true}
        onClick={rowClick}
        hideFooter={true}
      />
      {isModalOpen ? (
        <RejectionReasonModal onClose={closeModal} rejectionReasonId={selectedRow?._id} />
      ) : null}
    </div>
  );
};
