import React, { useState } from "react";
import { UseFormMethods, FieldValues } from "react-hook-form";
import { Formblock } from "ui";
import styles from "./AddOverride.module.css";

type Props = {
  form: UseFormMethods<FieldValues>;
  feinOverride: string | undefined;
  setFeinOverride: (value: string | undefined) => void;
};

export const AddFeinOverride: React.FC<Props> = ({ form, feinOverride, setFeinOverride }) => {
  const [showField, setShowField] = useState(false);

  return (
    <div>
      {showField ? (
        <div>
          <div className={styles.verticalSpacerSmall}></div>
          <div className={styles.flex}>
            <div className={styles.bold}>Add custom FEIN</div>
            <div className={styles.flex1}></div>
            <div
              className={`${styles.blueLink} ${styles.fontSize14}`}
              onClick={() => {
                setShowField(false);
                setFeinOverride(undefined);
              }}
            >
              Clear
            </div>
          </div>
          <div className={styles.verticalSpacerSmall}></div>
          <Formblock
            type="text"
            label="Other FEIN"
            control={form.control}
            name="otherFEIN"
            errors={form.errors}
            editing={true}
            height="auto"
            register={form.register}
            defaultValue={feinOverride || ""}
            onChange={(e) => {
              setFeinOverride(e.target.value || undefined);
            }}
          />
        </div>
      ) : (
        <div
          className={`${styles.blueLink} ${styles.marginTop10} ${styles.fontSize15}`}
          onClick={() => setShowField(true)}
        >
          + Add custom FEIN
        </div>
      )}
    </div>
  );
};
