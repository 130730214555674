import { useMessageTemplateOptions, useLookupMessageTemplate, useUser } from "dashboard/hooks/atom-hooks";
import React, { FC, useEffect, useRef } from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { SMSTemplateEditor } from "../message-templates/SMSTemplateEditor";
import styles from "./CreateTemplatedMessageModal.module.css";
import Banner from "../shared/Banner";
import { useNavigate } from "react-router-dom";
import {
  hydrateRecruitingMessageTemplate,
  messageTemplateSmartFields,
} from "dashboard/utils/message-templates";
import { Candidate, JobPosting } from "dashboard/types/ats";

type Props = {
  onClose: () => void;
  saveMessage: (text: string) => void;
  candidate: Candidate;
  jobPosting?: JobPosting;
};

const CreateTemplatedMessageModal: FC<Props> = ({ onClose, saveMessage, candidate, jobPosting }) => {
  const messageTemplateOptions = useMessageTemplateOptions();
  const form = useForm();
  const { watch } = form;
  const templateOption = watch("template");
  const lookupTemplate = useLookupMessageTemplate();
  const template = lookupTemplate(templateOption?.value);
  const navigate = useNavigate();
  const editorRef = useRef();
  const user = useUser();

  const renderBanner = () => {
    if (messageTemplateOptions.length !== 0) return null;
    return (
      <Banner
        type="warning"
        onClick={() => {
          navigate("/recruiting-settings");
        }}
        className={styles["banner"]}
      >
        It looks like you don&apos;t have any message templates yet. Click here to create one.
      </Banner>
    );
  };

  const onSubmit = () => {
    if (!user) {
      Notifier.error("Could not find your user profile. Please log out and log back in.");
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TinyMCE doesn't have proper typing for ref
    const text = editorRef.current?.getContent();
    const hydratedText = hydrateRecruitingMessageTemplate({
      text,
      template,
      candidate,
      jobPosting,
      sender: user,
    });
    saveMessage(hydratedText);
    onClose();
  };

  useEffect(() => {
    if (template) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TinyMCE doesn't have proper typing for ref
      editorRef.current?.setContent(template.raw_html);
    }
  }, [template]);

  return (
    <ActionModal
      headerText={"Create message"}
      showSubmit={true}
      submitText="Save"
      showCancel={true}
      cancelText="Cancel"
      onCancel={onClose}
      onHide={onClose}
      onSubmit={onSubmit}
      wrapperClassName={styles["create-templated-message-modal-wrapper"]}
      bodyClassName={styles["create-templated-message-modal-body"]}
    >
      {renderBanner()}

      <Formblock
        type="select"
        label="Select a message template"
        form={form}
        name="template"
        options={messageTemplateOptions}
        className="modal"
        editing={true}
      />

      <SMSTemplateEditor
        defaultHTML={template?.raw_html}
        smartFields={messageTemplateSmartFields}
        editorRef={editorRef}
        toolbar={""}
        height={300}
      />
    </ActionModal>
  );
};

export default CreateTemplatedMessageModal;
