import React from "react";

import styles from "./autoCalculateExplainer.module.css";
import { DateTime } from "luxon";
import { AutoCalculateLogItem, OvertimeTotalItem } from "backend/utils/payroll/types";
import { TextWithTooltip, usdString } from "ui";
import { notNullish } from "miter-utils";
import { WORK_HOURS_IN_WEEK } from "dashboard/utils/constants";

type Props = {
  logItem: AutoCalculateLogItem;
};

const OvertimeTableHeader: React.FC<Props> = ({ logItem }) => {
  const cellClassName = `${styles["overtime-table-cell"]} ${styles["header"]}`;
  const totalCellClassName = cellClassName + ` ${styles["total-column"]}`;
  const otRuleCellClassName = cellClassName + ` ${styles["ot-rule-cells"]}`;
  const daysCellClassName = cellClassName + ` ${styles["days-column"]}`;

  const hasWeeklyDot = !!logItem.overtime_totals?.week?.weekly_dot;

  return (
    <div className={styles["overtime-table-row"]}>
      <div className={daysCellClassName}>Day</div>
      <div className={otRuleCellClassName}>OT calculation</div>
      <div className={cellClassName}>Regular</div>
      <div className={cellClassName}>Daily OT</div>
      <div className={cellClassName}>Weekly OT</div>
      <div className={cellClassName}>Daily DOT</div>
      {hasWeeklyDot ? <div className={cellClassName}>Weekly DOT</div> : null}
      <div className={totalCellClassName}>Total</div>
    </div>
  );
};

type TableRowProps = {
  daily_ot: OvertimeTotalItem;
  has_weekly_dot: boolean;
};

const otFunctionLabelLookup = {
  exempt: "Exempt",
  custom: "Custom",
  federal: "Federal",
  ca: "CA",
  nv: "NV",
  ky: "KY",
  co: "CO",
  ak: "AK",
  unknown: "-",
};

const OvertimeTableRow: React.FC<TableRowProps> = ({ daily_ot, has_weekly_dot }) => {
  const cellClassName = `${styles["overtime-table-cell"]}`;
  const dateCellClassName = cellClassName + ` ${styles["date-cells"]}`;
  const totalCellClassName = cellClassName + ` ${styles["total-column"]}`;
  const otRuleCellClassName = cellClassName + ` ${styles["ot-rule-cells"]}`;

  const dateDt = DateTime.fromISO(daily_ot.date);

  let niceDate = dateDt.toLocaleString({ month: "short", day: "numeric" }) + ` (${dateDt.toFormat("EEEEE")})`;

  if (daily_ot.isSeventhConsecutive) {
    niceDate = niceDate + "*";
  }

  if (daily_ot.fourTenOverride) {
    niceDate = niceDate + "+";
  }

  let otFunctionLabel =
    daily_ot.overtime_rule_used?.label || otFunctionLabelLookup[daily_ot.dailyOtFunction || "unknown"];

  if (otFunctionLabel.length > 20) otFunctionLabel = otFunctionLabel.slice(0, 18).trim() + "...";

  return (
    <div className={styles["overtime-table-row"]}>
      <div className={dateCellClassName}>{niceDate}</div>
      <div className={otRuleCellClassName}>{otFunctionLabel}</div>
      <div className={cellClassName}>{daily_ot.reg}</div>
      <div className={cellClassName}>{daily_ot.daily_ot}</div>
      <div className={cellClassName}>{daily_ot.weekly_ot}</div>
      <div className={cellClassName}>{daily_ot.daily_dot}</div>
      {has_weekly_dot ? <div className={cellClassName}>{daily_ot.weekly_dot}</div> : null}
      <div className={totalCellClassName}>{daily_ot.total}</div>
    </div>
  );
};

const OvertimeTableTotalRow: React.FC<Props> = ({ logItem }) => {
  const cellClassName = `${styles["overtime-table-cell"]} ${styles["total-row"]}`;
  const totalCellClassName = cellClassName + ` ${styles["total-column"]}`;
  const otRuleCellClassName = cellClassName + ` ${styles["ot-rule-cells"]}`;
  const daysCellClassName = cellClassName + ` ${styles["days-column"]}`;

  const weeklyTotals = logItem?.overtime_totals?.week;
  if (!weeklyTotals) return null;

  const hasWeeklyDot = !!logItem.overtime_totals?.week?.weekly_dot;

  return (
    <div className={styles["overtime-table-row"]}>
      <div className={daysCellClassName}>Total</div>
      <div className={otRuleCellClassName}></div>
      <div className={cellClassName}>{weeklyTotals.reg}</div>
      <div className={cellClassName}>{weeklyTotals.daily_ot}</div>
      <div className={cellClassName}>{weeklyTotals.weekly_ot}</div>
      <div className={cellClassName}>{weeklyTotals.daily_dot}</div>
      {hasWeeklyDot ? <div className={cellClassName}>{weeklyTotals.weekly_dot}</div> : null}
      <div className={totalCellClassName}>{weeklyTotals.total}</div>
    </div>
  );
};

const getThresholdTooltip = (threshold: "OT" | "DOT", label: string | undefined) => {
  return label != null
    ? `The weekly ${threshold} threshold for ${label}`
    : `The default weekly ${threshold} threshold.`;
};

const AutoCalculateOvertimeExplainer: React.FC<{ logItem?: AutoCalculateLogItem }> = ({ logItem }) => {
  if (!logItem) return null;
  const customOtRules = logItem.days?.map((d) => d.overtime_rule_used).filter(notNullish);

  const anyCustomOt = !!customOtRules && !!customOtRules.length;

  const otHourAllocation = logItem.ot_configuration?.ot_hour_allocation;
  const includesSeventhConsecutive = (logItem?.overtime_totals?.days || []).some(
    (day) => day.isSeventhConsecutive
  );
  const includesFourTenOverride = (logItem?.overtime_totals?.days || []).some((day) => day.fourTenOverride);

  const explainerText =
    otHourAllocation === "spread_evenly"
      ? `Overtime for a day or week is allocated proportionally across all timesheets during the period.`
      : `Overtime is not allocated across timesheets during a period. Instead, it applies only to timesheets occuring after the overtime threshold.`;

  const weeklyThresholdRef = logItem.week?.threshold_ref;

  const showRrpText =
    logItem.ot_configuration?.ot_rate_determination === "weighted_average" &&
    logItem.regular_rate_of_pay != null;
  const hasWeeklyDot = !!logItem.overtime_totals?.week?.weekly_dot;

  const renderExplainerText = () => {
    return (
      <div className="yellow-text-container">
        <div style={{ display: "flex", flexWrap: "wrap", fontSize: 15 }}>
          {`Miter is calculating overtime `} &nbsp;
          <TextWithTooltip
            id="tooltip"
            tooltip={explainerText}
            text={otHourAllocation === "in_effect" ? " in effect" : " spread evenly across the week"}
          />
          &nbsp;
          {`, and weekly overtime kicks in at`}
          &nbsp;
          <TextWithTooltip
            id="weeklyOtThreshold"
            tooltip={getThresholdTooltip("OT", weeklyThresholdRef?.used_ot_rule?.label)}
            text={`${weeklyThresholdRef?.threshold || WORK_HOURS_IN_WEEK} hours.`}
          />
          {weeklyThresholdRef?.dot_threshold ? (
            <div style={{ display: "flex", fontSize: 15, marginTop: 10 }}>
              Weekly double overtime kicks in at&nbsp;
              <TextWithTooltip
                id="weeklyDotThreshold"
                tooltip={getThresholdTooltip("DOT", weeklyThresholdRef?.used_ot_rule?.label)}
                text={`${weeklyThresholdRef.dot_threshold} hours.`}
              />
            </div>
          ) : null}
        </div>

        {showRrpText && (
          <div style={{ display: "flex", fontSize: 15, marginTop: 10 }}>
            <span>{`This week's regular rate of pay is ${usdString(logItem.regular_rate_of_pay)}.`} </span>
            &nbsp;
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {renderExplainerText()}
      <div className="vertical-spacer"></div>
      <div>
        <OvertimeTableHeader logItem={logItem} />
        {logItem?.overtime_totals?.days.map((item) => {
          return <OvertimeTableRow daily_ot={item} has_weekly_dot={hasWeeklyDot} />;
        })}
        <OvertimeTableTotalRow logItem={logItem} />
      </div>

      {includesSeventhConsecutive && !anyCustomOt && (
        <div style={{ fontSize: 14 }}>
          <div className="vertical-spacer"></div>
          *This is the employee&apos;s seventh consecutive day of work and thus subject to unique overtime
          rules.
        </div>
      )}

      {includesFourTenOverride && !anyCustomOt && (
        <div style={{ fontSize: 14 }}>
          <div className="vertical-spacer"></div>
          +Because the employee worked on a CA DIR job on this day, OT kicks in after 8 hours.
        </div>
      )}
    </div>
  );
};

export default AutoCalculateOvertimeExplainer;
