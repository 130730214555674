import Cohere from "cohere-js";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import { useActiveAccount, useUser } from "./atom-hooks";
import { useEffect } from "react";
import { buildCompanyUserName } from "miter-utils";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { datadogRum } from "@datadog/browser-rum";

export const useTrackers = (): void => {
  const user = useUser();
  const activeAccount = useActiveAccount();

  useEffect(() => {
    if (!user) {
      datadogRum.clearUser();
      return;
    }

    const userFullName = buildCompanyUserName(user);

    Sentry.setUser({
      id: user._id,
      username: user.email || undefined,
    });

    if (activeAccount) {
      Sentry.setTags({
        name: activeAccount.full_name,
        company_id: activeAccount.company_id,
        company_name: activeAccount.company_object.check_company.trade_name,
      });

      datadogRum.setUser({
        id: user._id,
        name: activeAccount.full_name,
        email: user.email || undefined,
        company_name: activeAccount.company_object.check_company.trade_name,
        company_id: activeAccount.company_id,
        account_id: activeAccount._id,
        account_type: activeAccount.type,
      });

      Cohere.identify(user._id, {
        displayName: activeAccount.full_name,
        email: user.email || undefined,
        phone_number: user.phone || undefined,
        company_name: activeAccount.company_object.check_company.trade_name,
        company_id: activeAccount.company_object._id,
        team_member_id: activeAccount?.team_member?._id,
      });

      if (IS_PRODUCTION) {
        posthog.identify(user._id, {
          name: activeAccount.full_name,
          email: user.email,
          phone_number: user.phone,
          company_name: activeAccount.company_object.check_company.trade_name,
          company_id: activeAccount.company_id,
          team_member_id: activeAccount?.team_member?._id,
        });
      }
    } else {
      Sentry.setTags({ name: userFullName });

      datadogRum.setUser({
        id: user._id,
        name: userFullName,
        email: user.email || undefined,
      });

      Cohere.identify(user._id, {
        displayName: userFullName,
        email: user.email || undefined,
        phone_number: user.phone || undefined,
      });

      if (IS_PRODUCTION) {
        posthog.identify(user._id, {
          name: userFullName,
          email: user.email,
          phone_number: user.phone,
        });
      }
    }
  }, [user, activeAccount]);
};
