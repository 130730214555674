import React from "react";
import { AccountingConfiguration } from "./AccountingConfiguration";
import { Toggler } from "ui";
import { useEnhancedSearchParams } from "miter-utils";
import { LedgerEntryGranularity } from "./LedgerGranularitySettings";
import { LedgerMappings } from "./LedgerMappings";

const togglerConfig = [
  { label: "Configuration", path: "config" },
  { label: "Mappings", path: "mappings" },
  { label: "Granularity", path: "granularity" },
];

export const AccountingSettings: React.FC = () => {
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const togglerState = parsedSearchParams.sub || "config";

  const handleToggle = (path: string) => {
    setSearchParams({ sub: path });
  };

  return (
    <>
      <Toggler config={togglerConfig} toggle={handleToggle} active={togglerState} secondary />
      <div style={{ display: "flex", flexDirection: "column" }}>
        {togglerState === "config" && <AccountingConfiguration />}
        {togglerState === "mappings" && <LedgerMappings />}
        {togglerState === "granularity" && <LedgerEntryGranularity />}
        <div className="vertical-spacer-large" />
      </div>
    </>
  );
};
