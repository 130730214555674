import { useLookupCompanyUsers } from "dashboard/hooks/atom-hooks";
import { OnboardingChecklist } from "dashboard/types/onboarding-types";
import { OnboardingTaskRow } from "miter-utils";

export type OnboardingChecklistRow = OnboardingChecklist & {
  milestones_description: string;
};

export const generateOnboardingChecklistRow = (
  checklists: OnboardingChecklist[]
): OnboardingChecklistRow[] => {
  const rows: OnboardingChecklistRow[] = checklists.map((checklist) => {
    const { milestones } = checklist;
    const milestonesDescription = Object.keys(milestones)
      .filter((milestone) => milestones[milestone])
      .map((milestone) => MAP_MILESTONE_TO_DETAILS[milestone].label)
      .join(", ");
    return {
      ...checklist,
      milestones_description: milestonesDescription,
    };
  });

  return rows;
};

export const MAP_MILESTONE_TO_DETAILS = {
  payroll: {
    label: "Payroll enrollment",
    description:
      "New hire should be enrolled into payroll. Necessary tasks: personal information, employment information, payment method, and witholding elections.",
  },
  ach: {
    label: "ACH",
    description: "New hire should be able to be reimbursed via ACH. Necessary tasks: ACH bank account setup.",
  },
  mobile_app: {
    label: "Mobile app",
    description: "New hire should have access to the mobile app. Tasks include: phone number setup.",
  },
  work_authorization: {
    label: "Work authorization",
    description:
      "New hire should be work authorized and ready for an I-9 audit. Tasks include: employee I-9 and employer I-9. Note: contractors will not be asked to complete an I-9.",
  },
};

export const requiredDocument = (v: { label: string; value: string }): true | string => {
  if (!v || !v.value) return "A document must be selected";
  return true;
};

export const requiredForm = (v: { label: string; value: string }): true | string => {
  if (!v || !v.value) return "A form must be selected";
  return true;
};

export const requiredCertification = (v: { label: string; value: string }): true | string => {
  if (!v || !v.value) return "A certification must be selected";
  return true;
};

export type OnboardingTaskRowWithAssignee = OnboardingTaskRow & {
  assigneeName: string;
};

/* This hook adds the assignee object onto each onboarding checklist task */
export const useFormatToDashboardTasks = (): {
  formatToDashboardTasks: (
    tasks: OnboardingTaskRow[],
    new_hire_user_id: string
  ) => OnboardingTaskRowWithAssignee[];
} => {
  const lookupCompanyUser = useLookupCompanyUsers();
  const formatToDashboardTasks = (
    tasks: OnboardingTaskRow[],
    new_hire_user_id: string
  ): OnboardingTaskRowWithAssignee[] => {
    return tasks.map((task) => {
      const assigneeUserId = task.assignee.type === "new_hire" ? new_hire_user_id : task.assignee.user_id;
      const user = lookupCompanyUser(assigneeUserId);
      const name = user
        ? user.first_name || user.last_name
          ? `${user.first_name} ${user.last_name}`
          : user.email || "Miter user"
        : "Miter user";
      return {
        ...task,
        assigneeName: name,
      };
    });
  };

  return { formatToDashboardTasks };
};
