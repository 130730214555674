import React from "react";
import { UseFormMethods, FieldValues } from "react-hook-form";
import { Formblock } from "ui";
import { SettingsCard } from "ui/settings/SettingsCard";
import { Sage300ConnectionMetadata } from "backend/services/sage300/sage300-types";
import { IntegrationConnection } from "dashboard/miter";
import { buildAtomicMongoUpdateFromNested, convertUndefinedToMongoUnset } from "dashboard/utils";
import { Option } from "ui/form/Input";

interface TeamMemberConfigProps {
  form: UseFormMethods<FieldValues>;
  sage300Metadata: Sage300ConnectionMetadata;
  updateIntegrationConnection: (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ) => Promise<void>;
}

const defaultPhoneOptions: Option<string>[] = [
  { value: "cell_phone", label: "Cell Phone" },
  { value: "main_phone", label: "Main Phone" },
];

export const TeamMemberConfig: React.FC<TeamMemberConfigProps> = ({
  form,
  sage300Metadata,
  updateIntegrationConnection,
}) => {
  const { defaultPhoneToUse } = sage300Metadata || undefined;

  const updatePhoneDefault = async (defaultPhoneOption: "cell_phone" | "main_phone") => {
    const raw = { metadata: { sage_300: { defaultPhoneToUse: defaultPhoneOption } } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    const finalUpdate = convertUndefinedToMongoUnset(flattened);
    await updateIntegrationConnection(finalUpdate);
  };

  return (
    <SettingsCard title="Team Members">
      <Formblock
        form={form}
        name="default_phone"
        defaultValue={defaultPhoneToUse}
        type="select"
        label="Default Phone"
        onChange={(option) => updatePhoneDefault(option.value)}
        options={defaultPhoneOptions}
        labelInfo="The phone the team member sync should default to when pulling from Sage300"
        editing={true}
        inputProps={{ style: { width: 250 } }}
      />
    </SettingsCard>
  );
};
