import { Formblock, SettingsCard } from "ui";
import { useActiveCompany, useTeamMemberChangeRequestPolicyOptions } from "dashboard/hooks/atom-hooks";
import { PoliciesTable } from "../policies/PoliciesTable";
import { CompanySettings } from "backend/models/company";

type TeamChangeRequestPolicySettingsProps = {
  handleTeamSettingsChange: (settings: Partial<CompanySettings["team"]>) => void;
};

const TeamChangeRequestPolicySettings: React.FC<TeamChangeRequestPolicySettingsProps> = ({
  handleTeamSettingsChange,
}) => {
  const teamChangeRequestPolicyOptions = useTeamMemberChangeRequestPolicyOptions();
  const activeCompany = useActiveCompany();
  if (!activeCompany) return null;
  const { settings } = activeCompany;

  const changeRequestCategories = [
    { label: "Employment", value: "employment" },
    { label: "Pay", value: "pay" },
    { label: "Defaults", value: "defaults" },
    { label: "Classifications", value: "classifications" },
    { label: "Personal", value: "personal" },
    { label: "Demographics", value: "demographics" },
    { label: "Emergency contacts", value: "emergency-contacts" },
    { label: "All", value: "all" },
  ];

  return (
    <SettingsCard
      title="Team member profile change settings"
      info="Set up approvals or notifications for changes to team member profiles."
    >
      <Formblock
        type="checkbox"
        name="enable_change_request_policies"
        text="Enable team member profile change policies"
        onChange={(e) => {
          handleTeamSettingsChange({ enable_change_request_policies: e.target.checked });
        }}
        checked={settings.team.enable_change_request_policies}
        editing={true}
      />
      <Formblock
        className="modal"
        type="multiselect"
        name="change_request_category"
        editing={true}
        label="Require approvals for the following categories:"
        placeholder={"Select categories..."}
        options={changeRequestCategories}
        defaultValue={settings.team.change_request_category}
        onChange={(e) => {
          handleTeamSettingsChange({ change_request_category: e?.map((category) => category.value) });
        }}
      />
      <Formblock
        type="select"
        name="default_policy_id"
        label="Default policy"
        placeholder="Select a default policy"
        options={teamChangeRequestPolicyOptions}
        onChange={(o) => handleTeamSettingsChange({ default_change_request_policy_id: o?.value || null })}
        defaultValue={settings.team.default_change_request_policy_id}
        editing={true}
        labelStyle={{ marginRight: 0 }}
        style={{ width: 350, marginBottom: -60, marginTop: 25 }}
      />
      <PoliciesTable policyType="team_member_change_request" />
    </SettingsCard>
  );
};

export default TeamChangeRequestPolicySettings;
