import { AggregatedJob, Job } from "dashboard/miter";

import React, { useState } from "react";
import DataBox from "dashboard/components/dataBox/DataBox";
import { useLookupPrg } from "dashboard/hooks/atom-hooks";

import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import { JobPayRateGroupsForm } from "./forms/JobPayRateGroupsForm";
import { useTrades } from "dashboard/hooks/useTrades";

type Props = {
  data: AggregatedJob;
  updateJob: (update: Partial<Job> & Record<string, $TSFixMe>) => Promise<void>;
  updatingJob: boolean;
};

export const JobPayRateGroups: React.FC<Props> = (props) => {
  const { data } = props;
  const [editing, setEditing] = useState(false);

  const jobAbilities = useJobAbilities();

  const lookupPrg = useLookupPrg();
  const { tradesLookup } = useTrades();

  const prgLabel = lookupPrg(data.pay_rate_group)?.label || "-";

  return (
    <DataBox
      title="Pay rate group options"
      className={undefined}
      onEdit={jobAbilities.can("update", data) ? () => setEditing(true) : undefined}
      editText={undefined}
      rows={undefined}
    >
      <>
        {!data.trades && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Pay rate group</span>
            <span className={"data-box-section-value font-14"}>{prgLabel}</span>
          </div>
        )}
        {Object.entries(data.trades || {})?.map(([tradeId, prgId]) => {
          return (
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>{tradesLookup[tradeId]?.name}</span>
              <span className={"data-box-section-value font-14"}>{lookupPrg(prgId)?.label}</span>
            </div>
          );
        })}

        {editing && (
          <JobPayRateGroupsForm
            data={props.data}
            updateJob={props.updateJob}
            updatingJob={props.updatingJob}
            hide={() => setEditing(false)}
          />
        )}
      </>
    </DataBox>
  );
};
