import { CSSProperties } from "react";
import { PaymentWarning } from "backend/utils/payroll/types";
import { useLookupTeam } from "dashboard/hooks/atom-hooks";

type TimesheetWarningsTooltipProps = {
  warnings: PaymentWarning[] | undefined;
  style?: CSSProperties;
};

/** Tooltip that shows the team member's mocked earning warnings for the pay period */
export const TimesheetWarningsTooltip: React.FC<TimesheetWarningsTooltipProps> = ({ warnings, style }) => {
  const lookupTeam = useLookupTeam();

  // If returning early, we need to return a full <div /> or else we get all sorts of errors from AgGrid
  if (!warnings?.length) return <div />;
  if (!Array.isArray(warnings)) return <div />;

  const title = warnings.length === 1 ? "Warning" : "Warnings";

  return (
    <div style={style} className="breakTimeTable">
      <table className={"simple-table"}>
        <thead className={"simple-table-header"}>
          <tr>
            <th>{title}</th>
          </tr>
        </thead>
        <tbody className={"simple-table-body"}>
          {warnings.map((warning) => (
            <tr key={warning.signature}>
              <td>
                {warning.warning_text}
                {warning.team_member_id ? ` for ${lookupTeam(warning.team_member_id)?.full_name}` : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
