import React from "react";
import { SignOffForm, RuleBuilderSection } from "./PolicyRuleBuilder";
import { Formblock } from "ui";
import { cloneDeep } from "lodash";
import { SignOffMethodOptions, getApprovalItemType } from "./PolicyConstants";
import { Button } from "ui";
import { Plus, X } from "phosphor-react";

import styles from "./PolicyRules.module.css";
import { PolicyType } from "backend/services/approvals/types";
import pluralize from "pluralize";

type SignOffBuilderProps = {
  policyType: PolicyType;
  signOff: SignOffForm;
  setSignOff: React.Dispatch<React.SetStateAction<SignOffForm>>;
};

export const SignOffBuilder: React.FC<SignOffBuilderProps> = ({ policyType, signOff, setSignOff }) => {
  const itemType = getApprovalItemType(policyType);

  const handleAddSignOff = () => {
    setSignOff({ agreement: "", method: SignOffMethodOptions[1] });
  };

  const handleRemoveSignOff = () => {
    setSignOff(null);
  };

  const handleUpdateAgreement = (type: "en" | "es", value: string) => {
    const updatedSignOff = cloneDeep(signOff) || { agreement: "", agreement_es: "" };

    if (type === "en") {
      updatedSignOff.agreement = value;
    } else {
      updatedSignOff.agreement_es = value;
    }

    setSignOff(updatedSignOff);
  };

  const renderSignOffForm = () => {
    if (!signOff) return;

    return (
      <div className={styles["rule-builder-light-grey-section"]}>
        <Formblock
          type="paragraph"
          name="agreement_en"
          editing={true}
          className="modal approval-flow"
          placeholder={"Enter your sign off agreement (English)"}
          onChange={(e) => handleUpdateAgreement("en", e.target.value)}
          defaultValue={signOff?.agreement}
          style={{ marginBottom: 15 }}
        />
        <Formblock
          type="paragraph"
          name="agreement_es"
          editing={true}
          className="modal approval-flow"
          placeholder={"Enter your sign off agreement (Spanish)"}
          onChange={(e) => handleUpdateAgreement("es", e.target.value)}
          defaultValue={signOff?.agreement_es}
        />

        <Button
          className="button-text purple-link no-margin"
          style={{ marginTop: 20 }}
          onClick={() => handleRemoveSignOff()}
        >
          <X style={{ marginRight: 7 }} />
          Remove sign off
        </Button>
      </div>
    );
  };

  return (
    <RuleBuilderSection
      title={"Add sign off requirements"}
      subtitle={`Require your employees to sign off on their ${pluralize(itemType)}`}
    >
      {!signOff && (
        <Button className="button-1 tall-button no-margin" onClick={handleAddSignOff}>
          <Plus style={{ marginRight: 7 }} />
          Add sign off
        </Button>
      )}
      {renderSignOffForm()}
    </RuleBuilderSection>
  );
};
