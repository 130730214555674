import { AggregatedExpense, Policy, PolicyRule } from "dashboard/miter";
import { ExpensePolicyField, ExpensePolicyFields } from "backend/models/policy";
import {
  useActiveCompany,
  useLookupPolicy,
  useLookupJob,
  useLookupDepartment,
  useIsSuperAdmin,
  useLookupTeam,
  useLookupCardTransactionCategories,
} from "dashboard/hooks/atom-hooks";
import { useMemo, useCallback } from "react";
import { findItemPolicyRule } from "../approvals";

type ExpensePolicyItem = AggregatedExpense;

type UseExpensePolicy = {
  meetsPolicyRequirements: () => boolean;
  matchingPolicyRule: PolicyRule | null | undefined;
  isFieldHidden: (field: ExpensePolicyField) => boolean;
  isFieldVisible: (field: ExpensePolicyField) => boolean;
  isFieldRequired: (field: ExpensePolicyField) => boolean;
  isFieldLocked: (field: ExpensePolicyField) => boolean;
  policy: Policy | undefined;
  needsAttentionMessages: string[];
};

/** Reusable hook to get the policy and/or policy rule for an item */
export const useExpensePolicy = (item: ExpensePolicyItem | null): UseExpensePolicy => {
  /*********************************************************
   * Hooks
   **********************************************************/
  const company = useActiveCompany();
  const lookupPolicy = useLookupPolicy();
  const lookupJob = useLookupJob();
  const lookupTeamMember = useLookupTeam();
  const lookupDepartment = useLookupDepartment();
  const isAdmin = useIsSuperAdmin();
  const lookupCardTransactionCategory = useLookupCardTransactionCategories();

  const category = lookupCardTransactionCategory(item?.card_transaction_category_id);

  /*********************************************************
   * Policy getters
   **********************************************************/
  const getTeamMemberPolicy = useCallback((): Policy | undefined => {
    if (!item) return;

    const teamMember = lookupTeamMember(item.team_member_id);
    return lookupPolicy(teamMember?.expense_policy_id);
  }, [item, lookupPolicy, lookupTeamMember]);

  const getJobPolicy = useCallback((): Policy | undefined => {
    if (!item) return;

    const job = lookupJob(item.job_id);
    return lookupPolicy(job?.expense_policy_id);
  }, [item, lookupJob, lookupPolicy]);

  const getDepartmentPolicy = useCallback((): Policy | undefined => {
    if (!item) return;

    const department = lookupDepartment(item.department_id);
    return lookupPolicy(department?.expense_policy_id);
  }, [item, lookupDepartment, lookupPolicy]);

  const getCompanyPolicy = useCallback((): Policy | undefined => {
    if (!item) return;

    return lookupPolicy(company?.settings?.expenses?.default_policy_id);
  }, [company?.settings?.expenses?.default_policy_id, item, lookupPolicy]);

  const policy = useMemo(() => {
    return (
      lookupPolicy(item?.approval_stage?.policy_id) ||
      getTeamMemberPolicy() ||
      getJobPolicy() ||
      getDepartmentPolicy() ||
      getCompanyPolicy()
    );
  }, [
    getCompanyPolicy,
    getDepartmentPolicy,
    getJobPolicy,
    getTeamMemberPolicy,
    item?.approval_stage?.policy_id,
    lookupPolicy,
  ]);

  const matchingPolicyRule = useMemo(() => {
    if (!policy || !item) return;
    return findItemPolicyRule(item, policy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, policy, JSON.stringify(item)]);

  /*********************************************************
   * Helpers that are returned from the hook
   **********************************************************/

  // Helper function to determine the field's policy status based on the new and old policy settings.
  const getFieldPolicyStatus = useCallback(
    (field: ExpensePolicyField, status: "hidden" | "required"): boolean => {
      if (!policy || !matchingPolicyRule?.fields) return false;
      const fields = matchingPolicyRule.fields as ExpensePolicyFields;
      return fields[field] === status;
    },
    [matchingPolicyRule, policy]
  );

  // Use the helper function to check if the field is hidden.
  const isFieldHidden = (field: ExpensePolicyField): boolean => {
    return !isAdmin && getFieldPolicyStatus(field, "hidden");
  };

  // Use the helper function to check if the field is required.
  const isFieldRequired = useCallback(
    (field: ExpensePolicyField): boolean => {
      return getFieldPolicyStatus(field, "required");
    },
    [getFieldPolicyStatus]
  );

  // Use the isFieldHidden function to check if the field is visible.
  const isFieldVisible = (field: ExpensePolicyField): boolean => {
    return !isFieldHidden(field);
  };

  const isFieldLocked = (field: ExpensePolicyField): boolean => {
    switch (field) {
      case "activity_id":
        return category?.activity?.is_locked || false;
      case "job_id":
        return category?.job?.is_locked || false;
      case "expense_account_id":
        return category?.gl_account?.is_locked || false;
      case "cost_type_id":
        return category?.cost_type?.is_locked || false;
      default:
        return false;
    }
  };

  const meetsPolicyRequirements = (): boolean => {
    // This is only used on the expense list so return true if has_policy is not present
    if (!item || !("has_receipt" in item)) return true;

    // If this item has been kicked back, it doesn't meet the policy requirements
    if (item.approval_stage?.kick_back) return false;

    const receiptFailed = isFieldRequired("file_ids") && !item?.has_receipt;

    const submitterMemoFailed = isFieldRequired("submitter_note") && !item?.submitter_note;

    const jobFailed = isFieldRequired("job_id") && !item?.job_id;

    const activityFailed = isFieldRequired("activity_id") && !item?.activity_id;

    const costTypeFailed = isFieldRequired("cost_type_id") && !item?.cost_type_id;

    const glAccountFailed = isFieldRequired("expense_account_id") && !item?.expense_account_id;

    const categoryFailed =
      isFieldRequired("card_transaction_category_id") && !item.card_transaction_category_id;

    return !(
      receiptFailed ||
      submitterMemoFailed ||
      jobFailed ||
      activityFailed ||
      costTypeFailed ||
      glAccountFailed ||
      categoryFailed
    );
  };

  const needsAttentionMessages = useMemo(() => {
    const reasons: string[] = [];

    // If this item has been kicked back, it doesn't meet the policy requirements
    if (item && "approval_stage" in item && item.approval_stage?.kick_back) {
      reasons.push(
        "Item kicked back: " +
          (item.approval_stage.kick_back.notes
            ? item.approval_stage.kick_back.notes
            : " please fix or contact the your approver.")
      );
    }

    const receiptFailed = isFieldRequired("file_ids") && !item?.has_receipt;
    if (receiptFailed) reasons.push("Receipt required");

    const submitterMemoFailed = isFieldRequired("submitter_note") && !item?.submitter_note;
    if (submitterMemoFailed) reasons.push("Memo required");

    const jobFailed = isFieldRequired("job_id") && !item?.job_id;
    if (jobFailed) reasons.push("Job required");

    const activityFailed = isFieldRequired("activity_id") && !item?.activity_id;
    if (activityFailed) reasons.push("Activity required");

    const costTypeFailed = isFieldRequired("cost_type_id") && !item?.cost_type_id;
    if (costTypeFailed) reasons.push("Cost type required");

    const glAccountFailed = isFieldRequired("expense_account_id") && !item?.expense_account_id;
    if (glAccountFailed) reasons.push("GL account required");

    const categoryFailed =
      isFieldRequired("card_transaction_category_id") && !item?.card_transaction_category_id;
    if (categoryFailed) reasons.push("Category required");

    return reasons;
  }, [isFieldRequired, item]);

  return {
    meetsPolicyRequirements,
    matchingPolicyRule,
    isFieldHidden,
    isFieldVisible,
    isFieldRequired,
    isFieldLocked,
    policy,
    needsAttentionMessages,
  };
};
