import React from "react";
import { ActionModal } from "ui";
import QRCode from "react-qr-code";
import styles from "./ATS.module.css";

/** Builds an action modal that displays a QR code with the url from the props as the content */
type QRCodeModalProps = {
  /** The url to display in the QR code */
  url: string;
  /** The title of the modal */
  title: string;
  /** The action to perform when the modal is closed */
  onClose: () => void;
};

export const QRCodeModal: React.FC<QRCodeModalProps> = ({ url, title, onClose }) => {
  const handleDownload = () => {
    const svg = document.getElementById("qrcode");
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");

      const downloadLink = document.createElement("a");
      downloadLink.download = "qrcode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };
  return (
    <ActionModal
      headerText={title}
      onHide={onClose}
      showCancel={true}
      onCancel={onClose}
      cancelText="Close"
      showSubmit={true}
      submitText="Download"
      onSubmit={handleDownload}
    >
      <div className={styles["job-posting-apply"]} style={{ textAlign: "center" }}>
        <div style={{ marginBottom: 25, color: "#333" }}>
          Download this QR code to share with candidates. They can scan it with their phone and directly apply
          to your job posting.
        </div>
        <QRCode
          id="qrcode"
          size={256}
          style={{ height: "auto", maxWidth: "70%", width: "70%", margin: "0 auto", marginBottom: 15 }}
          value={url}
          viewBox={`0 0 256 256`}
        />
      </div>
    </ActionModal>
  );
};
