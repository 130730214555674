import React, { FC, useMemo } from "react";
import { MileageLocation } from "dashboard/miter";
import { useState } from "react";
import { ActionModal } from "ui";
import { StopBasedMileageEditor } from "./StopBasedMileageEditor";
import { isValidTrip } from "dashboard/pages/expenses/expenseUtils";

type Props = {
  trip?: MileageLocation[];
  onHide: () => void;
  onSave: (trip: MileageLocation[], mileage: number) => void;
};

export const StopBasedMileageEditorModal: FC<Props> = ({ trip, onHide, onSave }) => {
  const [currentTrip, setCurrentTrip] = useState<MileageLocation[] | undefined>(trip);
  const [currentMileage, setCurrentMileage] = useState<number>();

  const isCurrentTripValid = useMemo(() => {
    // at least two stops with valid place_id and description
    return isValidTrip(currentTrip);
  }, [currentTrip]);

  // bubbles up currentTrip and currentMileage
  const handleSaveTrip = () => {
    // validate
    if (!isCurrentTripValid || !currentMileage) {
      return;
    }

    onSave(currentTrip!, currentMileage);
    onHide();
  };

  return (
    <ActionModal
      headerText={!trip?.length ? "Add trip" : "Edit trip"}
      showSubmit={true}
      submitDisabled={!isCurrentTripValid}
      showCancel={true}
      cancelText={"Cancel"}
      onCancel={() => onHide()}
      submitText={"Save"}
      onHide={() => onHide()}
      onSubmit={handleSaveTrip}
      loading={false}
    >
      <div style={{ marginTop: "15px" }}>
        <StopBasedMileageEditor
          editingMode="all_fields"
          mileageTrip={trip}
          setMileage={(mileage: number) => setCurrentMileage(mileage)}
          onTripUpdate={(trip: MileageLocation[] | undefined) => setCurrentTrip(trip)}
        />
      </div>
    </ActionModal>
  );
};
