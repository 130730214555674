import { SettingsCard } from "ui/settings/SettingsCard";
import { Formblock } from "ui";
import { UseFormMethods } from "react-hook-form";
import { CPRSettings } from "backend/models/company/company-settings";
import InfoButton from "dashboard/components/information/information";

export const CPRPaymentsSettings: React.FC<{
  form: UseFormMethods;
  settings: CPRSettings | undefined;
  updateSettings: () => void;
}> = ({ form, settings, updateSettings }) => {
  return (
    <>
      <SettingsCard title={"Earnings"}>
        <Formblock
          type="checkbox"
          name="include_contractors"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_contractors}
        >
          <div>{`Include contractor payments in reports`}</div>
        </Formblock>
        <Formblock
          type="checkbox"
          name="include_imputed_income_in_gross_pay"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_imputed_income_in_gross_pay}
        >
          <div>{`Include imputed income (taxable fringes, etc.) in reported gross pay`}</div>
        </Formblock>
        <Formblock
          type="checkbox"
          name="include_constant_salary_earnings"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_constant_salary_earnings}
        >
          <div>{`Include earnings for overtime-exempt salaried team members with pay held constant`}</div>
        </Formblock>
        <Formblock
          type="checkbox"
          name="include_cash_in_lieu_of_fringe_in_lcp_tracker_gross_pay"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_cash_in_lieu_of_fringe_in_lcp_tracker_gross_pay}
        >
          <div>{`Include wages in lieu of fringes in gross pay on LCP tracker`}</div>
        </Formblock>
      </SettingsCard>
      <SettingsCard title={"Pay rates"}>
        <Formblock
          type="checkbox"
          name="include_taxable_fringes_in_pay_rates"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_taxable_fringes_in_pay_rates}
        >
          <div>{`Include cash and imputed per-hour fringes in pay rates`}</div>
        </Formblock>
        <Formblock
          type="checkbox"
          name="include_cash_fringes_for_in_lieu_payment_rates"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_cash_fringes_for_in_lieu_payment_rates}
        >
          <div>{`Include cash per-hour fringes in wages in lieu of fringe rates`}</div>
        </Formblock>
        <Formblock
          type="checkbox"
          name="reg_rate_as_total_minus_cash_in_lieu"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.reg_rate_as_total_minus_cash_in_lieu}
        >
          <div className="flex">
            {`Adjust base rate to ensure it equals total pay rate minus cash in lieu of benefits`}
            <InfoButton
              text={
                "When a team member's base rate is greater than their classification pay rate and we use the team member rate, the cash in lieu of fringe will be counted in both the in lieu pay rate as well as in the team rate. This setting will adjust the base rate to ensure it equals the total pay rate minus cash in lieu of benefits."
              }
            />
          </div>
        </Formblock>
        <div style={{ marginBottom: -10 }}>
          <Formblock
            type="checkbox"
            name="always_display_union_base_pay_rates"
            form={form}
            editing={true}
            onChange={updateSettings}
            defaultValue={settings?.always_display_union_base_pay_rates}
          >
            <div className="flex">
              {`Always display classification base rates`}
              <InfoButton
                text={
                  "By default, Miter will use the calculated pay rate from the week, including adjustments for weighted average overtime or custom activity pay rates. Enabling this will always use the base rate on the classification for the displayed rate and for calculating gross amounts."
                }
              />
            </div>
          </Formblock>
        </div>
      </SettingsCard>
      <SettingsCard title={"Deductions"}>
        <Formblock
          type="checkbox"
          name="add_dues_fringe_deductions"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.add_dues_fringe_deductions}
        >
          <div className="flex">
            {`Include employee fringe deductions for dues in reported totals`}
            <InfoButton text={"By default, we only report employer contributions toward fringes."} />
          </div>
        </Formblock>
        <Formblock
          type="checkbox"
          name="add_vacation_holiday_fringe_deductions"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.add_vacation_holiday_fringe_deductions}
        >
          <div className="flex">
            {`Include employee fringe deductions for vacation/holiday in reported totals`}
            <InfoButton text={"By default, we only report employer contributions toward fringes."} />
          </div>
        </Formblock>
      </SettingsCard>
    </>
  );
};
