import React from "react";

import styles from "./NotFound.module.css";
import Balloon from "dashboard/assets/wrong-password.png";
import { Link } from "react-router-dom";

export const PermissionDeniedPage: React.FC = () => {
  return (
    <div className={styles["not-found-container"]}>
      <div className={styles["not-found"]}>
        <img src={Balloon} alt="404" />
        <h1>Whoops! You don&apos;t have permission to visit this page.</h1>
        <p className={styles["subtitle"]}>
          Not everyone can access the page you&apos;re looking for. Click the button below to go home.
        </p>
        <Link className={"button-2 " + styles["dashboard-btn"]} to={"/home"}>
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};
