import { Formblock, SettingsCard } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import Banner from "dashboard/components/shared/Banner";
import { UseFormMethods } from "react-hook-form";
import { useMemo } from "react";

interface TimesheetRoundingSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
  form: UseFormMethods;
}

const TimesheetRoundingSettings: React.FC<TimesheetRoundingSettingsProps> = ({
  timesheetSettings,
  handleChange,
  form,
}) => {
  const roundingIntervalCopy = useMemo(
    () =>
      ({
        "1_min": "minute",
        "5_min": "5 minutes",
        "10_min": "10 minutes",
        "15_min": "15 minutes",
        "30_min": "30 minutes",
        "1_hour": "hour",
      }[timesheetSettings.clock_times_rounding_interval]),
    [timesheetSettings.clock_times_rounding_interval]
  );

  return (
    <SettingsCard
      title="Timesheet rounding"
      info="Round clock in and clock out times when team members clock in via kiosk or the mobile app. This setting also rounds break times that overlap with the rounded clock in and out times for the timesheet."
    >
      {(!timesheetSettings.clock_times_rounding_interval ||
        timesheetSettings.clock_times_rounding_interval !== "none") && (
        <Banner
          type={"warning"}
          content={
            "Warning: Turning rounding on will automatically edit clock in and clock out times for team members! Check your state and local laws to make sure time rounding is allowed."
          }
          style={{ width: "90%", marginBottom: 18 }}
        />
      )}
      <Formblock
        type="select"
        name="clock_times_rounding_interval"
        form={form}
        editing={true}
        label="Round clock in and clock out times to the nearest: "
        className="modal"
        options={[
          { label: "Off", value: "none" },
          { label: "1 minute", value: "1_min" },
          { label: "5 minutes", value: "5_min" },
          { label: "10 minutes", value: "10_min" },
          { label: "15 minutes", value: "15_min" },
          { label: "30 minutes", value: "30_min" },
          { label: "1 hour", value: "1_hour" },
        ]}
        onChange={(e) => {
          handleChange({ clock_times_rounding_interval: e?.value });
        }}
        height="unset"
        defaultValue={timesheetSettings.clock_times_rounding_interval || "none"}
        style={{ width: "90%" }}
      />
      {timesheetSettings.clock_times_rounding_interval &&
        timesheetSettings.clock_times_rounding_interval !== "none" && (
          <Formblock
            type="select"
            name="clock_times_rounding_direction"
            form={form}
            editing={true}
            label="Rounding direction to determine clock in and clock out times: "
            className="modal"
            placeholder={"Select interval"}
            options={[
              { label: "Round to nearest " + roundingIntervalCopy, value: "half" },
              { label: "Round up", value: "up" },
              { label: "Round down", value: "down" },
            ]}
            onChange={(e) => {
              handleChange({ clock_times_rounding_direction: e?.value });
            }}
            height="unset"
            defaultValue={timesheetSettings.clock_times_rounding_direction}
            style={{ width: "90%" }}
          />
        )}
    </SettingsCard>
  );
};

export default TimesheetRoundingSettings;
