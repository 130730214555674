import React, { FC } from "react";
import { BasicModal } from "ui";

type Props = {
  onExit: () => void;
  onSubmit: () => void;
};

export const AckPerformanceTemplateChange: FC<Props> = ({ onExit, onSubmit }) => {
  return (
    <BasicModal
      button2Text="Switch"
      onHide={onExit}
      button2Action={onSubmit}
      headerText="Are you sure you want to change the template?"
    >
      <div
        style={{ display: "flex" }}
      >{`This will erase any manual changes you made to the evaluation form.`}</div>
    </BasicModal>
  );
};
