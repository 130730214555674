import React from "react";

import PayrollContext from "./payrollContext";
import { formatDate } from "dashboard/utils/utils";
import InfoButton from "dashboard/components/information/information";
import { CheckPayroll } from "backend/utils/check/check-types";
import { usdString } from "ui";

const PayrollTotals: React.FC = () => {
  const { payroll } = React.useContext(PayrollContext);
  const totals = payroll?.check_payroll.totals as CheckPayroll["totals"];
  if (!totals || !payroll) return <></>;

  const cashRequirementTotals = payroll.cash_requirement;

  return (
    <div style={{ display: "flex" }}>
      <div className="payroll-totals-wrapper">
        <div className="total-line">
          <div className="total-line-label">Employee gross earnings</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(Number(totals.employee_gross))}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label">Employee reimbursements</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(Number(totals.employee_reimbursements))}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label">Contractor gross earnings</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(Number(totals.contractor_gross))}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label">Contractor reimbursements</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(Number(totals.contractor_reimbursements))}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label">Company taxes</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(Number(totals.company_taxes))}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label flex">Company benefits contributions</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(Number(totals.company_benefits))}</div>
        </div>
        <div className="total-line bold">
          <div className="total-line-label flex">
            <div>Total liability</div>
            <InfoButton text="The total amount owed to employees, contractors, tax agencies, and benefits providers." />
          </div>
          <div className="flex-1"></div>
          <div className="total-line-symbol"></div>
          <div className="total-line-number">{usdString(Number(totals.liability))}</div>
        </div>
      </div>
      <div style={{ minWidth: 20 }}></div>
      <div className="payroll-totals-wrapper">
        <div className="total-line">
          <div className="total-line-label flex">
            <div>Direct deposits</div>
          </div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(cashRequirementTotals.directDeposits)}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label">Employee taxes</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(cashRequirementTotals.employeeTaxes)}</div>
        </div>
        <div className="total-line">
          <div className="total-line-label">Company taxes</div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(cashRequirementTotals.companyTaxes)}</div>
        </div>
        {!!cashRequirementTotals.unremittableTaxes && (
          <div className="total-line">
            <div className="total-line-label flex">
              <div>Unremittable taxes</div>
              <InfoButton text="Taxes that Miter does not remit on your behalf." />
            </div>
            <div className="flex-1"></div>
            <div className="total-line-symbol">-</div>
            <div className="total-line-number">{usdString(cashRequirementTotals.unremittableTaxes)}</div>
          </div>
        )}
        <div className="total-line">
          <div className="total-line-label flex">
            <div>Managed deductions</div>
            <InfoButton text="Post-tax deductions that Miter remits on your behalf." />
          </div>
          <div className="flex-1"></div>
          <div className="total-line-symbol">+</div>
          <div className="total-line-number">{usdString(cashRequirementTotals.managedDeductions)}</div>
        </div>
        {!!cashRequirementTotals.other && (
          <div className="total-line">
            <div className="total-line-label flex">
              <div>Other</div>
              <InfoButton text="Usually failed direct deposit payments that were ultimately paid by paper check." />
            </div>
            <div className="flex-1"></div>
            <div className="total-line-symbol">{cashRequirementTotals.other > 0 ? "+" : "-"}</div>
            <div className="total-line-number">{usdString(Math.abs(cashRequirementTotals.other))}</div>
          </div>
        )}
        <div className="total-line bold" style={{ border: "none" }}>
          <div className="total-line-label flex">
            <div>Cash requirement</div>
            <InfoButton text={"The cash value necessary to fund this payroll."} />
          </div>
          <div className="flex-1"></div>
          <div className="total-line-symbol"></div>
          <div className="total-line-number">{usdString(Number(totals.cash_requirement))}</div>
        </div>
        {payroll?.check_payroll.status === "draft" && (
          <div className="payroll-totals-debit-notification">
            <div className="flex-1"></div>
            <div>
              <span>
                {"Miter will debit your account on " +
                  formatDate(payroll.check_payroll.approval_deadline, undefined, true)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayrollTotals;
