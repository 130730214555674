import { ActionModal } from "ui";
import { AuditLogHistory, AuditLogHistoryProps } from "./AuditLogHistory";

type Props = AuditLogHistoryProps & {
  onHide: () => void;
};

export const AuditLogHistoryModal: React.FC<Props> = ({ itemId, type, onHide, refreshCounter }) => {
  return (
    <ActionModal
      headerText={"Audit Log History"}
      onHide={onHide}
      wrapperStyle={{ minWidth: 800 }}
      bodyStyle={{ height: 800 }}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <AuditLogHistory itemId={itemId} type={type} refreshCounter={refreshCounter} />
      </div>
    </ActionModal>
  );
};
