import React from "react";
import { AggregatedTeamMember } from "dashboard/miter";
import { IntacctEmployeeFields } from "./IntacctEmployeeFields";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";

export const IntegrationEmployeeFields: React.FC<{ tm: AggregatedTeamMember }> = (props) => {
  const { tm } = props;

  const integrationMap = useHasIntegration();

  const intacctIc = integrationMap.get("sage_intacct");

  return (
    // thinking we can put each integration as a separate line within the <></> here so they appear as separate databoxes
    <>{intacctIc ? <IntacctEmployeeFields tm={tm} ic={intacctIc} /> : null}</>
  );
};
