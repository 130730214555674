import { Candidate } from "dashboard/types/ats";
import React, { FC, useCallback, useEffect, useState } from "react";
import { AggregatedCandidate } from "dashboard/types/ats";
import { MiterAPI } from "dashboard/miter";
import { Loader } from "ui";
import { Article } from "phosphor-react";
import styles from "./ATS.module.css";

type Props = {
  candidates: Candidate[];
};

export const DuplicateCandidatesPopover: FC<Props> = ({ candidates }) => {
  const [aggregatedCandidates, setAggregatedCandidates] = useState<AggregatedCandidate[]>([]);
  const [loading, setLoading] = useState(false);

  const getAggregatedCandidates = useCallback(async () => {
    setLoading(true);
    const response = await MiterAPI.candidates.retrieve_many([
      {
        field: "_id",
        comparisonType: "in",
        value: candidates.map((candidate) => candidate._id),
      },
    ]);
    setAggregatedCandidates(response);
    setLoading(false);
  }, [candidates]);

  useEffect(() => {
    getAggregatedCandidates();
  }, [getAggregatedCandidates]);

  return (
    <div className={styles["duplicate-candidates-modal"]}>
      <div className={styles["duplicate-candidates-header"]}>
        <p style={{ fontWeight: "bold" }}>Possible duplicate candidates!</p>
      </div>

      <div className={styles["duplicate-candidates-content"]}>
        <p>Merge from the job application page.</p>
        {loading ? (
          <Loader />
        ) : (
          aggregatedCandidates.map((candidate) => {
            return (
              <div key={candidate._id} className={styles["duplicate-candidate-item"]}>
                <p>
                  {candidate.first_name} {candidate.last_name}
                </p>

                <div className="flex">
                  {candidate.job_applications?.length}
                  <Article size={16} style={{ marginLeft: 4 }} />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
