import React, { FC, useState } from "react";
import {
  useActiveCompanyId,
  useActivityOptions,
  useExpenseReimbursementCategoryOptions,
  useLookupExpenseReimbursementCategories,
  useRefetchPerDiemRates,
} from "dashboard/hooks/atom-hooks";
import { PerDiemRate, PerDiemRateCreationParams, MiterAPI, MiterError } from "dashboard/miter";
import { ActionModal, Badge, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import {
  TeamMemberGroupSelectValue,
  useTeamMemberGroupOptions,
} from "dashboard/components/team-members/useTeamMemberGroupOptions";
import * as vals from "dashboard/utils/validators";
import { capitalize } from "lodash";
import { notNullish } from "miter-utils";
import Banner from "dashboard/components/shared/Banner";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";
import { CadenceType, PerDiemRateAddressType } from "backend/models/per-diem-rate";
import { Address } from "backend/types";
import InfoButton from "dashboard/components/information/information";

type PerDiemRateFormParams = {
  name: string;
  cadence: Option<CadenceType>;
  expense_reimbursement_category_id: Option<string>;
  eligible_team_member_groups: Option<TeamMemberGroupSelectValue>[];
  eligible_activity_ids: Option<string>[];
  enable_distance_based_eligibility: boolean;
  minimum_jobsite_distance_from_home?: number;
  maximum_jobsite_distance_from_home?: number;
  address_type?: PerDiemRateAddressType;
  other_address?: Address;
};

const MAX_DISTANCE_FROM_JOBSITE = 10000;

type Props = {
  selectedPerDiemRate?: PerDiemRate;
  onSave: () => void;
  onHide: () => void;
};

const PerDiemRateModal: FC<Props> = ({ selectedPerDiemRate, onSave, onHide }) => {
  // hooks
  const activeCompanyId = useActiveCompanyId();
  const fetchUserData = useFetchUserData();

  const expenseReimbursementCategoryOptions = useExpenseReimbursementCategoryOptions();
  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: true,
    excludedGroups: ["self"],
  });
  const activityOptions = useActivityOptions();
  const lookupReimbursementCategory = useLookupExpenseReimbursementCategories();
  const refetchPerDiemRates = useRefetchPerDiemRates();

  const eligibleCategoryOptions = expenseReimbursementCategoryOptions.map((option) => {
    const category = lookupReimbursementCategory(option.value);

    return {
      ...option,
      isDisabled: !category?.amount || !!category.mileage_rate || false, // must have amount and not be mileage
    };
  });

  const buildDefaultValues = (perDiemRate?: PerDiemRate) => {
    if (!perDiemRate) {
      return {
        cadence: { value: "daily" as CadenceType, label: "Daily" },
      };
    }

    const teamMemberOptions = teamMemberGroupOptions.flatMap((group) => group.options);

    const teamMembers = (perDiemRate.eligible_team_member_groups || [])
      .map((group) => {
        const option = teamMemberOptions.find(
          (tm) => tm.value?.type === group.type && tm.value?.value === group.value
        );
        return option;
      })
      .filter(notNullish);

    const activities = (perDiemRate.eligible_activity_ids || [])
      .map((activityId) => {
        const option = activityOptions.find((option) => option.value === activityId);
        return option;
      })
      .filter(notNullish);

    return {
      name: perDiemRate.name,
      cadence: { value: perDiemRate.cadence, label: capitalize(perDiemRate.cadence) },
      expense_reimbursement_category_id: expenseReimbursementCategoryOptions.find(
        (option) => option.value === perDiemRate.expense_reimbursement_category_id
      ),
      eligible_team_member_groups: teamMembers,
      eligible_activity_ids: activities,
      enable_distance_based_eligibility: perDiemRate.enable_distance_based_eligibility,
      minimum_jobsite_distance_from_home: perDiemRate.minimum_jobsite_distance_from_home,
      maximum_jobsite_distance_from_home: perDiemRate.maximum_jobsite_distance_from_home,
      address_type: perDiemRate.address_type,
      // bug with Formblock - address has to be directly set on defaultValues for the state to work
    };
  };

  // form
  const form = useForm<PerDiemRateFormParams>({
    defaultValues: buildDefaultValues(selectedPerDiemRate),
  });
  const { control, handleSubmit, errors, watch } = form;
  const formData = watch();

  // state
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAddressType, setSelectedAddressType] = useState<PerDiemRateAddressType | undefined>(
    selectedPerDiemRate ? selectedPerDiemRate.address_type : "team_member_home"
  );

  const cleanParams = (data: PerDiemRateFormParams): PerDiemRateCreationParams => {
    // if distance based eligibility is not enabled, unset all distance based fields
    if (!data.enable_distance_based_eligibility) {
      data.minimum_jobsite_distance_from_home = undefined;
      data.maximum_jobsite_distance_from_home = undefined;
      data.other_address = undefined;
    }

    // if address type is team member, unset other address
    if (selectedAddressType === "team_member_home") {
      data.other_address = undefined;
    }

    return {
      name: data.name,
      company_id: activeCompanyId!,
      cadence: data.cadence.value,
      expense_reimbursement_category_id: data.expense_reimbursement_category_id?.value,
      eligible_team_member_groups: data.eligible_team_member_groups?.map((group) => group.value),
      eligible_activity_ids: data.eligible_activity_ids?.map((activity) => activity.value),
      enable_distance_based_eligibility: data.enable_distance_based_eligibility,
      // bug with Formblock - returns a string even though type is number
      minimum_jobsite_distance_from_home: Number(data.minimum_jobsite_distance_from_home),
      maximum_jobsite_distance_from_home: Number(data.maximum_jobsite_distance_from_home),
      // bring in selected address type from React state
      address_type: data.enable_distance_based_eligibility ? selectedAddressType : undefined,
      other_address: data.other_address
        ? {
            ...data.other_address,
            // @ts-expect-error state is an option from the form
            state: data?.other_address?.state?.value || undefined,
          }
        : undefined,
    };
  };

  const validateParams = (data: PerDiemRateCreationParams) => {
    if (!data.enable_distance_based_eligibility) {
      return;
    } else {
      // validate distance based eligibility
      if (
        data.minimum_jobsite_distance_from_home == null ||
        data.maximum_jobsite_distance_from_home == null
      ) {
        throw new Error("Minimum and maximum jobsite distance must be set.");
      }
    }

    // validate distance. Max distance has to be greater than min distance
    if (data.minimum_jobsite_distance_from_home > data.maximum_jobsite_distance_from_home) {
      throw new Error("Maximum jobsite distance must be greater than minimum jobsite distance.");
    }

    // just to preserve our own sanity - delta between min and max should be at least 10 miles
    if (data.maximum_jobsite_distance_from_home - data.minimum_jobsite_distance_from_home < 10) {
      throw new Error("Minimum and maximum jobsite distance must be at least 10 miles apart.");
    }

    if (data.maximum_jobsite_distance_from_home > MAX_DISTANCE_FROM_JOBSITE) {
      throw new Error(`Maximum jobsite distance must be less than ${MAX_DISTANCE_FROM_JOBSITE} miles.`);
    }

    // min distance = 0 miles
    if (data.minimum_jobsite_distance_from_home < 0) {
      throw new Error("Minimum jobsite distance cannot be a negative number.");
    }
  };

  const upsertPerDiemRate = async (data: PerDiemRateFormParams) => {
    setLoading(true);
    try {
      const params = cleanParams(data);
      validateParams(params);

      // validate params

      // create or update
      let res: PerDiemRate & MiterError;
      if (selectedPerDiemRate) {
        res = await MiterAPI.expense_reimbursements.per_diem_rates.update(selectedPerDiemRate._id, params);
      } else {
        res = await MiterAPI.expense_reimbursements.per_diem_rates.create(params);
      }

      if (res.error) throw new Error(res.error);

      Notifier.success(`Per diem rate ${selectedPerDiemRate ? "updated" : "created"}`);
      refetchPerDiemRates();
      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    if (!selectedPerDiemRate) return;

    setLoading(true);
    try {
      const res = await MiterAPI.expense_reimbursements.per_diem_rates.archive(selectedPerDiemRate._id);
      if (res.error) throw new Error(res.error);

      Notifier.success("Per diem rate deleted");
      // refresh since there's a side effect of updating company settings
      fetchUserData();
      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const addressOptions = [
    {
      value: "team_member_home",
      label: "Team member's home address",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const renderForm = () => {
    return (
      <div className="modal-body form two-column">
        <div className={"modal-form-column"}>
          <Formblock
            type="text"
            name="name"
            label="Name*"
            className="modal"
            form={form}
            val={vals.required}
            editing={true}
            errors={errors}
          />
          <Formblock
            type="select"
            name="cadence"
            label="Cadence*"
            labelInfo={
              formData.cadence.value === "hourly"
                ? "Total per diem will be calculated as rate amount * hours on the timesheet (including partial hours)."
                : ""
            }
            className="modal"
            form={form}
            val={vals.required}
            editing={true}
            options={[
              { value: "daily", label: "Daily" },
              { value: "hourly", label: "Hourly" },
            ]}
            requiredSelect={true}
          />
          <Formblock
            type="select"
            name="expense_reimbursement_category_id"
            label="Reimbursement category*"
            className="modal"
            labelInfo="Only categories with a set amount are eligible for per diem rates."
            form={form}
            val={vals.required}
            editing={true}
            options={eligibleCategoryOptions}
            requiredSelect={true}
          />
        </div>
        <div className={"modal-form-column"}>
          <Formblock
            type="multiselect"
            name="eligible_team_member_groups"
            label={"Eligible team members*"}
            labelInfo="Select team members eligible for this per diem rate."
            form={form}
            control={control}
            editing={true}
            className="modal"
            placeholder={"Select team members"}
            options={teamMemberGroupOptions}
            height="unset"
            requiredSelect={true}
          />
          <Formblock
            type="multiselect"
            name="eligible_activity_ids"
            label={
              <div className="flex">
                <>Eligible activities</>
                <Badge text="New" color="green" />
              </div>
            }
            labelInfo="If selected, only timesheets with these activities will generate per diems."
            form={form}
            control={control}
            editing={true}
            className="modal"
            placeholder={"Select activities"}
            options={activityOptions}
            height="unset"
          />
          <div className="flex margin-top-15 align-items-top">
            <Formblock
              type="checkbox"
              name="enable_distance_based_eligibility"
              form={form}
              control={control}
              editing={true}
              className="no-margin align-items-top"
              checkboxWrapperStyle={{ alignItems: "flex-start", marginTop: 4 }}
              placeholder={"Select team members"}
            />
            <div>
              <span className="margin-left-4">
                {!!formData.enable_distance_based_eligibility
                  ? "Issue per diem if the jobsite address is"
                  : "Enable distance-based eligibility"}
              </span>
              {!!formData.enable_distance_based_eligibility && (
                <>
                  <div className="flex margin-top-12">
                    <span style={{ width: "120px" }}>more than</span>
                    <Formblock
                      type="number"
                      name="minimum_jobsite_distance_from_home"
                      form={form}
                      control={control}
                      editing={true}
                      style={{ width: 70 }}
                    />
                    &nbsp; miles
                    <InfoButton text={"One way driving miles, calculated with mapbox.com"} place="top" />
                  </div>
                  <div className="flex">
                    <span style={{ width: "120px" }}>and less than</span>
                    <Formblock
                      type="number"
                      name="maximum_jobsite_distance_from_home"
                      form={form}
                      control={control}
                      editing={true}
                      style={{ width: 70 }}
                    />
                    &nbsp; miles from the
                  </div>
                  <Formblock
                    defaultValue={selectedAddressType}
                    onChange={(e) => {
                      setSelectedAddressType(e.target.value);
                    }}
                    type="radio"
                    name="address_type"
                    editing={true}
                    options={addressOptions}
                  />
                  {selectedAddressType === "other" && (
                    <Formblock
                      type="address"
                      name="other_address"
                      form={form}
                      editing={true}
                      defaultValue={selectedPerDiemRate?.other_address}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <ActionModal
      headerText={selectedPerDiemRate ? "Edit per diem rate" : "Create per diem rate"}
      showSubmit={true}
      showCancel={true}
      showDelete={true}
      deleteDisabled={selectedPerDiemRate == null}
      onDelete={handleDelete}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={handleSubmit(upsertPerDiemRate)}
      loading={loading}
      wrapperClassName="form two-column"
    >
      {selectedPerDiemRate && (
        <Banner
          type="warning"
          content="Updates to this per diem rate will also apply to other jobs with this same rate."
          className="margin-top-15"
        />
      )}
      {renderForm()}
    </ActionModal>
  );
};

export default PerDiemRateModal;
