import { usePermissionGroupOptions, useRefetchPaySchedules } from "dashboard/hooks/atom-hooks";
import { MiterAPI, PaySchedule } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useMemo, useState } from "react";
import { Button, Formblock } from "ui";

type Props = {
  ps: PaySchedule;
};

export const PaySchedulePermissions: React.FC<Props> = ({ ps }) => {
  const refetchPaySchedules = useRefetchPaySchedules();
  const permissionGroupOptions = usePermissionGroupOptions();

  const defaultPermissionGroups = useMemo(() => {
    return permissionGroupOptions.filter((pg) => ps.permission_groups_with_access?.includes(pg.value));
  }, [permissionGroupOptions, ps.permission_groups_with_access]);

  const [permissionGroups, setPermissionGroups] = useState(defaultPermissionGroups);
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    try {
      const permissionGroupsToSave = permissionGroups.length ? permissionGroups.map((pg) => pg.value) : null;
      const params = { permission_groups_with_access: permissionGroupsToSave };
      const response = await MiterAPI.pay_schedules.update(ps._id, params);
      if (response.error) throw new Error(response.error);

      await refetchPaySchedules();
      Notifier.success("Pay schedule permissions updated");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(
        `There was an error handling these updates: ${e.message}. Please refresh and try again.`
      );
    }
    setSaving(false);
  };

  return (
    <>
      <div className="vertical-spacer-small"></div>
      <div className="space-between payment-active-view-header" style={{ marginTop: 0, marginBottom: 15 }}>
        <div>Permissions</div>
        <Button text="Save" className="button-2 no-margin" onClick={handleSave} loading={saving} />
      </div>
      <Formblock
        label="Permission groups with access"
        labelInfo="Select the permission groups that can view and manage payroll for team members in this pay schedule. All super admins have access by default."
        type="multiselect"
        name={`permission_groups_with_access`}
        placeholder="Select a permission group"
        editing={true}
        className="modal wizard"
        options={permissionGroupOptions}
        value={permissionGroups}
        height="unset"
        onChange={(values) => setPermissionGroups(values)}
        disabled={saving}
      />
    </>
  );
};
