import { BulkTeamMemberSelectTeamMember } from "dashboard/components/team-members/BulkTeamMemberSelect";
import { AggregatedFile, AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { CreateESignatureRequestParams } from "backend/services/esignature-item-service";

const formatESignatureRequestsParams = (params: {
  signers: AggregatedTeamMember[];
  document_id: string;
  user_id: string;
  company_id: string;
}): CreateESignatureRequestParams[] => {
  const { company_id, user_id } = params;

  return params.signers
    .filter((signer) => !!signer)
    .map((signer) => ({
      company_id,
      status: "sent",
      requestor: {
        user_id,
      },
      parent_document_id: params.document_id,
      document_type: "file",
      signer: {
        user_id: signer.user,
        team_member_id: signer._id,
        name: signer.full_name,
        title: signer.title || "N/A",
        email: signer.email,
        type: "team_member",
      },
      events: [],
    }));
};

export const updateSignatureRequests = async (params: {
  signers: BulkTeamMemberSelectTeamMember[];
  document: AggregatedFile;
  companyId: string;
  userId: string;
}): Promise<AggregatedFile> => {
  // Get the team member IDs for the current signature requests on the document (if any)
  const existingSignatureRequests =
    "esignature_items" in params.document ? params.document.esignature_items : [];

  const existingSignatureRequestsTMIds = existingSignatureRequests.map((sr) => sr.signer.team_member_id!);

  // Get the team member IDs for the updated list of signature requests
  const selectedSigners = params.signers;
  const selectedSignerIds = selectedSigners.map((sr) => sr._id);

  // Get the newly added signers (team members) by checking what is in the updated list but not in the existing list
  const creatingSigners = selectedSigners.filter((sr) => !existingSignatureRequestsTMIds.includes(sr._id));

  // Convert the team members into signature request params
  const creatingSignatureRequests = formatESignatureRequestsParams({
    signers: creatingSigners as unknown as AggregatedTeamMember[],
    document_id: params.document._id,
    user_id: params.userId,
    company_id: params.companyId,
  });

  // Get the signature requests that exist on the document but aren't selected or are already signed
  const deletingSignatureRequests = existingSignatureRequests.filter(
    (sr) => !selectedSignerIds.includes(sr.signer.team_member_id!) || sr.status === "signed"
  );

  // Update the document's signature requests
  const res = await MiterAPI.files.update_esignature_requests(params.document._id, {
    create: creatingSignatureRequests,
    delete: deletingSignatureRequests.map((signer) => signer._id),
  });
  if (res.error) throw new Error(res.error);

  // Update the document in place so we don't have to refetch
  const updatedDoc = {
    ...params.document!,
    esignature_items: res,
  };

  return updatedDoc;
};
