import { LocationsTable } from "dashboard/components/locations/LocationsTable";
import React from "react";
import { Helmet } from "react-helmet";

type Props = {};

export const Locations: React.FC<Props> = () => {
  return (
    <div className="section-wrapper">
      <Helmet>
        <title>Locations | Miter</title>
      </Helmet>
      <LocationsTable />
    </div>
  );
};
