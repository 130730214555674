import AppContext from "dashboard/contexts/app-context";
import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui";

export const WarningSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const paymentWarningEnabled = activeCompany?.settings.payroll.enable_payment_warnings;
  const unapprovedTimeSheetWarningEnabled =
    activeCompany?.settings.payroll.enable_unapproved_timesheet_warning;
  const [paymentWarningSelected, setPaymentWarningSelected] = React.useState(paymentWarningEnabled);
  const [unapprovedTimeSheetWarningSelected, setTimesheetWarningSelected] = React.useState(
    unapprovedTimeSheetWarningEnabled
  );

  const handleChange = async (
    e: ChangeEvent<HTMLInputElement>,
    settingId: string,
    setSelected: Dispatch<SetStateAction<boolean | undefined>>
  ) => {
    setSelected(e.target.checked);
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { [settingId]: e.target.checked },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
  };

  return (
    <SettingsCard title="Warnings" contentStyle={{ color: "rgb(51,51,51)" }}>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={(e) =>
            handleChange(e, "settings.payroll.enable_payment_warnings", setPaymentWarningSelected)
          }
          checked={paymentWarningSelected}
        />
        <label>
          Enable payment warnings when running payroll. Payment warnings will flag potential mistakes related
          to a payment.
        </label>
      </div>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={(e) =>
            handleChange(
              e,
              "settings.payroll.enable_unapproved_timesheet_warning",
              setTimesheetWarningSelected
            )
          }
          checked={unapprovedTimeSheetWarningSelected}
        />
        <label>Enable warning when approving payroll with unapproved timesheets.</label>
      </div>
    </SettingsCard>
  );
};
