import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Formblock } from "ui";
import * as vals from "dashboard/utils/validators";

export const IntegrationLoginForm: React.FC<{
  integration: MiterIntegrationForCompany;
  onLogin: () => Promise<void>;
}> = ({ integration, onLogin }) => {
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const { getIntegrations } = useContext(AppContext);

  const isJonas = integration.key === "jonas";

  const login = async (data) => {
    if (!integration?.connection) return;
    setLoading(true);
    try {
      const { username, password } = data;
      const response = await MiterAPI.integrations.login_with_username_password({
        id: integration.connection?._id,
        username,
        password,
      });
      if (response.error) throw new Error(response.error);
      // In order, so status is updated before the page refreshes and shows the normal status page!
      await onLogin();
      await getIntegrations();
      Notifier.success(`Successfully logged in to the integration.`);
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error logging in: ${e.message}`);
    }
    setLoading(false);
  };

  return (
    <div style={{ border: "1px solid lightgray", padding: 20, borderRadius: 5 }}>
      <div className="vertical-spacer" />
      <Formblock
        label={"Username"}
        name="username"
        type="text"
        labelInfo={
          isJonas ? "This should be the Client ID, followed by a backslash, followed by the username" : ""
        }
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <Formblock
        label="Password"
        name="password"
        type="password"
        form={form}
        editing={true}
        style={{ maxWidth: 400 }}
        val={vals.required}
      />
      <div className="vertical-spacer-small"></div>
      <Button
        text="Connect"
        onClick={form.handleSubmit(login)}
        className="button-2 no-margin"
        loading={loading}
      />
    </div>
  );
};
