import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { Option } from "packages/ui/form/Input";
import { useCertificationTypeOptions } from "dashboard/hooks/atom-hooks";
import { CertificationOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const CertificationTaskContent: React.FC<ChecklistTaskContentProps<CertificationOnboardingTaskForm>> =
  ({ task, setTask }) => {
    const certificationOptions = useCertificationTypeOptions();

    const handleCertificationChange = (option: Option<string> | null) => {
      setTask({ ...task, data: { ...task.data, certification_type_id: option?.value } });
    };

    return (
      <div className={styles["team-member-checklist-task-content"]}>
        <Formblock
          type="select"
          name="certification_type_id"
          label="Select certification"
          editing={true}
          defaultValue={task.data.certification_type_id}
          options={certificationOptions}
          className="modal"
          onChange={handleCertificationChange}
          style={{ marginBottom: 0 }}
        />
        <DueDateForm task={task} setTask={setTask} />
      </div>
    );
  };
