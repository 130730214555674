import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import {
  CreateWorkplaceParams,
  MiterAPI,
  MiterError,
  UpdateWorkplaceParams,
  Workplace,
} from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { useActiveCompanyId, useSetWorkplaces } from "dashboard/hooks/atom-hooks";
import Banner from "../shared/Banner";
import { WorkplaceTableEntry } from "./WorkplacesTable";
import { CheckAddress } from "backend/utils/check/check-types";
import { SuggestedAlternative } from "./SuggestedAlternative";
import { displayFieldErrors } from "dashboard/utils/errors";

type Props = {
  workplace?: WorkplaceTableEntry;
  onFinish: (workplace: Workplace) => void;
  onHide: () => void;
};

export const WorkplaceModal: React.FC<Props> = ({ workplace, onFinish, onHide }) => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const setWorkplaces = useSetWorkplaces();
  const form = useForm();
  const { setValue } = form;

  // State
  const [loading, setLoading] = useState(false);
  const [suggestedAlternative, setSuggestedAlternative] = useState<CheckAddress | null>(null);

  const handleWorkplaceResponse = (res: Workplace & MiterError, action: "created" | "updated") => {
    if (res.error) {
      if (res.fields) {
        const mappedFields = res.fields.map((f) => ({ ...f, name: "address." + f.name }));
        displayFieldErrors(mappedFields, form.setError);
      }
      if (res.suggested_alternative) {
        setSuggestedAlternative(res.suggested_alternative);
      } else {
        throw new Error(res.error);
      }
    } else {
      Notifier.success(`Workplace ${action} successfully`);
      setWorkplaces((prev) => prev.concat(res));
      onFinish(res);
      onHide();
    }
  };

  const createWorkplace = async (data): Promise<void> => {
    if (!activeCompanyId || workplace) return;
    setLoading(true);

    try {
      const params: CreateWorkplaceParams = {
        company: activeCompanyId!,
        name: data?.name || null,
        keep_active: data?.keep_active,
        address: {
          ...data.address,
          state: data?.address?.state?.value || undefined,
        },
      };
      const res = await MiterAPI.workplaces.create(params);
      handleWorkplaceResponse(res, "created");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateWorkplace = async (data) => {
    if (!activeCompanyId || !workplace) return;
    setLoading(true);
    try {
      const params: UpdateWorkplaceParams = {
        company: activeCompanyId!,
        name: data?.name || null,
        address: {
          ...data.address,
          state: data?.address?.state?.value || undefined,
        },
        keep_active: data?.keep_active,
      };
      const res = await MiterAPI.workplaces.update(workplace._id, params);
      handleWorkplaceResponse(res, "updated");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const onConfirmSuggestedAlternative = async (): Promise<void> => {
    if (!suggestedAlternative) return;
    setValue("address.line1", suggestedAlternative.line1);
    setValue("address.line2", suggestedAlternative.line2);
    setValue("address.city", suggestedAlternative.city);
    setValue("address.state", { label: suggestedAlternative.state, value: suggestedAlternative.state });
    setValue("address.postal_code", suggestedAlternative.postal_code);
    setSuggestedAlternative(null);
    submit({ address: suggestedAlternative });
  };

  const submit = async (data) => {
    if (workplace) {
      await updateWorkplace(data);
    } else {
      await createWorkplace(data);
    }
  };

  return (
    <ActionModal
      headerText={`${workplace ? "Update" : "Create"} workplace`}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={form.handleSubmit(submit)}
      loading={loading}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        {workplace?.team_members?.length || workplace?.jobs?.length ? (
          <>
            <Banner
              content={
                "You are manually updating the details of a workplace associated with active jobs and/or team members."
              }
              type="warning"
            />
            <div className="vertical-spacer-small" />
          </>
        ) : (
          <></>
        )}
        <Formblock
          type="text"
          name="name"
          label="Name"
          labelInfo="Custom label to identify this workplace"
          form={form}
          editing={true}
          defaultValue={workplace?.name}
          className="modal"
        />
        <Formblock
          type="address"
          name="address"
          label="Address*"
          notRequiredRegister={form.register} // for line2
          defaultValue={workplace?.check_workplace?.address}
          className="modal"
          editing={true}
          form={form}
          val={vals.required} // for line1, city, zip
          required // for the state dropdown
        />
      </div>
      {suggestedAlternative && (
        <div className="flex" style={{ marginBottom: 15 }}>
          <SuggestedAlternative
            suggestedAlternative={suggestedAlternative}
            onConfirm={onConfirmSuggestedAlternative}
            onDismiss={() => setSuggestedAlternative(null)}
          />
        </div>
      )}
      <Formblock
        type="checkbox"
        name="keep_active"
        label="Force active"
        labelInfo="If unchecked, Miter will auto-deactivate unused workplaces each night to minimize your tax jurisdiction complexity. This box should only be checked in rare circumstances."
        text="Prevent workplace from being auto-deactivated"
        form={form}
        editing={true}
        defaultValue={workplace?.keep_active}
        className="modal"
      />
    </ActionModal>
  );
};
