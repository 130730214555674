import { MiterAPI } from "dashboard/miter";
import { buildAtomicMongoUpdateFromNested, Notifier } from "dashboard/utils";
import React from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useActiveCompany, useActiveCompanyId, useSetActiveCompany } from "dashboard/hooks/atom-hooks";
import { CPRGeneralSettings } from "./CPRGeneralSettings";
import { CPRPaymentsSettings } from "./CPRPayRateSettings";
import { CPRFormattingSettings } from "./CPRFormattingSettings";
import { useEnhancedSearchParams } from "miter-utils";
import { Toggler } from "ui/toggler";
import { CPRSettings as CPRSettingsType } from "backend/models/company/company-settings";

export const CPRSettings: React.FC = () => {
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const setActiveCompany = useSetActiveCompany();
  const cprSettings = activeCompany?.settings?.reports?.cpr;
  const form = useForm<CPRSettingsType>({ defaultValues: cprSettings });
  const { handleSubmit } = form;

  const backToCPR = () => {
    navigate("/reports/cpr");
  };

  const updateSettings = async (data: CPRSettingsType) => {
    try {
      const update = buildAtomicMongoUpdateFromNested({ "settings.reports.cpr": data });
      const response = await MiterAPI.companies.update(activeCompanyId!, update);
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      setActiveCompany(response);
    } catch (e) {
      Notifier.error("There was an error updating certified payroll settings. We're looking into it!");
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const togglerState = parsedSearchParams.sub || "general";

  const togglerConfig = [
    { label: "General", path: "general" },
    { label: "Payments", path: "payments" },
    { label: "Formatting", path: "formatting" },
  ];

  const handleToggle = (path: string) => {
    setSearchParams({ sub: path });
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Certified Payroll Settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={backToCPR}>
          Back to certified payroll
        </div>
        <div className="flex">
          <h1>Certified payroll settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure how your certified payroll report gets generated</div>
        <div className="vertical-spacer-small"></div>

        <div className="toggler-vertical-container">
          <Toggler
            config={togglerConfig}
            toggle={handleToggle}
            active={togglerState}
            type="vertical"
            wrapperStyle={{ marginTop: 20 }}
          />
          <div className="toggler-right-content">
            {togglerState === "general" && (
              <CPRGeneralSettings
                form={form}
                settings={cprSettings}
                updateSettings={updateSettingsDebounced}
              />
            )}
            {togglerState === "payments" && (
              <CPRPaymentsSettings
                form={form}
                settings={cprSettings}
                updateSettings={updateSettingsDebounced}
              />
            )}
            {togglerState === "formatting" && (
              <CPRFormattingSettings
                form={form}
                settings={cprSettings}
                updateSettings={updateSettingsDebounced}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
