import { useMemo } from "react";
import { useActiveTeam } from "./atom-hooks";

export const usePolicyLevelsWithEnrollees = (policyId?: string): Set<string> => {
  const team = useActiveTeam();

  const levelsWithEnrollees = useMemo(() => {
    if (!policyId) return new Set<string>();
    const arr = team
      .flatMap((employee) => employee.time_off?.policies)
      .filter((policy) => policy.policy_id === policyId && !!policy.level_id)
      .map((p) => p.level_id!);

    return new Set(arr);
  }, [team, policyId]);

  return levelsWithEnrollees;
};
