import { AggregatedFillableTemplate, FillableTemplate } from "dashboard/types/fillable-template-types";
import { FC, useCallback, useEffect, useState } from "react";
import { Notifier, Wizard } from "ui";
import { UploadTemplateWizardScreen } from "./UploadTemplateWizardScreen";
import { TemplateEditorWizardScreen } from "./TemplateEditorWizardScreen";
import { MapFillableInputsWizardScreen } from "./MapFillableInputsWizardScreen";
import { ConfirmFillableInputsWizardScreen } from "./ConfirmFillableInputsWizardScreen";
import { MiterAPI } from "dashboard/miter";
import { generateFillableInputFields } from "dashboard/utils/fillable-templates";

type Props = {
  fillableTemplate?: AggregatedFillableTemplate;
  onExit: () => void;
  onComplete: () => void;
};

export const FillableTemplateWizard: FC<Props> = ({ fillableTemplate, onExit, onComplete }) => {
  const [activeFillableTemplate, setActiveFillableTemplate] = useState<
    AggregatedFillableTemplate | undefined
  >(fillableTemplate);

  useEffect(() => {
    setActiveFillableTemplate(fillableTemplate);
  }, [fillableTemplate]);

  /**
   * This onExit side-effect ensures that Anvil inputs and Miter inputs are in sync.
   * This function is required if the customer early exits from the creation wizard flow.
   * This is a hack and can be deleted once Anvil supports embdedded metadata modals.
   */
  const syncAnvilTemplateInputs = useCallback(async () => {
    try {
      if (!activeFillableTemplate) return;
      const response = await MiterAPI.fillable_templates.template_inputs({ id: activeFillableTemplate._id });
      if (response.error) {
        throw new Error(response.error);
      }

      // Could not get React State to work (nor did jotai), it was always referring to old state, so we just refetch here.
      const updateFillableTemplate = await MiterAPI.fillable_templates.retrieve_many([
        {
          field: "_id",
          value: activeFillableTemplate._id,
          type: "_id",
        },
      ]);

      if (updateFillableTemplate.error || !updateFillableTemplate[0]) {
        throw new Error(updateFillableTemplate.error || "Fillable template not found.");
      }

      const calculatedTemplateInputs = generateFillableInputFields({
        fillableTemplate: updateFillableTemplate[0],
        anvilFormInputs: response,
        forceFill: true,
      });
      const res = await MiterAPI.fillable_templates.update({
        id: activeFillableTemplate._id,
        update: {
          inputs: calculatedTemplateInputs as FillableTemplate["inputs"],
        },
      });

      if (res.error) {
        throw new Error(res.error);
      }
    } catch (error: $TSFixMe) {
      Notifier.error(error.message);
    }
  }, [activeFillableTemplate]);

  const exitWithSync = useCallback(async () => {
    await syncAnvilTemplateInputs();
    onExit();
  }, [onExit, syncAnvilTemplateInputs]);

  return (
    <Wizard onExit={exitWithSync} onComplete={onComplete}>
      <UploadTemplateWizardScreen
        fillableTemplate={activeFillableTemplate}
        name="Upload template"
        setFillableTemplate={setActiveFillableTemplate}
      />
      {activeFillableTemplate && (
        <TemplateEditorWizardScreen
          fillableTemplate={activeFillableTemplate}
          name="Drag and drop inputs"
          setFillableTemplate={setActiveFillableTemplate}
        />
      )}
      {activeFillableTemplate && (
        <MapFillableInputsWizardScreen
          fillableTemplate={activeFillableTemplate}
          name="Describe input fields"
          setFillableTemplate={setActiveFillableTemplate}
        />
      )}
      {activeFillableTemplate && (
        <ConfirmFillableInputsWizardScreen fillableTemplate={activeFillableTemplate} name="Confirm inputs" />
      )}
    </Wizard>
  );
};
