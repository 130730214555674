import React from "react";
import styles from "../../not-found/NotFound.module.css";
import ExpiredIcon from "../../../assets/404.svg";

const OfferExpired: React.FC = () => {
  return (
    <div className={styles["not-found-container"]}>
      <div className={styles["not-found"]}>
        <img src={ExpiredIcon} alt="Offer Expired" />
        <h1>This offer has expired.</h1>
        <p style={{ width: "80vw" }} className={styles["subtitle"]}>
          We&apos;re sorry, but the offer letter you&apos;re trying to access has expired. If you believe this
          is an error, please contact the hiring manager or HR representative.
        </p>
      </div>
    </div>
  );
};

export default OfferExpired;
