import React, { useState } from "react";
import { ActionModal, Formblock } from "ui";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { useUser } from "dashboard/hooks/atom-hooks";

type Props = {
  headerText: string;
  payload: $TSFixMe;
  hide: () => void;
  handleNewSignUrl: (e: string) => void;
};

export const SignCPRModal: React.FC<Props> = ({ hide, payload, headerText, handleNewSignUrl }) => {
  const user = useUser();

  const [loading, setLoading] = useState(false);
  const form = useForm();

  const sign = async (data) => {
    setLoading(true);
    try {
      const newPayload = payload;
      newPayload.params.signer_name = data.signer_name;
      newPayload.params.signer_title = data.signer_title;
      newPayload.params.signer_email = user!.email;
      newPayload.format = "generate_sign_url";
      const response = await MiterAPI.reports.create(newPayload);
      if (response.error) throw new Error(response.error);
      handleNewSignUrl(response.url);
    } catch (e) {
      Notifier.error("There was an error handling the e-signature. We're looking into it!");
    }
    setLoading(false);
  };

  return (
    <div>
      <ActionModal
        submitText="Sign"
        onSubmit={form.handleSubmit(sign)}
        headerText={headerText}
        onHide={hide}
        showSubmit={true}
        onCancel={hide}
        showCancel={true}
        loading={loading}
      >
        <div className="vertical-spacer"></div>
        <Formblock
          label="Signer name"
          register={form.register(vals.required)}
          type="text"
          name="signer_name"
          className="modal"
          errors={form.errors}
          editing={true}
        />
        <Formblock
          label="Signer title"
          register={form.register(vals.required)}
          type="text"
          name="signer_title"
          className="modal"
          errors={form.errors}
          editing={true}
        />
        <div className="vertical-spacer"></div>
      </ActionModal>
    </div>
  );
};
