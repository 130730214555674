import { MiterAPI } from "../../miter";
import Notifier from "../..//utils/notifier";
import React, { ReactNode, useState } from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import styles from "./auth.module.css";
import { Loader } from "ui";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuthToken } from "dashboard/hooks/atom-hooks";

const Login: React.FC = () => {
  const authToken = useAuthToken();
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const loginUser = async (): Promise<void> => {
    setLoading(true);
    try {
      const formattedEmail = email.toLowerCase().trim();

      const res = await MiterAPI.users.sendMagicLink(formattedEmail);
      if (res.error) {
        // Navigate to the team portal login page if the user is a team member
        if (res.error_type === "redirect_team_member") {
          Notifier.error(
            `You don't have access to this page. Please log in at ${process.env.REACT_APP_TEAM_PORTAL_URL}. You will be redirected in a few seconds.`
          );
          setTimeout(() => {
            window.location.replace(process.env.REACT_APP_TEAM_PORTAL_URL + "/login");
          }, 3000);
          return;
        }
        throw new Error(res.error);
      }

      setSubmitted(true);
      Notifier.success("Check your inbox for your magic link!");
      setLoading(false);
    } catch (e: $TSFixMe) {
      console.log("Error logging user in", e);
      Notifier.error(e.message);
      setLoading(false);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    loginUser();
  };

  const handleEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
  };

  const renderLoginForm: React.ReactNode = (() => {
    let buttonNode: ReactNode;

    if (submitted) {
      buttonNode = "Magic link sent!";
    } else if (loading) {
      buttonNode = <Loader className="button auth" />;
    } else {
      buttonNode = "Login";
    }

    return (
      <div className={styles["login-form"]}>
        <form onSubmit={handleSubmit}>
          {!submitted && (
            <input
              className={styles["login-input"]}
              name="email"
              type="email"
              placeholder={"admin@contractor.com"}
              onChange={handleEmail}
              required
            />
          )}
          {
            <button type="submit" className={styles["login-submit"]} disabled={submitted || loading}>
              {buttonNode}
            </button>
          }
        </form>
      </div>
    );
  })();

  if (authToken) return <Navigate to={"/home"} replace />;

  return (
    <div className={styles["login-wrapper"]}>
      <Helmet>
        <title>Login | Miter</title>
      </Helmet>
      <div className={styles["login-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["login"]}>
          <div className={styles["login-header"]}>
            <h1>Miter Admin Dashboard</h1>
            <p>Enter your email below to login.</p>
          </div>
          {renderLoginForm}
        </div>
      </div>
    </div>
  );
};

export default Login;
