import React, { useState } from "react";
import mastercard from "dashboard/assets/mastercard.png";
import visa from "dashboard/assets/visa.png";
import amex from "dashboard/assets/amex.png";
import alert from "dashboard/assets/alert.png";
import link from "dashboard/assets/stripe-link-logo.svg";
import { GetCompanyBillingInfoResponse, MiterAPI } from "dashboard/miter";
import { Button, Notifier } from "ui";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { Bank } from "phosphor-react";
type Props = {
  info: GetCompanyBillingInfoResponse;
};

const PaymentMethodInfo: React.FC<Props> = ({ info }) => {
  const activeCompanyId = useActiveCompanyId();
  let cardImage: string | null = null;
  let last4: string | null | undefined = null;
  let pmLabel = "";
  let methodLabel: string | null = "";
  if (info.payment_method?.us_bank_account) {
    pmLabel = "Bank account";
    methodLabel = info.payment_method.us_bank_account.bank_name;
    last4 = info.payment_method.us_bank_account.last4;
  } else if (info.payment_method?.card) {
    const { brand } = info.payment_method.card;
    if (brand === "visa") cardImage = visa;
    if (brand === "mastercard") cardImage = mastercard;
    if (brand === "amex") cardImage = amex;
    last4 = info.payment_method.card.last4;
    pmLabel = "Card";
  } else if (info.source?.card) {
    const { brand } = info.source.card;
    if (brand === "Visa") cardImage = visa;
    if (brand === "MasterCard") cardImage = mastercard;
    if (brand === "American Express") cardImage = amex;
    last4 = info.source.card.last4;
    pmLabel = "Card";
  } else if (info.payment_method?.link) {
    methodLabel = info.payment_method.link.email;
    cardImage = link;
    pmLabel = "Link";
  }

  const [isLoadingPortal, setIsLoadingPortal] = useState<boolean>(false);

  const goToBillingPortal = async () => {
    setIsLoadingPortal(true);
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.companies.get_customer_portal_link(activeCompanyId);
      if (response.error) throw new Error(response.error);
      window.open(response.url, "_self");
    } catch (e) {
      Notifier.error("There was an error loading the billing portal. We're looking into it.");
      console.error(e);
    }
    setIsLoadingPortal(false);
  };

  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Payment method</div>
        {!info.source && !info.payment_method && <img style={{ marginLeft: 8, height: 13 }} src={alert} />}
      </div>
      <div className="flex" style={{ height: 40, marginTop: 15 }}>
        {pmLabel ? (
          <>
            <div style={{ marginRight: 30, color: "gray" }}>{pmLabel}</div>
            <div className="flex">
              {cardImage ? <img style={{ height: 20 }} src={cardImage} /> : <Bank color={"darkgray"} />}
              {methodLabel ? <span style={{ marginLeft: 8 }}>{methodLabel}</span> : null}
              {last4 ? (
                <>
                  <span style={{ marginLeft: 8, fontSize: 3 }}>{"\u2B24 \u2B24 \u2B24 \u2B24"}</span>
                  <span style={{ marginLeft: 5 }}>{last4}</span>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <div style={{ color: "darkgray" }}>You have not added a payment method yet.</div>
        )}

        <div className="flex-1"></div>
        <Button
          className="button-2 no-margin"
          onClick={() => goToBillingPortal()}
          loading={isLoadingPortal}
          text="Update payment method"
        />
      </div>
    </div>
  );
};

export default PaymentMethodInfo;
