import { FlatfileResults } from "@flatfile/react";
import {
  useActiveCompany,
  useActiveCompanyId,
  useActiveJobs,
  useActivities,
  useClasses,
  useClassificationOptions,
  useCostTypes,
  useDepartmentOptions,
  useDepartments,
  useEquipment,
  useJobOptions,
  useLocations,
  useLookupDepartment,
  useLookupJob,
  useSelectableActivitiesMap,
  useTeam,
  useTimeOffPolicyOptions,
  useWcCodeOptions,
} from "dashboard/hooks/atom-hooks";
import { normalizeDate, buildCustomFieldColumns, buildCustomFieldValues } from "dashboard/utils/flatfile";
import React, { useContext, useMemo, useCallback } from "react";
import { Notifier } from "ui";
import { keyBy } from "lodash";
import { parseClockInTime, timezoneOptions } from "miter-utils";
import { DateTime } from "luxon";
import { AggregatedJob, CreateTimesheetParams, MiterAPI, Timesheet } from "dashboard/miter";
import { ImportField, Importer } from "../importer/Importer";
import { DraftTimesheet } from "dashboard/pages/timesheets/BulkCreateTimesheets/BulkCreateTimesheets";
import { TimesheetPolicyField } from "backend/models/policy";
import { useTimesheetPolicies } from "dashboard/utils/policies/timesheet-policy-utils";
import AppContext from "dashboard/contexts/app-context";
import {
  useValidateClassificationOverride,
  useValidateClockInTime,
  useValidateCostCode,
  useValidateCostTypeCode,
  useValidateEquipmentCodes,
  useValidateHours,
  useValidateJobCode,
  useValidateNotes,
  useValidateTeamMemberID,
  useValidateTimeOffPolicy,
} from "dashboard/utils/timesheetUtils";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";
import { useImportValidators } from "dashboard/hooks/flatfile-import/useImportValidators";
import { useOptionalDimensionsUsage } from "dashboard/hooks/dimensions-gating";

export type PrelimTimesheetImportRow = {
  teamMemberId: string;
  date: string;
  hours: string;
  clockIn?: string;
  timezone?: string;
  jobCode?: string;
  jobId?: string;
  costCode?: string;
  costTypeCode?: string;
  timeType?: "REG" | "OT" | "DOT" | "HOL" | "PTO" | "SICK";
  timeOffPolicyId?: string;
  classificationOverride?: string;
  notes?: string;
  departmentCode?: string;
  departmentName?: string;
  classCode?: string;
  locationCode?: string;
  status?: Timesheet["status"];
  wcCode?: string;
  equipmentCodes?: string;

  // Custom fields
  [key: string]: string | number | undefined | string[];
};

type Props = {
  onFinish: () => void;
};

export const TimesheetImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const { customFields } = useContext(AppContext);

  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const activeJobs = useActiveJobs();
  const activities = useActivities();
  const locations = useLocations();
  const classes = useClasses();
  const timeOffPolicyOptions = useTimeOffPolicyOptions();
  const { buildPolicy } = useTimesheetPolicies();

  const { hasDepartments, hasLocations, hasClasses, hasCostTypes } = useOptionalDimensionsUsage();

  /** Need to do useTeam instead of useActiveTeam so we can upload timesheets for terminated team members */
  const teamMembers = useTeam();
  const costTypes = useCostTypes();
  const departments = useDepartments();
  const departmentOptions = useDepartmentOptions();
  const jobOptions = useJobOptions();
  const allClassificationOptions = useClassificationOptions({});

  const wcCodeOptions = useWcCodeOptions();
  const equipment = useEquipment();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  // flatfile validator hooks
  const validateTeamMemberID = useValidateTeamMemberID();
  const validateHours = useValidateHours();
  const validateClockInTime = useValidateClockInTime();
  const validateJobCode = useValidateJobCode();
  const validateCostCode = useValidateCostCode();
  const { validateClassCode, validateLocationCode } = useImportValidators();

  const validateCostTypeCode = useValidateCostTypeCode();
  const validateNotes = useValidateNotes();
  const validateTimeOffPolicy = useValidateTimeOffPolicy();
  const validateClassificationOverride = useValidateClassificationOverride();
  const validateEquipmentCodes = useValidateEquipmentCodes();

  const timesheetsCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "timesheet"),
    [customFields]
  );

  const lookupJobCode = useMemo(() => keyBy(activeJobs, "code"), [activeJobs]);
  const lookupTeamID = useMemo(() => keyBy(teamMembers, "friendly_id"), [teamMembers]);
  const lookupCostTypeCode = useMemo(() => keyBy(costTypes, "code"), [costTypes]);
  const lookupActivityCode = useMemo(() => keyBy(activities, "cost_code"), [activities]);
  const lookupEquipmentCode = useMemo(() => keyBy(equipment, "code"), [equipment]);
  const lookupLocationCode = useMemo(() => keyBy(locations, "external_id"), [locations]);
  const lookupClassCode = useMemo(() => keyBy(classes, "code"), [classes]);
  const lookupJob = useLookupJob();
  const lookupActivity = useSelectableActivitiesMap();
  const lookupDept = useLookupDepartment();

  const enableAdminWcCode = activeCompany?.settings?.timesheets.enable_admin_wc_code;

  const departmentCodeOptions = useMemo(() => {
    return departments
      .filter((d) => d.identifier)
      .map((d) => {
        return {
          label: d.identifier!,
          value: d._id,
        };
      });
  }, [departments]);

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/

  /** Build clock in from date + timezone + clock in time */
  const buildClockIn = (date: string, timezone: string | undefined, clockIn: string | undefined) => {
    const { hour, minute, second } = parseClockInTime(clockIn, activeCompany);

    return DateTime.fromISO(date, { zone: timezone }).set({
      hour,
      minute,
      second,
      millisecond: 0,
    });
  };

  const buildEarningTypeParams = (
    row: PrelimTimesheetImportRow
  ): Pick<Timesheet, "earning_type" | "time_off_policy_id"> => {
    const { timeType } = row;
    let earningType: Timesheet["earning_type"];
    if (timeType === "REG") {
      earningType = "hourly";
    } else if (timeType === "OT") {
      earningType = "overtime";
    } else if (timeType === "DOT") {
      earningType = "double_overtime";
    } else if (timeType === "HOL") {
      earningType = "paid_holiday";
    } else if (timeType === "PTO") {
      earningType = "pto";
    } else if (timeType === "SICK") {
      earningType = "sick";
    }
    return { earning_type: earningType };
  };

  const buildDraftTimesheet = useCallback(
    (row: PrelimTimesheetImportRow): DraftTimesheet => {
      const team_member_id = row.teamMemberId ? lookupTeamID[row.teamMemberId]?._id : undefined;
      const job_id = row.jobCode ? lookupJobCode[row.jobCode]?._id : undefined;
      const activity_id = row.costCode ? lookupActivityCode[row.costCode]?._id : undefined;
      const cost_type_id = row.costTypeCode ? lookupCostTypeCode[row.costTypeCode]?._id : undefined;

      const equipmentCodeArray = row.equipmentCodes
        ? row.equipmentCodes?.split(",").map((code) => code.trim())
        : [];
      const equipmentIds = equipmentCodeArray
        .map((code) => lookupEquipmentCode[code]?._id)
        .filter((id): id is string => id !== undefined);

      // Unlike job and activity, department is actually a dropdown, so departmentCode is actually the underlying _id
      const department_id = lookupDept(row.departmentCode)?._id;

      return {
        team_member: team_member_id,
        date: DateTime.fromISO(row.date),
        hours: Number(row.hours),
        clock_in_time: row.clockIn,
        job: job_id,
        activity: activity_id,
        cost_type_id: cost_type_id,
        department_id,
        notes: row.notes,
        equipment_ids: equipmentIds,
      };
    },
    [lookupActivityCode, lookupCostTypeCode, lookupDept, lookupEquipmentCode, lookupJobCode, lookupTeamID]
  );

  const buildJobId = (row: PrelimTimesheetImportRow): AggregatedJob | undefined => {
    if (row.jobCode) {
      return lookupJobCode[row.jobCode];
    } else if (row.jobId) {
      return lookupJob(row.jobId);
    } else {
      return undefined;
    }
  };

  const buildTimesheetParams = (row: PrelimTimesheetImportRow): CreateTimesheetParams => {
    if (!activeCompanyId) throw new Error("No active company ID");
    const {
      teamMemberId,
      date,
      status,
      hours,
      clockIn,
      timezone,
      costCode,
      notes,
      departmentCode,
      departmentName,
      locationCode,
      classCode,
      wcCode,
      equipmentCodes,
    } = row;

    const teamMember = lookupTeamID[teamMemberId];
    if (!teamMember) throw new Error(`Team member ${teamMemberId} not found`);

    const job = buildJobId(row);
    const activity = costCode ? lookupActivity.get(job?._id).find((a) => a.cost_code === costCode) : null;

    // Unlike job and activity, department is actually a dropdown, so both departmentCode and departmentName are actually the underlying _id
    const dept = lookupDept(departmentCode || departmentName);

    const classObj = classCode ? lookupClassCode[classCode] : null;
    const location = locationCode ? lookupLocationCode[locationCode] : null;

    const finalTimezone = timezone || job?.timezone || teamMember?.timezone || activeCompany?.timezone;
    const clockInDT = buildClockIn(date, finalTimezone, clockIn);
    const clockOutDT = clockInDT.plus({ hours: Number(hours) });

    const earningTypeParams = buildEarningTypeParams(row);
    const customFieldValues = buildCustomFieldValues(row, timesheetsCustomFields);

    const equipmentCodeArray = equipmentCodes ? equipmentCodes.split(",").map((code) => code.trim()) : [];
    const equipmentIds = equipmentCodeArray
      .map((code) => lookupEquipmentCode[code]?._id)
      .filter((id): id is string => id !== undefined);

    return {
      company: activeCompanyId,
      team_member: teamMember._id,
      clock_in: clockInDT.toSeconds(),
      clock_out: clockOutDT.toSeconds(),
      job: job?._id,
      activity: activity?._id,
      creation_method: "bulk_import",
      status: status || undefined,
      notes,
      timezone: finalTimezone,
      time_off_policy_id: row.timeOffPolicyId,
      classification_override: row.classificationOverride,
      department_id: dept?._id,
      class_id: classObj?._id,
      location_id: location?._id,
      wc_code: wcCode,
      custom_field_values: customFieldValues,
      equipment_ids: equipmentIds,
      ...earningTypeParams,
    };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedTimesheets = results.validData.map(buildTimesheetParams);

      const response = await MiterAPI.timesheets.import({
        clean_inputs: preppedTimesheets,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;
      const warnings = response.results.warnings.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} timesheets with ${errors} errors and ${warnings} warnings.`);
        } else {
          Notifier.success(`Imported ${successes} timesheets with ${warnings} warnings.`);
        }
      } else {
        Notifier.error(`There were ${errors} errors and ${warnings} warnings.`);
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the timesheets.");
    }
  };

  const isFieldRequired = useCallback(
    (row, field: TimesheetPolicyField): boolean => {
      const shouldBuildPolicy = isFullRow(row);

      if (shouldBuildPolicy) {
        const draftTimesheet = buildDraftTimesheet(row);
        const { isFieldRequired: policyIsFieldRequired } = buildPolicy(draftTimesheet);
        return policyIsFieldRequired(field);
      }

      return false;
    },
    [buildDraftTimesheet, buildPolicy]
  );

  /**********************************************************************************************************
   * Custom fields
   **********************************************************************************************************/

  const customFieldColumns = useMemo(
    () => buildCustomFieldColumns(timesheetsCustomFields),
    [timesheetsCustomFields]
  );

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Employee ID number",
        type: "string",
        key: "teamMemberId",
        description: "Unique identifer for team member (must be same in source system and Miter).",
        validators: [{ validate: "required" }],
        hook: (val) =>
          typeof val === "string" ? validateTeamMemberID({ teamMemberId: val }) : validateTeamMemberID(val),
      },
      {
        label: "Date",
        type: "string",
        key: "date",
        description: "The date work was performed, in YYYY-MM-DD format.",
        validators: [{ validate: "required" }],
        hook: (val) => (typeof val === "string" ? normalizeDate(val) : normalizeDate(val.date)),
      },
      {
        label: "Status",
        type: "select",
        key: "status",
        description: "The status of the timesheet.",
        options: [
          { label: "Unapproved", value: "unapproved" },
          { label: "Approved", value: "approved" },
        ],
      },
      {
        label: "Hours",
        type: "string",
        key: "hours",
        description: "Specifies the hours worked for the timesheet.",
        validators: [{ validate: "required" }],
        hook: (val) => (typeof val === "string" ? validateHours({ hours: val }) : validateHours(val)),
      },
      {
        label: "Clock-in time",
        type: "string",
        key: "clockIn",
        description: `Example: "09:30" or "17:30". If left blank, Miter will assume 9am clock-in`,
        hook: (val) =>
          typeof val === "string" ? validateClockInTime({ clockIn: val }) : validateClockInTime(val),
      },
      {
        label: "Time zone",
        type: "select",
        key: "timezone",
        description: `TZ identifier (i.e., "America/Los_Angeles"). If left blank, Miter will use the timezone of the job or employee`,
        options: timezoneOptions,
      },
      {
        label: "Job code",
        type: "string",
        key: "jobCode",
        description: "Unique identifer for a job (must be same in source system and Miter)",
        hook: (val) =>
          typeof val === "string"
            ? validateJobCode({ jobCode: val }, isFieldRequired)
            : validateJobCode(val, isFieldRequired),
      },
      {
        label: "Job name",
        type: "select",
        key: "jobId",
        description: "The unique job name.",
        options: jobOptions,
      },
      {
        label: "Cost code",
        type: "string",
        key: "costCode",
        description: "Unique identifer for a cost code (must be same in source system and Miter)",
        hook: (val) =>
          typeof val === "string"
            ? validateCostCode({ costCode: val, jobCode: undefined }, isFieldRequired)
            : validateCostCode(val, isFieldRequired),
      },
      ...(hasCostTypes
        ? [
            {
              label: "Cost type code",
              type: "string" as const,
              key: "costTypeCode",
              description: "Unique identifer for a cost type (must be same in source system and Miter)",
              hook: (val) =>
                typeof val === "string"
                  ? validateCostTypeCode({ costTypeCode: val })
                  : validateCostTypeCode(val),
            },
          ]
        : []),
      {
        label: "Time type",
        type: "select",
        key: "timeType",
        description: "If left blank, Miter will automatically determine whether the timesheet is OT.",
        options: [
          { label: "REG", value: "REG" },
          { label: "OT", value: "OT" },
          { label: "DOT", value: "DOT" },
          { label: "HOL", value: "HOL" },
          { label: "PTO", value: "PTO" },
          { label: "SICK", value: "SICK" },
        ],
      },
      {
        label: "Notes",
        type: "string",
        key: "notes",
        description: "Notes for the timesheet",
        hook: (val) =>
          typeof val === "string"
            ? validateNotes({ notes: val }, isFieldRequired)
            : validateNotes(val, isFieldRequired),
      },
      {
        label: "Time off policy",
        type: "select",
        key: "timeOffPolicyId",
        description: "The time off policy who's balance will be affected by this timesheet.",
        options: timeOffPolicyOptions,
        hook: (val) =>
          typeof val === "string"
            ? validateTimeOffPolicy({ timeOffPolicyId: val })
            : validateTimeOffPolicy(val),
      },
      {
        label: "Classification",
        type: "select",
        key: "classificationOverride",
        description: "The classification to override the default classification for this timesheet.",
        options: allClassificationOptions,
        hook: (val) =>
          typeof val === "string"
            ? validateClassificationOverride({ classificationOverride: val })
            : validateClassificationOverride(val),
      },
    ];

    if (hasDepartments) {
      fieldList.push(
        {
          label: "Department code",
          type: "select",
          key: "departmentCode",
          description: "The unique department identifier/code.",
          options: departmentCodeOptions,
        },
        {
          label: "Department name",
          type: "select",
          key: "departmentName",
          description: "The unique department name.",
          options: departmentOptions,
        }
      );
    }

    if (hasLocations) {
      fieldList.push({
        label: "Location code",
        type: "string",
        key: "locationCode",
        description: "Unique identifer for a Location (must be same in source system and Miter)",
        hook: (val) =>
          typeof val === "string" ? validateLocationCode(val) : validateLocationCode(val.locationCode),
      });
    }

    if (hasClasses) {
      fieldList.push({
        label: "Class code",
        type: "string",
        key: "classCode",
        description: "Unique identifer for a Class (must be same in source system and Miter)",
        hook: (val) => (typeof val === "string" ? validateClassCode(val) : validateClassCode(val.classCode)),
      });
    }

    if (hasAccessToEquipmentTracking) {
      fieldList.push({
        label: "Equipment codes",
        type: "string",
        key: "equipmentCodes",
        description: "Comma separated list of equipment codes.",
        hook: (val) =>
          typeof val === "string"
            ? validateEquipmentCodes({ equipmentCodes: val }, isFieldRequired)
            : validateEquipmentCodes(val, isFieldRequired),
      });
    }

    if (enableAdminWcCode) {
      fieldList.push({
        label: "Workers comp code",
        type: "select",
        key: "wcCode",
        description: "The workers comp code for this timesheet.",
        options: wcCodeOptions,
      });
    }

    fieldList.push(...customFieldColumns);
    return fieldList;
  }, [
    jobOptions,
    hasCostTypes,
    timeOffPolicyOptions,
    allClassificationOptions,
    hasDepartments,
    hasLocations,
    hasClasses,
    hasAccessToEquipmentTracking,
    enableAdminWcCode,
    customFieldColumns,
    validateTeamMemberID,
    validateHours,
    validateClockInTime,
    validateJobCode,
    isFieldRequired,
    validateCostCode,
    validateCostTypeCode,
    validateNotes,
    validateTimeOffPolicy,
    validateClassificationOverride,
    departmentCodeOptions,
    departmentOptions,
    validateLocationCode,
    validateClassCode,
    validateEquipmentCodes,
    wcCodeOptions,
  ]);

  return <Importer id="timesheets" resource="timesheets" onSave={handleSubmit} fields={fields} />;
};

/** Type guard to ensure that the row is a full row (i.e. it has a team member ID, date, and hours) */
export const isFullRow = (
  row:
    | PrelimTimesheetImportRow
    | { timeOffPolicyId: string | undefined }
    | { classificationOverride: string | undefined }
    | undefined
): row is PrelimTimesheetImportRow => {
  if (!row) return false;
  return "teamMemberId" in row && "date" in row && "hours" in row;
};
