import React, { useMemo, useState, useCallback } from "react";
import { AggregatedExpense, AggregatedJob, Job } from "dashboard/miter";
import { FilePicker, Formblock, Loader } from "ui";
import { useExpenseAbilities } from "dashboard/hooks/abilities-hooks/useExpenseAbilities";
import {
  EXPENSE_ATTACHMENT_ACCEPTED_FILE_TYPES,
  EXPENSE_ATTACHMENT_MAX_FILE_SIZE,
  EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED,
  glAccountSelectionOptions,
} from "../expenseUtils";
import {
  useTeamOptions,
  useDepartmentOptions,
  useJobOptions,
  useCostTypeOptions,
  useActivityOptions,
  useLedgerAccountOptions,
  useActiveCompanyId,
  useIsSuperAdmin,
  useActivityOptionsMap,
  useLookupDepartment,
  useCardTransactionCategoryOptions,
  useLookupCardTransactionCategories,
} from "dashboard/hooks/atom-hooks";
import { isExpenseScoped, useAccessFilterScoped } from "dashboard/pages/activities/activityUtils";
import { UpdateExpenseParams } from "backend/services/expenses/expense-service";
import { FilePickerFile } from "ui/form/FilePicker";
import { FileUploadParams, updateFiles } from "miter-utils";
import { useExpensePolicy } from "dashboard/utils/policies/expense-policy-utils";
import InfoButton from "dashboard/components/information/information";
import { JobInput } from "dashboard/components/shared/JobInput";
import { Option } from "ui/form/Input";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";
import { isJobSpendManagementScoped } from "../../jobs/jobUtils";
import { useOptionalDimensionsUsage } from "dashboard/hooks/dimensions-gating";

type Props = {
  expense: AggregatedExpense;
  handleExpenseValueChange: (updateExpenseParam: UpdateExpenseParams) => Promise<void>;
  refreshExpense: () => void;
  inboxMode?: InboxMode;
};
export const CardTransactionsModalForm: React.FC<Props> = ({
  expense,
  handleExpenseValueChange,
  refreshExpense,
  inboxMode,
}) => {
  const isSuperAdmin = useIsSuperAdmin();
  const { cannot, teamPredicate, jobPredicate } = useExpenseAbilities({ inboxMode });
  const { cannot: globalAbilitiesCannot } = useMiterAbilities();

  const { hasDepartments, hasCostTypes } = useOptionalDimensionsUsage();

  const { isFieldRequired, isFieldLocked, isFieldHidden } = useExpensePolicy(expense);

  const activeCompanyId = useActiveCompanyId();
  const activityOptionsMap = useActivityOptionsMap();

  const teamOptions = useTeamOptions({
    defaultValue: expense?.team_member?._id,
    predicate: teamPredicate("update"),
  });

  const { isAccessFilterScoped } = useAccessFilterScoped({
    teamMember: expense?.team_member,
  });

  const lookupDepartment = useLookupDepartment();

  const departmentOptions = useDepartmentOptions({ defaultValue: expense?.department?._id });

  // WBS
  const cardTransactionCategoryOptions = useCardTransactionCategoryOptions({
    defaultValue: expense?.card_transaction_category_id,
  });
  const lookupCardTransactionCategory = useLookupCardTransactionCategories();

  const jobExpenseScopedPredicate = useCallback(
    (job: Job | AggregatedJob) => {
      return jobPredicate("update") && isJobSpendManagementScoped(job);
    },
    [jobPredicate]
  );

  const jobOptions = useJobOptions({
    defaultValue: expense?.job?._id,
    // if user is in inbox, show all jobs regardless of permission
    predicate: !!inboxMode ? undefined : jobExpenseScopedPredicate,
  });

  const costTypeOptions = useCostTypeOptions({
    defaultValue: expense?.cost_type_id,
    predicate: isCostTypeExpenseManagementScoped,
  });

  const activityOptionsPredicate = useCallback(
    (activity) => {
      return isExpenseScoped(activity) && isAccessFilterScoped(activity);
    },
    [isAccessFilterScoped]
  );

  const activityOptions = useActivityOptions(expense?.job?._id, {
    predicate: activityOptionsPredicate,
  });

  const options = useMemo(() => {
    return glAccountSelectionOptions({
      activeCompanyId,
      departmentId: expense.department?._id,
      lookupDepartment,
    });
  }, [activeCompanyId, expense.department?._id, lookupDepartment]);

  const ledgerAccountOptions = useLedgerAccountOptions(options);

  // state
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  // max height before scrolling
  const maxDropdownMenuHeight = 300;
  // min heigh before flipping from below to above
  const minHeightOfSelectDropdownBeforeRenderingAbove = 300;

  const handleReceiptChange = async (receipts: FilePickerFile[] | null) => {
    if (!receipts || cannot("update", expense)) return;

    const params: FileUploadParams = {
      parent_id: expense._id,
      parent_type: "expense",
      company_id: activeCompanyId!,
    };

    setIsFileUploading(true);
    await updateFiles(receipts, params);
    refreshExpense();
    setIsFileUploading(false);
  };

  const handleJobChange = (jobOption: Option<string> | null) => {
    const newActivityOptions = activityOptionsMap.get(jobOption?.value);
    if (newActivityOptions.every((o) => o.value !== expense.activity?._id)) {
      handleExpenseValueChange({ job_id: jobOption?.value ?? null, activity_id: null });
    } else {
      handleExpenseValueChange({ job_id: jobOption?.value ?? null });
    }
  };

  return (
    <>
      <div style={{ marginTop: "45px", marginBottom: "20px", fontWeight: "bold" }}>Cardholder</div>
      <Formblock
        label="Team member"
        labelInfo={"Select the team member associated with this purchase."}
        type="select"
        name="team_member_id"
        options={teamOptions}
        className="modal"
        value={teamOptions.find((option) => option.value === expense?.team_member?._id)}
        requiredSelect={false}
        noOptionsMessage={"No team members found."}
        editing={true}
        onChange={(option) => handleExpenseValueChange({ team_member_id: option?.value ?? null })}
        maxMenuHeight={maxDropdownMenuHeight}
        minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
        // don't allow changing the team member if the user doesn't have permission to update the expense of "other"
        disabled={cannot("update", expense) || globalAbilitiesCannot("card_transactions:others:update")}
        isClearable
      />
      <Formblock
        label="Department"
        labelInfo={"Select the department associated with this purchase."}
        type="select"
        name="department_id"
        options={departmentOptions}
        className="modal"
        value={departmentOptions.find((option) => option.value === expense?.department?._id)}
        requiredSelect={false}
        noOptionsMessage={"No departments found."}
        editing={true}
        onChange={(option) => handleExpenseValueChange({ department_id: option?.value ?? null })}
        maxMenuHeight={maxDropdownMenuHeight}
        minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
        disabled={cannot("update", expense)}
        isClearable
        hidden={!hasDepartments}
      />
      <div className="flex" style={{ marginTop: "45px", marginBottom: "20px", fontWeight: "bold" }}>
        Categorization
        {isSuperAdmin && (
          <InfoButton
            text={
              "If you are an admin, all fields are available to be edited even if they are hidden for other team members (based on the current policy)."
            }
          />
        )}
      </div>
      <div>
        <Formblock
          label={"Category" + (isFieldRequired("card_transaction_category_id") ? "*" : "")}
          labelInfo={"Select the category purchase."}
          type="select"
          name="card_transaction_category_id"
          options={cardTransactionCategoryOptions}
          className="modal"
          value={
            cardTransactionCategoryOptions.find(
              (option) => option.value === expense?.card_transaction_category_id
            ) || null
          }
          requiredSelect={false}
          editing={true}
          onChange={(option) => {
            const newCategory = lookupCardTransactionCategory(option?.value);

            handleExpenseValueChange({
              card_transaction_category_id: option?.value ?? null,
              job_id: newCategory?.job?.id ?? null,
              activity_id: newCategory?.activity?.id ?? null,
              cost_type_id: newCategory?.cost_type?.id ?? null,
              expense_account_id: newCategory?.gl_account?.id ?? null,
            });
          }}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          disabled={cannot("update", expense)}
          isClearable
          hidden={isFieldHidden("card_transaction_category_id")}
        />
        <JobInput
          label={"Job" + (isFieldRequired("job_id") ? "*" : "")}
          labelInfo={"Select the job associated with this purchase."}
          type="select"
          name="job_id"
          options={jobOptions}
          className="modal"
          value={jobOptions.find((option) => option.value === expense?.job?._id)}
          requiredSelect={false}
          noOptionsMessage={"No jobs found."}
          editing={true}
          onChange={handleJobChange}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          disabled={cannot("update", expense) || isFieldLocked("job_id")}
          isClearable
          hidden={isFieldHidden("job_id")}
        />
        <Formblock
          label={"Activity" + (isFieldRequired("activity_id") ? "*" : "")}
          labelInfo={"Select the activity associated with this purchase."}
          type="select"
          name="activity_id"
          options={activityOptions}
          className="modal"
          value={activityOptions.find((option) => option.value === expense?.activity?._id) || null}
          requiredSelect={false}
          noOptionsMessage={"Please select a job first."}
          editing={true}
          onChange={(option) => handleExpenseValueChange({ activity_id: option?.value ?? null })}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          disabled={cannot("update", expense) || isFieldLocked("activity_id")}
          isClearable
          hidden={isFieldHidden("activity_id")}
        />
        <Formblock
          label={"Cost type" + (isFieldRequired("cost_type_id") ? "*" : "")}
          labelInfo={"Select the cost type associated with this purchase."}
          type="select"
          name="cost_type_id"
          options={costTypeOptions}
          className="modal"
          value={costTypeOptions.find((option) => option.value === expense?.cost_type_id)}
          requiredSelect={false}
          editing={true}
          onChange={(option) => handleExpenseValueChange({ cost_type_id: option?.value ?? null })}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          disabled={cannot("update", expense) || isFieldLocked("cost_type_id")}
          isClearable
          hidden={!hasCostTypes || isFieldHidden("cost_type_id")}
        />
        <Formblock
          label={"GL account" + (isFieldRequired("expense_account_id") ? "*" : "")}
          labelInfo={
            "Select the GL account this purchase should be posted to. It will override the default from your ledger mappings."
          }
          type="select"
          name="expense_account_id"
          options={ledgerAccountOptions}
          className="modal"
          value={ledgerAccountOptions.find((option) => option.value === expense?.expense_account_id)}
          requiredSelect={false}
          editing={true}
          onChange={(option) => handleExpenseValueChange({ expense_account_id: option?.value ?? null })}
          maxMenuHeight={maxDropdownMenuHeight}
          minMenuHeight={minHeightOfSelectDropdownBeforeRenderingAbove}
          disabled={cannot("update", expense) || isFieldLocked("expense_account_id")}
          isClearable
          hidden={isFieldHidden("expense_account_id")}
        />
        <div style={{ marginTop: "45px", marginBottom: "20px", fontWeight: "bold" }}>
          Receipts{isFieldRequired("file_ids") ? "*" : ""}
        </div>

        {isFileUploading ? (
          <Loader />
        ) : (
          <FilePicker
            multiple={true}
            onChange={handleReceiptChange}
            files={expense?.attachments?.map((attachment) => ({
              data: attachment,
            }))}
            type={"dropzone"}
            maxFileSize={EXPENSE_ATTACHMENT_MAX_FILE_SIZE}
            maxFilesAllowed={EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED}
            compact={true}
            confirmBeforeRemovingFile={true}
            readOnly={isFileUploading || cannot("update", expense)}
            showPreviewModal={true}
            acceptedFileTypes={EXPENSE_ATTACHMENT_ACCEPTED_FILE_TYPES}
            hidden={isFieldHidden("file_ids")}
          />
        )}
        <div style={{ marginTop: "45px", marginBottom: "20px", fontWeight: "bold" }}>
          {"Team Member Memo" + (isFieldRequired("submitter_note") ? "*" : "")}
        </div>
        <Formblock
          type="paragraph"
          name="submitter_note"
          className="modal time-off-request-notes"
          defaultValue={expense.submitter_note}
          editing={true}
          placeholder={"Note from submitter"}
          disabled={cannot("update", expense)}
          onChange={(e) => handleExpenseValueChange({ submitter_note: e.target.value })}
          hidden={isFieldHidden("submitter_note")}
        />
        <div style={{ marginTop: "45px", marginBottom: "20px", fontWeight: "bold" }}>Approver Memo</div>
        <Formblock
          type="paragraph"
          name="approver_note"
          className="modal time-off-request-notes"
          defaultValue={expense.approver_note}
          editing={true}
          placeholder={"Reason for approval or denial"}
          onChange={(e) => handleExpenseValueChange({ approver_note: e.target.value })}
          disabled={cannot("update", expense)}
        />
      </div>
    </>
  );
};
