import { useEffect } from "react";

const listener = (_event: WheelEvent) => {
  if (
    document.activeElement &&
    "type" in document.activeElement &&
    document.activeElement.type === "number" &&
    "blur" in document.activeElement &&
    typeof document.activeElement.blur === "function"
  ) {
    document.activeElement.blur();
  }
};

/** When scrolling in a modal/page and a number input field happens to be selected, it'll scroll down the number. This has impacted the pay rate field for example when adding a new team member, such that the pay rate changes without the admin realizing it */
export const useDisableNumberInputScroll = (): void => {
  useEffect(() => {
    document.addEventListener("wheel", listener);
    return () => {
      document.removeEventListener("wheel", listener);
    };
  }, []);
};
