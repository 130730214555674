import React, { useState, useEffect } from "react";
import AddException from "./AddException";
import x from "dashboard/assets/x.png";
import Notifier from "dashboard/utils/notifier";

export type Exception = {
  exception: string;
  explanation: string;
};

export type ExceptionProps = {
  key: number;
  exception: Exception;
  exceptions: Exception[];
  index: number;
  setExceptions: (e: Exception[]) => void;
};

const Exception: React.FC<ExceptionProps> = ({ exception, index, exceptions, setExceptions }) => {
  const handleDelete = () => {
    console.log("index", index);
    const newExceptions = [...exceptions];
    newExceptions.splice(index, 1);
    setExceptions(newExceptions);
  };

  return (
    <div className="exception-wrapper">
      <div className="flex-1">
        <div className="exception-craft">{exception.exception}</div>
        <div className="exception-explanation">{exception.explanation}</div>
      </div>
      <img src={x} className="exception-x" onClick={handleDelete} />
    </div>
  );
};

type ExceptionsProps = {
  exceptions: Exception[];
  setExceptions: (e: Exception[]) => void;
};

export const Exceptions: React.FC<ExceptionsProps> = ({ exceptions, setExceptions }) => {
  const [adding, setAdding] = useState(false);
  const [exceptionList, setExceptionList] = useState(exceptions);

  const handleCreate = (newException) => {
    if (exceptionList.length > 7) {
      Notifier.error("The maximum number of exceptions is 8.");
      return;
    }
    setExceptions(exceptionList.concat([newException]));
  };

  useEffect(() => {
    setExceptionList(exceptions);
  }, [exceptions]);

  return (
    <div style={{ marginTop: exceptions.length > 0 ? "20px" : "0px" }}>
      {adding && <AddException create={handleCreate} hide={() => setAdding(false)} />}
      {exceptionList.length > 0 && <div className="bold">Exceptions</div>}
      {exceptionList.map((e, index) => {
        return (
          <Exception
            key={index}
            index={index}
            exception={e}
            setExceptions={setExceptions}
            exceptions={exceptions}
          />
        );
      })}
      <div className="blue-link" style={{ marginTop: 10, fontSize: 15 }} onClick={() => setAdding(true)}>
        {exceptionList.length > 0 ? "+ Add another exception" : "+ Add an exception"}
      </div>
      {exceptionList.length > 0 && <div className="vertical-spacer-small"></div>}
    </div>
  );
};
