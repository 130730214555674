import React, { FC, useEffect, useState } from "react";
import { RevieweesTableAndSelector } from "./RevieweesTableAndSelector";
import { AggregatedTeamMember, MiterAPI, PerformanceReviewCycle } from "dashboard/miter";
import { Notifier } from "ui";
import { useActiveTeam, useRefetchPerformanceReviewSchedules } from "dashboard/hooks/atom-hooks";
import { DateTime } from "luxon";

type Props = {
  performanceReviewCycle: PerformanceReviewCycle;
};

export const UpdateReviewees: FC<Props> = ({ performanceReviewCycle }) => {
  const [reviewees, setReviewees] = useState<AggregatedTeamMember[]>([]);
  const [selectedReviewees, setSelectedReviewees] = useState<AggregatedTeamMember[]>([]);
  const teamMembers = useActiveTeam();
  const refetchPerformanceReviewSchedules = useRefetchPerformanceReviewSchedules();

  const { team_member_ids } = performanceReviewCycle;

  useEffect(() => {
    setReviewees(teamMembers.filter((member) => team_member_ids.includes(member._id)));
  }, [teamMembers, team_member_ids]);

  const updateReviewees = async (chosenTeamMembers: AggregatedTeamMember[]) => {
    try {
      const res = await MiterAPI.performance_review_cycles.update(performanceReviewCycle._id, {
        ...performanceReviewCycle,
        team_member_ids: chosenTeamMembers.map((enrollee) => enrollee._id),
        timezone: DateTime.local().zoneName,
      });
      if (res.error) {
        throw new Error(res.error);
      }
      refetchPerformanceReviewSchedules();
      Notifier.success("Updated enrolled reviewees.");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
      console.error(e);
    }
  };

  const removeReviewees = async () => {
    const filteredReviewees = reviewees.filter((reviewee) => !selectedReviewees.includes(reviewee));
    if (filteredReviewees.length === 0) {
      Notifier.error("Cannot have a review schedule with 0 reviewees.");
      return;
    }
    await updateReviewees(filteredReviewees);
    setSelectedReviewees([]);
  };

  return (
    <RevieweesTableAndSelector
      reviewees={reviewees}
      onSubmit={updateReviewees}
      onRemove={removeReviewees}
      selectedReviewees={selectedReviewees}
    />
  );
};
