import { SettingsCard, Formblock } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";

interface TimesheetEarningTypeSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetEarningTypeSettings: React.FC<TimesheetEarningTypeSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <SettingsCard title="Earning types" info={"Manage timesheet earning types settings"}>
      <Formblock
        type="checkbox"
        name="always_display_earning_types"
        text="Always display earning types on timesheets"
        onChange={(e) => handleChange({ always_display_earning_types: e.target.checked })}
        checked={timesheetSettings.always_display_earning_types}
        editing={true}
        style={{ zIndex: 2, width: "50%" }}
        labelInfo="If enabled, we will always display earning types on the timesheet modal."
      />
      <Formblock
        type="checkbox"
        name=""
        text="Allow team members to update earning type from the mobile app"
        onChange={(e) => handleChange({ edit_earning_type_from_mobile: e.target.checked })}
        checked={timesheetSettings.edit_earning_type_from_mobile}
        editing={true}
      />
    </SettingsCard>
  );
};

export default TimesheetEarningTypeSettings;
