import { Message } from "@twilio/conversations";
import { truncateFilename } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { FC, useState } from "react";
import { FaCircleNotch, FaFileAlt } from "react-icons/fa";
import styles from "./Chat.module.css";
import Linkify from "react-linkify";

type Props = {
  message: Message;
  prevMessage?: Message;
  author: "sender" | "receiver";
};

export type MessageAttributes = {
  sender?: {
    label: string;
  };
};

const ChatMessage: FC<Props> = ({ message, prevMessage, author }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const senderLabel = (message.attributes as MessageAttributes).sender?.label;
  const senderIsSameAsPrevious =
    (prevMessage?.attributes as MessageAttributes)?.sender?.label === senderLabel;

  const messageTimestamp = DateTime.fromJSDate(message.dateCreated);

  const minutesBetweenMessages = prevMessage
    ? messageTimestamp.diff(DateTime.fromJSDate(prevMessage.dateCreated), "minutes").minutes
    : 15;

  const openMedia = async () => {
    if (message.type !== "media") return;

    setLoading(true);
    const url = await message.media.getContentTemporaryUrl();
    window.open(url, "_blank");

    setLoading(false);
  };

  const renderMessageContent = () => {
    if (message.type === "media") {
      return (
        <>
          <span className={styles["message-body"] + " " + styles["message-media"]} onClick={openMedia}>
            {!loading && <FaFileAlt style={{ marginBottom: -2, marginRight: 7 }} />}
            {loading && <FaCircleNotch style={{ marginBottom: -2, marginRight: 7 }} className="fa-spin" />}

            {truncateFilename(message.media.filename, 20)}
          </span>
        </>
      );
    }

    return <p className={styles["message-body"]}>{message.body}</p>;
  };

  return (
    <>
      <div className={styles["message-container"]}>
        {senderLabel && (!senderIsSameAsPrevious || minutesBetweenMessages > 15) && (
          <div className={styles["sender-label"]}>{senderLabel}</div>
        )}
        <div className={author === "sender" ? styles["message-sent"] : styles["message-received"]}>
          <Linkify>{renderMessageContent()}</Linkify>
        </div>
      </div>
    </>
  );
};

export const ChatTimestamp: FC<{ timestamp: DateTime }> = ({ timestamp }) => {
  return (
    <div className={styles["message-timestamp-container"]}>
      <p className={styles["message-timestamp"]}>{timestamp.toLocaleString(DateTime.DATETIME_MED)}</p>
    </div>
  );
};

export default ChatMessage;
