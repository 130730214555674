import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { titleCase } from "../../../utils/utils";
import { useActiveTeamMember } from "dashboard/hooks/atom-hooks";

type Props = {
  selectedRows: string[];
  onHide: () => void;
  onDelete: () => void;
  loading: boolean;
};

const DeleteRole: React.FC<Props> = ({ selectedRows, onHide, onDelete, loading }) => {
  const activeTeamMember = useActiveTeamMember();

  const isDeletingSelf = () => {
    for (const rowId of selectedRows) {
      console.log(rowId);
      // Make sure a user isn't deleting themself.
      if (rowId === activeTeamMember?.role?._id) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={onHide}>
        <div className="modal-wrapper role">
          {!isDeletingSelf() && (
            <>
              <div className="modal-header">{`Delete role${selectedRows.length > 1 ? "s" : ""}?`}</div>

              <div className="modal-body">
                {`Are you sure you want to delete ${selectedRows.length > 1 ? "these roles" : "this role"}?`}
              </div>
              <div className="modal-footer">
                <button className="button-1" onClick={onHide}>
                  Cancel
                </button>
                <button className={loading ? "button-2 inactive" : "button-2"} onClick={onDelete}>
                  {selectedRows.length > 1 ? "Yes, delete roles" : "Yes, delete role"}
                </button>
              </div>
            </>
          )}
          {isDeletingSelf() && (
            <>
              <h3 className="modal-header red ">Error</h3>

              <p className="modal-text">
                You can&apos;t delete your own role. To delete this role, sign into a different account.
                <br /> <br />
                <b> Email: </b>
                {activeTeamMember?.role?.email} <br />
                <b> Role type: </b>
                {titleCase(activeTeamMember?.role?.type)}
              </p>

              <div className="modal-footer center">
                <button className="button-1" onClick={onHide}>
                  Hide
                </button>
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DeleteRole;
