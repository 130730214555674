import {
  useActiveCompanyId,
  useActiveTeam,
  useHolidaySchedulesAtom,
  useLookupHolidaySchedule,
} from "dashboard/hooks/atom-hooks";
import { MiterAPI, HolidaySchedule, AggregatedTeamMember } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { Plus } from "phosphor-react";
import React, { useMemo } from "react";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { HolidayScheduleModal } from "./HolidayScheduleModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useEnhancedSearchParams } from "miter-utils";

type HolidayTableEntry = HolidaySchedule & { num_enrolled_employees: number };

export const HolidaySchedulesTable: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const [holidaySchedules, setHolidaySchedules] = useHolidaySchedulesAtom();
  const lookupHolidaySchedules = useLookupHolidaySchedule();
  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const hid = parsedSearchParams.hid;
  const teamMembers = useActiveTeam();

  const editableHolidaySchedule = useMemo(() => {
    return lookupHolidaySchedules(hid);
  }, [hid, lookupHolidaySchedules]);

  const { can, cannot } = useMiterAbilities();

  const tableEntries: HolidayTableEntry[] = useMemo(() => {
    const allRows = holidaySchedules.map((schedule) => {
      return {
        ...schedule,
        num_enrolled_employees: getNumTmsEnrolled(schedule._id, teamMembers),
      };
    });

    return allRows;
  }, [holidaySchedules, teamMembers]);

  const createHolidaySchedule = async () => {
    if (!activeCompanyId || cannot("time_off:holidays:manage")) return;

    try {
      const newHolidaySchedule = await MiterAPI.holiday_schedules.create({
        title: "New holiday schedule",
        company_id: activeCompanyId,
      });
      if (newHolidaySchedule.error) {
        throw new Error(newHolidaySchedule.error);
      }
      setHolidaySchedules((prev) => prev.concat(newHolidaySchedule));
      setSearchParams({ hid: newHolidaySchedule._id });
    } catch (e: $TSFixMe) {
      console.error("Error creating holiday schedule:", e);
      Notifier.error("Error creating a new holiday schedule. We're looking into it!");
    }
  };

  const staticActions: TableActionLink[] = [
    {
      label: "Add holiday schedule",
      className: "button-2 no-margin",
      action: createHolidaySchedule,
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => can("time_off:holidays:manage"),
    },
  ];

  const handleRowClick = (row: HolidayTableEntry) => {
    setSearchParams({ hid: row._id });
  };

  const hideAndRefetch = () => {
    setSearchParams({ hid: null });
  };

  const renderTable = () => {
    return (
      <>
        {editableHolidaySchedule && (
          <HolidayScheduleModal originalHolidaySchedule={editableHolidaySchedule} hide={hideAndRefetch} />
        )}
        <TableV2
          id={"holidays-table"}
          resource="holidays"
          data={tableEntries}
          columns={holidayScheduleColumns}
          staticActions={staticActions}
          onClick={handleRowClick}
        />
      </>
    );
  };

  return <div>{renderTable()}</div>;
};

const holidayScheduleColumns: ColumnConfig<HolidayTableEntry>[] = [
  {
    field: "title",
    headerName: "Schedule",
    dataType: "string",
  },
  {
    field: "num_enrolled_employees",
    headerName: "Enrolled team members",
    dataType: "number",
  },
  {
    field: "is_default",
    headerName: "Default",
    dataType: "boolean",
    headerTooltip: "New team members will automatically be enrolled into this holiday schedule.",
  },
];

const getNumTmsEnrolled = (hs_id: string, tms: AggregatedTeamMember[]) => {
  return tms.reduce((c, tm) => c + (tm?.holiday_schedule_id === hs_id ? 1 : 0), 0);
};
