import React from "react";
import { SettingsCard } from "ui/settings/SettingsCard";
import { PoliciesTable } from "dashboard/components/policies/PoliciesTable";
import { Formblock, Notifier } from "ui";
import {
  useActiveCompany,
  useExpensePolicyOptions,
  useReimbursementPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";
import { SpendProduct } from "./ExpenseSettingsV2";

export const ExpensePolicies: React.FC<{ product: SpendProduct }> = ({ product }) => {
  const activeCompany = useActiveCompany();
  const fetchUserData = useFetchUserData();

  const expenseSettings = activeCompany?.settings.expenses;
  const reimbursementSettings = activeCompany?.settings.reimbursements;

  const expensePolicyOptions = useExpensePolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();

  const updateSettings = async (value, key) => {
    try {
      if (!activeCompany?._id) throw new Error("No active company");

      const response = await MiterAPI.companies.update(activeCompany._id!, {
        $set: { [key]: value },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Spend management settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error(
        "There was an error updating spend management settings. Our engineers are looking into it!"
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div>
        {product === "cards" && (
          <SettingsCard>
            <Formblock
              type="select"
              name="default_policy_id"
              label="Default policy"
              placeholder="Select a default policy"
              options={expensePolicyOptions}
              onChange={(o) => updateSettings(o?.value || null, "settings.expenses.default_policy_id")}
              defaultValue={expenseSettings?.default_policy_id}
              editing={true}
              labelStyle={{ marginRight: 0 }}
              style={{ width: 350, marginBottom: -60, marginTop: 25 }}
            />
            <PoliciesTable policyType="expense" />
          </SettingsCard>
        )}
        {product === "reimbursements" && (
          <SettingsCard>
            <Formblock
              type="select"
              name="default_policy_id"
              label="Default policy"
              placeholder="Select a default policy"
              options={reimbursementPolicyOptions}
              onChange={(o) => updateSettings(o?.value || null, "settings.reimbursements.default_policy_id")}
              defaultValue={reimbursementSettings?.default_policy_id}
              editing={true}
              labelStyle={{ marginRight: 0 }}
              style={{ width: 350, marginBottom: -60, marginTop: 25 }}
            />
            <PoliciesTable policyType="expense_reimbursement" />
          </SettingsCard>
        )}
      </div>
    </div>
  );
};
