import { useState } from "react";
import { ActionModal, Formblock } from "ui";
import DataBox from "dashboard/components/dataBox/DataBox";
import { DataBoxRow } from "dashboard/components/dataBox/DataBoxRow";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";
import { AggregatedJob, Job, JobScope } from "dashboard/miter";
import { useForm } from "react-hook-form";
import { isJobSpendManagementScoped, isJobWFMScoped } from "./jobUtils";

type JobScopesForm = {
  workforceManagement: boolean;
  spendManagement: boolean;
};

type Props = {
  updateJob: (update: Partial<Job>) => Promise<void>;
  data: AggregatedJob;
  updatingJob: boolean;
};

export const JobScopes: React.FC<Props> = (props: Props) => {
  const { data, updateJob, updatingJob } = props;

  const [editingScopes, setEditingScopes] = useState(false);

  const jobAbilities = useJobAbilities();

  const { handleSubmit, register } = useForm<JobScopesForm>();

  const handleSave = async (data: JobScopesForm) => {
    const updatedScopes = new Set<JobScope>();

    if (data.workforceManagement) updatedScopes.add("workforce_management");
    if (data.spendManagement) updatedScopes.add("spend_management");

    await updateJob({
      scopes: [...updatedScopes],
    });

    setEditingScopes(false);
  };

  const renderJobScopesModal = () => {
    if (!editingScopes) return;

    return (
      <ActionModal
        submitText="Update"
        onHide={() => setEditingScopes(false)}
        cancelText="Cancel"
        onCancel={() => setEditingScopes(false)}
        onSubmit={handleSubmit(handleSave)}
        loading={updatingJob}
        headerText={"Update module filters"}
        showSubmit={true}
        showCancel={true}
      >
        <div className="vertical-spacer-small" />
        <div className="yellow-text-container">
          Job module filters determine in which module a job can be used.
        </div>
        <div className="vertical-spacer" />

        <Formblock
          label="Workforce management"
          type="checkbox"
          name="workforceManagement"
          className="tabbed-modal"
          defaultValue={isJobWFMScoped(data)}
          register={register}
          editing={true}
          disabled={jobAbilities.cannot("update", data)}
          style={{ alignItems: "center" }}
        />
        <Formblock
          label="Spend management"
          type="checkbox"
          name="spendManagement"
          className="tabbed-modal"
          defaultValue={isJobSpendManagementScoped(data)}
          register={register}
          editing={true}
          disabled={jobAbilities.cannot("update", data)}
          style={{ alignItems: "center" }}
        />

        <div className="vertical-spacer" />
      </ActionModal>
    );
  };

  return (
    <>
      <DataBox
        title={"Module Filters"}
        onEdit={jobAbilities.can("update", data) ? () => setEditingScopes(true) : undefined}
      >
        <DataBoxRow
          label="Workforce management"
          value={data.scopes?.includes("workforce_management") ? "Yes" : "No"}
        />
        <DataBoxRow
          label="Spend management"
          value={data.scopes?.includes("spend_management") ? "Yes" : "No"}
        />
      </DataBox>
      {renderJobScopesModal()}
    </>
  );
};
