import React, { useState } from "react";
import PayrollContext from "../payrollContext";
import checkmark from "dashboard/assets/check-mark.png";
import { BasicModal } from "ui";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";

const ReopenPayrollModal: React.FC<{ hide: () => void }> = (props) => {
  const { payroll, getPayroll } = React.useContext(PayrollContext);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!payroll) return null;

  const reloadPayrollAndHide = async () => {
    setLoading(true);
    await getPayroll();
    props.hide();
    setLoading(false);
  };

  const reopen = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.payrolls.reopen(payroll._id.toString());
      if (response.error) {
        if (response.error_type === "payroll_reopening_invalid") {
          Notifier.error(`This payroll cannot be reopened: ${response.error}`);
          await reloadPayrollAndHide();
        } else {
          throw new Error(response.error);
        }
      } else {
        setSuccess(true);
      }
    } catch (e: $TSFixMe) {
      console.error("Error reopening payroll.", e);
      Notifier.error("There was an error reopening the payroll. " + e.message);
    }
    setLoading(false);
  };

  const exit = () => {
    if (success) {
      reloadPayrollAndHide();
    } else {
      props.hide();
    }
  };

  return (
    <BasicModal
      headerText="Reopen payroll"
      button1Text={!success && "Cancel"}
      button1Action={exit}
      button2Text={success ? "Done" : "Reopen"}
      button2Action={success ? exit : reopen}
      button2Loading={loading}
      wrapperStyle={{ width: 360 }}
    >
      {success ? (
        <div className="payroll-approval-success-text">
          <img src={checkmark} className="success-checkmark" />
          <div>Success! The payroll was successfully reopened.</div>
        </div>
      ) : (
        <div className="payroll-approval-text-wrapper">
          Reopening this payroll will move it back into draft status. While the payroll is in draft status,
          you may make changes, but you must re-approve the payroll in order for it to run.
        </div>
      )}
    </BasicModal>
  );
};

export default ReopenPayrollModal;
