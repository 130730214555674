import {
  AggregatedExpense,
  AggregatedExpenseReimbursement,
  AggregatedTimeOffRequest,
  AggregatedTimesheet,
  ApprovalItem,
} from "dashboard/miter";
import { PolicyType } from "backend/services/approvals/types";
import React, { useState } from "react";
import Banner from "../shared/Banner";
import { BasicModal } from "ui";
import { useExpensePolicy } from "dashboard/utils/policies/expense-policy-utils";
import { useExpenseReimbursementPolicy } from "dashboard/utils/policies/expense-reimbursement-policy-utils";
import { capitalize } from "lodash";
import { useTimesheetPolicy } from "dashboard/utils/policies/timesheet-policy-utils";
import {
  useActiveCompany,
  useLookupDepartment,
  useLookupPolicy,
  useLookupTeam,
} from "dashboard/hooks/atom-hooks";
import {
  TimeOffRequestPolicyParams,
  useTimeOffRequestPolicy,
} from "miter-components/time-off/time-off-request-policy-utils";

type Props = {
  item?: ApprovalItem;
  policyType: PolicyType;
  style?: React.CSSProperties;
};

/**
 * This component is used to display a banner on the top of the modal if the item has any policy violations.
 */
export const NeedsAttentionBanner: React.FC<Props> = ({ item, policyType, style }) => {
  const [showPolicyMessages, setShowPolicyMessages] = useState(false);
  const { needsAttentionMessages, meetsPolicyRequirements } = useUnifiedPolicy(item, policyType);

  if (!item) return <></>;
  if (meetsPolicyRequirements()) return <></>;

  const itemLabel = getItemLabel(policyType);
  const notes = `This ${itemLabel} has one or more issues that need to be fixed. Click to view the issues.`;

  return (
    <>
      <Banner
        type={"error"}
        content={notes}
        style={style ? style : { marginLeft: -42, marginRight: -42 }}
        onClick={() => setShowPolicyMessages(true)}
      />
      {showPolicyMessages && (
        <BasicModal
          onHide={() => setShowPolicyMessages(false)}
          headerText={`${capitalize(itemLabel)} issues`}
          button2Text={"Close"}
          button2Action={() => setShowPolicyMessages(false)}
        >
          <div className={"mini-modal-list-container"}>
            {needsAttentionMessages.map((message, index) => {
              return (
                <div className={"mini-modal-list-row"} key={message + "-" + index}>
                  <div className={"mini-modal-list-message"}>{message}</div>
                </div>
              );
            })}
          </div>
        </BasicModal>
      )}
    </>
  );
};

/** This hook is used to get the policy helpers for the item. */
const useUnifiedPolicy = (item: ApprovalItem | undefined, policyType: PolicyType) => {
  // todo remove these to clean up when we delete team portal and clean up time off request utils
  const company = useActiveCompany();
  const lookupPolicy = useLookupPolicy();
  const lookupDepartment = useLookupDepartment();
  const lookupTeam = useLookupTeam();

  const timesheetPolicyHelpers = useTimesheetPolicy(
    policyType === "timesheet" ? (item as AggregatedTimesheet) : null
  );

  const expensesPolicyHelpers = useExpensePolicy(
    policyType === "expense" ? (item as AggregatedExpense) : null
  );

  const reimbursementPolicyHelpers = useExpenseReimbursementPolicy(
    policyType === "expense_reimbursement" ? (item as AggregatedExpenseReimbursement) : null
  );

  item = item as AggregatedTimeOffRequest;
  const timeOffRequestPolicyHelpers = useTimeOffRequestPolicy(
    policyType === "time_off_request"
      ? ({ item, company, lookupPolicy, lookupDepartment, lookupTeam } as TimeOffRequestPolicyParams)
      : null
  );

  if (policyType === "timesheet") {
    return timesheetPolicyHelpers;
  } else if (policyType === "expense") {
    return expensesPolicyHelpers;
  } else if (policyType === "time_off_request") {
    return timeOffRequestPolicyHelpers;
  }

  return reimbursementPolicyHelpers;
};

const getItemLabel = (policyType: PolicyType): string => {
  if (policyType === "timesheet") {
    return "timesheet";
  } else if (policyType === "expense") {
    return "expense";
  } else if (policyType === "time_off_request") {
    return "time off request";
  }
  return "expense reimbursement";
};
