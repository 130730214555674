import React, { useCallback, useEffect, useMemo, useState } from "react";
import { JobPostingWizard } from "dashboard/components/ats/JobPostingWizard";
import { JobApplicationsTable } from "dashboard/components/ats/JobApplicationsTable";
import { QRCodeModal } from "dashboard/components/ats/QRCodeModal";
import { useActiveCompany, useLookupJobPostings, useRefetchJobPostings } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { CaretDown } from "phosphor-react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Breadcrumbs, Button, DropdownButton, Loader, Notifier, Toggler } from "ui";
import { JobPostingDetails } from "./JobPostingDetails";
import styles from "./Ats.module.css";
import { useJobPostingAbilities } from "dashboard/hooks/recruiting/useJobPostingAbilities";

const togglerConfig = [
  { label: "Applications", path: "applications" },
  { label: "Details", path: "details" },
];

export const JobPosting: React.FC = () => {
  /** Navigation */
  const navigate = useNavigate();
  const { id, view } = useParams<{ id: string; view: string }>();

  /** Atom hooks */
  const lookupJobPosting = useLookupJobPostings();
  const jobPosting = lookupJobPosting(id);
  const refetchJobPosting = useRefetchJobPostings();
  const company = useActiveCompany();

  /** Abilities */

  const { can } = useJobPostingAbilities();

  /** State management */
  const [editJobPosting, setEditJobPosting] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  /* Handlers */

  const changeActivationStatus = useCallback(async () => {
    if (!jobPosting) return;

    const newStatus = jobPosting.status === "active" ? "inactive" : "active";
    try {
      const res = await MiterAPI.job_postings.update(jobPosting._id.toString(), {
        ...jobPosting,
        status: newStatus,
      });
      if (res.error) {
        throw new Error(res.error);
      }
      refetchJobPosting(jobPosting._id);
      Notifier.success("Job posting successfully updated!");
    } catch (error) {
      Notifier.error("Unable to update job posting status.");
      console.error("Unable to update status of job posting", error);
    }
  }, [jobPosting, refetchJobPosting]);

  const jobPostingSlug = useMemo(() => {
    return process.env.REACT_APP_CAREER_PAGE_URL + "/" + company?.slug + "/" + jobPosting?.slug;
  }, [company, jobPosting]);

  const copyCareerPageURL = useCallback(() => {
    navigator.clipboard.writeText(jobPostingSlug);
    Notifier.success("Copied job posting link to clipboard.");
  }, [jobPostingSlug]);

  const openCareerPage = () => {
    if (!company?.slug || !jobPosting?.slug) {
      Notifier.error("Unable to open job posting on career page.");
      return;
    }
    window.open(jobPostingSlug, "_blank");
  };

  const onExitWizard = async () => {
    await refetchJobPosting(jobPosting?._id);
    setEditJobPosting(false);
    setIsDuplicating(false);
  };

  const handleToggle = (path) => {
    navigate(`/recruiting/job-postings/${id}/${path}`);
  };

  /* useEffect callBacks */

  useEffect(() => {
    if (!id) {
      Notifier.error("No job posting id found.");
      navigate("/recruiting/job-postings", { replace: true });
      return;
    }
  }, [id, navigate]);

  const actions = useMemo(
    () => [
      {
        label: "Edit posting & questions",
        action: () => setEditJobPosting(true),
        shouldShow: () => can("update", jobPosting),
      },
      {
        label: jobPosting?.status === "active" ? "Make posting inactive" : "Make posting active",
        action: changeActivationStatus,
        shouldShow: () => can("update", jobPosting),
      },
      {
        label: "Copy link",
        action: copyCareerPageURL,
        shouldShow: () => can("read", jobPosting) && jobPosting?.status === "active",
      },
      {
        label: "View QR code",
        action: () => setShowQRCode(true),
        shouldShow: () => can("read", jobPosting) && jobPosting?.status === "active",
      },
      {
        label: "Duplicate",
        action: () => setIsDuplicating(true),
        shouldShow: () => can("read", jobPosting) && jobPosting?.status === "active",
      },
    ],
    [can, changeActivationStatus, copyCareerPageURL, jobPosting]
  );

  /* Render Functions */

  const renderBreadcrumbs = () => {
    if (!jobPosting) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Job postings", path: "/recruiting/job-postings" },
          { label: jobPosting.title, path: "/recruiting/job-postings/" + jobPosting._id },
        ]}
      />
    );
  };

  const renderView = () => {
    if (!jobPosting) return;
    switch (view) {
      case "applications":
        return <JobApplicationsTable jobPosting={jobPosting} secondary={true} />;
      case "details":
        return (
          <div className={styles["job-posting-details-container"]}>
            <JobPostingDetails jobPosting={jobPosting} />
          </div>
        );
      default:
        return <JobApplicationsTable jobPosting={jobPosting} secondary={true} />;
    }
  };

  return jobPosting ? (
    <div className="page-content">
      <Helmet>
        <title>{jobPosting.title} | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <div>
          {renderBreadcrumbs()}
          <div className="flex" style={{ alignItems: "center" }}>
            <h1>{jobPosting.title}</h1>
            <Badge color={jobPosting.status === "active" ? "blue" : "orange"} text={jobPosting.status} />
          </div>
        </div>
        <div className="flex-1"></div>
        <Button onClick={openCareerPage} className="button-2 no-margin">
          Live posting
        </Button>
        <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
          Actions
          <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
        </DropdownButton>
      </div>
      <Toggler
        secondary={false}
        active={view || "applications"}
        config={togglerConfig}
        toggle={handleToggle}
      />
      {renderView()}

      {editJobPosting && <JobPostingWizard onExit={onExitWizard} jobPosting={jobPosting} />}
      {isDuplicating && (
        <JobPostingWizard onExit={onExitWizard} jobPosting={jobPosting} isDuplicating={isDuplicating} />
      )}
      {showQRCode && (
        <QRCodeModal
          onClose={() => setShowQRCode(false)}
          title={"Direct apply QR code"}
          url={jobPostingSlug}
        />
      )}
    </div>
  ) : (
    <Loader />
  );
};
