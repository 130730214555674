import React, { FC, useEffect, useRef } from "react";
import { AggregatedJobApplication } from "dashboard/types/ats";
import { ActionModal, Formblock } from "ui";
import Banner from "../../shared/Banner";
import { Option } from "ui/form/Input";
import styles from "./RejectionReason.module.css";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { MiterAPI } from "../../../miter";
import { Notifier } from "ui/notifier";
import {
  hydrateRecruitingMessageTemplate,
  messageTemplateSmartFields,
} from "dashboard/utils/message-templates";
import {
  useRejectionReasonOptions,
  useLookupMessageTemplate,
  useUser,
  useLookupRejectionReason,
  useActiveCompanyId,
  useRecruitingTwilioClient,
} from "dashboard/hooks/atom-hooks";
import { getTwilioConversations } from "dashboard/utils/chat";
import { sendMessageHelper } from "dashboard/utils/chat";
import { SMSTemplateEditor } from "dashboard/components/message-templates/SMSTemplateEditor";
import { getUserLabel } from "dashboard/utils";
import { buildCompanyUserName } from "miter-utils";
import { useNavigate } from "react-router-dom";

type Props = {
  onClose: () => void;
  jobApplication: AggregatedJobApplication;
  onSubmit: () => Promise<void>;
};

type RejectCandidateForm = {
  rejection_reason_id: Option<string>;
  notes?: string;
  send_message?: boolean;
};

export const RejectApplicationsModal: FC<Props> = ({ onClose, jobApplication, onSubmit }) => {
  const form = useForm<RejectCandidateForm>();
  const rejectionReasonOptions = useRejectionReasonOptions();
  const lookupMessageTemplates = useLookupMessageTemplate();
  const lookupRejectionReason = useLookupRejectionReason();
  const { register, watch, handleSubmit } = form;
  const shouldSendMessage = !!watch("send_message");
  const rejectionReasonFromForm = watch("rejection_reason_id");
  const editorRef = useRef();
  const user = useUser();
  const companyId = useActiveCompanyId();
  const twilioClient = useRecruitingTwilioClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    const rejectionReasonId = rejectionReasonFromForm?.value;
    const selectedRejectionReason = lookupRejectionReason(rejectionReasonId);
    const messageTemplate = lookupMessageTemplates(selectedRejectionReason?.message_template_id);

    if (messageTemplate) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TinyMCE doesn't have proper typing for ref
      const hydratedText = hydrateRecruitingMessageTemplate({
        text: messageTemplate.raw_html,
        template: messageTemplate,
        candidate: jobApplication.candidate,
        jobPosting: jobApplication.job_posting,
        sender: user,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TinyMCE doesn't have proper typing for ref
      editorRef.current?.setContent(hydratedText);
    }
  }, [
    rejectionReasonFromForm,
    jobApplication.candidate,
    jobApplication.job_posting,
    lookupMessageTemplates,
    user,
    lookupRejectionReason,
  ]);

  const sendNote = async (note: string) => {
    try {
      if (!user) throw new Error("User not found.");
      if (!companyId) throw new Error("Company ID not found.");
      const authorName = buildCompanyUserName(user);
      const res = await MiterAPI.notes.create({
        parent_id: jobApplication._id,
        parent_type: "job_application",
        content: note,
        author_name: authorName,
        author_user_id: user._id,
        company_id: companyId,
      });
      if (res.error) throw new Error(res.error);
      Notifier.success("Note sent to candidate.");
    } catch (e: $TSFixMe) {
      Notifier.error(`We are unable to create the note at this time: ${e.message}`);
    }
  };

  const rejectApplication = async (rejectionReasonId: string) => {
    try {
      const res = await MiterAPI.job_applications.reject([
        {
          _id: jobApplication._id,
          params: {
            rejection_reason_id: rejectionReasonId,
          },
        },
      ]);
      if (res.errors && res.errors.length > 0) throw new Error(res.errors?.[0]?.message || "Unknown error");
      Notifier.success("Application rejected.");
    } catch (e: $TSFixMe) {
      Notifier.error(`We are unable to reject the application at this time: ${e.message}`);
    }
  };

  const createChat = async (message: string): Promise<void> => {
    try {
      if (!companyId) throw new Error("Company ID not found.");
      if (!user) throw new Error("User not found.");
      const res = await MiterAPI.chat.recruiting.create(companyId, jobApplication.candidate._id);
      if (res.error) throw new Error(res.error);

      if (!twilioClient) {
        window.location.reload();
        return;
      }

      const twilioConversations = await getTwilioConversations(twilioClient);
      const matchingTwilioConversation = twilioConversations.find(
        (conversation) => conversation.sid === res.conversation_sid
      );

      if (!matchingTwilioConversation) {
        throw new Error("Twilio conversation not found.");
      }

      const { success } = await sendMessageHelper({
        message,
        twilioConversation: matchingTwilioConversation,
        userLabel: getUserLabel(user),
      });
      if (!success) {
        throw new Error("Failed to send message.");
      }
      Notifier.success("SMS sent to candidate.");
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to send SMS to candidate.`);
    }
  };

  const submit = handleSubmit(async (data: RejectCandidateForm) => {
    const { rejection_reason_id, notes } = data;
    const rejectionReasonId = rejection_reason_id?.value;
    const promises: Promise<void>[] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TinyMCE doesn't have proper typing for ref
    const htmlContent = editorRef.current?.getContent();

    if (shouldSendMessage && !htmlContent) {
      Notifier.error("Please enter a message to send to the candidate.");
      return;
    }

    if (rejectionReasonId) {
      promises.push(rejectApplication(rejectionReasonId));
    }
    if (notes) {
      promises.push(sendNote(notes));
    }
    if (shouldSendMessage) {
      promises.push(createChat(htmlContent));
    }
    await Promise.all(promises);
    await onSubmit();
  });

  return (
    <ActionModal
      headerText={`Reject application`}
      showSubmit={true}
      submitText="Submit"
      showCancel={true}
      cancelText="Cancel"
      onCancel={onClose}
      onHide={onClose}
      onSubmit={submit}
      wrapperClassName={styles["reject-applications-modal-wrapper"]}
      bodyClassName={styles["reject-applications-modal-body"]}
    >
      <div className={styles["reject-applications-modal-content"]}>
        <Banner
          className={styles["rejection-reason-modal-banner"]}
          content="Click here to create rejection reasons to track your hiring metrics."
          type="info"
          onClick={() => navigate("/recruiting-settings")}
        />
        <Formblock
          label="Reason*"
          labelInfo="Select a reason for rejecting this application."
          type="select"
          placeholder="Select a rejection reason"
          editing={true}
          form={form}
          className="modal"
          name="rejection_reason_id"
          register={register(vals.required)}
          options={rejectionReasonOptions}
          requiredSelect={true}
          style={{ marginBottom: 20 }}
        />
        <Formblock
          label="Notes"
          type="paragraph"
          placeholder="Enter notes"
          labelInfo="Add any additional notes about why this application was rejected."
          editing={true}
          form={form}
          className="modal"
          name="notes"
          style={{ marginBottom: 20 }}
        />
        <Formblock
          formblockClassName={styles["send-sms-checkbox"]}
          labelStyle={{ width: "800px", marginTop: 6 }}
          type="checkbox"
          name="send_message"
          editing={true}
          form={form}
          label="Send SMS to rejected candidate"
        />
        <div style={{ display: shouldSendMessage ? "block" : "none", marginTop: 10 }}>
          <SMSTemplateEditor
            smartFields={messageTemplateSmartFields}
            editorRef={editorRef}
            toolbar={""}
            height={300}
          />
        </div>
      </div>
    </ActionModal>
  );
};
