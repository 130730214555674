import { MiterAPI } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import styles from "ui/modal/modalWithSidebar.module.css";
import { MenuItem } from "ui/modal/ModalWithSidebar";
import { Badge, BasicModal, ConfirmModal, DropdownButton, ModalSidebar } from "ui";
import x from "dashboard/assets/x.png";
import { PayScheduleBasics } from "./PayScheduleBasics";
import { Notifier } from "dashboard/utils";
import { UpdatePayScheduleParams } from "backend/services/pay-schedule-service";
import { PaySchedulePaydays } from "./UpcomingPaydays";
import { PayScheduleTms } from "./PayScheduleTms";
import { CaretDown } from "phosphor-react";
import {
  useIsSuperAdmin,
  useLookupPaySchedule,
  useRefetchPaySchedules,
  useRefetchTeam,
  useSetPaySchedules,
} from "dashboard/hooks/atom-hooks";
import { displayFieldErrors } from "dashboard/utils/errors";
import { useForm } from "react-hook-form";
import { PaySchedulePermissions } from "./PaySchedulePermissions";

type Props = {
  hide: () => void;
  payScheduleId: string;
};

type NewMenuItem = Omit<MenuItem, "component">;

export const PayScheduleModal: React.FC<Props> = ({ payScheduleId, hide }) => {
  const form = useForm();
  const setPaySchedules = useSetPaySchedules();
  const refetchPaySchedules = useRefetchPaySchedules();
  const lookupPaySchedule = useLookupPaySchedule();
  const refetchTeam = useRefetchTeam();
  const paySchedule = lookupPaySchedule(payScheduleId);
  const isSuperAdmin = useIsSuperAdmin();

  const [loading, setLoading] = useState(false);
  const [archiveConfirmation, setArchiveConfirmation] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [makeDefaultConfirmation, setMakeDefaultConfirmation] = useState(false);

  const tabs = useMemo(() => {
    const base = ["Basics", "Team members", "Paydays"];
    if (isSuperAdmin) base.push("Permissions");

    return base;
  }, [isSuperAdmin]);

  const menuItems = tabs.map((t) => {
    return { label: t, path: t };
  });

  const [activeMenuItem, setActiveMenuItem] = useState<NewMenuItem | undefined>(menuItems[0]);

  const isDefaultPs = !!paySchedule?.default;

  const actions = useMemo(() => {
    const arr = [
      {
        action: () => setArchiveConfirmation(true),
        label: "Delete",
      },
      {
        action: () => setMakeDefaultConfirmation(true),
        label: "Make default",
      },
    ];
    return arr;
  }, []);

  if (!paySchedule) return null;

  const handleToggle = (path) => {
    const activeItem = menuItems.find((item) => item.path === path);
    setActiveMenuItem(activeItem);
  };

  const handleHide = () => {
    hide();
  };

  const updatePaySchedule = async (update: UpdatePayScheduleParams): Promise<boolean> => {
    let success = true;
    try {
      setLoading(true);
      const response = await MiterAPI.pay_schedules.update(paySchedule._id, update);
      if (response.error) {
        if (response.fields) {
          for (const f of response.fields || []) {
            if (f.name === "first_payday" && f.error.includes("be 15th or last day")) {
              f.error = "Must be 15th or last day of month for semimonthly, or set a second payday.";
              form.setError("label", { message: f.error });
            }
          }
          displayFieldErrors(response.fields || [], form.setError);
        }
        if (response.error_type === "pay_schedule_frequency_unchangeable") {
          Notifier.error(
            `You cannot change the pay frequency of a pay schedule once it has been connected to a payroll. Please create a new pay schedule with the desired pay frequency.`,
            { duration: 10000 }
          );
          return false;
        }
        throw new Error(response.error);
      }
      // Changing the default property impacts other pay schedules, so need to refetch them all
      if (update.default) {
        await refetchPaySchedules();
      } else {
        setPaySchedules((prev) => prev.concat(response));
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message || `There was an error updating the pay schedule`);
      success = false;
    }
    setLoading(false);
    return success;
  };

  const archivePaySchedule = async () => {
    setArchiving(true);
    try {
      const response = await MiterAPI.pay_schedules.archive(paySchedule._id);
      if (response.error) throw new Error(response.error);
      setPaySchedules((prev) => prev.filter((ps) => ps._id !== paySchedule._id));
      hide();
      Notifier.success("Pay schedule deleted successfully.");
    } catch (e) {
      console.log(e);
      Notifier.error(`There was an error deleting the pay schedule`);
    }
    // Deleting a pay schedule means anyone on it will be moved to the default pay schedule, so need to refetch team members
    await refetchTeam();
    setArchiving(false);
  };

  const handleMakeDefault = async () => {
    await updatePaySchedule({ default: true, inactive: false });
    setMakeDefaultConfirmation(false);
  };

  return (
    <div className="modal-background">
      {archiveConfirmation && (
        <BasicModal
          headerText={`Delete ${paySchedule.label}?`}
          button2Action={archivePaySchedule}
          button2Loading={archiving}
          button2Text="Delete"
          button1Text="Cancel"
          button1Action={() => setArchiveConfirmation(false)}
        >
          <div className="red-text-container">
            All team members on this schedule will be reassigned to the company default pay schedule.
          </div>
        </BasicModal>
      )}
      {makeDefaultConfirmation && (
        <ConfirmModal
          body={`Make ${paySchedule.label} the default pay schedule? New team members will automatically be assigned to this pay schedule.`}
          onYes={handleMakeDefault}
          onNo={() => setMakeDefaultConfirmation(false)}
          loading={loading}
          yellowBodyText
        />
      )}
      <div className={styles["modal-wrapper"]} style={{ minHeight: 400, width: 800 }}>
        <div className={styles["header"]}>
          <div className="width-100 flex">
            <div>{paySchedule.label}</div>
            {paySchedule.default && (
              <Badge
                color="green"
                text="DEFAULT"
                tooltip="New team members will automatically be assigned to this pay schedule"
              />
            )}
            <div className="flex-1"></div>
            {!isDefaultPs && (
              <DropdownButton className={"button-1 no-margin modal-dropdown-button"} options={actions}>
                Actions
                <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
              </DropdownButton>
            )}
            <div style={{ marginRight: 15 }}></div>
            <div style={{ width: 10 }}></div>
          </div>
          <div className="flex-1"></div>

          <img src={x} style={{ height: 12, cursor: "pointer" }} onClick={handleHide} />
        </div>
        <div className={styles["below"]}>
          <ModalSidebar config={menuItems} active={activeMenuItem?.path} toggle={handleToggle} />
          <div className={styles["active-view"]} style={{ overflowY: activeMenuItem?.overflow || "scroll" }}>
            {activeMenuItem?.path === "Basics" && (
              <PayScheduleBasics
                originalPaySchedule={paySchedule}
                updatePaySchedule={updatePaySchedule}
                form={form}
              />
            )}
            {activeMenuItem?.path === "Paydays" && <PaySchedulePaydays ps={paySchedule} />}
            {activeMenuItem?.path === "Team members" && <PayScheduleTms ps={paySchedule} />}
            {activeMenuItem?.path === "Permissions" && <PaySchedulePermissions ps={paySchedule} />}
          </div>
        </div>
      </div>
    </div>
  );
};
