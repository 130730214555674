import React, { FC } from "react";
import { Formblock } from "ui";
import { FieldRequirement } from "dashboard/miter";
import { set } from "lodash";
import { useForm } from "react-hook-form";
import { CompanySettings } from "backend/models/company";
import InfoButton from "../information/information";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type Settings = CompanySettings["timesheets"];

export type Props = {
  settings: Settings;
  handleChange: (newFields: Partial<Settings>) => Promise<void>;
};

const options = [
  { label: "Required", value: "required" },
  { label: "Optional", value: "optional" },
  { label: "Hidden", value: "hidden" },
];

export type FieldProps = {
  name: string;
  label: string;
  value: FieldRequirement;
  description?: string;
};

export const TimesheetDashboardFieldRequirements: FC<Props> = ({ settings, handleChange }) => {
  const form = useForm();
  const { control } = form;
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  const fields: FieldProps[] = [
    {
      name: "job",
      label: "Job",
      value: settings.field_requirements.dashboard.job,
    },
    {
      name: "activity",
      label: "Activity",
      value: settings.field_requirements.dashboard.activity,
    },
    {
      name: "classification",
      label: "Classification",
      value: settings.field_requirements.dashboard.classification,
      description: "Override the default classification / pay rate on any timesheet.",
    },
    {
      name: "notes",
      label: "Notes",
      value: settings.field_requirements.dashboard.notes,
    },
    {
      name: "cost_type_id",
      label: "Cost type",
      value: settings.field_requirements.dashboard.cost_type_id,
    },
  ];
  if (hasAccessToEquipmentTracking) {
    fields.push({
      name: "equipment_ids",
      label: "Equipment",
      value: settings.field_requirements.dashboard.equipment_ids,
    });
  }

  return (
    <div>
      {fields.map((field) => {
        const { name, label, value, description } = field;
        const update = async (requirement: { label: string; value: FieldRequirement }) => {
          const updatedSettings = { ...settings };
          set(updatedSettings, `field_requirements.dashboard.${name}`, requirement.value);
          handleChange(updatedSettings);
        };
        return (
          <div style={{ display: "flex", minWidth: 600, marginBottom: 10 }}>
            <div style={{ width: 150, display: "flex" }}>
              {label}
              {description && (
                <div style={{ marginTop: 5 }}>
                  <InfoButton text={description} />
                </div>
              )}
            </div>

            <Formblock
              name={name}
              type="select"
              options={options}
              defaultValue={value}
              onChange={update}
              editing={true}
              control={control}
              style={{ width: 200, marginTop: -5 }}
            />
          </div>
        );
      })}
    </div>
  );
};
