import React, { useMemo } from "react";
import { Formblock, Loader, SettingsCard } from "ui";
import { MIN_LABEL_WIDTH } from "../CardProgramSettings";
import { baseSensitiveCompare } from "miter-utils";
import { AggregatedCardProgram, CardProgram } from "dashboard/miter";
import { useFetchIntacctObjects } from "dashboard/hooks/integrations/sage_intacct/useFetchIntacctObjects";
import { DeepPartial } from "utility-types";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";

interface CardProgramIntegrationsProps {
  updateCardProgram: (update: Partial<CardProgram>) => Promise<void>;
  cardProgram: AggregatedCardProgram;
  sageIntacctConnectionId: string | undefined;
  acumaticaConnectionId: string | undefined;
}

export const CardProgramIntegrations: React.FC<CardProgramIntegrationsProps> = ({
  updateCardProgram,
  cardProgram,
  sageIntacctConnectionId,
}) => {
  const { intacctData, loading: loadingIntacctData } = useFetchIntacctObjects({
    integrationConnectionId: sageIntacctConnectionId,
    objectTypes: ["creditCards", "attachmentFolders"],
  });

  const intacctFolderOptions = useMemo(() => {
    return (
      intacctData?.attachmentFolders
        ?.map((t) => ({ label: t.name, value: t.name }))
        .sort((a, b) => baseSensitiveCompare(a.label, b.label)) || []
    );
  }, [intacctData]);

  const intacctCreditCardOptions = useMemo(() => {
    return (
      intacctData?.creditCards
        ?.map((t) => ({ label: t.DESCRIPTION || t.CARDID, value: t.CARDID }))
        .sort((a, b) => baseSensitiveCompare(a.label, b.label)) || []
    );
  }, [intacctData]);

  const updateCardProgramMetadata = async (
    update: DeepPartial<CardProgram>,
    opts?: { collapseCount?: number }
  ) => {
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(update, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateCardProgram(flattened);
  };

  if (loadingIntacctData) return <Loader />;

  return (
    <>
      {sageIntacctConnectionId && (
        <SettingsCard title="Sage Intacct">
          <div style={{ maxWidth: 600 }}>
            <Formblock
              labelStyle={{ minWidth: MIN_LABEL_WIDTH }}
              type="select"
              label="Sage Intacct card"
              labelInfo="Select the card in Sage Intacct this card is matched to."
              name="sage_intacct_card_id"
              onChange={(o) =>
                updateCardProgramMetadata({
                  integrations: {
                    sage_intacct: {
                      creditCardId: o?.value || null,
                    },
                  },
                })
              }
              editing={true}
              options={intacctCreditCardOptions}
              defaultValue={cardProgram?.integrations?.sage_intacct?.creditCardId}
              isClearable
            />
            <Formblock
              labelStyle={{ minWidth: MIN_LABEL_WIDTH }}
              type="select"
              label="Attachments folder"
              labelInfo="The Sage Intacct folder where Miter will upload expense receipts."
              name="sage_intacct_receipt_upload_folder_id"
              onChange={(o) =>
                updateCardProgramMetadata({
                  integrations: {
                    sage_intacct: {
                      receiptFolderId: o?.value || null,
                    },
                  },
                })
              }
              editing={true}
              options={intacctFolderOptions}
              defaultValue={cardProgram?.integrations?.sage_intacct?.receiptFolderId}
              isClearable
            />
          </div>
        </SettingsCard>
      )}
      {/* {acumaticaConnectionId && (
        <SettingsCard title={"Acumatica"}>
          <div style={{ maxWidth: 600 }}>
            <Formblock
              labelStyle={{ minWidth: MIN_LABEL_WIDTH }}
              type="select"
              label="Acumatica corporate card"
              labelInfo={"Select the corporate card in Acumatica this card is matched to."}
              name="acumatica_corporate_card"
              onChange={(o) => {
                updateCardProgramMetadata({
                  integrations: {
                    acumatica: {
                      corporateCardId: o?.value || null,
                    },
                  },
                });
              }}
              editing={true}
              options={acumaticaCorporateCardOptions}
              defaultValue={cardProgram?.integrations?.acumatica?.corporateCardId}
              isClearable
            />
          </div>
        </SettingsCard>
      )} */}
    </>
  );
};
