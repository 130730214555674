import { Formblock, SettingsCard } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import { UseFormMethods } from "react-hook-form";

interface TimesheetClockOutSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
  form: UseFormMethods;
}

const TimesheetClockOutSettings: React.FC<TimesheetClockOutSettingsProps> = ({
  timesheetSettings,
  handleChange,
  form,
}) => {
  return (
    <SettingsCard
      title="Clock out time"
      info="Choose when to record the clock out time for timesheets created via clock in."
    >
      <Formblock
        type="radio"
        name="record_clock_out_time_as"
        form={form}
        editing={true}
        label="Choose when the clock out time should be recorded."
        className="modal"
        options={[
          {
            label: (
              <div>
                <b>Before filling clock out form: </b>the time at which team members initially click the clock
                out button
              </div>
            ),
            value: "before_clock_out_form",
          },
          {
            label: (
              <div>
                <b>After filling clock out form: </b>the time at which the timesheet is saved
              </div>
            ),
            value: "after_clock_out_form",
          },
        ]}
        onChange={(e) => {
          handleChange({ record_clock_out_time_as: e?.target?.value });
        }}
        height="unset"
        defaultValue={timesheetSettings.record_clock_out_time_as || "before_clock_out_form"}
        style={{ width: "90%" }}
      />
    </SettingsCard>
  );
};

export default TimesheetClockOutSettings;
