import { DateTime } from "luxon";
import { FC, useState } from "react";
import { OfferLetter } from "dashboard/types/offers-types";
import { useForm } from "react-hook-form";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { ActionModal, Formblock } from "ui";

type UpdateExpiryFormParams = {
  date: DateTime | null;
};

export const UpdateExpiryModal: FC<{
  handleClose: () => void;
  handleSuccess: () => Promise<void>;
  offerLetter: OfferLetter;
}> = ({ handleClose, offerLetter, handleSuccess }) => {
  const [saving, setSaving] = useState(false);
  const form = useForm<UpdateExpiryFormParams>({
    defaultValues: {
      date: offerLetter.expires_on ? DateTime.fromSeconds(offerLetter.expires_on) : null,
    },
  });
  const { handleSubmit } = form;
  const onSubmit = async () => {
    handleSubmit(updateExpiry)();
  };

  const updateExpiry = async (data: UpdateExpiryFormParams) => {
    try {
      setSaving(true);
      const updateOfferLetterRes = await MiterAPI.offer_letters.update(offerLetter._id, {
        expires_on: data.date ? data.date.toSeconds() : null,
      });
      if (updateOfferLetterRes.error) throw Error(updateOfferLetterRes.error);
      Notifier.success("Offer letter expiration date updated.");
      await handleSuccess();
      setSaving(false);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    handleClose();
  };
  const renderForm = () => {
    return (
      <div style={{ marginTop: 15, marginBottom: 25 }}>
        <Formblock
          label="Expiration date"
          type="datetime"
          dateOnly={true}
          name="date"
          form={form}
          className="double time-off-request-date"
          editing={true}
          min={DateTime.fromSeconds(offerLetter.created_at)}
          defaultValue={null}
          customFormat="MMM dd, yyyy"
        />
      </div>
    );
  };
  return (
    <ActionModal
      headerText={"Update expiration date"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Cancel"}
      onCancel={handleClose}
      submitText={"Submit"}
      onHide={handleClose}
      onSubmit={onSubmit}
      loading={saving}
      wrapperStyle={{ width: 500 }}
      bodyStyle={{ minHeight: 0 }}
    >
      {renderForm()}
    </ActionModal>
  );
};
