import { IntegrationConnection, MiterIntegrationForCompany } from "dashboard/miter";
import React from "react";
import { IntegrationEntity } from "backend/models/integrations/integrations";

import styles from "./integrations.module.css";
import { SyncSettingsEntityRow } from "./SyncSettingsEntityRow";
import { Loader } from "ui";
import { filterSupportedEntities } from "./utils";

type Props = {
  integration: MiterIntegrationForCompany;
  updateIntegrationConnection: (u: Partial<IntegrationConnection>) => void;
  getSyncs: () => Promise<void>;
  loadingConnectionStatus: boolean;
};

export const IntegrationSyncSettings: React.FC<Props> = ({
  integration,
  updateIntegrationConnection,
  getSyncs,
  loadingConnectionStatus,
}) => {
  const entities = Object.keys(integration.supported_operations)
    .filter((integrationEntity) => filterSupportedEntities(integration, integrationEntity))
    .map((supportedIntegrationEntity) => supportedIntegrationEntity as IntegrationEntity);

  // For Intacct, getting the connection status generates a new API session, which will invalidate existing sessions, so we have to wait before we can fetch other data
  if (integration.key === "sage_intacct" && loadingConnectionStatus) return <Loader />;

  return (
    <div>
      <div className={`${styles["row"]} ${styles["header-row"]}`}>
        <div className={`${styles["column-1"]} ${styles["header"]}`}>Data entity</div>
        <div
          className={`${styles["column-2"]} ${styles["header"]}`}
        >{`From ${integration.label} to Miter`}</div>
        <div
          className={`${styles["column-3"]} ${styles["header"]}`}
        >{`From Miter to ${integration.label}`}</div>
      </div>
      <div style={{}}>
        {entities.map((entity) => {
          return (
            <SyncSettingsEntityRow
              integration={integration}
              getSyncs={getSyncs}
              entity={entity}
              updateIntegrationConnection={updateIntegrationConnection}
              key={entity}
            />
          );
        })}
      </div>
      <div className="vertical-spacer-large"></div>
    </div>
  );
};
