import React, { FC, ReactNode } from "react";
import styles from "./Onboarding.module.css";

export type OnboardingCard = {
  title: ReactNode | string;
  description: string;
};

export const OnboardingCard: FC<OnboardingCard> = ({ title, description }) => {
  return (
    <div className={styles["onboarding-card"]}>
      <h2 className={styles["onboarding-card-title"]}>{title}</h2>
      <div className={styles["onboarding-card-description"]}>{description}</div>
    </div>
  );
};
