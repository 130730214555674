import React, { useMemo, useState } from "react";

import { Button, Formblock, Notifier, SliderModal, TableV2 } from "ui";
import { useActiveCompany, useActiveTeam } from "dashboard/hooks/atom-hooks";
import { Address, AggregatedTeamMember, MiterAPI, ShippingAddressType } from "dashboard/miter";
import { convertCheckAddressToMiterAddress } from "dashboard/utils/addressUtils";
import { addressToString, addressToTownStateZip } from "dashboard/utils";
import { CheckAddress } from "backend/utils/check/check-types";
import BulkTeamMemberSelect from "dashboard/components/team-members/BulkTeamMemberSelect";
import { Pencil } from "phosphor-react";
import { CardManagementTableRow } from "../CardManagementTable";
import { useMiterCardAbilities } from "dashboard/hooks/abilities-hooks/useMiterCardAbilities";
import InfoButton from "dashboard/components/information/information";
import { useForm } from "react-hook-form";
import { IssueMiterCardModalFooter } from "./IssueMiterCardModalFooter";
import pluralize from "pluralize";
import { isValidAddress } from "miter-utils";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { FailureItem } from "dashboard/components/shared/FailuresModal";
import { ColumnConfig } from "packages/ui/table-v2/Table";

type Props = {
  onHide: () => void; // hide modal
  onSubmit: () => void; // refresh table
  miterCards: CardManagementTableRow[];
};

const companyCard = {
  _id: "Accounts Payable",
  full_name: "Accounts Payable (general company account)",
};

const IssueMiterCardModal: React.FC<Props> = ({ onHide, onSubmit, miterCards }) => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const activeCompany = useActiveCompany();
  const teamMembers = useActiveTeam();
  const miterCardAbilities = useMiterCardAbilities();
  const { setFailures, renderFailuresModal } = useFailuresModal();
  const form = useForm();
  const { control, errors } = form;
  const formData = form.watch();

  // Filter out the team members that already have a Miter card
  const tmsWithCards = useMemo(() => {
    return new Set<string>(
      miterCards
        .filter((card) => card.cardStatus !== "canceled")
        .map((card) => card.assignedTeamMemberId || companyCard._id)
    );
  }, [miterCards]);

  const potentialNewCardHolders: AggregatedTeamMember[] = useMemo(() => {
    return teamMembers.filter(
      (
        tm:
          | {
              _id: string;
              full_name: string;
            }
          | AggregatedTeamMember
      ) => {
        const alreadyHasCard = tmsWithCards.has(tm._id);
        if (alreadyHasCard) return false;

        if ("company" in tm) {
          const canCreateCard = miterCardAbilities.teamPredicate("create")(tm);
          return canCreateCard;
        }

        return true;
      }
    );
  }, [tmsWithCards, teamMembers, miterCardAbilities]);

  /*********************************************************
   *  Initialize state
   **********************************************************/
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTeamMemberSelector, setShowTeamMemberSelector] = useState<boolean>(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<AggregatedTeamMember[]>([]);
  const [selectedShippingAddressType, setSelectedShippingAddressType] =
    useState<ShippingAddressType>("company");

  const getAddressForTeamMember = (tm: AggregatedTeamMember): Address | undefined => {
    if (selectedShippingAddressType === "company" && companyAddress) {
      return convertCheckAddressToMiterAddress(companyAddress);
    } else if (selectedShippingAddressType === "team_member") {
      const address = tm.mailing_address || tm.address;
      if (!address) return;

      return convertCheckAddressToMiterAddress(address);
    } else if (selectedShippingAddressType === "other") {
      // convert form data to address
      return {
        line1: formData.address.line1,
        line2: formData.address.line2,
        city: formData.address.city,
        state: formData.address.state?.value,
        postal_code: formData.address.postal_code,
      };
    }
  };

  const handleSubmit = async () => {
    if (!activeCompany) return;

    setIsLoading(true);

    let numSuccesses = 0;
    const failures: FailureItem[] = [];
    await Promise.all(
      selectedTeamMembers.map(async (tm) => {
        try {
          const shippingAddress = getAddressForTeamMember(tm);
          if (!shippingAddress) {
            throw new Error(`No shipping address found for new cardholder ${tm.full_name}`);
          }

          const res = await MiterAPI.expense_cards.create({
            company: activeCompany._id,
            team_member_id: tm._id,
            shipping_address_type: selectedShippingAddressType,
            shipping_address: shippingAddress,
          });
          if (res.error) {
            throw new Error(res.error);
          }

          numSuccesses++;
        } catch (err: $TSFixMe) {
          // add to failures, render in FailuresModal

          failures.push({
            label: tm.full_name,
            message: err.message,
          });
        }
      })
    );
    setFailures(failures);

    if (numSuccesses > 0) {
      Notifier.success(`Issued ${numSuccesses} ${pluralize("cards", numSuccesses)}.`);
    }

    if (!failures.length) {
      onHide();
    }

    setIsLoading(false);
    onSubmit();
  };

  const companyAddress: CheckAddress | undefined = activeCompany?.check_company.address;

  const renderIntroCopy = () => {
    return (
      <>
        <div>
          <p>
            Miter cards are prefunded cards that your team can use for easy job attribution. Cards can be used
            anywhere Visa is accepted.
          </p>
          <p>
            You will receive the cards in <strong>7-10 business days</strong>. Tracking information will be
            available once each card is shipped.
          </p>
          <p className="flex">
            The billing address is your company’s HQ address.
            <InfoButton
              text={addressToString(convertCheckAddressToMiterAddress(companyAddress!), {
                oneLiner: true,
                hideCountry: true,
              })}
              place="top"
            />
          </p>
        </div>
      </>
    );
  };

  const renderTeamMemberSelector = () => {
    return (
      <BulkTeamMemberSelect
        defaultTeamMembers={selectedTeamMembers}
        title="Add new cardholders"
        teamMembersPool={potentialNewCardHolders}
        onHide={() => setShowTeamMemberSelector(false)}
        submitText="Save"
        onSubmit={(teamMembers) => {
          setSelectedTeamMembers(teamMembers);
          setShowTeamMemberSelector(false);
        }}
      />
    );
  };

  const renderIssueTo = () => {
    const columns: ColumnConfig<AggregatedTeamMember>[] = [
      {
        field: "full_name",
        headerName: "Name",
        dataType: "string",
      },
      {
        field: "address",
        headerName: "Address",
        dataType: "string",
        valueFormatter: (params) => {
          const address = params.data?.mailing_address || params.data?.address;
          if (!address) return "No residential or mailing address on file";

          if (!isValidAddress(address)) return "Incomplete address on file";

          return addressToTownStateZip(address);
        },
      },
    ];

    return (
      <>
        <h4>Issue to</h4>
        <div style={{ marginTop: "-30px" }}>
          <TableV2
            id={"miter-card-team-member-table"}
            resource="team members"
            columns={columns}
            data={selectedTeamMembers}
            rowClickDisabled={() => true}
            hideSearch={true}
            hideFooter={selectedTeamMembers.length <= 5}
            paginationPageSize={5}
            renderLeftActionBar={() => (
              <Button
                text={
                  <>
                    <Pencil weight="bold" style={{ marginRight: 5 }} />
                    {`${!!selectedTeamMembers.length ? "Edit" : "Add"} team members`}
                  </>
                }
                className="button-2 table-button"
                onClick={() => setShowTeamMemberSelector(true)}
                style={{ marginLeft: 0 }}
              />
            )}
          />
        </div>
      </>
    );
  };

  const allTeamMembersHaveHomeAddress = useMemo(
    () => !selectedTeamMembers.some((tm) => !isValidAddress(tm.address)),
    [selectedTeamMembers]
  );

  const renderShippingInformation = () => {
    if (!selectedTeamMembers.length) {
      return <></>;
    }

    const shippingOptions = [
      {
        value: "company",
        label: "Company address",
      },
      {
        value: "team_member",
        label: (
          <div className="flex">
            {"Team member's address"}
            <InfoButton
              text={
                allTeamMembersHaveHomeAddress
                  ? "Mailing address if specified, otherwise their residential address."
                  : "All selected team members need to have a valid address on file."
              }
            />
          </div>
        ),
        // cannot select this if any of the team members selected don't have an address on file
        isDisabled: !allTeamMembersHaveHomeAddress,
      },
      {
        value: "other",
        label: "Other",
      },
    ];

    return (
      <div style={{ marginTop: "-50px" }}>
        {<h4>Shipping Address</h4>}
        <div style={{ marginTop: "-10px" }}>
          <Formblock
            defaultValue={selectedShippingAddressType}
            onChange={(e) => {
              setSelectedShippingAddressType(e.target.value);
            }}
            type="radio"
            name="shipping_address"
            editing={true}
            options={shippingOptions}
          />
        </div>
        {renderSelectedAddressInformation()}
      </div>
    );
  };

  const renderSelectedAddressInformation = () => {
    if (selectedShippingAddressType === "company") {
      return (
        <div className="billing-card-wrapper">
          {addressToString(convertCheckAddressToMiterAddress(companyAddress!), {
            oneLiner: false,
            hideCountry: true,
          })}
        </div>
      );
    } else if (selectedShippingAddressType === "team_member") {
      // if single team member, show their town, state and zip code. If multiple, refer to table

      if (selectedTeamMembers.length === 1) {
        return (
          <div className="billing-card-wrapper">{addressToTownStateZip(selectedTeamMembers[0]?.address)}</div>
        );
      } else {
        return <div className="billing-card-wrapper">See table above.</div>;
      }
    } else if (selectedShippingAddressType === "other") {
      return (
        <Formblock
          type="address"
          name="address"
          control={control}
          errors={errors}
          form={form}
          editing={true}
        />
      );
    }
  };

  const title = "Issue Miter Card";

  if (!companyAddress) return <>Your company must have an address set in Miter to issue cards.</>;

  return (
    <>
      <SliderModal
        title={title}
        onCancel={onHide}
        animate={true}
        shouldOnclickAwayClose={true}
        footer={
          <IssueMiterCardModalFooter numCardsToIssue={selectedTeamMembers.length} onSubmit={handleSubmit} />
        }
        loading={isLoading}
      >
        <div style={{ width: "500px" }}>
          {renderIntroCopy()}
          {renderIssueTo()}
          {renderShippingInformation()}
        </div>
        {showTeamMemberSelector && renderTeamMemberSelector()}
      </SliderModal>
      {renderFailuresModal()}
    </>
  );
};

export default IssueMiterCardModal;
