import { Form } from "dashboard/miter";
import { get } from "lodash";
import { DateTime } from "luxon";
import { FormAnswer, notNullish, renderCustomFieldDefaultString } from "miter-utils";
import React, { useMemo } from "react";
import { Button } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";

/**
 * This function returns the column configs for a given form. Technically this hook is an anti-pattern because
 * some of the columns return JSX elements.
 */

type FormColumns = {
  formColumns: ColumnConfig<$TSFixMe>[];
};
export const useFormColumns = (inputs: {
  form?: Form;
  setSelectedAnswer: React.Dispatch<React.SetStateAction<FormAnswer>>;
  pathToAnswers?: string;
}): FormColumns => {
  const { form, setSelectedAnswer, pathToAnswers } = inputs;

  const formColumns = useMemo(
    () =>
      (form?.components || [])
        .map((c) => {
          if (c.type === "section") return null;
          return {
            headerName: c.name,
            field: c._id,
            dataType: "string",
            disableCellClick: c.type === "esignature",
            cellRenderer: (params) => {
              const answers = get(params.data, pathToAnswers || "answers") || [];
              const answer = answers.find((a) => a.form_field_id === c._id);

              if (answer?.value?.signature) {
                return (
                  <Button className="button-1 no-margin" onClick={() => setSelectedAnswer(answer)}>
                    View
                  </Button>
                );
              }

              if (c.type === "file" && Array.isArray(answer?.value)) {
                return (
                  <div>
                    {answer?.value.length} file{`${answer?.value.length > 1 ? "s" : ""}`} uploaded
                  </div>
                );
              }

              if (c.type === "photo" && Array.isArray(answer?.value)) {
                return (
                  <div>
                    {answer?.value.length} photo{`${answer?.value.length > 1 ? "s" : ""}`} uploaded
                  </div>
                );
              }

              return renderCustomFieldDefaultString(c, answer);
            },
            valueGetter: (params) => {
              const answers = get(params.data, pathToAnswers || "answers") || [];
              const answer = answers.find((a) => a.form_field_id === c._id);
              if (answer?.value && typeof answer.value === "object" && "created_at" in answer.value) {
                if (!answer.value.created_at) return "Unsigned";
                return `Signed on ${DateTime.fromSeconds(answer.value.created_at).toFormat("ff")}`;
              }

              return renderCustomFieldDefaultString(c, answer);
            },
          };
        })
        .filter(notNullish),
    [pathToAnswers, form, setSelectedAnswer]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Typescript is not seeing this as a valid column config
  return { formColumns };
};
