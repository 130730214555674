import { MiterAPI } from "dashboard/miter";
import { useEffect, useState, useCallback } from "react";
import { Notifier } from "ui";
import { ProcoreCustomFieldDefinition } from "backend/services/procore/procore-types";

type FetchCustomFieldDefinitions = {
  result: ProcoreCustomFieldDefinition[] | undefined;
  loading: boolean;
  error: unknown | null;
  fetchCutomFieldDefinitions: () => void;
};

export const useFetchCustomFieldDefinitions = (
  integrationConnectionId?: string
): FetchCustomFieldDefinitions => {
  const [result, setResult] = useState<ProcoreCustomFieldDefinition[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchCutomFieldDefinitions = useCallback(async () => {
    if (!integrationConnectionId) return;

    setLoading(true);
    try {
      const result = await MiterAPI.integrations.procore.list_custom_field_definitions(
        integrationConnectionId
      );

      if (result.error) throw Error(result.error);

      setResult(result);
    } catch (e: $TSFixMe) {
      Notifier.error("Error fetching custom field definitions.");
      setError(e);
    }
    setLoading(false);
  }, [integrationConnectionId]);

  useEffect(() => {
    fetchCutomFieldDefinitions();
  }, [fetchCutomFieldDefinitions]);

  return {
    result,
    loading,
    error,
    fetchCutomFieldDefinitions,
  };
};
