import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { Company } from "dashboard/miter";
import React from "react";
import { useWatch } from "react-hook-form";
import { Formblock, SettingsCard, SettingsPage } from "ui";
import { Option } from "ui/form/Input";

type SettingsForm = NonNullable<Company["settings"]["classes"]> & {};

const SETTINGS_PATH = "settings.classes";

export const ClassSettings: React.FC = () => {
  const activeCompany = useActiveCompany();
  const { form, handleFieldUpdate } = useSettingsForm<SettingsForm>(SETTINGS_PATH);
  useWatch<SettingsForm>({ control: form.control });

  return (
    <SettingsPage
      title="Class settings"
      subtitle="Configure how classes are managed in your company."
      backText="Back to classes"
      backLink="/settings/classes"
    >
      <SettingsCard title={"General"}>
        <Formblock
          label="Class source"
          labelInfo="Whether an item's class should be determined by the job or the team member. The team member's class will always be used as a backup."
          type="select"
          options={classSourceOptions}
          form={form}
          editing={true}
          onChange={(o) => handleFieldUpdate(o.value, "class_source")}
          style={{ width: 500 }}
          labelStyle={{ width: 300 }}
          defaultValue={activeCompany?.settings.classes?.class_source} // need to set default value here because this is a Select input
        />
      </SettingsCard>
    </SettingsPage>
  );
};

<div className="vertical-spacer-small" />;

const classSourceOptions: Option<string>[] = [
  { label: "Team member", value: "team_member" },
  { label: "Job", value: "job" },
];
