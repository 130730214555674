import { WorkplacesTable } from "dashboard/components/workplaces/WorkplacesTable";
import React from "react";
import { Helmet } from "react-helmet";

type Props = {};

export const Workplaces: React.FC<Props> = () => {
  return (
    <div className="section-wrapper">
      <Helmet>
        <title>Workplaces | Miter</title>
      </Helmet>
      <WorkplacesTable />
    </div>
  );
};
