import { MiterIntegrationForCompany } from "dashboard/miter";
import React from "react";

export const IntacctAuthentication: React.FC<{ integration: MiterIntegrationForCompany }> = ({
  integration,
}) => {
  if (!integration) return null;

  return (
    <>
      <div className="vertical-spacer-medium" />
      Once you complete the instructions linked above on creating a &quot;Web services&quot; user for Miter
      within Intacct, we will receive an email with the appropriate credentials.
      <div className="vertical-spacer-medium" />
      At that point, no further action is required on your end. Your Miter launch team will use these
      credentials to complete the setup and establish the connection. Please reach out with any questions.
    </>
  );
};
