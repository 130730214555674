import React, { FC, useState } from "react";
import { Button } from "ui";

type Props = {
  onSubmit: () => Promise<void>;
};

export const CreateExpenseReimbursementModalFooter: FC<Props> = ({ onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <div className={"modal-footer"}>
        <div className="flex-1"></div>
        <Button
          className="button-2"
          onClick={async () => {
            setIsSubmitting(true);
            await onSubmit();
            setIsSubmitting(false);
          }}
          text="Submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        />
      </div>
    </>
  );
};
