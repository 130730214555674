import { useCompanyDocuments } from "dashboard/hooks/atom-hooks";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { useMemo } from "react";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { Option } from "packages/ui/form/Input";
import { SignDocumentOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const SignableDocumentTaskContent: React.FC<
  ChecklistTaskContentProps<SignDocumentOnboardingTaskForm>
> = ({ task, setTask }) => {
  const companyDocuments = useCompanyDocuments();

  const onboardingDocumentOptions = useMemo(() => {
    return companyDocuments.map((doc) => ({ value: doc._id, label: doc.label || "Document" }));
  }, [companyDocuments]);

  const handleDocumentChange = (option: Option<string> | null) => {
    setTask({ ...task, data: { ...task.data, file_id: option?.value } });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <Formblock
        type="select"
        name="file_id"
        label="Select document"
        editing={true}
        defaultValue={task.data.file_id}
        options={onboardingDocumentOptions}
        className="modal"
        onChange={handleDocumentChange}
        style={{ marginBottom: 0, marginRight: 5 }}
      />

      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
