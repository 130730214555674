import React, { FC, useState } from "react";
import { Button, Notifier } from "ui";
import { AggregatedExpense, MiterAPI } from "../../../miter";
import InfoButton from "dashboard/components/information/information";
import { useExpenseAbilities } from "dashboard/hooks/abilities-hooks/useExpenseAbilities";
import { KickBackItemsAction } from "dashboard/components/approvals/KickBackItemsAction";
import { ApprovalStatusEnum } from "backend/services/approvals-service";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type Props = {
  expense: AggregatedExpense | undefined;
  onHide: () => void;
  onSplitCardTransaction: () => void;
  onApprovalChange: () => void;
  refreshData: () => void;
  inboxMode?: InboxMode;
};

export const CardTransactionsModalFooter: FC<Props> = ({
  expense,
  onHide,
  onSplitCardTransaction,
  onApprovalChange,
  refreshData,
  inboxMode,
}) => {
  const { can } = useExpenseAbilities({ inboxMode });

  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isUnapproving, setIsUnapproving] = useState<boolean>(false);
  const [isDenying, setIsDenying] = useState<boolean>(false);

  const updateExpenseStatus = async (newStatus: ApprovalStatusEnum) => {
    if (!expense) {
      Notifier.error("Expense to update not found");
      return;
    }

    if (newStatus === "denied") setIsDenying(true);
    else if (newStatus === "approved") setIsApproving(true);
    else setIsUnapproving(true);

    // set approval_status to the new status
    const updateApprovalStatusResult = await MiterAPI.expenses.update_approval_status([
      {
        _id: expense._id.toString(),
        params: {
          approval_status: newStatus,
        },
      },
    ]);

    if (updateApprovalStatusResult.error) {
      Notifier.error(updateApprovalStatusResult.error);

      setIsApproving(false);
      setIsDenying(false);
      setIsUnapproving(false);
      return;
    }

    if (updateApprovalStatusResult.errors.length > 0) {
      for (const failure of updateApprovalStatusResult.errors) {
        Notifier.error(failure.message);
      }
    } else {
      refreshData();
      onApprovalChange();
      Notifier.success(`Marked card transaction as ${newStatus}`);
    }

    if (newStatus === "denied") {
      setIsDenying(false);
    } else if (newStatus === "approved") {
      setIsApproving(false);
    } else {
      setIsUnapproving(false);
    }
  };

  const isUnapprovedAndHasPermissionToApprove =
    expense?.approval_status === "unapproved" && can("approve", expense);
  const isNotUnapprovedAndHasPermissionToApprove =
    expense?.approval_status !== "unapproved" && can("approve", expense);
  const loading = isApproving || isDenying || isUnapproving;

  const isExpenseNotClosed = expense?.purchase_status !== "closed";
  return (
    <>
      <div className={"modal-footer"}>
        <Button className="button-1" onClick={onHide} text="Cancel" disabled={loading} />
        {can("split", expense) && (
          <Button
            className="button-1"
            onClick={onSplitCardTransaction}
            disabled={isExpenseNotClosed || loading}
          >
            Split
            {isExpenseNotClosed ? <InfoButton text={"Only closed transactions can be split."} /> : <></>}
          </Button>
        )}
        <div className="flex-1"></div>
        {isUnapprovedAndHasPermissionToApprove && expense && (
          <KickBackItemsAction
            items={[expense]}
            itemType={"expense"}
            onKickBack={() => {
              onApprovalChange();
              refreshData();
            }}
          />
        )}
        {isNotUnapprovedAndHasPermissionToApprove && (
          <Button
            className="button-1"
            onClick={() => updateExpenseStatus("unapproved")}
            style={{ marginRight: "auto" }}
            text="Unapprove"
            loading={isUnapproving}
            disabled={loading}
          />
        )}
        {isUnapprovedAndHasPermissionToApprove && (
          <>
            <Button
              className="button-3"
              onClick={() => updateExpenseStatus("denied")}
              text="Deny"
              loading={isDenying}
              disabled={loading}
            />
            <Button
              className="button-2"
              onClick={() => updateExpenseStatus("approved")}
              text="Approve"
              loading={isApproving}
              disabled={loading}
            />
          </>
        )}
      </div>
    </>
  );
};
