import React from "react";
import { Helmet } from "react-helmet";
import { SettingsCard } from "ui/settings/SettingsCard";
import { PoliciesTable } from "dashboard/components/policies/PoliciesTable";
import { Formblock, Notifier } from "ui";
import { useActiveCompany, useTimeOffRequestPolicyOptions } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";

export const TimeOffRequestPolicies: React.FC = () => {
  const activeCompany = useActiveCompany();
  const fetchUserData = useFetchUserData();

  const timeOffSettings = activeCompany?.settings.time_off;
  const timeOffRequestPolicyOptions = useTimeOffRequestPolicyOptions();

  const updateSettings = async (value, key) => {
    try {
      if (!activeCompany?._id) throw new Error("No active company");

      const response = await MiterAPI.companies.update(activeCompany._id!, {
        $set: { [key]: value },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Time off settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating time off settings. Our engineers are looking into it!");
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Time off request policies | Miter</title>
      </Helmet>
      <div>
        <SettingsCard title={"Time off request policies"}>
          <Formblock
            type="select"
            name="default_policy_id"
            label="Default policy"
            placeholder="Select a default policy"
            options={timeOffRequestPolicyOptions}
            onChange={(o) => updateSettings(o?.value || null, "settings.time_off.default_policy_id")}
            defaultValue={timeOffSettings?.default_policy_id}
            editing={true}
            labelStyle={{ marginRight: 0 }}
            style={{ width: 350, marginBottom: -60, marginTop: 25 }}
          />
          <PoliciesTable policyType="time_off_request" />
        </SettingsCard>
      </div>
    </div>
  );
};
