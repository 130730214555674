import CrewModal from "dashboard/components/crews/CrewModal";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { Plus, TrashSimple } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { BasicModal, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Crew } from "dashboard/miter";
import { useCrews, useLookupTeam, useRefetchCrews } from "dashboard/hooks/atom-hooks";

type CrewTableEntry = Crew;

export const CrewsTable: React.FC = () => {
  const crews = useCrews();
  const refetchCrews = useRefetchCrews();
  const lookupTeam = useLookupTeam();

  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState<CrewTableEntry | undefined>();
  const [archiveConfirmation, setArchiveConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState<CrewTableEntry[]>([]);

  const tableData: CrewTableEntry[] = useMemo(() => {
    return crews;
  }, [crews]);

  const hideModal = () => {
    setCreating(false);
    setEditing(undefined);
  };

  const staticActions: TableActionLink[] = [
    {
      label: "Add crew",
      className: "button-2 no-margin",
      action: () => setCreating(true),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const dynamicActions: TableActionLink[] = [
    {
      label: "Delete",
      className: "button-3 no-margin table-button",
      action: () => setArchiveConfirmation(true),
      icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
    },
  ];

  const columns: ColumnConfig<CrewTableEntry>[] = [
    {
      headerName: "Name",
      field: "name",
      dataType: "string",
    },
    {
      headerName: "Lead",
      field: "lead_team_member_id",
      dataType: "string",
      valueGetter: (c) => lookupTeam(c.data?.lead_team_member_id)?.full_name || "-",
      enableRowGroup: true,
    },
    {
      headerName: "Size",
      field: "team_member_ids",
      dataType: "number",
      valueGetter: (c) => c.data?.team_member_ids?.length || 0,
    },
  ];

  const archiveSelectedCrews = async () => {
    setDeleting(true);
    try {
      await Promise.all(selected.map((s) => MiterAPI.crews.update(s._id, { archived: true })));
      await refetchCrews();
      setSelected([]);
      setArchiveConfirmation(false);
      Notifier.success("Crew(s) deleted successfully.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting the selected crew(s). We're looking into it!");
    }
    setDeleting(false);
  };

  return (
    <div>
      <TableV2
        id={"crews-table"}
        resource="crews"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelected}
        defaultSelectedRows={selected}
        onClick={setEditing}
        containerStyle={{ paddingBottom: 0 }}
        hideFooter={true}
      />
      {(creating || editing) && <CrewModal onFinish={hideModal} onHide={hideModal} crewID={editing?._id} />}
      {archiveConfirmation && (
        <BasicModal
          headerText="Delete crew(s)?"
          onHide={() => setArchiveConfirmation(false)}
          button2Action={archiveSelectedCrews}
          loading={deleting}
          button2Text="Delete"
        >
          <div className="red-text-container">Are you sure you want to delete the selected crew(s)?</div>
        </BasicModal>
      )}
    </div>
  );
};
