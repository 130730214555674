import React, { useMemo, useState } from "react";
import { Button, TableV2, Toggler } from "ui";
import { CustomHoliday, UpdateHolidayScheduleParams } from "dashboard/miter";
import { HolidayTabProps } from "./utils";
import { DateTime } from "luxon";
import { CustomHolidayModal } from "./CustomHolidayModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ColumnConfig } from "ui/table-v2/Table";
import { DeepPartial } from "utility-types";
import { useLookupOtRule, useOtRules } from "dashboard/hooks/atom-hooks";
import { ValueFormatterParams } from "ag-grid-community";

type CustomHolidayRow = CustomHoliday & {
  display_date: string;
};

type CustomHolidayTabProps = HolidayTabProps & {
  updateHolidaySchedule: (param: DeepPartial<UpdateHolidayScheduleParams>) => Promise<void>;
};

export const CustomHolidays: React.FC<CustomHolidayTabProps> = ({
  originalHolidaySchedule,
  updateHolidaySchedule,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCustomHoliday, setSelectedCustomHoliday] = useState<CustomHoliday | null>(null);
  const [activePath, setActivePath] = useState<"upcoming" | "past">("upcoming");
  const { can } = useMiterAbilities();

  const otRules = useOtRules();
  const lookupOtRule = useLookupOtRule();

  const customHolidayColumns: ColumnConfig<CustomHolidayRow>[] = useMemo(
    () => [
      {
        field: "label",
        headerName: "Holiday",
        type: "string",
      },
      {
        field: "display_date",
        headerName: "Date",
        type: "string",
      },
      ...(!!otRules.length
        ? [
            {
              field: "overtime_rule",
              headerName: "Overtime rule",
              type: "string",
              dataType: "string" as const,
              valueFormatter: (params: ValueFormatterParams<CustomHolidayRow>): string => {
                const rule = lookupOtRule(params.data?.overtime_rule_id);
                return rule?.label || "-";
              },
            },
          ]
        : []),
    ],
    [otRules, lookupOtRule]
  );

  const togglerConfig = [
    { path: "upcoming", label: "Upcoming" },
    { path: "past", label: "Past" },
  ];

  const tableData = useMemo(() => {
    const customHolidays = originalHolidaySchedule.custom_holidays;
    const todayIso = DateTime.now().toISODate();
    const holidayRows = customHolidays
      .filter((holiday) => {
        const matchTimeTab = activePath === "upcoming" ? holiday.date >= todayIso : holiday.date < todayIso;
        const notDeleted = !holiday.archived;
        return matchTimeTab && notDeleted;
      })
      .sort((a, b) => {
        const aOccursBeforeB = a.date < b.date;
        if (activePath === "upcoming") {
          // sort in ascending date order
          return aOccursBeforeB ? -1 : 1;
        }
        // sort in descending order for past holidays
        return aOccursBeforeB ? 1 : -1;
      })
      .map((holiday) => {
        return {
          ...holiday,
          display_date: DateTime.fromISO(holiday.date).toFormat("DDDD"),
        };
      });
    return holidayRows;
  }, [originalHolidaySchedule, activePath]);

  const handleRowClick = (row) => {
    setShowModal(true);
    setSelectedCustomHoliday(row);
  };

  const renderTable = () => {
    return (
      <TableV2
        data={tableData}
        columns={customHolidayColumns}
        resource="custom-holidays"
        id="custom-holidays-table"
        onClick={handleRowClick}
        customEmptyStateMessage={`No ${activePath === "upcoming" ? "upcoming" : "past"} custom holidays`}
        paginationPageSize={3}
      />
    );
  };

  return (
    <div>
      <div className="flex" style={{ marginTop: 10 }}>
        <div className="flex-1"></div>
        {can("time_off:holidays:manage") && (
          <Button text="New" className="button-2" onClick={() => setShowModal(true)} />
        )}
      </div>
      <Toggler
        config={togglerConfig}
        active={activePath}
        toggle={(tab) => setActivePath(tab as "upcoming" | "past")}
        wrapperStyle={{ marginTop: -5 }}
        className="modal-with-tabs"
      />
      {renderTable()}
      {showModal && (
        <CustomHolidayModal
          selectedCustomHoliday={selectedCustomHoliday}
          setSelectedCustomHoliday={setSelectedCustomHoliday}
          updateHolidaySchedule={updateHolidaySchedule}
          originalHolidaySchedule={originalHolidaySchedule}
          hide={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
