import React, { useState, useEffect } from "react";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { useNavigate } from "react-router-dom";
import { CandidateTableData, formatCandidateTableData } from "dashboard/utils/ats";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { ValueFormatterParams } from "ag-grid-community";

const columns: ColumnConfig<CandidateTableData>[] = [
  {
    headerName: "Name",
    valueFormatter: (params: ValueFormatterParams<CandidateTableData>) => {
      const { first_name, last_name } = params?.data || {
        first_name: "Unknown",
        last_name: "Candidate",
      };
      return `${first_name} ${last_name}`;
    },
  },
  {
    headerName: "Phone",
    field: "phone",
    dataType: "string",
  },
  {
    headerName: "Email",
    field: "email",
    dataType: "string",
  },

  {
    headerName: "Job applications",
    field: "count",
    dataType: "number",
  },
];

export const CandidatesTable: React.FC = () => {
  const navigate = useNavigate();

  const activeCompanyId = useActiveCompanyId();
  const [data, setData] = useState<CandidateTableData[]>([]);

  useEffect(() => {
    const getData = async () => {
      const res = await MiterAPI.candidates.retrieve_many([{ field: "company_id", value: activeCompanyId }]);
      setData(formatCandidateTableData(res));
    };

    getData();
  }, [activeCompanyId]);

  const handleRowClick = (candidate) => {
    navigate(`/recruiting/candidates/${candidate._id}`);
  };

  /*********************************************************
    Functions to render table components
  **********************************************************/

  return (
    <div>
      <TableV2
        id={"job-postings-table"}
        resource="candidates"
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        columns={columns}
        onClick={handleRowClick}
        ssr={false}
        data={data}
      />
    </div>
  );
};
