import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Input, Button, ModalHeader } from "ui";
import { isValidEmail } from "../../utils/validators";

type Props = {
  verifyUniqueEmail: (email: string) => Promise<boolean>;
  updateEmailAndStatus: (email: string) => Promise<void>;
  hide: () => void;
};

const EnableSimplyInsuredModal: React.FC<Props> = ({ verifyUniqueEmail, hide, updateEmailAndStatus }) => {
  const [email, setEmail] = useState<string | null>();
  const [emailError, setEmailError] = useState<string | null>();
  const [saving, setSaving] = useState<boolean | false>();

  const save = async () => {
    setSaving(true);
    if (email && isValidEmail(email)) {
      if (await verifyUniqueEmail(email)) {
        setEmailError("");
        await updateEmailAndStatus(email);
      } else {
        setEmailError("This email is already used by another company, please provide another one.");
      }
    } else {
      setEmailError("Please provide a valid email.");
    }
    setSaving(false);
  };
  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={hide}>
        <div className="modal-wrapper form">
          <ModalHeader heading={"Set Benefit Admin's Email"} onHide={hide} />
          <div
            className="modal-body form overflow"
            style={{
              padding: "20px 20px 0px 20px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flexStart",
            }}
          >
            <div style={{ width: "100%" }}>
              <span>{`Please provide an email that is responsible for receieving information about employee benefits from SimplyInsured.`}</span>
              <div style={{ height: 20 }}></div>
              <div>
                <Input
                  type="text"
                  placeholder={"Email"}
                  onChange={(e) => setEmail(e.target.value)}
                  name="break_minutes"
                  className="modal"
                />
                {emailError && <div style={{ color: "red", fontSize: 14, marginTop: 5 }}>{emailError}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer form">
            <button onClick={hide} className={saving ? "button-1 inactive" : "button-1"}>
              Cancel
            </button>
            <Button className="button-2" loading={saving} onClick={save} text="Save" />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default EnableSimplyInsuredModal;
