import ChangeRequestsTable from "dashboard/components/change-requests/ChangeRequestsTable";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const CreateChangeRequestReport: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="page-content">
      <Helmet>
        <title>Change Requests | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Team member profile change requests</h1>
      </div>
      <ChangeRequestsTable parentType="team_member" shouldRedirectURLWhenOpening={true} />
    </div>
  );
};

export default CreateChangeRequestReport;
