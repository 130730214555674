import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TableV2 } from "ui";
import {
  MiterAPI,
  AssignedTeamMemberOnboardingTask,
  TeamMemberOnboardingTask,
  AggregatedI9,
  AggregatedTeamMemberOnboardingTask,
  AggregatedTeamMember,
} from "dashboard/miter";
import { useRefetchActionableItems, useActiveTeamMember } from "dashboard/hooks/atom-hooks";
import { Notifier } from "dashboard/utils";
import { Check, X, Play } from "phosphor-react";
import { ColumnConfig } from "ui/table-v2/Table";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { getDueDate, getTaskLabel } from "miter-components/onboarding-v2/utils";
import { DateTime } from "luxon";
import { OnboardingChecklistV2AdminWizard } from "miter-components/onboarding-v2/OnboardingChecklistV2AdminWizard";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

export type TeamMemberOnboardingTaskRow = Omit<
  AssignedTeamMemberOnboardingTask,
  "admin_status" | "due_date" | "team_member"
> &
  AggregatedTeamMemberOnboardingTask & {
    team_member: AggregatedTeamMember;
  };

export type OnboardingTaskRow =
  | (AssignedTeamMemberOnboardingTask & {
      i9?: AggregatedI9 | null;
    })
  | (TeamMemberOnboardingTaskRow & {
      i9?: AggregatedI9 | null;
    });

type Props = {
  tasksType: "new_hire" | "admin";
  fetchOnboardingTasks?: () => Promise<AssignedTeamMemberOnboardingTask[]>;
  tasks?: TeamMemberOnboardingTaskRow[];
  refetchTasks?: () => Promise<void>;
  inboxMode?: InboxMode;
};

export const OnboardingTasksTable: React.FC<Props> = ({
  tasks,
  refetchTasks,
  fetchOnboardingTasks,
  inboxMode,
  tasksType = "admin",
}) => {
  const refetchActionableItems = useRefetchActionableItems();
  const activeTeamMember = useActiveTeamMember();
  const [tableData, setTableData] = useState<OnboardingTaskRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<OnboardingTaskRow[]>([]);
  const [taskUpdateLoading, setTaskUpdateLoading] = useState(false);
  const [taskIncompleteLoading, setTaskIncompleteLoading] = useState(false);
  const [taskCompleteLoading, setTaskCompleteLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);
  const [onboardingWizardItems, setOnboardingWizardItems] = useState<OnboardingTaskRow[]>([]);

  const columns: ColumnConfig<OnboardingTaskRow>[] = useMemo(
    () => [
      {
        field: "type",
        headerName: "Task",
        dataType: "string" as const,
        valueFormatter: (params: ValueFormatterParams<OnboardingTaskRow>) => {
          if (!params.data) return "-";
          return getTaskLabel(params.data);
        },
      },
      ...(inboxMode === "needs_attention"
        ? [
            { field: "team_member.friendly_id", headerName: "Team member ID", dataType: "string" as const },
            {
              field: "team_member.full_name",
              headerName: "Team member",
              dataType: "string" as const,
            },
            {
              field: "admin_status",
              headerName: "Status",
              dataType: "string" as const,
              displayType: "badge" as const,
              colors: {
                actionable: "light-green",
                blocked_by_employee_i9: "lightgray",
                missing_start_date: "light-red",
                complete: "light-green",
                incomplete: "light-red",
              },
            },
          ]
        : [
            {
              field: "status",
              headerName: "Status",
              dataType: "string" as const,
              displayType: "badge" as const,
              colors: {
                complete: "light-green",
                incomplete: "light-red",
                actionable: "light-green",
                blocked_by_employee_i9: "lightgray",
                missing_start_date: "light-red",
              },
            },
          ]),
      {
        field: "due_days_from_start",
        headerName: "Due date",
        dataType: "string" as const,
        valueGetter: (params: ValueGetterParams<OnboardingTaskRow>) => {
          // If due_days_from_start is not set, return a default message
          if (params.data?.due_days_from_start == null) {
            return "No due date set";
          }

          const startDate = params.data?.team_member?.start_date;

          // If start date is available, calculate the due date
          // Otherwise, return null (will be handled in valueFormatter)
          return startDate ? getDueDate(startDate, params.data.due_days_from_start) : null;
        },
        valueFormatter: (params: ValueFormatterParams<OnboardingTaskRow>) => {
          // Handle null cases
          if (params.value == null) return "-";
          if (params.value === "No due date set") return params.value;

          // Format the date as "MMM dd, yyyy" (e.g., "Jan 01, 2023")
          return DateTime.fromISO(params.value).toFormat("LLL dd, yyyy");
        },
        sortable: true,
      },
    ],
    [inboxMode]
  );

  const refetchOnboardingTasksData = useCallback(async () => {
    if (fetchOnboardingTasks) {
      const tasks = await fetchOnboardingTasks();
      setTableData(tasks);
    } else if (refetchTasks) {
      await refetchTasks();
    }
    setDataLoading(false);
  }, [fetchOnboardingTasks, refetchTasks]);

  useEffect(() => {
    if (inboxMode !== "needs_attention") {
      setTableData(tasks || []);
      setDataLoading(false);
      return;
    }
    refetchOnboardingTasksData();
  }, [inboxMode, refetchOnboardingTasksData, tasks]);

  const createTaskUpdater = useCallback(
    (checklistId: string) =>
      async (updatedTask: Partial<OnboardingTaskRow>): Promise<void> => {
        try {
          const response = await MiterAPI.team_member_onboarding_checklists.update_tasks(checklistId, {
            updatedTasks: [updatedTask as TeamMemberOnboardingTask],
            requestorTeamMemberId: activeTeamMember?._id,
          });
          if (response.error) throw new Error(response.error);
          await refetchOnboardingTasksData();
        } catch (error) {
          console.error("Error updating task", error);
          Notifier.error("Error updating task.");
        }
      },
    [refetchOnboardingTasksData, activeTeamMember]
  );

  const getI9ForTask = useCallback(async (task: OnboardingTaskRow): Promise<AggregatedI9 | undefined> => {
    if (task.type !== "i9_employer") return undefined;

    try {
      const res = await MiterAPI.i_9s.search([{ field: "team_member_id", value: task.team_member._id }]);
      if (res.error) throw new Error(res.error);

      if (res.length > 0 && res[0]) {
        return res[0];
      }
    } catch (error) {
      Notifier.error("Failed to get I-9");
    }
    return undefined;
  }, []);

  const completeTasks = useCallback(async () => {
    setTaskUpdateLoading(true);
    const tasksWithI9s = await Promise.all(
      selectedRows.map(async (task) => {
        const i9 = await getI9ForTask(task);
        return { ...task, i9 };
      })
    );
    setOnboardingWizardItems(tasksWithI9s);
    setShowOnboardingWizard(true);
    setTaskUpdateLoading(false);
  }, [selectedRows, getI9ForTask]);

  const onRowClick = useCallback(
    async (row: OnboardingTaskRow) => {
      if (tasksType !== "admin") return;
      const i9 = await getI9ForTask(row);
      setOnboardingWizardItems([{ ...row, i9 }]);
      setShowOnboardingWizard(true);
    },
    [getI9ForTask, tasksType]
  );

  // Manually mark tasks as complete
  const markTasksAsComplete = useCallback(async () => {
    if (selectedRows.every((task) => task.status === "complete")) {
      Notifier.error("All selected tasks are already complete.");
      return;
    }
    try {
      setTaskCompleteLoading(true);
      // Group the onboarding tasks by checklist_id
      const checklistIdToTasks: Record<string, AssignedTeamMemberOnboardingTask[]> = selectedRows.reduce(
        (acc, task) => {
          acc[task.team_member_onboarding_checklist_id] = acc[task.team_member_onboarding_checklist_id] || [];
          acc[task.team_member_onboarding_checklist_id].push(task);
          return acc;
        },
        {}
      );
      // Update each checklist with the selected tasks
      await Promise.all(
        Object.entries(checklistIdToTasks).map(([checklistId, tasks]) =>
          MiterAPI.team_member_onboarding_checklists.update_tasks(checklistId, {
            updatedTasks: tasks.map((task) => ({
              _id: task._id,
              status: "complete",
              completed_by: "admin",
            })),
            requestorTeamMemberId: activeTeamMember?._id,
          })
        )
      );
      if (
        inboxMode !== "needs_attention" &&
        tasksType === "new_hire" &&
        selectedRows.length > 0 &&
        selectedRows.length === tasks?.length
      ) {
        // If all new hire tasks are complete, mark the checklist as complete
        await MiterAPI.team_member_onboarding_checklists.update(
          selectedRows[0]!.team_member_onboarding_checklist_id,
          {
            completed_at: Math.floor(DateTime.now().toSeconds()),
          }
        );
      }

      Notifier.success("Tasks completed successfully");
      setSelectedRows([]);
      await refetchOnboardingTasksData();
      // Refetch the actionable items counts
      await refetchActionableItems();
    } catch (error) {
      Notifier.error("Failed to complete tasks");
    }
    setTaskCompleteLoading(false);
  }, [
    selectedRows,
    inboxMode,
    tasksType,
    tasks?.length,
    refetchOnboardingTasksData,
    refetchActionableItems,
    activeTeamMember?._id,
  ]);

  // Manually mark tasks as incomplete
  const markTasksAsIncomplete = useCallback(async () => {
    if (selectedRows.every((task) => task.status === "incomplete")) {
      Notifier.error("All selected tasks are already incomplete.");
      return;
    }
    try {
      setTaskIncompleteLoading(true);
      // Group the onboarding tasks by checklist_id
      const checklistIdToTasks: Record<string, AssignedTeamMemberOnboardingTask[]> = selectedRows.reduce(
        (acc, task) => {
          acc[task.team_member_onboarding_checklist_id] = acc[task.team_member_onboarding_checklist_id] || [];
          acc[task.team_member_onboarding_checklist_id].push(task);
          return acc;
        },
        {}
      );
      // Update each checklist with the selected tasks
      await Promise.all(
        Object.entries(checklistIdToTasks).map(async ([checklistId, tasks]) => {
          await MiterAPI.team_member_onboarding_checklists.update_tasks(checklistId, {
            updatedTasks: tasks.map((task) => ({
              _id: task._id,
              status: "incomplete",
            })),
            requestorTeamMemberId: activeTeamMember?._id,
          });

          // Update the completed_at field to null since checklist is no longer complete
          if (tasksType === "new_hire") {
            await MiterAPI.team_member_onboarding_checklists.update(checklistId, {
              completed_at: null,
            });
          }
        })
      );

      Notifier.success("Tasks marked as incomplete successfully");
      setSelectedRows([]);
      await refetchOnboardingTasksData();
      // Refetch the actionable items counts
      await refetchActionableItems();
    } catch (error) {
      Notifier.error("Failed to mark tasks as incomplete");
    }
    setTaskIncompleteLoading(false);
  }, [selectedRows, tasksType, refetchOnboardingTasksData, refetchActionableItems, activeTeamMember?._id]);

  const dynamicActions = useMemo(() => {
    return [
      {
        label: "Mark incomplete",
        className: `button-3 table-button`,
        action: markTasksAsIncomplete,
        icon: <X weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => selectedRows.length > 0 && inboxMode !== "needs_attention",
        loading: taskIncompleteLoading,
      },
      {
        label: "Mark complete",
        className: `button-4 table-button`,
        action: markTasksAsComplete,
        icon: <Check weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => selectedRows.length > 0 && inboxMode !== "needs_attention",
        loading: taskCompleteLoading,
      },
      {
        label: "Complete admin tasks",
        className: `button-2 table-button`,
        action: completeTasks,
        icon: <Play weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => selectedRows.length > 0 && tasksType === "admin",
        loading: taskUpdateLoading,
      },
    ];
  }, [
    selectedRows,
    taskUpdateLoading,
    completeTasks,
    tasksType,
    inboxMode,
    markTasksAsComplete,
    markTasksAsIncomplete,
    taskCompleteLoading,
    taskIncompleteLoading,
  ]);

  const renderWizard = () => {
    return (
      <OnboardingChecklistV2AdminWizard
        onComplete={() => {
          Notifier.success("Completed selected tasks.");
          setShowOnboardingWizard(false);
          setOnboardingWizardItems([]);
        }}
        onExit={() => {
          setShowOnboardingWizard(false);
          setOnboardingWizardItems([]);
        }}
        createTaskUpdater={createTaskUpdater}
        onboardingTasks={onboardingWizardItems}
        getI9ForTask={getI9ForTask}
      />
    );
  };

  return (
    <>
      {showOnboardingWizard && renderWizard()}
      <TableV2
        id="onboarding-tasks-table"
        title="Admin onboarding tasks"
        resource="onboarding tasks"
        columns={columns}
        data={tableData}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        wrapperClassName="base-ssr-table"
        dynamicActions={dynamicActions}
        containerClassName="onboarding-tasks-table-container"
        onClick={onRowClick}
        isLoading={dataLoading}
        showReportViews={true}
      />
    </>
  );
};
