import React from "react";
import { ActionModal, Formblock, Notifier } from "ui";
import { TeamMember } from "backend/models";
import { AggregatedTeamMember } from "dashboard/miter";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { useBenefitsEligibilityGroupOptions } from "dashboard/hooks/atom-hooks";
import { BENEFITS_ELIGIBILITY_STATUS_OPTIONS } from "./options";

type Props = {
  defaultData: AggregatedTeamMember;
  form: UseFormMethods<FieldValues>;
  onHide: () => void;
  onSubmit: (
    update: Pick<TeamMember, "benefits_eligibility_status" | "benefits_eligibility_groups">,
    scheduleChanges: boolean
  ) => void;
  submitting: boolean;
  submitText?: string;
};

export const BenefitsForm: React.FC<Props> = ({ defaultData, onHide, onSubmit, submitting, form }) => {
  const { control, errors, handleSubmit } = form;
  const benefitsEligibilityGroupOptions = useBenefitsEligibilityGroupOptions();

  const cleanDataForUpdate = (data) => {
    const update: Partial<TeamMember> = {
      benefits_eligibility_status: data.benefits_eligibility_status?.value || null,
      benefits_eligibility_groups: data.benefits_eligibility_groups?.map((group) => group.value) || null,
    };

    for (const key of Object.keys(update)) {
      if (update[key] === defaultData[key]) {
        delete update[key];
      }
    }

    if (Object.keys(update).length === 0) {
      Notifier.error("No changes detected");
      return;
    }

    onSubmit(update, false);
  };

  return (
    <ActionModal
      headerText={defaultData.first_name + "'s benefits info"}
      onHide={onHide}
      onCancel={onHide}
      onSubmit={handleSubmit(cleanDataForUpdate)}
      loading={submitting}
      submitText="Save"
      showSubmit={true}
      showCancel={true}
    >
      <div className="vertical-spacer-small"></div>
      <Formblock
        label="Benefits eligibility override"
        labelInfo="By default, all employees enrolled for payroll are eligible for benefits (with SSNs, DOBs, start dates, addresses). This field allows you to override that setting for EEs unenrolled in payroll, or for contractors that may be benefits eligible."
        defaultValue={defaultData?.benefits_eligibility_status}
        type="select"
        name="benefits_eligibility_status"
        isClearable={true}
        options={BENEFITS_ELIGIBILITY_STATUS_OPTIONS}
        control={control}
        errors={errors}
        className="modal small-margin"
        editing={true}
      />
      <div className="vertical-spacer-small"></div>
      {!!benefitsEligibilityGroupOptions.length && (
        <Formblock
          label="Benefits eligibility groups*"
          labelInfo="The benefits eligibility groups that this employee is in."
          defaultValue={defaultData?.benefits_eligibility_groups}
          type="multiselect"
          name="benefits_eligibility_groups"
          isClearable={true}
          options={benefitsEligibilityGroupOptions}
          control={control}
          errors={errors}
          className="modal small-margin"
          editing={true}
          requiredSelect={true}
        />
      )}
    </ActionModal>
  );
};
