/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { UseFormMethods, useWatch } from "react-hook-form";
import { PermissionGroupForm } from "./PermissionGroupModal";
import styles from "./PermissionGroups.module.css";
import { Formblock } from "ui";
import { PermissionGroup } from "dashboard/miter";
import { PermissionGroupScopeSection } from "./PermissionGroupScopeSection";

type Props = {
  form: UseFormMethods<PermissionGroupForm>;
  permissionGroup?: PermissionGroup;
};

export const PermissionGroupScopes: React.FC<Props> = ({ form, permissionGroup }) => {
  const formData = form.watch();
  useWatch({ control: form.control });

  // Super admins have full access to all data across the dashboard
  if (permissionGroup?.super_admin) return <></>;

  return (
    <div className={styles["permission-group-scopes"]}>
      <Formblock
        type="radio"
        form={form}
        className="modal permission-group-scopes"
        name="scope_type"
        editing={true}
        options={SCOPE_TYPE_OPTIONS}
        onChange={() => form.setValue("scopes", null)}
      />

      {formData.scope_type && formData.scope_type !== "full" && (
        <PermissionGroupScopeSection
          form={form}
          teamMemberScopeName="scopes.global.team_member"
          jobScopeName="scopes.global.job"
          scopeType={formData.scope_type}
        />
      )}
    </div>
  );
};

const SCOPE_TYPE_OPTIONS = [
  {
    label: (
      <>
        <b>Full access:</b> unrestricted access data for all team members and jobs
      </>
    ),
    value: "full",
  },
  {
    label: (
      <>
        <b>Team scoped access: </b> restrict access to specific team members' data
      </>
    ),
    value: "team_member",
  },
  {
    label: (
      <>
        <b>Job scoped access:</b> restrict access to specific jobs' data
      </>
    ),
    value: "job",
  },
];

// Commenting out module scoped access for now - not currently used
// {
//   label: (
//     <>
//       <b>Module scoped access:</b> restrict access to data on a module by module basis.
//     </>
//   ),
//   value: "module",
// },
//
// const _renderModuleScopes = () => {
//   return (
//     <>
//       <PermissionGroupScopeSection
//         title="Human Resources Scopes"
//         labelInfo="Specify whose HR data this permission group can access on the dashboard"
//         form={form}
//         teamMemberScopeName="scopes.human_resources.team_member"
//       />
//       <PermissionGroupScopeSection
//         title="Workforce Management Scopes"
//         labelInfo="Specify whose timesheets, schedules, and daily reports this permission group can access on the dashboard"
//         form={form}
//         teamMemberScopeName="scopes.workforce_management.team_member"
//         jobScopeName="scopes.workforce_management.job"
//       />
//       <PermissionGroupScopeSection
//         title="Expense Management Scopes"
//         labelInfo="Specify whose expenses data this permission group can access on the dashboard"
//         form={form}
//         teamMemberScopeName="scopes.expense_management.team_member"
//         jobScopeName="scopes.expense_management.job"
//       />
//       <PermissionGroupScopeSection
//         title="Payroll & Compliance Scopes"
//         labelInfo="Specify whose payroll data this permission group can access on the dashboard"
//         form={form}
//         teamMemberScopeName="scopes.payroll_and_compliance.team_member"
//       />
//       <PermissionGroupScopeSection
//         title="Company Scopes"
//         labelInfo="Specify whose forms and chat conversations this permission group can access on the dashboard"
//         form={form}
//         teamMemberScopeName="scopes.company.team_member"
//       />
//     </>
//   );
// };
