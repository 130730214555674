import { useActiveCompany, useLedgerAccountOptions } from "dashboard/hooks/atom-hooks";
import { AggregatedCardProgram, CardProgram, MiterAPI } from "dashboard/miter";
import { useHasIntegration } from "dashboard/utils/useHasIntegration";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Params, useParams } from "react-router-dom";
import { Notifier, SettingsCard, Formblock, Breadcrumbs, Loader, Button, DeleteModal } from "ui";
import { useNavigate } from "react-router-dom";
import { CardProgramIntegrations } from "./integrations/CardProgramIntegrations";
import { useDebouncedCallback } from "use-debounce";

export const MIN_LABEL_WIDTH = 300;

const CardProgramSettings: React.FC = () => {
  // hooks
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;
  const { id: cardProgramId } = useParams<Params>();
  const ledgerAccountOptions = useLedgerAccountOptions();
  const integrationKeyToIntegrationMap = useHasIntegration();
  const navigate = useNavigate();

  const sageIntacctConnectionId = integrationKeyToIntegrationMap.get("sage_intacct")?._id;
  const acumaticaConnectionId = integrationKeyToIntegrationMap.get("acumatica")?._id;
  const qboConnectionId = integrationKeyToIntegrationMap.get("qbo")?._id;

  // state
  const [cardProgram, setCardProgram] = useState<AggregatedCardProgram>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [archiveLoading, setarchiveLoading] = useState(false);

  const handleArchive = async () => {
    if (!cardProgram) return;

    setarchiveLoading(true);
    try {
      const response = await MiterAPI.expenses.card_programs.archive(cardProgram._id);
      if (response.error) throw new Error(response.error);
      Notifier.success("Card program deleted.");

      // navigate back to list of card programs
      navigate("/spend/cards/programs");
    } catch (e) {
      Notifier.error("There was an error deleting your card program. The Miter team has been notified.");
    }
    setarchiveLoading(false);
  };

  useEffect(() => {
    const getCardProgram = async () => {
      if (!activeCompanyId || !cardProgramId) return;
      const cardProgram = await MiterAPI.expenses.card_programs.list({
        filter: [
          { field: "company_id", value: activeCompanyId },
          { field: "_id", value: cardProgramId },
        ],
      });
      setCardProgram(cardProgram[0]);
    };

    getCardProgram();
  }, [activeCompanyId, cardProgramId]);

  const updateCardProgram = async (update: Partial<CardProgram>) => {
    try {
      const response = await MiterAPI.expenses.card_programs.update({
        _id: cardProgramId,
        ...update,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");

      // update
      setCardProgram(response);
    } catch (e) {
      Notifier.error("There was an error updating your settings. Our engineers are looking into it!");
    }
  };

  const debouncedUpdate = useDebouncedCallback(updateCardProgram, 500);

  const renderBreadcrumbs = () => {
    if (!cardProgram) return;

    return (
      <Breadcrumbs
        crumbs={[
          { label: "Card Programs", path: "/spend/cards/programs" },
          { label: cardProgram?.name, path: `/spend/cards/programs/${cardProgram?._id}` },
          { label: "Settings", path: `/spend/cards/programs/settings/${cardProgram?._id}` },
        ]}
      />
    );
  };

  if (!cardProgram) return <Loader />;

  // if SIC customer, don't show GL setting because it's superseded by SIC
  // however, always show this if QBO customer
  const showLiabilityGL = cardProgram.type === "miter_card" || !!qboConnectionId || !sageIntacctConnectionId;

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{cardProgram.name} settings | Miter</title>
      </Helmet>
      <div className="page-content">
        <div>
          {renderBreadcrumbs()}
          <h1>{cardProgram.name} settings</h1>
          {showLiabilityGL && (
            <SettingsCard title={"Accounting"}>
              <div style={{ maxWidth: 600 }}>
                <Formblock
                  labelStyle={{ minWidth: MIN_LABEL_WIDTH }}
                  type="select"
                  label={cardProgram.type === "miter_card" ? "Asset GL account" : "Liability GL account"}
                  labelInfo={
                    cardProgram.type === "miter_card"
                      ? "The GL account used to represent funds stored in Miter to fund cards purchases."
                      : "The GL account representing the card program liability."
                  }
                  name="ledger_account_id"
                  onChange={(o) => debouncedUpdate({ ledger_account_id: o?.value || null })}
                  editing={true}
                  options={ledgerAccountOptions}
                  defaultValue={cardProgram.ledger_account_id}
                  isClearable
                />
              </div>
            </SettingsCard>
          )}
          <CardProgramIntegrations
            updateCardProgram={updateCardProgram}
            cardProgram={cardProgram}
            acumaticaConnectionId={acumaticaConnectionId}
            sageIntacctConnectionId={sageIntacctConnectionId}
          />
          <SettingsCard title={"General"}>
            <div style={{ maxWidth: 600 }}>
              <Formblock
                type="text"
                label="Name"
                labelStyle={{ minWidth: MIN_LABEL_WIDTH }}
                name="name"
                onChange={(o) => {
                  if (!o?.target.value) return;

                  debouncedUpdate({ name: o?.target?.value || null });
                }}
                editing={true}
                defaultValue={cardProgram.name}
              />

              <Button
                className="button-3 no-margin"
                text="Delete card program"
                onClick={() => setShowDeleteModal(true)}
                disabled={cardProgram.type === "miter_card"}
              />
            </div>
          </SettingsCard>
        </div>
        {showDeleteModal && (
          <DeleteModal
            header={"Delete card program"}
            body={
              "Deleting this program will delete all corresponding cards and disconnect Miter from your issuing bank. Existing transactions will not be affected."
            }
            deleteText={"Delete"}
            onHide={() => setShowDeleteModal(false)}
            onDelete={handleArchive}
            loading={archiveLoading}
          />
        )}
      </div>
    </div>
  );
};

export default CardProgramSettings;
