import { AggregatedTeamMemberWithI9, MiterAPI } from "dashboard/miter";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";
import { FilePickerFile } from "ui/form/FilePicker";

import * as vals from "dashboard/utils/validators";
import { formatFilesForUpload } from "miter-utils";
import { DateTime } from "luxon";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {
  onCancel: () => void;
  onFinish: () => void;
  teamMember: AggregatedTeamMemberWithI9;
};

type UpdateI9FormFields = { files?: FilePickerFile[]; date?: DateTime };
const UpdateI9Modal: FC<Props> = ({ onCancel, onFinish, teamMember }) => {
  const activeCompanyId = useActiveCompanyId();

  const form = useForm<UpdateI9FormFields>();
  const [saving, setSaving] = useState(false);

  const saveI9 = async (data: UpdateI9FormFields) => {
    setSaving(true);
    try {
      if (!data.files) throw new Error("No file selected");
      if (!activeCompanyId) throw new Error("No active role");

      // Create file
      const formattedFiles = formatFilesForUpload(data.files, activeCompanyId).map((file) => {
        return {
          ...file,
          team_member: teamMember._id,
          label: DateTime.now().year + " I-9",
        };
      });

      const createFileRes = await MiterAPI.files.upload({ files: formattedFiles });
      if (!createFileRes[0]) throw new Error("Unable to upload I-9");

      // Archive old I-9 (if it exists)
      if (teamMember.I9) {
        const archivedI9 = await MiterAPI.i_9s.delete(teamMember.I9._id);
        if (archivedI9.error) throw new Error(archivedI9.error);
      }

      const dateOfCompletion = data?.date?.toISODate();

      if (!dateOfCompletion) throw new Error("No date of completion");

      // Create a new I-9 with the new file
      const createdFile = createFileRes[0];
      const newI9 = await MiterAPI.i_9s.create({
        company_id: activeCompanyId,
        team_member_id: teamMember._id,
        cached_pdf_id: createdFile.file._id,
        manual: true,
        status: "complete",
        employer_completion_date: dateOfCompletion,
      });
      if (newI9.error) throw new Error(newI9.error);

      Notifier.success("I-9 updated");
      onFinish();
    } catch (e: $TSFixMe) {
      console.error("Error  I9:", e);
      Notifier.error("Error updating I9. Please try again or contact support.");
    }

    setSaving(false);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    await form.handleSubmit(saveI9)();
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          name={"files"}
          className="modal wizard"
          label="Upload a new I-9"
          type="file"
          form={form}
          multiple={true}
          maxFilesAllowed={1}
          variant="dropzone"
          editing={true}
          compact={true}
          dropzoneLabel="Drag and drop or click to upload the I-9"
          val={vals.required}
          labelStyle={{ marginBottom: 10 }}
        />
        <Formblock
          label="Date of the I-9's completion"
          type="datetime"
          dateOnly={true}
          name="date"
          rules={vals.required}
          form={form}
          className="double time-off-request-date"
          editing={true}
          max={DateTime.now()}
          defaultValue={null}
          customFormat="MMM dd, yyyy"
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={"Update I-9"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Cancel"}
      onCancel={handleCancel}
      submitText={"Save"}
      onHide={handleCancel}
      onSubmit={handleSubmit}
      loading={saving}
      wrapperStyle={{ width: 500 }}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default UpdateI9Modal;
