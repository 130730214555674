import { useHasAccessToAccessFilters } from "dashboard/gating";
import { useAccessFilter } from "dashboard/hooks/access-filters/useAccessFilter";
import { Activity, AggregatedTeamMember, TeamMember } from "dashboard/miter";
import { useCallback, useMemo } from "react";

export const isTimesheetScoped = (activity: Activity): boolean => {
  return activity.scopes.includes("timesheets");
};

export const isExpenseScoped = (activity: Activity): boolean => {
  return activity.scopes.includes("expenses");
};

export const isReimbursementScoped = (activity: Activity): boolean => {
  return activity.scopes.includes("reimbursements");
};

type AccessFilterScoped = {
  teamMember?: AggregatedTeamMember | TeamMember;
};

type AccessFilterScopedResults = {
  isAccessFilterScoped: (activity: Activity) => boolean;
  isAccessFilterScopedWithTeamMember: (
    activity: Activity,
    teamMember: AggregatedTeamMember | TeamMember
  ) => boolean;
};

export const useAccessFilterScoped = ({ teamMember }: AccessFilterScoped): AccessFilterScopedResults => {
  const accessFilters = useAccessFilter({ entityName: "activity" });
  const hasAccessToAccessFilters = useHasAccessToAccessFilters();

  const isAccessFilterScoped = useCallback(
    (activity: Activity): boolean => {
      if (!hasAccessToAccessFilters) return true;

      if (!teamMember) return true;
      const teamMemberIds = accessFilters.teamMemberIds({ entity: activity });

      return teamMemberIds.has(teamMember._id);
    },
    // tsc complains about the use of teamMember?._id
    // and wants it to be teamMember
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAccessToAccessFilters, accessFilters, teamMember?._id]
  );

  const isAccessFilterScopedWithTeamMember = useCallback(
    (activity: Activity, teamMember: AggregatedTeamMember | TeamMember): boolean => {
      if (!hasAccessToAccessFilters) return true;

      if (!teamMember) return true;
      const teamMemberIds = accessFilters.teamMemberIds({ entity: activity });

      return teamMemberIds.has(teamMember._id);
    },
    [hasAccessToAccessFilters, accessFilters]
  );
  return useMemo(
    () => ({ isAccessFilterScoped, isAccessFilterScopedWithTeamMember }),
    [isAccessFilterScoped, isAccessFilterScopedWithTeamMember]
  );
};
