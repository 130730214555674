import React, { useState } from "react";
import { formatDate } from "dashboard/utils/utils";
import PayrollContext from "../payrollContext";
import checkmark from "dashboard/assets/check-mark.png";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { BasicModal, usdString } from "ui";
import { DateTime } from "luxon";
import { useRefetchTeam, useSetConfetti, useTeam } from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import { useNavigate } from "react-router-dom";
import { sleep } from "miter-utils";

const ApprovePayrollModal: React.FC<{ hide: () => void; onEscapePreview: () => void }> = ({
  hide,
  onEscapePreview,
}) => {
  const teamMembers = useTeam();
  const refetchTeam = useRefetchTeam();
  const { payroll, getPayroll, setPayroll } = React.useContext(PayrollContext);
  const { fetchUserData } = React.useContext(AppContext);
  const setConfetti = useSetConfetti();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fed100kError, setFed100kError] = useState(false);
  const [blockedEmployeeError, setBlockedEmployeeError] = useState<string>();
  const [togglingFundMethod, setTogglingFundMethod] = useState(false);

  if (!payroll) return null;

  const checkPayroll = payroll.check_payroll;

  const fundingByWire = checkPayroll.funding_payment_method === "wire";
  const cashRequirement = usdString(checkPayroll?.totals?.cash_requirement);
  const deadline =
    fundingByWire && checkPayroll.approval_deadline
      ? DateTime.fromISO(checkPayroll.approval_deadline).toFormat("t ZZZZ 'on' DD")
      : formatDate(checkPayroll.approval_deadline, undefined, true);

  const approve = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.payrolls.approve(payroll._id.toString());
      if (response.error) {
        if (response.error_type === "employee_net_pay_split_deactivated") {
          const checkTmId = (response.error as string).match(/((emp_)|(ctr_))[\w\d]+/)?.[0];
          const tm = teamMembers.find((t) => t.check_id === checkTmId);
          Notifier.error(
            `${
              tm?.full_name || "A team member"
            } has a deactivated bank account. Please confirm their pay method settings on their profile`
          );
        } else if (response.error_type === "federal_tax_liability_exceeds_100k") {
          setFed100kError(true);
        } else if (response.error_type === "miter_check_payroll_mismatch") {
          Notifier.error(
            `There is an issue with the payroll, likely due to multiple users making changes simultaneously. Please ensure only one person is making changes, refresh the page, and try again.`,
            { duration: 10000 }
          );
        } else if (
          response.error_type === "payroll_date_invalid" ||
          response.error_type === "payday_invalid"
        ) {
          Notifier.error(response.error);
        } else if (response.error_type === "payroll_approval_deadline_expired") {
          Notifier.error("The payroll approval deadline has passed. Please change the payday and try again.");
        } else if (response.error_type === "payroll_already_approved") {
          Notifier.warning("Payroll was already approved");
          return successExit();
        } else if (
          response.error_type === "employee_blocking" ||
          response.error_type === "contractor_blocking"
        ) {
          await Promise.all([fetchUserData(), refetchTeam()]);
          await sleep(0); // sleep here so we guarantee that `teamMembers` has been updated by the time `blockedEmployeeError` is truthy
          setBlockedEmployeeError(response.error);
        } else if (response.error_type === "company_blocking") {
          await fetchUserData();
          Notifier.error(
            "Please complete the required steps of your company onboarding before approving payroll"
          );
          navigate("/onboarding");
        } else {
          throw new Error(response.error);
        }
      } else {
        setConfetti(true);
        setSuccess(true);
      }
    } catch (e: $TSFixMe) {
      console.error("Error approving payroll.", e);
      Notifier.error("There was an error approving the payroll. " + e.message);
    }
    setLoading(false);
  };

  const successExit = async () => {
    setLoading(true);
    await getPayroll();
    setLoading(false);
  };

  const handleToggleFundingMethod = async () => {
    setTogglingFundMethod(true);
    try {
      const response = await MiterAPI.payrolls.update_payroll(payroll._id, {
        funding_payment_method: fundingByWire ? "ach" : "wire",
      });
      if (response.error) throw new Error(response.error);
      setPayroll((prev) => {
        if (!prev) return;
        // Need to keep most of our existing CheckPayroll because it's the previewed version (with totals, benefits, taxes, etc.), and we don't want to lose those
        const newCheckPayroll = {
          ...prev.check_payroll,
          funding_payment_method: response.check_payroll.funding_payment_method,
          approval_deadline: response.check_payroll.approval_deadline,
        };
        return { ...prev, check_payroll: newCheckPayroll };
      });
      setFed100kError(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error changing the funding method");
    }
    setTogglingFundMethod(false);
  };

  const renderWireFundingRequiredModal = () => {
    return (
      <BasicModal
        headerText="Wire funding required"
        button1Text={"Cancel"}
        button1Action={hide}
        button2Text={"Fund by wire"}
        button2Action={handleToggleFundingMethod}
        button2Loading={togglingFundMethod}
        showCloseX
        onHide={hide}
        wrapperStyle={{ width: 450 }}
      >
        <div className="yellow-text-container">
          By approving this payroll, you will have accumulated $100,000 or more in federal employment taxes
          within your tax deposit period. Due to the{" "}
          <a
            href="https://www.irs.gov/publications/p15#en_US_2023_publink1000202463"
            target="_blank"
            rel="noreferrer"
          >
            $100,000 Next-Day Deposit Rule
          </a>
          , we must deposit the full tax by the business day following this payroll&apos;s payday.{" "}
          <strong>As such, we require that you fund this payroll via wire payment</strong> instead of the
          normal ACH debit.
          <br />
          <br />
          Selecting &quot;Fund by wire&quot; below <strong>will not</strong> automatically approve the
          payroll. You&apos;ll have an opportunity to review the payroll before giving final approval.
        </div>
      </BasicModal>
    );
  };

  const renderSuccessBody = () => {
    return (
      <div className="payroll-approval-success-text">
        <img src={checkmark} className="success-checkmark" />
        <div>
          <span>Success! The payroll was successfully approved. </span>
          {fundingByWire && <span>Please click &quot;Done&quot; to view the wire details.</span>}
        </div>
      </div>
    );
  };

  const renderWireFundingApprovalBody = () => {
    return (
      <span>
        You have chosen to fund this payroll via wire.{" "}
        <strong>
          You are responsible for authorizing a wire payment of {cashRequirement} by {deadline}.
        </strong>{" "}
        Team member payments will only be sent out on receipt of the wire. We cannot guarantee payrolls being
        paid out on time if the wire is initiated past this deadline.
        <br />
        <br />
        Upon approval, we will provide account and routing number information that you should use when
        directing your bank to send the wire.
        <br />
        <br />
        Since this is wire-funded, once this payroll is approved,{" "}
        <strong>you will not be able to reopen the payroll.</strong>
        <br />
        <br />
        You may switch back to normal ACH funding by clicking below, but please note{" "}
        <strong>you are required to fund via wire</strong> if your federal tax liability exceeds $100,000.
        Only switch back to ACH if your liability no longer exceeds the threshold.
      </span>
    );
  };

  const renderAchFundingApprovalBody = () => {
    return (
      <span>
        Once this payroll is approved, Miter will debit <strong>{cashRequirement}</strong> from your account
        on <strong>{deadline}</strong>. Please make sure you have these funds available in your account.
      </span>
    );
  };

  if (fed100kError && !fundingByWire) return renderWireFundingRequiredModal();

  if (blockedEmployeeError) {
    const checkId = /(?:emp|ctr)_[a-zA-Z0-9]{20}/.exec(blockedEmployeeError)?.[0] || "";
    const onboardedPayments = new Set(
      payroll.miter_payments.filter((mp) => mp.onboarded).map((mp) => mp.team_member._id)
    );
    const tms = teamMembers
      .filter(
        (tm) =>
          tm.check_id === checkId ||
          (tm.check_tm?.onboard.status === "blocking" && onboardedPayments.has(tm._id))
      )
      .map((tm) => tm.full_name);

    const formatter = new Intl.ListFormat("en", { style: "long", type: "conjunction" });

    const action = () => {
      getPayroll();
      hide();
      onEscapePreview();
    };

    return (
      <BasicModal
        headerText="Warning!"
        bodyText={`${formatter.format(tms)} ${
          tms.length > 1 ? "are" : "is"
        } blocked from payroll, please complete their onboarding and check once more that their payment(s) are included before approving.`}
        yellowBodyText
        button2Text="Okay"
        button2Action={action}
      />
    );
  }

  return (
    <BasicModal
      headerText="Approve payroll"
      button1Text={success ? null : fundingByWire ? "Fund by ACH" : "Cancel"}
      button1Action={!success && fundingByWire ? handleToggleFundingMethod : hide}
      button1Loading={togglingFundMethod}
      button2Text={success ? "Done" : "Approve"}
      button2Action={success ? successExit : approve}
      button2Loading={loading}
      showCloseX
      onHide={hide}
      wrapperStyle={{ width: !success && fundingByWire ? 600 : 350 }}
    >
      {success ? (
        renderSuccessBody()
      ) : (
        <div className="yellow-text-container">
          {fundingByWire ? renderWireFundingApprovalBody() : renderAchFundingApprovalBody()}
        </div>
      )}
    </BasicModal>
  );
};

export default ApprovePayrollModal;
