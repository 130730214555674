import { AggregatedExpenseReimbursement, AggregatedJob, AggregatedTeamMember, Job } from "dashboard/miter";
import { ExpenseReimbursementEntry } from "dashboard/pages/expenses/ExpenseReimbursementsTable";
import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { BulkEditedReimbursement } from "dashboard/pages/expenses/expenseUtils";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

/*
 * EXPENSE REIMBURSEMENTS
 */
type ExpenseReimbursementAbilitiesOpts = { inboxMode?: InboxMode };

type ExpenseReimbursementParams =
  | AggregatedExpenseReimbursement
  | AggregatedExpenseReimbursement[]
  | ExpenseReimbursementEntry
  | ExpenseReimbursementEntry[]
  | BulkEditedReimbursement
  | BulkEditedReimbursement[]
  | undefined;

type ExpenseReimbursementAction = "create" | "read" | "update" | "approve" | "delete" | "process_payment";
type ExpenseReimbursementAbilities = {
  can: (action: ExpenseReimbursementAction, items: ExpenseReimbursementParams) => boolean;
  cannot: (action: ExpenseReimbursementAction, items: ExpenseReimbursementParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: ExpenseReimbursementAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: ExpenseReimbursementAction) => (job: AggregatedJob | Job) => boolean;
};

export const useExpenseReimbursementAbilities = (
  opts?: ExpenseReimbursementAbilitiesOpts
): ExpenseReimbursementAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: ExpenseReimbursementAction, items?: ExpenseReimbursementParams) => {
      if (!items) return false;

      const reimbursements = Array.isArray(items) ? items : [items];

      // If we are in inbox mode, we can always read, approve, and update anything in the inbox
      const isApprover = opts?.inboxMode === "approval";

      // either approval or needs attention mode
      const isInboxMode = opts?.inboxMode != undefined;

      return reimbursements.every((reimbursement) => {
        const isPersonal = reimbursement.team_member_id === activeTeamMember?._id;
        const opts = { teamMember: getTeamMemberId(reimbursement), job: getJobId(reimbursement) };

        if (isPersonal) {
          switch (action) {
            case "create":
              return can_("reimbursements:personal:create");
            case "read":
              return can_("reimbursements:personal:read") || isInboxMode;
            case "approve":
              return can_("reimbursements:personal:approve") || isApprover;
            case "update":
              return can_("reimbursements:personal:update") || isInboxMode;
            case "delete":
              return can_("reimbursements:personal:delete");
            case "process_payment":
              return can_("reimbursements:personal:process_payment");
            default:
              return false;
          }
        } else {
          switch (action) {
            case "create":
              return can_("reimbursements:others:create", opts);
            case "read":
              return can_("reimbursements:others:read", opts) || isApprover;
            case "approve":
              return can_("reimbursements:others:approve", opts) || isApprover;
            case "update":
              return can_("reimbursements:others:update", opts) || isApprover;
            case "delete":
              return can_("reimbursements:others:delete", opts);
            case "process_payment":
              return can_("reimbursements:others:process_payment", opts);
            default:
              return false;
          }
        }
      });
    },
    [can_, activeTeamMember, opts?.inboxMode]
  );

  const cannot = useCallback(
    (action: ExpenseReimbursementAction, items: ExpenseReimbursementParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "reimbursements:personal",
    othersPermissionPath: "reimbursements:others",
    teamMemberField: { fieldName: "team_member_id", fieldType: "string" },
    jobField: { fieldName: "job_id", fieldType: "string" },
    inboxMode: opts?.inboxMode,
    appModule: "expense_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<ExpenseReimbursementAction>("reimbursements");

  /** Job filter predicate */
  const jobPredicate = useAbilitiesJobPredicate<ExpenseReimbursementAction>("reimbursements");

  return useMemo(
    () => ({ can, cannot, filter, teamPredicate, jobPredicate }),
    [can, cannot, filter, teamPredicate, jobPredicate]
  );
};

const getTeamMemberId = (
  reimbursement:
    | ExpenseReimbursementEntry
    | BulkEditedReimbursement
    | AggregatedExpenseReimbursement
    | Partial<AggregatedExpenseReimbursement>
): string | undefined => {
  if ("team_member" in reimbursement) {
    if (typeof reimbursement.team_member === "string") return reimbursement.team_member;
    return reimbursement.team_member?._id;
  } else if ("team_member_id" in reimbursement) {
    return reimbursement.team_member_id;
  }
};

const getJobId = (
  reimbursement:
    | ExpenseReimbursementEntry
    | BulkEditedReimbursement
    | AggregatedExpenseReimbursement
    | Partial<AggregatedExpenseReimbursement>
): string | undefined | null => {
  if ("job" in reimbursement) {
    if (typeof reimbursement.job === "string") return reimbursement.job;
    return reimbursement.job?._id;
  } else if ("job_id" in reimbursement) {
    return reimbursement.job_id;
  }
};
