import React from "react";
import { TimesheetSettings } from "./TimesheetSettings";
import { useHasAccessToSiteBoundClockOut } from "dashboard/gating";

interface GeolocationSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const GEOFENCE_RADIUS_MIN = 0.1;
const GEOFENCE_RADIUS_MAX = 100;

const GeolocationSettings: React.FC<GeolocationSettingsProps> = ({ timesheetSettings, handleChange }) => {
  const hasAccessToSiteBoundClockOut = useHasAccessToSiteBoundClockOut();

  const handleToggleGeolocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ geolocation: e.target.checked });
  };

  const handleChangeGeofenceRadius = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (value >= GEOFENCE_RADIUS_MIN && value <= GEOFENCE_RADIUS_MAX) {
      handleChange({ geofence_radius: value });
    }
  };

  const handleToggleGeolocationBreadcrumbs = async (e) => {
    handleChange({
      enable_geolocation_breadcrumbs: e.target.checked,
      enable_geolocation_mileage: !timesheetSettings.enable_geolocation_breadcrumbs
        ? false
        : timesheetSettings.enable_geolocation_breadcrumbs,
    });
  };

  const handleToggleGeolocationGeofencing = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof TimesheetSettings
  ) => {
    handleChange({ [field]: e.target.checked });
  };

  return (
    <div className="billing-card-wrapper">
      <div style={{ fontWeight: 600, fontSize: 18 }}>Geolocation</div>
      <div className="vertical-spacer-small"></div>
      <div className="flex" style={{ alignItems: "flex-start" }}>
        <input
          style={{ marginLeft: 10 }}
          type="checkbox"
          name="geolocation"
          checked={timesheetSettings.geolocation}
          onChange={handleToggleGeolocation}
        />
        <span style={{ marginLeft: 15 }}>
          <strong>Geolocation: </strong>
          {`Store team members' device geo-coordinates when they clock in and out. When this setting is enabled, Miter will prompt your team to enable location sharing when using the mobile app.`}
        </span>
      </div>
      {timesheetSettings.geolocation && (
        <>
          <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
            <span style={{ marginLeft: 60 }}>
              <strong>Set the geofence radius. </strong>
              {` Team members will be considered outside of the job site if they are located this far away from the job location: `}
              <input
                style={{
                  fontFamily: "Karla",
                  fontSize: "16px",
                  borderRadius: "4px",
                  border: "1px solid rgb(211, 211, 211)",
                }}
                type="number"
                value={timesheetSettings.geofence_radius}
                min={GEOFENCE_RADIUS_MIN.toString()}
                max={GEOFENCE_RADIUS_MAX.toString()}
                step="0.01"
                onChange={handleChangeGeofenceRadius}
              />
              {` miles`}
            </span>
          </div>
          <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
            <input
              style={{ marginLeft: 10 }}
              type="checkbox"
              name="disable_clock_in_out_outside_geofence"
              checked={timesheetSettings.disable_clock_in_out_outside_geofence}
              onChange={(e) => handleToggleGeolocationGeofencing(e, "disable_clock_in_out_outside_geofence")}
            />
            <span style={{ marginLeft: 15 }}>
              <strong>Site bound clock-in: </strong>
              {` Prevent team members from clocking in via the mobile app if they are located outside of the job site for their selected job.`}
            </span>
          </div>
          {hasAccessToSiteBoundClockOut && (
            <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
              <input
                style={{ marginLeft: 10 }}
                type="checkbox"
                name="disable_clock_out_outside_geofence"
                checked={timesheetSettings.disable_clock_out_outside_geofence}
                onChange={(e) => handleToggleGeolocationGeofencing(e, "disable_clock_out_outside_geofence")}
              />
              <span style={{ marginLeft: 15 }}>
                <strong>Site bound clock-out: </strong>
                {` Prevent team members from clocking out via the mobile app if they are located outside of the job site for their selected job.`}
              </span>
            </div>
          )}
          <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
            <input
              style={{ marginLeft: 10 }}
              type="checkbox"
              name="enable_geolocation_breadcrumbs"
              checked={timesheetSettings.enable_geolocation_breadcrumbs}
              onChange={handleToggleGeolocationBreadcrumbs}
            />
            <span style={{ marginLeft: 15 }}>
              <strong>GPS breadcrumbs: </strong>
              {` Track team members' geolocation throughout the duration of the timesheet.`}
            </span>
          </div>
        </>
      )}
      {timesheetSettings.geolocation && timesheetSettings.enable_geolocation_breadcrumbs && (
        <div className="flex" style={{ alignItems: "flex-start", marginTop: 15 }}>
          <input
            style={{ marginLeft: 10 }}
            type="checkbox"
            name="enable_geolocation_mileage"
            checked={timesheetSettings.enable_geolocation_mileage}
            onChange={(e) => handleChange({ enable_geolocation_mileage: e.target.checked })}
          />
          <span style={{ marginLeft: 15 }}>
            <strong>Mileage tracking: </strong>
            {`Keep track of the distance traveled by a team member based on their GPS breadcrumbs`}
          </span>
        </div>
      )}
    </div>
  );
};

export default GeolocationSettings;
