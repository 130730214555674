import { SettingsCard } from "ui/settings/SettingsCard";
import { Formblock } from "ui";
import { UseFormMethods } from "react-hook-form";
import { CPRSettings } from "backend/models/company/company-settings";
import InfoButton from "dashboard/components/information/information";

export const CPRFormattingSettings: React.FC<{
  form: UseFormMethods;
  settings: CPRSettings | undefined;
  updateSettings: () => void;
}> = ({ form, settings, updateSettings }) => {
  return (
    <SettingsCard title={"Formatting"}>
      <Formblock
        type="checkbox"
        name="hide_zero_hours"
        form={form}
        editing={true}
        onChange={updateSettings}
        defaultValue={settings?.hide_zero_hours}
      >
        <div>{`Don't show 0s in the hours columns of the certified payroll report PDF`}</div>
      </Formblock>
      <Formblock
        type="checkbox"
        name="include_all_payment_numbers"
        form={form}
        editing={true}
        onChange={updateSettings}
        defaultValue={settings?.include_all_payment_numbers}
      >
        <div className="flex">
          {`Include payment numbers (ACH trace IDs, check numbers, etc.) for all payments in the week`}
          <InfoButton
            text={
              "By default, Miter only includes the main payment number on reports, and doesn't add payment numbers for corrections or other off-cycle payrolls."
            }
          />
        </div>
      </Formblock>
      <Formblock
        type="checkbox"
        name="use_mmddyyyy_format_for_lcp_payment_date"
        form={form}
        editing={true}
        onChange={updateSettings}
        defaultValue={settings?.use_mmddyyyy_format_for_lcp_payment_date}
      >
        <div>{`Use MM/DD/YYYY format for the LCP tracker CSV payment date`}</div>
      </Formblock>
      <Formblock
        type="checkbox"
        name="use_mmddyyyy_format_for_pdf_dates"
        form={form}
        editing={true}
        onChange={updateSettings}
        defaultValue={settings?.use_mmddyyyy_format_for_pdf_dates}
      >
        <div>{`Use MM/DD/YYYY format for dates in the certified payroll report PDF`}</div>
      </Formblock>
      <Formblock
        type="checkbox"
        name="include_demographics_for_federal"
        form={form}
        editing={true}
        onChange={updateSettings}
        defaultValue={settings?.include_demographics_for_federal}
      >
        <div>{`Include employee demographic data in the federal WH-347 PDF`}</div>
      </Formblock>
      <Formblock
        type="checkbox"
        name="include_employee_address_for_federal"
        form={form}
        editing={true}
        onChange={updateSettings}
        defaultValue={settings?.include_employee_address_for_federal}
      >
        <div>{`Include employee address in the federal WH-347 PDF`}</div>
      </Formblock>
      <div style={{ marginBottom: -10 }}>
        <Formblock
          type="checkbox"
          name="include_employee_address_for_pa"
          form={form}
          editing={true}
          onChange={updateSettings}
          defaultValue={settings?.include_employee_address_for_pa}
        >
          <div>{`Include employee address in the PA LLC-25 PDF`}</div>
        </Formblock>
      </div>
    </SettingsCard>
  );
};
