import { ValueFormatterParams } from "ag-grid-community";
import { cadenceOptions } from "dashboard/components/performance/ReviewCycleCreationScreen";
import {
  AggregatedPerformanceReview,
  AggregatedPerformanceReviewCycle,
  MiterAPI,
  PerformanceReviewCycle,
} from "dashboard/miter";
import { DateTime } from "luxon";
import { isQuestionAnswered } from "./form";
import { FormAnswer } from "miter-utils";

export type PerformanceReviewCycleTableEntry = PerformanceReviewCycle & {
  reviewees: number;
};

export type PerformanceReviewTableEntry = AggregatedPerformanceReview & {
  review_period: string;
};

export const cleanReviewCyclesFromBackend = (
  performanceReviewCycles: PerformanceReviewCycle[]
): PerformanceReviewCycleTableEntry[] => {
  return performanceReviewCycles.map((performanceReviewCycle) => {
    return {
      ...performanceReviewCycle,
      reviewees: performanceReviewCycle.team_member_ids?.length || 0,
      description: generateDescription(
        performanceReviewCycle.request_style,
        performanceReviewCycle.days_offset_request
      ),
    };
  });
};

export const calculateLastReviewCycleStartDate = (
  date: DateTime,
  cadence: PerformanceReviewCycle["cadence"]
): DateTime => {
  switch (cadence) {
    case "monthly":
      return date.minus({ months: 1 });
    case "quarterly":
      return date.minus({ months: 3 });
    case "semi_annually":
      return date.minus({ months: 6 });
    default:
      return date.minus({ months: 12 });
  }
};

export const calculateNextReviewCycleStartDate = (
  date: DateTime,
  cadence: PerformanceReviewCycle["cadence"]
): DateTime => {
  switch (cadence) {
    case "monthly":
      return date.plus({ months: 1 });
    case "quarterly":
      return date.plus({ months: 3 });
    case "semi_annually":
      return date.plus({ months: 6 });
    default:
      return date.plus({ months: 12 });
  }
};

export const generateReviewPeriodString = (review_period: string): string => {
  if (!review_period?.includes(" - ")) return "No review cycle";
  const [startDate, duration] = review_period?.split(" - ");
  if (!startDate || !duration) return "-";
  const currentReviewCycleStartDate = DateTime.fromISO(startDate);
  const nextReviewCycleStartDate = calculateNextReviewCycleStartDate(
    currentReviewCycleStartDate,
    duration as PerformanceReviewCycle["cadence"]
  );
  return `${currentReviewCycleStartDate.toFormat("DD")} - ${nextReviewCycleStartDate.toFormat("DD")}`;
};

export const formatCadence = (params: ValueFormatterParams<PerformanceReviewCycleTableEntry>): string => {
  if (params.data?.cadence) {
    return cadenceOptions.find((option) => option.value === params.data?.cadence)?.label || "-";
  }
  return "-";
};

export const generateBannerMessage = (performanceReviewCycle: AggregatedPerformanceReviewCycle): string => {
  if (!performanceReviewCycle.next_start_date) return "";
  const nextStartDate = DateTime.fromISO(performanceReviewCycle.next_start_date).toFormat("DD");
  return `The next performance review cycle will start on ${nextStartDate}.`;
};

export type ReviewWithFormResponseEntry = AggregatedPerformanceReview & { response: FormAnswer };

export const cleanResponsesFromBackend = (
  performanceReviews: AggregatedPerformanceReview[],
  questionId: string,
  evaluationType: string
): ReviewWithFormResponseEntry[] => {
  const submissionField = evaluationType === "self_review" ? "self_review_submission" : "reviewer_submission";
  const cleanedPerformanceReviews = performanceReviews.map((performanceReview) => {
    const submission = performanceReview?.[submissionField];
    if (!submission) return null;
    const questionResponse = submission.answers.find((answer) => answer.form_field_id === questionId);
    if (!questionResponse) return null;
    return {
      ...performanceReview,
      response: questionResponse,
    };
  });
  return cleanedPerformanceReviews.filter(
    (performanceReview) => performanceReview !== null && isQuestionAnswered(performanceReview.response)
  ) as ReviewWithFormResponseEntry[];
};

export const generateDescription = (request_style: string, days: number): string => {
  let description = "";
  if (request_style === "start_of_cycle" || request_style === "anniversary") {
    description += "Periodically, ";
  } else {
    description += "Once, ";
  }

  description += `${days} `;

  if (request_style === "start_of_cycle") {
    description += "days from the start of the review cycle";
  } else if (request_style === "anniversary") {
    description += "days from the employee's start date anniversary";
  } else if (request_style === "start_date") {
    description += "days from the employee's start date";
  } else if (request_style === "date_added") {
    description += "days from the employee joining performance schedule";
  }
  return description;
};

export const startReviewCycle = async (
  performanceReviewCycle: PerformanceReviewCycle
): Promise<null | string> => {
  // There should always be a next start date, but just in case we check here
  if (!performanceReviewCycle.next_start_date) return null;

  // These request styles dont have cycles, so there's nothing to start. Reviews are created only once.
  if (["start_date", "date_added"].includes(performanceReviewCycle.request_style)) return null;

  const startDate = DateTime.fromISO(performanceReviewCycle.next_start_date);

  if (
    !performanceReviewCycle.previous_start_date &&
    startDate.isValid &&
    startDate.startOf("day") < DateTime.now()
  ) {
    try {
      const res = await MiterAPI.performance_review_cycles.start_review_cycle(performanceReviewCycle._id, {
        timezone: DateTime.local().zoneName,
      });
      if (res.error) throw res.error;
    } catch (e: $TSFixMe) {
      console.log(e.message);
      return e.message;
    }
  }
  return null;
};

// Finalize the settings of a review schedule for all non-cyclic review cycles and when the start date
// is in the past for a cyclic review cycle
export const finalizeReviewSettings = async (
  performanceReviewCycle: PerformanceReviewCycle
): Promise<null | string> => {
  if (performanceReviewCycle.next_start_date) {
    const startDate = DateTime.fromISO(performanceReviewCycle.next_start_date);
    // If the start date is in the past, we should finalize the settings
    if (!(startDate.isValid && startDate.startOf("day") < DateTime.now())) return null;
  }

  try {
    const res = await MiterAPI.performance_review_cycles.update(performanceReviewCycle._id, {
      finalized: true,
    });
    if (res.error) throw res.error;
  } catch (e: $TSFixMe) {
    console.log(e.message);
    return e.message;
  }
  return null;
};
