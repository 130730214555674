import React, { useMemo, useState } from "react";
import { Formblock, Loader } from "ui";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { AcumaticaConnectionMetadata } from "backend/services/acumatica/acumatica-types";
import { useDebouncedCallback } from "use-debounce";
import { Option } from "ui/form/Input";
import { DEFAULT_ACUMATICA_LABEL_STYLE } from "./AcumaticaConfig";
import { AcumaticaBranch } from "backend/services/acumatica/types/acumatica-location-types";

type AcumaticaBranchSettingsProps = {
  integrationConnnection: IntegrationConnection | undefined;
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    options?: { collapseCount?: number }
  ) => Promise<void>;
  branches: AcumaticaBranch[] | undefined;
  form: UseFormMethods<FieldValues>;
  loading: boolean;
};

export const AcumaticaBranchSettings: React.FC<AcumaticaBranchSettingsProps> = ({
  integrationConnnection,
  updateAcumaticaMetadata,
  branches,
  form,
  loading,
}) => {
  const configObject = integrationConnnection?.metadata?.acumatica?.configObject;

  const [filteredBranches, setFilteredBranches] = useState<string[]>(configObject?.branchIds || []);

  const branchOptions = useMemo(() => {
    return branches
      ? branches.map((field) => ({
          label: `${field.BranchID.value}: ${field.BranchName.value}`,
          value: field.BranchID.value,
        }))
      : [];
  }, [branches]);

  const selectedFilteredBranchOptions = useMemo(() => {
    return branchOptions?.filter((o) => filteredBranches.includes(o.value));
  }, [filteredBranches, branchOptions]);

  const updateFilteredBranchesDb = async (branchIds: string[] | null) => {
    await updateAcumaticaMetadata({ configObject: { branchIds: branchIds } }, { collapseCount: 2 });
  };

  const debouncedUpdateFilteredBranches = useDebouncedCallback(updateFilteredBranchesDb, 1000);

  const updateFilteredBranches = async (branchIds: string[] | null) => {
    setFilteredBranches(branchIds || []);
    await debouncedUpdateFilteredBranches(branchIds);
  };

  if (loading) return <Loader />;

  return (
    <>
      <Formblock
        form={form}
        options={branchOptions}
        name="branches"
        type="multiselect"
        value={selectedFilteredBranchOptions}
        onChange={(options: Option<string>[] | null) =>
          updateFilteredBranches(options?.map((o) => o.value) || null)
        }
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Filtered branches"
        labelInfo="The branches Miter will filter by when pulling data from Acumatica."
        underlineTooltip
        height="unset"
      />
    </>
  );
};
