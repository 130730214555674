import FormSubmissionAnswersTable from "dashboard/components/forms/FormSubmissionAnswersTable";
import { useCompanyFormOptions, useLookupForms } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, FormSubmission, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import { ActionModal, Formblock, TableV2 } from "ui";
import { ColumnConfig, TableDropdownCellRenderer, TableActionLink } from "ui/table-v2/Table";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useFormSubmissionAbilities } from "dashboard/hooks/abilities-hooks/useFormSubmissionAbilities";
import { useNavigate } from "react-router-dom";
import { Envelope, PaperPlaneTilt, Pencil, Trash } from "phosphor-react";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import pluralize from "pluralize";

type Props = {
  teamMember: AggregatedTeamMember;
};

export const TeamMemberFormSubmissions: React.FC<Props> = ({ teamMember }) => {
  /*********************************************************
   * Important refs, states, and variables
   **********************************************************/
  const navigate = useNavigate();
  const miterAbilities = useMiterAbilities();
  const submissionAbilities = useFormSubmissionAbilities();

  const lookupForm = useLookupForms();
  const formOptions = useCompanyFormOptions();

  const [formSubmissions, setFormSubmissions] = useState<FormSubmission[]>([]);
  const [activeFormSubmission, setActiveFormSubmission] = useState<FormSubmission>();

  const [loading, setLoading] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<FormSubmission[]>([]);

  const [showSubmissionRequestsModal, setShowSubmissionRequestsModal] = useState<boolean>(false);
  const [selectedFormForRequest, setSelectedFormForRequest] = useState<string>();
  const [sendingSubmissionRequests, setSendingSubmissionRequests] = useState<boolean>(false);

  /*********************************************************
   * Backend functions
   **********************************************************/
  const getFormSubmissions = useCallback(async () => {
    setLoading(true);
    try {
      const res = await MiterAPI.form_submissions.search([
        { field: "team_member_id", value: teamMember._id },
      ]);

      if (res.error) throw new Error(res.error);

      // Don't show form submissions that are not base company forms
      const companyFormSubmissions = res
        .filter((submission) => {
          const form = lookupForm(submission.form_id);
          return form?.parent_type === "company";
        })
        .sort((a, b) => (a.completed_at || 0) - (b.completed_at || 0));

      setFormSubmissions(companyFormSubmissions);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    setLoading(false);
  }, [lookupForm, teamMember._id]);

  useEffect(() => {
    getFormSubmissions();
  }, [getFormSubmissions]);

  const handleArchive = useCallback(
    async (formSubmission?: FormSubmission) => {
      if (formSubmission && submissionAbilities.cannot("delete", formSubmission)) {
        Notifier.error("You do not have permission to delete this submission.");
        return;
      } else if (!formSubmission && submissionAbilities.cannot("delete", selectedRows)) {
        Notifier.error("You do not have permission to delete one or more of these submissions.");
        return;
      }

      setLoading(true);
      try {
        const archivingIds = formSubmission ? [formSubmission._id] : selectedRows.map((form) => form._id);
        for (const id of archivingIds) {
          const response = await MiterAPI.form_submissions.delete(id);
          if (response.error) throw new Error(response.error);
        }

        Notifier.success(pluralize("Submission", selectedRows.length) + " successfully deleted.");

        setArchiving(false);
        setSelectedRows([]);
      } catch (e) {
        console.error(e);
        Notifier.error("There was an error deleting one or more form. We're looking into it.");
      }
      setLoading(false);
      getFormSubmissions();
    },
    [getFormSubmissions, selectedRows, submissionAbilities]
  );

  const handleSendReminder = useCallback(
    async (formSubmission?: FormSubmission) => {
      setLoading(true);
      try {
        const sendingIds = formSubmission ? [formSubmission._id] : selectedRows.map((row) => row._id);

        const response = await MiterAPI.form_submissions.send_reminders(sendingIds, "company");
        if (response.error || response.failures.length > 0) {
          throw new Error(response.error || response.failures[0]?.message);
        }

        Notifier.success(pluralize("Reminder", selectedRows.length) + " successfully sent.");
      } catch (e) {
        console.error(e);
        Notifier.error("There was an error sending one or more reminders. We're looking into it.");
      }
      setLoading(false);
    },
    [selectedRows]
  );

  const handleSendSubmissionRequests = async (formId?: string) => {
    if (!formId) {
      Notifier.error("Please select a form to request submissions for.");
      return;
    }

    if (miterAbilities.cannot("forms:submissions:others:create")) {
      Notifier.error("You do not have permission to request submissions from other team members.");
      return;
    }

    setSendingSubmissionRequests(true);
    try {
      const res = await MiterAPI.forms.send_submission_requests({
        team_member_ids: [teamMember._id],
        form_ids: [formId],
      });

      if (res.error) throw new Error(res.error);

      Notifier.success("Submission requests successfully sent.");
      setShowSubmissionRequestsModal(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error sending one or more submission requests. We're looking into it.");
    }

    setSendingSubmissionRequests(false);
    getFormSubmissions();
  };

  /*********************************************************
    Helper functions
  **********************************************************/
  const handleEdit = (formSubmission: FormSubmission) => {
    const form = lookupForm(formSubmission.form_id);

    if (!form?.allow_editable_submissions) {
      Notifier.error("This form does not allow editing of submissions.");
      return;
    }

    if (submissionAbilities.cannot("update", formSubmission)) {
      Notifier.error("You do not have permission to edit this submission.");
      return;
    }

    navigate("/forms/" + formSubmission.form_id + "/submissions/" + formSubmission._id);
  };

  const buildActionsCell = (formSubmission: FormSubmission) => {
    return [
      {
        label: "Send reminder",
        action: () => handleSendReminder(formSubmission),
        icon: <Envelope weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => submissionAbilities.can("update", formSubmission) && !formSubmission.started_at,
      },
      {
        label: "Edit",
        action: () => handleEdit(formSubmission),
        icon: <Pencil weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () =>
          submissionAbilities.can("update", formSubmission) &&
          !!lookupForm(formSubmission.form_id)?.allow_editable_submissions,
      },
      {
        label: "Delete",
        action: () => handleArchive(formSubmission),
        icon: <Trash weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        shouldShow: () => submissionAbilities.can("delete", formSubmission),
        loading: archiving,
      },
    ];
  };

  /*********************************************************
    Table configs
  **********************************************************/
  const columns: ColumnConfig<FormSubmission>[] = [
    {
      headerName: "Form",
      field: "form_name",
      dataType: "string",
      valueGetter: (entry) => lookupForm(entry.data?.form_id)?.name,
      filter: "agSetColumnFilter",
    },
    {
      headerName: "Status",
      field: "status",
      dataType: "string",
      displayType: "badge",
      filter: "agSetColumnFilter",
    },
    {
      headerName: "Completed At",
      field: "completed_at",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "ff",
    },
    {
      headerName: "Actions",
      field: "actions",
      dataType: "component",
      disableCellClick: true,
      cellRenderer: (params) => {
        const actions = buildActionsCell(params.data).filter((a) => a.shouldShow());
        if (!actions.length) return;

        return <TableDropdownCellRenderer options={buildActionsCell(params.data)} />;
      },
    },
  ];

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Send submission request",
        icon: <PaperPlaneTilt weight="bold" style={{ marginRight: 7, marginBottom: -2 }} />,
        action: () => setShowSubmissionRequestsModal(true),
        disabled: !miterAbilities.can("forms:submissions:others:create"),
        className: "button-2 no-margin",
        important: true,
      },
    ],
    [miterAbilities]
  );

  const dynamicActions = useMemo(
    () => [
      {
        label: "Send reminders",
        action: () => handleSendReminder(),
        disabled: !submissionAbilities.can("update", selectedRows),
        className: "button-1",
      },
      {
        label: "Delete",
        action: () => handleArchive(),
        disabled: !submissionAbilities.can("delete", selectedRows),
        className: "button-1",
        loading: archiving,
      },
    ],
    [archiving, handleArchive, handleSendReminder, selectedRows, submissionAbilities]
  );

  /*********************************************************
    Render functions
  **********************************************************/
  const renderFormSubmissionModal = () => {
    if (!activeFormSubmission) return;

    const name = teamMember.full_name;
    const form = lookupForm(activeFormSubmission.form_id);
    const submissionDate = activeFormSubmission.completed_at
      ? DateTime.fromSeconds(activeFormSubmission.completed_at).toFormat("ff")
      : "-";

    if (!form) return;

    return (
      <ActionModal
        headerText={`Submission for ${name} on ${submissionDate}`}
        showCancel={true}
        cancelText="Close"
        onCancel={() => setActiveFormSubmission(undefined)}
        onHide={() => setActiveFormSubmission(undefined)}
        wrapperStyle={{ width: "80%" }}
      >
        <FormSubmissionAnswersTable form={form} formSubmission={activeFormSubmission} />
      </ActionModal>
    );
  };

  const renderSubmissionRequestsModal = () => {
    if (!showSubmissionRequestsModal) return;

    return (
      <ActionModal
        headerText="Request a form submission"
        showCancel={true}
        showSubmit={true}
        cancelText="Close"
        onCancel={() => setShowSubmissionRequestsModal(false)}
        onHide={() => setShowSubmissionRequestsModal(false)}
        onSubmit={() => handleSendSubmissionRequests(selectedFormForRequest)}
        loading={sendingSubmissionRequests}
      >
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Formblock
            label={"Select a form"}
            type="select"
            name={"request-form"}
            placeholder="Select a form"
            className="modal wizard"
            editing={true}
            options={formOptions}
            onChange={(option) => setSelectedFormForRequest(option?.value)}
          />
        </div>
      </ActionModal>
    );
  };

  return (
    <>
      <TableV2
        id="form-submissions-table"
        data={formSubmissions}
        isLoading={loading}
        columns={columns}
        resource="form submissions"
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onClick={(submission) => setActiveFormSubmission(submission)}
        onSelect={(rows) => setSelectedRows(rows)}
        defaultSelectedRows={selectedRows}
      />
      {renderFormSubmissionModal()}
      {renderSubmissionRequestsModal()}
    </>
  );
};
