import React, { useMemo } from "react";
import { OvertimeSettings } from "./OvertimeCalculation";
import { Helmet } from "react-helmet";
import { PaystubEarningGrouping } from "./PaystubEarningsGrouping";
import { SalaryPayrollSettings } from "./SalaryPayrollSettings";
import { OvertimeRules } from "./OvertimeRules/OvertimeRules";
import { WarningSettings } from "./WarningSettings";
import { PaySchedules } from "./PaySchedules/PaySchedules";
import { MultiWorkplacePayrolls } from "./MultiWorkplacePayrolls";
import { BurdenRates } from "dashboard/pages/burdenRates/BurdenRates";
import { SupplementalTaxMethod } from "./SupplementalTaxMethod";
import { PayRateAndFringeSettings } from "./PayRateAndFringeSettings";
import { WorkHoursInYear } from "./WorkHoursInYear";
import { RateDifferentials } from "dashboard/pages/timesheets/TimesheetSettings/RateDifferentials";
import { PayrollCheckSignature } from "./PayrollSignature";
import { PaystubsAndChecks } from "./PaystubsAndChecks";
import { Toggler } from "ui";
import { useEnhancedSearchParams } from "miter-utils";
import { ContractorOvertime } from "./ContractorOvertime";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Trades } from "./trades/Trades";
import { PayRateGroups } from "./payRateGroups/PayRateGroups";

export const PayrollSettings: React.FC = () => {
  const { can, cannot } = useMiterAbilities();

  const togglerConfig = useMemo(() => {
    return [
      { label: "General", path: "general", hide: cannot("payrolls:settings") },
      { label: "Running payroll", path: "run_payroll", hide: cannot("payrolls:settings") },
      { label: "Paystubs", path: "paystubs", hide: cannot("payrolls:settings") },
      { label: "Overtime", path: "overtime", hide: cannot("payrolls:settings") },
      { label: "Modifiers", path: "modifiers", hide: cannot("payrolls:settings") },
      { label: "Pay rate groups", path: "pay_rate_groups", hide: cannot("lists:pay_rate_groups:read") },
      { label: "Trades", path: "trades", hide: cannot("lists:pay_rate_groups:read") },
    ];
  }, [cannot]);

  const { parsedSearchParams, setSearchParams } = useEnhancedSearchParams({ replaceInHistory: true });
  const defaultState = can("payrolls:settings") ? "general" : "pay_rate_groups";
  const togglerState = parsedSearchParams.sub || defaultState;

  const handleToggle = (path: string) => {
    setSearchParams({ sub: path });
  };

  return (
    <div>
      <Helmet>
        <title>Payroll Settings | Miter</title>
      </Helmet>
      <Toggler
        config={togglerConfig}
        toggle={handleToggle}
        active={togglerState}
        wrapperStyle={{ marginTop: 0 }}
      />
      {togglerState === "general" && (
        <>
          {/* Company level toggles */}
          <PaySchedules />
          <MultiWorkplacePayrolls />
          <WorkHoursInYear />
          <SupplementalTaxMethod />
        </>
      )}
      {togglerState === "run_payroll" && (
        <>
          {/* Running payroll */}
          <PayRateAndFringeSettings />
          <SalaryPayrollSettings />
          <WarningSettings />
        </>
      )}
      {togglerState === "paystubs" && (
        <>
          {/* Paystubs */}
          <PaystubEarningGrouping />
          <PaystubsAndChecks />
          <PayrollCheckSignature />
        </>
      )}
      {togglerState === "overtime" && (
        <>
          {/* Overtime */}
          <OvertimeSettings />
          <OvertimeRules />
          <ContractorOvertime />
        </>
      )}
      {togglerState === "modifiers" && (
        <>
          {/* Burden rates and rate differentials */}
          <BurdenRates />
          <RateDifferentials />
        </>
      )}
      {togglerState === "pay_rate_groups" && (
        <>
          <PayRateGroups />
        </>
      )}
      {togglerState === "trades" && (
        <>
          <Trades />
        </>
      )}
    </div>
  );
};
