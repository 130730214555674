import {
  AutoCalculatedMiterEarningType,
  AutoCalculatedMiterReimbursementType,
  PayrollAdjustment,
} from "dashboard/pages/payrolls/payrollTypes";

export const isAutoCalculateFullyDisabled = (adjustment: PayrollAdjustment | undefined): boolean => {
  return adjustment?.auto_calculate?.disable_all_categories || false;
};

export const isAutoCalculatePartiallyDisabled = (adjustment: PayrollAdjustment | undefined): boolean => {
  return (
    isAutoCalculateFullyDisabled(adjustment) ||
    (adjustment?.auto_calculate?.disabled_categories || []).length > 0
  );
};

export const isEarningAutoCalculateDisabled = (
  earningType: AutoCalculatedMiterEarningType | AutoCalculatedMiterReimbursementType,
  tmAdjustment: PayrollAdjustment | undefined
): boolean => {
  return !!(
    tmAdjustment?.auto_calculate?.disable_all_categories ||
    tmAdjustment?.auto_calculate?.disabled_categories?.includes(earningType)
  );
};
