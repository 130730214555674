import React, { FC, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";

import { ActionModal, Button, Label, LoadingModal, ModalHeader } from "ui";
import { ModalFooter } from "ui";
import Notifier from "dashboard/utils/notifier";
import { Checkmark, RedX } from "dashboard/components/icons/icons";

import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import InputMask from "react-input-mask";
import { isSafari } from "react-device-detect";

import rightArrowPng from "dashboard/assets/right-arrow.png";
import { useCheckComponent } from "dashboard/utils/useCheckComponent";
import { useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { useNavigate } from "react-router-dom";
import { CheckTM } from "backend/utils/check/check-types";

type Props = {
  defaultData: AggregatedTeamMember;
  onHide: () => void;
};
const PaymentInformationForm: FC<Props> = ({ defaultData, onHide }) => {
  const refetchTeam = useRefetchTeam();

  const [loadingSMS, setLoadingSMS] = useState(false);
  const [statusSMS, setStatusSMS] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const [_loadingCheckModal, setLoadingCheckModal] = useState(false);
  const [_checkModalStatus, setCheckModalStatus] = useState<string | null>(null);

  const [ssn, setSSN] = useState<string>();
  const [updatingSSN, setUpdatingSSN] = useState(false);
  const [showSSNModal, setShowSSNModal] = useState(false);

  const navigate = useNavigate();

  const checkTm = defaultData.check_tm as CheckTM | undefined;
  const withholdingsNeedsAttention =
    checkTm &&
    (checkTm.onboard.remaining_steps.some((s) => s === "withholdings" || s === "ssn") ||
      ("employee_details" in checkTm.onboard && checkTm.onboard.employee_details?.includes("residence")));

  // Call this function when user exits the Check Onboard modal. Need to sync the TM with Check when the
  // Check Onboard modal closes in case they didn't send a webhook that the onboard status has changed
  const onCheckOnboardClose = async () => {
    setRefreshing(true);
    onHide();
    if (!defaultData.check_id) return;

    await MiterAPI.team_member
      .sync_with_check(defaultData.check_id)
      .catch((e) =>
        console.error(`Issue syncing TM ${defaultData._id} with Check upon Check Onboard modal close`, e)
      );

    refetchTeam(defaultData._id);
    setRefreshing(false);
  };

  const onCheckOnboardEvent = async (event_name) => {
    if (event_name === "check-onboard-app-loaded") {
      setLoadingCheckModal(false);
    }
  };

  const openCheckOnboard = async () => {
    setLoadingCheckModal(true);

    try {
      const link = await MiterAPI.team_member.retrieve_check_onboard_link(defaultData._id);
      if (link.error) {
        throw new Error(link.error);
      }

      if (isSafari) {
        window.open(link.url, "_blank");
        setLoadingCheckModal(false);
      } else {
        const handler = window.Check.create({
          link: link.url,
          onClose: onCheckOnboardClose,
          onEvent: onCheckOnboardEvent,
        });
        await handler.open();
      }
    } catch (e) {
      console.log("Error opening Check Onboard", e);
      Notifier.error("Sorry, we can't update payment information right now. We're looking into it!");
      setCheckModalStatus("failure");
      setLoadingCheckModal(false);
    }
  };

  const { componentURL, setComponentURL, renderComponent, setLoadingComponent } =
    useCheckComponent(onCheckOnboardClose);

  const sendOnboardingSMS = async () => {
    setLoadingSMS(true);
    try {
      const response = await MiterAPI.team_member.send_onboard_sms(defaultData._id);
      if (response.error) {
        throw new Error(response.error);
      }
      Notifier.success("Sent SMS.");
      setStatusSMS("sent");
    } catch (e) {
      console.log(e);
      Notifier.error("Failed to send a text. We're looking into it!");
      setStatusSMS("failed");
    }

    setLoadingSMS(false);
  };

  const getWitholdingsSetupLink = async () => {
    if (!defaultData) return;
    if (!defaultData.check_tm?.ssn_last_four) {
      Notifier.error("Please setup your SSN before you try to add your withholdings.");
      return;
    }

    setLoadingComponent(true);
    try {
      const res = await MiterAPI.team_member.retrieve_withholdings_setup_link(defaultData?._id);
      setComponentURL(res.url);
    } catch (e: $TSFixMe) {
      console.log("Error getting withholdings setup link", e);
      Notifier.error("There was an error opening this page. Please contact support.");
    }
    setLoadingComponent(false);
  };

  const updateSSN = async () => {
    if (!ssn?.length) return Notifier.error("Please fill out the form before submitting.");
    if (isNaN(Number(ssn)) || ssn.length !== 9) return Notifier.error("Please enter a valid SSN.");

    setUpdatingSSN(true);
    try {
      const res = await MiterAPI.team_member.update(defaultData._id, { ssn });
      if (res.error) {
        const ssnError = res.fields?.find((f) => f.name === "ssn");

        if (ssnError) {
          throw new Error(ssnError.error);
        } else {
          throw new Error(res.error);
        }
      }

      await refetchTeam(defaultData._id);

      Notifier.success("Profile successfully updated.");
      setShowSSNModal(false);
      onHide();
    } catch (err: $TSFixMe) {
      console.log("Error saving SSN", err);
      Notifier.error(err.message ?? "There was an error updating your profile. Please contact support.");
    }
    setUpdatingSSN(false);
  };

  const renderContractorButtons = () => {
    return (
      <>
        <div className="check-option">
          <img className="check-option-arrow" src={rightArrowPng} />
          <div className="check-option-text">Edit payment method</div>
          <button
            className={"button-1 check-option-button"}
            onClick={() => navigate(`/team-members/${defaultData._id}/payment-info`)}
          >
            {"Continue"}
          </button>
        </div>
        <div className="check-option">
          <img className="check-option-arrow" src={rightArrowPng} />
          <div className="check-option-text">Sign W9</div>
          <button className={"button-1 check-option-button"} onClick={openCheckOnboard}>
            {"Continue"}
          </button>
        </div>
      </>
    );
  };

  const renderEmployeeButtons = () => {
    return (
      <>
        <div className="check-option">
          <img className="check-option-arrow" src={rightArrowPng} />
          <div className="check-option-text">Edit withholdings</div>
          <button className={"button-1 check-option-button"} onClick={getWitholdingsSetupLink}>
            {"Continue"}
          </button>
        </div>
        {withholdingsNeedsAttention ? (
          <div style={{ marginLeft: 6, marginTop: -5, marginBottom: 20 }} className="error-msg">
            Federal and/or state withholdings need attention
          </div>
        ) : null}

        <div className="check-option">
          <img className="check-option-arrow" src={rightArrowPng} />
          <div className="check-option-text">Edit payment method</div>
          <button
            className={"button-1 check-option-button"}
            onClick={() => navigate(`/team-members/${defaultData._id}/payment-info`)}
          >
            {"Continue"}
          </button>
        </div>
      </>
    );
  };

  const renderNewCheckModalButtons = (buttonClassName?: string) => {
    return (
      <>
        <div className="check-option">
          <img className="check-option-arrow" src={rightArrowPng} />
          <div className="check-option-text">Edit SSN</div>
          <button className={buttonClassName} onClick={() => setShowSSNModal(true)}>
            {"Continue"}
          </button>
        </div>
        {defaultData.employment_type === "employee" && renderEmployeeButtons()}
        {defaultData.employment_type === "contractor" && renderContractorButtons()}
      </>
    );
  };

  const renderEditDirectly = () => {
    return renderNewCheckModalButtons("button-1 check-option-button");
  };

  const renderSendSMSDirectly = () => {
    return (
      <div className="check-option">
        <img className="check-option-arrow" src={rightArrowPng} />
        <div className="check-option-text">
          {"Send " +
            defaultData.first_name +
            " a text message prompting them to update their own information"}
        </div>
        <Button
          className="button-1 check-option-button"
          onClick={sendOnboardingSMS}
          disabled={statusSMS === "failed" || statusSMS === "sent"}
          loading={loadingSMS}
        >
          {statusSMS === "sent" ? <Checkmark /> : statusSMS === "failed" ? <RedX /> : "Send SMS"}
        </Button>
      </div>
    );
  };

  const renderSSNModal = () => {
    return (
      <ActionModal
        headerText={"Update team member SSN"}
        submitText={"Submit"}
        onSubmit={updateSSN}
        showSubmit={true}
        onHide={() => {
          setShowSSNModal(false);
          setSSN(undefined);
        }}
        // bodyStyle={{ height: 250 }}
        loading={updatingSSN}
      >
        <div className={"ssn-modal"}>
          <Label className="modal" label={"SSN*"} labelInfo="SSN is required to run payroll" />
          <InputMask
            className="form2-text ssn-input"
            onChange={(e) => setSSN(e.target.value?.replaceAll("-", ""))}
            mask={"999-99-9999"}
            placeholder={
              "XXX - XX - " + (defaultData.ssn_last_four || defaultData?.check_tm?.ssn_last_four || "XXXX")
            }
          />
        </div>
      </ActionModal>
    );
  };

  if (componentURL) return renderComponent();

  return (
    <>
      {!showSSNModal && (
        <ClickAwayListener onClickAway={() => {}}>
          <div className="modal-wrapper form">
            <ModalHeader onHide={onHide} heading={"Edit " + defaultData.first_name + "'s payment info"} />
            {defaultData && (
              <div className="modal-body form team-details trade">
                {renderEditDirectly()}
                {renderSendSMSDirectly()}
              </div>
            )}
            <ModalFooter
              hideCancel={true}
              cancelText={"Cancel"}
              onSubmit={onHide}
              submitText={"Done"}
              className="form"
            />
          </div>
        </ClickAwayListener>
      )}
      {refreshing && <LoadingModal text="Loading" />}
      {showSSNModal && renderSSNModal()}
    </>
  );
};

export default PaymentInformationForm;
