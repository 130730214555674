/* eslint-disable @typescript-eslint/no-explicit-any */
import { Equipment, MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import React, { useMemo, useState, FC } from "react";
import { useParams } from "react-router-dom";
import { BasicModal, TableV2 } from "ui";
import EquipmentModal from "./EquipmentModal";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { useEquipment, useRefetchEquipment } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";
import { toDollarFormat } from "miter-utils";
import { EquipmentImporter } from "./EquipmentImporter";
import { ImportHistory } from "../importer/ImportHistory";

const EquipmentTable: FC = () => {
  /*********************************************************
   *  Call important hooks
   **********************************************************/
  const { id } = useParams<{ id?: string }>();
  const { can, cannot } = useMiterAbilities();
  const equipment = useEquipment();
  const refetchEquipment = useRefetchEquipment();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();

  /*********************************************************
   *  Initialize states
   **********************************************************/

  const [loading, setLoading] = useState<boolean>(false);

  // States related to the table
  const [selectedRows, setSelectedRows] = useState<Equipment[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [idToEdit, setIdToEdit] = useState<string | undefined>();
  const [showImportHistory, setShowImportHistory] = useState<boolean>(false);
  const [showLatestImportResult, setShowLatestImportResult] = useState<boolean>(false);

  const updating = !!id;

  /*********************************************************
   *  Helper functions
   **********************************************************/
  const tableData = useMemo(() => equipment.map((e) => ({ ...e, code: e.code || "-" })), [equipment]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleRowClick = (equipment) => {
    setIdToEdit(equipment?._id.toString());
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const handleArchive = async () => {
    if (cannot("lists:equipment:manage")) {
      Notifier.error("You do not have permission to delete equipment");
      return;
    }

    setLoading(true);
    try {
      for (const equipment of selectedRows) {
        const response = await MiterAPI.equipment.delete(equipment._id);
        if (response.error) throw new Error(response.error);
      }

      const singPlur = selectedRows.length > 1 ? "Equipment" : "DailyReport";
      Notifier.success(singPlur + " successfully deleted.");

      setArchiving(false);
      setSelectedRows([]);
      await refetchEquipment();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting one or more equipment. We're looking into it.");
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setAdding(false);
    setIdToEdit(undefined);
    setArchiving(false);
  };

  const handleModalSubmit = async () => {
    await refetchEquipment();
    setAdding(false);
    setIdToEdit(undefined);
    setArchiving(false);
  };

  const onEquipmentImport = async () => {
    setShowImportHistory(true);
    setShowLatestImportResult(true);
    await refetchEquipment();
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = [
    {
      label: "Add equipment",
      className: "button-2 no-margin",
      action: handleAdd,
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => can("lists:equipment:manage"),
    },
    {
      key: "import",
      component: <EquipmentImporter onFinish={onEquipmentImport} />,
      shouldShow: () => can("lists:equipment:manage"),
    },
  ];

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:equipment:manage"),
      },
    ],
    [can]
  );

  // Dynamically set columns based on whether any equipment have work classifications
  const columns: ColumnConfig<Equipment>[] = [
    {
      headerName: "Name",
      field: "name",
      dataType: "string" as const,
      filter: {
        field: "name",
        type: "string",
      },
    },
    {
      headerName: "Code",
      field: "code",
      dataType: "string" as const,
      filter: {
        field: "code",
        type: "string",
      },
    },
    {
      headerName: "Notes",
      field: "notes",
      dataType: "string" as const,
      filter: {
        field: "notes",
        type: "string",
      },
    },
  ];
  if (hasAccessToEquipmentTracking) {
    columns.push({
      headerName: "Cost Rate",
      field: "cost_rate",
      dataType: "number" as const,
      filter: {
        field: "cost_rate",
        type: "number",
      },
      valueFormatter: toDollarFormat,
    });
    columns.push({
      headerName: "Status",
      field: "status",
      dataType: "string" as const,
      displayType: "badge",
      colors: {
        active: "light-green",
        inactive: "light-gray",
      },
      filter: {
        field: "status",
        type: "string",
      },
    });
  }

  const renderTable = () => {
    return (
      <TableV2
        id={"equipment"}
        resource="equipment"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        onClick={handleRowClick}
        defaultSelectedRows={selectedRows}
      />
    );
  };

  const renderImportHistory = () => {
    if (!showImportHistory) return null;
    return (
      <ImportHistory
        id="equipment"
        resource={"equipment items"}
        onClose={() => {
          setShowImportHistory(false);
          setShowLatestImportResult(false);
        }}
        openLastResult={showLatestImportResult}
      />
    );
  };

  return (
    <div className="equipment-table-wrapper">
      <div>
        {(adding || idToEdit) && (
          <EquipmentModal equipmentID={idToEdit} onCancel={handleModalClose} onSubmit={handleModalSubmit} />
        )}
        {archiving && (
          <BasicModal
            loading={loading}
            button2Text="Delete"
            button1Action={() => setArchiving(false)}
            button1Text="Cancel"
            button2Action={handleArchive}
            headerText={"Delete equipment" + (selectedRows.length > 1 ? "s" : "")}
            bodyText={
              "Are you sure you want to delete the selected equipment? To restore deleted equipment, you must contact Miter Support."
            }
          />
        )}
        {updating && (
          <EquipmentModal onCancel={handleModalClose} onSubmit={handleModalSubmit} equipmentID={id} />
        )}

        {renderTable()}
        {renderImportHistory()}
      </div>
    </div>
  );
};

export default EquipmentTable;
