import React, { FC, useState } from "react";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import { OfferTemplateSmartField } from "dashboard/types/offers-types";
import { MessageTemplateSmartField } from "dashboard/types/message-template";
import { Loader } from "../loader";

export type BlobInfo = {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
};

/** Constants */
const familyFonts =
  "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats";
const plugins = [
  "preview",
  "advlist",
  "autolink",
  "lists",
  "link",
  "image",
  "charmap",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "format",
  "mergetags",
  "mergetags-customisation",
  "powerpaste",
];
const DEFAULT_TOOLBAR =
  "mergetags image link | blocks | " +
  "bold italic forecolor | alignleft aligncenter " +
  "alignright alignjustify | bullist numlist outdent indent | " +
  "table tabledelete | tableprops tablerowprops tablecellprops | " +
  "preview";

type SmartField = OfferTemplateSmartField | MessageTemplateSmartField;

export type EditorProps = {
  smartFields: { value: SmartField; title: string }[];
  defaultHTML?: string;
  editorRef: React.MutableRefObject<TinyMCEEditor | undefined>;
  toolbar?: string;
  handleImageUpload?: (blob: BlobInfo) => Promise<string>;
  height?: number;
  disabled?: boolean;
  menubar?: string;
};

const Editor: FC<EditorProps> = ({
  smartFields,
  defaultHTML,
  editorRef,
  handleImageUpload,
  height,
  toolbar,
  disabled,
  menubar = "format",
}) => {
  const finalToolbar = toolbar != undefined ? toolbar : DEFAULT_TOOLBAR;
  const [loading, setLoading] = useState(false);

  const setup = (editor) => {
    // We must be careful in referring to state here as it will be fixed
    // to the value it was when the editor displayed.
    editor.editorManager.PluginManager.add("mergetags-customisation", (editor) => {
      const original = editor.ui.registry.getAll()["buttons"]["mergetags"];

      editor.ui.registry.addMenuButton("mergetags", {
        ...original,
        text: "Smart tokens",
        icon: null,
        search: false,
      });
    });
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <TinyMCEEditor
        apiKey={process.env.REACT_APP_TINYMCE_EDITOR_API_KEY}
        onInit={(_evt, editor) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          editorRef.current = editor;
          setLoading(false);
        }}
        initialValue={defaultHTML}
        init={{
          height: height || 500,
          font_family_formats: familyFonts,
          content_style: "div { margin: 10px; border: 5px solid red; padding: 3px; }",
          menubar,
          plugins: plugins,
          paste_data_images: true,
          mergetags_list: smartFields,
          branding: false,
          toolbar: finalToolbar,
          images_upload_handler: handleImageUpload,
          setup,
        }}
        disabled={!!disabled}
      />
    </>
  );
};

export default Editor;
