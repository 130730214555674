import React, { ReactElement } from "react";
import { get } from "lodash";
import { Info } from "phosphor-react";
import { ColumnConfig } from "ui/table-v2/Table";
import { ICellRendererParams } from "ag-grid-community";
import { PermissionGroup } from "dashboard/miter";
import { extractPaths } from "miter-utils";

type PermissionsTableEntry = {
  _id: string;
  label: string;
  path: string;
  info?: string;
};

/** Full permission configuration including labels, descriptions, and permissions */
export const PERMISSIONS_CONFIG = {
  "Human Resources": {
    label: "Human Resources",
    items: {
      team: {
        label: "Team",
        permissions: {
          create: {
            label: "Create team members",
          },
          read: {
            label: "View team members",
          },
          read_sensitive: {
            label: "View sensitive team member information",
          },
          read_pay_info: {
            label: "View team member pay information",
          },
          update: {
            label: "Edit team members",
          },
          update_sensitive: {
            label: "Edit sensitive team member information",
          },
          update_pay_info: {
            label: "Edit team member pay information",
          },
          request_change: {
            label: "Request changes to team member information",
          },
          terminate: {
            label: "Terminate team members",
          },
          delete: {
            label: "Delete team members",
          },
          settings: {
            label: "Manage team settings",
          },
        },
      },
      documents: {
        label: "Documents",
        items: {
          company: {
            label: "Company documents",
            permissions: {
              create: {
                label: "Create company documents",
              },
              read: {
                label: "View company documents",
              },
              update: {
                label: "Edit company documents",
              },
              delete: {
                label: "Delete company documents",
              },
              read_sensitive: {
                label: "View sensitive company documents",
              },
              update_sensitive: {
                label: "Edit sensitive company documents",
              },
              delete_sensitive: {
                label: "Delete sensitive company documents",
              },
              send_esignature_requests: {
                label: "Send e-signature requests for company documents",
              },
            },
          },
          team_member: {
            label: "Team member documents",
            permissions: {
              create: {
                label: "Create team member documents",
              },
              read: {
                label: "View team member documents",
              },
              read_sensitive: {
                label: "View sensitive team member documents",
              },
              update: {
                label: "Edit team member documents",
              },
              update_sensitive: {
                label: "Edit sensitive team member documents",
              },
              delete: {
                label: "Delete team member documents",
              },
              delete_sensitive: {
                label: "Delete sensitive team member documents",
              },
              send_esignature_requests: {
                label: "Send e-signature requests for team member documents",
              },
            },
          },
          settings: {
            label: "Manage document settings",
          },
        },
      },
      time_off: {
        label: "Time off",
        items: {
          policies: {
            label: "Policies",
            permissions: {
              create: {
                label: "Create time off policies",
              },
              read: {
                label: "View time off policies",
              },
              update: {
                label: "Edit time off policies",
              },
              delete: {
                label: "Delete time off policies",
              },
            },
          },
          requests: {
            label: "Requests",
            items: {
              personal: {
                label: "Personal",
                permissions: {
                  create: {
                    label: "Create time off requests",
                  },
                  read: {
                    label: "View time off requests",
                  },
                  update: {
                    label: "Edit time off requests",
                  },
                  delete: {
                    label: "Delete time off requests",
                  },
                  approve: {
                    label: "Approve time off requests",
                  },
                },
              },
              others: {
                label: "Others",
                permissions: {
                  create: {
                    label: "Create time off requests",
                  },
                  read: {
                    label: "View time off requests",
                  },
                  update: {
                    label: "Edit time off requests",
                  },
                  delete: {
                    label: "Delete time off requests",
                  },
                  approve: {
                    label: "Approve time off requests",
                  },
                },
              },
            },
          },
          holidays: {
            label: "Holiday schedules",
            permissions: {
              read: {
                label: "View holiday schedules",
              },
              manage: {
                label: "Manage holiday schedules",
              },
            },
          },
          settings: {
            label: "Manage time off settings",
          },
          leave: {
            label: "Leave of absence",
            permissions: {
              manage: {
                label: "Manage leave of absence",
              },
            },
          },
        },
      },
      recruiting: {
        label: "Recruiting",
        items: {
          job_postings: {
            label: "Job Postings",
            permissions: {
              create: {
                label: "Create job postings",
              },
              read: {
                label: "View job postings",
              },
              update: {
                label: "Edit job postings",
              },
              delete: {
                label: "Delete job postings",
              },
            },
          },
          job_applications: {
            label: "Job Applications",
            permissions: {
              create: {
                label: "Create job applications",
              },
              read: {
                label: "View job applications",
              },
              update: {
                label: "Edit job applications",
              },
              hire: {
                label: "Hire job applicants",
              },
              delete: {
                label: "Delete job applications",
              },
              read_sensitive: {
                label: "View demographic information (EEO/OFCCP)",
              },
            },
          },
          offers: {
            label: "Offers",
            items: {
              offer_templates: {
                label: "Offer templates",
                permissions: {
                  create: {
                    label: "Create offer templates",
                  },
                  read: {
                    label: "View offer templates",
                  },
                  update: {
                    label: "Edit offer templates",
                  },
                  delete: {
                    label: "Delete offer templates",
                  },
                },
              },
              offer_letters: {
                label: "Offer letters",
                permissions: {
                  create: {
                    label: "Create offer letters",
                  },
                  read: {
                    label: "View offer letters",
                  },
                  update: {
                    label: "Edit offer letters",
                  },
                  delete: {
                    label: "Delete offer letters",
                  },
                },
              },
            },
          },
        },
      },

      certifications: {
        label: "Certifications",
        items: {
          certification_types: {
            label: "Certification types",
            permissions: {
              create: {
                label: "Create certification types",
              },
              read: {
                label: "View certification types",
              },
              update: {
                label: "Edit certification types",
              },
              delete: {
                label: "Delete certification types",
              },
            },
          },
          certifications: {
            label: "Certifications",
            permissions: {
              request: {
                label: "Request certifications",
              },
              upload: {
                label: "Upload certifications",
              },
              read: {
                label: "View certifications",
              },
              update: {
                label: "Edit certifications",
              },
              delete: {
                label: "Delete certifications",
              },
            },
          },
        },
      },
      performance: {
        label: "Performance",
        items: {
          schedules: {
            label: "Review schedules",
            permissions: {
              create: {
                label: "Create review schedules",
              },
              read: {
                label: "View review schedules",
              },
              update: {
                label: "Edit review schedules",
              },
              delete: {
                label: "Delete review schedules",
              },
            },
          },
          reviews: {
            label: "Reviews",
            items: {
              personal: {
                label: "Personal",
                permissions: {
                  create: {
                    label: "Create personal reviews",
                  },
                  read: {
                    label: "View personal reviews",
                  },
                  update: {
                    label: "Edit personal reviews",
                  },
                  delete: {
                    label: "Delete personal reviews",
                  },
                },
              },
              others: {
                label: "Others",
                permissions: {
                  create: {
                    label: "Create reviews for others",
                  },
                  read: {
                    label: "View reviews for others",
                  },
                  update: {
                    label: "Edit reviews for others",
                  },
                  delete: {
                    label: "Delete reviews for others",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  "Workforce Management": {
    label: "Workforce Management",
    items: {
      jobs: {
        label: "Jobs",
        permissions: {
          create: {
            label: "Create jobs",
          },
          read: {
            label: "View jobs",
          },
          update: {
            label: "Edit jobs",
          },
          delete: {
            label: "Delete jobs",
          },
          settings: {
            label: "Manage job settings",
          },
        },
      },
      timesheets: {
        label: "Timesheets",
        items: {
          personal: {
            label: "Personal timesheets",
            permissions: {
              dashboard_clock_in: {
                label: "Clock in from the dashboard",
              },
              clock_in: {
                label: "View live timesheets",
              },
              create: {
                label: "Create timesheets",
              },
              read: {
                label: "View timesheets",
              },

              update: {
                label: "Edit timesheets",
              },

              approve: {
                label: "Approve timesheets",
              },
              delete: {
                label: "Delete timesheets",
              },
              read_sensitive: {
                label: "View sensitive timesheet information (i.e.pay rates, benefits contributions, etc)",
              },
              update_sensitive: {
                label: "Edit sensitive timesheet information (i.e. pay rates, benefits contributions, etc)",
              },
            },
          },
          others: {
            label: "Others timesheets",
            permissions: {
              clock_in: {
                label: "View live timesheets",
              },
              create: {
                label: "Create timesheets",
              },
              read: {
                label: "View timesheets",
              },
              update: {
                label: "Edit timesheets",
              },
              approve: {
                label: "Approve timesheets",
              },
              delete: {
                label: "Delete timesheets",
              },
              read_sensitive: {
                label: "View sensitive timesheet information (i.e. pay rates, benefits contributions, etc)",
              },
              update_sensitive: {
                label: "Edit sensitive timesheet information (i.e. pay rates, benefits contributions, etc)",
              },
            },
          },
          settings: {
            label: "Manage timesheet settings",
          },
        },
      },

      equipment: {
        label: "Equipment",
        items: {
          inventory: {
            label: "Equipment inventory",
            permissions: {
              read: {
                label: "View equipment",
              },
              manage: {
                label: "Manage equipment",
              },
            },
          },
          timesheets: {
            label: "Equipment timesheets",
            permissions: {
              create: {
                label: "Create equipment timesheets",
              },
              read: {
                label: "View equipment timesheets",
              },
              update: {
                label: "Edit equipment timesheets",
              },
              approve: {
                label: "Approve equipment timesheets",
              },
              delete: {
                label: "Delete equipment timesheets",
              },
            },
          },
          settings: {
            label: "Manage equipment settings",
          },
        },
      },
      assignments: {
        label: "Schedule",
        permissions: {
          create: {
            label: "Create assignments",
          },
          read: {
            label: "View assignments",
          },
          update: {
            label: "Edit assignments",
          },
          delete: {
            label: "Delete assignments",
          },
          settings: {
            label: "Manage assignment settings",
          },
        },
      },
      daily_reports: {
        label: "Daily Reports",
        items: {
          personal: {
            label: "Personal daily reports",
            permissions: {
              create: {
                label: "Create daily reports",
              },
              read: {
                label: "View and download daily reports",
              },
              update: {
                label: "Edit daily reports",
              },
              approve: {
                label: "Approve daily reports",
              },
              delete: {
                label: "Delete daily reports",
              },
            },
          },
          others: {
            label: "Daily reports for others",
            permissions: {
              create: {
                label: "Create daily reports",
              },
              read: {
                label: "View and download daily reports",
              },
              update: {
                label: "Edit daily reports",
              },
              approve: {
                label: "Approve daily reports",
              },
              delete: {
                label: "Delete daily reports",
              },
            },
          },
          settings: {
            label: "Manage daily report settings",
          },
        },
      },
    },
  },
  "Payroll & Compliance": {
    label: "Payroll & Compliance",
    items: {
      payrolls: {
        label: "Payrolls",
        permissions: {
          create: {
            label: "Create payrolls",
          },
          read: {
            label: "View payrolls",
          },
          update: {
            label: "Edit payrolls",
          },
          approve: {
            label: "Approve and/or reopen payrolls",
          },
          void: {
            label: "Void payrolls",
          },
          delete: {
            label: "Delete payrolls",
          },
          settings: {
            label: "Manage payroll settings",
          },
        },
      },
      benefits: {
        label: "Benefits",
        permissions: {
          create: {
            label: "Create benefits",
          },
          read: {
            label: "View benefits",
          },
          update: {
            label: "Edit benefits",
          },
          delete: {
            label: "Delete benefits",
          },
        },
      },
      post_tax_deductions: {
        label: "Post-tax deductions",
        permissions: {
          create: {
            label: "Create post-tax deductions",
          },
          read: {
            label: "View post-tax deductions",
          },
          update: {
            label: "Edit post-tax deductions",
          },
          delete: {
            label: "Delete post-tax deductions",
          },
        },
      },
      allowances: {
        label: "Allowances",
        permissions: {
          create: {
            label: "Create allowances",
          },
          read: {
            label: "View allowances",
          },
          update: {
            label: "Edit allowances",
          },
          delete: {
            label: "Delete allowances",
          },
        },
      },
    },
  },
  "Expense Management": {
    label: "Spend Management",
    items: {
      reimbursements: {
        label: "Reimbursements",
        items: {
          personal: {
            label: "Personal reimbursements",
            permissions: {
              create: {
                label: "Create reimbursements",
              },
              read: {
                label: "View reimbursements",
              },
              update: {
                label: "Edit reimbursements",
              },
              approve: {
                label: "Approve reimbursements",
              },
              delete: {
                label: "Delete reimbursements",
              },
              process_payment: {
                label: "Process payment for reimbursements",
              },
            },
          },
          others: {
            label: "Reimbursements for others",
            permissions: {
              create: {
                label: "Create reimbursements",
              },
              read: {
                label: "View reimbursements",
              },
              update: {
                label: "Edit reimbursements",
              },
              approve: {
                label: "Approve reimbursements",
              },
              delete: {
                label: "Delete reimbursements",
              },
              process_payment: {
                label: "Process payment for reimbursements",
              },
            },
          },
        },
      },
      miter_cards: {
        label: "Miter Cards",
        permissions: {
          create: {
            label: "Issue Miter cards",
          },
          read: {
            label: "View Miter cards",
          },
          update: {
            label: "Edit Miter cards",
          },
          delete: {
            label: "Delete Miter cards",
          },
        },
      },
      third_party_cards: {
        label: "Third-party cards",
        permissions: {
          create: {
            label: "Connect third-party cards",
          },
          read: {
            label: "View third-party cards",
          },
          update: {
            label: "Edit third-party cards",
          },
          delete: {
            label: "Delete third-party cards",
          },
        },
      },
      card_transactions: {
        label: "Card transactions",
        items: {
          personal: {
            label: "Personal card transactions",
            permissions: {
              create: {
                label: "Create card transactions",
              },
              read: {
                label: "View card transactions",
              },
              update: {
                label: "Edit card transactions",
              },
              approve: {
                label: "Approve card transactions",
              },
              delete: {
                label: "Delete card transactions",
              },
              split: {
                label: "Split card transactions",
              },
            },
          },
          others: {
            label: "Card transactions for others",
            permissions: {
              create: {
                label: "Create card transactions",
              },
              read: {
                label: "View card transactions",
              },
              update: {
                label: "Edit card transactions",
              },
              approve: {
                label: "Approve card transactions",
              },
              delete: {
                label: "Delete card transactions",
              },
              split: {
                label: "Split card transactions",
              },
            },
          },
        },
      },
      bill_pay: {
        label: "Bill pay",
        items: {
          bills: {
            label: "Bills",
            permissions: {
              create: {
                label: "Create new bill",
              },
              read: {
                label: "View bills",
              },
              update: {
                label: "Edit bills",
              },
              pay: {
                label: "Pay bills",
              },
              delete: {
                label: "Delete bills",
              },
            },
          },
        },
      },
      expenses: {
        label: "Spend management settings",
        permissions: {
          settings: {
            label: "Manage spend management settings",
          },
        },
      },
    },
  },
  Company: {
    label: "Company",
    items: {
      accounting: {
        label: "Accounting",
        items: {
          ledger_accounts: {
            label: "Ledger accounts",
            permissions: {
              create: {
                label: "Create ledger accounts",
              },
              read: {
                label: "View ledger accounts",
              },
              update: {
                label: "Edit ledger accounts",
              },
              delete: {
                label: "Delete ledger accounts",
              },
            },
          },
          ledger_entries: {
            label: "Ledger entries",
            permissions: {
              generate: {
                label: "Generate/regenerate ledger entries",
              },
              read: {
                label: "View and download ledger entries",
              },
              sync: {
                label: "Delete ledger entries",
              },
            },
          },
          settings: {
            label: "Manage accounting settings",
          },
        },
      },
      integrations: {
        label: "Integrations",
        permissions: {
          manage: {
            label: "Manage integrations",
          },
        },
      },
      forms: {
        label: "Forms",
        items: {
          forms: {
            label: "Forms",
            permissions: {
              manage: {
                label: "Manage forms",
              },
              manage_sensitive: {
                label: "Manage sensitive forms",
              },
            },
          },
          submissions: {
            label: "Submissions",
            items: {
              personal: {
                label: "Personal submissions",
                permissions: {
                  create: {
                    label: "Fill out forms",
                  },
                  read: {
                    label: "View your form submissions",
                  },
                  update: {
                    label: "Edit your form submissions",
                  },
                  delete: {
                    label: "Delete your form submissions",
                  },
                },
              },
              others: {
                label: "Submissions for others",
                permissions: {
                  create: {
                    label: "Request submissions",
                  },
                  read: {
                    label: "View others' submissions",
                  },
                  update: {
                    label: "Edit others' submissions",
                  },
                  delete: {
                    label: "Delete others' submissions",
                  },
                },
              },
            },
          },
        },
        settings: {
          label: "Manage form settings",
        },
      },
      lists: {
        label: "Lists",
        items: {
          pay_rate_groups: {
            label: "Pay rate groups",
            permissions: {
              read: {
                label: "View pay rate groups",
              },
              manage: {
                label: "Manage pay rate groups",
              },
            },
          },
          activities: {
            label: "Activities",
            permissions: {
              read: {
                label: "View activities",
              },
              manage: {
                label: "Manage activities",
              },
            },
          },
          cost_types: {
            label: "Cost types",
            permissions: {
              read: {
                label: "View cost types",
              },
              manage: {
                label: "Manage cost types",
              },
            },
          },
          equipment: {
            label: "Equipment",
            permissions: {
              read: {
                label: "View equipment",
              },
              manage: {
                label: "Manage equipment",
              },
            },
          },
          departments: {
            label: "Departments",
            permissions: {
              read: {
                label: "View departments",
              },
              manage: {
                label: "Manage departments",
              },
            },
          },
          customers: {
            label: "Customers",
            permissions: {
              read: {
                label: "View customers",
              },
              manage: {
                label: "Manage customers",
              },
            },
          },
          workers_comp_codes: {
            label: "Workers' comp codes and groups",
            permissions: {
              read: {
                label: "View workers' comp codes and groups",
              },
              manage: {
                label: "Manage workers' comp codes and groups",
              },
            },
          },
          workplaces: {
            label: "Workplaces",
            permissions: {
              read: {
                label: "View workplaces",
              },
              manage: {
                label: "Manage workplaces",
              },
            },
          },
          locations: {
            label: "Locations",
            permissions: {
              read: {
                label: "View locations",
              },
              manage: {
                label: "Manage locations",
              },
            },
          },
          classes: {
            label: "Classes",
            permissions: {
              read: {
                label: "View classes",
              },
              manage: {
                label: "Manage classes",
              },
            },
          },
        },
      },
      chat: {
        label: "Chat",
        permissions: {
          send: {
            label: "Send messages",
          },
          read: {
            label: "View messages",
          },
        },
      },
      reports: {
        label: "Reports",
        items: {
          cpr: {
            label: "Certified payroll reports",
          },
          pay_period_hours: {
            label: "Pay period hours",
          },
          earnings_summary: {
            label: "Earnings summary",
          },
          fringe_report: {
            label: "Fringe report",
          },
          payroll_journal: {
            label: "Payroll journal",
          },
          das_140: {
            label: "DAS 140",
          },
          das_142: {
            label: "DAS 142",
          },
          job_costing: {
            label: "Job costing",
          },
          timesheet_analysis: {
            label: "Timesheet analysis",
          },
          eeo_1: {
            label: "EEO-1",
          },
          team_summary: {
            label: "Team summary",
          },
          deductions_contributions: {
            label: "Deductions and contributions",
          },
          reimbursements: {
            label: "Reimbursements",
          },
          workers_comp: {
            label: "Workers' comp",
          },
          "401k_report": {
            label: "401k report",
          },
          simple_ira_report: {
            label: "SIMPLE IRA report",
          },
          fringe_benefit_statement: {
            label: "Fringe benefit statement",
          },
          apprentice_ratio: {
            label: "Apprentice ratio",
          },
          training_contributions: {
            label: "Training contributions",
          },
          time_off_balances: {
            label: "Time off balances",
          },
          birthdays_and_tenure_milestones: {
            label: "Birthdays and tenure milestones",
          },
          ledger_entry: {
            label: "Ledger entry",
          },
          pay_rate_histories: {
            label: "Pay rate histories",
          },
          tax_deposits: {
            label: "Tax deposits",
          },
          tax_filings: {
            label: "Tax filings",
          },
          w2_preview: {
            label: "W2 preview",
          },
          expenses: {
            label: "Credit card transactions and reimbursements, including images of receipts",
          },
          miter_card_statement: {
            label: "Monthly statements for Miter Card transactions",
          },
          team_member_retention: {
            label: "Team member retention/turnover report",
          },
          settings: {
            label: "Manage reports settings",
          },
        },
      },
      company: {
        label: "Company settings",
        items: {
          settings: {
            label: "Manage settings",
            permissions: {
              manage: {
                label: "Manage general company settings",
              },
            },
          },
          billing: {
            label: "Manage billing",
            permissions: {
              manage: {
                label: "Manage billing",
              },
            },
          },
          permission_groups: {
            label: "Manage permission groups",
            permissions: {
              manage: {
                label: "Manage permission groups",
              },
            },
          },
          forums: {
            label: "Manage feature requests",
            permissions: {
              manage: {
                label: "Create posts and comment on feature requests",
              },
            },
          },
          roles: {
            label: "Manage roles",
            permissions: {
              manage: {
                label: "Create and edit roles",
              },
            },
          },
        },
      },
    },
  },
};

/**
 * Helper function to recursively build the flattened permission table list
 * - Turns this: { "Human Resources": { items: { team: { permissions: { create: { label: "Create team members" } } } } } }
 * - Into this: { _id: "Human Resources.items.team.permissions.create", label: "Create team members", path: "team.create" }
 *
 * This can then be loaded in to TableV2 with treeData={true} so that we have hierarchical checkboxes with labels for the permissions
 * */

const buildPermissionTableListHelper = (
  path: string,
  level: number,
  visited: Set<string>,
  data: PermissionsTableEntry[]
) => {
  // If we've already visited this node, return
  if (visited.has(path)) return;

  // Get the current node
  const obj = get(PERMISSIONS_CONFIG, path);
  if (!obj) return;

  // Build and add the table entry
  const tablePath = path.replaceAll(".items.", ".").replaceAll(".permissions.", ".");
  data.push({ _id: path, label: obj.label, info: obj.info, path: tablePath });

  // Mark the current node as visited
  visited.add(path);

  // If the current node has items, generate the table entries for those items
  if (obj.items) {
    Object.keys(obj.items).forEach((key) => {
      buildPermissionTableListHelper(`${path}.items.${key}`, level + 1, visited, data);
    });
  }

  // If the current node has permissions, generate the table entries for those permissions
  if (obj.permissions) {
    Object.keys(obj.permissions).forEach((key) => {
      buildPermissionTableListHelper(`${path}.permissions.${key}`, level + 1, visited, data);
    });
  }
};

/**
 * Flattened version of the permission config used to load the permissions into a table
 *
 * - Turns this: { "Human Resources": { items: { team: { permissions: { create: { label: "Create team members" } } } } } }
 * - Into this: [{ _id: "Human Resources.items.team.permissions.create", label: "Create team members", path: "team.create" }]
 *
 * This can then be loaded in to TableV2 with treeData={true} so that we have hierarchical checkboxes with labels for the permissions
 * */
export const PERMISSIONS_TABLE_LIST: PermissionsTableEntry[] = (() => {
  const data: PermissionsTableEntry[] = [];

  const visited = new Set<string>();
  const keys = Object.keys(PERMISSIONS_CONFIG);

  keys.forEach((key) => {
    buildPermissionTableListHelper(key, 0, visited, data);
  });

  return data;
})();

/** Column configuration for the permissions table */
export const PERMISSIONS_TABLE_COLUMNS: ColumnConfig<PermissionsTableEntry>[] = [
  {
    headerName: "Label",
    field: "label",
    rowGroup: true,
    hide: true,
    checkboxSelection: true,
  },
  {
    headerName: "Info",
    field: "info",
    maxWidth: 50,
    tooltipValueGetter: (params) => params.data?.info,
    cellRenderer: (params: ICellRendererParams): ReactElement | undefined => {
      if (!params.data?.info) return;
      return <Info color={"#4d55bb"} weight="duotone" />;
    },
  },
];

export const buildSelectedPermissionsList = (
  permissionGroup: PermissionGroup | undefined,
  opts?: { includeParent?: boolean }
): PermissionsTableEntry[] => {
  if (!permissionGroup) return [];
  const permissionPaths = extractPaths(permissionGroup?.permissions || {});

  return PERMISSIONS_TABLE_LIST.filter((d) => {
    const cleanedPath = d.path.replaceAll("items.", "").replaceAll("permissions.", "").split(".").slice(1);

    // If we want to include the parent as checked (only required for the non form version of the table) then we need to check if the cleaned path is a prefix of the permission path
    if (opts?.includeParent && cleanedPath.length) {
      return permissionPaths.some((path) => path.startsWith(cleanedPath.join(".")));
    } else {
      return permissionPaths.includes(cleanedPath.join("."));
    }
  });
};

export const userHasPermission = (entityGroups: string[], userGroups: string[]): boolean => {
  return entityGroups.length === 0 || entityGroups.some((group) => userGroups.includes(group));
};
