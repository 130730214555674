import { ClickAwayListener } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { ModalFooter } from "ui";
import { ModalHeader } from "ui";
import * as vals from "dashboard/utils/validators";
import { Exception } from "./Exceptions";

type Props = {
  create: (data: Exception) => void;
  hide: () => void;
};

const AddException: React.FC<Props> = ({ create, hide }) => {
  const { register, errors, handleSubmit } = useForm();

  const save = (data) => {
    create(data);
    hide();
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form">
          <ModalHeader heading={"Add exception"} onHide={hide} />
          <div className="modal-body form">
            <div className="vertical-spacer"></div>
            <Formblock
              label="Exception (craft)"
              type="text"
              register={register(vals.required)}
              name="exception"
              className="modal"
              errors={errors}
              editing={true}
            />
            <Formblock
              label="Explanation"
              type="paragraph"
              register={register(vals.required)}
              name="explanation"
              className="modal"
              errors={errors}
              editing={true}
            />
            <div className="vertical-spacer"></div>
          </div>
          <ModalFooter submitText="Save" onCancel={hide} onSubmit={handleSubmit(save)} className="form" />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default AddException;
