/** This function is a temporary fix to clean any potentially faulty timestamps that might be in milliseconds
 * or 1000s of seconds. It will convert these values to seconds. We know a Unix timestamp in seconds must less
 * 1e10 and greater than 1e9.
 */

const MAX_UNIX_TIMESTAMP = 1e10;
const MIN_UNIX_TIMESTAMP = 1e9;
export const convertTimeToSeconds = (seconds: number): number => {
  if (seconds > MAX_UNIX_TIMESTAMP) {
    return seconds / 1000;
  } else if (seconds < MIN_UNIX_TIMESTAMP) {
    return seconds * 1000;
  }
  return seconds;
};
