import React from "react";

import "./dataBox.css";

type Props = {
  title: string;
  className?: string;
  onEdit?: () => void;
  editText?: string | React.ReactElement;
  rows?: React.ReactChildren;
  children?: React.ReactNode;
};

const DataBox: React.FC<Props> = ({ title, className, children, onEdit, editText, rows }) => {
  const renderRows = () => {
    const rowExpression: React.ReactChild[] = [];
    if (rows) {
      for (const key of Object.keys(rows)) {
        rowExpression.push(
          <div className={"data-box-section " + (className ? className : "")} key={key}>
            <span className={"data-box-section-title " + (className ? className : "")}>{key}</span>
            <span className={"data-box-section-value " + (className ? className : "")}>{rows[key]}</span>
          </div>
        );
      }
    }

    return rowExpression;
  };
  return (
    <div className={"data-box " + (className ? className : "")}>
      <div className="data-box-header-wrapper">
        <div className="data-box-header">{title}</div>
        {onEdit && (
          <div className="data-box-edit" onClick={onEdit}>
            {editText ? editText : "Edit"}
          </div>
        )}
      </div>
      <div className="data-box-body">
        {children && children}
        {rows && renderRows()}
      </div>
    </div>
  );
};

export default DataBox;
