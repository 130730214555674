import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import { Location, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import {
  useActiveCompanyId,
  useLedgerMappingOptions,
  useLookupLocation,
  useLocationOptions,
  useTeamOptions,
  useSetLocations,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Option } from "ui/form/Input";

const STATUS_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

type LocationFormFields = {
  name: string;
  external_id: string;
  status: Option<"active" | "inactive">;
  parent_location_id?: Option<string>;
  supervisor_ids?: Option<string>[];
  superintendent_ids?: Option<string>[];
  ledger_mapping_id?: Option<string>;
};

type Props = {
  locationId?: string;
  onFinish: (location: Location) => void;
  onHide: () => void;
};

const LocationModal: React.FC<Props> = ({ locationId, onFinish, onHide }) => {
  const form = useForm();
  const setLocations = useSetLocations();
  const lookupLocation = useLookupLocation();
  const { can, cannot } = useMiterAbilities();
  const glMappingOptions = useLedgerMappingOptions();

  const [loading, setLoading] = useState(false);
  const { control, register, errors, handleSubmit } = form;

  const locationOptions = useLocationOptions();
  const selectedLocation = lookupLocation(locationId);
  const activeCompanyId = useActiveCompanyId();

  const supervisorDefaults = selectedLocation?.supervisor_ids?.map((supervisor_id) => supervisor_id);
  const teamOptionsForSupervisors = useTeamOptions({ defaultValue: supervisorDefaults });

  const superintendentDefaults = selectedLocation?.superintendent_ids?.map(
    (superintendent_id) => superintendent_id
  );
  const teamOptionsForSuperIntendents = useTeamOptions({ defaultValue: superintendentDefaults });

  const buildParams = (data: LocationFormFields) => {
    return {
      ...data,
      company_id: activeCompanyId!,
      name: data.name,
      external_id: data.external_id,
      status: data.status?.value,
      parent_location_id: data.parent_location_id?.value || null,
      supervisor_ids: data.supervisor_ids ? data.supervisor_ids.map((s) => s.value) : [],
      superintendent_ids: data.superintendent_ids ? data.superintendent_ids.map((s) => s.value) : [],
      ledger_mapping_id: data.ledger_mapping_id?.value || null,
    };
  };

  const createLocation = async (data: LocationFormFields) => {
    if (selectedLocation || !activeCompanyId || cannot("lists:locations:manage")) return;
    setLoading(true);
    try {
      const res = await MiterAPI.locations.create(buildParams(data));
      if (res.error) throw new Error(res.error);

      Notifier.success("Location created successfully");

      setLocations((prev) => prev.concat(res));
      onFinish(res);
      onHide();
    } catch (e: $TSFixMe) {
      console.log("Error creating location:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateLocation = async (data: LocationFormFields) => {
    if (!selectedLocation || cannot("lists:locations:manage")) return;
    setLoading(true);
    try {
      const res = await MiterAPI.locations.update(selectedLocation._id, buildParams(data));
      if (res.error) throw new Error(res.error);

      setLocations((prev) => prev.concat(res));
      onFinish(res);
      onHide();

      Notifier.success("Location updated successfully");
    } catch (e: $TSFixMe) {
      console.log("Error updating location:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    if (selectedLocation) {
      handleSubmit(updateLocation)();
    } else {
      handleSubmit(createLocation)();
    }
  };

  return (
    <ActionModal
      headerText={selectedLocation ? "Update locations" : "Create locations"}
      showSubmit={can("lists:locations:manage")}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={submit}
      loading={loading}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={selectedLocation?.name}
          className="modal"
          disabled={cannot("lists:locations:manage")}
        />

        <Formblock
          type="text"
          name="external_id"
          label="ID*"
          labelInfo="Identifier you can use to identify this location"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={selectedLocation?.external_id}
          className="modal"
          disabled={cannot("lists:locations:manage")}
        />

        <Formblock
          label="Status*"
          type="select"
          control={control}
          className="modal"
          options={STATUS_OPTIONS}
          register={register(vals.required)}
          defaultValue={selectedLocation?.status}
          name="status"
          editing={true}
          errors={errors}
          disabled={cannot("lists:locations:manage")}
        />

        <Formblock
          label="Parent Location"
          name="parent_location_id"
          type="select"
          height="auto"
          defaultValue={selectedLocation?.parent_location_id}
          options={locationOptions}
          className="modal"
          editing={true}
          maxMenuHeight={175}
          control={control}
          errors={errors}
          disabled={!!selectedLocation || cannot("lists:locations:manage")}
        />

        <Formblock
          label="Supervisors"
          name="supervisor_ids"
          type="multiselect"
          height="auto"
          defaultValue={supervisorDefaults}
          options={teamOptionsForSupervisors}
          className="modal"
          editing={true}
          maxMenuHeight={175}
          control={control}
          errors={errors}
          disabled={cannot("lists:locations:manage")}
        />

        <Formblock
          label="Superintendents"
          name="superintendent_ids"
          type="multiselect"
          height="auto"
          defaultValue={superintendentDefaults}
          options={teamOptionsForSuperIntendents}
          className="modal"
          editing={true}
          maxMenuHeight={175}
          control={control}
          errors={errors}
          disabled={cannot("lists:locations:manage")}
        />

        <Formblock
          labelStyle={{ minWidth: 150 }}
          label="GL account mapping"
          type="select"
          name="ledger_mapping_id"
          form={form}
          isClearable={true}
          options={glMappingOptions}
          defaultValue={selectedLocation?.ledger_mapping_id}
          editing={true}
          disabled={cannot("accounting:settings")}
          className="modal"
        />
      </div>
    </ActionModal>
  );
};

export default LocationModal;
