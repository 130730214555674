import { TextWithTooltip } from "ui";
import React from "react";
import { EnhancedMiterPayment } from "../payrollTypes";
import { isAutoCalculateFullyDisabled, isAutoCalculatePartiallyDisabled } from "dashboard/utils/adjustments";

export const AutoCalculateBadge: React.FC<{
  payment: EnhancedMiterPayment;
  useStateAsText: boolean;
  hideTooltip?: boolean;
}> = ({ payment, useStateAsText, hideTooltip }) => {
  const adjustment = payment.adjustment;
  let label = "Enabled";
  let tooltip = "Earnings and hours for this payment are being calculated automatically.";
  let className = "dot green";
  if (isAutoCalculateFullyDisabled(adjustment)) {
    label = "Disabled";
    tooltip = "Auto-calculate has been disabled. Please add earnings and hours manually.";
    className = "dot gray";
  } else if (isAutoCalculatePartiallyDisabled(adjustment)) {
    label = "Partial";
    tooltip =
      "Auto-calculate has been partially enabled. Please adjust your settings or add earnings and hours manually.";
    className = "dot yellow";
  }

  if (paymentHasAutoCalculateError(payment)) {
    label = "Error";
    tooltip = "There was an error auto-calculating earnings. Please review and add earnings manually.";
    className = "dot red";
  }

  const renderText = () => {
    if (hideTooltip) {
      return <span>{useStateAsText ? label : "Auto-Calculate"}</span>;
    } else {
      return (
        <TextWithTooltip
          id={payment.team_member._id + "auto" + useStateAsText} // concatenating useStateAsText to the id to avoid duplicate ids on the PaymentModal
          tooltip={tooltip}
          suppressUnderline={true}
        >
          <span>{useStateAsText ? label : "Auto-Calculate"}</span>
        </TextWithTooltip>
      );
    }
  };

  return (
    <div>
      <div className="auto-calculate-badge">
        <div className={className}></div>
        {renderText()}
      </div>
    </div>
  );
};

export const buildAutoCalculateLabel = (payment: EnhancedMiterPayment): string => {
  const adjustment = payment.adjustment;
  let label = "Enabled";
  if (isAutoCalculateFullyDisabled(adjustment)) {
    label = "Disabled";
  } else if (isAutoCalculatePartiallyDisabled(adjustment)) {
    label = "Partial";
  }

  if (paymentHasAutoCalculateError(payment)) {
    label = "Error";
  }

  return label;
};

export const buildAutoCalculateTooltip = (payment: EnhancedMiterPayment): string => {
  const adjustment = payment.adjustment;
  let tooltip = "Earnings and hours for this payment are being calculated automatically.";
  if (isAutoCalculateFullyDisabled(adjustment)) {
    tooltip = "Auto-calculate has been disabled. Please add earnings and hours manually.";
  } else if (isAutoCalculatePartiallyDisabled(adjustment)) {
    tooltip =
      "Auto-calculate has been partially enabled. Please adjust your settings or add earnings and hours manually.";
  }

  if (paymentHasAutoCalculateError(payment)) {
    tooltip = "There was an error auto-calculating earnings. Please review and add earnings manually.";
  }

  return tooltip;
};

const paymentHasAutoCalculateError = (payment: EnhancedMiterPayment): boolean => {
  return !!payment.auto_calculate_log?.some((log) => log.error);
};
