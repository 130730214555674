import React from "react";
import PayrollContext from "./payrollContext";
import { ExpenseReimbursementsTable } from "dashboard/pages/expenses/ExpenseReimbursementsTable";

type Props = {
  viewOnly?: boolean;
  emptyHeader?: string;
};

const ReviewExpenseReimbursements: React.FC<Props> = ({ viewOnly }) => {
  const payrollContext = React.useContext(PayrollContext);
  const { expensesLoading, getExpenseReimbursementEntries, expenseReimbursementEntries, payroll } =
    payrollContext;

  const isDraft = payroll?.check_payroll.status === "draft";

  return (
    <div style={{ marginTop: isDraft ? undefined : -25 }}>
      <ExpenseReimbursementsTable
        viewOnly={viewOnly}
        customDataFetch={getExpenseReimbursementEntries}
        customData={expenseReimbursementEntries}
        customLoading={expensesLoading}
        shouldRedirectURLWhenOpening={false}
        togglerConfigFilter={isDraft ? payrollReviewReimbursements : undefined}
        hideToggler={!isDraft}
      />
    </div>
  );
};

export default ReviewExpenseReimbursements;

// Has to be outside of the component so it's stable
const payrollReviewReimbursements = (path: string) => {
  return path === "unapproved" || path === "approved";
};
