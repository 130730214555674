import React from "react";
import InfoButton from "dashboard/components/information/information";
import { TimesheetSettings } from "./TimesheetSettings";

type Props = {
  updateTimesheetSettings: (newFields: Partial<TimesheetSettings>) => void;
  timesheetSettings: TimesheetSettings;
};

export const DefaultTimesheetsView: React.FC<Props> = ({ updateTimesheetSettings, timesheetSettings }) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Default timesheets view</div>
        <InfoButton text="Controls the default timesheet view that team members will see when accessing timesheets from the dashboard." />
      </div>

      <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
        <div className="flex" style={{ marginTop: 5 }}>
          <input
            style={{ marginLeft: 10 }}
            type="radio"
            name="list"
            checked={
              !timesheetSettings.default_timesheets_view ||
              timesheetSettings.default_timesheets_view === "list"
            }
            onChange={() => updateTimesheetSettings({ default_timesheets_view: "list" })}
          />
          <span style={{ marginLeft: 15 }}>
            <span className="bold">List view</span>
            {`: see a list of timesheet punches. Timesheets can be grouped, sorted, and filtered by any dimension.`}
          </span>
        </div>
        <div className="flex" style={{ marginTop: 5 }}>
          <input
            style={{ marginLeft: 10 }}
            name="pay_period"
            type="radio"
            checked={timesheetSettings.default_timesheets_view === "pay_period"}
            onChange={() => updateTimesheetSettings({ default_timesheets_view: "pay_period" })}
          />
          <span style={{ marginLeft: 15 }}>
            {" "}
            <span className="bold">Pay period view</span>
            {`: see hours by team member and job for each day within a date range.`}
          </span>
        </div>
      </div>
    </div>
  );
};
