import React, { FC } from "react";
import { uploadTimyMCEImage } from "dashboard/utils/offer-letters";
import Editor, { BlobInfo, EditorProps } from "ui/editor/Editor";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type OfferEditorProps = Omit<EditorProps, "handleImageUpload"> & {
  setS3URLToFileIdMap: React.Dispatch<React.SetStateAction<Record<string, string>>>;
};

export const OfferEditor: FC<OfferEditorProps> = ({
  disabled,
  defaultHTML,
  setS3URLToFileIdMap,
  smartFields,
  editorRef,
  height,
  toolbar,
}) => {
  const companyId = useActiveCompanyId();
  const handleImageUpload = async (blob: BlobInfo) => {
    if (!companyId) {
      throw new Error("Company ID is not set");
    }
    const { url, fileId } = await uploadTimyMCEImage({ companyId, tinyMCEBlob: blob });
    setS3URLToFileIdMap((prev) => ({ ...prev, [url]: fileId }));
    return url;
  };

  return (
    <Editor
      handleImageUpload={handleImageUpload}
      defaultHTML={defaultHTML}
      smartFields={smartFields}
      editorRef={editorRef}
      height={height}
      toolbar={toolbar}
      disabled={disabled}
    />
  );
};
