import {
  useActiveCompany,
  useCostTypeOptions,
  useLedgerMappingOptions,
  usePrgs,
} from "dashboard/hooks/atom-hooks";
import { booleanOptions } from "dashboard/utils";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ClickableText, Formblock } from "ui";
import { ActivityModalTabProps } from "./TabbedActivityModal";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useTeamMemberGroupOptions } from "../team-members/useTeamMemberGroupOptions";
import { useHasAccessToAccessFilters } from "dashboard/gating";
import { isEqual } from "lodash";
import { ActivityTeamMembersModal } from "./ActivityTeamMembersModal";

export const ActivityAdvancedOptions: React.FC<
  ActivityModalTabProps & {
    showContributionsTab: boolean;
    setShowContributionsTab: React.Dispatch<React.SetStateAction<boolean>>;
  }
> = ({ selectedActivity, handleFieldsChange, showContributionsTab, setShowContributionsTab, readonly }) => {
  const [openTeamMembersModal, setOpenTeamMembersModal] = useState<boolean>(false);
  const { control, errors } = useForm();
  const { can } = useMiterAbilities();
  const costTypeOptions = useCostTypeOptions();
  const ledgerMappingOptions = useLedgerMappingOptions();
  const prgs = usePrgs();
  const activeCompany = useActiveCompany();
  const multiWorkplacesEnabled = !!activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;
  const [showCustomAccessFilter, setShowCustomAccessFilter] = useState<boolean>(
    !!selectedActivity.enable_custom_access_filter
  );
  const [accessFilterViewType, setAccessFilterViewType] = useState<"included" | "excluded">("included");
  const hasAccessToAccessFilters = useHasAccessToAccessFilters();

  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    hideMitosaurs: true,
    excludedGroups: ["self"],
  });

  const selectedIncludedTMGroupsOption = useMemo(() => {
    const flatGroupOptions = teamMemberGroupOptions.flatMap((o) => o.options);
    return flatGroupOptions.filter((g) =>
      (selectedActivity?.custom_access_filter?.included_groups || []).some((sg) => isEqual(sg, g.value))
    );
  }, [selectedActivity?.custom_access_filter?.included_groups, teamMemberGroupOptions]);

  const selectedExcludedTMGroupsOption = useMemo(() => {
    const flatGroupOptions = teamMemberGroupOptions.flatMap((o) => o.options);
    return flatGroupOptions.filter((g) =>
      (selectedActivity?.custom_access_filter?.excluded_groups || []).some((sg) => isEqual(sg, g.value))
    );
  }, [selectedActivity?.custom_access_filter?.excluded_groups, teamMemberGroupOptions]);

  const includedTeamMemberGroupOptions = useMemo(() => {
    const includedTMGroupOptions = teamMemberGroupOptions.map((teamMemberGroupOption) => {
      const options = teamMemberGroupOption.options;

      const updatedOptions = options.filter(
        (option) => !selectedExcludedTMGroupsOption.some((o) => isEqual(o, option))
      );

      return { label: teamMemberGroupOption.label, options: updatedOptions };
    });

    return includedTMGroupOptions;
  }, [selectedExcludedTMGroupsOption, teamMemberGroupOptions]);

  const excludedTeamMemberGroupOptions = useMemo(() => {
    const excludedTMGroupOptions = teamMemberGroupOptions.map((teamMemberGroupOption) => {
      const options = teamMemberGroupOption.options;

      const updatedOptions = options.filter(
        (option) => !selectedIncludedTMGroupsOption.some((o) => isEqual(o, option))
      );

      return { label: teamMemberGroupOption.label, options: updatedOptions };
    });

    return excludedTMGroupOptions;
  }, [selectedIncludedTMGroupsOption, teamMemberGroupOptions]);

  return (
    <>
      <div className="vertical-spacer-small" />
      {selectedActivity.company_activity && (
        <Formblock
          label="Default"
          labelInfo="If true, this activity will be included by default when you enable custom activities for a job."
          type="select"
          name="default"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          options={booleanOptions}
          defaultValue={selectedActivity?.default ? "true" : "false"}
          onChange={(option) => handleFieldsChange({ default: option.value === "true" })}
          errors={errors}
          editing={true}
          disabled={readonly}
        />
      )}
      <Formblock
        label="Exclude in Certified Payroll"
        labelInfo="If true, this activity will not appear in Certified Payroll Reports."
        type="select"
        name="ignore_in_cprs"
        underlineTooltip={true}
        className="tabbed-modal"
        control={control}
        options={booleanOptions}
        defaultValue={selectedActivity?.ignore_in_cprs ? "true" : "false"}
        onChange={(option) => handleFieldsChange({ ignore_in_cprs: option.value === "true" })}
        errors={errors}
        editing={true}
        disabled={readonly}
      />
      <Formblock
        label="Ignore in OT calculations"
        labelInfo="If true, hours for this activity will not count toward daily or weekly OT."
        type="select"
        name="ignore_in_ot_calculations"
        underlineTooltip={true}
        className="tabbed-modal"
        control={control}
        options={booleanOptions}
        defaultValue={selectedActivity?.ignore_in_ot_calculations ? "true" : "false"}
        onChange={(option) => handleFieldsChange({ ignore_in_ot_calculations: option.value === "true" })}
        errors={errors}
        editing={true}
        disabled={readonly}
      />
      <Formblock
        label="Ignore benefit accruals"
        labelInfo="If true, hours for this activity will not accrue employee benefits."
        type="select"
        name="ignore_benefits"
        underlineTooltip={true}
        className="tabbed-modal"
        control={control}
        options={booleanOptions}
        defaultValue={selectedActivity?.ignore_benefits ? "true" : "false"}
        onChange={(option) => handleFieldsChange({ ignore_benefits: option.value === "true" })}
        errors={errors}
        editing={true}
        disabled={readonly}
      />
      {/* Only need to show `ignore_fringes` if the pay rate is nullish because if it's defined then we automatically ignore fringes */}
      {!!prgs.length && selectedActivity.pay_rate == null && (
        <Formblock
          label="Ignore fringe accruals"
          labelInfo="If true, hours for this activity will not accrue classification fringes."
          type="select"
          name="ignore_fringes"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          options={booleanOptions}
          defaultValue={selectedActivity?.ignore_fringes ? "true" : "false"}
          onChange={(option) => handleFieldsChange({ ignore_fringes: option.value === "true" })}
          errors={errors}
          editing={true}
          disabled={readonly}
        />
      )}
      {multiWorkplacesEnabled && (
        <Formblock
          label="Ignore job tax jurisdiction"
          labelInfo="If true, earnings with this activity will always use the team member's primary workplace."
          type="select"
          name="ignore_job_workplace"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          options={booleanOptions}
          defaultValue={selectedActivity?.ignore_job_workplace ? "true" : "false"}
          onChange={(option) => handleFieldsChange({ ignore_job_workplace: option.value === "true" })}
          errors={errors}
          editing={true}
          disabled={readonly}
        />
      )}
      <Formblock
        label="Enable contributions"
        labelInfo="Allows you to configure activity-specific employer contributions to in-kind benefit plans."
        type="select"
        underlineTooltip={true}
        className="tabbed-modal"
        control={control}
        options={booleanOptions}
        defaultValue={showContributionsTab ? "true" : "false"}
        onChange={(o) => {
          setShowContributionsTab(o.value === "true");
          handleFieldsChange({ benefit_contributions: [] });
        }}
        errors={errors}
        editing={true}
        disabled={readonly}
      />
      <Formblock
        label="Non-billable"
        labelInfo="If true, this activity will be not be billed to customers."
        type="select"
        name="non_billable"
        underlineTooltip={true}
        className="tabbed-modal"
        control={control}
        options={booleanOptions}
        defaultValue={selectedActivity?.non_billable ? "true" : "false"}
        onChange={(option) => handleFieldsChange({ non_billable: option.value === "true" })}
        errors={errors}
        editing={true}
        disabled={readonly}
      />
      {selectedActivity.pay_rate_type === "default" && (
        <Formblock
          label="Pay default rate"
          labelInfo="If true, team members will be paid their default pay rate when performing this activity, unless overridden by a manually-selected classification."
          type="select"
          name="always_pay_tm_default_rate"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          options={booleanOptions}
          defaultValue={selectedActivity?.always_pay_tm_default_rate ? "true" : "false"}
          onChange={(option) => handleFieldsChange({ always_pay_tm_default_rate: option.value === "true" })}
          errors={errors}
          editing={true}
          disabled={readonly}
        />
      )}
      {can("accounting:settings") && (
        <Formblock
          label="Default cost type"
          labelInfo="The default cost type for this activity. Selecting an activity will automatically also set this cost type on expenses."
          type="select"
          className="tabbed-modal"
          defaultValue={selectedActivity?.default_cost_type_id}
          options={costTypeOptions}
          name="default_cost_type_id"
          editing={true}
          errors={errors}
          control={control}
          isClearable={true}
          onChange={(option) => handleFieldsChange({ default_cost_type_id: option?.value || null })}
          disabled={readonly}
        />
      )}
      {can("accounting:settings") && (
        <Formblock
          label="GL mapping"
          type="select"
          className="tabbed-modal"
          defaultValue={selectedActivity?.ledger_mapping_id}
          options={ledgerMappingOptions}
          name="ledger_mapping_id"
          editing={true}
          errors={errors}
          control={control}
          isClearable={true}
          onChange={(option) => handleFieldsChange({ ledger_mapping_id: option?.value || null })}
          disabled={readonly}
        />
      )}
      {hasAccessToAccessFilters && (
        <>
          <h4 style={{ marginBottom: -2 }}>Team member access filters</h4>
          <Formblock
            text="Override global access filters by specifying who can access this activity"
            type="checkbox"
            control={control}
            defaultValue={selectedActivity?.enable_custom_access_filter}
            name="enable_custom_access_filter"
            className="modal"
            errors={errors}
            editing={true}
            onChange={(e) => {
              handleFieldsChange({ enable_custom_access_filter: e.target.checked });
              setShowCustomAccessFilter(e.target.checked);
              if (!e.target.checked) {
                handleFieldsChange({
                  custom_access_filter: {
                    included_groups: undefined,
                  },
                });
              }
            }}
          />
          {showCustomAccessFilter && (
            <>
              <>
                <Formblock
                  type="multiselect"
                  name="included_groups"
                  label={"Accessible to team members"}
                  control={control}
                  editing={true}
                  className="modal"
                  placeholder={"Select team members"}
                  value={selectedIncludedTMGroupsOption}
                  options={includedTeamMemberGroupOptions}
                  height="unset"
                  onChange={(selectedOptions) => {
                    handleFieldsChange({
                      enable_custom_access_filter: true,
                      custom_access_filter: {
                        ...selectedActivity.custom_access_filter,
                        included_groups: selectedOptions?.map((option) => option.value),
                      },
                    });
                  }}
                />
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <ClickableText
                    text="View team members with access"
                    onClick={() => {
                      setAccessFilterViewType("included");
                      setOpenTeamMembersModal(true);
                    }}
                  />
                </div>
              </>
              <>
                <Formblock
                  type="multiselect"
                  name="excluded_groups"
                  label={"Inaccessible to team members"}
                  control={control}
                  editing={true}
                  className="modal"
                  placeholder={"Select team members"}
                  value={selectedExcludedTMGroupsOption}
                  options={excludedTeamMemberGroupOptions}
                  height="unset"
                  requiredSelect={true}
                  onChange={(selectedOptions) => {
                    handleFieldsChange({
                      enable_custom_access_filter: true,
                      custom_access_filter: {
                        ...selectedActivity.custom_access_filter,
                        excluded_groups: selectedOptions?.map((option) => option.value),
                      },
                    });
                  }}
                />
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <ClickableText
                    text="View team members without access"
                    onClick={() => {
                      setAccessFilterViewType("excluded");
                      setOpenTeamMembersModal(true);
                    }}
                  />
                </div>
                <div className="vertical-spacer-small"></div>
                {openTeamMembersModal && (
                  <ActivityTeamMembersModal
                    selectedActivity={selectedActivity}
                    setOpenTeamMembersModal={setOpenTeamMembersModal}
                    accessType={accessFilterViewType}
                  />
                )}
              </>
            </>
          )}
        </>
      )}
    </>
  );
};
