import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { useState } from "react";
import { Notifier } from "ui";

type GetSetupLookHook = {
  setupLink: string | undefined;
  getSetupLink: () => Promise<void>;
  goToSetupLink: () => void;
  loadingSetupLink: boolean;
};

export const useGetSetupLink = (inputs: {
  integration: MiterIntegrationForCompany;
  activeCompanyId: string | null;
}): GetSetupLookHook => {
  const { integration, activeCompanyId } = inputs;

  const [setupLink, setSetupLink] = useState<string | undefined>();
  const [loadingSetupLink, setLoadingSetupLink] = useState(false);

  const getSetupLink = async () => {
    if (!integration.connection?._id) {
      Notifier.error(`There was a problem getting the setup link for the integration.`);
      return;
    }
    setLoadingSetupLink(true);

    try {
      const response = await MiterAPI.integrations.get_setup_link({
        key: integration.key,
        company_id: activeCompanyId || undefined,
        integrationConnectionId: integration.connection._id,
      });
      if (response.error) throw new Error(response.error);
      setSetupLink(response.link);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was a problem getting the setup link for the integration.`);
    }
    setLoadingSetupLink(false);
  };

  const goToSetupLink = () => {
    if (!setupLink) return;
    window.open(setupLink, "_blank");
  };

  return {
    goToSetupLink,
    getSetupLink,
    setupLink,
    loadingSetupLink,
  };
};
