import styles from "../TeamMemberChecklistBuilder.module.css";
import { useCallback } from "react";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { PaymentMethodOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";
import { Formblock } from "ui";

export const PaymentMethodTaskContent: React.FC<ChecklistTaskContentProps<PaymentMethodOnboardingTaskForm>> =
  ({ task, setTask, teamMember }) => {
    const handleMethodChange = useCallback(
      (method: string, value: boolean) => {
        if (!setTask) return;

        setTask({
          ...task,
          data: {
            ...task.data,
            methods: {
              ...DEFAULT_PAYMENT_METHODS,
              ...task.data?.methods,
              [method]: value,
            },
          },
        });
      },
      [setTask, task]
    );

    const workerType = teamMember?.employment_type === "employee" ? "employee" : "contractor";

    return (
      <div className={styles["team-member-checklist-task-content"]}>
        {`Require this ${workerType} to select how they would like to be paid, of the following options:`}

        <Formblock
          name={"payment_method-direct_deposit"}
          type="checkbox"
          text={"Direct deposit"}
          defaultValue={task.data?.methods?.direct_deposit}
          editing={true}
          className="modal"
          style={{ marginBottom: 0, marginTop: 10 }}
          onChange={(e) => handleMethodChange("direct_deposit", e.target.checked)}
        />
        <Formblock
          name={"payment_method-manual"}
          type="checkbox"
          text={"Paper check"}
          defaultValue={task.data?.methods?.manual}
          editing={true}
          className="modal"
          style={{ marginBottom: 0, marginTop: 0 }}
          onChange={(e) => handleMethodChange("manual", e.target.checked)}
        />
        <DueDateForm task={task} setTask={setTask} />
      </div>
    );
  };

const DEFAULT_PAYMENT_METHODS = {
  direct_deposit: false,
  manual: false,
};
