import React from "react";
import { Button, Formblock, Label, SettingsCard } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { JonasConnectionMetadata } from "backend/services/jonas/jonas-types";
import { X } from "phosphor-react";
import { useDebouncedCallback } from "use-debounce";
import { buildAtomicMongoUpdateFromNested, convertUndefinedToMongoUnset } from "dashboard/utils";
import { DeepPartial } from "utility-types";
import { useJobOptions } from "dashboard/hooks/atom-hooks";

export const JonasIntegrationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const [formData, setFormData] = React.useState<JonasConnectionMetadata>(
    integration.connection?.metadata?.jonas || ({} as JonasConnectionMetadata)
  );

  const [jobIdsToAddToGL, setJobIdsToAddToGL] = React.useState(
    integration.connection?.metadata?.jonas?.jonasCsvExportSettings?.nonJobCostedJobs || []
  );
  const jobOptions = useJobOptions();

  const handleChange = useDebouncedCallback(async (metadataUpdate: DeepPartial<JonasConnectionMetadata>) => {
    const raw = { metadata: { jonas: metadataUpdate } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    const finalUpdate = convertUndefinedToMongoUnset(flattened);

    await updateIntegrationConnection(finalUpdate);
  }, 500);

  const handleChangeJobIdsToAddToGL = useDebouncedCallback((ids: string[] | null) => {
    setJobIdsToAddToGL(ids || []);

    updateIntegrationConnection({
      metadata: {
        ...integration.connection?.metadata,
        jonas: {
          ...integration.connection?.metadata?.jonas,
          jonasCsvExportSettings: {
            ...integration.connection?.metadata?.jonas?.jonasCsvExportSettings,
            nonJobCostedJobs: ids,
          },
        },
      },
    });
  }, 500);

  const cleanSubLedgerCodesArr = (arr: (string | null | undefined)[]) => {
    const c = arr.filter((a): a is string => !!a);
    if (!c.length) return;
    return c;
  };

  /** Handles the addition of a new subledger code */
  const handleAddSubLedgerCode = () => {
    const currentSubLedgerCodes = formData?.payrollSubLedgerCodes || [];
    const newSubLedgerCodes = [...currentSubLedgerCodes].concat("");

    // handleChange({ payrollSubLedgerCodes: newSubLedgerCodes });
    setFormData({ ...formData, payrollSubLedgerCodes: newSubLedgerCodes });
  };

  /** Handles the change of a subledger code */
  const handleChangeSubLedgerCode = (value: string | boolean | null, index: number) => {
    const currentSubLedgerCodes = formData.payrollSubLedgerCodes || [];
    const newSubLedgerCodes = [...currentSubLedgerCodes];

    newSubLedgerCodes[index] = value as string;
    const finalVal = cleanSubLedgerCodesArr(newSubLedgerCodes);
    handleChange({ payrollSubLedgerCodes: finalVal });
    setFormData({ ...formData, payrollSubLedgerCodes: finalVal });
  };

  /** Handles the deletion of a subledger code */
  const handleFieldOptionsDelete = (subLedgerCodeIndex: number) => {
    const currentSubLedgerCodes = formData.payrollSubLedgerCodes || [];
    const newSubLedgerCodes = [...currentSubLedgerCodes];

    newSubLedgerCodes.splice(subLedgerCodeIndex, 1);
    const finalVal = cleanSubLedgerCodesArr(newSubLedgerCodes);
    handleChange({ payrollSubLedgerCodes: finalVal });
    setFormData({ ...formData, payrollSubLedgerCodes: finalVal });
  };

  /** Handles the change of payroll/expense occupation code */
  const handleChangeOccupationCode = (
    value: string | boolean | null,
    field: "payrollOccupationCode" | "expenseOccupationCode"
  ) => {
    handleChange({ [field]: value || undefined });
    setFormData({ ...formData, [field]: value });
  };

  /** Handles change to pullCostCodesWithoutScopes */
  const handleChangePullCostCodesWithoutScopes = (value: boolean | null | undefined) => {
    handleChange({ pullCostCodesWithoutScopes: value });
    setFormData({ ...formData, pullCostCodesWithoutScopes: value });
  };

  /** Handles change to pullAllCostCodesAsCompanyActivities */
  const handleChangePullAllCostCodesAsCompanyActivities = (value: boolean | null | undefined) => {
    handleChange({ pullAllCostCodesAsCompanyActivities: value });
    setFormData({ ...formData, pullAllCostCodesAsCompanyActivities: value });
  };

  /** Handles change to pullTimesheetsAsApproved */
  const handleChangePullTimesheetsAsApproved = (value: boolean | null | undefined) => {
    handleChange({ pullTimesheetsAsApproved: value });
    setFormData({ ...formData, pullTimesheetsAsApproved: value });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", maxWidth: 700 }}>
      <SettingsCard
        title="Activities sync"
        info="Configure how cost codes are pulled in as activities from Premier to Miter"
      >
        <Formblock
          type="checkbox"
          name={`pullCostCodesWithoutScopes`}
          text="Pull cost codes from Premier without making them selectable on timesheets or expenses, by default (i.e no default scopes)"
          checked={!!formData.pullCostCodesWithoutScopes}
          editing={true}
          onChange={(e) => handleChangePullCostCodesWithoutScopes(e.target.checked)}
          checkboxWrapperStyle={{ alignItems: "flex-start" }}
        />
        <Formblock
          type="checkbox"
          name={`pullAllCostCodesAsCompanyActivities`}
          text="Pull all cost codes from Premier at the company level, so each can be added to any job, even if it only appear on a single job. If left unchecked, only cost codes that appear on two or more jobs will be added to the company level"
          checked={!!formData.pullAllCostCodesAsCompanyActivities}
          editing={true}
          onChange={(e) => handleChangePullAllCostCodesAsCompanyActivities(e.target.checked)}
          checkboxWrapperStyle={{ alignItems: "flex-start" }}
        />
      </SettingsCard>
      <SettingsCard title="Timesheets sync">
        <Formblock
          type="checkbox"
          label="Approval status"
          name={`pullTimesheetsAsApproved`}
          text="When importing timesheets from Premier, automatically mark them as 'approved'"
          checked={!!formData.pullTimesheetsAsApproved}
          editing={true}
          onChange={(e) => handleChangePullTimesheetsAsApproved(e.target.checked)}
          // checkboxWrapperStyle={{ alignItems: "flex-start" }}
          className="modal"
        />
        {/* <div className="vertical-spacer-medium" /> */}
        <div>
          <Label
            label="Payroll Subledger Codes"
            labelInfo="You will need to add these codes to sync Jonas time entries into Miter"
            style={{ marginBottom: 15, fontSize: 15 }}
          />
          {formData.payrollSubLedgerCodes?.map((code, index) => {
            return (
              <div className="flex align-items-center" key={index}>
                <Formblock
                  className={"modal width-100-percent"}
                  placeholder={`Option ${index + 1}`}
                  type="text"
                  name={`payrollSubLedgerCodes.${index}`}
                  value={code}
                  editing={true}
                  onChange={(e) => handleChangeSubLedgerCode(e.target.value, index)}
                />

                <div>
                  <Button className="button-text" onClick={() => handleFieldOptionsDelete(index)}>
                    <X style={{ marginLeft: 10, marginTop: -10 }} />
                  </Button>
                </div>
              </div>
            );
          })}
          {!formData.payrollSubLedgerCodes?.length && (
            <div style={{ marginBottom: 25, opacity: 0.7, fontSize: 15 }}>No options selected</div>
          )}

          <Button className="button-1 no-margin" text="Add option" onClick={() => handleAddSubLedgerCode()} />
        </div>
      </SettingsCard>
      <SettingsCard title="GL entries sync">
        <Formblock
          label="Payroll Occupation Code"
          labelInfo="This will be the value shown under the 'Occupation' column of any payroll journal entries in the Jonas journal entry export"
          className={"modal width-100-percent"}
          placeholder={"Enter payroll occupation code"}
          type="text"
          name={`payrollOccupationCode`}
          defaultValue={formData.payrollOccupationCode}
          editing={true}
          onChange={(e) => handleChangeOccupationCode(e.target.value, "payrollOccupationCode")}
        />
        <Formblock
          label="Expense Occupation Code"
          labelInfo="This will be the value shown under the 'Occupation' column of any expense journal entries in the Jonas journal entry export"
          className={"modal width-100-percent"}
          placeholder={"Enter expense occupation code"}
          type="text"
          name={`expenseOccupationCode`}
          defaultValue={formData.expenseOccupationCode}
          editing={true}
          onChange={(e) => handleChangeOccupationCode(e.target.value, "expenseOccupationCode")}
        />
      </SettingsCard>
      <SettingsCard
        title="Non-Job Costed Jobs in Jonas CSV Export"
        info="Select jobs that should be excluded from job costing in the Jonas CSV export"
      >
        <Formblock
          type="multiselect"
          name="nonJobCostedJobs"
          label={"Non-job costed jobs"}
          editing={true}
          className="modal wizard"
          placeholder={"Select jobs"}
          options={jobOptions}
          value={jobOptions.filter((option) => jobIdsToAddToGL.includes(option.value))}
          onChange={(selectedOptions) => {
            const selectedIds = selectedOptions ? selectedOptions.map((option) => option.value) : [];
            handleChangeJobIdsToAddToGL(selectedIds);
          }}
          height="unset"
          isClearable
        />
      </SettingsCard>
    </div>
  );
};
