import AdvancedBreakTimeForm, {
  validateAdvancedBreaks,
} from "dashboard/components/break-time/AdvancedBreakTimeForm";
import SimpleBreakTimeForm from "dashboard/components/break-time/SimpleBreakTimeForm";
import { AggregatedTimesheet, Company } from "dashboard/miter";
import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Notifier } from "ui";
import TimesheetContext from "./timesheetContext";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
const TimesheetBreakTime: React.FC = () => {
  const activeCompany = useActiveCompany();
  const { timesheet, updateTimesheet, inboxMode } = useContext(TimesheetContext);
  const timesheetAbilities = useTimesheetAbilities({ inboxMode });

  const [editing, setEditing] = useState(
    timesheet?.breaks?.length || timesheet?.unpaid_break_time ? false : true
  );
  const [saving, setSaving] = useState(false);
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};
  const showAdvancedBreakTime = shouldShowAdvancedBreakTime(timesheet, activeCompany!);

  const form = useForm({
    defaultValues: {
      breaks: timesheet?.breaks,
      unpaid_break_time: timesheet?.unpaid_break_time,
    },
  });
  const formData = form.watch();

  if (!timesheet) return null;

  const handleSave = async () => {
    if (timesheetAbilities.cannot("update", timesheet)) {
      Notifier.error("You do not have permission to update this timesheet");
      return;
    }

    setSaving(true);

    try {
      const payload = showAdvancedBreakTime
        ? { breaks: formData.breaks }
        : { unpaid_break_time: formData.unpaid_break_time };

      if (showAdvancedBreakTime && payload.breaks?.length && timesheet?.clock_in && timesheet?.clock_out) {
        validateAdvancedBreaks(
          payload.breaks,
          DateTime.fromSeconds(timesheet?.clock_in),
          DateTime.fromSeconds(timesheet?.clock_out),
          breakTypesObject
        );
      }

      const updated = await updateTimesheet(payload);
      if (updated) setEditing(false);
    } catch (error: $TSFixMe) {
      Notifier.error(error.message);
    }

    setSaving(false);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const renderActions = () => {
    if (editing) {
      return (
        <>
          <Button className="button-2 " onClick={handleSave} loading={saving}>
            Save
          </Button>
          <Button className="button-1 no-margin" onClick={handleCancel}>
            Cancel
          </Button>
        </>
      );
    } else if (timesheetAbilities.can("update", timesheet) && timesheet?.status === "unapproved") {
      return (
        <Button className="button-1 no-margin" onClick={() => setEditing(true)}>
          Edit
        </Button>
      );
    }
  };

  return (
    <>
      <div className="payment-active-view-header" style={{ marginBottom: 20 }}>
        <div className="">Break time</div>
        <div className="flex-1"></div>
        {renderActions()}
      </div>
      {showAdvancedBreakTime && (
        <Controller
          name={"breaks"}
          control={form.control}
          render={({ onChange }) => (
            <AdvancedBreakTimeForm
              editing={editing}
              onChange={onChange}
              defaultBreaks={timesheet?.breaks}
              mode="update"
              clockOut={DateTime.fromSeconds(timesheet.clock_out)}
              clockIn={DateTime.fromSeconds(timesheet.clock_in)}
              clearNewBreaks={!editing}
            />
          )}
        />
      )}
      {!showAdvancedBreakTime && (
        <Controller
          name={"unpaid_break_time"}
          control={form.control}
          render={({ onChange }) => (
            <SimpleBreakTimeForm
              editing={editing}
              onChange={onChange}
              defaultBreakTime={timesheet?.unpaid_break_time}
              mode="update"
              maxDuration={(timesheet?.clock_out || 0) - (timesheet?.clock_in || 0)}
            />
          )}
        />
      )}
    </>
  );
};

export default TimesheetBreakTime;

const shouldShowAdvancedBreakTime = (
  timesheet: AggregatedTimesheet | undefined,
  company: Company | undefined
) => {
  const companyHasAdvancedBreak = !!company?.settings.timesheets.advanced_break_time;
  const timesheetHasAdvancedBreak =
    timesheet?.breaks?.length || (!timesheet?.breaks?.length && !timesheet?.unpaid_break_time);

  return companyHasAdvancedBreak && timesheetHasAdvancedBreak;
};
