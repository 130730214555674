import { MiterAPI, MiterError, Trade } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { Notifier } from "dashboard/utils";
import { useLookupPrg, usePrgOptions } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { usePrgReferencedByJobTrade } from "dashboard/hooks/usePrgReferencedByJobTrade";

type Props = {
  hide: () => void;
  readonly: boolean;
  onSubmit: () => {};
  trade?: (Trade & { prgs: string[] }) | null;
};

type TradeModalForm = {
  name: string;
  prgs: Option<string>[];
};

export const TradeModal: React.FC<Props> = ({ hide, readonly, trade, onSubmit }) => {
  const [loading, setLoading] = useState(false);

  const prgOptions = usePrgOptions();
  const lookupPrg = useLookupPrg();

  const prgsReferencedByJobTrade = usePrgReferencedByJobTrade();

  const prgAvailableOptions = useMemo(() => {
    return prgOptions.filter((option) => {
      const prgTradeId = lookupPrg(option.value)?.trade_id;
      return !prgTradeId || prgTradeId === trade?._id;
    });
  }, [prgOptions, trade, lookupPrg]);

  const buildDefaultValues = () => {
    if (trade) {
      return {
        name: trade.name,
        prgs: prgAvailableOptions.filter((option) => trade?.prgs?.includes(option.value)),
      };
    }
  };

  const { register, errors, control, handleSubmit } = useForm({
    defaultValues: buildDefaultValues(),
  });

  const saveTrade = async (data: TradeModalForm) => {
    setLoading(true);
    try {
      let response: Trade & MiterError;
      const requestBody: Partial<Trade> = {
        name: data.name,
      };

      // update/create the trade
      if (trade) {
        response = await MiterAPI.trades.update(trade._id, requestBody);
      } else {
        response = await MiterAPI.trades.create(requestBody);
      }

      if (response.error) throw new Error(response.error);

      // update/create prgs
      const selectedPrgs = data.prgs?.map((option) => option.value) || [];
      let prgsToAddTradeTo: string[] = selectedPrgs;
      let prgsToRemoveTradeFrom: string[] = [];

      if (trade) {
        // prgs where we need to add the trade
        prgsToAddTradeTo = selectedPrgs.filter((prg) => !trade.prgs.includes(prg));
        // prgs where we need to remove the trade
        prgsToRemoveTradeFrom = trade.prgs.filter((prg) => !selectedPrgs.includes(prg));

        prgsToRemoveTradeFrom.map((prg) => {
          if (prgsReferencedByJobTrade.has(prg)) {
            throw new Error(
              "Cannot remove " +
                (lookupPrg(prg)?.label || "pay rate group") +
                " because it is already referenced in a job with trade " +
                trade.name
            );
          }
        });

        const prgResponseRemove = await MiterAPI.pay_rate_groups.update(prgsToRemoveTradeFrom, {
          trade_id: null,
        });
        if (prgResponseRemove?.error || prgResponseRemove?.failed.length) {
          throw new Error(response.error);
        }
      }

      const prgResponseAdd = await MiterAPI.pay_rate_groups.update(prgsToAddTradeTo, {
        trade_id: response._id,
      });

      if (prgResponseAdd.error || prgResponseAdd.failed.length) {
        throw new Error(response.error);
      }

      hide();
      onSubmit();
    } catch (e: $TSFixMe) {
      Notifier.error("There was a problem saving: " + e.message);
    }
    setLoading(false);
  };
  return (
    <div>
      <ActionModal
        onHide={hide}
        onCancel={hide}
        onSubmit={handleSubmit(saveTrade)}
        submitText={trade ? "Update" : "Create"}
        headerText={trade ? "Update trade" : "Create trade"}
        loading={loading}
        showCancel={true}
        showSubmit={true}
        wrapperStyle={{ width: 500 }}
        bodyStyle={{ maxHeight: 600 }}
      >
        <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
          <Formblock
            label="Trade name"
            type="text"
            control={control}
            register={register()}
            name="name"
            className={"modal "}
            errors={errors}
            editing={true}
          />

          <Formblock
            label="Pay rate groups"
            type="multiselect"
            control={control}
            name="prgs"
            options={prgAvailableOptions}
            register={register()}
            className={"modal "}
            errors={errors}
            editing={true}
            disabled={readonly}
            height="fit-content"
          />
        </div>
      </ActionModal>
    </div>
  );
};
