import React from "react";
import { Formblock } from "ui";
import { DeepPartial } from "utility-types";
import { UseFormMethods, FieldValues } from "react-hook-form";
import {
  AcumaticaConfigObject,
  AcumaticaConnectionMetadata,
  AcumaticaDataForConfig,
} from "backend/services/acumatica/acumatica-types";
import { DEFAULT_ACUMATICA_LABEL_STYLE, ACUMATICA_INPUT_LENGTH } from "./AcumaticaConfig";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => Promise<void>;
  dataForConfig?: AcumaticaDataForConfig;
  configObject?: AcumaticaConfigObject;
  loading?: boolean;
  form: UseFormMethods<FieldValues>;
};

export const AcumaticaFallbackSelections: React.FC<Props> = ({
  updateAcumaticaMetadata,
  configObject,
  form,
}) => {
  const updateDefaultSelection = async (subObj: AcumaticaConfigObject["fallbackSelections"]) => {
    await updateAcumaticaMetadata({ configObject: { fallbackSelections: subObj } }, { collapseCount: 2 });
  };

  const debouncedHandler = useDebouncedCallback(
    (
      property: keyof NonNullable<AcumaticaConfigObject["fallbackSelections"]>,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      updateDefaultSelection({ [property]: e.target.value || null });
    },
    400
  );

  const { accountGroupId, branchId, costCodeId, laborItemId, expenseItemId } =
    configObject?.fallbackSelections || {};

  return (
    <>
      <Formblock
        form={form}
        name="expenseItemId"
        type="text"
        defaultValue={expenseItemId}
        onChange={(o) => debouncedHandler("expenseItemId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default Expense Item"
        labelInfo="The Expense Item Miter will use when pushing expense claims."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="laborItemId"
        type="text"
        defaultValue={laborItemId}
        onChange={(o) => debouncedHandler("laborItemId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default Labor Item"
        labelInfo="The Labor Item Miter will use when pushing Journal and Project Transactions for payrolls."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="costCodeId"
        type="text"
        defaultValue={costCodeId}
        onChange={(o) => debouncedHandler("costCodeId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default Cost Code"
        labelInfo="If a Cost Code is missing for a Project Transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="branchId"
        type="text"
        defaultValue={branchId}
        onChange={(o) => debouncedHandler("branchId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default Branch"
        labelInfo="If a Branch is missing for a Project or Journal Transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
      <Formblock
        form={form}
        name="accountGroupId"
        type="text"
        defaultValue={accountGroupId}
        onChange={(o) => debouncedHandler("accountGroupId", o)}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Default Account Group"
        labelInfo="If the Account Group (cost type) is missing for a Project or Journal Transaction line item, Miter will use this one."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
      />
    </>
  );
};
