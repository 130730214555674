import ObjectID from "bson-objectid";
import { FormComponent } from "dashboard/components/forms/FormBuilderScreen";
/*
 * This function duplicates the form components but with different ObjectIDs
 */
export const duplicateFormComponents = (formComponents: FormComponent[]): FormComponent[] => {
  return formComponents.map((component) => ({
    ...component,
    _id: ObjectID().toHexString(),
  }));
};
