import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { CompanySettings } from "backend/models/company";
import { Input } from "ui";
import InfoButton from "dashboard/components/information/information";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

export const SchedulingSettings: React.FC = () => {
  const navigate = useNavigate();

  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const { fetchUserData } = useContext(AppContext);
  const [settings, setSettings] = useState(activeCompany?.settings.scheduling);

  const updateCompany = async () => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { "settings.scheduling": settings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating timesheet settings. Our engineers are looking into it!");
    }
  };

  const updateCompanyDebounced = useDebouncedCallback(() => {
    updateCompany();
  }, 1000);

  const handleSettingsChange = (newFields: Partial<CompanySettings["scheduling"]>) => {
    setSettings((prev) => {
      return { ...prev, ...newFields };
    });
    updateCompanyDebounced();
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Scheduling settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/scheduling")}>
          Back to scheduling
        </div>
        <div className="flex">
          <h1>Scheduling settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure scheduling settings.</div>
        <div className="billing-card-wrapper">
          <div className="flex">
            <div style={{ fontWeight: 600, fontSize: 18 }}>Timezones</div>
          </div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="scheduling.enable_job_based_timezones"
              checked={settings?.enable_job_based_timezones}
              onChange={(e) => handleSettingsChange({ enable_job_based_timezones: e.target.checked })}
            />
            <span style={{ marginLeft: 6 }}>{`Use the job's timezone when scheduling an assigment`}</span>
          </div>
        </div>
        <div className="billing-card-wrapper">
          <div className="flex">
            <div style={{ fontWeight: 600, fontSize: 18 }}>Defaults</div>
            <InfoButton text="Control the default clock-in time within the dashboard (not the mobile app)" />
          </div>

          <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
            <div className="vertical-spacer-small"></div>

            <div className="flex">
              <span>Start time:&nbsp;&nbsp;</span>
              <div style={{ width: 120 }}>
                <Input
                  type="time"
                  defaultValue={settings?.default_start_time || "09:00"}
                  onChange={(e) => handleSettingsChange({ default_start_time: e.target.value })}
                />
              </div>
            </div>
            <div className="vertical-spacer-small"></div>

            <div className="flex">
              <span>Duration:&nbsp;&nbsp;</span>
              <div style={{ width: 120 }}>
                <Input
                  type="number"
                  defaultValue={settings?.default_duration || 60}
                  onChange={(e) => handleSettingsChange({ default_duration: e.target.value })}
                />
              </div>
              &nbsp;minutes
            </div>

            <div className="flex" style={{ marginTop: 25, marginLeft: -5 }}>
              <input
                type="checkbox"
                name="scheduling.default_title_is_job_name"
                checked={settings?.default_title_is_job_name}
                onChange={(e) => handleSettingsChange({ default_title_is_job_name: e.target.checked })}
              />
              <span
                style={{ marginLeft: 6 }}
              >{`Use the assignment job's name as the default assignment title`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
