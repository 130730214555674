import React from "react";
import styles from "./OvertimeRuleModal.module.css";
import { Button, Input, Label } from "ui";
import { useForm } from "react-hook-form";
import { IoIosClose } from "react-icons/io";
import { Option } from "ui/form/Input";
import { OvertimeRuleItem, OvertimeThreshold, OvertimeThresholdType } from "backend/models/overtime-rule";
import { OvertimeRule } from "dashboard/miter";
import x from "dashboard/assets/x.png";

type Props = {
  key: number;
  itemIndex: number;
  overtimeRule: OvertimeRule;
  setOvertimeRule: React.Dispatch<React.SetStateAction<OvertimeRule>>;
};

const daysOfWeekOptions: Option<number>[] = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 },
  { label: "7th consecutive day of work", value: 8 },
];

export const OvertimeRuleItemForm: React.FC<Props> = ({ overtimeRule, setOvertimeRule, itemIndex }) => {
  const form = useForm();
  form.watch();
  const { items } = overtimeRule;
  const item = overtimeRule.items[itemIndex]!;

  // Determine which days are already represented in other items
  const otherItems = items.filter((item, fullItemListItemIndex) => fullItemListItemIndex !== itemIndex);
  const seventhConsecutiveAlreadySelected = otherItems.some((item) => item.seventh_consecutive);
  const alreadySelectedDays = otherItems.flatMap((item) => item.days_of_week);
  if (seventhConsecutiveAlreadySelected) alreadySelectedDays.push(8);

  const selectedDays = daysOfWeekOptions.filter(
    (option) => item.days_of_week.includes(option.value) || (option.value === 8 && item.seventh_consecutive)
  );

  const nonSelectedDays = daysOfWeekOptions.filter((option) => !alreadySelectedDays.includes(option.value));

  const handleDelete = () => {
    const newItems = items.slice();
    newItems.splice(itemIndex, 1);
    setOvertimeRule((prev) => ({ ...prev, items: newItems }));
  };

  const handleDaysChange = (selectedOptions) => {
    const newItem = {
      ...item,
      days_of_week:
        selectedOptions?.filter((option) => option.value !== 8).map((option) => option.value) || [],
      seventh_consecutive: selectedOptions?.some((option) => option.value === 8) || false,
    };
    const itemsCopy = items.slice();
    itemsCopy.splice(itemIndex, 1, newItem);
    setOvertimeRule((prev) => ({ ...prev, items: itemsCopy }));
  };

  const handleThresholdsChange = (thresholds: OvertimeThreshold[]) => {
    const newItem = { ...item, thresholds };
    const itemsCopy = items.slice();
    itemsCopy.splice(itemIndex, 1, newItem);
    setOvertimeRule((prev) => ({ ...prev, items: itemsCopy }));
  };

  return (
    <div className={styles["item-form-wrapper"]}>
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Rule item</div>
        <div className="flex-1"></div>
        <button className={styles["close-button"]} onClick={handleDelete}>
          <IoIosClose />
        </button>
      </div>
      <div className="vertical-spacer"></div>
      <div className="flex">
        <div className={styles["rule-item-label"]}>
          <Label label="Days of week" labelInfo="The days for which this rule item applies." />
        </div>
        <Input
          type="multiselect"
          errors={form.errors}
          options={nonSelectedDays}
          value={selectedDays}
          onChange={handleDaysChange}
          name="days_of_week"
          control={form.control}
          height={"unset"}
        />
      </div>
      <div className="vertical-spacer-small"></div>
      <ThresholdsForm item={item} setNewThresholds={handleThresholdsChange} />
    </div>
  );
};

type ThresholdsFormProps = {
  item: OvertimeRuleItem;
  setNewThresholds: (thresholds: OvertimeThreshold[]) => void;
};

const ThresholdsForm: React.FC<ThresholdsFormProps> = ({ item, setNewThresholds }) => {
  const thresholds: OvertimeThreshold[] = (item.thresholds || []).slice();

  const handleThresholdChange = (index: number, threshold: OvertimeThreshold) => {
    const newThresholds = item.thresholds.slice();
    newThresholds.splice(index, 1, threshold);
    setNewThresholds(newThresholds);
  };

  const handleAddThreshold = () => {
    const newThresholds = item.thresholds.slice();
    newThresholds.push({ time_type: "ot", threshold_type: "total_hours_worked", value: "8" });
    setNewThresholds(newThresholds);
  };

  const handleDeleteThreshold = (index) => {
    const newThresholds = item.thresholds.slice();
    newThresholds.splice(index, 1);
    setNewThresholds(newThresholds);
  };

  return (
    <div style={{ display: "flex", marginTop: 7 }}>
      <div className={styles["rule-item-label"]}>
        <Label label="Thresholds" labelInfo={`When REG, OT, DOT "kick in" throughout the day.`} />
      </div>

      <div className="width-100">
        <div className="flex" style={{ fontWeight: 500, color: "slate" }}>
          <div className={`${styles["time-type-wrapper"]}  ${styles["threshold-column"]}`}>REG/OT/DOT</div>
          <div className={`${styles["threshold-type-column"]}  ${styles["threshold-column"]}`}>Kicks in</div>
          <div className={`${styles["value-column"]}  ${styles["threshold-column"]}`}>At/after</div>
          <div className={`${styles["x-column"]} ${styles["threshold-column"]}`}></div>
        </div>
        <>
          {thresholds.length ? (
            <>
              {thresholds.map((t, index) => {
                return (
                  <ThresholdForm
                    threshold={t}
                    key={index}
                    index={index}
                    handleChange={handleThresholdChange}
                    handleDelete={handleDeleteThreshold}
                  />
                );
              })}
            </>
          ) : (
            <div className="flex" style={{ fontWeight: 500, color: "slate" }}>
              <div className={`${styles["time-type-wrapper"]}  ${styles["threshold-column"]}`}>-</div>
              <div className={`${styles["threshold-type-column"]}  ${styles["threshold-column"]}`}>-</div>
              <div className={`${styles["value-column"]}  ${styles["threshold-column"]}`}>-</div>
              <div className={`${styles["x-column"]} ${styles["threshold-column"]}`}></div>
            </div>
          )}
        </>

        <div className="vertical-spacer-small"></div>
        <Button text="+ New threshold" className="button-1 no-margin" onClick={handleAddThreshold} />
      </div>
    </div>
  );
};

type ThresholdFormProps = {
  threshold: OvertimeThreshold;
  index: number;
  handleChange: (index: number, threshold: OvertimeThreshold) => void;
  handleDelete: (index: number) => void;
};

const ThresholdForm: React.FC<ThresholdFormProps> = ({ threshold, index, handleDelete, handleChange }) => {
  const timeTypeOptions = [
    { label: "REG", value: "reg" },
    { label: "OT", value: "ot" },
    { label: "DOT", value: "dot" },
  ];

  const thresholdTypeOptions = [
    { label: "After # total hours worked", value: "total_hours_worked" },
    { label: "After # REG hours worked", value: "reg_hours_worked" },
    { label: "After # OT hours worked", value: "ot_hours_worked" },
    { label: "After # DOT hours worked", value: "dot_hours_worked" },
    { label: "At a certain time of day", value: "time_of_day" },
  ];

  const form = useForm();

  const handleThresholdTypeChange = (newThresholdType: OvertimeThresholdType) => {
    // If the threshold type has not changed, do nothing.
    if (newThresholdType === threshold.threshold_type) return;

    // If the threshold type HAS changed, ensure the value is in the right format (hours vs time of day)
    let newThresholdValue: string;
    if (newThresholdType !== "time_of_day") {
      newThresholdValue = "8";
    } else {
      newThresholdValue = "17:00";
    }
    handleChange(index, { ...threshold, threshold_type: newThresholdType, value: newThresholdValue });
  };

  return (
    <div className="flex">
      <div className={`${styles["time-type-wrapper"]} ${styles["threshold-column"]}`}>
        <Input
          type="select"
          value={timeTypeOptions.find((option) => option.value === threshold.time_type)}
          options={timeTypeOptions}
          control={form.control}
          name="time_type"
          onChange={(option) => handleChange(index, { ...threshold, time_type: option.value })}
          height={"unset"}
        />
      </div>
      <div className={`${styles["threshold-type-column"]} ${styles["threshold-column"]}`}>
        <Input
          type="select"
          value={thresholdTypeOptions.find((option) => option.value === threshold.threshold_type)}
          options={thresholdTypeOptions}
          control={form.control}
          name="threshold_type"
          onChange={(option) => handleThresholdTypeChange(option.value)}
          height={"unset"}
        />
      </div>
      <div className={`${styles["value-column"]} ${styles["threshold-column"]}`}>
        <div className="flex" style={{ position: "relative" }}>
          <Input
            type={threshold.threshold_type !== "time_of_day" ? "text" : "time"}
            onChange={(e) => handleChange(index, { ...threshold, value: e.target.value })}
            register={form.register}
            defaultValue={threshold.value}
            value={threshold.value}
            errors={form.errors}
            name={"value"}
          />
          {threshold.threshold_type !== "time_of_day" && (
            <span style={{ position: "absolute", right: 15, marginLeft: 15 }}>hours</span>
          )}
        </div>
      </div>
      <div className={`${styles["x-column"]} ${styles["threshold-column"]}`}>
        <img src={x} style={{ height: 12, cursor: "pointer" }} onClick={() => handleDelete(index)} />
      </div>
    </div>
  );
};
