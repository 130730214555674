import { TimesheetSettings } from "./TimesheetSettings";
import { TimesheetMobileFieldRequirements } from "dashboard/components/timesheets/TimesheetMobileFieldRequirements";
import { TimesheetDashboardFieldRequirements } from "dashboard/components/timesheets/TimesheetDashboardFieldRequirements";
import { SettingsCard } from "ui";

interface TimesheetFieldRequirementSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => Promise<void>;
}

const TimesheetFieldRequirementSettings: React.FC<TimesheetFieldRequirementSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  const hasPoliciesEnabled = timesheetSettings.enable_timesheet_policies;
  if (hasPoliciesEnabled) {
    return null;
  }
  return (
    <>
      <SettingsCard
        title="Mobile field requirements"
        info={"Set field requirements for timesheets submitted from the mobile app."}
      >
        <div style={{ marginTop: 10 }}>
          <TimesheetMobileFieldRequirements settings={timesheetSettings} handleChange={handleChange} />
        </div>
      </SettingsCard>
      <SettingsCard
        title={"Dashboard field requirements"}
        info={"Set field requirements for timesheets submitted from the dashboard."}
      >
        <div style={{ marginTop: 10 }}>
          <TimesheetDashboardFieldRequirements settings={timesheetSettings} handleChange={handleChange} />
        </div>
      </SettingsCard>
    </>
  );
};

export default TimesheetFieldRequirementSettings;
