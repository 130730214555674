import React, { useMemo } from "react";
import JobsTable from "dashboard/components/tables/JobsTable";
import { Helmet } from "react-helmet";
import { useSelectableSubJobsMap } from "dashboard/hooks/atom-hooks";
import { AggregatedJob } from "dashboard/miter";

type Props = {
  job: AggregatedJob;
};

const JobSubJobs: React.FC<Props> = ({ job }) => {
  const selectableSubJobsMap = useSelectableSubJobsMap();
  const subJobs = useMemo(() => (job ? selectableSubJobsMap?.get(job._id) : []), [job, selectableSubJobsMap]);

  return (
    <>
      <Helmet>
        <title>{job?.name || "Job"} Sub Jobs | Miter</title>
      </Helmet>
      <JobsTable filteredJobs={subJobs} subJobView={true} />
    </>
  );
};

export default JobSubJobs;
