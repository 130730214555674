import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import Cohere from "cohere-js";
import CohereSentry from "cohere-sentry";
import posthog from "posthog-js";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

import App from "./App";
import { IS_PRODUCTION } from "./utils/environment";
import { buildIgnoredPosthogErrors } from "./utils";

window.releaseName = process.env.REACT_APP_SENTRY_RELEASE_NAME;

type DatadogVariableConfig = Omit<RumInitConfiguration, "applicationId" | "clientToken">;

const getDatadogSettingsForEnvironment = (): DatadogVariableConfig => {
  let config: DatadogVariableConfig;
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    config = {
      sessionSampleRate: 50,
      sessionReplaySampleRate: 40,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    };
  } else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
    config = {
      sessionSampleRate: 50,
      sessionReplaySampleRate: 40,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    };
  } else {
    config = {
      sessionSampleRate: 0,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    };
  }

  if (process.env.REACT_APP_DD_RUM_SAMPLE_RATE && !isNaN(Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE))) {
    config.sessionSampleRate = Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE);
  }

  if (
    process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE &&
    !isNaN(Number(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE))
  ) {
    config.sessionReplaySampleRate = Number(process.env.REACT_APP_DD_SESSION_REPLAY_SAMPLE_RATE);
  }

  if (process.env.REACT_APP_DD_TRACK_USER_INTERACTIONS != undefined) {
    config.trackUserInteractions = process.env.REACT_APP_DD_TRACK_USER_INTERACTIONS === "true";
  }

  if (process.env.REACT_APP_DD_TRACK_RESOURCES != undefined) {
    config.trackResources = process.env.REACT_APP_DD_TRACK_RESOURCES === "true";
  }

  if (process.env.REACT_APP_DD_TRACK_LONG_TASKS != undefined) {
    config.trackLongTasks = process.env.REACT_APP_DD_TRACK_LONG_TASKS === "true";
  }

  if (process.env.REACT_APP_DD_DEFAULT_PRIVACY_LEVEL) {
    config.defaultPrivacyLevel = process.env.REACT_APP_DD_DEFAULT_PRIVACY_LEVEL;
  }

  return config;
};

let version: string | undefined;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  version = "latest";
} else if (process.env.REACT_APP_SENTRY_RELEASE_NAME?.startsWith("miter@v")) {
  version = process.env.REACT_APP_SENTRY_RELEASE_NAME.slice(6);
} else if (process.env.REACT_APP_SENTRY_RELEASE_NAME) {
  version = process.env.REACT_APP_SENTRY_RELEASE_NAME;
} else {
  version = "unknown";
}

const tracingUrls = [process.env.REACT_APP_MITER_BACKEND_API];
if (process.env.REACT_APP_MITER_BACKEND_REPORTS_API) {
  tracingUrls.push(process.env.REACT_APP_MITER_BACKEND_REPORTS_API);
}

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APP_ID ?? "",
  clientToken: process.env.REACT_APP_DATADOG_TOKEN ?? "",
  site: "us3.datadoghq.com",
  service: "miter-dashboard",
  env: process.env.REACT_APP_ENVIRONMENT,
  version,
  allowedTracingUrls: tracingUrls,
  ...getDatadogSettingsForEnvironment(),
});

if (process.env.REACT_APP_ENVIRONMENT !== "development") {
  const ignoredPosthogErrors = buildIgnoredPosthogErrors();

  Sentry.init({
    dsn: "https://3dbcf233de694a5f8374d9bf17bdc9a3@o1003175.ingest.sentry.io/5965217",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ["error", "warn"],
      }),
      new CohereSentry(),
      new Sentry.Replay(),
    ],
    ignoreErrors: [
      "Failed to fetch",
      "Load failed",
      "when attempting to fetch resource.",
      "request timeout",
      "this.get_config is not a function",
      "Your session has expired. Please log in again.",
      "No target window",
      "disconnected",
      "Cannot redefine property: adoptedStyleSheets",
      "Google Maps JavaScript API has been loaded directly without",
      "Enqueued failed request for retry in 6000",
      "ResizeObserver loop completed with undelivered notifications.",
      `AG Grid: \`getSelectedNodes\` and \`getSelectedRows\` functions cannot be used with \`groupSelectsChildren\` and the server-side row model`,
      `AG Grid: cannot select node until id for node is known`,
      `AG Grid: tried to call sizeColumnsToFit`,
      `AG Grid: setFilterModel`,
      `AG Grid: Column property headerCheckboxSelectionFilteredOnly is not supported with the row model type serverSide`,
      `AG Grid: api.forEachNodeAfterFilterAndSort can only be called when gridOptions.rowModelType is clientSide`,
      `Force restarting websocket`,
      /Unable to connect/,
      /AG Grid: React Component '.+?' not created within/i,
      /AG Grid: Grid API function .+? cannot be called as the grid has been destroyed./i,
      `AG Grid: to see all the valid columnDefs properties please check`,
      `AG Grid: invalid columnDefs property`,
      `AG Grid: Unable to use the Clipboard API`,
      `Failed to execute 'readText' on 'Clipboard': Read permission denied`,
      /__gCrWeb/,
      /Maximum attempt time limit/,
      ...ignoredPosthogErrors,
    ],
    // Set this so that sentry doesn't send us a 413 Error.
    maxBreadcrumbs: 50,
    release: process.env.REACT_APP_SENTRY_RELEASE_NAME || undefined,

    // Capture 25% of traces
    tracesSampleRate: 0.25,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

if (IS_PRODUCTION) {
  Cohere.init("DvJn8oXFOr6WgHlq5menl8Ol");
  posthog.init("phc_ajCwp4aSthM52mGSoj4fHVprVdBoLs76wABSh6STfSO", { api_host: "https://app.posthog.com" });
}

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
