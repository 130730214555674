import {
  AggregatedEquipmentTimesheet,
  AggregatedJob,
  AggregatedTeamMember,
  EquipmentTimesheet,
  Job,
} from "dashboard/miter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";

type EquipmentTimesheetParams =
  | EquipmentTimesheet
  | EquipmentTimesheet[]
  | AggregatedEquipmentTimesheet
  | AggregatedEquipmentTimesheet[];

export type EquipmentTimesheetAction = "create" | "read" | "update" | "delete" | "approve";

export type EquipmentTimesheetAbilities = {
  can: (action: EquipmentTimesheetAction, items: EquipmentTimesheetParams) => boolean;
  cannot: (action: EquipmentTimesheetAction, items: EquipmentTimesheetParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: EquipmentTimesheetAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: EquipmentTimesheetAction) => (job: AggregatedJob | Job) => boolean;
};

export const useEquipmentTimesheetAbilities = (): EquipmentTimesheetAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: EquipmentTimesheetAction, items: EquipmentTimesheetParams) => {
      if (!items) return false;
      const equipmentTimesheets = Array.isArray(items) ? items : [items];

      return equipmentTimesheets.every((equipmentTimesheet) => {
        const teamMemberId = equipmentTimesheet.team_member_id;
        const jobId = equipmentTimesheet.job_id;

        const opts = { teamMember: teamMemberId, job: jobId };

        switch (action) {
          case "create":
            return can_("equipment:timesheets:create", opts);
          case "read":
            return can_("equipment:timesheets:read", opts);
          case "update":
            return can_("equipment:timesheets:update", opts);
          case "delete":
            return can_("equipment:timesheets:delete", opts);
          case "approve":
            return can_("equipment:timesheets:approve", opts);
          default:
            return false;
        }
      });
    },
    [can_]
  );

  const cannot = useCallback(
    (action: EquipmentTimesheetAction, items: EquipmentTimesheetParams) => {
      return !can(action, items);
    },
    [can]
  );

  const filter = useAbilitiesBackendFilter({
    permissionPath: "equipment:timesheets",
    teamMemberField: { fieldName: "team_member_id", fieldType: "string" },
    appModule: "workforce_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<EquipmentTimesheetAction>("equipment");

  /** Job filter predicate */
  const jobPredicate = useAbilitiesJobPredicate<EquipmentTimesheetAction>("equipment");

  return useMemo(
    () => ({ can, cannot, filter, teamPredicate, jobPredicate }),
    [can, cannot, filter, teamPredicate, jobPredicate]
  );
};
