import { SettingsCard, Formblock } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

interface TimesheetWorkersCompCodeSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetWorkersCompCodeSettings: React.FC<TimesheetWorkersCompCodeSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  const abilities = useMiterAbilities();
  const canReadWcCode = abilities.can("lists:workers_comp_codes:read");
  if (!canReadWcCode) {
    return null;
  }
  return (
    <SettingsCard title="Workers' compensation code" info={"Manage timesheet workers' comp code settings"}>
      <Formblock
        type="checkbox"
        name=""
        text="Allow users with permissions to view workers' compensation codes to also add or update workers' comp codes on timesheets."
        onChange={(e) => handleChange({ enable_admin_wc_code: e.target.checked })}
        checked={timesheetSettings.enable_admin_wc_code}
        editing={true}
      />
    </SettingsCard>
  );
};

export default TimesheetWorkersCompCodeSettings;
