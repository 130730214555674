import React, { useMemo } from "react";
import { Badge, TableV2 } from "ui";
import "ui/modal/modal.css";
import { useState } from "react";
import Notifier from "dashboard/utils/notifier";
import { AggregatedRole, MiterAPI, PermissionGroup } from "dashboard/miter";
import DeleteRole from "./DeleteRole";
import { RoleModal } from "./RoleModal";
import { Helmet } from "react-helmet";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import {
  useCompanyRoles,
  usePermissionGroups,
  useRefetchCompanyRoles,
  useRefetchPermissionGroups,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { isMiterRep } from "miter-utils";

type PermissionGroupWithColor = PermissionGroup & { color: string };

const Roles: React.FC = () => {
  const user = useUser();
  const companyRoles = useCompanyRoles();
  const permissionGroups = usePermissionGroups();
  const refetchCompanyRoles = useRefetchCompanyRoles();
  const refetchPermissionGroups = useRefetchPermissionGroups();

  const [loading, setLoading] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<AggregatedRole[]>([]);

  const [addingRole, setAddingRole] = useState(false);
  const [deletingRole, setDeletingRole] = useState(false);
  const [editingRole, setEditingRole] = useState<AggregatedRole | undefined>();

  const permissionGroupsWithColors: PermissionGroupWithColor[] = useMemo(() => {
    return permissionGroups.map((pg, i) => {
      return { ...pg, color: colors[i % colors.length]! };
    });
  }, [permissionGroups]);

  const tableData = useMemo(() => {
    const isMiterUser = isMiterRep(user);
    return companyRoles.filter((r) => {
      if (process.env.REACT_APP_ENVIRONMENT === "development" || isMiterUser) return true;
      return !isMiterRep(r);
    });
  }, [user, companyRoles]);

  const deleteRoles = async () => {
    setLoading(true);
    try {
      const response = await MiterAPI.roles.update_many({
        ids: selectedRoles.map((r) => r._id),
        update: { archived: true, active: false },
      });
      if (response.error) {
        throw new Error(response.error);
      } else if (response.fails.length) {
        for (const { id, error } of response.fails) {
          const role = companyRoles?.find((r) => r._id.toString() === id);
          Notifier.error(`Error deleting role for ${role?.email}: ${error}`);
        }
      } else {
        Notifier.success("Role(s) successfully deleted.");
      }
      await refetchCompanyRoles();
      setSelectedRoles([]);
      setDeletingRole(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error deleting roles. We're looking into it!");
    }
    setLoading(false);
  };

  const handleModalHide = () => {
    refetchCompanyRoles();
    refetchPermissionGroups();
    setAddingRole(false);
    setEditingRole(undefined);
  };

  const columns = useMemo(() => {
    const cols: ColumnConfig<AggregatedRole>[] = [
      { field: "first_name", headerName: "First", width: 125, dataType: "string" },
      { field: "last_name", headerName: "Last", width: 125, dataType: "string" },
      {
        field: "email",
        headerName: "Email",
        dataType: "string",
      },
      {
        field: "permission_groups",
        headerName: "Permission groups",
        dataType: "string",
        cellRenderer: (params) => {
          const rolePermissionGroups = permissionGroupsWithColors.filter((pg) =>
            pg.members.some((m) => m.type === "role" && m.group.value === params.data?._id)
          );

          if (!rolePermissionGroups.length) return <Badge className="no-margin" text="None" color="grey" />;

          return (
            <div className="flex">
              {rolePermissionGroups.map((pg) => {
                return (
                  <Badge
                    className="no-margin"
                    text={pg.name}
                    color={pg.color}
                    style={{ marginRight: 5 }}
                    key={pg._id}
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        field: "created_at",
        headerName: "Date added",
        dataType: "date",
      },
    ];
    return cols;
  }, [permissionGroupsWithColors]);

  const staticActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Add role",
        className: "button-2 no-margin",
        action: () => setAddingRole(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
    return acts;
  }, []);

  const dynamicActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Delete",
        className: "button-3 no-margin",
        action: () => setDeletingRole(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
    return acts;
  }, []);

  return (
    <div className="section-wrapper">
      <Helmet>
        <title>External Roles | Miter</title>
      </Helmet>

      {deletingRole && (
        <DeleteRole
          selectedRows={selectedRoles.map((r) => r._id)}
          onHide={() => setDeletingRole(false)}
          onDelete={deleteRoles}
          loading={loading}
        />
      )}
      {addingRole && <RoleModal onHide={handleModalHide} />}
      {editingRole && <RoleModal onHide={handleModalHide} role={editingRole} />}

      <TableV2
        id={"roles"}
        resource="roles"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRoles}
        defaultSelectedRows={selectedRoles}
        onClick={(row) => setEditingRole(row)}
      />
    </div>
  );
};

export default Roles;

const colors = ["yellow", "light-blue", "red", "grey", "light-green", "light-gray"];
