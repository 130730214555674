import React, { useState } from "react";
import { ModalFooter } from "ui";
import { ModalHeader } from "ui";
import { AggregatedJob, MiterAPI } from "dashboard/miter";
import Notifier from "../../../utils/notifier";
import { ClickAwayListener } from "@material-ui/core";
import "../jobs.css";
import { useActiveCompany, useRefetchJobs } from "dashboard/hooks/atom-hooks";

type Props = {
  job: AggregatedJob;
  hide: () => void;
};

// This modal allows users to enable/disable custom activities for a certain job.
const CustomActivitiesModal: React.FC<Props> = ({ job, hide }) => {
  const activeCompany = useActiveCompany();
  const refetchJobs = useRefetchJobs();
  const companyName = activeCompany!.check_company.trade_name;

  const [enabled, setEnabled] = useState(job.custom_activities);
  const [saving, setSaving] = useState(false);

  const save = async () => {
    setSaving(true);
    try {
      const response = await MiterAPI.jobs.update(job._id.toString(), { custom_activities: enabled });
      if (response.error) {
        throw new Error(response.error);
      }

      await refetchJobs(job._id);
      Notifier.success("Job successfully updated.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the job. We're looking into it.");
    }
    hide();
    setSaving(false);
  };

  // Renders a radio option that allows a user to enable/disable custom activities
  const RadioOption = (props) => {
    const { isSelected, header, description } = props;
    const handleChange = () => {
      if (!isSelected) {
        setEnabled(!enabled);
      }
    };
    return (
      <div className="custom-activity-option" onClick={handleChange}>
        <div className={"custom-activity-option-radio " + (isSelected ? "enabled" : "disabled")}></div>
        <div className="custom-activity-option-text">
          <div style={{ fontWeight: "bold", marginBottom: 5 }}>{header}</div>
          <div>{description}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={hide}>
        <div className="modal-wrapper form">
          <ModalHeader heading="Job activities settings" onHide={hide} />
          <div className="modal-body form">
            <div className="vertical-spacer"></div>
            <RadioOption
              isSelected={enabled}
              header="Enable custom activities"
              description={`Define a custom set of activities that pertain only to this job. ${companyName}'s default activities will not apply.`}
            />
            <div className="vertical-spacer"></div>
            <RadioOption
              isSelected={!enabled}
              header="Disable custom activities"
              description={`This job will use ${companyName}'s default, company-level activities.`}
            />
            <div className="vertical-spacer"></div>
          </div>
          <ModalFooter loading={saving} submitText="Save" className="form" onSubmit={save} onCancel={hide} />
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default CustomActivitiesModal;
