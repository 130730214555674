import React, { useMemo, useState } from "react";
import { Button, Formblock, LoadingModal } from "ui";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { DateRange } from "ui/form/DateRangePicker";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { saveAs } from "file-saver";
import { useActiveCompanyId, useJobOptions, useTeamOptions } from "dashboard/hooks/atom-hooks";
import { useForm } from "react-hook-form";
import { reportList } from "../reportList";
import { Option } from "ui/form/Input";
import { JobInput } from "dashboard/components/shared/JobInput";
import { EXPENSE_REPORT_ITEM_SELECTION } from "backend/utils/reports/expense-report";

const reportObject = reportList.find((report) => report.slug === "expenses");

export const CreateExpensesReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();
  const form = useForm({ shouldUnregister: false });
  const jobOptions = useJobOptions();
  const teamMemberOptions = useTeamOptions();

  // State
  const [dateRange, setDateRange] = useState<DateRange>({
    start: DateTime.now().minus({ months: 1 }),
    end: DateTime.now(),
  });
  const [selectedJob, setSelectedJob] = useState<Option<string> | null>(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState<Option<string> | null>(null);
  const [includeReceipts, setIncludeReceipts] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<Option<"both" | EXPENSE_REPORT_ITEM_SELECTION>>({
    label: "Card transactions and reimbursements",
    value: "both",
  });

  const itemSelectionOptions: Option<"both" | EXPENSE_REPORT_ITEM_SELECTION>[] = useMemo(
    () =>
      [
        { label: "Card transactions and reimbursements", value: "both" as const },
        { label: "Card transactions only", value: "cards_only" as const },
        { label: "Reimbursements only", value: "reimbursements_only" as const },
      ].filter((option) => option.value !== selectedItems.value),
    [selectedItems]
  );

  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const downloadPDF = async () => {
    if (!activeCompanyId) return;

    setDownloadingPDF(true);

    try {
      const payload = {
        type: "expenses",
        params: {
          start_date: dateRange.start!.toISODate(),
          end_date: dateRange.end!.toISODate(),
          job_id: selectedJob?.value,
          selected_team_member_id: selectedTeamMember?.value,
          include_receipts: includeReceipts,
          item_selection: selectedItems.value === "both" ? undefined : selectedItems.value,
          company_id: activeCompanyId!,
        },
        format: "pdf",
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      const blob = new Blob([Uint8Array.from(response.buffer.data).buffer], { type: "application/pdf" });
      saveAs(blob, response.filename);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }

    setDownloadingPDF(false);
  };

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject?.label}</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 500 }}>
        <Formblock
          label="Date range"
          type="daterange"
          control={form.control}
          onChange={setDateRange}
          value={dateRange}
          name="date_rate"
          topOfInput={true}
          editing={true}
        />

        <JobInput
          type="select"
          labelInfo="If selected, only expenses from this job will be included."
          name="job"
          onChange={setSelectedJob}
          label="Job"
          editing={true}
          form={form}
          options={jobOptions}
          isClearable
        />
        <Formblock
          type="select"
          labelInfo="If selected, only expenses assigned to this team member will be included."
          name="team_member_id"
          onChange={setSelectedTeamMember}
          label="Team member"
          editing={true}
          form={form}
          options={teamMemberOptions}
          isClearable
        />
        <Formblock
          type="select"
          labelInfo="Return card transactions, reimbursements, or both."
          name="item_selection"
          onChange={setSelectedItems}
          label="Items to include"
          editing={true}
          form={form}
          options={itemSelectionOptions}
          value={selectedItems}
        />
        <Formblock
          type="checkbox"
          labelInfo="If selected, images of all receipts will be included. This will take 30 seconds to 1 minute to generate."
          name="include_receipts"
          onChange={(e) => setIncludeReceipts(e.target.checked)}
          label="Include receipts?"
          editing={true}
          form={form}
          defaultValue={true}
        />
      </div>
      <div className="flex" style={{ marginTop: 10, marginBottom: -33 }}>
        <Button text="Download PDF" onClick={downloadPDF} className="button-2 no-margin" disabled={false} />
      </div>
      {downloadingPDF && <LoadingModal text="Downloading PDF" />}
    </div>
  );
};
