import { useTeamOptions } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember } from "dashboard/miter";
import {
  ScreeningRow,
  adjudicationOptions,
  packageMap,
  resultOptions,
  statusOptions,
} from "dashboard/utils/screening";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Link } from "ui";
import Banner from "../shared/Banner";
import { MiterGuidesIcon } from "../MiterGuidesIcon";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { CheckrPackage } from "dashboard/types/screening-types";

type Props = {
  onExit: () => void;
  teamMember: AggregatedTeamMember;
  screening: ScreeningRow;
  // if a customer disables Checkr, then they know longer have access to fetched packages
  companyPackages?: CheckrPackage[];
};

export const ViewScreeningModal: FC<Props> = ({ onExit, teamMember, screening, companyPackages }) => {
  const title = `Background check for ${teamMember.first_name} ${teamMember.last_name}`;
  const form = useForm<ScreeningRow>({ defaultValues: screening, mode: "all" });
  const teamOptions = useTeamOptions();
  const showReportLink = !!screening.checkr_report_id;

  const packageName = useMemo(() => {
    if (!companyPackages || !companyPackages.length) {
      return packageMap[screening.checkr_package] || "Checkr Package";
    }
    return companyPackages.find((p) => p.slug === screening.checkr_package)?.name || "Checkr Package";
  }, [screening.checkr_package, companyPackages]);

  return (
    <ActionModal
      headerText={title}
      onHide={onExit}
      hideFooter={true}
      wrapperStyle={{ minWidth: 700 }}
      bodyStyle={{ padding: 30 }}
    >
      <Banner style={{ marginBottom: 20 }}>
        <div className="flex">
          Learn more about background check results.
          <div style={{ marginBottom: -5 }}>
            <MiterGuidesIcon path="team-members/background-checks#waiting-for-result" marginLeft={7} />
          </div>
        </div>
      </Banner>
      <Formblock
        name="team_member_id"
        label="Report for"
        editing={false}
        form={form}
        type={"select"}
        options={teamOptions}
        defaultValue={screening.team_member_id}
      />
      <div className="flex" style={{ alignItems: "center" }}>
        <Formblock
          name="checkr_package"
          label="Screening package"
          editing={false}
          form={form}
          type={"text"}
          labelStyle={{ minWidth: 150 }}
        />
        <div style={{ marginLeft: -60, marginBottom: 16 }}>
          <Link onClick={() => goToMiterGuide(`team-members/background-checks#package-options`)}>
            {packageName}
          </Link>
        </div>
      </div>
      <Formblock
        name="status"
        label="Report status"
        editing={false}
        form={form}
        type={"select"}
        labelInfo='The completion status of the background check, ranging from a "pending invitation" to a "completed report".'
        options={statusOptions}
        defaultValue={screening.status}
      />

      {screening.result && (
        <Formblock
          name="result"
          label="Assessment result"
          editing={false}
          form={form}
          labelInfo='The final result of the background check, can be either "clear" or "needs review".'
          type={"select"}
          options={resultOptions}
          defaultValue={screening.result}
        />
      )}

      {screening.adjudication && (
        <Formblock
          name="adjudication"
          label="Actions taken"
          editing={false}
          form={form}
          labelInfo={`Hiring decision made from the Checkr dashboard`}
          type={"select"}
          options={adjudicationOptions}
          defaultValue={screening.adjudication}
        />
      )}

      {showReportLink && (
        <div className="flex" style={{ alignItems: "center" }}>
          <Formblock name="report_link" label="Report details" type="text" form={form} editing={false} />
          <div style={{ marginLeft: -60, marginBottom: 16 }}>
            <Link
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_CHECKR_DASHBOARD}/reports/${screening.checkr_report_id}`,
                  "_blank"
                )
              }
            >
              View Checkr report
            </Link>
          </div>
        </div>
      )}

      {screening.report_status === "pending" && screening.eta && (
        <Formblock
          name="eta"
          label="Estimated report completion"
          dateOnly={true}
          editing={false}
          form={form}
          type={"datetime"}
          defaultValue={DateTime.fromISO(screening.eta)}
        />
      )}
    </ActionModal>
  );
};
