import React from "react";
import styles from "./TaskProgressBar.module.css";

type TaskProgressProps = {
  completed: number;
  total: number;
};

const TaskProgressBar: React.FC<TaskProgressProps> = ({ completed, total }) => {
  const percentage = total === 0 ? 0 : Math.round((completed / total) * 100);

  return (
    <div className={styles.container}>
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar} style={{ width: `${percentage}%` }} />
      </div>
      <span className={styles.progressText}>
        {completed}/{total}
      </span>
    </div>
  );
};

export default TaskProgressBar;
