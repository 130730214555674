import { AggregatedPayroll, AggregatedTeamMember } from "dashboard/miter";
import { useMemo } from "react";
import { useTeamOptions } from "./atom-hooks";
import { Option } from "ui/form/Input";

// same as useTeamOptions, but takes into account the current payroll
export const useTeamMemberOptionsEligibleForPayroll = (params: {
  payroll?: AggregatedPayroll;
  predicate: (tm: AggregatedTeamMember) => boolean;
}): Option<string>[] => {
  const { payroll, predicate } = params;

  const combinedPredicate = useMemo(() => {
    if (!payroll) return (tm: AggregatedTeamMember) => predicate?.(tm) ?? true;

    const tmIdSet = (payroll?.miter_payments || []).reduce(
      (acc, mp) => (mp.onboarded ? acc.add(mp.team_member._id) : acc),
      new Set<string>()
    );

    return (tm: AggregatedTeamMember) => tmIdSet.has(tm._id) && (!predicate || predicate(tm));
  }, [payroll, predicate]);

  const teamOptions = useTeamOptions({
    predicate: combinedPredicate,
  });

  return teamOptions;
};
