import { Formblock, SettingsCard } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";

interface WeeklyTimesheetReportSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const WeeklyTimesheetReportSettings: React.FC<WeeklyTimesheetReportSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <SettingsCard title="Weekly timesheet reports">
      <Formblock
        type="checkbox"
        name="show_all_team_members_weekly_timesheet_reports_in_mobile_app"
        text="Show all team members weekly timesheet reports in mobile app"
        onChange={(e) =>
          handleChange({
            show_all_team_members_weekly_timesheet_reports_in_mobile_app: e.target.checked,
          })
        }
        checked={timesheetSettings.show_all_team_members_weekly_timesheet_reports_in_mobile_app}
        editing={true}
        style={{ zIndex: 2, width: "100%" }}
        labelInfo="If enabled, all team members will be able to see weekly timesheet reports in mobile app"
      />
    </SettingsCard>
  );
};

export default WeeklyTimesheetReportSettings;
