import { AggregatedCandidate, JobApplication } from "dashboard/types/ats";
import React, { FC } from "react";
import styles from "./MergeCandidates.module.css";
import { Envelope, Phone, CheckCircle } from "phosphor-react";
import { LineVertical } from "@phosphor-icons/react";
import { TableV2 } from "ui";
import { useLookupJobPostings } from "dashboard/hooks/atom-hooks";
import { ColumnConfig } from "ui/table-v2/Table";

type Props = {
  candidate: AggregatedCandidate;
  selected: boolean;
  onClick: () => void;
};

type JobApplicationWithJobPostingTitle = JobApplication & { jobPostingTitle: string };

const columns: ColumnConfig<JobApplicationWithJobPostingTitle>[] = [
  {
    headerName: "Job posting",
    field: "jobPostingTitle",
  },
  {
    headerName: "Status",
    field: "status",
    displayType: "badge",
    colors: {
      applied: "yellow",
      in_review: "orange",
      interviewing: "blue",
      offer: "light-green",
      hired: "green",
      rejected: "red",
    },
  },
  {
    headerName: "Applied on",
    field: "applied_on",
    dataType: "date" as const,
    dateType: "timestamp" as const,
  },
];

export const CandidateOptionCard: FC<Props> = ({ candidate, selected, onClick }) => {
  const fullName = `${candidate.first_name} ${candidate.last_name}`;

  const lookupJobPosting = useLookupJobPostings();
  const jobApplications = candidate.job_applications;

  const data = jobApplications.map((jobApplication) => {
    const jobPosting = lookupJobPosting(jobApplication.job_posting_id);
    return {
      ...jobApplication,
      jobPostingTitle: jobPosting?.title || "Deleted job posting",
    };
  });

  return (
    <div className={styles["option-wrapper"] + " " + (selected ? styles["selected"] : "")} onClick={onClick}>
      <div className={styles["option-header-wrapper"]}>
        <p className={styles["option-header"]}>{fullName}</p>
        {selected ? <CheckCircle size={24} weight="fill" className={styles["selected-icon"]} /> : null}
      </div>
      <div className={styles["contact-info-wrapper"]}>
        <div className={styles["options-subheader"]}>Contact information</div>
        <div className={styles["contact-info-container"]}>
          <div className={styles["contact-info-sub-container"]}>
            <Envelope size={16} className={styles["contact-info-icon"]} />
            <div className={styles["contact-info"]}>{candidate.email}</div>
          </div>
          <LineVertical size={16} className={styles["contact-divider"]} />
          <div className={styles["contact-info-sub-container"]}>
            <Phone size={16} className={styles["contact-info-icon"]} />
            <div className={styles["contact-info"]}>{candidate.phone}</div>
          </div>
        </div>
      </div>
      <div className={styles["applications-wrapper"]}>
        <p className={styles["applications-subheader"]}>Applications</p>
        <TableV2
          id={"job-applications-table"}
          resource="job applications"
          columns={columns}
          data={data}
          hideColumns={true}
          hideSearch={true}
          hideSecondaryActions={true}
          hideFooter={true}
        />
      </div>
    </div>
  );
};
