import AppContext from "dashboard/contexts/app-context";
import React, { FC, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";
import { Customer, MiterAPI } from "dashboard/miter";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
type Props = {
  customer_id?: string;
  onFinish: (customer: Customer) => void;
  onHide: () => void;
};

const CustomerModal: FC<Props> = ({ customer_id, onFinish, onHide }) => {
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();
  const { customers, getCustomers } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const { control, register, errors, handleSubmit } = form;

  const selectedCustomer = customers.find((customer) => customer._id === customer_id);

  // Remove properties that equal what exists in the user object
  const cleanParams = (data) => {
    return { ...data, company_id: activeCompanyId! };
  };

  const createCustomer = async (data) => {
    if (selectedCustomer) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.customers.create(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Customer created successfully");
      await getCustomers();
      onFinish(res);
    } catch (e: $TSFixMe) {
      console.error("Error creating customer:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateCustomer = async (data) => {
    if (!selectedCustomer) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.customers.update(selectedCustomer._id, params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Customer updated successfully");
      await getCustomers();
      onFinish(res);
    } catch (e: $TSFixMe) {
      console.error("Error updating customer:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    if (selectedCustomer) {
      handleSubmit(updateCustomer)();
    } else {
      handleSubmit(createCustomer)();
    }
  };

  const hide = () => {
    onHide();
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={selectedCustomer?.name}
          className="modal"
        />
        <Formblock
          type="text"
          name="identifier"
          label="Identifier"
          labelInfo="Optional identifier you can use to identify this customer"
          control={control}
          register={register}
          editing={true}
          errors={errors}
          defaultValue={selectedCustomer?.identifier}
          className="modal"
        />
        <Formblock
          type="phone"
          name="phone"
          label="Phone number"
          control={control}
          register={register}
          editing={true}
          errors={errors}
          defaultValue={selectedCustomer?.phone}
          className="modal"
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={selectedCustomer ? "Update customer" : "Create customer"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={hide}
      submitText={"Save"}
      onHide={hide}
      onSubmit={submit}
      loading={loading}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default CustomerModal;
