import { AggregatedExpense, AggregatedJob, AggregatedTeamMember, Job } from "dashboard/miter";
import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { ExpensesTableRow } from "dashboard/pages/expenses/CardTransactionsTable";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { BulkEditedExpense } from "dashboard/pages/expenses/expenseUtils";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

/*
 * EXPENSES
 */
type ExpenseParams =
  | AggregatedExpense
  | AggregatedExpense[]
  | BulkEditedExpense
  | BulkEditedExpense[]
  | undefined;
type ExpenseAbilitiesOpts = { inboxMode?: InboxMode };

type ExpenseAction = "create" | "read" | "update" | "approve" | "delete" | "split";
type ExpenseAbilities = {
  can: (action: ExpenseAction, items: ExpenseParams) => boolean;
  cannot: (action: ExpenseAction, items: ExpenseParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: ExpenseAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: ExpenseAction) => (job: AggregatedJob | Job) => boolean;
};

export const useExpenseAbilities = (opts?: ExpenseAbilitiesOpts): ExpenseAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: ExpenseAction, items?: ExpenseParams) => {
      if (!items) return false;

      const expenses = Array.isArray(items) ? items : [items];

      // If we are in inbox mode, we can always read, approve, and update anything in the inbox
      const isApprover = opts?.inboxMode === "approval";

      // either approval or needs attention mode
      const isInboxMode = opts?.inboxMode != undefined;

      return expenses.every((expense) => {
        const isPersonal = expense.team_member_id === activeTeamMember?._id;

        if (isPersonal) {
          switch (action) {
            case "create":
              return can_("card_transactions:personal:create");
            case "read":
              return can_("card_transactions:personal:read") || isInboxMode;
            case "approve":
              return can_("card_transactions:personal:approve") || isApprover;
            case "update":
              return can_("card_transactions:personal:update") || isInboxMode;
            case "delete":
              return can_("card_transactions:personal:delete");
            case "split":
              return can_("card_transactions:personal:split");
            default:
              return false;
          }
        } else {
          const opts = { teamMember: getTeamMemberId(expense), job: getJobId(expense) };
          switch (action) {
            case "create":
              return can_("card_transactions:others:create", opts);
            case "read":
              return can_("card_transactions:others:read", opts) || isApprover;
            case "approve":
              return can_("card_transactions:others:approve", opts) || isApprover;
            case "update":
              return can_("card_transactions:others:update", opts) || isApprover;
            case "delete":
              return can_("card_transactions:others:delete", opts);
            case "split":
              return can_("card_transactions:others:split", opts);
            default:
              return false;
          }
        }
      });
    },
    [can_, activeTeamMember, opts?.inboxMode]
  );

  const cannot = useCallback(
    (action: ExpenseAction, items: ExpenseParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "card_transactions:personal",
    othersPermissionPath: "card_transactions:others",
    teamMemberField: { fieldName: "team_member._id", fieldType: "_id" },
    jobField: { fieldName: "job._id", fieldType: "_id" },
    inboxMode: opts?.inboxMode,
    appModule: "expense_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<ExpenseAction>("card_transactions");

  /** Job filter predicate */
  const jobPredicate = useAbilitiesJobPredicate<ExpenseAction>("card_transactions");

  return useMemo(
    () => ({ can, cannot, filter, teamPredicate, jobPredicate }),
    [can, cannot, filter, teamPredicate, jobPredicate]
  );
};

const getTeamMemberId = (
  expense: ExpensesTableRow | AggregatedExpense | BulkEditedExpense | Partial<AggregatedExpense>
): string | undefined => {
  if ("team_member" in expense) {
    if (typeof expense.team_member === "string") return expense.team_member;
    return expense.team_member?._id;
  } else if ("team_member_id" in expense) {
    return expense.team_member_id;
  }
};

const getJobId = (
  expense: ExpensesTableRow | AggregatedExpense | BulkEditedExpense | Partial<AggregatedExpense>
): string | undefined => {
  if ("job" in expense) {
    if (typeof expense.job === "string") return expense.job;
    return expense.job?._id;
  } else if ("job_id" in expense) {
    return expense.job_id;
  }
};
