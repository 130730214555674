import React, { FC } from "react";
import { AggregatedDailyReport } from "dashboard/miter";
import styles from "./DailyReports.module.css";
import { DateTime } from "luxon";

type DailyReportWeatherItem = NonNullable<AggregatedDailyReport["weather"]>[number];

const DailyReportWeather: FC<{ weather: DailyReportWeatherItem }> = ({ weather }) => {
  // Format the hour to 12 hour format
  const hour = ((Number(weather.hour.slice(0, -2)) + 11) % 12) + 1;
  const timeStr =
    DateTime.fromISO(weather.date).toFormat("EEE LLL dd") +
    " " +
    hour +
    ":00" +
    (Number(weather.hour.slice(0, -2)) > 12 ? " PM" : " AM");

  // Convert precipitation from mm to inches
  const precipitation = weather.precipitation / 25.4 + '"';

  return (
    <div className={styles["daily-report-weather"]}>
      <div className={styles["daily-report-weather-hour"]}>{timeStr}</div>
      <div className={styles["daily-report-weather-header"]}>
        <div className={styles["daily-report-weather-icon"]}>
          <img src={weather.icon} alt="Weather icon" />
        </div>
        <div className={styles["daily-report-weather-temp"]}>{weather.temperature}&deg;</div>
      </div>
      <p>{weather.description}</p>
      <div className={styles["daily-report-weather-attributes"]}>
        <div className={styles["daily-report-weather-attribute"]}>
          <p className={styles["daily-report-weather-attribute-label"]}>Wind</p>
          <p className={styles["daily-report-weather-attribute-value"]}>
            {weather.wind.speed} mph {weather.wind.direction}
          </p>
        </div>

        <div className={styles["daily-report-weather-attribute"]}>
          <p className={styles["daily-report-weather-attribute-label"]}>Humidity</p>
          <p className={styles["daily-report-weather-attribute-value"]}>{weather.humidity}%</p>
        </div>

        <div className={styles["daily-report-weather-attribute"]}>
          <p className={styles["daily-report-weather-attribute-label"]}>Precipitation</p>
          <p className={styles["daily-report-weather-attribute-value"]}>{precipitation}</p>
        </div>
      </div>
    </div>
  );
};

export default DailyReportWeather;
