/** This component allows an admin to scope Recruiting resources (job posting, job application) by job posting  */

import React from "react";
import { UseFormMethods } from "react-hook-form";
import { PermissionGroupForm } from "../PermissionGroupModal";
import styles from "../PermissionGroups.module.css";
import { Formblock } from "ui";

export type PermissionGroupRecruitingScopeProps = {
  form: UseFormMethods<PermissionGroupForm>;
};

export const PermissionGroupRecruitingScope: React.FC<PermissionGroupRecruitingScopeProps> = ({ form }) => {
  return (
    <div className={styles["permission-group-scopes"]}>
      <Formblock
        type="multiselect"
        name={"scopes.modules.recruiting.job_postings"}
        label={"Job posting scopes"}
        form={form}
        editing={true}
        className="modal"
        placeholder={"Select scopes"}
        options={RECRUITING_SCOPE_OPTIONS}
        height="unset"
        style={{ marginTop: 20 }}
      />
    </div>
  );
};

export const RECRUITING_SCOPE_OPTIONS = [
  {
    label: (
      <>
        <b>Hiring manager</b> - Job postings the member is the hiring manager for
      </>
    ),
    value: "hiring_manager",
  },
  {
    label: (
      <>
        <b>Hiring team</b> - Job postings the member is on the hiring team for
      </>
    ),
    value: "hiring_team",
  },
];
