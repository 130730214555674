import React from "react";
import { SettingsCard, Formblock } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import { UseFormMethods } from "react-hook-form";
import { PAY_PERIOD_EDITOR_COLUMN_OPTIONS } from "../TimesheetsByPayPeriod/TimesheetsByPayPeriodEditor";

interface TimesheetsPayPeriodEditorSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
  form: UseFormMethods;
}

const TimesheetsPayPeriodEditorSettings: React.FC<TimesheetsPayPeriodEditorSettingsProps> = ({
  timesheetSettings,
  handleChange,
  form,
}) => {
  return (
    <SettingsCard title="Timesheets pay period editor" info={"Configure the timesheets pay period editor"}>
      <Formblock
        type="multiselect"
        name="default_timesheet_pay_period_editor_columns"
        form={form}
        editing={true}
        label="Select the default columns to display in the timesheets pay period view"
        className="modal wizard"
        placeholder={"Select columns"}
        options={PAY_PERIOD_EDITOR_COLUMN_OPTIONS}
        onChange={(e) => {
          handleChange({
            default_timesheet_pay_period_editor_columns: e?.map((col) => col.value) || [],
          });
        }}
        height="unset"
        defaultValue={timesheetSettings.default_timesheet_pay_period_editor_columns}
        style={{ width: "90%" }}
      />
    </SettingsCard>
  );
};

export default TimesheetsPayPeriodEditorSettings;
