import React, { FC, ReactElement, useState } from "react";
import styles from "./Popup.module.css";
import { Notifier } from "dashboard/utils";
import { CaretDown, CaretUp } from "phosphor-react";
import { Loader } from "../loader";

type Props = {
  label: ReactElement | string;
  canOpen?: () => Promise<boolean>;
  onOpenFail?: () => Promise<void>;
  onOpenSuccess?: () => Promise<void>;
  defaultOpen: boolean;
  children: ReactElement;
  wrapperStyle?: React.CSSProperties;
};
const Popup: FC<Props> = ({
  label,
  defaultOpen,
  onOpenFail,
  canOpen,
  children,
  wrapperStyle,
  onOpenSuccess,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [loading, setLoading] = useState(false);

  /** Toggler functions */
  const open = async () => {
    setLoading(true);
    try {
      const success = canOpen ? await canOpen() : true;
      if (success) {
        onOpenSuccess && onOpenSuccess();
        setIsOpen(true);
      } else {
        onOpenFail && onOpenFail();
      }
    } catch (e) {
      Notifier.error("Failed to open popup");
    }
    setLoading(false);
  };

  const close = () => {
    setIsOpen(false);
  };

  const headerOnClick = () => {
    if (loading) return;
    isOpen ? close() : open();
  };

  const renderLabel = () => {
    const isString = typeof label === "string";
    return isString ? <div className={styles["label"]}>{label}</div> : label;
  };

  const renderHeader = () => {
    return (
      <div
        className={styles["header-container"]}
        onClick={headerOnClick}
        style={{ opacity: loading ? 0.4 : 1 }}
      >
        {renderLabel()}
        {loading ? (
          <Loader small={true} />
        ) : (
          <div>
            {isOpen ? (
              <CaretDown size={18} weight={"bold"} style={{ marginBottom: -2, marginLeft: 5 }} />
            ) : (
              <CaretUp size={18} weight={"bold"} style={{ marginBottom: -2, marginLeft: 5 }} />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={wrapperStyle}>
      {renderHeader()}
      {isOpen ? children : null}
    </div>
  );
};

export default Popup;
