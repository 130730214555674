import React from "react";

export type FlatfileImportDropdownItemProps = {
  launch: () => void;
  resource: string;
  title?: string;
  children?: React.ReactNode;
};

export const FlatfileImportDropdownItem: React.FC<FlatfileImportDropdownItemProps> = ({
  launch,
  resource,
  title,
  children,
}) => {
  return (
    <>
      <div
        className=""
        onClick={launch}
        style={{
          minWidth: 215,
          width: "auto",
          whiteSpace: "nowrap",
          marginRight: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {children}
        {title || `Import ${resource}`}
      </div>
    </>
  );
};
