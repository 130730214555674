import { MiterIntegrationForCompany } from "dashboard/miter";
import React, { useState } from "react";
import { ReportConfig } from "../reports/reportList";
import { ReportItem } from "../reports/reports";
import { ContractorsPlanCensus } from "./IntegrationReports/ContractorsPlanCensus";
import { ContractorsPlanContributions } from "./IntegrationReports/ContractorsPlanContributions";
import { ENDemographicAuditReport } from "./IntegrationReports/ENDemographicAuditReport";
import { ENDeductionAuditReport } from "./IntegrationReports/ENDeductionAuditReport";

type Props = {
  integration: MiterIntegrationForCompany;
};

type IntegrationReportConfig = ReportConfig & {
  slug: string;
  integration_key?: MiterIntegrationForCompany["key"];
  component?: React.FC;
};

export const IntegrationReports: React.FC<Props> = (props) => {
  const [activeReport, setActiveReport] = useState<IntegrationReportConfig | undefined>();

  return (
    <div className="reports-list" style={{ marginTop: 0 }}>
      {activeReport ? (
        <div style={{ width: "100%" }}>
          <div className="blue-link" onClick={() => setActiveReport(undefined)}>
            Back to all reports
          </div>
          <div style={{ fontSize: 20, fontWeight: "bold", marginTop: 25, marginBottom: 20 }}>
            {activeReport.label}
          </div>
          {activeReport.component ? <activeReport.component /> : null}
        </div>
      ) : (
        <>
          {integrationReportList
            .filter((r) => r.integration_key === props.integration.key)
            .map((r, index) => {
              return <ReportItem {...r} key={r.slug} index={index} handleClick={setActiveReport} />;
            })}
        </>
      )}
    </div>
  );
};

const integrationReportList: IntegrationReportConfig[] = [
  {
    // @ts-expect-error slug not in ReportConfig
    slug: "cp_employee_census",
    label: "Employee Census",
    description:
      "A list of employees and their benefit plan enrollment information required by The Contractors' Plan.",
    integration_key: "contractors_plan",
    link: "",
    category: "",
    component: ContractorsPlanCensus,
  },
  {
    // @ts-expect-error slug not in ReportConfig
    slug: "contributions",
    label: "Contributions",
    description: "A summary of benefit plan contributions for a specified pay period.",
    integration_key: "contractors_plan",
    link: "",
    category: "",
    component: ContractorsPlanContributions,
  },
  {
    // @ts-expect-error slug not in ReportConfig
    slug: "demographic-audit-report",
    label: "Demographic Audit Report",
    description: "Employee navigator report that shows the demographic information for all employees.",
    integration_key: "employee_navigator",
    link: "",
    category: "",
    component: ENDemographicAuditReport,
  },
  {
    // @ts-expect-error slug not in ReportConfig
    slug: "deduction-audit-report",
    label: "Deduction Audit Report",
    description: "Employee navigator report that shows the deduction information for all employees.",
    integration_key: "employee_navigator",
    link: "",
    category: "",
    component: ENDeductionAuditReport,
  },
];
