import React, { useState } from "react";
import { BookOpen } from "phosphor-react";
import { goToMiterGuide } from "dashboard/utils/miterGuides";

type Props = {
  path: string;
  marginLeft?: number;
};

export const MiterGuidesIcon: React.FC<Props> = ({ path, marginLeft }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await goToMiterGuide(path);
    setLoading(false);
  };

  return (
    <div style={{ marginLeft, cursor: "pointer" }} className="" onClick={handleClick}>
      <BookOpen size={14} weight="bold" color={loading ? "#868abd" : "#4d55bb"} />
    </div>
  );
};
