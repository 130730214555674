import React, { useState } from "react";
import { Button, ModalHeader } from "ui";
import { ClickAwayListener } from "@material-ui/core";
import { ExpenseCard, MiterAPI } from "dashboard/miter";
import ExpenseCardSpendingLimitsForm, {
  useSpendingLimitsForm,
  ValidFormValues,
} from "./ExpenseCardsSpendingLimitsForm";
import Notifier from "dashboard/utils/notifier";
import pluralize from "pluralize";
import styles from "./Expenses.module.css";
import { notNullish } from "miter-utils";

type Props = {
  onHide: () => void;
  onSuccess: () => void;
  expenseCards: ExpenseCard[];
};

const AddSpendingLimitsModal = ({ onHide, onSuccess, expenseCards }: Props): React.ReactElement => {
  const [isSaving, setIsSaving] = useState(false);
  const form = useSpendingLimitsForm(undefined);
  const [errors, setErrors] = useState<{ message: string; name: string; last4: string }[]>([]);

  const onSubmit = async ({ spending_limits }: ValidFormValues) => {
    setIsSaving(true);
    const intervalsBeingSet = new Set(spending_limits.map(({ interval }) => interval));

    const results = await Promise.allSettled(
      expenseCards.map(async (expenseCard) =>
        MiterAPI.expense_cards
          .update(expenseCard._id, {
            spending_controls: {
              ...expenseCard.cardholder.spending_controls,
              spending_limits_currency: "usd",

              // Merge in the new spending limits to cardholder's existing spending limits,
              // but remove any previous spending limits with the same interval as what we're adding now.
              //
              // For example, if a user had a limit of [{ interval: "month", amount: 100 }, { interval: "year", amount: 200 }]
              // and we add a new limit of [{ interval: "month", amount: 200 }], we want to end up with
              // [{ interval: "month", amount: 200 }, { interval: "year", amount: 200 }].
              spending_limits: (expenseCard.cardholder.spending_controls?.spending_limits || [])
                .filter(({ interval }) => !intervalsBeingSet.has(interval))
                .concat(spending_limits),
            },
          })
          .catch((error) => {
            return { error };
          })
      )
    );

    const errors = results
      .map((result, i) => {
        if (result.status === "rejected") {
          throw Error("Unexpected: all promises should have been fulfilled, since we catch each one.");
        }
        if ("error" in result.value) {
          const { error } = result.value;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const message = error.message || (error.raw as any).message;
          return { message, name: expenseCards[i]!.cardholder.name, last4: expenseCards[i]!.card.last4 };
        }
      })
      .filter(notNullish);

    setErrors(errors);

    const numSuccessful = results.length - errors.length;
    if (numSuccessful) {
      Notifier.success(`${pluralize("card", numSuccessful, true)} updated.`);
      onSuccess();
    }

    setIsSaving(false);

    if (!errors.length) {
      onHide();
    }
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form">
          <ModalHeader heading={`Add spending limits`} onHide={onHide} />

          <div id={styles.modal_body_height} className="modal-body form">
            <h4 className={styles.margin_bottom_8}>Spending controls</h4>

            {errors.length > 0 ? (
              <div className="error-msg">
                <p>{`${errors.length} ${pluralize("card", errors.length)} failed to update:`}</p>
                <ol>
                  {errors.map(({ message, name, last4 }) => (
                    <li key={last4} className={styles.margin_bottom_8}>
                      <strong>
                        *{last4} ({name})
                      </strong>
                      : {message}
                    </li>
                  ))}
                </ol>
              </div>
            ) : (
              <ExpenseCardSpendingLimitsForm form={form} />
            )}

            <div className="vertical-spacer" />
          </div>

          <div className="modal-footer form">
            <Button className="button-1" onClick={onHide}>
              Close
            </Button>
            {errors.length === 0 && (
              <Button className="button-2" loading={isSaving} onClick={form.handleSubmit(onSubmit)}>
                Save
              </Button>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
export default AddSpendingLimitsModal;
