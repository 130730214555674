import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import { ActionLink } from "../action-menu/ActionMenu";

import styles from "./PageModal.module.css";
import { Button } from "../button";
import { Question, SignOut } from "phosphor-react";
import { use100vh } from "react-div-100vh";
import { Assign } from "utility-types";
import { motion } from "framer-motion";

export type PageModalActionLink = Assign<ActionLink, { position: "left" | "right"; disabled?: boolean }>;

type Props = {
  children: React.ReactNode;
  header: string | ReactElement;
  onClose: () => void;
  onHelp?: () => void;

  /** Action buttons */
  footerActions?: PageModalActionLink[];

  /** Styles */
  wrapperClassName?: string;
  headerClassName?: string;
  footerClassName?: string;
  bodyClassName?: string;
  bodyContentStyle?: React.CSSProperties;

  leftElement?: ReactElement;
};

const PageModal: React.FC<Props> = ({
  children,
  header,
  onClose,
  onHelp,
  footerActions,
  wrapperClassName,
  headerClassName,
  footerClassName,
  bodyClassName,
  bodyContentStyle,
  leftElement,
}) => {
  // Needed to due to safari/mobile navigation bar
  // https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d
  const height = use100vh();
  const bodyRef = useRef<HTMLDivElement>(null);

  // @ts-expect-error Makes sure the body is not scrollable when the wizard is open
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const filteredFooterActions = useMemo(() => {
    return footerActions?.filter((footerActions) => {
      if (!("shouldShow" in footerActions)) return true;
      return footerActions.shouldShow?.();
    });
  }, [footerActions]);

  const renderHeader = () => {
    return (
      <div className={styles["page-modal-header"] + " " + headerClassName}>
        <div className={styles["page-modal-header-title"]}>
          <h1 className={styles["page-modal-header-title-text"]}>{header}</h1>
        </div>

        <div className={styles["page-modal-header-exit"]}>
          {onHelp && (
            <Button onClick={onHelp} className={"button-text " + styles["page-modal-header-exit-button"]}>
              <span style={{ marginRight: 7, fontSize: "1.1rem" }}>Help</span>
              <Question />
            </Button>
          )}
          <Button onClick={onClose} className={"button-text " + styles["page-modal-header-exit-button"]}>
            <span style={{ marginRight: 7, fontSize: "1.1rem" }}>Exit</span>
            <SignOut />
          </Button>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={styles["page-modal-footer"] + " " + footerClassName}>
        <div className={styles["page-modal-footer-left"]}>
          {filteredFooterActions
            ?.filter((item) => item.position === "left")
            .map((item) => (
              <Button
                key={item.label}
                onClick={item.action}
                loading={item.loading}
                className={item.className + " " + styles["page-modal-btn"]}
              >
                {item.icon}
                {item.label}
              </Button>
            ))}
        </div>
        <div className={styles["page-modal-footer-right"]}>
          {filteredFooterActions
            ?.filter((item) => item.position === "right")
            .map((item) => (
              <Button
                key={item.label}
                onClick={item.action}
                loading={item.loading}
                disabled={item.disabled}
                className={item.className + " " + styles["page-modal-btn"]}
              >
                {item.icon}
                {item.label}
              </Button>
            ))}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        className={styles["page-modal-body"] + " " + bodyClassName}
        style={{ height: `calc(${height}px - 125px)` }}
        ref={bodyRef}
      >
        <div className={styles["page-modal-body-content"]} style={bodyContentStyle}>
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className={styles["page-modal"] + " " + wrapperClassName}>
      <motion.div
        className={styles["wizard"]}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{ duration: 0.2 }}
      >
        <div className="flex">
          {leftElement}
          <div className="width-100-percent">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PageModal;
