import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useLookupLedgerMapping, useLookupTeam, useLookupLocation } from "dashboard/hooks/atom-hooks";
import { Location, AggregatedTeamMember } from "dashboard/miter";
import { truncateTeamMemberFullNameList } from "dashboard/utils";
import { notNullish } from "miter-utils";
import { FC, useState } from "react";
import DataBox from "../dataBox/DataBox";
import { DataBoxRow } from "../dataBox/DataBoxRow";
import LocationModal from "./LocationModal";

type Props = {
  location: Location;
};

export const LocationDetails: FC<Props> = ({ location }) => {
  const { can } = useMiterAbilities();
  const lookupTeam = useLookupTeam();
  const lookupLocation = useLookupLocation();
  const lookupLedgerMapping = useLookupLedgerMapping();

  const [editing, setEditing] = useState<boolean>(false);

  const handleModalClose = () => {
    setEditing(false);
  };

  const getTeamMembers = (ids: string[]): AggregatedTeamMember[] => {
    const teamMembers = ids.map((id) => lookupTeam(id)).filter(notNullish);
    return teamMembers;
  };

  return (
    <div>
      {editing && (
        <LocationModal
          locationId={location._id.toString()}
          onFinish={handleModalClose}
          onHide={handleModalClose}
        />
      )}
      <div className="vertical-spacer-small"></div>
      <DataBox
        title="Location details"
        className="flex-col no-margin"
        onEdit={can("lists:locations:manage") ? () => setEditing(true) : undefined}
        editText={"Edit"}
      >
        <DataBoxRow label="Location name" value={location.name} />
        <DataBoxRow
          label="Parent location name"
          value={location.parent_location_id ? lookupLocation(location.parent_location_id)?.name || "-" : "-"}
        />
        <DataBoxRow
          label="Supervisors"
          value={truncateTeamMemberFullNameList(getTeamMembers(location.supervisor_ids)) || "-"}
        />
        <DataBoxRow
          label="Superintendents"
          value={truncateTeamMemberFullNameList(getTeamMembers(location.superintendent_ids)) || "-"}
        />
        <DataBoxRow label="Source ID" value={location.external_id || "-"} />
        <DataBoxRow label="GL mapping" value={lookupLedgerMapping(location.ledger_mapping_id)?.name || "-"} />
      </DataBox>
    </div>
  );
};
