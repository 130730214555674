import FormsTable from "dashboard/components/forms/FormsTable";
import React from "react";
import { Helmet } from "react-helmet";

export const Forms: React.FC = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>Forms | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <h1>Forms</h1>
      </div>
      <FormsTable />
    </div>
  );
};
