import { User } from "dashboard/miter";
import { Candidate, JobPosting } from "dashboard/types/ats";
import {
  MessageTemplate,
  MessageTemplateSmartField,
  MessageTemplateSmartFieldArray,
  MessageTemplateSmartFieldOption,
} from "dashboard/types/message-template";

export const messageTemplateSmartFields: MessageTemplateSmartFieldOption[] = [
  {
    value: "candidate_first_name",
    title: "Candidate first name",
  },
  {
    value: "candidate_last_name",
    title: "Candidate last name",
  },

  {
    value: "job_posting_title",
    title: "Job posting title",
  },
  {
    value: "sender_first_name",
    title: "Sender first name",
  },
  {
    value: "sender_last_name",
    title: "Sender last name",
  },
];

export const getSmartFieldsFromHTML = (html: string): MessageTemplateSmartField[] => {
  const smartFieldRegex = /{{([^}]+)}}/g;
  const matches = html.match(smartFieldRegex) || [];
  return matches
    .map((match) => match.slice(2, -2))
    .filter((field) => MessageTemplateSmartFieldArray.includes(field as MessageTemplateSmartField)); // Remove {{ and }}
};

type HydrateRecruitingMessageTemplateProps = {
  text: string;
  template?: MessageTemplate;
  candidate: Candidate;
  jobPosting?: JobPosting;
  sender: User;
};

export const hydrateRecruitingMessageTemplate = ({
  text,
  template,
  candidate,
  jobPosting,
  sender,
}: HydrateRecruitingMessageTemplateProps): string => {
  const smartFields = template?.smart_fields || [];
  const informationMap = {
    candidate_first_name: candidate.first_name,
    candidate_last_name: candidate.last_name,
    job_posting_title: jobPosting?.title,
    sender_first_name: sender.first_name,
    sender_last_name: sender.last_name,
  };
  const hydratedText = smartFields.reduce((acc, field) => {
    if (informationMap[field]) {
      return acc.replace(`{{${field}}}`, informationMap[field]);
    }
    return acc;
  }, text);

  return hydratedText;
};

/*
 * Convert html to trimmed plain text
 * This is useful for getting the text content of a message template
 */
export const convertToTrimmedPlainText = (html: string): string => {
  if (!html) return "";
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = (div.textContent || div.innerText || "").trim();
  div.remove();
  return text;
};

/*
 * Twilio respects the newline character in a message body, so we want to replace any <br> tags with a newline character.
 * But we also want to get rid of any other html tags.
 */
export const convertToPlainTextWithNewLines = (html: string): string => {
  if (!html) return "";
  const htmlWithNewLines = html.replace(/<\/p>/g, "</p>\n").replace(/<br\s*\/?>/gi, "\n");
  const div = document.createElement("div");
  div.innerHTML = htmlWithNewLines;
  const plainText = div.textContent || div.innerText || "";
  div.remove();
  return plainText;
};
