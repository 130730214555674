import React, { useMemo, useState } from "react";
import { ActionModal } from "ui";
import { useLookupLedgerMapping, useSetLedgerMappings } from "dashboard/hooks/atom-hooks";
import { useLedgerAccountLabeler, useLedgerAccountOptions } from "dashboard/hooks/atom-hooks";
import { ColumnOrGroupConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { ICellEditorParams } from "ag-grid-community";
import { BulkUpdateResult, LedgerMapping, MiterAPI } from "dashboard/miter";
import {
  INCLUDE_WITH_EARNING_ACCOUNT,
  LEDGER_LINE_TYPE_ID_SEPARATOR,
  LedgerMappingLineInfo,
  includeWithEarningOption,
  ledgerMappingCategoryLookup,
  useLedgerLineTypes,
} from "../accountingUtils";
import { LedgerMappingLineType } from "backend/utils/accounting";
import { Notifier } from "ui";
import { cloneDeep } from "lodash";
import { Pencil } from "phosphor-react";
import styles from "./LedgerMappingModal.module.css";
import { ClickAwayListener } from "@material-ui/core";
import { LedgerMappingUsageModal } from "./LedgerMappingUsageModal";

type Props = {
  ledgerMappingId: string;
  hide: () => void;
};

const typesAllowingAllocation: LedgerMappingLineType[] = [
  "employer_benefit_contributions",
  "fringe_contributions",
  "employer_payroll_taxes",
  "workers_comp_expenses",
  "burden_rate_expenses",
];

export const LedgerMappingModal: React.FC<Props> = ({ ledgerMappingId, hide }) => {
  const lookupMapping = useLookupLedgerMapping();
  const accountLabeler = useLedgerAccountLabeler();
  const accountOptionsForAllocatableExpenses = useLedgerAccountOptions({
    includeCustomOption: includeWithEarningOption,
  });
  const basicAccountOptions = useLedgerAccountOptions();
  const setLedgerMappings = useSetLedgerMappings();
  const [showObjectsUsing, setObjectsUsing] = useState(false);
  const mapping = lookupMapping(ledgerMappingId);

  const mappingLineItems = useLedgerLineTypes({ mapping, isDefault: mapping?.is_company_default });

  const columns = useMemo(() => {
    const cols: ColumnOrGroupConfig<LedgerMappingLineInfo>[] = [
      {
        field: "category",
        dataType: "string",
        valueGetter: (params) => params.data && ledgerMappingCategoryLookup[params.data.category],
        rowGroup: true,
        hide: true,
      },
      {
        field: "label",
        headerName: "Label",
        dataType: "string",
        filter: "agSetColumnFilter",
        tooltipValueGetter: (params) => params.data?.description,
        minWidth: 250,
        pinned: "left",
      },
      {
        field: "flavor",
        headerName: "Type",
        maxWidth: 100,
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "creditOrDebit",
        headerName: "Credit/Debit",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        field: "accountId",
        headerName: "Default GL Account",
        filter: "agSetColumnFilter",
        editorType: "select",
        editable: true,
        width: 300,
        suppressColumnsToolPanel: true,
        cellEditorParams: {
          options: (params: ICellEditorParams<LedgerMappingLineInfo>) =>
            typesAllowingAllocation.includes(params.data._id as LedgerMappingLineType)
              ? accountOptionsForAllocatableExpenses
              : basicAccountOptions,
          isClearable: true,
        },
        useValueFormatterForExport: true,
        valueFormatter: (params) =>
          params.data?.accountId === INCLUDE_WITH_EARNING_ACCOUNT
            ? includeWithEarningOption.label
            : accountLabeler(params.data?.accountId) || "-",
        minWidth: 250,
      },
    ];
    return cols;
  }, [accountLabeler, accountOptionsForAllocatableExpenses, basicAccountOptions]);

  // Update functions
  const updateMapping = async (update: Partial<LedgerMapping>) => {
    if (!mapping) return;
    try {
      const response = await MiterAPI.ledger_mappings.update(mapping._id, update);
      if (response.error) throw new Error(response.error);
      setLedgerMappings((prev) => prev.concat(response));
      Notifier.success("Successfully updated mapping.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error("There was an error updating this mapping. Please try again or reach out to support.");
    }
  };

  const handleSave = async (data: LedgerMappingLineInfo[]): Promise<BulkUpdateResult> => {
    const ret = { successes: [], errors: [] };
    if (!mapping) return ret;
    const { _id, updated_at, created_at, ...rest } = mapping;
    try {
      const clonedConfig = cloneDeep(rest) || {};
      if (!clonedConfig.defaults) clonedConfig.defaults = {};
      if (!clonedConfig.earning_types) clonedConfig.earning_types = {};
      if (!clonedConfig.benefit_type_expenses) clonedConfig.benefit_type_expenses = {};
      if (!clonedConfig.benefit_type_liabilities) clonedConfig.benefit_type_liabilities = {};

      for (const d of data) {
        const cleanedId = d._id.split(LEDGER_LINE_TYPE_ID_SEPARATOR)[0]!;
        let cleanedCategory: keyof LedgerMapping;
        if (["payroll", "expense_mgmt"].includes(d.category)) {
          cleanedCategory = "defaults";
        } else {
          cleanedCategory = d.category as keyof LedgerMapping;
        }
        const finalAccountId =
          mapping?.[cleanedCategory]?.[cleanedId] && !d.accountId ? null : d.accountId || undefined;
        clonedConfig[cleanedCategory][cleanedId] = finalAccountId;
      }
      await updateMapping({ ...clonedConfig, name: header || clonedConfig.name });
    } catch (e: $TSFixMe) {
      Notifier.error(`There was an error updating this mapping: ${e.message}`);
    }
    return ret;
  };

  const staticActions = useMemo(() => {
    const acts: TableActionLink[] = [
      {
        label: "Show objects using this mapping",
        className: "button-1 no-margin",
        action: () => setObjectsUsing(true),
        important: true,
        shouldShow: () => true,
        showInEditMode: true,
      },
    ];
    return acts;
  }, []);

  // Header editing
  const [isEditingHeader, setIsEditingHeader] = useState(false);
  const [header, setHeader] = useState(mapping?.name);

  const renderHeader = () => {
    return isEditingHeader ? (
      <div className="flex">
        <ClickAwayListener onClickAway={() => setIsEditingHeader(false)} mouseEvent="onMouseDown">
          <input
            className={`${styles["label-input"]}`}
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            placeholder={"Add a name for the mapping"}
            style={{ marginRight: 10, width: 500 }}
          />
        </ClickAwayListener>
      </div>
    ) : (
      <div className="flex">
        <div onClick={() => setIsEditingHeader(true)} style={{ cursor: "pointer" }}>
          {header}
        </div>
        <Pencil
          weight="bold"
          color="#4d55bb"
          height={14}
          style={{ marginLeft: 6, cursor: "pointer" }}
          onClick={() => setIsEditingHeader(true)}
        />
      </div>
    );
  };

  if (!mapping) return null;

  return (
    <>
      <ActionModal
        headerText={renderHeader()}
        onHide={hide}
        wrapperStyle={{ width: "50%", minWidth: 1000 }}
        bodyStyle={{ overflow: "visible", maxHeight: "100%" }}
        showCancel={false}
        cancelText="Hide"
        onCancel={hide}
        hideFooter
      >
        <TableV2
          data={mappingLineItems}
          columns={columns}
          resource="mappings"
          id={`ledger-line-item-mappings`}
          groupDefaultExpanded={-1}
          groupDisplayType="groupRows"
          groupHideOpenParents={false}
          editable={true}
          hideBulkEdit={true}
          onSave={handleSave}
          gridWrapperStyle={{ height: "60vh" }}
          hideFooter
          alwaysEditable
          hideSelectColumn
          alwaysShowSecondaryActions // makes sure export is always available
          staticActions={staticActions}
        />
      </ActionModal>
      {showObjectsUsing && (
        <LedgerMappingUsageModal ledgerMappingId={ledgerMappingId} hide={() => setObjectsUsing(false)} />
      )}
    </>
  );
};
