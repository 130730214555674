import { MiterAPI } from "dashboard/miter";
import { MiterIntegrationKey } from "backend/models/integrations/integrations";
import AppContext from "dashboard/contexts/app-context";
import { useContext, useState } from "react";
import { Notifier } from "ui";
import { useNavigate } from "react-router-dom";
import { capitalize } from "lodash";

type AddFromUrlHook = {
  addFromUrl: (key: MiterIntegrationKey) => Promise<void>;
  loading: boolean;
};

export const useAddFromUrl = (activeCompanyId: string | null): AddFromUrlHook => {
  const { getIntegrations } = useContext(AppContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const addFromUrl = async (key: MiterIntegrationKey) => {
    setLoading(true);
    try {
      const response = await MiterAPI.integrations.add_from_url(activeCompanyId!, window.location.href, key);
      if (response.error) throw new Error(response.error);
      await getIntegrations();
      Notifier.success("Your account was connected successfully.");
      navigate("/integrations/" + key);
    } catch (e) {
      console.error(e);
      Notifier.error(`There was an error connecting to ${capitalize(key)}. We're looking into it!`);
    }
    setLoading(false);
  };

  return {
    addFromUrl,
    loading,
  };
};
