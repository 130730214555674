import React from "react";
import TimesheetContext from "./timesheetContext";
import { ApprovalTimeline } from "dashboard/components/approvals/ApprovalTimeline";
import { ApprovalItemPolicyData } from "dashboard/components/approvals/ApprovalItemPolicyData";

export const TimesheetApprovals: React.FC = () => {
  const { timesheet } = React.useContext(TimesheetContext);

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Policy and Approvals</div>
      </div>
      {timesheet && (
        <div style={{ marginBottom: 50, marginTop: 25 }}>
          <ApprovalItemPolicyData item={timesheet} />
        </div>
      )}
      {timesheet && <ApprovalTimeline item={timesheet} />}
    </div>
  );
};
