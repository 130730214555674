import EquipmentsTable from "dashboard/components/equipment/EquipmentTable";
import { capitalize } from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Toggler } from "ui";

const Assets: React.FC = () => {
  const { view } = useParams<{ view: string }>();
  const navigate = useNavigate();

  const togglerConfig = {
    config: [
      { path: "equipment", label: "Equipment" },
      // { path: "materials", label: "Materials" },
    ],
    handler: (v) => {
      navigate(`/assets/${v}`);
    },
    path: view ? view : "equipment",
    secondary: true,
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{capitalize(view)} | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex" style={{ justifyContent: "space-between" }}>
          <h1 className="view-title title-with-toggler">Assets</h1>
          <Button className="button-1" text="Settings" onClick={() => navigate("/asset-settings")} />
        </div>
        <Toggler
          config={togglerConfig.config}
          secondary={false}
          toggle={togglerConfig.handler}
          active={togglerConfig.path}
        />
        <div className="vertical-spacer" />

        <EquipmentsTable />
      </div>
    </div>
  );
};

export default Assets;
