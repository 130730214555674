import React from "react";

type Props = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
};

const HighlightBox: React.FC<Props> = ({ title, className, children }) => {
  return (
    <div className={"highlight-box " + (className ? className : "")}>
      {title && <div className="highlight-box-header">{title}</div>}
      <div className="highlight-box-body">{children}</div>
    </div>
  );
};

export default HighlightBox;
