import { useEffect } from "react";

/** This hooks runs the callback if the page is closed or hard refreshed only. */

export const usePageCloseOrRefresh = (callback: () => void): void => {
  useEffect(() => {
    const handleUnload = (event) => {
      event.preventDefault();
      callback();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [callback]);
};
