import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

type SettingsProps = {
  children: React.ReactNode;

  title: string;
  subtitle: string;
  backText: string;
  backLink: string;
};

export const SettingsPage: React.FC<SettingsProps> = ({ children, title, subtitle, backText, backLink }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(backLink);
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>{title} | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={handleBack}>
          {backText}
        </div>
        <h1>{title}</h1>
        <div className="settings-subtitle">{subtitle}</div>
        <div className="settings-content">{children}</div>
      </div>
    </div>
  );
};
