import React from "react";

import styles from "./progressBar.module.css";

type StageProps = {
  index: number;
  active: number;
  text: string;
  includeNumbers?: boolean;
};

const Stage: React.FC<StageProps> = ({ index, active, includeNumbers, text }) => {
  const stage_wrapper = index === 0 ? "stage-wrapper-first" : "stage-wrapper";
  const stage_color = index === active ? "stage-color-active" : "stage-color";
  const stage_text = index === active ? "stage-text-active" : "stage-text";
  const stage_text_content = includeNumbers ? (index + 1).toString() + ". " + text : text;

  return (
    <div className={styles[stage_wrapper]}>
      <div className={styles[stage_color]}></div>
      <div className={styles[stage_text]}>{stage_text_content}</div>
    </div>
  );
};

type ProgressBarProps = {
  stages: string[];
  active: number;
  includeNumbers?: boolean;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ stages, active, ...props }) => {
  return (
    <div className={styles["progress-bar-wrapper"]}>
      {stages.map((stage, index) => {
        return <Stage key={index} index={index} {...props} text={stage} active={active} />;
      })}
    </div>
  );
};

export default ProgressBar;
