import { File, MiterAPI } from "dashboard/miter";
import React, { useState } from "react";
import { Loader } from "ui";

import fileIcon from "dashboard/assets/file.png";
import Notifier from "dashboard/utils/notifier";
import { DateTime } from "luxon";
import { AggregatedDailyReport } from "dashboard/miter";

const FileEntry: React.FC<{ file: File }> = ({ file }) => {
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async () => {
    setDownloading(true);
    try {
      const filter = [{ field: "_id", value: file._id }];
      const response = await MiterAPI.files.get_urls({ filter });
      if (response.error) throw new Error(response.error);
      window.open(response.urls[0]?.value.url);
      Notifier.success("File downloaded.");
    } catch (e) {
      Notifier.error("There was an error downloading the file.");
    }
    setDownloading(false);
  };

  return (
    <div
      onClick={downloadFile}
      style={{ height: 30, display: "flex", cursor: "pointer", alignItems: "center" }}
    >
      <img src={fileIcon} style={{ height: 15 }} />
      <span style={{ fontSize: 14, marginLeft: 10, color: downloading ? "blue" : undefined }}>
        {file.label || file.originalname}
      </span>
    </div>
  );
};

type Props = {
  files: File[] | undefined;
  daily_reports?: AggregatedDailyReport[];
  loading: boolean;
};

export const Attachments: React.FC<Props> = ({ files, daily_reports, loading }) => {
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {files?.map((file) => {
            return <FileEntry key={file._id} file={file} />;
          })}
          {daily_reports?.map((daily_report) => {
            return (
              <a
                style={{
                  fontSize: 14,
                  color: "#4d55bb",
                  marginTop: 0,
                  marginBottom: 10,
                  background: "#fff",
                  padding: "15px",
                  borderRadius: 4,
                  border: "1px solid #e4e4e4",
                  display: "block",
                }}
                target="_blank"
                href={`/jobs/${daily_report.job._id}/daily-reports?date=${daily_report.start_date}`}
                rel="noreferrer"
              >
                <strong>Daily Report {DateTime.fromISO(daily_report.start_date).toFormat("DD")}</strong>
                <br /> {daily_report.job.name} - {daily_report.creator.full_name}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};
