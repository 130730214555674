import { usePerformanceReviewAbilities } from "dashboard/hooks/abilities-hooks/usePerformanceReviewAbilities";
import { useTeamOptions } from "dashboard/hooks/atom-hooks";
import { MiterAPI, PerformanceReviewCycle } from "dashboard/miter";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BasicModal, Formblock, Notifier } from "ui";

type Props = {
  onExit: () => void;
  refresh: () => void;
  performanceReviewCycle: PerformanceReviewCycle;
};

export const CreatePerformanceReviewModal: React.FC<Props> = ({
  performanceReviewCycle,
  onExit,
  refresh,
}) => {
  const form = useForm<{ team_member_id: string }>();
  const performanceReviewAbilities = usePerformanceReviewAbilities();
  const teamMemberOptions = useTeamOptions({ predicate: performanceReviewAbilities.teamPredicate("create") });

  const { control, handleSubmit } = form;
  const [loading, setLoading] = useState(false);

  const createPerformanceReview = async (data) => {
    try {
      const res = await MiterAPI.performance_reviews.create({
        reviewee_id: data.team_member_id?.value,
        review_cycle_id: performanceReviewCycle._id,
        company_id: performanceReviewCycle.company_id,
        timezone: DateTime.local().zoneName,
      });

      if (res.error) {
        throw new Error(res.error);
      }
      Notifier.success("Performance review created!");
      onExit();
      refresh();
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an error creating the performance review. We're looking into it.");
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    await handleSubmit(createPerformanceReview)();
    setLoading(false);
  };

  return (
    <BasicModal
      loading={loading}
      button2Text="Add"
      onHide={onExit}
      button2Action={onSubmit}
      headerText="Add review"
    >
      <Formblock
        name="team_member_id"
        type="select"
        control={control}
        form={form}
        requiredSelect={true}
        label="Reviewee*"
        editing={true}
        className="modal wizard"
        options={teamMemberOptions}
        height="unset"
        placeholder="Team member to review"
        defaultValue={null}
      />
    </BasicModal>
  );
};
