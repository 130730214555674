import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import BenefitsAdminLander from "./BenefitsAdminLander";
import { Loader, Notifier } from "ui";
import { MiterAPI } from "dashboard/miter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";
import { ClaspReact } from "clasp-components";
import axios from "axios";

const BenefitsAdmin = (): JSX.Element | null => {
  const company = useActiveCompany();
  const [componentUrl, setComponentUrl] = useState<string | null>(null);
  const isActive = useMemo(() => isActiveClaspCompany(company), [company]);

  const getComponentUrl = useCallback(async () => {
    if (!isActive || !company) return;
    try {
      const response = await MiterAPI.clasp.get_employer_component_url(company._id);
      if (response.error) throw new Error(response.error);
      setComponentUrl(response.url);
    } catch (e) {
      console.log("Clasp error: ", e);
      Notifier.error(
        "Failed to authenticate for Miter benefits administration. Please try again or reach out to support if the issue persists."
      );
    }
  }, [isActive, company]);

  useEffect(() => {
    getComponentUrl();
  }, [isActive, getComponentUrl]);

  const onError = useCallback(
    async (error) => {
      if (axios.isAxiosError(error) && error.response?.status !== 403) {
        console.error("Clasp component error: ", error.response?.data);
        await getComponentUrl();
      }
    },
    [getComponentUrl]
  );

  if (!isActive) {
    return <BenefitsAdminLander status={company?.integrations?.clasp?.clasp_employer?.status} />;
  } else if (!componentUrl) {
    return <Loader />;
  }

  return (
    <>
      <div className="vertical-spacer" />
      <ClaspReact className="height-100" link={componentUrl} onError={onError} />
    </>
  );
};

export default BenefitsAdmin;
