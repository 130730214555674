import { AggregatedPayroll, MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import React, { useContext, useMemo, useState } from "react";
import { ActionModal, Input, Notifier } from "ui";
import { isNotValidBankDay } from "miter-utils/holidays";
import PayrollContext from "./viewPayroll/payrollContext";
import Select, { ValueType } from "react-select";
import { Option } from "ui/form/Input";
import { styles } from "ui/form/styles";

const ACH_REVERSAL_REASON_OPTIONS: Option<string>[] = [
  {
    label: "Duplicate payment",
    value: "Duplicate payment",
  },
  {
    label: "Incorrect payment amount",
    value: "Incorrect payment amount",
  },
  {
    label: "Incorrect recipient or account number",
    value: "Incorrect recipient or account number",
  },
];

type Props = {
  onHide: () => void;
  payroll: AggregatedPayroll;
  paymentIds: string[];
};

export const VoidPaymentsModal: React.FC<Props> = ({ onHide, payroll, paymentIds }) => {
  const { getPayroll } = useContext(PayrollContext);
  const [voidingPayments, setVoidingPayments] = useState<boolean>();
  const [achReversal, setAchReversal] = useState<boolean>();
  const [selectedAchReversalReason, setSelectedAchReversalReason] =
    useState<ValueType<Option<string>, false>>();

  const checkPayroll = payroll.check_payroll;
  const nowIso = DateTime.now().setZone("America/New_York").toISODate();

  // Need to know whether there was actually an ACH among the selected payments
  const manualPaymentsOnly = useMemo(() => {
    return paymentIds.every((id) => {
      const payment = payroll.miter_payments.find((mp) => mp._id === id);
      const checkItmCp = payment?.check_item || payment?.check_cp;
      if (!checkItmCp) return true; // If there's no Check item, then it can't be voided at all actually, not to mention ACH-reversed
      return checkItmCp.payment_method === "manual";
    });
  }, [payroll, paymentIds]);

  // ACH is only reversable if it was actually an ACH and it's been less than 5 banking days since the payment was made
  const achIsReversable = useMemo(() => {
    if (manualPaymentsOnly) return false;

    let daysSincePayday = 0,
      dayDt = DateTime.fromISO(checkPayroll.payday, { zone: "America/New_York" });

    while (daysSincePayday < 5) {
      dayDt = dayDt.plus({ days: 1 });
      if (!isNotValidBankDay(dayDt, true)) {
        daysSincePayday += 1;
      }
    }
    return dayDt.toISODate() > nowIso;
  }, [checkPayroll.payday, nowIso, manualPaymentsOnly]);

  const voidPayments = async () => {
    const achReversalReason = selectedAchReversalReason?.value;
    if (achReversal && !achReversalReason) {
      Notifier.error("Please select a reversal reason");
      return;
    }

    const companyId = payroll.company._id;
    setVoidingPayments(true);
    try {
      const res = await MiterAPI.payrolls.void_payments(payroll._id, paymentIds, companyId, {
        achReversal,
        achReversalReason,
      });
      if (res.error) throw new Error(res.error);
      await getPayroll();
      onHide();
      Notifier.success("Void payment request sent");
    } catch (err: $TSFixMe) {
      console.log("Failed to void payments:", err);
      Notifier.error(err.message);
    }
    setVoidingPayments(false);
  };

  return (
    <ActionModal
      onHide={onHide}
      onSubmit={voidPayments}
      headerText="Void Request"
      loading={voidingPayments}
      showCancel={true}
      showSubmit={true}
      onCancel={onHide}
      bodyStyle={{ minHeight: "unset" }}
    >
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <span>
          Are you sure you want to void the selected payment(s)?{" "}
          <strong>This should only be done if the values of the payment(s) are incorrect in some way.</strong>{" "}
          All associated wages, taxes, benefit contributions, and post-tax deductions will be negated.
          <div className="vertical-spacer" />
          {!achIsReversable && !manualPaymentsOnly && (
            <>
              <span>
                Miter will <strong>NOT</strong> execute an ACH reversal of net pay. It is your responsibility
                to collect net pay from the team member if that is your intent.
              </span>
              <div className="vertical-spacer" />
            </>
          )}
        </span>

        <span>
          <strong>This action cannot be undone.</strong>
        </span>

        <div className="vertical-spacer" />

        {achIsReversable && (
          <>
            <span>
              As part of this void, do you want to attempt to reverse the ACH for the payment(s)? We cannot
              guarantee that a reversal will succeed. If it is not successful,{" "}
              <strong>it is your responsibility to collect net pay from the team member.</strong>
            </span>
            <div className="vertical-spacer-small" />
            <Input
              name="achReversal"
              type="checkbox"
              text="Yes - attempt an ACH reversal"
              className="modal"
              defaultValue={achReversal}
              onChange={(e) => setAchReversal(e.target.checked)}
            />

            <div className="vertical-spacer-small" />

            {achReversal && (
              <>
                <div className="yellow-text-container">
                  The NACHA only permits ACH payment reversals in specific circumstances. For compliance,
                  please select from one of the options below.
                </div>

                <div className="vertical-spacer-medium" />

                <Select
                  name="achReversalReason"
                  options={ACH_REVERSAL_REASON_OPTIONS}
                  maxMenuHeight={175}
                  value={selectedAchReversalReason}
                  menuPortalTarget={document.body}
                  width="100%"
                  height="32px"
                  styles={styles}
                  onChange={(o: ValueType<Option<string>, false>) => setSelectedAchReversalReason(o)}
                />
              </>
            )}
          </>
        )}
      </div>
    </ActionModal>
  );
};
