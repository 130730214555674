import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { PayrollSettings } from "../settings/payrollSettings/PayrollSettings";

export const PayrollSettingsPage: React.FC = () => {
  const navigate = useNavigate();

  const goBackToPayrolls = () => {
    navigate("/payrolls");
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Payroll settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={goBackToPayrolls}>
          Back to payrolls
        </div>
        <div className="flex">
          <h1>Payroll settings</h1>
          <div className="flex-1"></div>
        </div>
        <div className="vertical-spacer-small"></div>
        <PayrollSettings />
      </div>
    </div>
  );
};
