import React from "react";
import TimesheetContext from "./timesheetContext";
import { AuditLogHistory } from "dashboard/components/audit-logs/AuditLogHistory";

export const TimesheetAuditLog: React.FC = () => {
  const { timesheet } = React.useContext(TimesheetContext);

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Audit Log</div>
      </div>
      {timesheet && (
        <div style={{ marginBottom: 50, marginTop: 25 }}>
          <AuditLogHistory itemId={timesheet._id} type="timesheet" fieldsStyle={{ background: "#f7f8fd" }} />
        </div>
      )}
    </div>
  );
};
