import React from "react";
import { AggregatedFileWithUrl } from "./Document";
import { DateTime } from "luxon";
import { useLookupCompanyUsers, useLookupFillableTemplates, useLookupTeam } from "dashboard/hooks/atom-hooks";
import styles from "./Documents.module.css";
import DataBox from "dashboard/components/dataBox/DataBox";
import { DataBoxRow } from "dashboard/components/dataBox/DataBoxRow";
import { Line } from "rc-progress";
import { Badge } from "ui";

type Props = {
  file: AggregatedFileWithUrl;
};

const colors = {
  company: "orange",
  team_member: "blue",
  fillable_doc: "green",
};
const typeElement = (file: AggregatedFileWithUrl) => {
  const types: string[] = [];
  if (file.parent_type === "company") {
    types.push("company");
  }
  if (file.parent_type === "team_member") {
    types.push("team_member");
  }
  if (!!file.fill_status) {
    types.push("fillable_doc");
  }
  return (
    <div style={{ display: "flex" }}>
      {types.map((type) => (
        <Badge
          key={type}
          text={type}
          textColor="rgb(44, 44, 44)"
          color={colors[type]}
          style={{ marginRight: -2 }}
        />
      ))}
    </div>
  );
};

export const DocumentDetails: React.FC<Props> = ({ file }) => {
  const lookupCompanyUser = useLookupCompanyUsers();
  const lookupTeamMember = useLookupTeam();
  const lookupFillableTemplate = useLookupFillableTemplates();

  const isFillableDocument = !!file.fill_status;
  const isCompletedDocument = file.fill_status === "complete" || !isFillableDocument;
  const createdAt = DateTime.fromSeconds(file.created_at).toLocaleString(DateTime.DATETIME_MED);
  const updatedAt = DateTime.fromSeconds(file.updated_at).toLocaleString(DateTime.DATETIME_MED);
  const createdBy = lookupCompanyUser(file.uploader_user_id)?.email || "Deleted user";

  // Create a file size string from the file size in bytes
  const fileSize = file.size ? file.size : 0;
  const fileSizeString =
    fileSize >= 1000000 ? `${Math.round(fileSize / 1000000)} MB` : `${Math.round(fileSize / 1000)} KB`;

  const expiryDate = file.expires_at ? DateTime.fromISO(file.expires_at).toFormat("EEE, DD") : "No expiry";

  const documentOwner = lookupTeamMember(file.parent_id);

  const progressMetrics = () => {
    const defaultReturnValue = {
      percent: -1,
      totalRequired: -1,
      answered: -1,
    };
    const fillableTemplate = lookupFillableTemplate(file.fillable_template_id);
    if (!fillableTemplate) return defaultReturnValue;
    const requiredFields = fillableTemplate.inputs.filter((input) => input?.validations?.required);
    if (!requiredFields.length) return defaultReturnValue;
    const answeredAndRequired = requiredFields.filter((field) =>
      (file?.answers || []).some((answer) => answer.form_field_id === field._id)
    );
    const percent = Math.round((answeredAndRequired.length / requiredFields.length) * 100);
    return {
      percent,
      totalRequired: requiredFields.length,
      answered: answeredAndRequired.length,
    };
  };

  const { percent, totalRequired, answered } = progressMetrics();

  return (
    <div className={styles["details-section"]}>
      <DataBox title={"General"}>
        <DataBoxRow label="Document type" value={typeElement(file)} />
        {documentOwner ? <DataBoxRow label="Document owner" value={documentOwner.full_name} /> : null}
        {!isFillableDocument ? <DataBoxRow label="Expiry date" value={expiryDate} /> : null}
        {!isFillableDocument ? (
          <DataBoxRow
            label="Auto-send esignature requests"
            value={file.autosend_esignature_request ? "Yes" : "No"}
          />
        ) : null}
        <DataBoxRow label="Sensitive document" value={file.sensitive ? "Yes" : "No"} />
      </DataBox>
      <DataBox title={"Other info"}>
        <DataBoxRow label="Created at" value={createdAt} />
        <DataBoxRow label="Updated at" value={updatedAt} />
        <DataBoxRow label={`${isFillableDocument ? "Requested" : "Created"} by`} value={createdBy} />
        {isCompletedDocument ? <DataBoxRow label="File size" value={fileSizeString} /> : null}
        <DataBoxRow label="File type" value={file.type || "Unknown"} />
        {isFillableDocument && percent !== -1 ? (
          <DataBoxRow
            label="Document progress"
            value={
              <div
                style={{
                  display: "flex",
                }}
              >
                <div>
                  <Line
                    style={{ width: 125, marginRight: 5, opacity: 0.9 }}
                    percent={percent}
                    trailWidth={3}
                    strokeWidth={6}
                    strokeColor="#4d54b6"
                    trailColor="#f0f1f5"
                  />
                </div>
                <div>{`${answered}/${totalRequired} inputs filled`}</div>
              </div>
            }
          />
        ) : null}
      </DataBox>
    </div>
  );
};
