import { useEffect, useState } from "react";
import { MiterAPI, Company } from "dashboard/miter";

export const useFetchCompanyLogoUrl = (activeCompany: Company | null | undefined): string | undefined => {
  const [companyLogoUrl, setCompanyLogoUrl] = useState<string | undefined>();

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (!activeCompany?.logo_file_id || !activeCompany.settings?.show_company_logo) {
        setCompanyLogoUrl(undefined);
        return;
      }

      try {
        const logoResponse = await MiterAPI.files.get_urls({
          filter: [{ field: "_id", value: activeCompany.logo_file_id, type: "_id" }],
        });

        if (!logoResponse.error) {
          setCompanyLogoUrl(logoResponse.urls.length > 0 ? logoResponse.urls[0]!.value.url : undefined);
        }
      } catch (error) {
        console.error("Error fetching company logo:", error);
      }
    };

    fetchLogoUrl();
  }, [activeCompany?.logo_file_id, activeCompany?.settings?.show_company_logo]);

  return companyLogoUrl;
};
