import AppContext from "dashboard/contexts/app-context";
import React, { useContext } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompanyId, useActiveCompany } from "dashboard/hooks/atom-hooks";
import { ConfirmModal, SettingsCard } from "ui";

export const WorkHoursInYear: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const { fetchUserData } = useContext(AppContext);

  const defaultValue = activeCompany?.settings.payroll.work_hours_in_year;
  const [value, setValue] = React.useState(defaultValue);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);

  const handleToggle = async () => {
    if (!activeCompanyId) return;
    // Right now, these are the only two options, so we can just toggle between them.
    const newValue = value === 2087 ? 2080 : 2087;
    try {
      const response = await MiterAPI.companies.update(activeCompanyId, {
        "settings.payroll.work_hours_in_year": newValue,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Payroll settings updated successfully.");
      fetchUserData();
      setValue(newValue);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
    setShowConfirmationModal(false);
  };

  return (
    <>
      {showConfirmationModal && (
        <ConfirmModal
          body={
            "This is a far-reaching change that will affect how Miter calculates \
            many aspects of your payroll. Generally it is best to make this \
            change right before the start of a new year."
          }
          onYes={handleToggle}
          onNo={() => setShowConfirmationModal(false)}
          yellowBodyText
        ></ConfirmModal>
      )}
      <SettingsCard
        title="Work hours in year"
        info="Baseline number used when calculating work hours and related work weeks per year."
        contentStyle={{ color: "rgb(51,51,51)" }}
      >
        <div className="flex">
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => setShowConfirmationModal(true)}
            checked={value === 2087}
          />
          <label>
            <span className="bold">2087</span>:
            <span>
              {` Miter should use the `}
              <a href="https://www.opm.gov/policy-data-oversight/pay-leave/pay-administration/fact-sheets/computing-hourly-rates-of-pay-using-the-2087-hour-divisor/">
                U.S. Office of Personnel Management
              </a>
              {` recommendation of 2087 work hours per year.`}
            </span>
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => setShowConfirmationModal(true)}
            checked={value === 2080}
          />
          <label>
            <span className="bold">2080</span>:
            <span>{` Miter should use 2080 hours per year (52 weeks times 40 hours per week).`}</span>
          </label>
        </div>
      </SettingsCard>
    </>
  );
};
