import InfoButton from "dashboard/components/information/information";
import { TimesheetSettings } from "./TimesheetSettings";
import { Input } from "@material-ui/core";

interface DefaultClockInTimeSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newSettings: Partial<TimesheetSettings>) => void;
}

const DefaultClockInTimeSettings: React.FC<DefaultClockInTimeSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Default clock-in time</div>
        <InfoButton text="Control the default clock-in time within the dashboard (not the mobile app)" />
      </div>

      <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
        <div className="vertical-spacer-small"></div>

        <div className="flex">
          <span>
            The default clock-in time when creating timesheets in the admin dashboard should be:&nbsp;&nbsp;
          </span>
          <div style={{ width: 120 }}>
            <Input
              type="time"
              defaultValue={timesheetSettings.default_clock_in_time || "09:00"}
              onChange={(e) => handleChange({ default_clock_in_time: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultClockInTimeSettings;
