import { useLocation, useNavigate } from "react-router-dom";
import { useSetAuthenticatedUserData, useSetUserFetched } from "./atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "ui";
import { authTokenAtom, defaultAtomStore } from "dashboard/atoms";

export const useFetchUserData = (): (() => Promise<void>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const setAuthenticatedUserData = useSetAuthenticatedUserData();
  const setUserFetched = useSetUserFetched();

  return async () => {
    // Make sure this is always the latest & greatest that will actually be used in the below query
    const authToken = defaultAtomStore.get(authTokenAtom);
    if (!authToken) return;

    let unauthorized = false;
    try {
      const res = await MiterAPI.sessions.current();
      setUserFetched(true);
      if (res.error) {
        if (res.error_status === 401) unauthorized = true;
        throw new Error(res.error);
      }

      setAuthenticatedUserData(res);

      // If this is the login or authenticate page, redirect to the dashboard
      if (location.pathname === "/login" || location.pathname === "/authenticate") {
        Notifier.success("You are already logged in.");
        navigate("/home", { replace: true });
      }
    } catch (e: $TSFixMe) {
      console.log("Login error:", e);
      Notifier.error(e.message);
      // Only clear the auth data if we're not in development mode, so that we can easily hot reload, get a Failed to fetch, and not auto-logout
      if (unauthorized) {
        setAuthenticatedUserData(null);
      }
      navigate("/login", { replace: true });
    }
  };
};
