import React from "react";
import { Formblock } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { DeepPartial } from "utility-types";

import { useForm } from "react-hook-form";
import { SettingsCard } from "ui/settings/SettingsCard";

import { BambooConnectionMetadata } from "backend/services/bamboo/bamboo-types";

const INPUTLENGTH = 250;

export const BambooConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const bambooMetadata: BambooConnectionMetadata =
    integration.connection?.metadata?.bamboo || ({} as BambooConnectionMetadata);

  const updateBambooMetadata = async (metadataUpdate: DeepPartial<BambooConnectionMetadata>) => {
    const raw = { metadata: { bamboo: metadataUpdate } };

    await updateIntegrationConnection(buildAtomicMongoUpdateFromNested(raw, { collapseCount: 2 }));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Team Member">
        <Formblock
          form={form}
          name="enrollEmployeesInPayroll"
          type="checkbox"
          label="Auto enroll Team Member to Payroll"
          labelInfo="Select this if you want Miter to automatically enroll a synced Employee from Bamboo into Miter Payroll"
          onChange={(e) => updateBambooMetadata({ enrollEmployeesInPayroll: e.target.checked })}
          defaultValue={bambooMetadata.enrollEmployeesInPayroll}
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
          editing={true}
        />
        <Formblock
          form={form}
          name="pullEmploymentHistory"
          type="checkbox"
          label="Do not pull employment history"
          labelInfo="When pulling team members, do not pull employment History from Bamboo."
          onChange={(e) => updateBambooMetadata({ dontPullEmploymentHistory: e.target.checked })}
          defaultValue={bambooMetadata.dontPullEmploymentHistory}
          inputProps={{ style: { width: INPUTLENGTH } }}
          underlineTooltip={true}
          editing={true}
        />
      </SettingsCard>
    </div>
  );
};
