import React, { useState } from "react";
import { UseFormMethods, FieldValues } from "react-hook-form";
import { Formblock } from "ui";
import styles from "./AddOverride.module.css";

type Props = {
  form: UseFormMethods<FieldValues>;
  companyNameOverride: string | undefined;
  setCompanyNameOverride: (value: string | undefined) => void;
};

export const AddCompanyNameOverride: React.FC<Props> = ({
  form,
  companyNameOverride,
  setCompanyNameOverride,
}) => {
  const [showField, setShowField] = useState(false);

  return (
    <div>
      {showField ? (
        <div>
          <div className={styles.verticalSpacerSmall}></div>
          <div className={styles.flex}>
            <div className={styles.bold}>Add custom company name</div>
            <div className={styles.flex1}></div>
            <div
              className={`${styles.blueLink} ${styles.fontSize14}`}
              onClick={() => {
                setShowField(false);
                setCompanyNameOverride(undefined);
              }}
            >
              Clear
            </div>
          </div>
          <div className={styles.verticalSpacerSmall}></div>
          <Formblock
            type="text"
            label="Other company name"
            control={form.control}
            name="otherCompanyName"
            errors={form.errors}
            editing={true}
            height="auto"
            register={form.register}
            defaultValue={companyNameOverride || ""}
            onChange={(e) => {
              setCompanyNameOverride(e.target.value || undefined);
            }}
          />
        </div>
      ) : (
        <div
          className={`${styles.blueLink} ${styles.marginTop10} ${styles.fontSize15}`}
          onClick={() => setShowField(true)}
        >
          + Add custom company name
        </div>
      )}
    </div>
  );
};
