import React from "react";

import PaymentContext from "./paymentContext";
import ReviewTimeOff from "../ReviewTimeOff";
import InfoButton from "dashboard/components/information/information";

const PaymentModalTimeOff: React.FC = () => {
  const { payroll, tm, editing } = React.useContext(PaymentContext);

  return (
    <div>
      <div className="payment-active-view-header">
        Time Off
        <InfoButton text="Showing all time off requests within the pay period. Only approved requests will be included in the payroll." />
        <div className="flex-1"></div>
      </div>
      <div style={{ marginTop: -20 }}>
        <ReviewTimeOff
          viewOnly={!editing || payroll?.check_payroll.status !== "draft"}
          tm={tm}
          emptyHeader="This payment has no time off requests."
        />
      </div>
    </div>
  );
};

export default PaymentModalTimeOff;
