import React from "react";
import { cloneDeep, set } from "lodash";
import { Formblock } from "ui";
import { FieldRequirementsForm, RuleBuilderSection } from "./PolicyRuleBuilder";
import { Option } from "ui/form/Input";

import styles from "./PolicyRules.module.css";
import { EnforceFieldOptions, getApprovalItemType } from "./PolicyConstants";
import { PolicyType } from "backend/services/approvals/types";

type Props = {
  policyType: PolicyType;
  fields: FieldRequirementsForm;
  setFields: React.Dispatch<React.SetStateAction<FieldRequirementsForm>>;
  fieldOptions: Option<string>[];
};

export const FieldRequirementsBuilder: React.FC<Props> = ({
  policyType,
  fields,
  setFields,
  fieldOptions,
}) => {
  const itemType = getApprovalItemType(policyType);
  const hasFields = Object.keys(fields).length > 0;

  const handleUpdateField = (field: string, value: string) => {
    setFields((prev) => {
      const newFields = cloneDeep(prev);
      if (newFields) set(newFields, field, value);
      return newFields;
    });
  };

  const renderFields = () => {
    return fields?.map((field, index) => {
      return (
        <div
          className={"flex align-items-center with-100-percent " + styles["rule-field"]}
          key={field.field?.label ?? "" + index}
        >
          <Formblock
            type="select"
            name={`${index}.field`}
            editing={true}
            className="modal approval-flow"
            placeholder={"Field"}
            rules={{ required: "Field is required" }}
            options={fieldOptions}
            style={{ marginRight: 15, width: 250 }}
            onChange={(o) => handleUpdateField(`[${index}].field`, o)}
            value={fields?.[index]?.field}
            disabled={true}
          />
          <Formblock
            type="select"
            name={`${index}.value`}
            editing={true}
            className="modal approval-flow"
            placeholder={"Option"}
            rules={{ required: "Option is required" }}
            options={EnforceFieldOptions}
            style={{ marginRight: 15, width: 250 }}
            onChange={(o) => handleUpdateField(`[${index}].value`, o)}
            value={fields?.[index]?.value}
          />
        </div>
      );
    });
  };

  const renderForm = () => {
    return (
      <div className={styles["rule-builder-light-grey-section"]}>
        <div className={styles["rule-builder-fields"]}>{renderFields()}</div>
      </div>
    );
  };

  return (
    <RuleBuilderSection
      title={"Enforce these field settings"}
      subtitle={`Specify which fields should be required or hidden on the ${itemType}`}
    >
      {hasFields && renderForm()}
    </RuleBuilderSection>
  );
};
