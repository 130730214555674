import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import { Class, MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useAuditLogHistory } from "../audit-logs/useAuditLogHistory";
import { ClassStatusEnum } from "backend/models/classes/class";
import { Option } from "ui/form/Input";
import AppContext from "dashboard/contexts/app-context";
import Banner from "../shared/Banner";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type ClassForm = {
  name: string;
  code: string;
  status: Option<ClassStatusEnum>;
};

type Props = {
  selectedClass?: Class;
  onFinish: () => void;
  onHide: () => void;
};

export const ClassModal: React.FC<Props> = ({ selectedClass, onFinish, onHide }) => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { integrations } = useContext(AppContext);
  const { can, cannot } = useMiterAbilities();
  const form = useForm<ClassForm>();
  const { renderAuditLogHistoryButton, renderAuditLogHistoryModal } = useAuditLogHistory({
    itemId: selectedClass?._id || "",
    itemType: "class",
    refreshCounter: 0,
    show: false,
  });

  // State
  const [loading, setLoading] = useState(false);

  const sourceSystem = useMemo(() => {
    let system: MiterIntegrationForCompany | undefined;
    if (selectedClass?.integrations) {
      const key = Object.keys(selectedClass.integrations)[0];
      if (key) system = integrations.find((i) => i.key === key);
    }
    return system;
  }, [selectedClass, integrations]);

  const cleanParams = (data: ClassForm) => {
    return {
      ...data,
      status: data.status.value,
      company_id: activeCompanyId!,
    };
  };

  const createClass = async (data: ClassForm) => {
    // TODO: add permission check
    if (!activeCompanyId || selectedClass) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.classes.create(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Class created.");
      onFinish();
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error creating class:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateClass = async (data: ClassForm) => {
    // TODO: add permission check
    if (!activeCompanyId || !selectedClass) return;

    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.classes.update(selectedClass._id, params);
      if (res.error) throw new Error(res.error);
      onFinish();
      onHide();
      Notifier.success("Class updated.");
    } catch (e: $TSFixMe) {
      console.error("Error updating Class:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={`${selectedClass ? "Update" : "Create"} class`}
      showSubmit={can("lists:classes:manage")}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={form.handleSubmit(selectedClass ? updateClass : createClass)}
      loading={loading}
    >
      {sourceSystem && (
        <Banner
          style={{ borderBottom: "1px solid #d6d6d6", marginRight: -20, marginLeft: -20 }}
          content=""
          type="modal"
        >
          <span className="flex">
            {`Note: this class was imported from ${sourceSystem.label}. Certain fields won't be
        editable.`}
          </span>
        </Banner>
      )}
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          form={form}
          val={vals.required}
          editing={true}
          defaultValue={selectedClass?.name}
          className="modal"
          disabled={!!sourceSystem || cannot("lists:classes:manage")}
        />
        <Formblock
          type="text"
          name="code"
          label="ID*"
          form={form}
          val={vals.required}
          editing={true}
          defaultValue={selectedClass?.code}
          className="modal"
          disabled={!!sourceSystem || cannot("lists:classes:manage")}
        />
        <Formblock
          label={"Status*"}
          labelInfo={"Inactive classes will be disabled in dropdowns."}
          type="select"
          name="status"
          options={[
            { label: "Active", value: "active" as ClassStatusEnum },
            { label: "Inactive", value: "inactive" as ClassStatusEnum },
          ]}
          className="modal"
          defaultValue={selectedClass?.status}
          requiredSelect={true}
          editing={true}
          form={form}
          errors={form.errors}
          disabled={!!sourceSystem || cannot("lists:classes:manage")}
        />
        {!!selectedClass && renderAuditLogHistoryButton()}
        {renderAuditLogHistoryModal()}
      </div>
    </ActionModal>
  );
};
