import React, { useMemo, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Formblock, Input, Label, Notifier, usdString } from "ui";
import * as vals from "dashboard/utils/validators";
import { ModalHeader } from "ui";
import { ModalFooter } from "ui";
import { AggregatedTeamMember } from "dashboard/miter";
import { UseFormMethods } from "react-hook-form";
import { TeamMember } from "backend/models";
import { CheckEmployee } from "backend/utils/check/check-types";
import { Option } from "ui/form/Input";
import { convertAnnualRateToDisplayRate } from "../TeamUtils";
import { convertDisplayRateToAnnualRate } from "../TeamUtils";
import { booleanOptions } from "dashboard/utils";
import {
  useHolidayScheduleOptions,
  useOtRuleOptions,
  usePaySchedules,
  usePrgOptions,
  useClassificationOptions,
  useLookupRateClassification,
  useActiveCompany,
  usePayScheduleOptions,
  useRateDifferentialOptions,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { isEqual } from "lodash";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";
import { TMPayType } from "backend/models/teamMember/team-member";

type Props = {
  defaultData: AggregatedTeamMember;
  form: UseFormMethods;
  onHide: () => void;
  onSubmit: (data: $TSFixMe, scheduleChanges: boolean) => Promise<void>;
  submitting: boolean;
  submitText?: string;
};

export const TeamMemberPayForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  form,
  submitText,
}) => {
  const activeCompany = useActiveCompany();
  const paySchedules = usePaySchedules();
  const { register, control, errors, handleSubmit } = form;
  const lookupRateClassification = useLookupRateClassification();
  const payScheduleOptions = usePayScheduleOptions();
  const rateDifferentialOptions = useRateDifferentialOptions();
  const { cannot } = useMiterAbilities();
  const { otExemptionIsRelevant } = useOtExemptUtils();

  const [payRate, setPayRate] = useState<number>(() =>
    convertAnnualRateToDisplayRate(
      activeCompany,
      defaultData.pay_type,
      defaultData.salary_rate_display,
      defaultData.pay_rate
    )
  );
  const [salaryType, setSalaryType] = useState<string | null | undefined>(defaultData.salary_rate_display);

  const [selectedRateDiffOption, setSelectedRateDiffOption] = useState<Option<string> | null>(
    rateDifferentialOptions.find((o) => o.value === defaultData.rate_differential_id) || null
  );

  const showOtRuleField = defaultData.employment_type === "employee" && !defaultData.overtime_exempt;

  const prgOptions = usePrgOptions();
  const [selectedPrgOption, setSelectedPrgOption] = useState<Option<string> | undefined>(() =>
    prgOptions.find((o) => o.value === defaultData.union_rate?.pay_rate_group)
  );

  const classificationOptions = useClassificationOptions({ prgId: selectedPrgOption?.value });
  const [selectedClassificationOption, setSelectedClassificationOption] = useState<
    Option<string> | undefined | null
  >(() => classificationOptions.find((o) => o.value === defaultData.union_rate?._id));

  const selectedClassification = lookupRateClassification(selectedClassificationOption?.value);

  // When the pay rate group changes, reset the classification options and the selected classification
  const handlePrgChange = (newPrgOption: Option<string>) => {
    setSelectedPrgOption(newPrgOption);
    setSelectedClassificationOption(null);
  };

  const cleanDataForUpdate = (data, scheduleChanges: boolean) => {
    const newPayType =
      selectedPayTypeOption?.value === "union_rate" ? "hourly" : selectedPayTypeOption?.value;

    if (selectedPayTypeOption?.value === "union_rate" && selectedPrgOption && !selectedClassification) {
      Notifier.error("Please select classification");
      return;
    }

    const update: Partial<TeamMember & CheckEmployee> = {
      pay_type: newPayType,
      union_rate: selectedClassificationOption?.value || null,
      overtime_exempt: overtimeExempt.value === "true",
      default_ot_rule_id: selectedOtRule?.value || null,
      pay_schedule_id: selectedPayScheduleOption?.value,
      salary_rate_display: data.salary_type?.value || "year",
      rate_differential_id: !!selectedClassificationOption?.value
        ? selectedRateDiffOption?.value || null
        : null, // For now, rate differentials only apply for union employees
      pay_rate:
        newPayType === "salary"
          ? convertDisplayRateToAnnualRate(
              activeCompany,
              data.salary_type?.value,
              data.pay_rate || defaultData.pay_rate
            )
          : Number(data.pay_rate || defaultData.pay_rate),
      holiday_schedule_id: data.holiday_schedule_id?.value || null,
    };

    // check if changes have been made
    const { union_rate, ...updateWithoutUnion } = update;
    const defaultUpdateValues = Object.fromEntries(
      Object.entries(defaultData).filter(([key]) => updateWithoutUnion.hasOwnProperty(key))
    );
    const updateUnionRate = union_rate ? union_rate : undefined;
    if (updateUnionRate === defaultData.union_rate?._id && isEqual(defaultUpdateValues, updateWithoutUnion)) {
      Notifier.error("No changes detected");
      return;
    }

    console.log;

    if (update.pay_rate && !update.pay_type) {
      form.setError("pay_type", { message: "This field is required if you enter a pay rate." });
      return;
    }

    onSubmit(update, scheduleChanges);
  };

  const renderPayRate = () => {
    if (selectedPayTypeOption?.value === "hourly") {
      return (
        <Formblock
          type="unit"
          unit="$"
          name="pay_rate"
          label={"Hourly rate"}
          className="modal small-margin"
          control={control}
          register={register(vals.dollar)}
          defaultValue={Number((payRate / 1.0).toFixed(2))}
          onChange={(e) => setPayRate(e.target.value)}
          placeholder="0.00"
          editing={true}
          errors={errors}
          disabled={disabled}
        />
      );
    }
    return (
      <div className="formblock-wrapper modal">
        <Label label="Pay rate" className="modal" />
        <div className="input-wrapper flex modal">
          <Input
            type="unit"
            unit="$"
            register={register(vals.dollar)}
            defaultValue={payRate}
            control={control}
            placeholder={"0.00"}
            onChange={(e) => setPayRate(e.target.value)}
            name="pay_rate"
            errors={errors}
            className="modal"
            disabled={disabled}
          />
          <div style={{ width: 20 }}></div>
          <Input
            type="select"
            options={salaryTypeOptions}
            defaultValue={salaryType || "year"}
            register={register}
            control={control}
            name="salary_type"
            onChange={(e) => setSalaryType(e.value)}
            errors={errors}
            className="modal"
            disabled={disabled}
          />
        </div>
      </div>
    );
  };

  const rawOtRuleOptions = useOtRuleOptions();
  const companyDefaultRuleId = activeCompany?.settings.payroll.default_ot_rule_id;

  const otRuleOptions: Option<string>[] = useMemo(() => {
    const companyDefaultOtRule = rawOtRuleOptions.find((o) => o.value === companyDefaultRuleId);
    const defaultOptionLabel = companyDefaultOtRule
      ? `Company default (${companyDefaultOtRule.label})`
      : "Federal/state defaults";
    return [{ label: defaultOptionLabel, value: "" }].concat(rawOtRuleOptions);
  }, [rawOtRuleOptions, companyDefaultRuleId]);

  const [selectedOtRule, setSelectedOtRule] = useState<Option<string> | undefined>(() =>
    otRuleOptions.find((o) => o.value === (defaultData.default_ot_rule_id || ""))
  );

  const defaultOvertimeExempt = defaultData.overtime_exempt ? "true" : "false";
  const [overtimeExempt, setOvertimeExempt] = useState<Option<string>>(
    booleanOptions.find((o) => o.value === defaultOvertimeExempt)!
  );

  // Pay type handling
  const payTypeOptions: Option<TMPayType | "union_rate">[] = [
    { value: "hourly", label: prgOptions.length ? "Hourly (non-union)" : "Hourly" },
    ...(prgOptions.length || defaultData.union_rate
      ? [{ value: "union_rate" as const, label: "Hourly (union)" }]
      : []),
    { value: "salary", label: "Salary" },
  ];

  const defaultPayType = defaultData.union_rate ? "union_rate" : defaultData.pay_type;
  const defaultPayTypeOption = payTypeOptions.find((o) => o.value === defaultPayType);

  const [selectedPayTypeOption, setSelectedPayTypeOption] = useState(defaultPayTypeOption!);

  // Pay schedule handling
  const defaultPaySchedule = paySchedules.find((ps) => ps.default);
  const companyDefaultPayScheduleOption = payScheduleOptions.find(
    (ps) => ps.value === defaultPaySchedule?._id.toString()
  ) || { label: "Company default", value: "default" };

  const initialPayScheduleOption =
    payScheduleOptions.find((o) => o.value === defaultData.pay_schedule_id) ||
    companyDefaultPayScheduleOption;

  const [selectedPayScheduleOption, setSelectedPayScheduleOption] = useState(initialPayScheduleOption);

  const handlePayTypeChange = (option: Option<TMPayType | "union_rate">) => {
    if (option.value !== "union_rate") {
      setSelectedClassificationOption(null);
    }
    setSelectedPayTypeOption(option);
  };

  /* Holiday schedules */
  const holidayScheduleOptions = useHolidayScheduleOptions();
  const disabled = cannot("team:update_sensitive") && cannot("team:request_change");

  return (
    <ClickAwayListener onClickAway={() => {}}>
      <div className={`modal-wrapper form`}>
        <ModalHeader onHide={onHide} heading={"Edit " + defaultData.first_name + "'s pay & overtime"} />
        {defaultData && (
          <div className={`modal-body form`}>
            <div className="vertical-spacer"></div>
            <Formblock
              label="Pay type"
              type="select"
              name="pay_type"
              control={control}
              value={selectedPayTypeOption}
              onChange={handlePayTypeChange}
              className="modal small-margin"
              options={payTypeOptions}
              errors={errors}
              editing={true}
              disabled={disabled}
            />
            {selectedPayTypeOption?.value === "union_rate" ? (
              <>
                <Formblock
                  type="select"
                  name="pay_rate_group"
                  label="Pay rate group"
                  control={control}
                  options={prgOptions}
                  onChange={handlePrgChange}
                  value={selectedPrgOption}
                  defaultValue={defaultData.union_rate?.pay_rate_group}
                  requiredSelect={true}
                  editing={true}
                  className="modal small-margin"
                  errors={errors}
                  disabled={disabled}
                />
                {classificationOptions && (
                  <Formblock
                    type="select"
                    name="union_rate"
                    label="Classification"
                    control={control}
                    onChange={setSelectedClassificationOption}
                    options={classificationOptions}
                    value={selectedClassificationOption}
                    defaultValue={defaultData.union_rate?._id}
                    requiredSelect={true}
                    editing={true}
                    className="modal small-margin"
                    errors={errors}
                    disabled={disabled}
                  />
                )}
                {selectedClassificationOption?.value && (
                  <Formblock
                    type="text"
                    name="pay_rate"
                    label="Base hourly rate"
                    defaultValue={usdString(selectedClassification?.base_rate)}
                    value={usdString(selectedClassification?.base_rate)}
                    control={control}
                    editing={true}
                    className="modal small-margin"
                    errors={errors}
                    disabled={true}
                  />
                )}
                {!!rateDifferentialOptions.length && (
                  <Formblock
                    type="select"
                    name="rate_differential_id"
                    label="Overscale rate differential"
                    control={control}
                    onChange={setSelectedRateDiffOption}
                    options={rateDifferentialOptions}
                    value={selectedRateDiffOption}
                    isClearable={true}
                    editing={true}
                    className="modal small-margin"
                    errors={errors}
                    disabled={disabled}
                  />
                )}
              </>
            ) : (
              renderPayRate()
            )}
            {otExemptionIsRelevant(defaultData) && (
              <Formblock
                type="select"
                name="overtime_exempt"
                label="Overtime exempt"
                labelInfo="Exempt employees do not receive overtime pay"
                control={control}
                options={booleanOptions}
                onChange={setOvertimeExempt}
                value={overtimeExempt}
                editing={true}
                className="modal small-margin"
                errors={errors}
                disabled={disabled}
              />
            )}
            {showOtRuleField && overtimeExempt.value === "false" && (
              <Formblock
                type="select"
                name="default_ot_rule_id"
                label="Default overtime rule"
                labelInfo="How this team member's OT will be calculated, unless it is superseded by another rule."
                control={control}
                options={otRuleOptions}
                onChange={setSelectedOtRule}
                value={selectedOtRule}
                className="modal small-margin"
                editing={true}
                errors={errors}
                disabled={disabled}
              />
            )}
            <Formblock
              type="select"
              name="pay_schedule_id"
              label="Pay schedule"
              control={control}
              onChange={setSelectedPayScheduleOption}
              options={payScheduleOptions}
              value={selectedPayScheduleOption}
              editing={true}
              className="modal small-margin"
              errors={errors}
              defaultValue={initialPayScheduleOption.value}
              requiredSelect
              disabled={disabled}
            />
            {defaultData.employment_type === "employee" && (
              <Formblock
                type="select"
                name="holiday_schedule_id"
                label="Holiday schedule"
                control={control}
                options={holidayScheduleOptions}
                defaultValue={defaultData.holiday_schedule_id}
                editing={true}
                className="modal small-margin"
                errors={errors}
                isClearable
                disabled={disabled}
              />
            )}
            <div className="vertical-spacer"></div>
          </div>
        )}
        <ModalFooter
          loading={submitting}
          onCancel={onHide}
          cancelText={"Cancel"}
          onSubmit={handleSubmit((data) => cleanDataForUpdate(data, false))}
          submitText={submitText || "Submit"}
          className="form"
          showEdit={submitText !== "Request changes"}
          editText={"Schedule changes"}
          onEdit={handleSubmit((data) => cleanDataForUpdate(data, true))}
        />
      </div>
    </ClickAwayListener>
  );
};

const salaryTypeOptions = [
  { value: "year", label: "per year" },
  { value: "month", label: "per month" },
  { value: "week", label: "per week" },
];
