import React from "react";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { SettingsCard } from "ui/settings/SettingsCard";
import { Formblock } from "ui";

export const PayrollIntegrationsConfig: React.FC<IntegrationConfigProps> = ({ integration }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Company">
        <Formblock
          type="text"
          label="Company ID"
          labelInfo="A unique company ID to provide to your 401(k) provider for the connection."
          name="company_id"
          defaultValue={integration.connection?.company_id}
          editing={false}
        />
      </SettingsCard>
    </div>
  );
};
