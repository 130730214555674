import { Formblock } from "ui";
import React from "react";
import * as vals from "dashboard/utils/validators";

type Props = {
  form: $TSFixMe;
  allFormData: $TSFixMe;
};

export const EnterEsignInfo: React.FC<Props> = ({ form, allFormData }) => {
  return (
    <div>
      <div>{`To e-sign your DAS 140, please add your name and title.`}</div>
      <div className="vertical-spacer"></div>
      <div style={{ maxWidth: 500 }}>
        <Formblock
          label="Your name"
          register={form.register(vals.required)}
          type="text"
          name="signer_name"
          defaultValue={allFormData?.sign?.signer_name}
          errors={form.errors}
          editing={true}
        />
        <Formblock
          label="Your title"
          register={form.register(vals.required)}
          type="text"
          name="signer_title"
          defaultValue={allFormData?.sign?.signer_title}
          errors={form.errors}
          editing={true}
        />
      </div>
    </div>
  );
};
