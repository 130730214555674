import React, { useEffect, useMemo, useState } from "react";
import { reportList } from "../../reportList";
import { useForm } from "react-hook-form";
import { BasicModal, Button } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import ProgressBar from "dashboard/components/progress-bar/progressBar";
import { BackgroundForm } from "./BackgroundForm";
import { SignAndSend } from "./SignAndSend";
import { EnterEsignInfo } from "./EnterEsignInfo";
import { Helmet } from "react-helmet";
import {
  useActiveCompany,
  useActiveCompanyId,
  useJobs,
  useLookupJob,
  useUser,
} from "dashboard/hooks/atom-hooks";

const DAS140: React.FC = () => {
  // Hooks
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();

  const backgroundForm = useForm({ shouldUnregister: false });
  const signForm = useForm();
  const navigate = useNavigate();

  const jobs = useJobs();
  const lookupJob = useLookupJob();
  const noDirJobs = useMemo(() => jobs.every((j) => !j.cpr_info), [jobs]);

  type AllFormData = {
    email: string;
    company: string;
    background?: $TSFixMe;
    sign?: $TSFixMe;
  };

  const [allFormData, setAllFormData] = useState<AllFormData>({
    email: activeUser?.email || "",
    company: activeCompanyId || "",
  });

  const stages = ["Enter background info", "Enter e-signature info", "Sign and send"];

  // State

  const [activeStage, setActiveStage] = useState(0);
  const [apprenticePrograms, setApprenticePrograms] = useState<$TSFixMe[]>([]);

  const getApprenticeProgramOptions = async () => {
    try {
      const response = await MiterAPI.apprenticeship_programs.retrieve();
      // @ts-expect-error error cleanup
      if (response.error) throw new Error(response.error);

      setApprenticePrograms(response);
    } catch (e) {
      Notifier.error("There was an error retrieving apprentice program options.");
    }
  };

  const backgroundDone = () => {
    backgroundForm.handleSubmit(async (data) => {
      if (!activeCompany) return;

      if (activeCompany.phone && activeCompany.ca_info?.license_number) {
        data.phone = activeCompany.phone;
        data.license_num = activeCompany.ca_info?.license_number;
      }

      if (data.start_date) {
        data.start_date = data.start_date?.toFormat("DD");
      }

      if (data.end_date) {
        data.end_date = data.end_date?.toFormat("DD");
      }

      setAllFormData({ ...allFormData, background: data });
      setActiveStage(1);
    })();
  };

  const signInformationDone = (data) => {
    setAllFormData({ ...allFormData, sign: data });
    setActiveStage(2);
  };

  const reportObject = reportList.find((report) => report.slug === "das_140");

  useEffect(() => {
    getApprenticeProgramOptions();
  }, []);

  return (
    <div className="page-content">
      <Helmet>
        <title>DAS 140 Report | Miter</title>
      </Helmet>
      {noDirJobs && (
        <BasicModal
          headerText="Please set up a CA DIR job!"
          button2Action={() => navigate("/jobs?action=create")}
          button2Text="Continue"
          button1Action={() => {}}
        >
          <div className="payroll-approval-text-wrapper">
            To create a DAS 140 form, you must first set up a CA DIR job. Please navigate to the{" "}
            <span onClick={() => navigate("/jobs")} className="blue-link">
              Jobs portal
            </span>
            <span> to create a CA DIR job.</span>
          </div>
        </BasicModal>
      )}
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Create a DAS 140 form</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div className="das-form" style={{ maxWidth: 850 }}>
        <ProgressBar stages={stages} includeNumbers active={activeStage} />
        <div style={{ maxWidth: 850 }}>
          {activeStage === 0 && (
            <div>
              <BackgroundForm form={backgroundForm} programs={apprenticePrograms} allFormData={allFormData} />
              <div className="back-next-footer">
                <div className="flex-1"></div>
                <button className="button-2 no-margin" onClick={backgroundDone}>
                  Next
                </button>
              </div>
            </div>
          )}
          {activeStage === 1 && (
            <div>
              <EnterEsignInfo form={signForm} allFormData={allFormData} />
              <div className="back-next-footer">
                <Button
                  className="button-1 no-margin"
                  onClick={() => setActiveStage(0)}
                  text="Back"
                  loading={false}
                />
                <div className="flex-1"></div>
                <Button
                  className="button-2 no-margin"
                  onClick={signForm.handleSubmit(signInformationDone)}
                  text="Continue"
                />
              </div>
            </div>
          )}
          {activeStage === 2 && (
            <>
              <SignAndSend
                programs={apprenticePrograms}
                county={allFormData.background.county.value}
                occupation={allFormData.background.occupation.value}
                formData={allFormData}
                selectedJob={lookupJob(allFormData.background?.job.value)}
              />
              <div className="back-next-footer">
                <Button
                  className="button-1 no-margin"
                  onClick={() => setActiveStage(1)}
                  text="Back"
                  loading={false}
                />
              </div>
            </>
          )}
          <div className="vertical-spacer-large"></div>
        </div>
      </div>
    </div>
  );
};

export default DAS140;
