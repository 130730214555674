import InfoButton from "dashboard/components/information/information";
import { useActiveCompanyId, useOtRuleOptions } from "dashboard/hooks/atom-hooks";
import { AggregatedPayRateGroup, PayRateGroup, UnionRate } from "dashboard/miter";
import { ALMENDARIZ_COMPANY_ID, Notifier, roundTo } from "dashboard/utils";
import React, { useMemo } from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { TimeType } from "backend/utils/time-type-utils";
import { Option } from "ui/form/Input";
import { IS_PRODUCTION } from "dashboard/utils/environment";
import { MissedBreakRules } from "./MissedBreakRules";

type Props = {
  handleChange: (option: Partial<UnionRate>) => void;
  rateGroup: AggregatedPayRateGroup;
  form: UseFormMethods;
  readonly?: boolean;
  updateGroup: (group: Partial<PayRateGroup>) => Promise<void>;
};

const MIXED = "MIXED";

export const PayRateGroupOvertime: React.FC<Props> = ({
  rateGroup,
  handleChange,
  form,
  readonly,
  updateGroup,
}) => {
  const activeCompanyId = useActiveCompanyId();

  const defaultOtRuleValue = useMemo(() => {
    const otRuleIdSet = new Set<string | null>();
    for (const r of rateGroup.union_rates) {
      otRuleIdSet.add(r.overtime_rule_id || null);
    }
    if (otRuleIdSet.size === 0) {
      return null;
    } else if (otRuleIdSet.size === 1) {
      return Array.from(otRuleIdSet)[0]!;
    } else {
      return MIXED;
    }
  }, [rateGroup]);

  const otRuleOptions = useOtRuleOptions({
    includeCustomOption: defaultOtRuleValue === MIXED ? { value: MIXED, label: "(Mixed)" } : undefined,
  });

  const defaultMissedLunchRule = rateGroup.union_rates[0]?.missed_lunch_rule || null;
  const defaultMissedLunchThreshold = roundTo(defaultMissedLunchRule?.timesheet_hours_threshold || 0);
  const defaultSubbedHours = roundTo(defaultMissedLunchRule?.subbed_hours || 0);

  const handleCheckboxChange = async (e) => {
    const newValue = e.target.checked
      ? {
          timesheet_hours_threshold: 5.5,
          subbed_hours: 0,
          subbed_time_type: "ot" as TimeType,
        }
      : null;
    handleChange({ missed_lunch_rule: newValue });
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (defaultMissedLunchRule) {
      const newMissedLunchRule = { ...defaultMissedLunchRule };
      const value = roundTo(Number(e.target.value));
      if (isNaN(value)) {
        Notifier.error("Please enter a valid number.");
        return;
      }
      e.target.value = value.toString();
      newMissedLunchRule[e.target.name] = value;
      if (newMissedLunchRule.timesheet_hours_threshold && newMissedLunchRule.subbed_hours) {
        if (newMissedLunchRule.timesheet_hours_threshold < newMissedLunchRule.subbed_hours) {
          Notifier.error("Lunch time cannot exceed gross timesheet duration");
          return;
        }
      }
      handleChange({ missed_lunch_rule: newMissedLunchRule });
    }
  };

  const handleOtRuleChange = (o: Option<string> | null) => {
    if (o?.value === MIXED) return;

    handleChange({ overtime_rule_id: o?.value || null });
  };

  const handleInputChangeDebounced = useDebouncedCallback((e) => {
    handleInputChange(e);
  }, 1000);

  const showMissedLunch =
    !IS_PRODUCTION || (!!activeCompanyId && [ALMENDARIZ_COMPANY_ID].includes(activeCompanyId));

  return (
    <div style={{ maxWidth: 1000 }}>
      {rateGroup.union_rates.length > 0 ? (
        <>
          <div className="vertical-spacer"></div>
          <div className="billing-card-wrapper">
            <div className="flex">
              <div style={{ fontWeight: 600, fontSize: 18 }}>Overtime calculation</div>
              <InfoButton text="How Miter should calculate overtime for this Pay Rate Group." />
            </div>
            <div className="vertical-spacer-small"></div>
            <Formblock
              label="Overtime rule"
              inputProps={{ style: { width: 300 } }}
              type="select"
              onChange={handleOtRuleChange}
              name="overtime_rule_id"
              control={form.control}
              options={otRuleOptions}
              defaultValue={defaultOtRuleValue}
              errors={form.errors}
              editing={true}
              isClearable
              disabled={readonly}
            />
          </div>
          {showMissedLunch && (
            <div className="billing-card-wrapper">
              <div className="flex">
                <div style={{ fontWeight: 600, fontSize: 18 }}>Missed lunch</div>
                <InfoButton text="This setting determines how OT is added to timesheets when lunch is missed." />
              </div>

              <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
                <div className="flex" style={{ marginTop: 5 }}>
                  <input
                    style={{ marginLeft: 5 }}
                    type="checkbox"
                    checked={defaultMissedLunchRule ? true : false}
                    onChange={handleCheckboxChange}
                    disabled={readonly}
                  />
                  <span style={{ marginLeft: 15 }}>
                    {`When team members miss their lunch, automatically include OT as part of their timesheet.`}
                  </span>
                </div>
                {defaultMissedLunchRule && (
                  <div style={{ marginTop: 15, marginLeft: 50 }}>
                    <div className="flex">
                      <span>A lunch is considered missed when a timesheet over</span>
                      <input
                        className="form2-text"
                        style={{ width: 50, margin: "0px 8px" }}
                        onChange={handleInputChangeDebounced}
                        name="timesheet_hours_threshold"
                        defaultValue={defaultMissedLunchThreshold}
                        disabled={readonly}
                      />
                      <span>{` hours has no unpaid break time.`}</span>
                    </div>
                  </div>
                )}
                {defaultMissedLunchRule && (
                  <div style={{ marginTop: 5, marginLeft: 50 }}>
                    <div className="flex">
                      <span>When lunch is missed, include</span>
                      <input
                        className="form2-text"
                        style={{ width: 50, margin: "0px 8px" }}
                        onChange={handleInputChangeDebounced}
                        name="subbed_hours"
                        defaultValue={defaultSubbedHours}
                        disabled={readonly}
                      />
                      <span>{` hours of OT on the timesheet.`}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="yellow-text-container" style={{ marginTop: 20 }}>
            Before you can configure overtime for this Pay Rate Group, please add at least one classification.
          </div>
        </>
      )}
      <MissedBreakRules rateGroup={rateGroup} updateGroup={updateGroup} />
    </div>
  );
};
