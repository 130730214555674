import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import React, { useContext, useState } from "react";
import { Sage300ConnectionMetadata } from "backend/services/sage300/sage300-types";
import AppContext from "dashboard/contexts/app-context";
import { Loader, Notifier } from "ui";
import { Sage100ConnectionMetadata } from "backend/services/sage100/sage100-types";

export const HH2Authentication: React.FC<{ integration: MiterIntegrationForCompany }> = ({ integration }) => {
  const { getIntegrations } = useContext(AppContext);

  const sage300Metadata = integration?.connection?.metadata?.sage_300 as
    | Sage300ConnectionMetadata
    | undefined;

  const sage100Metadata = integration?.connection?.metadata?.sage_100 as
    | Sage100ConnectionMetadata
    | undefined;

  const ucmId = (integration.key === "sage_100" ? sage100Metadata?.ucmId : sage300Metadata?.ucmId) || "";

  const initialHasCompletedSetup =
    sage300Metadata?.customerHasCompletedSetup || sage100Metadata?.customerHasCompletedSetup;

  const [hasCompletedSetup, setHasCompletedSetup] = useState(!!initialHasCompletedSetup);
  const [showFinalStep, setShowFinalStep] = useState(!!initialHasCompletedSetup);
  const [confirming, setConfirming] = useState(false);

  const navigateToHh2MeetingBooking = () => {
    window.open("https://go.oncehub.com/tier2", "_blank");
  };

  const navigateToHh2Eula = () => {
    window.open("https://www.hh2.com/eula", "_blank");
  };

  const handleSetupConfirmation = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!integration?.connection?._id) return;
    setConfirming(true);
    setHasCompletedSetup(e.target.checked);
    try {
      const response = await MiterAPI.integrations.confirm_hh2_setup(integration.connection?._id);
      if (response.error) throw new Error(response.error);
      await getIntegrations();
      setShowFinalStep(true);
      // Get the latest version of the integrations
    } catch (e) {
      console.error(e);
      Notifier.error("There was a problem updating the integration setup status.");
    }
    setConfirming(false);
  };

  return integration ? (
    <div className="yellow-text-container" style={{ maxWidth: 650, marginTop: 20 }}>
      <div>
        Miter has partnered with hh2, a data synchronization service, to exchange data with Sage ERP systems.
        To setup the integration:
      </div>
      <div style={{ marginLeft: 10, marginTop: 15 }}>
        <div>
          1. Review and sign{" "}
          <span onClick={navigateToHh2Eula} className="blue-link">{`hh2's End User License Agreement`}</span>{" "}
          (scroll to the bottom).
        </div>
        <div style={{ marginTop: 15 }}>
          <strong style={{ marginLeft: 20 }}>{`${ucmId}.hh2.com`}</strong>
        </div>
        <div style={{ marginTop: 25 }}>
          2.{" "}
          <span className="blue-link" onClick={navigateToHh2MeetingBooking}>
            Book a meeting with hh2
          </span>{" "}
          to install the Synchronization Client (choose the first meeting option when booking). Your hh2 web
          address is:{" "}
        </div>
        <div style={{ marginTop: 25 }}>
          3. Confirm that you have completed both setup items:
          <div style={{ marginTop: 15, marginLeft: 15 }}>
            <input
              type="checkbox"
              onChange={handleSetupConfirmation}
              checked={hasCompletedSetup}
              disabled={hasCompletedSetup}
            />{" "}
            I have completed both of these setup items.
          </div>
        </div>
        {confirming && <Loader />}
        {!confirming && showFinalStep && (
          <div style={{ marginTop: 25 }}>4. Sit back and let us handle the rest!</div>
        )}
        <div style={{ marginTop: 10 }}></div>
      </div>
    </div>
  ) : null;
};
