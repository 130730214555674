/* eslint-disable unused-imports/no-unused-vars */

import { StylesConfig } from "react-select";
import { Option } from "ui/form/Input";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const bulkTimesheetSelectStyles: StylesConfig<Option<string>, boolean> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (_provided, state) => ({
    boxShadow: "none",
    fontSize: "13px",
    backgroundColor: "white",
    borderRadius: "4px",
    border: state.isFocused ? "1px solid rgb(24, 61, 226)" : "1px solid rgb(211, 211, 211)",
    borderColor: "#fff",
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    boxSizing: "border-box",
    isDisabled: state.selectProps.isDisabled,
    width: state.selectProps.width ? state.selectProps.width : "100%",
    display: "flex",
    alignItems: "center",
    fontFamily: "Karla",
    lineHeight: "15px",
  }),
  menu: (provided, _state) => ({
    ...provided,
    zIndex: 25,
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width ? state.selectProps.width : "100%",
  }),
  indicatorsContainer: (provided, _state) => ({
    ...provided,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, _state) => ({
    ...provided,
  }),
  clearIndicator: (provided, _state) => ({ ...provided, padding: "0px" }),
  input: (provided, _state) => ({
    ...provided,
    cursor: "none",
    fontSize: "13px",
    margin: "0px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: "15px",
    height: state.selectProps.height - 2,
    padding: "4px 6px",
    display: "flex",
    alignItems: "center",
  }),
  indicatorSeparator: (base, _state) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.selectProps.width < 150 ? "none" : "flex",
    fontSize: "13px",
    color: "gray",
    fontFamily: "Karla",
  }),
};

export const equipmentSelectStyles: StylesConfig<Option<string>, true> = {
  ...bulkTimesheetSelectStyles,
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: "0px",
  }),
};

export const bulkEditTimesheetSelectStyles: StylesConfig<Option<string>, boolean> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (_provided, state) => ({
    boxShadow: "none",
    fontSize: "13px",
    backgroundColor: "white",
    borderRadius: "4px",
    border: state.isFocused ? "1px solid rgb(24, 61, 226)" : "1px solid rgb(211, 211, 211)",
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    boxSizing: "border-box",
    isDisabled: state.selectProps.isDisabled,
    width: state.selectProps.width ? state.selectProps.width : "100%",
    display: "flex",
    alignItems: "center",
    fontFamily: "Karla",
  }),
  menu: (provided, _state) => ({
    ...provided,
    zIndex: 25,
  }),
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width ? state.selectProps.width : "100%",
  }),
  indicatorsContainer: (provided, _state) => ({
    ...provided,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, _state) => ({
    ...provided,
  }),
  clearIndicator: (provided, _state) => ({ ...provided, padding: "0px" }),
  input: (provided, _state) => ({
    ...provided,
    cursor: "none",
    fontSize: "13px",
    margin: "0px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: "15px",
    height: state.selectProps.height - 2,
    padding: "4px 6px",
    display: "flex",
    alignItems: "center",
  }),
  indicatorSeparator: (base, _state) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.selectProps.width < 150 ? "none" : "flex",
    fontSize: "13px",
    color: "gray",
    fontFamily: "Karla",
  }),
};

export const UnderlinedSelectStyles: StylesConfig<Option<string>, boolean> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (_provided, state) => ({
    boxShadow: "none",
    fontSize: "1.08rem",
    backgroundColor: "transparent",
    minHeight: state.selectProps.height,
    height: state.selectProps.height,
    boxSizing: "border-box",
    isDisabled: state.selectProps.isDisabled,
    // width: "100%",
    // width: state.selectProps.width ? state.selectProps.width : "100%",
    width: "max-content",
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    fontFamily: "Karla",
    borderBottom: "1px solid #333",
    paddingBottom: "0px",
    color: "#333",
    fontWeight: "bold",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
  }),
  container: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width ? state.selectProps.width : "100%",
  }),
  indicatorsContainer: (provided, _state) => ({
    ...provided,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided, _state) => ({
    ...provided,
    padding: "0px",
    color: "#333",
  }),
  clearIndicator: (provided, _state) => ({ ...provided, padding: "0px" }),
  input: (provided, _state) => ({
    ...provided,
    cursor: "none",
    fontSize: "16px",
    margin: "0px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: "15px",
    height: state.selectProps.height - 2,
    padding: "0px",
    display: "flex",
    alignItems: "center",
  }),
  indicatorSeparator: (base, _state) => ({
    ...base,
    display: "none",
  }),
  placeholder: (base, state) => ({
    ...base,
    display: state.selectProps.width < 150 ? "none" : "flex",
    fontSize: "1.08rem",
    color: "#777",
    fontFamily: "Karla",
    fontWeight: "bold",
  }),
};
