import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { Wizard } from "ui";
import { AggregatedTeamMember, TeamMember, MiterAPI } from "dashboard/miter";
import { TeamMemberBasicInfoScreen } from "./TeamMemberBasicInfoScreen";
import { TeamMemberHRInfoScreen } from "./TeamMemberHRInfoScreen";
import { TeamMemberPersonalInfoScreen } from "./TeamMemberPersonalInfo";
import { TeamMemberEmploymentInfoScreen } from "./TeamMemberEmploymentInfo";
import { TeamMemberPayrollInfoScreen } from "./TeamMemberPayrollInfoScreen";
import { TeamMemberDefaultsScreen } from "./TeamMemberDefaultsScreen";
import { TeamMemberEEOInfoScreen } from "./TeamMemberEEOInfoScreen";
import { TeamMemberPoliciesAndPermissionsScreen } from "./TeamMemberPoliciesAndPermissionsScreen";
import AppContext from "dashboard/contexts/app-context";
import { TeamMemberCustomFieldsScreen } from "./TeamMemberCustomFieldsScreen";
import { TeamMemberOrgInfoScreen } from "./TeamMemberOrgInfoScreen";
import { TeamMemberChecklistBuilderScreen } from "./TeamMemberChecklistBuilderScreen";
import { TeamMemberBenefitsInfoScreen } from "./TeamMemberBenefitsInfoScreen";
import { useHasAccessToOnboardingV2 } from "dashboard/gating";
import { useActiveCompany, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";
import { Notifier } from "dashboard/utils";
import { useTrades } from "dashboard/hooks/useTrades";

export type TeamMemberWizardMode =
  | "create"
  | "enroll_in_payroll"
  | "new_hire_onboarding_checklist"
  | "admin_onboarding_checklist"
  | "onboarding_checklists";
export type WizardTeamMember = Partial<TeamMember> | AggregatedTeamMember;

type Props = {
  onExit: () => void;
  onComplete: () => void;
  teamMember?: WizardTeamMember;
  mode?: TeamMemberWizardMode;
};

const TeamMemberWizard: React.FC<Props> = ({ onExit, onComplete, mode, ...props }) => {
  const { customFields } = useContext(AppContext);
  const hasAccessToOnboardingV2 = useHasAccessToOnboardingV2();
  const activeCompany = useActiveCompany();
  const isActiveOnClasp = isActiveClaspCompany(activeCompany);
  const refetchTeam = useRefetchTeam();
  const { tradeOptions } = useTrades();
  const [teamMember, setTeamMember] = useState<WizardTeamMember | undefined>(props.teamMember);

  const teamMemberCustomFields = useMemo(() => {
    return customFields.filter((field) => field.parent_type === "team_member");
  }, [customFields]);

  // Handle conditional rendering of wizard screens
  const isCreateMode = mode === "create";
  const isEnrollInPayrollMode = mode === "enroll_in_payroll";
  const isNewHireOnboardingMode = mode === "new_hire_onboarding_checklist";
  const isAdminOnboardingMode = mode === "admin_onboarding_checklist";
  const showBothChecklistsMode = mode === "onboarding_checklists";

  const showBasicInfo =
    !isEnrollInPayrollMode && !isNewHireOnboardingMode && !isAdminOnboardingMode && !showBothChecklistsMode;
  const showOnboardingV2Screens = hasAccessToOnboardingV2 && showBasicInfo;
  const showBenefitsInfo = showOnboardingV2Screens && isActiveOnClasp;
  const showCustomFields = showOnboardingV2Screens && teamMemberCustomFields.length > 0;
  const showTeamMemberCreationScreens = hasAccessToOnboardingV2 && showBasicInfo;
  const showNewHireChecklist =
    hasAccessToOnboardingV2 && !isEnrollInPayrollMode && (!isAdminOnboardingMode || showBothChecklistsMode);
  const showAdminChecklist =
    hasAccessToOnboardingV2 && !isEnrollInPayrollMode && (!isNewHireOnboardingMode || showBothChecklistsMode);
  const showHRInfo = (hasAccessToOnboardingV2 && isEnrollInPayrollMode) || (!hasAccessToOnboardingV2 && mode);
  const showOrgInfo = isCreateMode && !hasAccessToOnboardingV2;

  useEffect(() => {
    setTeamMember(props.teamMember);
  }, [props.teamMember]);

  const startIndex = useMemo(() => {
    if (isEnrollInPayrollMode) {
      return 0;
    }
    return props.teamMember?.resume_wizard_index ?? 0;
  }, [isEnrollInPayrollMode, props.teamMember]);

  const handleTeamMemberWizardComplete = useCallback(async () => {
    if (isEnrollInPayrollMode) {
      return onComplete();
    }

    try {
      // First, immediately exit the wizard to prevent a blank screen from showing during async op
      onExit();
      if (!teamMember || !teamMember._id) throw new Error("Could not find team member profile to update.");
      // Set resume_wizard_index to null to indicate that the wizard is complete so the banner doesn't show
      const response = await MiterAPI.team_member.update(teamMember._id, { resume_wizard_index: null });
      if (response.error) throw new Error(response.error);
      refetchTeam(teamMember._id);
      onComplete();
    } catch (error) {
      console.error("Error handling team member wizard complete: ", error);
      Notifier.error("Failed to complete team member profile.");
    }
  }, [isEnrollInPayrollMode, teamMember, onComplete, onExit, refetchTeam]);

  return (
    <Wizard onExit={onExit} onComplete={handleTeamMemberWizardComplete} startIndex={startIndex}>
      {showBasicInfo && (
        <TeamMemberBasicInfoScreen
          name={"Basic information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showTeamMemberCreationScreens && (
        <TeamMemberPersonalInfoScreen
          name={"Personal information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showTeamMemberCreationScreens && (
        <TeamMemberEmploymentInfoScreen
          name={"Employment information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showTeamMemberCreationScreens && (
        <TeamMemberPayrollInfoScreen
          name={"Payroll information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
          mode={mode || "create"}
        />
      )}
      {showTeamMemberCreationScreens && showBenefitsInfo && (
        <TeamMemberBenefitsInfoScreen
          name={"Benefits information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
          mode={mode || "create"}
        />
      )}
      {showTeamMemberCreationScreens && (
        <TeamMemberDefaultsScreen
          tradeOptions={tradeOptions}
          name={"Default associations"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showTeamMemberCreationScreens && (
        <TeamMemberEEOInfoScreen
          name={"EEO information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showTeamMemberCreationScreens && (
        <TeamMemberPoliciesAndPermissionsScreen
          name={"Policies and permissions"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showTeamMemberCreationScreens && showCustomFields && (
        <TeamMemberCustomFieldsScreen
          name={"Custom fields"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showNewHireChecklist && (
        <TeamMemberChecklistBuilderScreen
          teamMember={teamMember}
          name={"New hire onboarding checklist"}
          setTeamMember={setTeamMember}
          type="new_hire"
          mode={mode}
        />
      )}
      {showAdminChecklist && (
        <TeamMemberChecklistBuilderScreen
          teamMember={teamMember}
          name={"Admin onboarding checklist"}
          setTeamMember={setTeamMember}
          type="admin"
          mode={mode}
        />
      )}
      {showHRInfo && (
        <TeamMemberHRInfoScreen
          name={isEnrollInPayrollMode ? "Enroll in payroll" : "HR information"}
          mode={mode}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
      {showOrgInfo && (
        <TeamMemberOrgInfoScreen
          name={"Org information"}
          teamMember={teamMember}
          setTeamMember={setTeamMember}
        />
      )}
    </Wizard>
  );
};

export default TeamMemberWizard;
