import React, { useState } from "react";
import { CostTypesTable } from "dashboard/components/cost-types/CostTypesTable";
import { Helmet } from "react-helmet";
import { Toggler } from "ui";
import { CostTypeSettings } from "./CostTypeSettings";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {};

export const CostTypes: React.FC<Props> = () => {
  const { can, cannot } = useMiterAbilities();
  const [view, setView] = useState("cost-types");

  const togglerConfig = [
    { path: "cost-types", label: "Cost types", hide: cannot("lists:cost_types:read") },
    { path: "config", label: "Configuration", hide: cannot("lists:cost_types:manage") },
  ];

  const toggle = (path: string) => {
    setView(path);
  };

  return (
    <div className="section-wrapper">
      <Helmet>
        <title>Cost types | Miter</title>
      </Helmet>
      <Toggler secondary={true} config={togglerConfig} active={view} toggle={toggle} />
      {view === "cost-types" && can("lists:cost_types:read") && <CostTypesTable />}
      {view === "config" && can("lists:cost_types:manage") && <CostTypeSettings />}
    </div>
  );
};
