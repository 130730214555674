import React from "react";
import MiterLogo from "../../assets/MiterLogo.png";
import styles from "./LoadingPage.module.css";
import { Loader } from "ui";

const LoadingPage: React.FC = () => {
  return (
    <div className={styles["loading-wrapper"]}>
      <div className={styles["loading-container"]}>
        <img src={MiterLogo} className={styles["logo"]} />
        <div className={styles["loading-div"]}>
          <div className={styles["loading-header"]}>
            <h1>Loading Miter</h1>
            <p>Please wait as we redirect you.</p>
          </div>
          {<Loader />}
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
