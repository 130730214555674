import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Notifier from "dashboard/utils/notifier";
import { AggregatedTeamMemberWithI9, MiterAPI } from "dashboard/miter";

import EmploymentInfoForm from "./EmploymentInfoForm";
import PersonalInformationForm from "./PersonalInformationForm";
import PaymentInformationForm from "./PaymentInformationForm";
import OnboardingInfoForm from "./OnboardingInfoForm";
import { TeamMemberPayForm } from "./TeamMemberPayForm";
import { DemographicsForm } from "./DemographicsForm";
import CustomFieldValueModal from "dashboard/components/custom-fields/CustomFieldValuesModal";
import { EmergencyContactsForm } from "./EmergencyContactsForm";
import { TmClassificationForm } from "./TmClassificationForm";
import { displayFieldErrors } from "dashboard/utils/errors";
import { useActiveCompany, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { JobInformationForm } from "./JobInformationForm";
import { TeamMemberDefaultsForm } from "./TeamMemberDefaultsForm";
import CreateChangeRequestModal from "dashboard/components/change-requests/CreateChangeRequestModal";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { TeamMemberEditingModalCategory } from "../TeamUtils";
import { UpdateTeamMemberParams } from "dashboard/miter";
import { TeamMemberPoliciesForm } from "./TeamMemberPoliciesForm";
import { BenefitsForm } from "./BenefitsForm";
import { TeamMemberCprOptionsForm } from "./TeamMemberCprOptionsForm";

type Props = {
  teamMember: AggregatedTeamMemberWithI9;
  type: TeamMemberEditingModalCategory;
  onHide: () => void;
};

const EditTeamMemberModal: React.FC<Props> = ({ teamMember, type, onHide }) => {
  const refetchTeam = useRefetchTeam();
  const form = useForm({ shouldUnregister: false });
  const teamAbilities = useTeamAbilities();
  // Triggering access to formState before render per RHF documentation.
  const _a = form.formState.dirtyFields; // Don't remove this line.

  const [submitting, setSubmitting] = useState(false);
  const [changeRequestModalOpen, setChangeRequestModalOpen] = useState(false);
  const [changeRequestData, setChangeRequestData] = useState<UpdateTeamMemberParams>();

  const activeCompany = useActiveCompany();
  if (!activeCompany) return null;

  const { settings } = activeCompany;
  const submitText =
    !teamAbilities.can("update", teamMember) &&
    teamAbilities.can("request_change", teamMember) &&
    settings.team.enable_change_request_policies
      ? "Request changes"
      : undefined;

  const onSubmit = async (data, scheduleChanges?: boolean) => {
    setSubmitting(true);
    try {
      if (
        !scheduleChanges &&
        (teamAbilities.can("update", teamMember) || teamAbilities.can("update_sensitive", teamMember))
      ) {
        // if user has update permissions, directly update the team member
        const response = await MiterAPI.team_member.update(teamMember._id, data);
        if (response.error) {
          if (response.fields) {
            displayFieldErrors(
              response.fields.map((f) => {
                if (f.name === "termination_date") f.name = "end_date";
                return f;
              }),
              form.setError
            );
          }
          throw new Error(response.error);
        }
        Notifier.success("Team member updated.");
        await refetchTeam(teamMember._id);
        onHide();
      } else if (teamAbilities.can("request_change", teamMember)) {
        // if the user has request change permission, but not update, open the change request modal
        setChangeRequestModalOpen(true);
        setChangeRequestData(data);
      }
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setSubmitting(false);
  };

  const renderChangeRequestModal = () => {
    if (!changeRequestModalOpen) return;
    if (!changeRequestData || Object.keys(changeRequestData).length === 0) {
      Notifier.error("No changes detected.");
      setChangeRequestModalOpen(false);
      return;
    }

    if (
      type === "onboarding" ||
      type === "payment" ||
      type === "jobs" ||
      type === "custom-fields" ||
      type === "policies" ||
      type === "benefits" ||
      type === "cprs"
    ) {
      Notifier.error("Change requests are not available for this category.");
      setChangeRequestModalOpen(false);
      return;
    }

    return (
      <CreateChangeRequestModal
        parentType="team_member"
        parentObject={teamMember}
        newData={changeRequestData}
        category={type}
        hideEditModal={onHide}
        onHide={() => setChangeRequestModalOpen(false)}
      />
    );
  };

  return (
    <>
      {renderChangeRequestModal()}
      <div className="modal-background">
        <>
          {type === "employment" && (
            <EmploymentInfoForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "pay" && (
            <TeamMemberPayForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "defaults" && (
            <TeamMemberDefaultsForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "classifications" && (
            <TmClassificationForm
              defaultData={teamMember}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "personal" && (
            <PersonalInformationForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "demographics" && (
            <DemographicsForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "benefits" && (
            <BenefitsForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "cprs" && (
            <TeamMemberCprOptionsForm
              data={teamMember}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
            />
          )}
          {type === "payment" && <PaymentInformationForm defaultData={teamMember} onHide={onHide} />}
          {type === "onboarding" && <OnboardingInfoForm defaultData={teamMember} onHide={onHide} />}
          {type === "custom-fields" && (
            <CustomFieldValueModal
              defaultValues={teamMember.custom_field_values}
              onHide={onHide}
              onFinish={() => refetchTeam(teamMember._id)}
              parentID={teamMember._id}
              parentType={"team_member"}
            />
          )}
          {type === "emergency-contacts" && (
            <EmergencyContactsForm
              defaultData={teamMember}
              form={form}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
              submitText={submitText}
            />
          )}
          {type === "jobs" && (
            <JobInformationForm
              defaultData={teamMember}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
            />
          )}
          {type === "policies" && (
            <TeamMemberPoliciesForm
              data={teamMember}
              onHide={onHide}
              onSubmit={onSubmit}
              submitting={submitting}
            />
          )}
        </>{" "}
      </div>
    </>
  );
};

export default EditTeamMemberModal;
