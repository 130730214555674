import React, { useState, useMemo, useCallback } from "react";

import { MiterAPI, WorkersCompCode } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { BasicModal } from "ui";
import WcCodeModal from "../crudModals/WcCodeModal";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Copy, Gear, Plus, TrashSimple } from "phosphor-react";
import { useActiveCompanyId, useWcCodesAtom } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useNavigate } from "react-router-dom";

type WCCodeRow = WorkersCompCode & {
  premium_rate_label: string;
};

const WcCodesTable: React.FC = ({}) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState(false);
  const activeCompanyId = useActiveCompanyId();
  const { can, cannot } = useMiterAbilities();
  const navigate = useNavigate();

  // States related to the table
  const [wcCodes, setWcCodes] = useWcCodesAtom();
  const [wcCodeModal, setWcCodeModal] = useState<WorkersCompCode | null>(null);
  const [selectedRows, setSelectedRows] = useState<WCCodeRow[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const tableData = useMemo(() => {
    const cleanedData = wcCodes.map((code): WCCodeRow => {
      return {
        ...code,
        premium_rate_label: code.premium_rate
          ? `${code.premium_rate}${code.premium_rate_type === "hourly" ? "$/hour" : "%"}`
          : "-",
      };
    });
    return cleanedData;
  }, [wcCodes]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/
  const handleRowClick = (wcCode) => {
    setWcCodeModal(wcCode);
  };

  const handleAdd = () => {
    setAdding(true);
  };

  const handleModalHide = () => {
    setAdding(false);
    setWcCodeModal(null);
  };

  const handleArchive = async () => {
    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to delete workers' comp codes.");
      return;
    }

    setLoading(true);
    try {
      const archivedCodes: WorkersCompCode[] = [];
      await Promise.all(
        selectedRows.map(async (wc_code) => {
          const response = await MiterAPI.wc_codes.update(wc_code._id, { archived: true });
          if (response.error) {
            console.error(response.error);
            Notifier.error(`Error deleting "${wc_code.label}"`);
          } else {
            Notifier.success(`Successfully deleted "${wc_code.label}"`);
            archivedCodes.push(response);
          }
        })
      );
      setWcCodes((prev) => prev.concat(archivedCodes));
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error deleting one or more codes. We're looking into it.");
    }
    setLoading(false);
  };

  const handleDuplicateRows = useCallback(
    async (ids: string[]) => {
      if (cannot("lists:workers_comp_codes:manage")) {
        Notifier.error("You do not have permission to delete workers' comp codes.");
        return;
      }

      setLoading(true);

      try {
        const newCodes: WorkersCompCode[] = [];
        await Promise.all(
          ids.map(async (id) => {
            const response = await MiterAPI.wc_codes.duplicate(id);
            if (response.error) {
              console.error(response.error);
              Notifier.error(`Error duplicating code`);
            } else {
              Notifier.success(`Successfully duplicated code`);
              newCodes.push(response);
            }
          })
        );
        setWcCodes((prev) => prev.concat(newCodes));
        setSelectedRows([]);
      } catch (e) {
        console.log(e);
        Notifier.error("There was an error duplicating one or more codes. We're looking into it.");
      }

      setLoading(false);
    },
    [cannot, setWcCodes]
  );

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add code",
        className: "button-2 no-margin",
        action: handleAdd,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:workers_comp_codes:manage"),
      },
      {
        label: "Settings",
        className: "button-1 ",
        action: () => navigate("settings"),
        important: true,
        icon: <Gear weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:workers_comp_codes:manage"),
      },
    ],
    [can, navigate]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Duplicate",
        className: "button-1 no-margin table-button",
        action: () => handleDuplicateRows(selectedRows.map((row) => row._id)),
        icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:workers_comp_codes:manage"),
      },
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:workers_comp_codes:manage"),
      },
    ],
    [can, selectedRows, handleDuplicateRows]
  );

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"workers-comp-table"}
        resource="workers comp codes"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={handleRowClick}
        showReportViews={true}
      />
    );
  };

  return (
    <div className="wc-codes-table-wrapper">
      {adding && activeCompanyId && <WcCodeModal company={activeCompanyId} hide={handleModalHide} />}
      {wcCodeModal && activeCompanyId && (
        <WcCodeModal code={wcCodeModal} edit={true} hide={handleModalHide} company={activeCompanyId} />
      )}
      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete code" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected code" +
            (selectedRows.length > 1 ? "s" : "") +
            "? To restore a deleted code, you must contact Miter Support."
          }
        />
      )}

      {renderTable()}
    </div>
  );
};

export default WcCodesTable;

const columns: ColumnConfig<WCCodeRow>[] = [
  {
    headerName: "Label",
    field: "label",
    dataType: "string",
  },
  {
    headerName: "Code",
    field: "code",
    dataType: "string",
  },
  {
    headerName: "Premium rate",
    field: "premium_rate_label",
    dataType: "string",
  },
];
