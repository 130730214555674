import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { JobPosting, AggregatedJobPosting } from "dashboard/types/ats";
type OfferLetterAction = "create" | "read" | "update" | "delete";

type JobPostingOptions = string | JobPosting | AggregatedJobPosting | null | undefined;

type OfferLetterAbilities = {
  can: (action: OfferLetterAction, jobPosting?: JobPostingOptions) => boolean;
  cannot: (action: OfferLetterAction, jobPosting?: JobPostingOptions) => boolean;
};

export const useOfferLetterAbilities = (): OfferLetterAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: OfferLetterAction, jobPosting?: JobPostingOptions) => {
      const opts = jobPosting ? { moduleParams: { recruiting: { jobPosting } } } : undefined;

      switch (action) {
        case "create":
          return can_("recruiting:offers:offer_letters:create", opts);
        case "read":
          return can_("recruiting:offers:offer_letters:read", opts);
        case "update":
          return can_("recruiting:offers:offer_letters:update", opts);
        case "delete":
          return can_("recruiting:offers:offer_letters:delete", opts);
        default:
          return false;
      }
    },
    [can_]
  );

  const cannot = useCallback(
    (action: OfferLetterAction, jobPosting?: JobPostingOptions) => {
      return !can(action, jobPosting);
    },
    [can]
  );

  return useMemo(() => ({ can, cannot }), [can, cannot]);
};
