import {
  AggregatedFillableTemplate,
  FetchAnvilFieldsResponse,
  FillableTemplate,
} from "dashboard/types/fillable-template-types";

export const MAX_FILLABLE_INPUTS = 40;

type GenerateFillableInputs = {
  fillableTemplate: AggregatedFillableTemplate;
  anvilFormInputs: FetchAnvilFieldsResponse;
  forceFill?: boolean;
};
import ObjectID from "bson-objectid";

export type PartialTemplateInput = Partial<FillableTemplate["inputs"][number]>;

export const generateFillableInputFields = (input: GenerateFillableInputs): PartialTemplateInput[] => {
  const { fillableTemplate, anvilFormInputs, forceFill } = input;
  const { inputs: miterFields } = fillableTemplate;
  const { fields: anvilFields } = anvilFormInputs;

  return anvilFields
    .map((anvilField) => {
      const miterField = miterFields.find((field) => field.anvil_cast_id === anvilField.id);

      const position = convertAnvilPositionToMiterPosition(anvilField);

      if (miterField) {
        return {
          ...miterField,
          // the input field could have been re-arranged in the Anvil editor
          position,
        };
      }

      return {
        position,
        anvil_cast_id: anvilField.id,
        assignee: [{ type: "self", value: "self" }],
        name: forceFill ? "Untitled input field" : "",
        description: forceFill ? "Empty description" : "",
        type: mapAnvilToMiterFieldsType(anvilField.type),
        _id: ObjectID().toHexString(),
        validations: {
          required: true,
        },
      };
    })
    .sort((a, b) => {
      if (a.position.page_number === b.position.page_number) {
        // y position is based from the bottom of page
        return b.position.top_left.y - a.position.top_left.y;
      }
      return a.position.page_number - b.position.page_number;
    }) as PartialTemplateInput[];
};

const convertAnvilPositionToMiterPosition = (
  anvilField: FetchAnvilFieldsResponse["fields"][number]
): FillableTemplate["inputs"][number]["position"] => {
  const anvilPosition = anvilField.rect;
  const page_number = anvilField.pageNum;
  if (anvilPosition.length !== 4) {
    throw new Error(`Invalid coordinates for anvil position: ${anvilPosition}, expected 4.`);
  }

  return {
    page_number,
    top_left: {
      x: anvilPosition[0]![0]!,
      y: anvilPosition[0]![1]!,
    },
    top_right: {
      x: anvilPosition[1]![0]!,
      y: anvilPosition[1]![1]!,
    },
    bottom_right: {
      x: anvilPosition[2]![0]!,
      y: anvilPosition[2]![1]!,
    },
    bottom_left: {
      x: anvilPosition[3]![0]!,
      y: anvilPosition[3]![1]!,
    },
  };
};

const mapAnvilToMiterFieldsType = (
  anvilFieldType: FetchAnvilFieldsResponse["fields"][number]["type"]
): FillableTemplate["inputs"][number]["type"] => {
  switch (anvilFieldType) {
    case "shortText":
      return "text";
    case "longText":
      return "paragraph";
    case "number":
      return "number";
    case "checkbox":
      return "checkbox";
    case "signature":
      return "esignature";
    case "signatureDate":
      return "date";
    default:
      throw new Error(`Invalid Anvil field type: ${anvilFieldType}`);
  }
};

/**
 * We currently have an awkward process where we overlay a CSS box on top of another div. This requires we render the whole
 * PDF and don't on the screen without making the user scroll the PDF page. But some PDFs are big, so we need to scale their size down.
 * But we also need to account for this scale factor when we render the CSS box on top of the PDF.
 */
export const PAGE_SCALE_FACTOR = 0.75;

export const generateBoxStyle = (input: {
  position: FillableTemplate["inputs"][number]["position"];
  pageDimensions: {
    height: number;
    width: number;
  };
}): {
  bottom: number;
  left: number;
  width: number;
  height: number;
} => {
  const { top_left, bottom_right, bottom_left } = input.position;
  const { height: pageHeight, width: pageWidth } = input.pageDimensions;

  const bottomOffset = Math.min(bottom_left.y, pageHeight);
  const leftOffset = Math.max(top_left.x, 0);

  const originalWidthOfBox = Math.abs(bottom_right.x - top_left.x);
  const originalHeightOfBox = Math.abs(bottom_right.y - top_left.y);

  const actualWidthOfBox = Math.min(originalWidthOfBox, pageWidth - leftOffset);
  const actualHeightOfBox = Math.min(originalHeightOfBox, pageHeight - bottomOffset);
  const style = {
    // TODO: This is a hacky way to adjust the position of the hover box
    // Should work directly with Anvil to make positions exact, or a way to select inputs natively
    bottom: bottomOffset * PAGE_SCALE_FACTOR,
    left: leftOffset * PAGE_SCALE_FACTOR,
    width: actualWidthOfBox * PAGE_SCALE_FACTOR,
    height: actualHeightOfBox * PAGE_SCALE_FACTOR,
  };

  return style;
};
