import {
  useActiveCompanyId,
  useCertificationTypeOptions,
  useLookupCertificationTypes,
  useLookupTeam,
  usePermissionGroups,
} from "dashboard/hooks/atom-hooks";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Loader, Notifier, Wizard } from "ui";

import { Option } from "ui/form/Input";
import { MiterAPI } from "dashboard/miter";
import { DateTime } from "luxon";
import { UploadCertificationWizardScreen } from "miter-components";
import { userHasPermission } from "dashboard/utils/permission-groups";

type Props = {
  teamMemberId: string;
  close: () => void;
};

export const UploadCertificationForTMModal: FC<Props> = ({ teamMemberId, close }) => {
  const certificationTypeOptions = useCertificationTypeOptions();
  const form = useForm();
  const { handleSubmit } = form;
  const lookupCertificationType = useLookupCertificationTypes();
  const [chosenCertificationTypeId, setChosenCertificationTypeId] = useState<string | null>();
  const lookupTeam = useLookupTeam();
  const teamMember = lookupTeam(teamMemberId);
  const certificationType = lookupCertificationType(chosenCertificationTypeId);
  const activeCompanyId = useActiveCompanyId();
  const permissionGroups = usePermissionGroups();

  const chooseCertificationType = async (data: { certification_type_id: Option<string> }) => {
    try {
      const res = await MiterAPI.certifications.forage({
        filter: [
          {
            type: "or",
            value: [
              {
                field: "expires_at",
                value: DateTime.now().toISODate(),
                type: "string",
                comparisonType: ">=",
              },
              { field: "expires_at", value: null, type: "string" },
            ],
          },
          { field: "team_member_id", value: teamMemberId, type: "string" },
          { field: "certification_type_id", value: data.certification_type_id.value, type: "string" },
        ],
        limit: 1,
      });

      if (res.data.length > 0) {
        throw new Error(
          `${teamMember?.full_name} already has this certification ${
            res.data[0]!.expires_at
              ? `that expires on ${DateTime.fromISO(res.data[0]!.expires_at!).toFormat("MMMM d, yyyy")}.`
              : `with no expiry.`
          }`
        );
      }

      setChosenCertificationTypeId(data.certification_type_id.value);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  };

  if (!teamMember) {
    return <Loader />;
  }

  const renderTeamMemberModal = () => {
    if (!activeCompanyId) return null;
    return (
      <div>
        <ActionModal
          headerText={"Upload for " + teamMember?.full_name}
          onHide={close}
          showCancel={true}
          onCancel={close}
          showSubmit={true}
          onSubmit={handleSubmit(chooseCertificationType)}
          submitText={"Create"}
          bodyStyle={{ overflowX: "visible" }}
        >
          <div className="vertical-spacer"></div>

          <Formblock
            label="Certification*"
            type="select"
            options={certificationTypeOptions}
            form={form}
            name="certification_type_id"
            className="modal"
            editing={true}
          />
        </ActionModal>
      </div>
    );
  };

  return (
    <div>
      {certificationType ? (
        <Wizard onExit={close}>
          <UploadCertificationWizardScreen
            certificationOwner={teamMember}
            certificationType={certificationType}
            onClose={close}
            companyId={activeCompanyId!}
            name={certificationType.title}
            readOnly={
              !userHasPermission(
                certificationType.permission_groups_can_manage || [],
                permissionGroups.map((pg) => pg._id)
              )
            }
          />
        </Wizard>
      ) : (
        renderTeamMemberModal()
      )}
    </div>
  );
};
