import React from "react";
import xPng from "dashboard/assets/x.png";

type Props = {
  onHide: () => void;
};

export const NewReleaseModal: React.FC<Props> = ({ onHide }) => {
  return (
    <div className="modal-wrapper-new-release">
      <img className="basic-modal-exit-icon" src={xPng} onClick={onHide} />
      <p>
        There&apos;s a new version of Miter available!{" "}
        <span className="blue-link" onClick={() => window.location.reload()}>
          <strong>Click here</strong>
        </span>{" "}
        to reload the page and get the latest and greatest.
      </p>
    </div>
  );
};
