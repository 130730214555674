import React, { useState } from "react";
import { Formblock } from "ui";

type Props = {
  form: $TSFixMe;
};

export const Remarks: React.FC<Props> = ({ form }) => {
  const [showField, setShowField] = useState(false);

  return (
    <div>
      {showField ? (
        <div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <div className="bold">Remarks</div>
            <div className="flex-1"></div>
            <div style={{ fontSize: 14 }} className="blue-link" onClick={() => setShowField(false)}>
              Clear
            </div>
          </div>
          <div className="vertical-spacer-small"></div>
          <Formblock
            register={form.register}
            type="paragraph"
            name="remarks"
            errors={form.errors}
            editing={true}
          />
        </div>
      ) : (
        <div className="blue-link" style={{ marginTop: 10, fontSize: 15 }} onClick={() => setShowField(true)}>
          + Add remarks
        </div>
      )}
    </div>
  );
};
