import { FilePickerFile } from "ui/form/FilePicker";
import { FileViewer } from "ui";
import styles from "./InvoiceViewer.module.css";

type Props = {
  attachments?: FilePickerFile[];
};

export const InvoiceViewer: React.FC<Props> = ({ attachments }) => {
  if (!attachments?.length || attachments.every((file) => file.deleted)) return <></>;

  return (
    <div className={styles["invoice-viewer-wrapper"]}>
      <FileViewer files={attachments} mode="read" />
    </div>
  );
};
