import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { Formblock, SettingsCard } from "ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Company } from "dashboard/miter";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { useWatch } from "react-hook-form";

const SETTINGS_PATH = "settings.equipment";
type SettingsForm = NonNullable<Company["settings"]["equipment"]> & {};

const EquipmentSettings: React.FC = () => {
  const navigate = useNavigate();
  const company = useActiveCompany();
  const { settings } = company!;
  const { form, handleFieldUpdate } = useSettingsForm<SettingsForm>(SETTINGS_PATH);
  useWatch<SettingsForm>({ control: form.control });

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Equipment settings | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/equipment")}>
          Back to equipment
        </div>
        <div className="flex">
          <h1>Equipment Settings</h1>
        </div>
        <div>Configure equipment related settings</div>
        <SettingsCard title="Display">
          <Formblock
            text="Prefix the equipment name with its code when displaying equipment options for a timesheet or assignment."
            type="checkbox"
            name="prefix_equipment_name_with_code"
            defaultValue={settings?.equipment?.prefix_equipment_name_with_code}
            onChange={(e) => handleFieldUpdate(e.target.checked, "prefix_equipment_name_with_code")}
            editing={true}
          />
        </SettingsCard>
        <SettingsCard title="Equipment timesheets">
          <Formblock
            text="Do not create equipment timesheets for equipment items that do not have a cost rate."
            type="checkbox"
            name="no_timesheets_for_zero_cost_rate"
            defaultValue={settings?.equipment?.no_timesheets_for_zero_cost_rate}
            onChange={(e) => handleFieldUpdate(e.target.checked, "no_timesheets_for_zero_cost_rate")}
            editing={true}
          />
        </SettingsCard>
      </div>
    </div>
  );
};

export default EquipmentSettings;
