import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useMemo, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import {
  useActiveCompanyId,
  useActiveCompany,
  useActiveJobs,
  useRefetchJobs,
} from "dashboard/hooks/atom-hooks";
import { ConfirmModal, SettingsCard } from "ui";
import { isEmptyAddress } from "dashboard/utils";

export const MultiWorkplacePayrolls: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const activeJobs = useActiveJobs();
  const { fetchUserData } = useContext(AppContext);
  const refetchJobs = useRefetchJobs();

  const defaultValue = activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;
  const [enabled, setEnabled] = useState(defaultValue);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleChange = async (newValue) => {
    if (!activeCompanyId) return;
    try {
      const response = await MiterAPI.companies.update(activeCompanyId, {
        "settings.payroll.multi_workplace_payrolls_enabled": newValue,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Payroll settings updated successfully.");
      setEnabled(newValue);
      fetchUserData();
      refetchJobs();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
    setShowConfirmationModal(false);
  };

  const confirmationBodyText = useMemo(() => {
    const jobsWithWorkplacesCount = activeJobs.filter((job) => !!job.workplace_id).length;
    const jobsWithoutWorkplacesCount = activeJobs.filter(
      (job) => !job.workplace_id && !isEmptyAddress(job.address)
    ).length;
    if (enabled && jobsWithWorkplacesCount) {
      return `Changing this setting will attempt to deactivate ${jobsWithWorkplacesCount} workplaces associated with jobs for your company.`;
    } else if (!enabled && jobsWithoutWorkplacesCount) {
      return `Changing this setting will attempt to activate ${jobsWithoutWorkplacesCount} workplaces associated with jobs for your company.`;
    }
    return null;
  }, [enabled, activeJobs]);

  return (
    <>
      {showConfirmationModal && (
        <ConfirmModal
          title={`${enabled ? "Disable" : "Enable"} multi-workplace payrolls`}
          body={null}
          onYes={async () => handleChange(!enabled)}
          onNo={() => setShowConfirmationModal(false)}
        >
          <div className="yellow-text-container">
            <div>{confirmationBodyText}</div>
            <br />
            <div>This may affect both employee and company tax obligations for earnings on these jobs.</div>
          </div>
        </ConfirmModal>
      )}
      <SettingsCard
        title="Job-based payroll taxes"
        info="In Miter, Workplaces represent distinct tax jurisdictions used to calculate payroll taxes."
        contentStyle={{ color: "rgb(51,51,51)" }}
      >
        <div className="flex">
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => setShowConfirmationModal(true)}
            checked={!enabled}
          />
          <label>
            <span className="bold">Disabled</span>:
            <span>{` Employees will be taxed based on the tax location of their primary workplace.`}</span>
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => setShowConfirmationModal(true)}
            checked={enabled}
          />
          <label>
            <span className="bold">Enabled</span>:
            <span>{` Employees will be taxed based on the tax locations of the jobs they work on.`}</span>
          </label>
        </div>
      </SettingsCard>
    </>
  );
};
