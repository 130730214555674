import React, { FC } from "react";
import ReactPaginate from "react-paginate";

import styles from "./Pagination.module.css";

type Props = {
  handlePageChange: (page: number) => void;
  pageCount: number;
};

const Pagination: FC<Props> = ({ handlePageChange, pageCount }) => {
  return (
    <ReactPaginate
      previousLabel={"Previous"}
      nextLabel={"Next"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageChange}
      containerClassName={styles["pagination-container"]}
      activeClassName={styles["active-page"]}
      pageClassName={styles["page"]}
      nextClassName={styles["next"]}
      previousClassName={styles["previous"]}
      pageLinkClassName={"button-1 no-margin " + styles["page-link"]}
      nextLinkClassName={"button-1 no-margin " + styles["next-link"]}
      previousLinkClassName={"button-1 no-margin " + styles["previous-link"]}
      activeLinkClassName={"button-2 no-margin " + styles["active-link"]}
    />
  );
};
export default Pagination;
