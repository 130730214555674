import React, { useMemo } from "react";
import { TableV2, usdString } from "ui";
import { inboundTransfersColumns } from "dashboard/components/table/tableColumnsConfig";
import { titleCase } from "dashboard/utils";
import { Stripe } from "dashboard/miter";

type Filter = "processing" | "succeeded" | "failed" | "all";

export type InboundTransferTableEntry = {
  _id: string;
  amount: string;
  account: string;
  status: Filter;
  created: string;
};

const InboundTransfersTable: React.FC<{
  transfers: Stripe.Treasury.InboundTransfer[];
  isLoading: boolean;
}> = ({ transfers, isLoading }) => {
  /*********************************************************`
   *  Handler functions for the modal and table
   **********************************************************/

  const data: InboundTransferTableEntry[] = useMemo(() => {
    if (transfers) {
      try {
        return transfers.map((transfer) => inboundTransferToTableEntry(transfer));
      } catch (e) {
        console.error("transfers table error", e);
        console.error("transfers", transfers);
      }
    }

    return [];
  }, [transfers]);

  const desc = <h4 className="margin-bottom-0">Transfers to Miter Card funding account</h4>;
  return (
    <>
      <div className="width-100-percent">
        {desc}
        <TableV2
          id={"inbound-transfers-table"}
          data={data}
          columns={inboundTransfersColumns}
          resource="transfers"
          paginationPageSize={5}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default InboundTransfersTable;

const inboundTransferToTableEntry = ({
  id,
  amount,
  origin_payment_method_details,
  status,
  created,
}): InboundTransferTableEntry => ({
  _id: id,
  amount: usdString(amount / 100),
  account: titleCase(origin_payment_method_details.us_bank_account.bank_name),
  status,
  created,
});
