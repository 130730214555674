import React from "react";

import styles from "./modalFormToggler.module.css";

type Props = {
  tabs: string[];
  onToggle: (e) => void;
  activeTab: string;
};

export const ModalFormToggler: React.FC<Props> = ({ tabs, onToggle, activeTab }) => {
  return (
    <div className={styles.wrapper + " flex"}>
      {tabs.map((tab) => {
        const isActive = activeTab === tab;
        return (
          <div
            className="width-100"
            onClick={() => onToggle(tab)}
            style={{ display: "flex", alignItems: "center", height: "100%", justifyContent: "center" }}
          >
            <div className={styles[isActive ? "active-tab" : "tab"]}>{tab}</div>
          </div>
        );
      })}
    </div>
  );
};

export const LeftAlignedModalFormToggler: React.FC<Props> = ({ tabs, onToggle, activeTab }) => {
  return (
    <div className={styles.wrapper + " flex"}>
      {tabs.map((tab) => {
        const isActive = activeTab === tab;
        return (
          <div
            key={tab}
            onClick={() => onToggle(tab)}
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              textAlign: "left",
              justifyContent: "start", // left align
              marginLeft: "10px",
            }}
          >
            <div className={styles[isActive ? "active-tab" : "tab"]} style={{ marginRight: "20px" }}>
              {tab}
            </div>
          </div>
        );
      })}
    </div>
  );
};
