import { useAccessFilter } from "dashboard/hooks/access-filters/useAccessFilter";
import { Activity } from "dashboard/miter";
import { SEARCH_FIELDS } from "dashboard/pages/team-members/TeamMembers";
import TeamMembersTable from "dashboard/pages/team-members/TeamMembersTable";
import { useMemo } from "react";
import { ActionModal } from "ui";

type ActivityTeamMembersModalProps = {
  selectedActivity: Activity;
  setOpenTeamMembersModal: (open: boolean) => void;
  accessType: "included" | "excluded";
};

export const ActivityTeamMembersModal: React.FC<ActivityTeamMembersModalProps> = ({
  selectedActivity,
  setOpenTeamMembersModal,
  accessType,
}) => {
  const accessFilters = useAccessFilter({ entityName: "activity" });

  const filterByTeamMemberIds = useMemo(() => {
    if (accessType === "included") {
      return [...accessFilters.teamMemberIds({ entity: selectedActivity })];
    } else if (accessType === "excluded") {
      return [...accessFilters.excludedTeamMemberIds({ entity: selectedActivity })];
    }
    return [];
  }, [accessType, accessFilters, selectedActivity]);

  return (
    <ActionModal
      headerText={`Team members ${accessType === "included" ? "with" : "without"} access`}
      showSubmit={false}
      showCancel={false}
      onCancel={() => setOpenTeamMembersModal(false)}
      onHide={() => setOpenTeamMembersModal(false)}
      wrapperStyle={{ minWidth: 1000, maxHeight: 1000 }}
      bodyStyle={{ padding: 30, overflowY: "visible", maxHeight: 1000 }}
      hideFooter={true}
    >
      <TeamMembersTable
        filterByTeamMemberIds={filterByTeamMemberIds}
        showToggler={false}
        showMiniTitle={false}
        showButtons={false}
        showSearch={true}
        showReportViews={false}
        searchFields={SEARCH_FIELDS}
        defaultFilters={[]}
      />
    </ActionModal>
  );
};
