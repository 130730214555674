import { Address } from "dashboard/miter";
import { Stripe } from "backend/utils/stripe";
import { CheckAddress } from "backend/utils/check/check-types";

export const convertStripeAddressToMiterAddress = (stripeAddress: Stripe.Address): Address => {
  return {
    line1: stripeAddress.line1 ?? "",
    line2: stripeAddress.line2 ?? undefined,
    city: stripeAddress.city ?? "",
    state: stripeAddress.state ?? "",
    postal_code: stripeAddress.postal_code ?? "",
  };
};

export const convertCheckAddressToMiterAddress = (checkAddress: CheckAddress): Address => {
  return {
    line1: checkAddress.line1,
    line2: checkAddress.line2 ?? undefined,
    city: checkAddress.city,
    state: checkAddress.state,
    postal_code: checkAddress.postal_code,
    country: checkAddress.country ?? undefined,
  };
};
