import React from "react";
import { Button, Formblock } from "ui";
import { AggregatedJob } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";
import { useActiveCprJobOptions, useLookupJob, useActiveCompany } from "dashboard/hooks/atom-hooks";
import { JobInput } from "dashboard/components/shared/JobInput";

type Props = {
  selectedJob?: AggregatedJob;
  setSelectedJob: (j) => void;
  form: $TSFixMe;
  onSubmit: (data) => void;
};

export const BackgroundInfo: React.FC<Props> = ({ form, selectedJob, setSelectedJob, onSubmit }) => {
  const activeCompany = useActiveCompany();

  const lookupJob = useLookupJob();
  const jobOptions = useActiveCprJobOptions();

  if (!activeCompany) return null;

  const hasRequiredCompanyInfo =
    activeCompany.phone &&
    activeCompany.ca_info?.license_number &&
    activeCompany.ca_info?.pwc_registration_number;

  return (
    <div>
      {!hasRequiredCompanyInfo && (
        <div>
          <div className="bold">Your company</div>
          <div className="vertical-spacer"></div>
          <Formblock
            label="Phone"
            register={form.register(vals.required)}
            type="phone"
            control={form.control}
            name="phone"
            errors={form.errors}
            defaultValue={activeCompany!.phone}
            editing={true}
          />
          <Formblock
            label="State license number"
            register={form.register(vals.required)}
            type="text"
            name="license_number"
            errors={form.errors}
            defaultValue={activeCompany.ca_info?.license_number}
            editing={true}
          />
          <Formblock
            label="PWC registration number"
            register={form.register(vals.required)}
            type="text"
            name="pwc_registration_number"
            errors={form.errors}
            defaultValue={activeCompany.ca_info?.pwc_registration_number}
            editing={true}
          />
          <div className="vertical-spacer"></div>
          <div className="bold">Job information</div>
          <div className="vertical-spacer"></div>
        </div>
      )}
      <JobInput
        type="select"
        name="job"
        form={form}
        register={form.register(vals.required)}
        control={form.control}
        label="Job"
        errors={form.errors}
        editing={true}
        onChange={(o) => setSelectedJob(lookupJob(o?.value))}
        options={jobOptions}
        requiredSelect={true}
      />
      {selectedJob && (
        <div key={selectedJob._id}>
          <Formblock
            label="PWC project number"
            register={form.register(vals.required)}
            type="text"
            name="pwc_project_number"
            errors={form.errors}
            editing={true}
            defaultValue={selectedJob.cpr_info?.project_number}
          />
          <Formblock
            label="Contract number"
            register={form.register(vals.required)}
            type="text"
            name="contract_number"
            errors={form.errors}
            editing={true}
            defaultValue={selectedJob.cpr_info?.contract_number}
          />
          <Formblock
            label="Total contract amount"
            register={form.register(vals.required)}
            type="unit"
            unit="$"
            name="total_contract_amount"
            defaultValue={selectedJob.cpr_info?.contract_amount}
            errors={form.errors}
            editing={true}
          />
          <Formblock
            label="Subcontract amount"
            register={form.register}
            type="unit"
            unit="$"
            defaultValue={selectedJob.cpr_info?.subcontract_amount}
            name="subcontract_amount"
            errors={form.errors}
            editing={true}
          />
        </div>
      )}
      <div className="flex" style={{ marginTop: 20 }}>
        <div className="flex-1"></div>
        <Button text="Next" onClick={form.handleSubmit(onSubmit)} className="button-2 no-margin" />
      </div>
    </div>
  );
};
