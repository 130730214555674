import { ValueFormatterFunc } from "ag-grid-community";
import { roundTo } from "dashboard/utils";
import { usdString } from "ui";

export const toDollarFormat = (params: $TSFixMe): string => {
  if (params.value == null) return "";
  return usdString(params.value);
};

export const toRateDollarFormat = (params: $TSFixMe): string => {
  if (params.value == null) return "";
  return usdString(params.value, 3);
};

export const sumValues = (params: $TSFixMe): number => {
  let sum = 0;
  if (params.values.length > 0) {
    for (const value of params.values) {
      if (isNaN(Number(value))) continue;
      sum += Number(value);
    }
  }
  return roundTo(sum);
};

export const roundToTwoDecimals = (params: $TSFixMe): string => {
  if (params.value == null) return "";
  return roundTo(params.value).toString();
};

export const agGridIsoDateComparator = (a: Date, b: string): number => {
  const comparisonString = a?.toISOString().split("T")[0];
  let returnValue = 0;
  if (comparisonString) {
    if (comparisonString > b) {
      returnValue = -1;
    } else if (comparisonString < b) {
      returnValue = 1;
    }
  }
  return returnValue;
};

export const hoursFormatter: ValueFormatterFunc = (params): string => {
  if (params?.value === 0) {
    return "-";
  } else {
    return params?.value?.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 });
  }
};

export const dashFormatter: ValueFormatterFunc = (params): string => {
  if (params.node?.group) return "";
  if (!params.value) return "-";
  return params.value;
};
