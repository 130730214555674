import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { ColDef, GridOptions, RowStyle } from "ag-grid-enterprise";
import { AggregatedJob } from "dashboard/miter";
import { DateTime } from "luxon";
import { Pencil, X } from "phosphor-react";
import React, { useEffect, useMemo, useState } from "react";
import { Badge } from "ui";
import { AggregatedEquipmentLog } from "dashboard/miter";
import { AgGridTable } from "../agGridTable/AgGridTable";
import { verticalCenteredCellStyle } from "../agGridTable/agGridUtils";
import EquipmentLogModal from "../equipment/EquipmentLogModal";
import styles from "./DailyReports.module.css";

type Props = {
  equipmentLogs: AggregatedEquipmentLog[];
  setEquipmentLogs?: (logs: AggregatedEquipmentLog[]) => void;
  createNewEquipmentLog?: boolean;
  onEquipmentLogModalSubmit?: (log: AggregatedEquipmentLog, action: "create" | "update" | "delete") => void;
  onEquipmentLogModalHide?: () => void;
  startDate?: DateTime;
  job?: AggregatedJob;
  hideDelete?: boolean;
  readOnly?: boolean;
};

type DailyReportEquipmentLogEntry = AggregatedEquipmentLog & {
  equipment_name: string;
  has_notes: boolean;
};

const DailyReportEquipmentLogs: React.FC<Props> = ({
  equipmentLogs,
  setEquipmentLogs,
  startDate,
  job,
  createNewEquipmentLog,
  onEquipmentLogModalHide,
  onEquipmentLogModalSubmit,
  hideDelete,
  readOnly,
}) => {
  const [viewingEquipmentLog, setViewingEquipmentLog] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (createNewEquipmentLog) {
      setViewingEquipmentLog("new");
    }
  }, [createNewEquipmentLog]);

  const data: DailyReportEquipmentLogEntry[] = useMemo(() => {
    return equipmentLogs.map((log) => {
      return {
        ...log,
        equipment_name: log.equipment.name,
        equipment_code: log.equipment.code || "-",
        has_notes: !!log.notes?.length,
      };
    });
  }, [equipmentLogs]);

  const handleSubmitEquipmentLog = (log: AggregatedEquipmentLog, action: "create" | "update" | "delete") => {
    if (onEquipmentLogModalSubmit) {
      onEquipmentLogModalSubmit(log, action);
    }
    setViewingEquipmentLog(undefined);
  };

  const handleHideEquipmentLogModal = () => {
    if (onEquipmentLogModalHide) {
      onEquipmentLogModalHide();
    }
    setViewingEquipmentLog(undefined);
  };

  const handleRemoveEquipmentLog = (_id) => {
    const newEquipmentLogs = data.filter((log) => log._id !== _id);
    if (setEquipmentLogs) setEquipmentLogs(newEquipmentLogs);
  };

  const columnDefs = [
    {
      field: "equipment_name",
      headerName: "Equipment",
      minWidth: 250,
    },
    {
      field: "equipment_code",
      headerName: "Code",
      minWidth: 100,
    },
    {
      field: "date",
      headerName: "Date",
      cellRenderer: (params) => {
        if (!params.node.allChildrenCount) {
          // Show day/month
          return params.value ? DateTime.fromISO(params.value).toFormat("MMM dd") : null;
        } else {
          return null;
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: (params) => {
        return <Badge text={params.value.replaceAll("_", " ").toUpperCase()} />;
      },
      minWidth: 170,
      cellStyle: verticalCenteredCellStyle,
    },
    {
      field: "quantity",
      headerName: "Qty",
      maxWidth: 85,
    },

    {
      field: "hours",
      headerName: "Hrs",
      maxWidth: 85,
    },
    {
      field: "has_notes",
      headerName: "Notes",
      maxWidth: 100,
      cellRenderer: (params) => {
        return <Badge text={params.value ? "✓" : "✕"} table color={params.value ? "green" : "red"} />;
      },
      tooltipField: "notes",
      cellStyle: verticalCenteredCellStyle,
    },
    {
      field: "notes",
      headerName: "Notes",
      hide: true,
    },
    {
      field: "_id",
      headerName: "",
      cellRenderer: (params) => {
        return (
          <>
            <button
              onClick={() => setViewingEquipmentLog(params.value)}
              className="button-1"
              style={readOnly ? {} : { height: 28, width: 28, padding: 0 }}
            >
              {readOnly ? "View" : <Pencil style={{ marginTop: 3 }} />}
            </button>
            {!readOnly && (
              <button
                onClick={() => handleRemoveEquipmentLog(params.value)}
                className="button-1"
                style={{ height: 28, width: 28, padding: 0 }}
              >
                <X style={{ marginTop: 3 }} />
              </button>
            )}
          </>
        );
      },
    },
  ] as ColDef[];

  const gridOptions = {
    animateRows: true,
    domLayout: "autoHeight",
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
    },
    tooltipShowDelay: 0,
    tooltipHideDelay: 2000,

    getRowStyle: (params) => {
      if (params.node.footer) {
        return { fontWeight: "bold", background: "#f3f3f3" } as RowStyle;
      } else {
        return {};
      }
    },
    csvExportOptions: {
      allColumns: true,
    },
  } as GridOptions;

  return (
    <div>
      {viewingEquipmentLog && (
        <EquipmentLogModal
          equipmentLogID={viewingEquipmentLog !== "new" ? viewingEquipmentLog : undefined}
          onSubmit={(log, action) => handleSubmitEquipmentLog(log!, action!)}
          onCancel={handleHideEquipmentLogModal}
          defaultDate={startDate}
          defaultJob={job}
          readOnly={readOnly}
          hideDelete={hideDelete}
        />
      )}
      <div>
        <AgGridTable
          columnDefs={columnDefs}
          data={data}
          gridOptions={gridOptions}
          hideDownloadCSV={!readOnly}
          wrapperClassName={styles["ag-grid-daily-report-timesheets-wrapper"]}
        />
      </div>
    </div>
  );
};

export default DailyReportEquipmentLogs;
export const MemoizedDailyReportEquipmentLogs = React.memo(DailyReportEquipmentLogs);
