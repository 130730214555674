import React, { FC } from "react";
import DataBox from "../dataBox/DataBox";
import { DataBoxRow } from "../dataBox/DataBoxRow";
import { PerformanceReviewCycle } from "dashboard/miter";
import { DateTime } from "luxon";
import { generateDescription } from "dashboard/utils/performance";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  performanceReviewSchedule: PerformanceReviewCycle;
  onEdit: () => void;
};

export const mapDueDateToString = {
  "1_week": "1 week after request",
  "2_weeks": "2 weeks after request",
  "3_weeks": "3 weeks after request",
};

export const mapCadenceToString = {
  monthly: "Monthly",
  quarterly: "Quarterly",
  semi_annually: "Semi annually",
  annually: "Yearly",
  no_cycle: "Ongoing",
};

export const ReviewScheduleDetails: FC<Props> = ({ performanceReviewSchedule, onEdit }) => {
  const {
    previous_start_date,
    next_start_date,
    cadence,
    request_style,
    name,
    self_review,
    direct_report_review,
    review_period,
    automated_reminders_enabled,
    days_offset_request,
  } = performanceReviewSchedule;

  const { can } = useMiterAbilities();

  const generateReviewType = () => {
    if (self_review.enabled && direct_report_review.enabled) {
      return "Self and manager review";
    }
    if (self_review.enabled) {
      return "Self review only";
    }
    return "Manager review only";
  };

  const generateRemindersEnabledString = () => {
    if (automated_reminders_enabled) {
      return "1 week before, 3 days before, and day of review due date.";
    }
    return "None.";
  };
  return (
    <div>
      {previous_start_date ? (
        <DataBox title="Current cycle" className="flex-col no-margin">
          <DataBoxRow label="Started on" value={DateTime.fromISO(previous_start_date).toFormat("DD")} />
        </DataBox>
      ) : null}
      <DataBox
        title="Review schedule"
        className="flex-col no-margin"
        onEdit={can("performance:schedules:update") ? () => onEdit() : undefined}
      >
        <DataBoxRow label="Name" value={name} />
        <DataBoxRow label="Type" value={generateReviewType()} />
        <DataBoxRow label="Requests" value={generateDescription(request_style, days_offset_request)} />
        {cadence && <DataBoxRow label="Cadence" value={mapCadenceToString?.[cadence] || "-"} />}
        {next_start_date && (
          <DataBoxRow
            label="Next cycle's start date"
            value={DateTime.fromISO(next_start_date).toFormat("DD")}
          />
        )}
        <DataBoxRow label="Evaluations due" value={mapDueDateToString?.[review_period]} />
        <DataBoxRow label="Automatic reminders" value={generateRemindersEnabledString()} />
      </DataBox>
    </div>
  );
};
