import { AggregatedTeamMember } from "dashboard/miter";
import React, { FC, useState, useEffect } from "react";
import { Button } from "ui";
import styles from "./TeamMemberOnboardingV2.module.css";
import { OnboardingTasksTable } from "dashboard/components/tables/OnboardingTasksTable";
import { useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { PencilSimple, Bell } from "phosphor-react";
import { MiterAPI } from "dashboard/miter";
import { AggregatedTeamMemberOnboardingTask } from "dashboard/miter";
import { Notifier } from "ui";
import Banner from "dashboard/components/shared/Banner";
import { goToMiterGuide } from "dashboard/utils/miterGuides";

type Props = {
  teamMember: AggregatedTeamMember;
};

import TeamMemberWizard, { TeamMemberWizardMode } from "dashboard/components/team-members/TeamMemberWizard";
import { TeamMemberOnboardingTaskRow } from "dashboard/components/tables/OnboardingTasksTable";

export const TeamMemberOnboardingV2: FC<Props> = ({ teamMember }) => {
  const refetchTeam = useRefetchTeam();
  const hasOnboardingChecklist = !!teamMember.onboarding_checklist;

  const [showChecklistBuilderWizard, setShowChecklistBuilderWizard] = useState(false);
  const [checklistMode, setChecklistMode] = useState<TeamMemberWizardMode>("onboarding_checklists");

  const [newHireTasks, setNewHireTasks] = useState<TeamMemberOnboardingTaskRow[]>([]);
  const [adminTasks, setAdminTasks] = useState<TeamMemberOnboardingTaskRow[]>([]);

  const [sendingReminder, setSendingReminder] = useState(false);

  useEffect(() => {
    const fetchTasks = async () => {
      const checklist = teamMember.onboarding_checklist;
      if (!checklist) {
        setNewHireTasks([]);
        setAdminTasks([]);
        return;
      }

      // We need to do this so that we can see more specific information about the task objects
      const aggregatedChecklist = await MiterAPI.team_member_onboarding_checklists.get_aggregated_checklist(
        checklist._id
      );
      const tasks: TeamMemberOnboardingTaskRow[] = aggregatedChecklist.tasks.map(
        (task: AggregatedTeamMemberOnboardingTask) => ({
          ...task,
          team_member: teamMember,
          team_member_onboarding_checklist_id: checklist._id,
        })
      );

      const newHireTasks = tasks.filter((task) => task.assignee.some((assignee) => assignee.type === "self"));
      const adminTasks = tasks.filter((task) => task.assignee.every((assignee) => assignee.type !== "self"));

      setNewHireTasks(newHireTasks);
      setAdminTasks(adminTasks);
    };

    fetchTasks();
  }, [teamMember]);

  const sendReminder = async () => {
    if (!teamMember.onboarding_checklist) return;
    setSendingReminder(true);
    try {
      await MiterAPI.team_member_onboarding_checklists.send_reminder(teamMember.onboarding_checklist._id);
      Notifier.success("Reminder sent.");
    } catch (error) {
      console.error(error);
      Notifier.error("Failed to send reminder.");
    } finally {
      setSendingReminder(false);
    }
  };

  const renderTasksTable = (tasksType: "new_hire" | "admin") => (
    <OnboardingTasksTable
      tasks={tasksType === "new_hire" ? newHireTasks : adminTasks}
      refetchTasks={async () => await refetchTeam(teamMember._id)}
      tasksType={tasksType}
    />
  );

  const renderSection = (title: string, mode: TeamMemberWizardMode, type: "new_hire" | "admin") => (
    <div className={styles.section}>
      <h3>{title} tasks</h3>
      <div className={styles.buttonGroup}>
        <Button
          className={`no-margin button-2 ${styles.editButton}`}
          onClick={() => {
            setShowChecklistBuilderWizard(true);
            setChecklistMode(mode);
          }}
        >
          <PencilSimple weight="bold" style={{ marginRight: 3 }} />
          {`Edit ${title.toLowerCase()} checklist`}
        </Button>
        {type === "new_hire" && (
          <Button
            className={`no-margin button-1 ${styles.reminderButton}`}
            onClick={sendReminder}
            loading={sendingReminder}
          >
            <Bell weight="bold" style={{ marginRight: 3 }} />
            Send reminder
          </Button>
        )}
      </div>
      {renderTasksTable(type)}
    </div>
  );

  return (
    <div className={styles.container}>
      <Banner
        type="info"
        content="Click here for more documentation about onboarding!"
        onClick={() =>
          goToMiterGuide("/miter-guides/hr/team-member-types/hiring-and-onboarding/hr-onboarding-2.0")
        }
      />
      <h2>Onboarding</h2>
      <div>
        {hasOnboardingChecklist
          ? `Edit ${teamMember.first_name}'s onboarding checklists and manage their tasks.`
          : `Create a checklist for ${teamMember.first_name} to complete their onboarding.`}
      </div>
      <div className="vertical-spacer-small"></div>
      {hasOnboardingChecklist ? (
        <div className={styles.sectionsContainer}>
          {renderSection("New hire", "new_hire_onboarding_checklist", "new_hire")}
          <div className="vertical-spacer-small"></div>
          {renderSection("Admin", "admin_onboarding_checklist", "admin")}
        </div>
      ) : (
        <Button
          className="no-margin button-2"
          text="Create team member checklists"
          onClick={() => {
            setShowChecklistBuilderWizard(true);
            setChecklistMode("onboarding_checklists");
          }}
        />
      )}
      {showChecklistBuilderWizard && (
        <TeamMemberWizard
          teamMember={teamMember}
          mode={checklistMode}
          onExit={() => setShowChecklistBuilderWizard(false)}
          onComplete={() => setShowChecklistBuilderWizard(false)}
        />
      )}
    </div>
  );
};
