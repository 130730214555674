import { Formblock, SettingsCard } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";

interface LiveTimesheetSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const LiveTimesheetSettings: React.FC<LiveTimesheetSettingsProps> = ({ timesheetSettings, handleChange }) => {
  return (
    <SettingsCard title="Live timesheets">
      <Formblock
        type="checkbox"
        name="show_all_team_members_in_live_view"
        text="Show all team members in the live timesheets table"
        onChange={(e) => handleChange({ show_all_team_members_in_live_view: e.target.checked })}
        checked={timesheetSettings.show_all_team_members_in_live_view}
        editing={true}
        labelInfo="If enabled, you will also see not clocked in team members in the live timesheets table"
      />
    </SettingsCard>
  );
};

export default LiveTimesheetSettings;
