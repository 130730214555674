import { OfferLetterStatus, OfferLetter as OfferLetterType } from "dashboard/types/offers-types";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./Offers.module.css";
import { MiterAPI, RetrieveFileResponse } from "dashboard/miter";
import { capitalize, Notifier } from "dashboard/utils";
import { DataBoxRow } from "../dataBox/DataBoxRow";
import DataBox from "../dataBox/DataBox";
import { CandidateInfo } from "./offer-letter-wizard/OfferLetterWizard";
import { DateTime } from "luxon";
import { ADMIN_ENTERED_SMART_FIELDS } from "dashboard/utils/offer-letters";
import { smartFieldLabelMap } from "dashboard/utils/offer-templates";
import { CaretDown, Download, Envelope, Plus, Trash, BellRinging } from "phosphor-react";
import { Badge, DropdownButton, Loader } from "ui";
import { useNavigate } from "react-router-dom";
import { downloadFiles } from "miter-utils";
import { DocumentDisplay } from "miter-components";
import { useOfferLetterAbilities } from "dashboard/hooks/abilities-hooks/useOfferLetterAbilities";
import { UpdateExpiryModal } from "./modals/UpdateExpiryModal";
import { ManuallyUploadSignedLetterModal } from "./modals/ManualUploadModal";

type Props = {
  offerLetterId: string;
  candidateInformation: CandidateInfo;
};

export const OfferLetter: FC<Props> = ({ candidateInformation, offerLetterId }) => {
  const [miterDocument, setMiterDocument] = useState<RetrieveFileResponse | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showExpiryModal, setShowExpiryModal] = useState(false);
  const navigate = useNavigate();
  const offerLetterAbilities = useOfferLetterAbilities();
  const [offerLetter, setOfferLetter] = useState<OfferLetterType | null>(null);

  const getOfferLetter = useCallback(async () => {
    try {
      if (!offerLetterId) return;
      const offerLetter = await MiterAPI.offer_letters.retrieve(offerLetterId);
      if (offerLetter.error) throw Error(offerLetter.error);
      setOfferLetter(offerLetter);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  }, [offerLetterId]);

  const getDocument = useCallback(async () => {
    try {
      if (!offerLetter?.file_id) return;
      const document = await MiterAPI.files.retrieve(offerLetter.file_id, { aggregated: true });
      if (document.error) throw Error(document.error);
      setMiterDocument(document);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  }, [offerLetter?.file_id]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);

  useEffect(() => {
    getOfferLetter();
  }, [getOfferLetter]);

  const fullName = `${candidateInformation.candidate_first_name} ${candidateInformation.candidate_last_name}`;
  const expiry = offerLetter?.expires_on
    ? DateTime.fromSeconds(offerLetter.expires_on).toFormat("MMMM d'th', yyyy")
    : "No expiry";

  const deleteOfferLetter = useCallback(async () => {
    try {
      if (!offerLetter) return;
      const response = await MiterAPI.offer_letters.archive({
        company_id: offerLetter.company_id,
        ids: [offerLetterId],
      });
      if (response.error) throw Error(response.error);
      Notifier.success("Offer letter deleted");
      navigate(`/recruiting/job-applications/${offerLetter.job_application_id}`);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  }, [offerLetter, offerLetterId, navigate]);

  const downloadPDF = useCallback(async () => {
    if (!offerLetter) return;
    downloadFiles([offerLetter.file_id], () => {});
  }, [offerLetter]);

  const remindCandidate = useCallback(async () => {
    try {
      if (!offerLetter) return;
      const response = await MiterAPI.offer_letters.remind(offerLetterId);
      if (response.error) throw Error(response.error);
      Notifier.success("Reminder sent");
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
  }, [offerLetterId, offerLetter]);

  const notSignedAndNotExpired = useMemo(() => {
    if (!offerLetter) return false;
    return (
      offerLetter.status !== "signed" &&
      (!offerLetter.expires_on || offerLetter.expires_on > DateTime.now().toSeconds())
    );
  }, [offerLetter]);

  const actions = useMemo(() => {
    if (!offerLetter) return [];
    return [
      {
        label: "Send reminder",
        action: remindCandidate,
        icon: <Envelope style={{ marginRight: 5 }} />,
        shouldShow: () => notSignedAndNotExpired,
      },
      {
        label: "Update expiration date",
        action: () => setShowExpiryModal(true),
        icon: <BellRinging style={{ marginRight: 5 }} />,
        shouldShow: () => offerLetterAbilities.can("update"),
      },

      {
        label: "Manually upload letter",
        action: () => setShowUploadModal(true),
        icon: <Plus style={{ marginRight: 5 }} />,
        shouldShow: () => offerLetterAbilities.can("update") && notSignedAndNotExpired,
      },
      {
        label: offerLetter.status !== "signed" ? "Download unsigned PDF" : "Download signed letter",
        action: downloadPDF,
        icon: <Download style={{ marginRight: 5 }} />,
      },
      {
        label: "Delete offer",
        action: deleteOfferLetter,
        shouldShow: () => offerLetterAbilities.can("delete"),
        icon: <Trash style={{ marginRight: 5 }} />,
      },
    ];
  }, [
    offerLetter,
    remindCandidate,
    downloadPDF,
    deleteOfferLetter,
    notSignedAndNotExpired,
    offerLetterAbilities,
  ]);

  const generateTimestamp = (event: OfferLetterType["events"][number]) => {
    return DateTime.fromSeconds(event.timestamp).toFormat("MMMM d'th', yyyy 'at' h:mm a");
  };

  const getOfferLetterStatusBadge = (status: OfferLetterStatus) => {
    switch (status) {
      case "sent":
        return <Badge style={{ marginRight: 0 }} text="Sent" color="orange" />;
      case "viewed":
        return <Badge style={{ marginRight: 0 }} text="Viewed" color="blue" />;
      case "signed":
        return <Badge style={{ marginRight: 0 }} text="Signed" color="green" />;
      default:
        return "Unknown";
    }
  };

  const shouldShowSmartFieldSection = useMemo(() => {
    return ADMIN_ENTERED_SMART_FIELDS.some((field) => offerLetter?.smart_field_information?.[field]);
  }, [offerLetter?.smart_field_information]);

  if (!miterDocument || !offerLetter) return <Loader />;

  return (
    <div>
      <div className={styles["split-view"]}>
        <div className={styles["profile-box"]}>
          <div className={styles["profile-box-header"]}>
            <h3>Offer letter</h3>
          </div>
          <DocumentDisplay uri={miterDocument.url} />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 20,
            }}
          >
            <DropdownButton className={"button-1"} options={actions} closeOnClick={true}>
              Offer letter
              <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
            </DropdownButton>
          </div>
          <DataBox title={"General"}>
            <DataBoxRow label="Candidate" value={fullName} />
            <DataBoxRow label="Sent to" value={offerLetter?.candidate_email} />
            <DataBoxRow label="Expires" value={expiry} />
            <DataBoxRow label="Status" value={getOfferLetterStatusBadge(offerLetter?.status)} />
          </DataBox>

          <DataBox title={"Events"}>
            {offerLetter.events.reverse().map((event) => (
              <DataBoxRow
                key={event.timestamp}
                value={generateTimestamp(event)}
                label={capitalize(event.type)}
              />
            ))}
          </DataBox>

          {shouldShowSmartFieldSection ? (
            <DataBox title={"Offer details"}>
              {ADMIN_ENTERED_SMART_FIELDS.map((field) => {
                let value = offerLetter.smart_field_information?.[field] ?? null;
                if (!value) return null;
                if (field === "annual_salary" || field === "hourly_pay_rate") {
                  value = value ? `$${Number(value).toLocaleString()}` : "-";
                } else if (field === "start_date" && value) {
                  value = DateTime.fromISO(value).toFormat("MMMM dd, yyyy");
                }
                return <DataBoxRow key={field} label={smartFieldLabelMap[field]} value={value || "-"} />;
              })}
            </DataBox>
          ) : null}
        </div>
      </div>
      {showUploadModal && miterDocument ? (
        <ManuallyUploadSignedLetterModal
          handleClose={() => setShowUploadModal(false)}
          offerLetter={offerLetter}
          existingOfferLetter={miterDocument}
          handleSuccess={getDocument}
        />
      ) : null}
      {showExpiryModal ? (
        <UpdateExpiryModal
          handleClose={() => setShowExpiryModal(false)}
          offerLetter={offerLetter}
          handleSuccess={getOfferLetter}
        />
      ) : null}
    </div>
  );
};
