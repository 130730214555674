import ObjectID from "bson-objectid";
import { useActiveCompany, useUser } from "dashboard/hooks/atom-hooks";
import {
  AggregatedJob,
  AggregatedTeamMember,
  CreateTimesheetParams,
  MiterAPI,
  PayRateItem,
  Timesheet,
} from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import { TableActionLink } from "ui/table-v2/Table";
import { Copy, FloppyDisk, Plus, Trash } from "phosphor-react";
import { FC, useCallback, useMemo, useState } from "react";
import { useBulkCreateTimesheetsColumns } from "./bulkCreateTimesheetsUtils";
import { PageModal, TableV2 } from "ui";
import { getEarningTypeParamsFromAlias } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { useTimesheetPolicies } from "dashboard/utils/policies/timesheet-policy-utils";
import { PartialCustomFieldValue } from "backend/services/custom-field-value-service";
import { Option } from "ui/form/Input";
import { Break } from "backend/models/timesheet";

type BulkCreateTimesheetsModalProps = {
  onHide: () => void;
  onFinish: (timesheets: Timesheet[]) => void;
  activeTeamMember?: AggregatedTeamMember;
  activeJob?: AggregatedJob;
  activeDate?: DateTime;
};

type CustomFieldTableType = {
  [key: string]: string | string[] | number | boolean | Option<string>[]; // Define key as string and value as custom field value type
};

const SECONDS_IN_HOUR = 3600;

export type DraftTimesheet = {
  _id: string;
  date?: DateTime;
  clock_in?: number;
  clock_out?: number;
  hours?: number;
  break_minutes?: number;
  team_member?: string;
  job?: string;
  activity?: string | null;
  classification_override?: string | null;
  class_id?: string | null;
  department_id?: string;
  cost_type_id?: string;
  notes?: string;
  editing?: boolean;
  selected?: boolean;
  injury?: boolean;
  clock_in_photo?: string;
  clock_out_photo?: string;
  wc_code?: string;
  rate_differential_id?: string | null;
  standard_classification_id?: string;
  earning_type?: string | undefined | null;
  pay_rate_item?: PayRateItem;
  equipment_options?: { value: string; label: string }[];
  company_id?: string;
  created_by?: string;
  time_off_policy_id?: string | undefined | null;
  company?: string;
  custom_field_values?: CustomFieldTableType;
  unpaid_break_time?: number;
  break?: Break;
};

const BulkCreateTimesheetsModal: FC<BulkCreateTimesheetsModalProps> = ({
  onHide,
  onFinish,
  activeTeamMember,
  activeJob,
  activeDate,
}) => {
  // hooks
  const activeCompany = useActiveCompany();
  const activeUser = useUser();
  const { buildPolicy } = useTimesheetPolicies();
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};

  // constants
  const activeDateOrNow = activeDate || DateTime.now();
  const activeDateAt9am = activeDateOrNow.set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).toSeconds();
  const activeDateAt5pm = activeDateOrNow.set({ hour: 17, minute: 0, second: 0, millisecond: 0 }).toSeconds();
  const emptyDraftTimesheet = {
    _id: ObjectID().toString(),
    equipment_options: [],
    company_id: activeCompany?._id,
    clock_in: activeDateAt9am,
    clock_out: activeDateAt5pm,
    hours: 8,
    created_by: activeUser?._id,
    earning_type: "",
    company: activeCompany?._id,
    creation_method: "dashboard",
    ...(activeTeamMember ? { team_member: activeTeamMember._id.toString() } : {}),
    ...(activeJob ? { job: activeJob._id.toString() } : {}),
  };

  // states
  const [draftTimesheets, setDraftTimesheets] = useState<DraftTimesheet[]>([emptyDraftTimesheet]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<DraftTimesheet[]>([]);

  // handlers
  const handleAddDraftTimesheetRow = useCallback(() => {
    setDraftTimesheets((prev) => [...prev, emptyDraftTimesheet]);
    // we need this to prevent handleRowUpdate from being called unnecessarily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftTimesheets]);

  // handlers for row actions
  const handleDuplicateRow = useCallback(
    (row: DraftTimesheet, index: number) => {
      const newRow = { ...row, _id: ObjectID().toString() };
      setDraftTimesheets([...draftTimesheets.slice(0, index), newRow, ...draftTimesheets.slice(index)]);
    },
    [draftTimesheets]
  );

  const handleDeleteRow = useCallback(
    (row: DraftTimesheet) => {
      const newRows = draftTimesheets.filter((timesheet) => timesheet._id !== row._id);
      setDraftTimesheets(newRows);
    },
    [draftTimesheets]
  );

  const handleSubmit = async () => {
    if (!activeCompany) return;
    if (!activeUser) return;

    const rowPolicyValidation = validateAllRows();
    if (!rowPolicyValidation.allValid) {
      Notifier.error(
        `Some timesheets do not meet policy requirements. Reasons: ${rowPolicyValidation.missingRequirements.join(
          ", "
        )}`
      );
      return;
    }

    const timesheetCreationParams: CreateTimesheetParams[] = [];

    for (const [i, row] of draftTimesheets.entries()) {
      const errorMessagePrefix = `Row ${i + 1}: `;
      if (!row.team_member) {
        Notifier.error(errorMessagePrefix + "Team member is required.");
        return;
      } else if (!row.clock_in) {
        Notifier.error(errorMessagePrefix + "Clock in time is required.");
        return;
      } else if (!row.clock_out || !row.hours) {
        if (row.hours && !row.clock_out && row.hours > 0) {
          row.clock_out = row.clock_in + row.hours * SECONDS_IN_HOUR;
        } else if (!row.hours && row.clock_out && row.clock_out > row.clock_in) {
          row.hours = (row.clock_out - row.clock_in) / SECONDS_IN_HOUR;
        } else {
          Notifier.error(errorMessagePrefix + "Clock out time is invalid.");
          return;
        }
      } else if (row.clock_out <= row.clock_in || row.hours <= 0) {
        Notifier.error(errorMessagePrefix + "Clock out time must be after clock in time.");
        return;
      }

      const customFieldValues: PartialCustomFieldValue[] = Object.entries(row.custom_field_values || {}).map(
        ([cfId, cfv]) => ({
          custom_field_id: cfId,
          value: Array.isArray(cfv) ? cfv.map((v) => v.value) : cfv,
        })
      );

      const breaks = row.break
        ? [
            {
              start: row.break.start,
              end: row.break.end,
              break_type_id: row.break.break_type_id,
              duration: row.break.duration,
              paid: breakTypesObject[row.break.break_type_id]?.paid || false,
            },
          ]
        : undefined;

      const creationParams: CreateTimesheetParams = {
        team_member: row.team_member,
        clock_in: row.clock_in,
        clock_out: row.clock_out,
        job: row.job,
        activity: row.activity,
        equipment_ids: row.equipment_options?.map((option) => option.value),
        injury: row.injury,
        classification_override: row.classification_override,
        class_id: row.class_id,
        department_id: row.department_id,
        cost_type_id: row.cost_type_id,
        notes: row.notes,
        company: activeCompany._id,
        creation_method: "dashboard",
        custom_field_values: customFieldValues,
        unpaid_break_time: (row.unpaid_break_time || 0) * 60, // convert minutes to seconds
        breaks: breaks,
        ...(row.earning_type ? { ...getEarningTypeParamsFromAlias(row.earning_type) } : {}),
      };

      timesheetCreationParams.push(creationParams);
    }
    setLoading(true);

    try {
      const newTimesheets = await Promise.all(
        timesheetCreationParams.map(async (timesheetParams) => {
          const newTimesheet = await MiterAPI.timesheets.create(timesheetParams);
          if (newTimesheet.error) throw new Error(newTimesheet.error);
          return newTimesheet;
        })
      );

      onFinish(newTimesheets);
    } catch (e) {
      Notifier.error("Failed to create timesheets.");
      console.error(e);
      return;
    } finally {
      setLoading(false);
    }

    Notifier.success("Timesheets created successfully.");
    setDraftTimesheets([]);
    onHide();
  };

  const handleRowUpdate = async (updatedRows: DraftTimesheet[]) => {
    setDraftTimesheets(updatedRows);
  };

  const validateAllRows = (): { allValid: boolean; missingRequirements: string[] } => {
    const validatedRows: { meetsRequirements: boolean; missingRequirements?: string[] }[] =
      draftTimesheets.map((timesheet) => {
        const { meetsPolicyRequirements, needsAttentionMessages } = buildPolicy(timesheet);
        let rowMeetsRequirements = meetsPolicyRequirements();

        if (
          timesheet.unpaid_break_time &&
          timesheet.hours &&
          timesheet.unpaid_break_time > timesheet.hours * SECONDS_IN_HOUR
        ) {
          needsAttentionMessages.push("Unpaid break time must be less than the total hours for the day.");
          rowMeetsRequirements = false;
        }

        if (timesheet.break && timesheet.hours) {
          if (timesheet.break.end - timesheet.break.start > timesheet.hours * SECONDS_IN_HOUR) {
            needsAttentionMessages.push("Break time must be less than the total hours for the day.");
            rowMeetsRequirements = false;
          }
          if (!timesheet.break.break_type_id) {
            needsAttentionMessages.push("Break type is required.");
            rowMeetsRequirements = false;
          }
          if (
            timesheet.break.start &&
            timesheet.clock_in &&
            timesheet.break.end &&
            timesheet.break.start < timesheet.clock_in
          ) {
            needsAttentionMessages.push("Break start time must be after clock in time.");
            rowMeetsRequirements = false;
          }

          if (
            timesheet.break.start &&
            timesheet.clock_out &&
            timesheet.break.end &&
            timesheet.break.end > timesheet.clock_out
          ) {
            needsAttentionMessages.push("Break end time must be before clock out time.");
            rowMeetsRequirements = false;
          }

          if (!timesheet.team_member) {
            needsAttentionMessages.push("Team member is required.");
            rowMeetsRequirements = false;
          }
        }

        return {
          meetsRequirements: rowMeetsRequirements,
          missingRequirements: needsAttentionMessages,
        };
      });

    const allValid = validatedRows.every((row) => row.meetsRequirements);

    const missingRequirements = new Set<string>();
    validatedRows.forEach((row) => {
      if (!row.meetsRequirements) {
        row.missingRequirements?.forEach((requirement) => {
          missingRequirements.add(requirement);
        });
      }
    });

    return {
      allValid,
      missingRequirements: Array.from(missingRequirements),
    };
  };

  // columns
  const columns = useBulkCreateTimesheetsColumns(handleDuplicateRow, handleDeleteRow);

  // table actions
  const staticActions: TableActionLink[] = [
    {
      label: "Add row",
      action: handleAddDraftTimesheetRow,
      className: "button-1 table-button",
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
      important: true,
    },
    {
      label: "Save",
      action: handleSubmit,
      className: "button-2 table-button",
      icon: <FloppyDisk weight="bold" style={{ marginRight: 3 }} />,
      showInEditMode: true,
      important: true,
      loading,
    },
  ];

  const dynamicActions: TableActionLink[] = useMemo(() => {
    // supporting multiple rows
    const duplicateRows = (rows: DraftTimesheet[]) => {
      const newRows = rows.map((row) => {
        const newRow = { ...row, _id: ObjectID().toString() };
        return newRow;
      });

      setDraftTimesheets([...draftTimesheets, ...newRows]);
    };

    const deleteRows = (rows: DraftTimesheet[]) => {
      const rowIdsToDelete = rows.map((row) => row._id);
      const newRows = draftTimesheets.filter((timesheet) => !rowIdsToDelete.includes(timesheet._id));
      setDraftTimesheets(newRows);
    };
    return [
      {
        label: "Delete",
        action: () => deleteRows(selectedRows),
        className: "button-3 table-button",
        icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
        showInEditMode: true,
        important: true,
      },
      {
        label: "Duplicate",
        action: () => duplicateRows(selectedRows),
        className: "button-1 table-button",
        icon: <Copy weight="bold" style={{ marginRight: 3 }} />,
        showInEditMode: true,
      },
    ];
  }, [draftTimesheets, selectedRows]);

  return (
    <PageModal
      header={"Bulk create timesheets"}
      onClose={onHide}
      bodyContentStyle={{ maxWidth: "unset", height: "100%" }} // PageModal default is 800px maxWidth, unset to override
    >
      <TableV2
        id="bulk-create-timesheets"
        resource="timesheets"
        data={draftTimesheets}
        columns={columns}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        showReportViews={true}
        wrapperClassName="base-ssr-table"
        containerClassName={"timesheets-table-container"}
        editable={true}
        alwaysEditable
        onSelect={setSelectedRows}
        isLoading={loading}
        hideSearch
        disablePagination
        hideFilters
        hideRowEditingStatus
        onSave={handleRowUpdate}
        autoSave
        gridWrapperStyle={{ height: "100%" }}
        containerStyle={{ height: "80%" }}
        editableControlled={true}
      />
    </PageModal>
  );
};

export default BulkCreateTimesheetsModal;
