import { Notifier } from "dashboard/utils";
import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import { LedgerAccount, MiterAPI, MiterError, MiterIntegrationForCompany } from "dashboard/miter";
import { useActiveCompanyId, useRefetchLedgerAccounts } from "dashboard/hooks/atom-hooks";
import AppContext from "dashboard/contexts/app-context";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { CreateLedgerAccountParams } from "backend/services/ledger-accounts-service";

type LedgerAccountModalProps = {
  hide: () => void;
  ledgerAccountToUpdate?: LedgerAccount;
};

export const LedgerAccountModal: React.FC<LedgerAccountModalProps> = ({ hide, ledgerAccountToUpdate }) => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { integrations } = useContext(AppContext);
  const refetchLedgerAccounts = useRefetchLedgerAccounts();
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const { cannot } = useMiterAbilities();

  const hasJonasIntegration = useMemo(
    () => integrations.find((i) => i.key === "jonas" && i.connection),
    [integrations]
  );

  const sourceSystem = useMemo(() => {
    let system: MiterIntegrationForCompany | undefined;
    if (ledgerAccountToUpdate?.integrations) {
      const key = Object.keys(ledgerAccountToUpdate.integrations)[0];
      if (key) return integrations.find((i) => i.key === key);
    }
    return system;
  }, [ledgerAccountToUpdate, integrations]);

  const save = async (data) => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      let response: LedgerAccount & MiterError;
      const params: CreateLedgerAccountParams & Record<string, $TSFixMe> = {
        company_id: activeCompanyId!,
        label: data.label as string,
        active: data.active,
        external_id: data.external_id,
        classification: data.classification?.value,
        "integrations.jonas.jonasGlDivision": data.jonasGlDivision,
      };
      if (ledgerAccountToUpdate) {
        response = await MiterAPI.ledger_accounts.update(ledgerAccountToUpdate._id.toString(), params);
      } else {
        response = await MiterAPI.ledger_accounts.create(params);
      }
      if (response.error) {
        throw new Error(response.error);
      }
      await refetchLedgerAccounts((response as LedgerAccount)._id);
      Notifier.success(`Account ${ledgerAccountToUpdate ? "updated" : "created"} successfully.`);
      hide();
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const connectedToExternal = !!ledgerAccountToUpdate?.integrations;
  const hasJonasGLDivision = !!ledgerAccountToUpdate?.integrations?.jonas;
  const importedFromExternal = connectedToExternal && !hasJonasGLDivision;
  const importedFromIntacct = !!ledgerAccountToUpdate?.integrations?.sage_intacct;

  return (
    <ActionModal
      headerText={`${ledgerAccountToUpdate ? "Update" : "Create"} account`}
      onHide={hide}
      showCancel={true}
      onCancel={hide}
      showSubmit={true}
      onSubmit={form.handleSubmit(save)}
      submitText={"Save"}
      loading={loading}
      hideFooter={
        (importedFromExternal && !importedFromIntacct) || cannot("accounting:ledger_accounts:update")
      }
    >
      {sourceSystem && (
        <span
          style={{
            marginTop: 10,
          }}
          className="flex"
        >
          Note: this Ledger Account was imported from&nbsp;{sourceSystem.label}
        </span>
      )}
      <div className="vertical-spacer"></div>
      <Formblock
        label="Label*"
        labelInfo="The name of the ledger account."
        className="modal"
        type="text"
        defaultValue={ledgerAccountToUpdate?.label}
        form={form}
        val={vals.required}
        name="label"
        editing={true}
        disabled={importedFromExternal}
      />
      <Formblock
        label="Identifier"
        labelInfo="A code or ID - usually numerical - used to identify the account."
        className="modal"
        type="text"
        form={form}
        defaultValue={ledgerAccountToUpdate?.external_id}
        name="external_id"
        editing={true}
        disabled={importedFromExternal}
      />
      <Formblock
        label="Type*"
        form={form}
        className="modal"
        type="select"
        options={ledgerAccountClassificationOptions}
        defaultValue={ledgerAccountToUpdate?.classification}
        name="classification"
        editing={true}
        disabled={importedFromExternal && !importedFromIntacct}
        isClearable
      />
      {hasJonasIntegration && (
        <Formblock
          label="GL Division"
          labelInfo="The GL Division that this account is associated with."
          className="modal"
          type="text"
          form={form}
          defaultValue={ledgerAccountToUpdate?.integrations?.jonas?.jonasGlDivision}
          name="jonasGlDivision"
          editing={true}
        />
      )}
      <Formblock
        name="active"
        type="checkbox"
        text="This account is active."
        form={form}
        defaultValue={ledgerAccountToUpdate?.active ?? true}
        editing={true}
        className="modal"
        disabled={importedFromExternal}
      />
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};

const classifications = ["Asset", "Equity", "Expense", "Liability", "Revenue"];
export const ledgerAccountClassificationOptions = classifications.map((c) => {
  return { label: c, value: c };
});
