import React from "react";

import { Helmet } from "react-helmet";

import "./team.css";
import TeamMemberWizard from "dashboard/components/team-members/TeamMemberWizard";
import { useNavigate } from "react-router-dom";

const NewTeamMember: React.FC = () => {
  const navigate = useNavigate();

  const handleExit = () => {
    navigate("/team-members");
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Create a New Team Member | Miter</title>
      </Helmet>
      <div className="page-content">
        <TeamMemberWizard onExit={handleExit} onComplete={handleExit} mode={"create"} />
      </div>
    </div>
  );
};

export default NewTeamMember;
