import { OfferTemplateSmartField, OfferTemplateSmartFieldOption } from "dashboard/types/offers-types";

export const smartFields: OfferTemplateSmartFieldOption[] = [
  {
    value: "candidate_signature",
    title: "Candidate signature input",
  },
  {
    value: "candidate_first_name",
    title: "Candidate first name",
  },
  {
    value: "candidate_last_name",
    title: "Candidate last name",
  },
  {
    value: "candidate_email",
    title: "Candidate email",
  },
  {
    value: "job_title",
    title: "Job title",
  },
  {
    value: "annual_salary",
    title: "Annual salary",
  },
  {
    value: "hourly_pay_rate",
    title: "Hourly pay rate",
  },
  {
    value: "start_date",
    title: "Start date",
  },
  {
    value: "sender_first_name",
    title: "Sender first name",
  },
  {
    value: "sender_last_name",
    title: "Sender last name",
  },
  {
    value: "sender_title",
    title: "Sender title",
  },
  {
    value: "department",
    title: "Department",
  },
];

export const smartFieldLabelMap: Record<OfferTemplateSmartField, string> = smartFields.reduce(
  (acc, field) => {
    acc[field.value] = field.title;
    return acc;
  },
  {} as Record<OfferTemplateSmartField, string>
);

export const smartFieldTokens = smartFields.map((field) => `{{${field.value}}}`);
