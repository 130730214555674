import { AggregatedCandidate, Candidate, JobPosting } from "dashboard/types/ats";

export const APPLICATION_STATUS_VALUE_LABEL = {
  applied: "Applied",
  in_review: "In review",
  interviewing: "Interviewing",
  offer: "Offer",
  hired: "Hired",
  rejected: "Rejected",
};

export type JobPostingTableData = JobPosting & {
  isActive: boolean;
};

export type CandidateTableData = AggregatedCandidate & {
  count: number;
};

export const CANDIDATE_STATUSES = ["applied", "in_review", "interviewing", "offer", "hired", "rejected"];

export const formatJobPostingsTableData = (jobPostings: JobPosting[]): JobPostingTableData[] => {
  return jobPostings.map((jobPosting) => ({
    ...jobPosting,
    isActive: jobPosting.status === "active",
  }));
};

export const formatCandidateTableData = (candidates: AggregatedCandidate[]): CandidateTableData[] => {
  return candidates.map((c) => ({
    ...c,
    count: c.job_applications.length,
  }));
};

export const getCandidateFullName = (candidate: Candidate): string => {
  const { first_name, last_name } = candidate;
  return `${first_name} ${last_name}`;
};

export const WORKPLACE_OPTIONS = [
  {
    label: "In person",
    value: "in_person",
  },
  {
    label: "Remote",
    value: "remote",
  },
  {
    label: "Hybrid",
    value: "hybrid",
  },
  {
    label: "Travel (different sites)",
    value: "travel",
  },
];

export const PAY_TYPE_OPTIONS = [
  {
    label: "Salary",
    value: "salary",
  },
  {
    label: "Hourly",
    value: "hourly",
  },
];

export const EMPLOYMENT_OPTIONS = [
  {
    label: "Full time",
    value: "full_time",
  },
  {
    label: "Part time",
    value: "part_time",
  },

  {
    label: "Contract",
    value: "contract",
  },

  {
    label: "Intern",
    value: "intern",
  },
];

export type JobApplicationPointer = {
  _id: string;
  status: string;
  created_at: number;
  candidate: Pick<Candidate, "first_name" | "last_name">;
};

export const sortJobApplicationPointers = (
  applications: JobApplicationPointer[]
): JobApplicationPointer[] => {
  const statusOrder = {
    applied: 0,
    in_review: 1,
    interviewing: 2,
    offer: 3,
    hired: 4,
    rejected: 5,
  };

  return applications.sort((a, b) => {
    // First, sort by created_at date
    const statusPriority = statusOrder[a.status] - statusOrder[b.status];
    if (statusPriority !== 0) return statusPriority;

    const dateComparison = b.created_at - a.created_at;

    return dateComparison;
  });
};

/**
 * Takes in an HTML string and determines if any characters/numbers/symbols are actually rendered.
 * Also, must be at least 100 characters long (as this is a LinkedIn requirement)
 */
export const htmlIsValid = (htmlString: string): boolean => {
  // Create a new DOMParser instance
  const parser = new DOMParser();
  // Parse the HTML string into a document
  const doc = parser.parseFromString(htmlString, "text/html");
  // Extract text content from the document
  const textContent = doc.body.textContent || "";
  // Check if there is any non-whitespace content
  const trimmedLength = textContent.trim().length;

  return trimmedLength >= 100;
};

export const REJECTION_REASONS_CATEGORY_MAP = {
  candidate_initiated: "Candidate not interested",
  employer_initiated: "Rejected by employer",
};

export const REJECTION_REASON_CATEGORY_OPTIONS = Object.entries(REJECTION_REASONS_CATEGORY_MAP).map(
  ([value, label]) => ({
    label,
    value,
  })
);

export const APPLICATION_STATUS_COLOR = {
  applied: "yellow",
  in_review: "orange",
  interviewing: "blue",
  offer: "light-green",
  hired: "green",
  rejected: "red",
};
