import React from "react";
import { Formblock } from "ui";
import { CompanySettings } from "backend/models/company";

type Props = {
  settings: CompanySettings["team"];
  handleSettingsChange: (settings: Partial<CompanySettings["team"]>) => void;
};

export const ReviewerPermissions: React.FC<Props> = ({ settings, handleSettingsChange }) => {
  const handleCheckboxClick = (e) => {
    const { name, checked } = e.target;
    let newList = [...(settings.supervisor_permissions.timesheets || [])];

    if (checked) {
      newList.push(name);
      if (["edit", "approve"].includes(name) && !newList.includes("view")) {
        newList.push("view");
      }
    } else {
      newList = newList.filter((item) => item !== name);
      if (name === "view") {
        newList = newList.filter((item) => item !== "edit" && item !== "approve");
      }
    }
    handleSettingsChange({ supervisor_permissions: { timesheets: newList } });
  };

  const renderAllocationLevelCheckbox = (name) => {
    const labelLookup = {
      create: `Supervisors and crew leads can create timesheets on behalf of others`,
      view: `Supervisors and crew leads can view timesheets created by others`,
      edit: `Supervisors and crew leads can edit timesheets created by others`,
      approve: `Supervisors and crew leads can approve timesheets`,
      delete: `Supervisors and crew leads can delete timesheets`,
    };

    return (
      <div className="flex" style={{ marginRight: 20, marginTop: 3, width: "auto", whiteSpace: "nowrap" }}>
        <input
          type="checkbox"
          style={{ whiteSpace: "nowrap" }}
          name={name}
          onChange={handleCheckboxClick}
          checked={settings?.supervisor_permissions?.timesheets.includes(name)}
        ></input>
        <span style={{ marginLeft: 6 }}>{labelLookup[name]}</span>
      </div>
    );
  };

  return (
    <>
      <div className="billing-card-wrapper" style={{ height: "auto" }}>
        <div style={{ fontWeight: 600, fontSize: 18 }}>Supervisor permissions</div>
        <div className="vertical-spacer-small"></div>
        <div className="flex">
          <Formblock
            labelStyle={{ minWidth: 225 }}
            type="text"
            label="Timesheets"
            labelInfo="Which timesheet actions supervisors can take on behalf of others."
            locked={true}
            style={{ display: "flex", flexDirection: "column", height: "auto", marginBottom: 0 }}
          >
            <div style={{ marginTop: 5 }}>
              {renderAllocationLevelCheckbox("create")}
              {renderAllocationLevelCheckbox("view")}
              {renderAllocationLevelCheckbox("edit")}
              {renderAllocationLevelCheckbox("approve")}
              {renderAllocationLevelCheckbox("delete")}
            </div>
          </Formblock>
        </div>
      </div>
    </>
  );
};
