import { HolidaySchedule, MiterAPI, UpdateHolidayScheduleParams } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useState } from "react";
import { Badge, ConfirmModal, DropdownButton, ModalSidebar } from "ui";
import { MenuItem } from "ui/modal/ModalWithSidebar";
import { FederalHolidays } from "./FederalHolidays";
import { HolidayScheduleBasics } from "./HolidayScheduleBasics";
import x from "dashboard/assets/x.png";
import styles from "ui/modal/modalWithSidebar.module.css";
import { CaretDown } from "phosphor-react";
import { CustomHolidays } from "./CustomHolidays";
import { useSetHolidaySchedules } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { HolidayScheduleTeamMembers } from "./HolidayScheduleTeamMembers";

type Props = {
  originalHolidaySchedule: HolidaySchedule;
  hide: () => void;
};

type NewMenuItem = Omit<MenuItem, "component">;

const tabs = ["Basics", "Federal Holidays", "Custom Holidays", "Team Members"];
const menuItems = tabs.map((t) => {
  return { label: t, path: t };
});

export const HolidayScheduleModal: React.FC<Props> = ({ originalHolidaySchedule, hide }) => {
  const setHolidaySchedules = useSetHolidaySchedules();
  const { can, cannot } = useMiterAbilities();

  const [updatedHolidaySchedule, setUpdatedHolidaySchedule] = useState(originalHolidaySchedule);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<NewMenuItem | undefined>(menuItems[0]);

  const holidayScheduleId = originalHolidaySchedule._id;

  const handleToggle = (path) => {
    const activeItem = menuItems.find((item) => item.path === path);
    setActiveMenuItem(activeItem);
  };

  const archiveHolidaySchedule = async (hs_id: string) => {
    try {
      if (cannot("time_off:holidays:manage")) {
        Notifier.error("You do not have permission to delete holiday schedules.");
        return;
      }

      const failedTms = await MiterAPI.holiday_schedules.archive(hs_id);
      if (failedTms.error) {
        Notifier.error(failedTms.error);
        throw new Error(failedTms.error);
      } else if (failedTms.length) {
        const namesOfFailedTms = failedTms.map((tm) => tm.full_name).join(", ");
        const idsOfFailedTms = failedTms.map((tm) => tm._id).join(", ");
        Notifier.error(
          `Encountered an issue unenrolling ${namesOfFailedTms}from holiday schedule - it was not deleted. Please try again later.`
        );
        throw new Error(`TMs failed to be removed from holiday schedule: ${idsOfFailedTms}`);
      } else {
        Notifier.success("Successfully deleted holiday schedule!");
        setHolidaySchedules((prev) => prev.filter((hs) => hs._id !== hs_id));
      }
    } catch (e: $TSFixMe) {
      console.log(`Error deleting holiday schedule:`, e);
    }
    hide();
  };

  const updateHolidaySchedule = async (update: UpdateHolidayScheduleParams) => {
    try {
      if (cannot("time_off:holidays:manage")) {
        Notifier.error("You do not have permission to update holiday schedules.");
        return;
      }

      const response = await MiterAPI.holiday_schedules.update(holidayScheduleId, update);
      if (response.error) {
        throw new Error(response.error);
      }
      setUpdatedHolidaySchedule(response);
      setHolidaySchedules((prev) => prev.concat(response));
      Notifier.success(`Successfully updated holiday schedule!`);
    } catch (e: $TSFixMe) {
      console.log(`Error updating holiday schedule:`, e);
      Notifier.error(e.message);
    }
  };

  const actions = [
    {
      label: "Delete schedule",
      action: () => setShowArchiveModal(true),
      shouldShow: () => can("time_off:holidays:manage"),
    },
  ];

  const renderArchiveConfirmation = () => {
    const title = `Delete holiday schedule`;
    const body = `Are you sure you'd like to delete this holiday schedule? Currently enrolled employees will be unenrolled from it.`;

    return (
      <ConfirmModal
        title={title}
        body={body}
        onYes={() => archiveHolidaySchedule(holidayScheduleId)}
        onNo={() => setShowArchiveModal(false)}
      />
    );
  };

  return (
    <div className="modal-background">
      <div className={styles["modal-wrapper"]} style={{ width: 800, position: "relative" }}>
        <div className={styles["header"]}>
          {updatedHolidaySchedule.title}
          {updatedHolidaySchedule.is_default && <Badge color="green" text="DEFAULT" />}
          <div className="flex-1"></div>
          {actions && (
            <DropdownButton className={"button-1 no-margin modal-dropdown-button"} options={actions}>
              Actions
              <CaretDown style={{ marginBottom: -2, marginLeft: 5 }} />
            </DropdownButton>
          )}

          <img src={x} style={{ height: 12, marginLeft: 10, cursor: "pointer" }} onClick={hide} />
        </div>
        <div className={styles["below"]}>
          <ModalSidebar config={menuItems} active={activeMenuItem?.path} toggle={handleToggle} />
          <div className={styles["active-view"]} style={{ height: 475 }}>
            {activeMenuItem?.path === "Basics" && (
              <HolidayScheduleBasics
                originalHolidaySchedule={updatedHolidaySchedule}
                updateHolidaySchedule={updateHolidaySchedule}
              />
            )}
            {activeMenuItem?.path === "Federal Holidays" && (
              <FederalHolidays
                originalHolidaySchedule={updatedHolidaySchedule}
                updateHolidaySchedule={updateHolidaySchedule}
              />
            )}
            {activeMenuItem?.path === "Custom Holidays" && (
              <CustomHolidays
                originalHolidaySchedule={updatedHolidaySchedule}
                // @ts-expect-error fix me
                updateHolidaySchedule={updateHolidaySchedule}
              />
            )}
            {activeMenuItem?.path === "Team Members" && (
              <HolidayScheduleTeamMembers originalHolidaySchedule={updatedHolidaySchedule} />
            )}
          </div>
        </div>
      </div>

      {showArchiveModal && renderArchiveConfirmation()}
    </div>
  );
};
