import { OnboardingChecklist } from "dashboard/types/onboarding-types";
import React, { useEffect, useState } from "react";
import { Wizard } from "ui";
import ChecklistBuilderScreen from "./ChecklistBuilderScreen";
import { useRefetchOnboardingChecklists } from "dashboard/hooks/atom-hooks";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  onboardingChecklist?: OnboardingChecklist;
  name?: string;
};

const ChecklistWizard: React.FC<Props> = ({ onExit, onComplete, name, onboardingChecklist }) => {
  const [checklist, setChecklist] = useState<OnboardingChecklist | undefined>(onboardingChecklist);
  const refetchChecklists = useRefetchOnboardingChecklists();
  useEffect(() => {
    setChecklist(onboardingChecklist);
  }, [onboardingChecklist]);

  const onCompleteWithRefetch = () => {
    onComplete();
    refetchChecklists();
  };

  return (
    <Wizard onExit={onExit} onComplete={onCompleteWithRefetch}>
      <ChecklistBuilderScreen
        name={name || "Create onboarding checklist"}
        setChecklistItem={setChecklist}
        checklistItem={checklist}
      />
    </Wizard>
  );
};

export default ChecklistWizard;
