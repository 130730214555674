import React, { useMemo } from "react";
import { titleCase } from "dashboard/utils/utils";

import PaymentContext from "./paymentContext";
import { baseSensitiveCompare } from "miter-utils";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { usdString } from "ui";
import { checkBenPtdTaxAmountCellRenderer, checkBenPtdTaxAmountTooltip } from "../viewPayrollUtils";

type TaxTableEntry = {
  _id: string;
  label: string;
  payer: string;
  is_fed: boolean;
  amount_string: string;
  amount_num: number;
};

const isFedTax = (l: string) => {
  return l.includes("federal") || l.includes(" fica ") || l === "fica" || l.includes("medicare");
};

const Taxes: React.FC = () => {
  const { payment } = React.useContext(PaymentContext);

  const taxes = useMemo(() => {
    if (!payment.check_item) return [];

    const tableReadyTaxes: TaxTableEntry[] = [];

    for (const tax of payment.check_item.taxes || []) {
      tableReadyTaxes.push({
        _id: tax.tax,
        label: tax.description,
        payer: titleCase(tax.payer),
        amount_string: usdString(tax.amount),
        amount_num: Number(tax.amount),
        is_fed: isFedTax(tax.description.toLowerCase()),
      });
    }

    tableReadyTaxes.sort((a, b) => {
      if (a.payer !== b.payer) return baseSensitiveCompare(b.payer, a.payer);
      if (a.is_fed !== b.is_fed) return a.is_fed ? -1 : 1;
      return baseSensitiveCompare(a.label, b.label);
    });

    return tableReadyTaxes;
  }, [payment]);

  const columns: ColumnConfig<TaxTableEntry>[] = useMemo(
    () => [
      { field: "label", headerName: "Tax", dataType: "string" },
      { field: "payer", headerName: "Payer", dataType: "string" },
      {
        field: "amount_string",
        headerName: "Amount",
        sortField: "amount_num",
        cellRenderer: (params) => {
          if (!params.data?._id || !params.data?.amount_string || !payment)
            return <span>{params.data?.amount_string}</span>;
          return checkBenPtdTaxAmountCellRenderer(params.data?._id, params.data?.amount_string, payment);
        },

        tooltipValueGetter: (params) => {
          if (!params.data?._id || !params.data?.amount_string || !payment) return;
          return checkBenPtdTaxAmountTooltip(params.data?._id, params.data?.amount_string, payment);
        },
      },
    ],
    [payment]
  );

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Taxes</div>
        <div className="flex-1"></div>
      </div>
      <div>
        <div>
          <span>{"Miter calculates and remits most taxes automatically."}</span>
        </div>
        <TableV2
          id="team-member-taxes-table"
          resource="taxes"
          columns={columns}
          data={taxes}
          paginationPageSize={10}
          hideSelectColumn
          hideSearch
          hideSecondaryActions
        />
      </div>
    </div>
  );
};

export default Taxes;
