import VendorsTable from "dashboard/components/vendors/VendorsTable";
import React, { FC } from "react";
import { Helmet } from "react-helmet";

type Props = {
  vendorId?: string;
};

const Vendors: FC<Props> = ({ vendorId }) => {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Vendors | Miter</title>
      </Helmet>
      <VendorsTable vendorId={vendorId} />
    </div>
  );
};

export default Vendors;
