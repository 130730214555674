import { Users, Link, ArrowsClockwise, Table, Download, PlusCircle } from "phosphor-react";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import styles from "../../components/banking/Lander.module.css";
import { Button, Notifier } from "ui";
import { useActiveCompany, useSetActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";

const BenefitsAdminLander: React.FC<{ status?: string }> = ({ status }) => {
  const company = useActiveCompany();
  const setActiveCompany = useSetActiveCompany();
  const [loading, setLoading] = useState(false);

  const renderHeader = () => {
    return (
      <div className={styles["header"]}>
        <h1 className={styles["header-title"]}>Benefits Administration</h1>
        <p className={styles["header-subtitle"]}>
          {status == null
            ? `Manage benefits administration within Miter for a seamless payroll experience.`
            : `Your benefits are under construction. We'll let you know when they're ready to go!`}
        </p>
      </div>
    );
  };

  const renderFeatures = () => {
    const featureElements = capitalFeatures.map((feature) => {
      return (
        <div className={styles["feature"]} key={feature.label}>
          <div className={styles["feature-icon"]}>{feature.icon}</div>
          <div className={styles["feature-label"]}>
            <p>{feature.label}</p>
          </div>
        </div>
      );
    });

    return <div className={styles["features"] + " " + styles["capital-features"]}>{featureElements}</div>;
  };

  const handleBenefitsAdminOnboard = async () => {
    if (!company) return Notifier.error("No active company");
    setLoading(true);
    try {
      const response = await MiterAPI.benefits_admin.onboard({ company_id: company._id });
      if (response.error) {
        throw new Error(response.error);
      }
      setActiveCompany(response);
      setLoading(false);
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      setLoading(false);
    }
  };

  const renderCTA = () => {
    return (
      <div className={styles["cta"]}>
        <div className="flex">
          <Button
            className={styles["cta-button"] + " button-2 no-margin"}
            onClick={handleBenefitsAdminOnboard}
            loading={loading}
            disabled={!!status}
            style={{ marginRight: "10px" }}
          >
            <PlusCircle style={{ marginBottom: -2, marginRight: 10 }} />
            {"Onboard to Miter Benefits Administration"}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Benefits Administration | Miter</title>
      </Helmet>
      {renderHeader()}
      {renderFeatures()}
      {renderCTA()}
    </div>
  );
};

const capitalFeatures = [
  {
    icon: <Users color="#4D54B6" weight="duotone" />,
    label: "Manage benefits for your team.",
  },
  {
    icon: <Link color="#4D54B6" weight="duotone" />,
    label: "Connect your existing benefits carriers.",
  },
  {
    icon: <ArrowsClockwise color="#4D54B6" weight="duotone" />,
    label: "Sync enrollment updates directly to payroll.",
  },
  {
    icon: <Table color="#4D54B6" weight="duotone" />,
    label: "Get detailed information about the breakdown of your team's coverage",
  },
  {
    icon: <Download color="#4D54B6" weight="duotone" />,
    label: "Generate reports for staying compliant with ACA.",
  },
];

export default BenefitsAdminLander;
