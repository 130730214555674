import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ActionModal, ConfirmModal, Formblock } from "ui";

import { Tag, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import { TagParentType } from "backend/models/tag";
import { capitalize } from "lodash";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {
  tagID?: string;
  parentType: TagParentType;
  onFinish: (tag: Tag) => void;
  onHide: () => void;
  tagNickname?: string;
};

type TagForm = Omit<Tag, "_id" | "created_at" | "updated_at">;

const TagModal: React.FC<Props> = ({ tagID, parentType, onFinish, onHide, tagNickname = "tag" }) => {
  // Form hooks
  const form = useForm<TagForm>();
  useWatch({ control: form.control });

  // Other hooks
  const { tags, getTags } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();

  // State functions
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  // Component level variables
  const selectedTag = tags.find((tag) => tag._id === tagID);
  const capitalizedTagNickname = capitalize(tagNickname);

  const createTag = async (data: TagForm) => {
    if (selectedTag || !activeCompanyId) return;
    setLoading(true);

    try {
      const res = await MiterAPI.tags.create({
        label: data.label,
        color: data.color,
        company_id: activeCompanyId,
        parent_type: parentType,
      });

      if (res.error) throw new Error(res.error);

      Notifier.success(`${capitalizedTagNickname} created successfully`);
      await getTags();
      onFinish(res);
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    setLoading(false);
  };

  const updateTag = async (data: TagForm) => {
    if (!selectedTag) return;
    setLoading(true);
    try {
      const res = await MiterAPI.tags.update(selectedTag._id, {
        label: data.label,
        color: data.color,
      });

      if (res.error) throw new Error(res.error);

      await getTags();
      onFinish(res);
      onHide();
      Notifier.success(`${capitalizedTagNickname} updated successfully`);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const deleteTag = async () => {
    if (!selectedTag) return;
    setDeleting(true);
    try {
      const res = await MiterAPI.tags.delete(selectedTag._id);
      if (res.error) throw new Error(res.error);

      await getTags();
      onFinish(res);
      onHide();
      Notifier.success(`${capitalizedTagNickname} deleted successfully`);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setDeleting(false);
  };

  const submit = () => {
    if (selectedTag) {
      form.handleSubmit(updateTag)();
    } else {
      form.handleSubmit(createTag)();
    }
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="label"
          label="Label*"
          form={form}
          val={vals.required}
          editing={true}
          className="modal"
          placeholder={`Add ${tagNickname} label`}
          defaultValue={selectedTag?.label}
        />
        <Formblock
          type="color"
          name="color"
          label="Color*"
          form={form}
          editing={true}
          className="modal"
          placeholder={`Select ${tagNickname} color`}
          labelInfo="This is the color of the tag in the user interface."
          disabled={!!tagID}
          val={vals.required}
          defaultValue={selectedTag?.color}
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={selectedTag ? `Edit ${tagNickname}` : `Create ${tagNickname}`}
      showSubmit={true}
      showCancel={true}
      showDelete={!!selectedTag}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={submit}
      onDelete={() => setShowDeleteConfirm(true)}
      loading={loading}
    >
      {renderForm()}
      {showDeleteConfirm && (
        <ConfirmModal
          title={`Delete ${tagNickname}`}
          body={`Are you sure you want to delete this ${tagNickname}?`}
          onYes={deleteTag}
          onNo={() => setShowDeleteConfirm(false)}
          loading={deleting}
        />
      )}
    </ActionModal>
  );
};

export default TagModal;
