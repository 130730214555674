import React, { useMemo, useState } from "react";
import { ActionModal, usdString } from "ui";
import { AggregatedPayRateGroup, AggregatedTeamMember, TeamMember, UnionRate } from "dashboard/miter";
import groupStyles from "../../settings/payrollSettings/payRateGroups/payRateGroupPage/PayRateGroupModal.module.css";
import Select, { ValueType } from "react-select";
import { Option } from "ui/form/Input";
import { styles } from "ui/form/styles";
import { usePrgs, useClassificationOptions } from "dashboard/hooks/atom-hooks";
import { isEqual } from "lodash";

type Props = {
  defaultData: AggregatedTeamMember;
  onHide: () => void;
  onSubmit: (data: $TSFixMe, scheduleChanges: boolean) => Promise<void>;
  submitting: boolean;
  submitText?: string;
};

export const TmClassificationForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  submitText,
}) => {
  const prgs = usePrgs();

  const [newMapping, setNewMapping] = useState(defaultData.prg_classifications || {});

  const handleSelectInputChange = (
    prg: AggregatedPayRateGroup,
    option: Option<string> | null | undefined
  ) => {
    setNewMapping((prev) => {
      const mapping = { ...prev };
      if (option?.value) mapping[prg._id] = option.value;
      else delete mapping[prg._id];
      return mapping;
    });
  };

  const unchanged = useMemo(() => {
    return isEqual(defaultData.prg_classifications || {}, newMapping);
  }, [defaultData.prg_classifications, newMapping]);

  const save = (scheduleChanges: boolean) => {
    const updates: Partial<TeamMember> = { prg_classifications: newMapping };
    const defaultPrgId = defaultData.union_rate?.pay_rate_group;
    const newDefaultRate = defaultPrgId && newMapping[defaultPrgId];
    if (newDefaultRate && newDefaultRate !== defaultData.union_rate?._id) {
      updates.union_rate = newDefaultRate;
    }
    onSubmit(updates, scheduleChanges);
  };
  return (
    <ActionModal
      headerText={`Edit classifications for ${defaultData.full_name}`}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={submitText || "Save"}
      onHide={onHide}
      onSubmit={() => save(false)}
      loading={submitting}
      wrapperStyle={{ width: "700px" }}
      submitDisabled={unchanged}
      showEdit={submitText !== "Request changes"}
      editText={"Schedule changes"}
      onEdit={() => save(true)}
      editDisabled={unchanged}
    >
      <>
        <div className="vertical-spacer-small" />
        <div className={groupStyles["person-row"] + " " + groupStyles["columns"]}>
          <div className={groupStyles["person-name"] + " " + groupStyles["column"]}>Group</div>
          <div className={groupStyles["miter-tm-select"] + " " + groupStyles["column"]}>Classification</div>
        </div>
        <div className={groupStyles["rows-wrapper"]}>
          {prgs.map((prg) => {
            return (
              <TmClassificationRow
                key={prg._id}
                prg={prg}
                handleSelectInputChange={handleSelectInputChange}
                newMapping={newMapping}
              />
            );
          })}
        </div>
      </>
    </ActionModal>
  );
};

const mapRateToOption = (rate: UnionRate): Option<string> => {
  return { label: `${rate.classification} - ${usdString(rate.base_rate)}`, value: rate._id };
};

export const TmClassificationRow: React.FC<{
  prg: AggregatedPayRateGroup;
  handleSelectInputChange: (prg: AggregatedPayRateGroup, o: Option<string> | null | undefined) => void;
  newMapping: Record<string, string>;
}> = ({ prg, handleSelectInputChange, newMapping }) => {
  const classificationOptions = useClassificationOptions({
    prgId: prg._id.toString(),
    opts: { mapFuncOverride: mapRateToOption },
  });
  const value = classificationOptions.find((o) => o.value === newMapping[prg._id]);

  return (
    <div key={prg._id} className={groupStyles["person-row"]}>
      <div className={groupStyles["person-name"]}>{<div>{prg.label}</div>}</div>
      <div className={groupStyles["miter-tm-select"]}>
        <Select
          key={prg._id}
          name="classification"
          options={classificationOptions}
          maxMenuHeight={175}
          value={value}
          width="300px"
          height="32px"
          styles={styles}
          onChange={(o: ValueType<Option<string>, false>) => handleSelectInputChange(prg, o)}
          isClearable
        />
      </div>
    </div>
  );
};
