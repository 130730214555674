import CustomFieldValuesForm from "dashboard/components/custom-fields/CustomFieldValuesForm";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { cleanCustomFieldValuesBeforeSaving } from "miter-utils";
import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "ui";
import TimesheetContext from "./timesheetContext";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
export const TimesheetCustomFields: React.FC = () => {
  const { customFields } = useContext(AppContext);
  const { timesheet, getTimesheet, inboxMode } = useContext(TimesheetContext);
  const form = useForm();
  const activeCompanyId = useActiveCompanyId();
  const timesheetAbilities = useTimesheetAbilities({ inboxMode });

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  const tsCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "timesheet"),
    [customFields]
  );

  const saveCustomFieldValues = async (data) => {
    if (!timesheet) return;

    if (timesheetAbilities.cannot("update", timesheet)) {
      Notifier.error("You do not have permission to update this timesheet");
      return;
    }

    setSaving(true);

    try {
      const params = cleanCustomFieldValuesBeforeSaving(
        data,
        activeCompanyId!,
        timesheet._id,
        "timesheet",
        timesheet.custom_field_values
      );

      const res = await MiterAPI.custom_field_values.save(params);
      if (res.error) throw new Error(res.error);

      // Get timesheet before setting editing to false
      await getTimesheet();
      setEditing(false);
      Notifier.success("Custom field values saved successfully");
    } catch (e: $TSFixMe) {
      console.error("Error creating custom field:", e);
      Notifier.error(e.message);
    }
    setSaving(false);
  };

  const handleSave = () => {
    form.handleSubmit(saveCustomFieldValues)();
  };

  return (
    <div>
      <div className="payment-active-view-header" style={{ width: "100%" }}>
        <div className="">Custom fields</div>
        <div className="flex-1" />
        {timesheetAbilities.can("update", timesheet) && !editing && timesheet?.status === "unapproved" && (
          <Button className="button-2" onClick={() => setEditing(true)}>
            Edit fields
          </Button>
        )}
        {timesheetAbilities.can("update", timesheet) && editing && (
          <>
            <Button className="button-1" onClick={() => setEditing(false)}>
              Cancel
            </Button>
            <Button className="button-2 no-margin" onClick={handleSave} loading={saving}>
              Save
            </Button>
          </>
        )}
      </div>
      <div className="vertical-spacer-small"></div>
      {timesheet && (
        <CustomFieldValuesForm
          form={form}
          customFields={tsCustomFields}
          defaultValues={timesheet.custom_field_values || []}
          formblockClassName="timesheet-custom-fields"
          editing={editing}
        />
      )}
    </div>
  );
};
