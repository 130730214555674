import { ApprenticeshipProgram, MiterAPI, AggregatedJob } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { Button } from "ui";
import { extensionToMimeType } from "dashboard/utils/utils";
import { SendMessageModal } from "dashboard/components/sendMessageModal/SendMessageModal";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

type Props = {
  selectedJob: AggregatedJob;
  selectedApprenticeProgram: ApprenticeshipProgram;
  fileId: string;
};

export const CompleteAndSend: React.FC<Props> = ({ selectedJob, selectedApprenticeProgram, fileId }) => {
  const activeCompany = useActiveCompany();

  const [downloading, setDownloading] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const downloadCompletedForm = async () => {
    setDownloading(true);
    try {
      if (!fileId) throw new Error("No file ID");
      const responseData = await MiterAPI.files.download([fileId]);
      if (responseData.error) throw new Error(responseData.error);

      saveAs(
        // @ts-expect-error not sure
        new Blob([Uint8Array.from(responseData.data.data).buffer], {
          type: extensionToMimeType(responseData.originalname),
        }),
        responseData.originalname
      );

      Notifier.success("File downloaded.");
    } catch (e) {
      Notifier.error("There was an error downloading the form.");
    }
    setDownloading(false);
  };

  const goToDir = () => {
    window.open("https://www.dir.ca.gov/das/publicworks.html", "_blank");
  };

  return (
    <div>
      {sending && (
        <SendMessageModal
          hide={() => setSending(false)}
          onSend={() => setSent(true)}
          file_ids={[fileId]}
          header="Send your DAS 142 form"
          content_type="das_142"
          job_id={selectedJob._id}
          email_params={{
            to: selectedApprenticeProgram.contact_email,
            subject: `DAS 142 for ${selectedJob.name} from ${activeCompany!.check_company.trade_name}`,
            text: `Hi, attached you'll find a completed DAS 142 form for ${selectedJob.name} from ${
              activeCompany!.check_company.trade_name
            }`,
          }}
          mail_params={{
            toAddress: {
              ...selectedApprenticeProgram.address,
              postal_name: selectedApprenticeProgram.committee,
            },
          }}
        />
      )}
      <div className="bold">Congrats!</div>
      <p>
        Your DAS 142 form was created successfully and saved to{" "}
        <Link to={"/jobs/" + selectedJob?._id + "/files"}>{`${selectedJob?.name}`}&apos;s files folder</Link>.
        <span>{` Please refer to the `}</span>
        <span className="blue-link" onClick={goToDir}>
          DIR website
        </span>
        <span>{` for the latest overview of contractors' responsibilities.`}</span>
      </p>
      <div className="flex" style={{ marginTop: 25 }}>
        <Button
          text="Download"
          className="button-1 no-margin"
          loading={downloading}
          onClick={downloadCompletedForm}
        />
        <div style={{ marginRight: 5 }}></div>
        <Button
          text="Send"
          className="button-2"
          disabled={sent}
          loading={false}
          onClick={() => setSending(true)}
        />
      </div>

      <div className="vertical-spacer"></div>
    </div>
  );
};
