import InfoButton from "dashboard/components/information/information";
import React, { FC, ReactElement, ReactNode } from "react";

type SettingsCardProps = {
  children: React.ReactNode;
  title?: string | ReactElement | ReactNode;
  info?: string;
  subtitle?: string;
  contentStyle?: React.CSSProperties;
};

export const SettingsCard: FC<SettingsCardProps> = ({ children, title, info, subtitle, contentStyle }) => {
  return (
    <div className="billing-card-wrapper">
      <div style={{ display: "flex" }}>
        {title && <div className="billing-card-header">{title}</div>}
        {info && (
          <div style={{ marginTop: 5 }}>
            <InfoButton text={info} />
          </div>
        )}
      </div>
      {subtitle && <div style={{ marginTop: 5, color: "#555", ...contentStyle }}>{subtitle}</div>}
      {(title || info) && <div className="vertical-spacer-medium" />}
      <div style={contentStyle}>{children}</div>
    </div>
  );
};
