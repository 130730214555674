import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { DateTime } from "luxon";
import { Notifier } from "dashboard/utils";

import * as vals from "dashboard/utils/validators";

type Props = {
  tm: AggregatedTeamMember;
  hide: () => void;
};

export const RehireModal: React.FC<Props> = ({ tm, hide }) => {
  const { control, errors, handleSubmit } = useForm();

  const [rehiring, setRehiring] = useState(false);
  const [startDate, setStartDate] = useState(DateTime.now().plus({ days: 1 }));

  const rehire = async () => {
    setRehiring(true);
    try {
      const response = await MiterAPI.team_member.rehire(tm._id, {
        start_date: startDate.toISODate(),
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("The employee was successfully rehired.");
      hide();
    } catch (e) {
      if (e instanceof Error) {
        Notifier.error(e.message);
      } else {
        Notifier.error("There was a problem updating the employee. We're looking into it!");
      }
    }
    setRehiring(false);
  };

  return (
    <ActionModal
      onHide={hide}
      overflowVisible={true}
      onCancel={hide}
      onSubmit={handleSubmit(rehire)}
      submitText="Rehire"
      headerText={`Rehire ${tm.full_name}`}
      loading={rehiring}
      showCancel={true}
      showSubmit={true}
    >
      <div className="vertical-spacer"></div>
      <div className="yellow-text-container">
        <span className="bold">Note</span>: Your state may have specific laws around rehiring. Please research
        your state&apos;s laws and stay compliant!
      </div>
      <div className="vertical-spacer"></div>
      <Formblock
        label="Rehire start day"
        labelInfo="Please select the day the employee will start."
        type="datetime"
        control={control}
        dateOnly={true}
        rules={vals.required}
        name="start_date"
        className={"modal"}
        errors={errors}
        defaultValue={startDate}
        onChange={setStartDate}
        editing={true}
      />
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
