import React from "react";
import { getPaymentTotals } from "dashboard/utils/utils";
import PaymentContext from "./paymentContext";
import { usdString } from "ui";

const PaymentTotals: React.FC = () => {
  const { payment, tm, editing } = React.useContext(PaymentContext);

  const totals = getPaymentTotals(payment);

  return (
    <div className="totals-wrapper">
      <div className="vertical-spacer" />
      {totals && (
        <div className="payment-totals-wrapper">
          <div className="total-line">
            <div className="total-line-label">Earnings</div>
            <div className="flex-1"></div>
            <div className="total-line-symbol green">+</div>
            <div className="total-line-number green">{usdString(totals.earnings)}</div>
          </div>
          <div className="total-line">
            <div className="total-line-label">Reimbursements</div>
            <div className="flex-1"></div>
            <div className="total-line-symbol green">+</div>
            <div className="total-line-number green">{usdString(totals.reimbursements)}</div>
          </div>
          {tm.employment_type === "employee" && !editing && (
            <div className="total-line">
              <div className="total-line-label">Benefits contributions</div>
              <div className="flex-1"></div>
              <div className="total-line-symbol red">-</div>
              <div className="total-line-number red">{usdString(totals.benefits)}</div>
            </div>
          )}
          {tm.employment_type === "employee" && !editing && (
            <div className="total-line">
              <div className="total-line-label">Post-tax deductions</div>
              <div className="flex-1"></div>
              <div className="total-line-symbol red">-</div>
              <div className="total-line-number red">{usdString(totals.post_tax_deductions)}</div>
            </div>
          )}
          {tm.employment_type === "employee" &&
          !editing &&
          totals.imputed_income &&
          totals.imputed_income !== 0 ? (
            <div className="total-line">
              <div className="total-line-label">Imputed income</div>
              <div className="flex-1"></div>
              <div className="total-line-symbol red">-</div>
              <div className="total-line-number red">{usdString(totals.imputed_income)}</div>
            </div>
          ) : null}
          {tm.employment_type === "employee" && !editing && (
            <div className="total-line last">
              <div className="total-line-label">Taxes</div>
              <div className="flex-1"></div>
              <div className="total-line-symbol red">-</div>
              <div className="total-line-number red">{usdString(totals.taxes)}</div>
            </div>
          )}

          {!editing && (
            <div className="total-line bold">
              <div className="total-line-label">Net pay</div>
              <div className="flex-1"></div>
              <div className="total-line-symbol"></div>
              <div className="total-line-number">{usdString(totals.net_pay)}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentTotals;
