import { useMemo } from "react";
import { TimeOffRequestFormItems } from "../pages/time-off/TimeOffRequestModal";
import {
  useActiveCompanyId,
  useActiveJobs,
  useActivities,
  useActivityLabelFormatter,
  useActivityOptions,
  useActivityOptionsMap,
  useJobNameFormatter,
  useJobOptions,
  useLookupActivity,
  useLookupJob,
  useLookupTeam,
  useLookupTimeOffPolicy,
  useSelectableActivitiesMap,
  useTeamOptions,
} from "./atom-hooks";
import { isTimesheetScoped } from "../pages/activities/activityUtils";
import { useTimeOffRequestAbilities } from "./abilities-hooks/useTimeOffRequestAbilities";
import { useJobScopesPredicate } from "dashboard/pages/jobs/jobUtils";

export const useTimeOffRequestFormItems = (
  inboxMode?: "needs_attention" | "approval"
): TimeOffRequestFormItems => {
  const timeOffRequestAbilities = useTimeOffRequestAbilities({ inboxMode });
  const employees = useTeamOptions({ predicate: timeOffRequestAbilities.teamPredicate("read") });

  const { wfmJobScopeWithAbility } = useJobScopesPredicate(timeOffRequestAbilities.jobPredicate("create"));

  const lookupTeam = useLookupTeam();
  const activeCompanyId = useActiveCompanyId();

  const timeOffPoliciesMap = useLookupTimeOffPolicy();
  const jobsMap = useLookupJob();
  const activities = useActivities();
  const activitiesMap = useLookupActivity();
  const activityOptionsMap = useActivityOptionsMap({
    predicate: isTimesheetScoped,
  });

  const activityLabelFormatter = useActivityLabelFormatter();
  const jobNameFormatter = useJobNameFormatter();
  const jobOptions = useJobOptions({ predicate: wfmJobScopeWithAbility });
  const activityOptions = useActivityOptions();
  const selectableActivitiesMap = useSelectableActivitiesMap();
  const jobs = useActiveJobs();

  const formItems: TimeOffRequestFormItems = useMemo(
    () => ({
      companyId: activeCompanyId!,
      teamMemberOptions: employees,
      teamMembersMap: lookupTeam,
      timeOffPoliciesMap,
      jobs,
      jobsMap,
      jobNameFormatter,
      jobOptions,
      activities,
      activitiesMap,
      activityOptionsMap,
      activityLabelFormatter,
      activityOptions,
      selectableActivitiesMap,
    }),
    [
      activeCompanyId,
      employees,
      lookupTeam,
      timeOffPoliciesMap,
      jobs,
      jobsMap,
      activities,
      activitiesMap,
      activityOptionsMap,
      activityLabelFormatter,
      jobNameFormatter,
      jobOptions,
      activityOptions,
      selectableActivitiesMap,
    ]
  );

  return formItems;
};
