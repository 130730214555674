import React from "react";
import { Formblock } from "ui";
import { ActivityModalTabProps } from "./TabbedActivityModal";
import { ActivityScope } from "backend/models/activity";
import {
  isExpenseScoped,
  isReimbursementScoped,
  isTimesheetScoped,
} from "dashboard/pages/activities/activityUtils";

export const ActivityScopes: React.FC<ActivityModalTabProps> = ({
  selectedActivity,
  handleFieldsChange,
  readonly,
}) => {
  const handleScopeChange = (isIncluded: boolean, scope: ActivityScope) => {
    const scopeSet = new Set(selectedActivity.scopes.slice());
    if (isIncluded) {
      scopeSet.add(scope);
    } else {
      scopeSet.delete(scope);
    }
    handleFieldsChange({ scopes: Array.from(scopeSet) });
  };

  return (
    <>
      <div className="vertical-spacer-small" />
      <div className="yellow-text-container">Activity scopes determine where an activity can be used.</div>
      <div className="vertical-spacer" />
      <Formblock
        label="Timesheets"
        type="checkbox"
        name="timesheets"
        className="tabbed-modal"
        checked={isTimesheetScoped(selectedActivity)}
        onChange={(e) => handleScopeChange(e.target.checked, "timesheets")}
        editing={true}
        disabled={readonly}
        style={{ alignItems: "center" }}
      />
      <Formblock
        label="Card transactions"
        type="checkbox"
        name="expenses"
        className="tabbed-modal"
        checked={isExpenseScoped(selectedActivity)}
        onChange={(e) => handleScopeChange(e.target.checked, "expenses")}
        editing={true}
        disabled={readonly}
        style={{ alignItems: "center" }}
      />
      <Formblock
        label="Reimbursements"
        type="checkbox"
        name="reimbursements"
        className="tabbed-modal"
        checked={isReimbursementScoped(selectedActivity)}
        onChange={(e) => handleScopeChange(e.target.checked, "reimbursements")}
        editing={true}
        disabled={readonly}
        style={{ alignItems: "center" }}
      />
    </>
  );
};
