import React, { useContext, useState } from "react";
import { BasicModal, Formblock } from "ui";
import Notifier from "dashboard/utils/notifier";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { MiterAPI } from "dashboard/miter";
import PayrollContext from "./payrollContext";

export const EditPayrollLabelModal: React.FC<{
  setEditingLabel: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setEditingLabel }) => {
  // Hooks
  const { payroll, setPayroll } = useContext(PayrollContext);
  const { cannot } = useMiterAbilities();
  const [payrollLabel, setPayrollLabel] = useState(payroll?.label);

  const handleLabelSave = async () => {
    try {
      if (!payroll) return;
      const res = await MiterAPI.payrolls.update_payroll(payroll._id, { label: payrollLabel });
      if (res.error) throw new Error(res.error);
      setPayroll((prev) => {
        if (!prev) return;
        return { ...prev, label: payrollLabel };
      });
    } catch (err: $TSFixMe) {
      console.log(err);
      Notifier.error("There was an error updating the payroll label. " + err.message);
    } finally {
      setEditingLabel(false);
    }
  };

  if (cannot("payrolls:update")) return null;

  return (
    <BasicModal
      headerText="Edit payroll label"
      button2Text="Submit"
      button2Action={handleLabelSave}
      onHide={() => setEditingLabel(false)}
      showCloseX
    >
      <Formblock
        type="text"
        name="label"
        className="modal"
        editing={true}
        onChange={(e) => setPayrollLabel(e.target.value)}
        value={payrollLabel}
      />
    </BasicModal>
  );
};
