import React, { useState } from "react";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { Formblock, SelectOption } from "ui";

type Props = {
  form: UseFormMethods<FieldValues>;
  jobOptions: SelectOption[];
};

export const AddOtherJobs: React.FC<Props> = ({ form, jobOptions }) => {
  const [showField, setShowField] = useState(false);

  return (
    <div>
      {showField ? (
        <div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <div className="bold">Add other jobs</div>
            <div className="flex-1"></div>
            <div
              style={{ fontSize: 14 }}
              className="blue-link"
              onClick={() => {
                setShowField(false);
                form.setValue("otherJobs", []);
              }}
            >
              Clear
            </div>
          </div>
          <div className="vertical-spacer-small"></div>
          <Formblock
            type="multiselect"
            label={"Other jobs"}
            labelInfo={"Hours and earnings from these jobs will also be included in the CPR."}
            options={jobOptions}
            control={form.control}
            name="otherJobs"
            errors={form.errors}
            editing={true}
            height="auto"
          />
        </div>
      ) : (
        <div className="blue-link" style={{ marginTop: 10, fontSize: 15 }} onClick={() => setShowField(true)}>
          + Add other jobs
        </div>
      )}
    </div>
  );
};
