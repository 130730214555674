import React from "react";
import { CheckAddress } from "backend/utils/check/check-types";
import { Button } from "ui";
import Banner from "../shared/Banner";
import { addressToString } from "dashboard/utils";

type Props = {
  suggestedAlternative: CheckAddress;
  onConfirm: () => void;
  onDismiss: () => void;
};

export const SuggestedAlternative: React.FC<Props> = ({ suggestedAlternative, onConfirm, onDismiss }) => {
  return (
    <Banner type="question">
      <div className="flex">
        <div>
          <span>{"Did you mean "}</span>
          <b>{addressToString(suggestedAlternative, { oneLiner: true, hideCountry: true })}</b>
          <span>{"?"}</span>
        </div>
        <Button className="button-1" style={{ cursor: "pointer" }} onClick={onDismiss}>
          No
        </Button>
        <Button className="button-2" style={{ cursor: "pointer" }} onClick={onConfirm}>
          Yes
        </Button>
      </div>
    </Banner>
  );
};
