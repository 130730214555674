import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { Job, AggregatedJob, AggregatedTeamMember } from "dashboard/miter";
import { CleanedJob } from "dashboard/utils/jobUtils";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * JOBS
 */
type JobParams =
  | Job
  | Job[]
  | AggregatedJob
  | AggregatedJob[]
  | CleanedJob<AggregatedJob>
  | CleanedJob<AggregatedJob>[]
  | undefined;

type JobAction = "create" | "read" | "update" | "delete";
type JobAbilities = {
  can: (action: JobAction, items: JobParams) => boolean;
  cannot: (action: JobAction, items: JobParams) => boolean;
  filter: FilterBuilder;
  jobPredicate: (action?: JobAction) => (job: AggregatedJob | Job) => boolean;
  teamPredicate: (action?: JobAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useJobAbilities = (): JobAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: JobAction, items?: JobParams) => {
      if (!items) return false;

      const jobs = Array.isArray(items) ? items : [items];

      return jobs.every((job) => {
        const jobId = job._id;

        switch (action) {
          case "create":
            return can_("jobs:create");
          case "read":
            return can_("jobs:read", { job: jobId });
          case "update":
            return can_("jobs:update", { job: jobId });
          case "delete":
            return can_("jobs:delete", { job: jobId });
          default:
            return false;
        }
      });
    },
    [can_]
  );

  const cannot = useCallback(
    (action: JobAction, items: JobParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "jobs",
    jobField: { fieldName: "_id", fieldType: "_id" },
    appModule: "workforce_management",
  });

  /** Job member filter predicate */
  const jobPredicate = useAbilitiesJobPredicate<JobAction>("jobs");

  const teamPredicate = useAbilitiesTeamPredicate<JobAction>("jobs");

  return useMemo(
    () => ({ can, cannot, filter, jobPredicate, teamPredicate }),
    [can, cannot, filter, jobPredicate, teamPredicate]
  );
};
