import React, { FC, useState } from "react";
import {
  useActiveCompanyId,
  useActivityOptionsMap,
  useCardTransactionCategories,
  useCostTypeOptions,
  useJobOptions,
  useLedgerAccountOptions,
  usePermissionGroupOptions,
} from "dashboard/hooks/atom-hooks";
import { CardTransactionCategory, MiterAPI } from "dashboard/miter";
import { ActionModal, Formblock, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { isExpenseScoped } from "dashboard/pages/activities/activityUtils";
import { Option } from "ui/form/Input";
import InfoButton from "dashboard/components/information/information";
import * as formValidators from "dashboard/utils/validators";
import { JobInput } from "dashboard/components/shared/JobInput";
import { CardTransactionCategoryCreationParams } from "backend/services/expenses/card-transaction-categories-service";
import { withValue } from "dashboard/utils";
import { isCostTypeExpenseManagementScoped } from "dashboard/components/cost-types/costTypeUtils";
import { isJobSpendManagementScoped } from "../../jobs/jobUtils";

type CategoryFormParams = {
  name?: string;
  job?: Option<string>;
  job_locked: boolean;
  activity?: Option<string>;
  activity_locked: boolean;
  cost_type?: Option<string>;
  cost_type_locked: boolean;
  gl_account?: Option<string>;
  gl_account_locked: boolean;
  permission_group_ids: Option<string>[];
};

type Props = {
  selectedCategory?: CardTransactionCategory;
  onSave: () => void;
  onHide: () => void;
};

const CardTransactionCategoryModal: FC<Props> = ({ selectedCategory, onSave, onHide }) => {
  // hooks
  const activeCompanyId = useActiveCompanyId();
  const existingCategories = useCardTransactionCategories();

  const jobOptions = useJobOptions({
    defaultValue: selectedCategory?.job?.id,
    predicate: isJobSpendManagementScoped,
  });
  const activityOptionsMap = useActivityOptionsMap({
    predicate: isExpenseScoped,
  });
  const [activityOptions, setActivityOptions] = useState<Option<string>[]>(
    activityOptionsMap.get(selectedCategory?.job?.id)
  );
  const costTypeOptions = useCostTypeOptions({
    predicate: isCostTypeExpenseManagementScoped,
  });
  const ledgerAccountOptions = useLedgerAccountOptions();
  const selectablePermissionGroupOptions = usePermissionGroupOptions();

  const form = useForm<CategoryFormParams>({
    shouldUnregister: false,
    defaultValues: {
      name: selectedCategory?.name,
      job: jobOptions.find(withValue(selectedCategory?.job?.id)),
      activity: activityOptions.find(withValue(selectedCategory?.activity?.id)),
      cost_type: costTypeOptions.find(withValue(selectedCategory?.cost_type?.id)),
      gl_account: ledgerAccountOptions.find(withValue(selectedCategory?.gl_account?.id)),
      job_locked: !!selectedCategory?.job?.is_locked,
      activity_locked: !!selectedCategory?.activity?.is_locked,
      cost_type_locked: !!selectedCategory?.cost_type?.is_locked,
      gl_account_locked: !!selectedCategory?.gl_account?.is_locked,
      permission_group_ids: selectablePermissionGroupOptions.filter((pg) =>
        selectedCategory?.permission_group_ids?.includes(pg.value)
      ),
    },
  });
  const formData = form.watch();

  // state
  const [loading, setLoading] = useState<boolean>(false);

  const cleanParams = (data: CategoryFormParams): CardTransactionCategoryCreationParams | undefined => {
    const {
      name,
      job,
      job_locked,
      activity,
      activity_locked,
      cost_type,
      cost_type_locked,
      gl_account,
      gl_account_locked,
      permission_group_ids,
    } = data;

    // cannot submit until name is entered
    if (!name) return;
    // if creating a new category and the name matches an existing category, return
    if (!selectedCategory && existingCategories.some((existingCategory) => existingCategory.name === name)) {
      Notifier.error(`Category with name "${name}" already exists`);
      return;
    }

    return {
      _id: selectedCategory?._id ?? undefined,
      name,
      company_id: activeCompanyId!,
      job: job
        ? {
            id: job.value,
            is_locked: job_locked,
          }
        : null,
      activity: activity
        ? {
            id: activity.value,
            is_locked: activity_locked,
          }
        : null,
      cost_type: cost_type
        ? {
            id: cost_type.value,
            is_locked: cost_type_locked,
          }
        : null,
      gl_account: gl_account
        ? {
            id: gl_account.value,
            is_locked: gl_account_locked,
          }
        : null,
      permission_group_ids: permission_group_ids?.map((pg) => pg.value) || [],
    };
  };
  const upsertCategory = async (data: CategoryFormParams) => {
    try {
      const params = cleanParams(data);
      if (!params) return;
      setLoading(true);

      const res = await MiterAPI.expenses.categories.create_or_update(params);
      if (res.error) throw new Error(res.error);

      Notifier.success(`Category ${selectedCategory ? "updated" : "created"}`);
      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      console.error(`Error ${selectedCategory ? "updated" : "created"} category:`, e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    if (!selectedCategory) return;

    setLoading(true);
    try {
      const res = await MiterAPI.expenses.categories.archive(selectedCategory._id);
      if (res.error) throw new Error(res.error);

      Notifier.success("Category deleted.");
      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error deleting category:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const { control, register, errors, setValue, handleSubmit } = form;
  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="name"
          label="Name*"
          control={control}
          register={register(formValidators.required)}
          editing={true}
          errors={errors}
          style={{ width: "75%" }}
        />
        <div className="flex">
          <h3 className="settings-subheader">Team member selectable fields</h3>
          <InfoButton
            text={`Selecting "Lock" prevents the team member from editing the field after selecting a category.`}
          />
        </div>
        {/* job */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <div>
            <JobInput
              type="select"
              name="job"
              label="Job"
              form={form}
              control={control}
              options={jobOptions}
              errors={errors}
              editing={true}
              onChange={(e) => {
                const newJobId = e?.value;
                if (!newJobId) {
                  setValue("job_locked", false);
                }

                if (newJobId === formData.job?.value) return;

                const newActivityOptions = activityOptionsMap.get(newJobId);
                setActivityOptions(newActivityOptions);

                // reset activity if current activity is not in new job's cost code list
                if (newActivityOptions.every((o) => o.value !== formData.activity?.value)) {
                  setValue("activity", null);
                  setValue("activity_locked", false);
                }
              }}
              isClearable
            />
          </div>
          <Formblock
            type="checkbox"
            name="job_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData.job}
          />
        </div>
        {/* activity */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="select"
            name="activity"
            label="Activity"
            control={control}
            options={activityOptions}
            errors={errors}
            editing={true}
            isClearable
            onChange={(e) => {
              if (!e) {
                setValue("activity_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="activity_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData.activity}
          />
        </div>
        {/* cost type */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="select"
            name="cost_type"
            label="Cost type"
            control={control}
            options={costTypeOptions}
            errors={errors}
            editing={true}
            isClearable
            onChange={(e) => {
              if (!e) {
                setValue("cost_type_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="cost_type_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData.cost_type}
          />
        </div>
        {/* gl account */}
        <div style={{ display: "grid", gridTemplateColumns: "75% 20%", gap: "5%" }}>
          <Formblock
            type="select"
            name="gl_account"
            label="GL account"
            control={control}
            options={ledgerAccountOptions}
            errors={errors}
            editing={true}
            isClearable
            onChange={(e) => {
              if (!e) {
                setValue("gl_account_locked", false);
              }
            }}
          />
          <Formblock
            type="checkbox"
            name="gl_account_locked"
            text="Lock"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            disabled={!formData.gl_account}
          />
        </div>
        <h3 className="settings-subheader">Advanced</h3>
        <Formblock
          type="multiselect"
          name="permission_group_ids"
          label="Permission groups"
          labelInfo="If selected, only team members in these permission groups can select this category. Useful for locking down a category just for payroll/accounting, etc."
          form={form}
          editing={true}
          placeholder={"Select permission groups"}
          options={selectablePermissionGroupOptions}
          height={"unset"}
          style={{ width: "75%" }}
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={selectedCategory ? "Edit category" : "Create category"}
      showSubmit={true}
      showCancel={true}
      showDelete={true}
      deleteDisabled={selectedCategory == null}
      onDelete={handleDelete}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={handleSubmit(upsertCategory)}
      loading={loading}
      wrapperStyle={{ minWidth: 600 }}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default CardTransactionCategoryModal;
