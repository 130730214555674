import { formatPhoneNumber } from "dashboard/utils";
import { truncate } from "lodash";
import React, { FC } from "react";
import { FaArrowCircleRight, FaEnvelope, FaExternalLinkSquareAlt, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Label } from "ui";
import styles from "../Chat.module.css";
import { useActiveRecruitingConversation, useLookupJobPostings } from "dashboard/hooks/atom-hooks";
import { AggregatedCandidate } from "dashboard/types/ats";

type Props = {
  candidate: AggregatedCandidate;
};

export const RecruitingChatProfile: FC<Props> = ({ candidate }) => {
  /** Atom hooks */
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const lookUpJobPosting = useLookupJobPostings();

  /** Render functions */
  const renderHeader = () => {
    const candidatePath = "/recruiting/candidates/" + activeRecruitingConversation?.candidate_id;

    return (
      <div className={styles["chat-subheader"] + " " + styles["conversation-profile-subheader"]}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ marginRight: 5 }} className={styles["chat-subheader-title"]}>
            Profile
          </h2>
          {renderMultipleCandidatesLabel()}
        </div>

        <Link target="_blank" to={candidatePath} className={styles["team-member-link"]}>
          <FaExternalLinkSquareAlt className={styles["team-member-link-icon"]} />
        </Link>
      </div>
    );
  };

  const renderMultipleCandidatesLabel = () => {
    const totalNamesLength = activeRecruitingConversation?.candidate_names.length || 0;
    if (totalNamesLength <= 1) return null;
    const namesConcatenated = activeRecruitingConversation?.candidate_names
      .filter((name) => name !== activeRecruitingConversation.primary_candidate_name)
      .join(", ");
    return (
      <Label
        labelInfo={`The following candidates are also tied to this phone number: ${namesConcatenated}.`}
        label={`(+${totalNamesLength - 1})`}
        underlineTooltip={true}
      />
    );
  };

  const renderCandidateData = () => {
    if (!candidate) return;
    const fullName = candidate.first_name + " " + candidate.last_name;

    return (
      <div className={styles["team-member-data"]}>
        <h3 className={styles["team-member-name"]}>{fullName} </h3>

        <h3 className={styles["team-member-data-subtitle"]}>Contact</h3>

        <a
          target="_blank"
          href={"tel:" + candidate.phone}
          className={styles["team-member-phone"]}
          rel="noreferrer"
        >
          <FaPhone className={styles["team-member-phone-icon"]} />
          {formatPhoneNumber(candidate.phone)}
        </a>

        <a
          target="_blank"
          href={"mailto:" + candidate.email}
          className={styles["team-member-email"]}
          rel="noreferrer"
        >
          <FaEnvelope className={styles["team-member-email-icon"]} />
          {truncate(candidate.email, { length: 22 })}
        </a>
      </div>
    );
  };

  const renderApplications = () => {
    if (!candidate) return;

    const hasApplications = candidate.job_applications.length > 0;

    const applicationElements = candidate.job_applications.map((application) => (
      <div className={styles["team-member-job"]} key={application._id}>
        <Link
          target="_blank"
          to={"/recruiting/job-applications/" + application._id}
          className={styles["team-member-job-link"]}
        >
          <span className={styles["team-member-job-name"]}>
            {truncate(lookUpJobPosting(application.job_posting_id)?.title || "Job application", {
              length: 20,
            })}
          </span>
          <FaArrowCircleRight className={styles["team-member-job-link-icon"]} />
        </Link>
      </div>
    ));

    return (
      <div className={styles["team-member-jobs"]}>
        <h3 className={styles["team-member-jobs-subtitle"]}>Applications</h3>
        {hasApplications ? (
          <div className={styles["job-list"]}> {applicationElements}</div>
        ) : (
          <p className={styles["team-member-jobs-empty"]}>No applications</p>
        )}
      </div>
    );
  };

  return (
    <div className={styles["conversations-profile-container"]}>
      {renderHeader()}
      {renderCandidateData()}
      {renderApplications()}
    </div>
  );
};
