import React from "react";
import { useEffect } from "react";

import { useState } from "react";
import Notifier from "dashboard/utils/notifier";
import { Message, MiterAPI } from "dashboard/miter";
import { Badge, Button, FileIconList, Formblock, Loader, ModalWithSidebar } from "ui";
import { DateTime } from "luxon";
import { addressToString, downloadPdfFromBase64String, shorten } from "dashboard/utils/utils";
import FilesTable from "dashboard/components/tables/FilesTable";
import { truncate } from "lodash";
import DailyReportsTable from "dashboard/components/daily-reports/DailyReportsTable";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";

const uspsUrl = "https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=";

type Props = {
  job_id: string;
};

const contentTypeLookup = {
  das_140: "DAS 140",
  das_142: "DAS 142",
};

const messageTypeLookup = {
  email: "Email",
  mail: "Certified mail",
  fax: "Fax",
};

const TrackLink = ({ tracking_num }) => {
  if (!tracking_num) return null;
  const handleClick = () => {
    const url = uspsUrl + tracking_num;
    window.open(url, "_blank");
  };
  return (
    <span className="blue-link" onClick={handleClick}>
      Track
    </span>
  );
};

const MessageSummary: React.FC<{ message: Message }> = ({ message }) => {
  const [downloading, setDownloading] = useState(false);

  // Hide proof of submission is this was a fax message and the fax wasn't not successfully sent
  const hideDownloadProof = message.sent_fax && message.sent_fax.status !== "success";

  const downloadProofOfSubmission = async (id) => {
    setDownloading(true);
    try {
      const response = await MiterAPI.messages.get_proof(id);
      if (response.error) throw new Error(response.error);
      await downloadPdfFromBase64String(response.data, response.filename);
    } catch (e) {
      Notifier.error(
        "There was an error downloading the submission. Please contact support@miter.com if the problem persists."
      );
    }
    setDownloading(false);
  };

  const tracking_num = message.sent_letter?.tracking_number;
  const track = () => {
    const url = uspsUrl + tracking_num;
    window.open(url, "_blank");
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="vertical-spacer-small"></div>
      <Formblock
        type="text"
        label="Sent at"
        defaultValue={DateTime.fromSeconds(message.sent_at).toLocaleString(DateTime.DATETIME_MED)}
      />
      <Formblock type="text" label="Sent via">
        <div>{messageTypeLookup[message.type]}</div>
      </Formblock>
      <Formblock type="text" label="Message content">
        {contentTypeLookup[message.content_type] || "-"}
      </Formblock>
      {message.mail_params && (
        <div>
          <Formblock
            type="text"
            label="USPS tracking"
            defaultValue={addressToString(message.mail_params.toAddress)}
          >
            <span className="blue-link" onClick={track}>
              Track package
            </span>
          </Formblock>
          <Formblock type="text" label="To" defaultValue={addressToString(message.mail_params.toAddress)} />
          {message.mail_params.cover_letter_text && (
            <Formblock
              type="text"
              label="Cover letter"
              defaultValue={shorten(message.mail_params.cover_letter_text, 50)}
            />
          )}
        </div>
      )}{" "}
      {message.email_params && (
        <div>
          <Formblock type="text" label="To" defaultValue={shorten(message.email_params?.to.join(", "), 50)} />
          <Formblock type="text" label="Subject" defaultValue={message.email_params?.subject} />
          <Formblock type="text" label="Body" defaultValue={message.email_params?.text} />
        </div>
      )}
      {message.fax_params && (
        <div>
          <Formblock
            type="text"
            label="To"
            defaultValue={
              message.sent_fax?.recipients
                ? message.sent_fax.recipients.map((r) => r.phone_number).join(", ")
                : ""
            }
          />
          <Formblock
            type="text"
            label="Status"
            defaultValue={
              message.sent_fax?.status === "success"
                ? "DELIVERED"
                : message.sent_fax?.status === "failure"
                ? "FAILED"
                : "PENDING DELIVERY"
            }
          />
        </div>
      )}
      <div className="vertical-spacer"></div>
      {!hideDownloadProof && (
        <Button
          onClick={() => downloadProofOfSubmission(message._id)}
          loading={downloading}
          className="button-2 no-margin"
          text="Download proof of submission"
        />
      )}
    </div>
  );
};

const Outbox: React.FC<Props> = ({ job_id }) => {
  const [messages, setMessages] = useState<$TSFixMe[] | undefined>();
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [messageModal, setMessageModal] = useState<$TSFixMe | undefined>();

  const getMessages = async () => {
    setFetchingMessages(true);
    try {
      const filter = [{ field: "job", value: job_id }];
      const response = await MiterAPI.messages.list(filter);
      //@ts-expect-error error cleanup
      if (response.error!) throw new Error(response.error);
      const cleanedMessages = response.map((m) => {
        const badgeText = (m.email_params && "Email") || (m.fax_params && "Fax") || "Certified mail";
        const badgeColor = (m.mail_params && "green") || (m.fax_params && "orange") || "blue";

        let recipient: string | undefined = "";
        if (m.type === "email") {
          recipient = truncate(m.email_params?.to.join(", "), { length: 50 });
        } else if (m.type === "fax") {
          recipient = m.sent_fax?.recipients ? m.sent_fax.recipients[0]?.phone_number : "";
        } else {
          recipient = m.mail_params?.toAddress?.postal_name;
        }

        return {
          ...m,
          type_badge: <Badge table={true} text={badgeText} color={badgeColor} />,
          sent_time: DateTime.fromSeconds(m.sent_at).toLocaleString(DateTime.DATETIME_MED),
          recipient: truncate(recipient, { length: 30 }),
          content: contentTypeLookup[m.content_type] || "-",
          track_link: <TrackLink tracking_num={m.sent_letter?.tracking_number} />,
          attachments: <FileIconList files={m.files} />,
        };
      });
      setMessages(cleanedMessages);
    } catch (e) {
      console.log(e);
      Notifier.error("Error loading messages. We're looking into it!");
    }
    setFetchingMessages(false);
  };

  const columns: ColumnConfig<$TSFixMe>[] = [
    {
      field: "sent_time",
      headerName: "Sent at",
    },
    {
      field: "type_badge",
      headerName: "Sent via",
      dataType: "component",
    },
    {
      field: "recipient",
      headerName: "Recipient",
    },
    {
      field: "content",
      headerName: "Content",
    },
    {
      field: "attachments",
      headerName: "Attachments",
      dataType: "component",
    },
    {
      field: "track_link",
      headerName: " ",
      dataType: "component",
    },
  ];

  const handleRowClick = (message) => {
    const dString = DateTime.fromSeconds(message.sent_at).toLocaleString(DateTime.DATE_SHORT);
    const mType = message.type || message.message_type;
    const contentType = contentTypeLookup[message.content_type];
    const headerString = contentType
      ? `${dString} ${contentType} submission via ${mType}`
      : `${dString} message via ${mType}`;
    const fileIds = message.files.map((f) => f._id);
    const defaultFilters = [
      {
        field: "_id",
        comparisonType: "in" as const,
        value: fileIds || message.daily_report_ids,
        type: "_id" as const,
      },
    ];
    const menuItems = [
      {
        label: "Summary",
        path: "summary",
        component: <MessageSummary message={message} />,
      },
      {
        label: "Attachments",
        path: "attachments",
        component: (
          <>
            {message.files?.length ? (
              <FilesTable
                smallWidth={true}
                defaultFilters={defaultFilters}
                parentType="message"
                parentId={message._id}
                hideActions={true}
              />
            ) : (
              <></>
            )}
            {message.daily_report_ids?.length ? (
              <DailyReportsTable defaultFilters={defaultFilters} />
            ) : (
              <> </>
            )}
          </>
        ),
      },
    ];
    setMessageModal({
      header: headerString,
      menuItems: menuItems,
    });
  };

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {fetchingMessages && <Loader />}
      {messageModal && (
        <ModalWithSidebar
          height={450}
          width={750}
          {...messageModal}
          hide={() => setMessageModal(undefined)}
        />
      )}
      {!fetchingMessages && messages && (
        <div>
          <TableV2
            id="outbox-table"
            resource={"messages"}
            data={messages}
            columns={columns}
            rowSelectDisabled={() => true}
            onClick={handleRowClick}
          />
        </div>
      )}
    </div>
  );
};

export default Outbox;
