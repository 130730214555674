import React, { useContext, useMemo } from "react";
import {
  GranularityLineConfig,
  LedgerMappingLineInfo,
  noJobNoActivityLineTypes,
  useLedgerLineTypes,
} from "../accountingUtils";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { ColumnConfig, ColumnOrGroupConfig, TableV2 } from "ui/table-v2/Table";
import { GranularityLevel, LedgerGranularityConfig } from "backend/utils/accounting";
import { BulkUpdateResult, MiterAPI } from "dashboard/miter";
import { cloneDeep } from "lodash";
import AppContext from "dashboard/contexts/app-context";
import { Notifier } from "dashboard/utils";
import Banner from "dashboard/components/shared/Banner";

export const LedgerEntryGranularity: React.FC<{}> = () => {
  const activeCompany = useActiveCompany();
  const { fetchUserData } = useContext(AppContext);

  const granularityConfig: LedgerGranularityConfig | undefined =
    activeCompany?.settings.ledger_entries?.granularity;

  const lineTypes = useLedgerLineTypes({ isGranularity: true });

  const updateGranularity = async (data: GranularityLineConfig[]) => {
    const ret: BulkUpdateResult = { successes: [], errors: [] };
    if (!activeCompany?._id) return ret;
    try {
      const updateConfig = cloneDeep(granularityConfig) || {};
      for (const d of data) {
        const dims: GranularityLevel[] = [];
        if (d.department) {
          dims.push("department");
        }
        if (d.location) {
          dims.push("location");
        }
        if (d.class) {
          dims.push("class");
        }
        if (d.teamMember) {
          dims.push("team_member");
        }
        // @ts-expect-error fix me
        if (d.job && !noJobNoActivityLineTypes.includes(d._id)) {
          dims.push("job");
        }
        // @ts-expect-error fix me
        if (d.activity && !noJobNoActivityLineTypes.includes(d._id)) {
          dims.push("activity");
        }
        // Cost types can't be associated with anything other than an expense. By definition!
        // Burden rate offsets can technically be expenses too, because maybe the expense side of it is actually used to hit the balance sheet as the offset and it's reversed
        if (d.costType && (d.flavor === "Expense" || d._id === "burden_rate_offset")) {
          dims.push("cost_type");
        }
        updateConfig[d._id] = dims;
      }
      const response = await MiterAPI.companies.update(activeCompany._id, {
        "settings.ledger_entries.granularity": updateConfig,
      });
      if (response.error) throw new Error(response.error);
      await fetchUserData();
    } catch (e: $TSFixMe) {
      Notifier.error("There was an error updating your settings. Please try again or reach out to support.");
    }
    return ret;
  };

  const entries = useMemo(() => {
    return lineTypes.map((cd): GranularityLineConfig => {
      const lineGran = granularityConfig?.[cd._id] || [];
      return {
        ...cd,
        department: lineGran.includes("department"),
        teamMember: lineGran.includes("team_member"),
        job: lineGran.includes("job"),
        activity: lineGran.includes("activity"),
        location: lineGran.includes("location"),
        costType: lineGran.includes("cost_type"),
        class: lineGran.includes("class"),
      };
    });
  }, [granularityConfig, lineTypes]);

  return (
    <>
      <Banner
        className="top-20"
        content={
          "Use these granularity settings to control how detailed your GL entries are. If you check a box in the below grid, the associated line items will be broken out by that dimension."
        }
      />
      <TableV2
        data={entries}
        columns={columns}
        resource="granularity settings"
        id="company-gl-granularity"
        editable
        onSave={updateGranularity}
        hideFooter
      />
    </>
  );
};

const baseDef: Partial<ColumnConfig<LedgerMappingLineInfo>> = {
  dataType: "boolean",
  editable: true,
  editorType: "checkbox",
  minWidth: 100,
};

const columns: ColumnOrGroupConfig<LedgerMappingLineInfo>[] = [
  {
    field: "label",
    headerName: "Label",
    dataType: "string",
    filter: "agSetColumnFilter",
    tooltipValueGetter: (params) => params.data?.description,
    minWidth: 250,
    pinned: "left",
  },
  {
    field: "flavor",
    headerName: "Type",
    dataType: "string",
    filter: "agSetColumnFilter",
  },
  {
    field: "creditOrDebit",
    headerName: "Credit/Debit",
    dataType: "string",
    filter: "agSetColumnFilter",
  },
  {
    ...baseDef,
    field: "department",
    headerName: "Department",
  },
  {
    ...baseDef,
    field: "location",
    headerName: "Location",
  },
  {
    ...baseDef,
    field: "class",
    headerName: "Class",
  },
  {
    ...baseDef,
    field: "teamMember",
    headerName: "Team member",
  },
  {
    ...baseDef,
    field: "job",
    headerName: "Job",
    headerTooltip: "Job (not applicable to certain liabilities)",
  },
  {
    ...baseDef,
    field: "activity",
    headerName: "Activity",
    headerTooltip: "Activity (not applicable to certain liabilities)",
  },
  {
    ...baseDef,
    field: "costType",
    headerName: "Cost Type",
    headerTooltip: "Cost Type (only applicable to expenses)",
  },
];
