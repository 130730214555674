import { useMemo, useState } from "react";
import { useActivityOptions, useLookupActivity, useSetActivities } from "dashboard/hooks/atom-hooks";
import { Activity, AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { notNullish } from "miter-utils";
import { BulkSelectOptionValue } from "packages/ui/bulk-actions/BulkSelector";
import { BulkSelectorModal } from "ui";

// Accessibility of an activity can be edited if:
// 1. It has no exclusion groups.
// 2. All team members were added individually (e.g., team_member_group.type === "team_member").
const canEditAccessibility = (activity: Activity, teamMemberId: string) => {
  const customAccessFilter = activity.custom_access_filter;
  const excludedGroups = customAccessFilter?.excluded_groups ?? [];

  if (excludedGroups.length === 0) return true;

  const allExclusionsAreTeamMembers = excludedGroups.every(
    (excludedGroup) => excludedGroup.type === "team_member"
  );
  const teamMemberIsExcluded = excludedGroups.some(
    (excludedGroup) => excludedGroup.type === "team_member" && excludedGroup.value === teamMemberId
  );

  return allExclusionsAreTeamMembers && teamMemberIsExcluded;
};

type TeamMemberActivityAssociationsProps = {
  teamMember: AggregatedTeamMember;
  associatedActivityIds: Set<string>;
  showBulkSelector: boolean;
  setShowBulkSelector: (showBulkSelector: boolean) => void;
};

export const TeamMemberActivityAssociations: React.FC<TeamMemberActivityAssociationsProps> = ({
  associatedActivityIds,
  teamMember,
  showBulkSelector,
  setShowBulkSelector,
}: TeamMemberActivityAssociationsProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const activityOptions = useActivityOptions();
  const lookupActivity = useLookupActivity();
  const setActivities = useSetActivities();

  const selections = useMemo(() => {
    return activityOptions
      .filter((activityOption) => associatedActivityIds.has(activityOption.value))
      .map((o) => o.value);
  }, [associatedActivityIds, activityOptions]);

  const accessibleActivityOptions = useMemo(() => {
    return activityOptions.map((activityOption) => {
      const activity = lookupActivity(activityOption.value);
      const label = activity?.label + (activity?.cost_code ? ` (${activity?.cost_code})` : "");
      const canEditActivityAccessibility =
        selections.includes(activity!._id) || canEditAccessibility(activity!, teamMember._id);

      return {
        label,
        value: activityOption.value,
        locked: !canEditActivityAccessibility
          ? "Team member has access through a group they belong to. Go to activity to update accessibility."
          : undefined,
        disabled: !canEditActivityAccessibility,
      };
    });
  }, [selections, activityOptions, lookupActivity, teamMember._id]);

  const buildUpdateActivityParams = (
    activityId: string,
    teamMemberId: string,
    type: "include" | "exclude"
  ): { id: string; updateParams: Partial<Activity> } | undefined => {
    const activity = lookupActivity(activityId);
    if (!activity) return;

    const activityCustomAccessFilter = activity.custom_access_filter;
    const includedGroups = activityCustomAccessFilter?.included_groups || [];
    const excludedGroups = activityCustomAccessFilter?.excluded_groups || [];
    const enableCustomAccessFilters = activity.enable_custom_access_filter;
    const shouldEnableCustomAccessFilters =
      includedGroups.length === 0 && excludedGroups.length === 0 && !enableCustomAccessFilters;

    const teamMemberGroupValue = { type: "team_member" as const, value: teamMemberId };

    if (type === "exclude") {
      const teamMemberGroupWithoutTeamMember = includedGroups.filter(
        (includedGroup) => includedGroup.type !== "team_member" && includedGroup.value !== teamMemberId
      );

      return {
        id: activity._id,
        updateParams: {
          enable_custom_access_filter: shouldEnableCustomAccessFilters
            ? shouldEnableCustomAccessFilters
            : undefined,
          custom_access_filter: {
            // if team member is added directly, just remove them
            included_groups: teamMemberGroupWithoutTeamMember,
            // if team member is added via a group association, explicitly exclude them
            excluded_groups: [...excludedGroups, teamMemberGroupValue],
          },
        },
      };
    } else {
      const teamMemberGroupWithoutTeamMember = excludedGroups.filter(
        (excludedGroup) => excludedGroup.type !== "team_member" && excludedGroup.value !== teamMemberId
      );

      return {
        id: activity._id,
        updateParams: {
          enable_custom_access_filter: shouldEnableCustomAccessFilters
            ? shouldEnableCustomAccessFilters
            : undefined,
          custom_access_filter: {
            included_groups: [...includedGroups, teamMemberGroupValue],
            excluded_groups: teamMemberGroupWithoutTeamMember,
          },
        },
      };
    }
  };

  const updateActivity = async (
    activitiesUpdateParamsArray: { id: string; updateParams: Partial<Activity> }[]
  ): Promise<void> => {
    try {
      const results: Activity[] = [];
      await Promise.all(
        activitiesUpdateParamsArray.map(async (activitiesUpdateParams) => {
          const response = await MiterAPI.activities.update(
            activitiesUpdateParams.id,
            activitiesUpdateParams.updateParams
          );
          if (response.error) {
            Notifier.error("Error updating accessible activities");
          } else {
            results.push(response);
          }
        })
      );

      Notifier.success(`Successfully updated accessible activities`);

      setActivities((prev) => prev.concat(results));
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the activity");
    }
  };

  const handleSubmit = async (updatedSelections: BulkSelectOptionValue[]) => {
    const newSelections: BulkSelectOptionValue[] = updatedSelections
      .filter((updatedSelection) => selections.includes(updatedSelection) === false)
      .map((selection) => selection);

    const removedSelections: BulkSelectOptionValue[] = selections
      .filter((originallySelected) => updatedSelections.includes(originallySelected) === false)
      .map((selection) => selection);

    setSubmitting(true);

    const updateActivityArrayParams = [
      ...newSelections.map((newSelection) =>
        buildUpdateActivityParams(newSelection, teamMember._id, "include")
      ),
      ...removedSelections.map((removeSelection) =>
        buildUpdateActivityParams(removeSelection, teamMember._id, "exclude")
      ),
    ];

    await updateActivity(updateActivityArrayParams.filter(notNullish));

    setSubmitting(false);
    setShowBulkSelector(false);
  };

  return (
    <>
      {showBulkSelector && (
        <BulkSelectorModal
          options={accessibleActivityOptions}
          selections={selections}
          resource={"activities"}
          properties={["name", "cost_code"]}
          onSubmit={handleSubmit}
          onCancel={() => setShowBulkSelector(false)}
          submitting={submitting}
        />
      )}
    </>
  );
};
