import {
  useActiveCompanyId,
  useRefetchRejectionReasons,
  useMessageTemplateOptions,
  useLookupRejectionReason,
  useLookupMessageTemplate,
} from "dashboard/hooks/atom-hooks";
import { ActionModal, Formblock, Notifier } from "ui";
import { Option } from "ui/form/Input";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { MiterAPI, MiterError } from "dashboard/miter";
import { RejectionReason } from "dashboard/types/rejection-reason-types";
import { MessageTemplate } from "dashboard/types/message-template";
import { REJECTION_REASON_CATEGORY_OPTIONS, REJECTION_REASONS_CATEGORY_MAP } from "dashboard/utils/ats";
import styles from "./RejectionReason.module.css";

type Props = {
  onClose: () => void;
  rejectionReasonId?: string;
};

type RejectionReasonForm = {
  name: string;
  category: Option<RejectionReason["category"]>;
  message_template_id: Option<string>;
};

export const RejectionReasonModal: FC<Props> = ({ onClose, rejectionReasonId }) => {
  const activeCompanyId = useActiveCompanyId();
  const refetchRejectionReasons = useRefetchRejectionReasons();
  const lookupRejectionReason = useLookupRejectionReason();
  const rejectionReason = lookupRejectionReason(rejectionReasonId);
  const messageTemplateOptions = useMessageTemplateOptions();
  const lookupMessageTemplate = useLookupMessageTemplate();

  const form = useForm<RejectionReasonForm>({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: buildDefaultValues(rejectionReason, lookupMessageTemplate),
  });

  const {
    register,
    watch,
    trigger,
    formState: { isValid, errors },
  } = form;
  const data = watch();

  const onSubmit = async () => {
    try {
      if (!activeCompanyId) {
        Notifier.error("Your browser session expired. Please refresh your page and try again.");
        return;
      }

      trigger();

      if (!isValid || Object.keys(errors).length > 0) {
        Notifier.error("Please fill out all required fields.");
        return;
      }

      let res: (RejectionReason & MiterError) | undefined;

      if (rejectionReason) {
        res = await MiterAPI.rejection_reasons.update(rejectionReason._id, {
          message_template_id: data.message_template_id?.value || null,
        });
      } else {
        res = await MiterAPI.rejection_reasons.create({
          company_id: activeCompanyId,
          name: data.name,
          category: data.category?.value as RejectionReason["category"],
          message_template_id: data.message_template_id?.value || null,
        });
      }

      if (res.error) {
        throw new Error(res.error);
      }
      Notifier.success(`Rejection reason ${rejectionReason ? "updated" : "created"} successfully.`);
      onClose();
      refetchRejectionReasons();
    } catch (error: $TSFixMe) {
      Notifier.error(`Failed to ${rejectionReason ? "update" : "create"} rejection reason: ${error.message}`);
    }
  };

  return (
    <ActionModal
      headerText={`${rejectionReason ? "Edit" : "Create"} a rejection reason`}
      showSubmit={true}
      submitText="Save"
      showCancel={true}
      cancelText="Cancel"
      onCancel={onClose}
      onHide={onClose}
      onSubmit={onSubmit}
      wrapperClassName={styles["rejection-reason-modal-wrapper"]}
    >
      <div className={styles["rejection-reason-modal-content"]}>
        <Formblock
          label="Name*"
          labelInfo="Name of the rejection (disposition) reason."
          type="text"
          placeholder="Enter reason name"
          editing={true}
          form={form}
          className="modal"
          name="name"
          disabled={!!rejectionReason}
          register={register(vals.required)}
          style={{ marginBottom: 20 }}
        />
        <Formblock
          label="Category*"
          type="select"
          placeholder="Select category"
          labelInfo="This categorizes dispositions as 'Rejected' or 'Not Interested' for reporting purposes."
          editing={true}
          form={form}
          className="modal"
          name="category"
          register={register(vals.required)}
          options={REJECTION_REASON_CATEGORY_OPTIONS}
          requiredSelect={true}
          disabled={!!rejectionReason}
          style={{ marginBottom: 20 }}
        />
        <Formblock
          label="Default message template"
          labelInfo="When you use this rejection reason, the selected message template will be the default when sending the candidate a rejection SMS."
          type="select"
          placeholder="Select message template"
          editing={true}
          form={form}
          className="modal"
          name="message_template_id"
          register={register()}
          options={messageTemplateOptions}
          isClearable={true}
        />
      </div>
    </ActionModal>
  );
};

const buildDefaultValues = (
  rejectionReason: RejectionReason | undefined,
  lookupMessageTemplate: (id: string) => MessageTemplate | undefined
) => {
  const messageTemplateLabel = lookupMessageTemplate(rejectionReason?.message_template_id || "")?.name;

  return {
    name: rejectionReason?.name,
    category: rejectionReason
      ? {
          label: REJECTION_REASONS_CATEGORY_MAP[rejectionReason.category],
          value: rejectionReason.category,
        }
      : undefined,
    message_template_id:
      messageTemplateLabel && rejectionReason?.message_template_id
        ? {
            label: messageTemplateLabel,
            value: rejectionReason.message_template_id,
          }
        : undefined,
  };
};
