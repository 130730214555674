import { Notifier } from "dashboard/utils";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { ActionModal, BasicModal, Formblock, TableV2 } from "ui";
import { MiterAPI, RateDifferential } from "dashboard/miter";
import InfoButton from "dashboard/components/information/information";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import {
  useActiveCompanyId,
  useRateDifferentials,
  useRefetchRateDifferentials,
} from "dashboard/hooks/atom-hooks";
import { useSetRateDifferentials } from "dashboard/hooks/atom-hooks";
import { TableActionLink } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { getOtMultiplierTooltip } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";

type RateDifferentialTableEntry = RateDifferential & {};

type Props = {};

export const RateDifferentials: React.FC<Props> = ({}) => {
  const rateDifferentials = useRateDifferentials();
  const refetchRateDifferentials = useRefetchRateDifferentials();
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState<RateDifferentialTableEntry | undefined>();
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [selectedRates, setSelectedRates] = useState<RateDifferentialTableEntry[]>([]);

  const columns: ColDef<RateDifferentialTableEntry>[] = [
    {
      field: "label",
      headerName: "Label",
      minWidth: 150,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Differential type",
      minWidth: 300,
      sortable: false,
      cellRenderer: (params) => {
        return rateModifierTypeLookup[params.value];
      },
    },
    {
      field: "value",
      headerName: "Differential value",
      minWidth: 200,
      sortable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.type === "pct_diff") {
          return `${params.value}%`;
        } else {
          return `$${params.value.toFixed(2)}`;
        }
      },
    },
  ];

  const archiveDifferentials = async () => {
    setArchiving(true);
    await Promise.all(
      selectedRates.map(async (r) => {
        try {
          const response = await MiterAPI.rate_differentials.update(r._id, { archived: true });
          if (response.error) throw new Error(response.error);
        } catch (e) {
          console.error(e);
          Notifier.error("There was an error deleting rate differentials. We're looking into it!");
        }
      })
    );
    await refetchRateDifferentials(selectedRates.map((r) => r._id));
    setShowArchiveModal(false);
    setSelectedRates([]);
    setArchiving(false);
  };

  const tableEntries: RateDifferentialTableEntry[] = useMemo(() => {
    return rateDifferentials;
  }, [rateDifferentials]);

  const handleModalHide = () => {
    setCreating(false);
    setUpdating(undefined);
  };

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "New",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    []
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setShowArchiveModal(true),
        loading: archiving,
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
      },
    ],
    [archiving]
  );

  return (
    <div className="billing-card-wrapper" style={{ height: "auto" }}>
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Rate differentials</div>
        <InfoButton text="Use rate differentials for overscale employees or to modify pay rates for certain shifts." />
      </div>
      <div className="vertical-spacer-small"></div>
      <TableV2
        id="rate-differentials-table"
        resource="rate differentials"
        columns={columns}
        data={tableEntries}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        hideSecondaryActions={true}
        containerStyle={{ height: 500 }}
        hideExporter={true}
        onSelect={setSelectedRates}
        onClick={(r) => setUpdating(r)}
      />
      {(creating || updating) && (
        <RateDifferentialModal hide={handleModalHide} rateDifferentialToUpdate={updating} />
      )}
      {showArchiveModal && (
        <BasicModal
          headerText={`Delete rate differential ${selectedRates.length > 1 ? "s" : ""}?`}
          yellowBodyText={true}
          loading={archiving}
          bodyText={`Are you sure you want to delete ${
            selectedRates.length > 1 ? "these rates" : "this rate"
          }?`}
          button2Action={archiveDifferentials}
          button2Text="Delete"
          button1Action={() => setShowArchiveModal(false)}
          button2ClassName="button-3 no-margin"
        />
      )}
    </div>
  );
};

type RateDifferentialModalProps = {
  hide: () => void;
  rateDifferentialToUpdate?: RateDifferentialTableEntry | undefined;
};

const RateDifferentialModal: React.FC<RateDifferentialModalProps> = ({ rateDifferentialToUpdate, hide }) => {
  // Hooks
  const setRateDifferentials = useSetRateDifferentials();
  const activeCompanyId = useActiveCompanyId();
  const form = useForm();
  const { register, handleSubmit, control, errors } = form;

  const [loading, setLoading] = useState(false);
  const initialOtMultipliers = Object.entries(rateDifferentialToUpdate?.ot_multipliers || {}).reduce(
    (obj, [key, value]) => {
      obj[key] = value?.toString();
      return obj;
    },
    {} as { ot: string; dot: string }
  );
  const [otMultipliers, setOtMultipliers] = useState(initialOtMultipliers);
  const [payRateModifierType, setPayRateModifierType] = useState<RateDifferential["type"]>(
    rateDifferentialToUpdate?.type || "dollar_diff"
  );

  const handleOtMultiplierChange = (e) => {
    const timeTypeKey = e.target.name.split("_")[0];
    let stringVal = e.target.value;
    const numValue = Number(e.target.value);
    if (["ot", "dot"].includes(timeTypeKey) && !isNaN(numValue)) {
      // Number("") coerces to 0, so we need to check for that
      if (numValue === 0 && stringVal !== "0") stringVal = null;
      setOtMultipliers((prev) => ({ ...prev, [timeTypeKey]: stringVal }));
    }
  };

  const save = async (data) => {
    setLoading(true);
    try {
      const cleanedOtMultipliers = Object.entries(otMultipliers || {}).reduce((acc, [key, value]) => {
        const numVal = Number(value);
        const stringVal = value?.toString();
        if (!isNaN(Number(value))) {
          if (numVal === 0 && stringVal !== "0") {
            acc[key] = null;
          } else {
            acc[key] = Number(value);
          }
        }
        return acc;
      }, {});
      const params = {
        company_id: activeCompanyId!,
        label: data.label,
        type: payRateModifierType,
        value: data.value,
        ot_multipliers: cleanedOtMultipliers,
      };
      let response;
      if (rateDifferentialToUpdate) {
        response = await MiterAPI.rate_differentials.update(rateDifferentialToUpdate._id, params);
      } else {
        response = await MiterAPI.rate_differentials.create(params);
      }
      if (response.error) throw new Error(response.error);
      setRateDifferentials((prev) => prev.concat(response));
      hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error saving the rate differential. We're looking into it!");
    }
    setLoading(false);
  };

  const payRateModifierOptions = [
    { value: "dollar_diff", label: "$/hour differential" },
    { value: "pct_diff", label: "% differential" },
  ];

  return (
    <ActionModal
      headerText={`${rateDifferentialToUpdate ? "Edit" : "Create"} rate differential`}
      onHide={hide}
      showCancel={true}
      onCancel={hide}
      showSubmit={true}
      onSubmit={handleSubmit(save)}
      submitText={"Submit"}
      loading={loading}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Label*"
        labelInfo="The name of the rate differential."
        className="modal"
        defaultValue={rateDifferentialToUpdate?.label}
        type="text"
        register={register(vals.required)}
        name="label"
        errors={errors}
        editing={true}
      />
      <Formblock
        label="Rate differential type*"
        labelInfo="How the pay rate should be modified."
        className="modal"
        type="select"
        control={control}
        options={payRateModifierOptions}
        value={payRateModifierOptions.find((o) => o.value === payRateModifierType)}
        onChange={(o) => setPayRateModifierType(o.value)}
        register={register(vals.required)}
        name="type"
        errors={errors}
        editing={true}
      />
      <Formblock
        label={rateModifierTypeLookup[payRateModifierType]}
        className="modal"
        type={payRateModifierType === "pct_diff" ? "percent" : `unit`}
        inputProps={{ style: { width: "100%" } }}
        unit="$"
        control={control}
        defaultValue={rateDifferentialToUpdate?.value || 0}
        register={register(vals.required)}
        name="value"
        errors={errors}
        editing={true}
      />
      <div className="flex">
        <Formblock
          type="text"
          label="OT multiplier"
          labelInfo={getOtMultiplierTooltip("ot", "rate_diffs")}
          form={form}
          value={otMultipliers?.ot?.toString()}
          onChange={handleOtMultiplierChange}
          name="ot_multiplier"
          editing={true}
          className="modal"
        />
        <div style={{ width: 15 }}></div>
        <Formblock
          type="text"
          label="DOT multiplier"
          labelInfo={getOtMultiplierTooltip("dot", "rate_diffs")}
          form={form}
          value={otMultipliers?.dot?.toString()}
          onChange={handleOtMultiplierChange}
          name="dot_multiplier"
          editing={true}
          className="modal"
        />
      </div>
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};

const rateModifierTypeLookup = {
  dollar_diff: "$/hour differential",
  pct_diff: "% differential",
};
