import EquipmentTimesheetsTable from "dashboard/components/equipment/EquipmentTimesheetsTable";
import React, { FC } from "react";
import { Helmet } from "react-helmet";

const EquipmentTimesheets: FC = () => {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Equipment Timesheets | Miter</title>
      </Helmet>
      <EquipmentTimesheetsTable />
    </div>
  );
};

export default EquipmentTimesheets;
