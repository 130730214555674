import { HolidaySchedule, UpdateHolidayScheduleParams } from "dashboard/miter";
import { DateTime } from "luxon";
import { PredefinedHolidayId } from "backend/models/holiday-schedule";

export type FixedParams = {
  month: number;
  day: number;
};
export type FloatingWeekOptions = "first" | "second" | "third" | "fourth" | "last";
export type FloatingParams = {
  weekOfMonth: FloatingWeekOptions;
  month: number;
  weekday: number;
};

export type PredefinedHolidaySchema = {
  id: PredefinedHolidayId;
  name: string;
  getUpcomingDate: () => DateTime;
};

// Utility functions
const getFloatingDate = (params: FloatingParams) => {
  const today = DateTime.now();
  const currentYearHoliday = calculateFloatingDate({ ...params, year: today.year });
  if (currentYearHoliday.startOf("day") < today.startOf("day")) {
    return calculateFloatingDate({ ...params, year: today.year + 1 });
  }
  return currentYearHoliday;
};

const calculateFloatingDate = (params: FloatingParams & { year: number }) => {
  const { year, weekOfMonth, month, weekday } = params;
  const firstDayOfMonth = DateTime.fromObject({ year: year, month: month });
  let firstOccurrenceOfDay: DateTime;
  if (weekday >= firstDayOfMonth.weekday) {
    firstOccurrenceOfDay = firstDayOfMonth.plus({ days: weekday - firstDayOfMonth.weekday });
  } else {
    firstOccurrenceOfDay = firstDayOfMonth.plus({ days: 7 - (firstDayOfMonth.weekday - weekday) });
  }
  let numWeeksToAdd: number;
  switch (weekOfMonth) {
    case "first": {
      numWeeksToAdd = 0;
      break;
    }
    case "second": {
      numWeeksToAdd = 1;
      break;
    }
    case "third": {
      numWeeksToAdd = 2;
      break;
    }

    case "fourth": {
      numWeeksToAdd = 3;
      break;
    }

    default: {
      // last weekday of the month
      if (firstOccurrenceOfDay.plus({ weeks: 4 }).month === month) {
        numWeeksToAdd = 4;
      } else {
        numWeeksToAdd = 3;
      }
    }
  }
  return firstOccurrenceOfDay.plus({ weeks: numWeeksToAdd });
};

const getFixedDate = (params: FixedParams) => {
  const today = DateTime.now();
  const currentYearHoliday = DateTime.fromObject({ month: params.month, day: params.day });
  if (currentYearHoliday.startOf("day") < today.startOf("day")) {
    return currentYearHoliday.plus({ years: 1 });
  }
  return currentYearHoliday;
};

export const predefinedHolidaySchemas: PredefinedHolidaySchema[] = [
  {
    id: "new_years",
    name: "New Year's Day",
    getUpcomingDate: () => getFixedDate({ month: 1, day: 1 }),
  },
  {
    id: "mlk",
    name: "Martin Luther King Jr. Day",
    getUpcomingDate: () => getFloatingDate({ weekOfMonth: "third", weekday: 1, month: 1 }),
  },
  {
    id: "presidents",
    name: "President's Day",
    getUpcomingDate: () => getFloatingDate({ weekOfMonth: "third", weekday: 1, month: 2 }),
  },
  {
    id: "memorial",
    name: "Memorial Day",
    getUpcomingDate: () => getFloatingDate({ weekOfMonth: "last", weekday: 1, month: 5 }),
  },
  {
    id: "juneteenth",
    name: "Juneteenth",
    getUpcomingDate: () => getFixedDate({ month: 6, day: 19 }),
  },
  {
    id: "independence",
    name: "Independence Day",
    getUpcomingDate: () => getFixedDate({ month: 7, day: 4 }),
  },
  {
    id: "labor",
    name: "Labor Day",
    getUpcomingDate: () => getFloatingDate({ weekOfMonth: "first", weekday: 1, month: 9 }),
  },
  {
    id: "columbus",
    name: "Columbus Day",
    getUpcomingDate: () => getFloatingDate({ weekOfMonth: "second", weekday: 1, month: 10 }),
  },
  {
    id: "veterans",
    name: "Veterans Day",
    getUpcomingDate: () => getFixedDate({ month: 11, day: 11 }),
  },
  {
    id: "thanksgiving",
    name: "Thanksgiving Day",
    getUpcomingDate: () => getFloatingDate({ weekOfMonth: "fourth", weekday: 4, month: 11 }),
  },
  {
    id: "christmas",
    name: "Christmas Day",
    getUpcomingDate: () => getFixedDate({ month: 12, day: 25 }),
  },
];

export type HolidayTabProps = {
  originalHolidaySchedule: HolidaySchedule;
  updateHolidaySchedule: (params: UpdateHolidayScheduleParams) => Promise<void>;
};

export type BasicResponse = {
  _id: string;
  error: string;
};

export function getFailuresFromPromiseAllSettled(updates: PromiseSettledResult<BasicResponse>[]): string[] {
  return updates
    .map((update) => {
      return (
        (update.status === "rejected" && `rejected: ${update.reason?.message}`) ||
        (update.status === "fulfilled" &&
          update.value.error &&
          `errored id ${update.value._id}: ${update.value.error}`) ||
        ""
      );
    })
    .filter(Boolean);
}
