import { FormComponentFormField } from "../FormBuilderScreen";
import ObjectID from "bson-objectid";

// Google Docs reference: https://docs.google.com/document/d/1AJIbnR1a57PKAF2o0USbLONSII52a4cX8PJR47MDsfI/edit

export enum PerformanceTemplate {
  SELF_EVALUATION = "SELF_EVALUATION",
  MANAGER_EVALUATION = "MANAGER_EVALUATION",
}

const selfEvaluationTemplate = (): FormComponentFormField[] => [
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "What were your major accomplishments since the last review?",
    description: "How did these accomplishments support our company goals and values?",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "What can you do to better support our company goals and values?",
    description: "How will you accomplish this and what resources will you require?",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "What are your upcoming career goals?",
    description: "",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 10, how happy are you with your job?",
    description: "1 = very dissatisfied, 10 = very happy",
    validations: { required: true, min: 1, max: 10 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "select",
    name: "How often do you feel overworked?",
    description: "",
    validations: { required: true },
    placeholder: "",
    options: [
      { _id: ObjectID().toHexString(), value: "Never" },
      { _id: ObjectID().toHexString(), value: "Rarely" },
      { _id: ObjectID().toHexString(), value: "Sometimes" },
      { _id: ObjectID().toHexString(), value: "Often" },
      { _id: ObjectID().toHexString(), value: "Always" },
    ],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your technical skills on the job?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your time management and communication?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your punctuality (arriving on time) at the jobsite?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your commitment to our company’s safety plans?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your interactions with our customers and the public?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your willingness to learn?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "Have any tasks or duties outside of your job description been added to your weekly responsibilities?",
    description: "",
    validations: { required: false },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "Please add any additional thoughts or comments.",
    description: "",
    validations: { required: false },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "esignature",
    name: "Please sign here to certify the accuracy of your response.",
    description: "",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
];

export const managerEvaluationForm = (): FormComponentFormField[] => [
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "What are your report’s responsibilities?",
    description: "",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "What were your report’s major accomplishments since the last review?",
    description: "How did these accomplishments support our company goals and values?",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "How can your report better support our company goals and values?",
    description: "What milestones will you set for them? What resources will they need to succeed?",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your report's technical skills on the job?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your report's time management and communication?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your report's punctuality (arriving on time) at the jobsite?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your report's commitment to our company’s safety plans?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your report's interactions with our customers and the public?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 5, how would you rate your report's willingness to learn?",
    description: "1 = very poor, 5 = very proficient",
    validations: { required: true, min: 1, max: 5 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "Have any tasks or duties outside of your report's job description been added to their weekly responsibilities?",
    description: "",
    validations: { required: false },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "number",
    name: "From a scale of 1 - 10, how would you rate your report's overall performance?",
    description: "1 = very poor, 10 = extremely proficient",
    validations: { required: true, min: 1, max: 10 },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "paragraph",
    name: "Please add any additional thoughts or comments about your report.",
    description: "",
    validations: { required: false },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
  {
    _id: ObjectID().toHexString(),
    type: "esignature",
    name: "Please sign here to certify the accuracy of your response.",
    description: "",
    validations: { required: true },
    placeholder: "",
    options: [],
    multiple: undefined,
  },
];

export const performanceTemplates: Record<PerformanceTemplate, () => FormComponentFormField[]> = {
  [PerformanceTemplate.SELF_EVALUATION]: selfEvaluationTemplate,
  [PerformanceTemplate.MANAGER_EVALUATION]: managerEvaluationForm,
};
