import React, { useMemo, useState } from "react";
import Notifier from "dashboard/utils/notifier";
import PayrollContext from "../payrollContext";
import { PayrollAdjustment } from "../../payrollTypes";
import { useTeam } from "dashboard/hooks/atom-hooks";
import { MAX_PAYROLL_PAYMENTS } from "../viewPayrollUtils";
import { usePayScheduleAccessor } from "dashboard/hooks/usePayScheduleAccessor";
import BulkTeamMemberSelect, {
  BulkTeamMemberSelectTeamMember,
} from "dashboard/components/team-members/BulkTeamMemberSelect";

const AddOffCyclePayments: React.FC<{ hide: () => void }> = (props) => {
  // Hooks
  const teamMembers = useTeam();
  const { payroll, recalculatePayroll } = React.useContext(PayrollContext);
  const { canAccessTeamMember } = usePayScheduleAccessor();

  // State
  const [saving, setSaving] = useState(false);

  const relevantTms = useMemo(() => {
    if (!payroll) return [];
    return teamMembers.filter((tm) => {
      if (!canAccessTeamMember(tm)) return false;

      const { period_start, period_end } = payroll?.check_payroll;
      // Filter out TMs that are inactive for the given period and that are already in the payroll
      if (
        (tm.end_date && tm.end_date < period_start) ||
        (tm.start_date && tm.start_date > period_end) ||
        payroll?.adjustments.some((a) => a.team_member === tm._id) ||
        !tm.check_id
      ) {
        return false;
      }
      return true;
    });
  }, [teamMembers, payroll, canAccessTeamMember]);

  const validateSelection = (adds: string[]) => {
    const currs = payroll?.adjustments.map((a) => a.team_member) || [];
    const ids = new Set(currs.concat(adds));
    if (ids.size > MAX_PAYROLL_PAYMENTS) {
      Notifier.error(`You can only add up to ${MAX_PAYROLL_PAYMENTS} payments to a given payroll.`);
      return false;
    }
    return true;
  };

  const handleSave = async (newlySelectedTeamMembers: BulkTeamMemberSelectTeamMember[]) => {
    if (!payroll) return;

    // very minor optimization - we need the string[] list of tm ids in multiple places
    const newTMIds = newlySelectedTeamMembers.map((tm) => tm._id);

    if (!validateSelection(newTMIds)) return;

    if (newlySelectedTeamMembers.length === 0) {
      Notifier.error("Please select at least one team member.");
      return;
    }
    setSaving(true);
    try {
      // Create an adjustment for each selected TM
      const newAdjustments = newlySelectedTeamMembers.map((tm) => {
        const newAdj: PayrollAdjustment = {
          team_member: tm._id,
          manual_earnings: [],
          manual_reimbursements: [],
        };
        return newAdj;
      });

      // Concatenate new adjustments with payroll's existing adjustments
      const allAdjustments = payroll.adjustments.concat(newAdjustments);
      // Recalculate payroll
      await recalculatePayroll({
        adjustments: allAdjustments,
        tms: newTMIds,
      });
      Notifier.success("Payments successfully added.");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error adding payments. We're looking into it! Feel free to try again.");
    }
    props.hide();
    setSaving(false);
  };

  return (
    <BulkTeamMemberSelect
      title="Add team members to this payroll"
      defaultTeamMembers={[]}
      teamMembersPool={relevantTms}
      onHide={props.hide}
      submitText="Add payments"
      onSubmit={handleSave}
      submitting={saving}
    />
  );
};

export default AddOffCyclePayments;
