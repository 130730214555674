import { BasicModal, DisplaySignature } from "ui";
import React, { FC } from "react";
import { ESignatureItem } from "dashboard/miter";

type Props = {
  onHide: () => void;
  esignatureItem?: ESignatureItem;
  imageData?: string;
};

export const ESignatureModal: FC<Props> = ({ esignatureItem, onHide, imageData }) => {
  return (
    <BasicModal headerText={`View signature`} button2Text={"Close"} button2Action={onHide}>
      <DisplaySignature
        signature={imageData || esignatureItem?.signature?.image}
        signerName={esignatureItem?.signer?.name}
        signerTitle={esignatureItem?.signer?.title}
        createdAt={esignatureItem?.created_at}
      />
    </BasicModal>
  );
};
