import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { Formblock, SettingsCard } from "ui";

type Props = {
  entityType: string;
};

type GlobalAccessFilterForm = {
  location?: boolean;
  department?: boolean;
  grouping?: "or" | "and";
};

export const groupingOptions = [
  { label: "Either", value: "or" },
  { label: "Both", value: "and" },
];

export const GlobalAccessFilterSettingCard: React.FC<Props> = ({ entityType }) => {
  const activeCompany = useActiveCompany();
  const { form, handleFieldUpdate, updateFields } = useSettingsForm<GlobalAccessFilterForm>(
    `settings.${entityType}.global_access_filter`
  );

  const globalAccessFilter = activeCompany?.settings?.[entityType]?.global_access_filter;

  // make sure if all access filters aren't selected to reset grouping to OR
  const handleFieldUpdateSideEffect = (value: boolean, field: string) => {
    const departmentFilter = field === "department" ? value : globalAccessFilter?.department;
    const locationFilter = field === "location" ? value : globalAccessFilter?.location;

    updateFields({
      grouping: !locationFilter || !departmentFilter ? "or" : globalAccessFilter.grouping,
      department: departmentFilter,
      location: locationFilter,
    });
  };

  return (
    <SettingsCard title="Global access filters">
      <p>{`Team members can access ${entityType} associated with`}</p>
      <Formblock
        type="select"
        name="custom_access_filter.grouping"
        form={form}
        editing={true}
        options={groupingOptions}
        onChange={(o) => handleFieldUpdate(o.value, "grouping")}
        style={{ width: 300, alignItems: "center" }}
        labelStyle={{ width: 300 }}
        defaultValue={globalAccessFilter?.grouping || "or"}
      />
      <Formblock
        text="Team member's department"
        type="checkbox"
        form={form}
        editing={true}
        onChange={(e) => handleFieldUpdateSideEffect(e.target.checked, "department")}
        style={{ width: "100%", alignItems: "center" }}
        labelStyle={{ width: "100%" }}
        defaultValue={globalAccessFilter?.department}
      />
      <Formblock
        text="Team member's location"
        type="checkbox"
        form={form}
        editing={true}
        onChange={(e) => handleFieldUpdateSideEffect(e.target.checked, "location")}
        style={{ width: "100%", alignItems: "center" }}
        labelStyle={{ width: "100%" }}
        defaultValue={globalAccessFilter?.location}
      />
    </SettingsCard>
  );
};
