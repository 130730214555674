import React from "react";
import PayrollContext from "../payrollContext";
import TimesheetsTable from "dashboard/components/tables/TimesheetsTable";

const NonDraftPayrollTimesheets: React.FC = () => {
  const { payroll } = React.useContext(PayrollContext);

  return (
    <div style={{ marginTop: -25 }}>
      {payroll && (
        <TimesheetsTable
          defaultFilters={[{ field: "_id", comparisonType: "in", value: payroll.timesheets, type: "_id" }]}
          showToggler={false}
          showMiniTitle={false}
          hideCreateButtons={true}
        />
      )}
    </div>
  );
};

export default NonDraftPayrollTimesheets;
