import React, { FC } from "react";
import "./icons.css";

import checkMarkPng from "dashboard/assets/check-mark.png";
import redXJpg from "dashboard/assets/red-x.jpg";
import alertPng from "dashboard/assets/alert.png";
import { FaExclamationTriangle } from "react-icons/fa";

export const Checkmark: FC<{ className?: string }> = ({ className }) => {
  return <img className={"default-icon " + (className || "")} src={checkMarkPng} />;
};

export const RedX: FC<{ className?: string }> = ({ className }) => {
  return <img className={"default-icon " + (className || "")} src={redXJpg} />;
};

export const Alert: FC<{ className?: string; text?: string }> = ({ className, text }) => {
  return (
    <div className={"icon-wrapper " + (className || "")}>
      {text && <span className={"icon-text " + (className || "")}>{text}&nbsp;&nbsp;</span>}
      <img className={"default-icon " + (className || "")} src={alertPng} />
    </div>
  );
};

export const Warning: FC<{ className?: string; text?: string }> = ({ className, text }) => {
  return (
    <div className={"icon-wrapper " + (className || "")}>
      {text && <span className={"icon-text " + (className || "")}>{text}&nbsp;&nbsp;</span>}
      <FaExclamationTriangle style={{ color: "#d4bc0d" }} className={"default-icon " + (className || "")} />
      {/* <img className={"default-icon " + className} src={warningPng} /> */}
    </div>
  );
};
