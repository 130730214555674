import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { HolidaySchedule } from "dashboard/miter";
import * as vals from "dashboard/utils/validators";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useOtRuleOptions } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import { FederalHolidayTableRow } from "./FederalHolidays";
import { PredefinedHoliday } from "backend/models/holiday-schedule";

export type FederalHolidayForm = {
  label: string;
  overtime_rule?: Option<string>;
};

type Props = {
  selectedHoliday: FederalHolidayTableRow;
  updateHolidays: (updatedHolidays: PredefinedHoliday[]) => Promise<void>;
  originalHolidaySchedule: HolidaySchedule;
  hide: () => void;
};

export const FederalHolidayModal: React.FC<Props> = ({
  selectedHoliday,
  updateHolidays,
  originalHolidaySchedule,
  hide,
}) => {
  const { can, cannot } = useMiterAbilities();

  const form = useForm<FederalHolidayForm>();
  const { control, handleSubmit, register, errors } = form;
  const [loading, setLoading] = useState(false);
  const otRuleOptions = useOtRuleOptions();

  const onHide = () => {
    setLoading(false);
    hide();
  };

  const onUpdate = async (data: FederalHolidayForm) => {
    if (cannot("time_off:holidays:manage")) return;

    setLoading(true);

    const chosenHoliday = originalHolidaySchedule.enabled_predefined_holidays.find(
      (h) => h.key === selectedHoliday._id
    );
    if (chosenHoliday) {
      chosenHoliday.overtime_rule_id = data.overtime_rule?.value;
    }

    const updatedEnabledPredefinedHolidays = [...originalHolidaySchedule.enabled_predefined_holidays];

    await updateHolidays(updatedEnabledPredefinedHolidays);
    onHide();
  };

  return (
    <ActionModal
      headerText={"Edit federal holiday"}
      onSubmit={handleSubmit(onUpdate)}
      submitText={"Update"}
      onHide={onHide}
      showSubmit={can("time_off:holidays:manage")}
      loading={loading}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Holiday*"
        type="text"
        register={register(vals.required)}
        name="label"
        className="modal"
        errors={errors}
        editing={true}
        defaultValue={selectedHoliday.label}
        disabled={true}
      />
      <Formblock
        label="Date*"
        type="text"
        name="date"
        className="modal"
        control={control}
        editing={true}
        errors={errors}
        defaultValue={selectedHoliday.date}
        disabled={true}
      />
      {!!otRuleOptions.length && (
        <Formblock
          label="Overtime rule"
          className="modal"
          type="select"
          options={otRuleOptions}
          isClearable={true}
          name="overtime_rule"
          editing={true}
          form={form}
          errors={errors}
          defaultValue={selectedHoliday?.overtime_rule_id}
          disabled={cannot("time_off:holidays:manage")}
        />
      )}
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
