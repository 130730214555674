import { Onboarding } from "dashboard/components/product-onboarding/Onboarding";
import { ArrowsOut, Ear, NumberCircleOne, NumberCircleThree, NumberCircleTwo, TrendUp } from "phosphor-react";
import React, { FC, useState } from "react";
import styles from "./Performance.module.css";
import { AckPerformanceOnboardingModal } from "./AckPerformanceOnboardingModal";
import { Helmet } from "react-helmet";

type TitleProps = {
  title: string;
  icon: React.ReactNode;
};
const Title: FC<TitleProps> = ({ icon, title }) => {
  return (
    <div style={{ display: "flex" }}>
      {icon}
      <div style={{ marginLeft: 10 }} className={styles["onboarding-card-title"]}>
        {title}
      </div>
    </div>
  );
};
const usecases = [
  {
    title: <Title icon={<Ear style={{ marginTop: 2 }} weight="bold" />} title="Engage" />,
    description:
      "Use recurring performance reviews to keep your team engaged and uncover employee sentiments.",
  },
  {
    title: <Title icon={<ArrowsOut style={{ marginTop: 2 }} weight="bold" />} title="Develop" />,
    description:
      "Collect feedback from self and manager evaluations to make clear HR decisions and grow your team.",
  },
  {
    title: <Title icon={<TrendUp style={{ marginTop: 2 }} weight="bold" />} title="Measure" />,
    description:
      "Compare responses across review cycles to identify actionable themes within your organization.",
  },
];

const setup = [
  {
    title: <NumberCircleOne weight="bold" />,
    description:
      "Create a review schedule that automatically requests evaluations at the start of each cycle, or mid-cycle if you want to get started immediately.",
  },
  {
    title: <NumberCircleTwo weight="bold" />,
    description:
      "Design self and manager evaluations with a variety of question types (text, number, signature, and more) from the simple form builder interface.",
  },
  {
    title: <NumberCircleThree weight="bold" />,
    description:
      "Wait for and remind employees (with a single click) to complete their reviews that are accessible on the mobile app. Then analyze responses from the dashboard.",
  },
];

export const PerformanceOnboarding: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Performance Management | Miter</title>
      </Helmet>
      <div className="page-content">
        {showModal && <AckPerformanceOnboardingModal onExit={closeModal} />}
        <Onboarding
          title="Performance"
          subheader="Track employee growth and make informed HR decisions."
          usecases={usecases}
          setup={setup}
          continueOnClick={openModal}
        />
      </div>
    </div>
  );
};
