import AppContext from "dashboard/contexts/app-context";
import { Notifier } from "dashboard/utils";
import { timezoneOptions } from "miter-utils";
import React, { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { ActionModal, Button, Formblock, Loader } from "ui";
import { OvertimeRuleModal } from "./OvertimeRuleModal";
import { MiterAPI, OvertimeRule } from "dashboard/miter";
import { DateTime } from "luxon";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import InfoButton from "dashboard/components/information/information";
import { ColDef, ICellRendererParams, RowDragMoveEvent } from "ag-grid-community";
import { verticalCenteredCellStyle } from "dashboard/components/agGridTable/agGridUtils";
import { Option } from "ui/form/Input";
import {
  useActiveCompany,
  useActiveCompanyId,
  useOtRuleOptions,
  useOtRulesAtom,
  useSetOtRules,
} from "dashboard/hooks/atom-hooks";

type OtRuleTableEntry = { _id: string; lastUpdatedString: string; object: OvertimeRule };

export const OvertimeRules: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const { fetchUserData } = useContext(AppContext);
  const [otRules, setOtRules] = useOtRulesAtom();
  const [showOvertimeRuleModal, setShowOvertimeRuleModal] = useState<OvertimeRule | undefined>();
  const [createOtRuleModal, setCreateOtRuleModal] = useState(false);

  const [updatingPrecedence, setUpdatingPrecedence] = useState(false);

  const renderRowActions = (params: ICellRendererParams): React.ReactElement => {
    if (params.node.level !== 0) return <></>;

    const otRule = params.data.object;

    return (
      <div className="flex">
        <Button className="button-1" text="Edit" onClick={() => setShowOvertimeRuleModal(otRule)} />
      </div>
    );
  };

  const columns: ColDef[] = [
    {
      field: "object.precedence",
      headerName: "Precedence",
      rowDrag: true,
      minWidth: 150,
      sortable: false,
      headerTooltip:
        "If a pay period is subject to multiple Overtime Rules, Miter will use the Overtime Rule with the lowest precedence. All custom OT rules take precendence over state OT rules.",
    },
    { field: "object.label", headerName: "Rule", minWidth: 300, sortable: false },
    { field: "lastUpdatedString", headerName: "Last updated", minWidth: 200, sortable: false },
    {
      headerName: " ",
      width: 50,
      cellRenderer: renderRowActions,
      cellStyle: { overflow: "visible", ...verticalCenteredCellStyle },
    },
  ];

  const tableEntries: OtRuleTableEntry[] = useMemo(() => {
    return otRules.map((rule) => {
      return {
        _id: rule._id,
        lastUpdatedString: rule.updated_at ? DateTime.fromSeconds(rule.updated_at).toFormat("DD") : "",
        object: rule,
      };
    });
  }, [otRules]);

  const ruleOptions = useOtRuleOptions({
    includeCustomOption: { label: "Federal/state defaults", value: "" },
  });

  const [selectedDefaultRuleOption, setSelectedDefaultRuleOption] = useState(() => {
    const companyDefault = activeCompany?.settings.payroll.default_ot_rule_id;
    return ruleOptions.find((option) => option.value === (companyDefault || ""));
  });

  const handleNewRule = (newRule: OvertimeRule) => {
    setCreateOtRuleModal(false);
    setShowOvertimeRuleModal(newRule);
  };

  const updatePrecedence = async (ruleId: string, precedence: number) => {
    const response = await MiterAPI.overtime_rules.update([ruleId], activeCompanyId!, { precedence });
    if (response.error) throw new Error(response.error);
    if (!response[0]) throw new Error("Response has no data");
    return response[0]!;
  };

  const onRowDragEnd = async (e: RowDragMoveEvent) => {
    setUpdatingPrecedence(true);
    try {
      const ruleId = e.node.data._id?.toString();
      const newRuleIds = tableEntries
        .slice()
        .filter((entry) => entry.object._id.toString() !== ruleId)
        .map((entry) => entry._id);
      newRuleIds.splice(e.overIndex, 0, ruleId);
      const updatedRules = await Promise.all(
        newRuleIds.map((ruleId, index) => updatePrecedence(ruleId, index))
      );
      setOtRules((prev) => prev.concat(updatedRules));
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating an overtime rule's precedence. We're looking into it!");
    }
    setUpdatingPrecedence(false);
  };

  const handleDefaultRuleChange = async (option: Option<string>) => {
    setSelectedDefaultRuleOption(option);
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        "settings.payroll.default_ot_rule_id": option.value || null,
      });
      if (response.error) throw new Error(response.error);
      fetchUserData();
      Notifier.success("Default overtime rule updated");
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the default overtime rule. We're looking into it!");
    }
  };

  return (
    <div className="billing-card-wrapper" style={{ height: "auto" }}>
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Overtime rules</div>
        <InfoButton text="Overtime rules describe how daily and weekly overtime should be applied during a pay period." />
        <div className="flex-1"></div>
        {updatingPrecedence && <Loader className="small-text" />}
        <Button className="button-2" text="+ New" onClick={() => setCreateOtRuleModal(true)} />
      </div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        <Formblock
          form={useForm()}
          options={ruleOptions}
          name={"defaultRule"}
          labelInfo="Which rule should be used as a fallback if no other rules are applicable."
          type="select"
          label="Default rule"
          value={selectedDefaultRuleOption}
          onChange={handleDefaultRuleChange}
          editing={true}
        />
      </div>
      {!tableEntries ? (
        <Loader />
      ) : (
        <AgGridTable
          columnDefs={columns}
          data={tableEntries}
          gridHeight={200}
          hideSidebar={true}
          containerStyle={{ marginBottom: 10 }}
          hideDownloadCSV={true}
          gridOptions={{ rowDragManaged: true, onRowDragEnd }}
        />
      )}
      {createOtRuleModal && (
        <AddOvertimeRuleModal
          onSubmit={handleNewRule}
          hide={() => setCreateOtRuleModal(false)}
          precedence={tableEntries.length}
        />
      )}
      {showOvertimeRuleModal && (
        <OvertimeRuleModal
          overtimeRuleToEdit={showOvertimeRuleModal}
          hide={() => setShowOvertimeRuleModal(undefined)}
        />
      )}
    </div>
  );
};

type AddOvertimeRuleModalProps = {
  hide: () => void;
  onSubmit: (newRule: OvertimeRule) => void;
  precedence: number;
};

const AddOvertimeRuleModal: React.FC<AddOvertimeRuleModalProps> = ({ onSubmit, hide, precedence }) => {
  // Hooks
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();
  const setOtRules = useSetOtRules();
  const { register, errors, handleSubmit, control } = useForm();

  const [loading, setLoading] = useState(false);

  const save = async (data) => {
    setLoading(true);
    try {
      const response = await MiterAPI.overtime_rules.create({
        company: activeCompanyId!,
        label: data.label,
        precedence,
        timezone: data.timezone?.value,
      });
      if (response.error) throw new Error(response.error);
      setOtRules((prev) => prev.concat(response));
      onSubmit(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the overtime rule group. We're looking into it!");
    }
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={"Create overtime rule"}
      onHide={hide}
      showCancel={true}
      onCancel={hide}
      bodyStyle={{ maxHeight: 200 }}
      showSubmit={true}
      onSubmit={handleSubmit(save)}
      submitText={"Create"}
      loading={loading}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Label*"
        labelInfo="The name of the overtime rule."
        className="modal"
        type="text"
        register={register(vals.required)}
        name="label"
        errors={errors}
        editing={true}
      />
      <Formblock
        label="Timezone*"
        className="modal"
        type="select"
        requiredSelect={true}
        options={timezoneOptions}
        control={control}
        name="timezone"
        errors={errors}
        editing={true}
        defaultValue={activeCompany?.timezone}
      />
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
