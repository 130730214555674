import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import { MiterAPI, AggregatedRole } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import {
  useActiveCompanyId,
  usePermissionGroupOptions,
  usePermissionGroups,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import Banner from "dashboard/components/shared/Banner";
import { useNavigate } from "react-router-dom";
import { Option } from "ui/form/Input";
import { CreateRoleParams, UpdateRoleParams } from "backend/services/role-service";

type Props = {
  role?: AggregatedRole;
  onHide: () => void;
};

type RoleForm = {
  first_name?: string;
  last_name?: string;
  email?: string;
  permission_groups?: Option<string>[];
};

export const RoleModal: React.FC<Props> = ({ role, onHide }) => {
  const form = useForm<RoleForm>();
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const permissionGroups = usePermissionGroups();
  const permissionGroupOptions = usePermissionGroupOptions();

  const { can, cannot } = useMiterAbilities();

  const [loading, setLoading] = useState(false);
  const { handleSubmit } = form;

  // Get the permission groups this role is a part of
  const defaultRolePermissionGroupIds = useMemo(() => {
    if (!role || !permissionGroups) return [];

    return permissionGroups
      .filter((pg) => {
        const isMember = pg.members.some((m) => {
          return m.type === "role" && m.group.type === "role" && m.group.value === role._id;
        });

        return isMember;
      })
      .map((pg) => pg._id);
  }, [role, permissionGroups]);

  const createRole = async (data: RoleForm) => {
    if (role || !activeCompanyId || cannot("company:roles:manage")) return;

    setLoading(true);
    try {
      if (!data.email) throw new Error("Email is required");

      const params: CreateRoleParams = {
        first_name: data.first_name,
        last_name: data.last_name,
        full_name: `${data.first_name} ${data.last_name}`,
        email: data.email!,
        company: activeCompanyId,
        permission_group_ids: data.permission_groups?.map((pg) => pg.value),
      };

      const res = await MiterAPI.roles.create(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Role created successfully");
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error creating role:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateRole = async (data: RoleForm) => {
    if (!role || !activeCompanyId || cannot("company:roles:manage")) {
      Notifier.error("You do not have permission to update this role");
      return;
    }

    setLoading(true);
    try {
      const params: UpdateRoleParams = {
        first_name: data.first_name,
        last_name: data.last_name,
        full_name: `${data.first_name} ${data.last_name}`,
        permission_group_ids: data.permission_groups?.map((pg) => pg.value),
      };

      const res = await MiterAPI.roles.update(role._id, params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Role updated successfully");
      onHide();
    } catch (e: $TSFixMe) {
      console.error("Error updating role:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    if (role) {
      handleSubmit(updateRole)();
    } else {
      handleSubmit(createRole)();
    }
  };

  return (
    <>
      <ActionModal
        headerText={role ? "Update role" : "Create role"}
        showSubmit={can("company:roles:manage")}
        showCancel={true}
        cancelText={"Close"}
        onCancel={onHide}
        submitText={"Save"}
        onHide={onHide}
        onSubmit={submit}
        loading={loading}
      >
        <Banner
          style={{ borderBottom: "1px solid #d6d6d6", marginRight: -20, marginLeft: -20 }}
          type="modal"
          onClick={() => navigate("/team-members")}
        >
          <span className="flex">{`Note: if this person works at your company, add them as a team member by clicking here.`}</span>
        </Banner>

        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            type="text"
            name="first_name"
            label="First name"
            form={form}
            editing={true}
            defaultValue={role?.first_name}
            className="modal"
            disabled={cannot("company:roles:manage")}
          />
          <Formblock
            type="text"
            name="last_name"
            label="Last name"
            form={form}
            editing={true}
            defaultValue={role?.last_name}
            className="modal"
            disabled={cannot("company:roles:manage")}
          />
          <Formblock
            type="text"
            name="email"
            label="Email"
            form={form}
            editing={true}
            defaultValue={role?.email}
            className="modal"
            disabled={role ? true : cannot("company:roles:manage")}
          />
          <Formblock
            type="multiselect"
            name="permission_groups"
            label="Permission groups"
            labelInfo="Select the permission groups this role is a part of. The permission groups you select will determine what this role can do."
            form={form}
            editing={true}
            options={permissionGroupOptions}
            defaultValue={defaultRolePermissionGroupIds}
            className="modal"
            disabled={cannot("company:roles:manage")}
            height={"unset"}
          />
        </div>
      </ActionModal>
    </>
  );
};
