import AppContext from "dashboard/contexts/app-context";
import React, { ChangeEvent, useContext } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui";

export const PaystubsAndChecks: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const miterPaystubSetting = "settings.payroll.use_miter_paystubs_and_checks";
  const hideCompanyTaxesSetting = "settings.payroll.hide_company_taxes_on_paystubs";
  const hideCompanyBenefitContributionsSetting =
    "settings.payroll.hide_company_benefit_contributions_on_paystubs";

  const [useMiterPaystubsAndChecks, setUseMiterPaystubsAndChecks] = React.useState(
    activeCompany?.settings.payroll.use_miter_paystubs_and_checks
  );
  const [hideCompanyTaxesOnPaystubs, setHideCompanyTaxesOnPaystubs] = React.useState(
    activeCompany?.settings.payroll.hide_company_taxes_on_paystubs
  );
  const [hideCompanyBenefitContributions, sethideCompanyBenefitContributionsSetting] = React.useState(
    activeCompany?.settings.payroll.hide_company_benefit_contributions_on_paystubs
  );

  const handleChange = async (e: ChangeEvent<HTMLInputElement>, setting: string) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { [setting]: e.target.checked },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
  };

  return (
    <SettingsCard title="Paystubs and check printing settings" contentStyle={{ color: "rgb(51,51,51)" }}>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="checkbox"
          style={{ margin: "0px 12px" }}
          onChange={(e) => {
            setUseMiterPaystubsAndChecks(e.target.checked);
            handleChange(e, miterPaystubSetting);
          }}
          checked={useMiterPaystubsAndChecks}
        />
        <label>Use new enhanced version of paystubs and check data for printing</label>
      </div>
      {useMiterPaystubsAndChecks && (
        <>
          <div className="flex" style={{ marginTop: 7 }}>
            <input
              type="checkbox"
              style={{ margin: "0px 12px" }}
              onChange={(e) => {
                setHideCompanyTaxesOnPaystubs(e.target.checked);
                handleChange(e, hideCompanyTaxesSetting);
              }}
              checked={hideCompanyTaxesOnPaystubs}
            />
            <label>Hide company taxes on paystubs</label>
          </div>
          <div className="flex" style={{ marginTop: 7 }}>
            <input
              type="checkbox"
              style={{ margin: "0px 12px" }}
              onChange={(e) => {
                sethideCompanyBenefitContributionsSetting(e.target.checked);
                handleChange(e, hideCompanyBenefitContributionsSetting);
              }}
              checked={hideCompanyBenefitContributions}
            />
            <label>Hide company benefit contributions on paystubs</label>
          </div>
        </>
      )}
    </SettingsCard>
  );
};
