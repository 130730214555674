import React from "react";
import AppContext from "dashboard/contexts/app-context";
import { useForm } from "react-hook-form";

import { Formblock, Loader } from "ui";
import { ActivityModalTabProps } from "./TabbedActivityModal";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useClassAndItemMappingOptions } from "dashboard/hooks/integrations/quickbooks/useClassAndItemMappingOptions";
import { notNullish } from "miter-utils";

export const ActivityQuickbooksMapping: React.FC<ActivityModalTabProps> = ({
  selectedActivity,
  handleFieldsChange,
  readonly,
}) => {
  const { integrations } = React.useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();
  const form = useForm();

  const connectionKeys = integrations
    .filter((i) => !!i.connection)
    .map((i) => i.connection?.integration_key)
    .filter(notNullish) as string[];

  const {
    classOptions,
    serviceItemOptions,
    connectionError,
    getQbClassIdForActivity,
    getQbItemIdForActivity,
    handleItemChange,
    handleClassChange,
    connectedSystem,
  } = useClassAndItemMappingOptions(connectionKeys, handleFieldsChange, activeCompanyId);

  console.log({ classOptions, serviceItemOptions });

  if (!connectedSystem || connectionError) return null;

  return (
    <div>
      <div className="vertical-spacer-small"></div>
      <>
        {classOptions && serviceItemOptions ? (
          <>
            <Formblock
              label="Quickbooks Class"
              labelInfo="The Quickbooks Class associated with this Activity."
              type="select"
              form={form}
              name="qb_class"
              className="tabbed-modal"
              defaultValue={getQbClassIdForActivity(selectedActivity, connectedSystem)}
              onChange={handleClassChange}
              options={classOptions}
              disabled={readonly}
              editing={true}
              isClearable
            />
            <Formblock
              label="Quickbooks Service Item"
              labelInfo="The Quickbooks Service Item associated with this Activity."
              type="select"
              form={form}
              name="qb_item"
              className="tabbed-modal"
              defaultValue={getQbItemIdForActivity(selectedActivity, connectedSystem)}
              onChange={handleItemChange}
              options={serviceItemOptions}
              disabled={readonly}
              editing={true}
              isClearable
            />
          </>
        ) : (
          <Loader />
        )}
        <div className="vertical-spacer"></div>
      </>
    </div>
  );
};
