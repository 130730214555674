import React, { FC, ReactElement } from "react";
import styles from "../../components/performance/Performance.module.css";

type Props = {
  label: string;
  value: string | ReactElement;
};

export const DataBoxRow: FC<Props> = ({ label, value }) => {
  return (
    <div className={"data-box-section " + styles["data-row"]}>
      <span className={"data-box-section-title font-14"}>{label}</span>
      <span className={"data-box-section-value font-14"}>{value}</span>
    </div>
  );
};
