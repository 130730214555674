import {
  useActiveCompany,
  useActivityOptionsMap,
  useClassOptions,
  useCostTypeOptions,
  useDepartmentOptions,
  useEquipmentOptions,
  useGetClassificationOptions,
  useJobOptions,
  useLookupActivity,
  useLookupClass,
  useLookupCostType,
  useLookupDepartment,
  useLookupRateClassification,
  useLookupTeam,
  useLookupTimeOffPolicy,
  useLookupWcCode,
  usePolicies,
  useTeamOptions,
  useWcCodeOptions,
} from "dashboard/hooks/atom-hooks";
import { ColumnConfig, ColumnDataType, ColumnEditorType } from "ui/table-v2/Table";
import { DraftTimesheet } from "dashboard/components/timesheets/BulkCreateTimesheetsModal";
import {
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";
import { useTimesheetAbilities } from "dashboard/hooks/abilities-hooks/useTimesheetAbilities";
import { isTimesheetScoped, useAccessFilterScoped } from "dashboard/pages/activities/activityUtils";
import {
  getEarningTypeOptionsForTmFlattened,
  getEarningTypeParamsFromAlias,
  useEarningTypeLabeler,
} from "dashboard/pages/timesheets/TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";
import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { usePreppedTimesheetPayRates } from "dashboard/usePreppedTimesheetPayRates";
import { PreppedBulkEntryTimesheet } from "backend/services/timesheets/timesheets-service";
import { useJobScopesPredicate } from "dashboard/pages/jobs/jobUtils";
import { Copy, X } from "phosphor-react";
import { DEFAULT_TIME_FORMAT } from "ui/table-v2/AgGridDateEditor";
import { useDebounce } from "use-debounce";
import AppContext from "dashboard/contexts/app-context";
import { CustomField } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { useOptionalDimensionsUsage } from "dashboard/hooks/dimensions-gating";

const SECONDS_IN_HOUR = 60 * 60;

const prepBulkCreatedDraftTimesheet = (draftTimesheet: DraftTimesheet): PreppedBulkEntryTimesheet | null => {
  if (!draftTimesheet || !draftTimesheet.clock_in || !draftTimesheet.hours || !draftTimesheet.team_member)
    return null;
  // @ts-expect-error typescript not picking up that we are checking for null above
  return {
    ...draftTimesheet,
    clock_out: draftTimesheet.clock_out || draftTimesheet.clock_in + draftTimesheet.hours * SECONDS_IN_HOUR,
    creation_method: "dashboard",
  };
};

const PayRateCellRenderer = (params?: ICellRendererParams) => {
  const { buildPayRatesMap } = usePreppedTimesheetPayRates();
  const [payRate, setPayRate] = useState(0);
  const data = params?.data as DraftTimesheet;
  const [debouncedData] = useDebounce(data, 300);

  useEffect(() => {
    const fetchPayRate = async () => {
      const preppedTimesheet = prepBulkCreatedDraftTimesheet(debouncedData);
      if (!preppedTimesheet) return;
      const payRatesMap = await buildPayRatesMap([preppedTimesheet]);
      setPayRate(payRatesMap[preppedTimesheet._id]?.rates?.reg || 0);
    };
    if (debouncedData) fetchPayRate();
  }, [buildPayRatesMap, debouncedData]);

  return <div>{`$${payRate}/hr`}</div>;
};

const getTableDataTypeForCustomField = (cf: CustomField): ColumnDataType => {
  switch (cf.type) {
    case "checkbox":
      return "boolean";
    case "number":
    case "quantity":
    case "date":
      return "number";
    default:
      return "string";
  }
};

const getTableEditorTypeForCustomField = (cf: CustomField): ColumnEditorType => {
  switch (cf.type) {
    case "select":
      if (cf.multiple) return "multiselect";
      return "select";
    case "quantity":
      return "number";
    case "date":
      return "date";
    case "checkbox":
      return "checkbox";
    default:
      return "text";
  }
};

const getOptionsForCustomField = (cf: CustomField): Option<string>[] | null => {
  if (cf.type === "select" && cf.options_list) {
    return cf.options_list.map((option) => {
      return {
        value: option.value,
        label: option.value,
      };
    });
  }
  return null;
};

const getValidatorForCustomField = (cf: CustomField) => {
  const { required, min, max } = cf.validations || {};
  return (value) => {
    if (required && (value === undefined || value === null)) {
      // it doesn't make sense to mark a checkbox field as required, because then you're forced to select "yes"
      if (cf.type !== "checkbox") {
        return cf.name + " is required";
      }
    }

    if (cf.type === "number" || cf.type === "file") {
      if (min !== null && min !== undefined && value < min) {
        return `Must be at least ${min}`;
      }

      if (max !== null && max !== undefined && value > max) {
        return `Must be at most ${max}`;
      }
    }
    return true;
  };
};

const getClockInValue = (newValue: string, rowData: DraftTimesheet) => {
  // convert the time to seconds
  const newDateTime = DateTime.fromISO(newValue);
  const newClockInSeconds = newDateTime.toSeconds();
  // if clock_in is already set, add the new clock_in time to the day of the existing clock_in time
  if (rowData.clock_in) {
    // get the current clock in day in seconds
    const currentClockIn = DateTime.fromSeconds(rowData.clock_in);
    const currentClockInTimeInSeconds = currentClockIn.hour * SECONDS_IN_HOUR + currentClockIn.minute * 60;
    // add the new clock in time to the day of the current clock in day to get the clock in in seconds
    const newClockInTimeInSeconds = newClockInSeconds + currentClockInTimeInSeconds;
    if (rowData.hours) {
      // set the clock out to the new clock in plus the hours
      rowData.clock_out = newClockInTimeInSeconds + rowData.hours * SECONDS_IN_HOUR;
      return newClockInTimeInSeconds;
    }
  } else {
    return newClockInSeconds;
  }
};

const getClockOutValue = (newValue: string, rowData: DraftTimesheet) => {
  if (!newValue) return;
  // convert the time to seconds
  const newClockOutDateTime = DateTime.fromFormat(newValue, DEFAULT_TIME_FORMAT);
  const newClockOutTimeInSeconds =
    newClockOutDateTime.hour * SECONDS_IN_HOUR + newClockOutDateTime.minute * 60;
  // get the current clock in day in seconds
  const currentClockInDayInSeconds = DateTime.fromSeconds(rowData.clock_in || DateTime.now().toSeconds())
    .startOf("day")
    .toSeconds();
  // add the new clock out time to the day of the current clock in day to get the clock out in swconds
  const clockOut = currentClockInDayInSeconds + newClockOutTimeInSeconds;
  // if the clock in is set, and the clock out is before the clock in, return the clock in
  if (rowData.clock_in) {
    if (clockOut < rowData.clock_in) return rowData.clock_in;
    // set the hours to the difference between the clock out and clock in
    rowData.hours = (clockOut - rowData.clock_in) / SECONDS_IN_HOUR;
  }
  return clockOut;
};

const getBreakStartValue = (newValue: string, rowData: DraftTimesheet) => {
  if (!newValue || !rowData.clock_in) return;
  // get the current clock in day in seconds
  const currentClockInDayInSeconds = DateTime.fromSeconds(rowData.clock_in).startOf("day").toSeconds();
  // convert the break start time to seconds
  const newBreakStartDateTime = DateTime.fromFormat(newValue, DEFAULT_TIME_FORMAT);
  const newBreakStartInSeconds =
    newBreakStartDateTime.hour * SECONDS_IN_HOUR + newBreakStartDateTime.minute * 60;
  // add the break start time to the day of the current clock in day to get the break start in seconds
  const breakStart = currentClockInDayInSeconds + newBreakStartInSeconds;
  // if the break end is set, and the break start is after the break end, return the break end to avoid a negative break time
  if (rowData.break?.end && breakStart > rowData.break.end) return rowData.break.end;
  return breakStart;
};

const getBreakEndValue = (newValue: string, rowData: DraftTimesheet) => {
  if (!newValue || !rowData.clock_in) return;
  // get the current clock in day in seconds
  const currentClockInDayInSeconds = DateTime.fromSeconds(rowData.clock_in).startOf("day").toSeconds();
  // convert the break end time to seconds
  const newBreakEndDateTime = DateTime.fromFormat(newValue, DEFAULT_TIME_FORMAT);
  const newBreakEndInSeconds = newBreakEndDateTime.hour * SECONDS_IN_HOUR + newBreakEndDateTime.minute * 60;
  // add the break end time to the day of the current clock in day to get the break end in seconds
  const breakEnd = currentClockInDayInSeconds + newBreakEndInSeconds;
  // if the break start is set, and the break end is before the break start, return the break start to avoid a negative break time
  if (rowData.break?.start && breakEnd < rowData.break.start) return rowData.break.start;
  return breakEnd;
};

export const useBulkCreateTimesheetsColumns = (
  handleDuplicateRow: (row: DraftTimesheet, index: number) => void,
  handleDeleteRow: (row: DraftTimesheet) => void
): ColumnConfig<DraftTimesheet>[] => {
  // context
  const { customFields } = useContext(AppContext);
  const tsCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "timesheet"),
    [customFields]
  );

  // hooks and settings
  const activeCompany = useActiveCompany();
  const timesheetAbilities = useTimesheetAbilities();
  const { wfmJobScopeWithAbility } = useJobScopesPredicate(timesheetAbilities.jobPredicate("create"));
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const teamMemberOptions = useTeamOptions({ predicate: timesheetAbilities.teamPredicate("create") });
  const timesheetDashboardFieldSettings = activeCompany?.settings.timesheets.field_requirements.dashboard;
  const enableAdminWcCode = activeCompany?.settings?.timesheets.enable_admin_wc_code;
  const showEarningType = activeCompany?.settings.timesheets.always_display_earning_types;
  const showPayRate = activeCompany?.settings?.timesheets?.show_pay_rate_in_bulk_create;
  const manualTimeInput = activeCompany?.settings?.timesheets?.mobile_time_input;
  const hasAdvancedBreakTime = !!activeCompany?.settings.timesheets.advanced_break_time;
  const breakTypesObject = activeCompany?.settings.timesheets.break_types;
  const breakTypeOptions = useMemo(() => {
    return Object.entries(breakTypesObject || {})
      .filter(([_id, breakType]) => !breakType.archived)
      .map(([id, breakType]) => ({ label: breakType.label, value: id }));
  }, [breakTypesObject]);
  const policies = usePolicies();
  const hasTimesheetPoliciesEnabled = useMemo(
    () => policies.filter((policy) => policy.type === "timesheet").length > 0,
    [policies]
  );
  const lookupTeam = useLookupTeam();
  const lookupActivity = useLookupActivity();
  const jobOptions = useJobOptions({ predicate: wfmJobScopeWithAbility });
  const activityOptionsMappedByJob = useActivityOptionsMap({
    predicate: isTimesheetScoped,
  });
  const equipmentOptions = useEquipmentOptions();
  const lookupCostType = useLookupCostType();
  const costTypeOptions = useCostTypeOptions();
  const lookupWcCode = useLookupWcCode();
  const wcCodeOptions = useWcCodeOptions();
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const { isAccessFilterScopedWithTeamMember } = useAccessFilterScoped({ teamMember: undefined });

  // classifications
  const getClassificationOptions = useGetClassificationOptions();
  const lookupClassification = useLookupRateClassification();

  const earningTypeLabeler = useEarningTypeLabeler();

  // classes
  const classOptions = useClassOptions();
  const lookupClass = useLookupClass();

  // departments
  const departmentOptions = useDepartmentOptions();
  const lookupDepartment = useLookupDepartment();

  // optional dimensions
  const { hasDepartments, hasClasses } = useOptionalDimensionsUsage();

  // callbacks
  const renderActivityLabel = useCallback((params: ValueFormatterParams) => params.value?.label || "", []);

  // column field requirements
  const BULK_ENTRY_COLUMNS = {
    job: "optional",
    activity: "optional",
    classification: "optional",
    notes: "optional",
    cost_type_id: "optional",
    equipment_ids: "optional",
    rate_differential_id: "optional",
  };
  const {
    job: jobFieldRequirement,
    activity: activityFieldRequirement,
    cost_type_id: costTypeFieldRequirement,
    classification: classificationFieldRequirement,
    notes: notesFieldRequirement,
    equipment_ids: equipmentFieldRequirement,
  } = hasTimesheetPoliciesEnabled
    ? BULK_ENTRY_COLUMNS
    : timesheetDashboardFieldSettings || BULK_ENTRY_COLUMNS;

  // columns
  return useMemo(() => {
    // cell editor params
    const classificationCellEditorParams = (params: {
      data: { team_member?: string; activity?: string; job?: string };
    }) => {
      return {
        options: params?.data
          ? getClassificationOptions({
              tmId: params.data?.team_member,
              activityId: params.data?.activity,
              jobId: params.data?.job,
            })
          : getClassificationOptions({}),
        isClearable: true,
      };
    };

    const columns: ColumnConfig<DraftTimesheet>[] = [
      {
        headerName: "Team member",
        field: "team_member",
        dataType: "string",
        editable: true,
        editorType: "select",
        pinned: "left",
        valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
          const teamMember = lookupTeam(params.value);
          return teamMember?.full_name || "";
        },
        cellEditorParams: () => ({ options: teamMemberOptions, isClearable: true }),
        validations: (value) => {
          if (!value) return "Team member is required";
          return true;
        },
      },
      {
        headerName: "Date",
        field: "clock_in",
        dataType: "number",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
        valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
          if (!params.value) return "-";
          return DateTime.fromISO(params.value).toFormat("MMM dd, yyyy");
        },
        valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
          return params.data?.clock_in ? DateTime.fromSeconds(params.data.clock_in).toISO() : undefined;
        },
        validations: (value) => {
          if (!value) return "Date is required";
          return true;
        },
        valueEditor: (newValue: string, rowData: DraftTimesheet) => {
          return getClockInValue(newValue, rowData);
        },
      },
      {
        field: "clock_in",
        headerName: "In",
        dataType: "number",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
        minWidth: 120,
        valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
          return params.data?.clock_in
            ? DateTime.fromSeconds(params.data.clock_in).toFormat(DEFAULT_TIME_FORMAT)
            : undefined;
        },
        valueEditor: (newValue: string, rowData: DraftTimesheet) => {
          const currentClockInDayInSeconds = DateTime.fromSeconds(
            rowData.clock_in || DateTime.now().toSeconds()
          )
            .startOf("day")
            .toSeconds();
          if (!newValue) {
            return currentClockInDayInSeconds;
          }
          const newClockInDateTime = DateTime.fromFormat(newValue, DEFAULT_TIME_FORMAT);
          const newClockInTimeInSeconds =
            newClockInDateTime.hour * SECONDS_IN_HOUR + newClockInDateTime.minute * 60;
          const clockIn = currentClockInDayInSeconds + newClockInTimeInSeconds;
          if (rowData.hours) {
            rowData.clock_out = clockIn + rowData.hours * SECONDS_IN_HOUR;
            return clockIn;
          }
          return clockIn;
        },
        validations: (value) => {
          if (!value) return "Clock in time is required";
          return true;
        },
        cellEditorParams: {
          dateType: "time",
        },
      },
      manualTimeInput === "duration"
        ? {
            field: "hours",
            headerName: "Hours",
            editable: true,
            dataType: "number",
            minWidth: 120,
            valueEditor: (newValue: number, rowData: DraftTimesheet) => {
              if (rowData.clock_in) rowData.clock_out = rowData.clock_in + newValue * SECONDS_IN_HOUR;
              return newValue;
            },
            validations: (value) => {
              if (!value) return "Hours are required";
              if (value < 0 || value > 24) return "Hours must be between 0 and 24.";
              return true;
            },
          }
        : {
            field: "clock_out",
            headerName: "Out",
            dataType: "number",
            editorType: "date",
            editorDateType: "iso",
            editable: true,
            minWidth: 120,
            valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
              return params.data?.clock_out
                ? DateTime.fromSeconds(params.data.clock_out).toFormat(DEFAULT_TIME_FORMAT)
                : undefined;
            },
            valueEditor: (newValue: string, rowData: DraftTimesheet) => {
              return getClockOutValue(newValue, rowData);
            },
            validations: (value) => {
              if (!value) return "Clock out time is required";
              return true;
            },
            cellEditorParams: {
              dateType: "time",
            },
          },
    ];

    if (hasAdvancedBreakTime) {
      columns.push({
        headerName: "Break start",
        field: "break.start",
        dataType: "number",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
        minWidth: 120,
        valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
          return params.data?.break?.start
            ? DateTime.fromSeconds(params.data.break.start).toFormat(DEFAULT_TIME_FORMAT)
            : undefined;
        },
        valueEditor: (newValue: string, rowData: DraftTimesheet) => {
          return getBreakStartValue(newValue, rowData);
        },
        cellEditorParams: {
          dateType: "time",
          isClearable: true,
        },
      });
      columns.push({
        headerName: "Break end",
        field: "break.end",
        dataType: "number",
        editorType: "date",
        editorDateType: "iso",
        editable: true,
        minWidth: 120,
        valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
          return params.data?.break?.end
            ? DateTime.fromSeconds(params.data.break.end).toFormat(DEFAULT_TIME_FORMAT)
            : undefined;
        },
        valueEditor: (newValue: string, rowData: DraftTimesheet) => {
          return getBreakEndValue(newValue, rowData);
        },
        cellEditorParams: {
          dateType: "time",
          isClearable: true,
        },
      });
      columns.push({
        headerName: "Break type",
        field: "break.break_type_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
          if (!params.data?.break?.break_type_id) return "-";
          const breakTypes = breakTypesObject || {};
          return breakTypes[params.data?.break?.break_type_id]?.label || "";
        },
        cellEditorParams: (row: { data: DraftTimesheet }) => ({
          options: breakTypeOptions,
          // only show the clear button if there is no break times set
          isClearable: !row?.data?.break?.start && !row?.data?.break?.end,
          value: row?.data?.break?.break_type_id,
        }),
      });
    } else {
      columns.push({
        headerName: "Break (minutes)",
        field: "unpaid_break_time",
        headerTooltip: "Unpaid break time in minutes",
        dataType: "number",
        editable: true,
        editorType: "number",
        validations: (value) => {
          if (value && value < 0) return "Unpaid break time must be greater than 0.";
          return true;
        },
      });
    }

    if (jobFieldRequirement !== "hidden") {
      columns.push({
        headerName: "Job",
        field: "job",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueGetter: (params) => {
          const job = params.data?.job;

          // job will be an id if selecting from the bulk edit flow
          if (typeof job === "string") return jobOptions.find((j) => j.value === job)?.label;
          return job;
        },
        valueEditor: (newValue: string, rowData: DraftTimesheet) => {
          const activityId = rowData.activity;
          if (activityId) {
            if (!activityOptionsMappedByJob.get(newValue)?.some((a) => a.value === activityId)) {
              rowData.activity = undefined;
            }
          }
          return newValue;
        },
        cellEditorParams: () => ({ options: jobOptions, isClearable: true }),
      });
    }
    if (activityFieldRequirement !== "hidden") {
      columns.push({
        headerName: "Activity",
        field: "activity",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: renderActivityLabel,
        valueGetter: (params) => {
          const activity = params.data?.activity;
          if (typeof activity === "string") return lookupActivity(activity);
          return activity;
        },
        cellEditorParams: (row: { data: DraftTimesheet }) => {
          const jobId = row?.data?.job;
          const jobSpecificActivityOptions = activityOptionsMappedByJob.get(jobId);

          const teamMemberScopedActivityOptions = jobSpecificActivityOptions?.filter((activityOption) => {
            const activity = lookupActivity(activityOption.value);
            const teamMember = lookupTeam(row?.data?.team_member);
            return activity && teamMember ? isAccessFilterScopedWithTeamMember(activity, teamMember) : true;
          });

          return {
            options: teamMemberScopedActivityOptions,
            isClearable: true,
          };
        },
      });
    }
    if (classificationFieldRequirement !== "hidden") {
      columns.push({
        headerName: "Classification",
        field: "classification_override",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
          const classification = params.value;
          return classification?.classification || "";
        },
        valueGetter: (params) => {
          const classification = params.data?.classification_override;
          if (typeof classification === "string") return lookupClassification(classification);
          return classification;
        },
        cellEditorParams: classificationCellEditorParams,
      });
    }

    if (hasDepartments) {
      columns.push({
        headerName: "Department override",
        headerTooltip: "This will override the department from the team member or job.",
        field: "department_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
          const classObj = params.value;
          return classObj?.name || "";
        },
        valueGetter: (params) => {
          const deptIdOrObj = params.data?.department_id;
          if (typeof deptIdOrObj === "string") return lookupDepartment(deptIdOrObj);
          return deptIdOrObj;
        },
        cellEditorParams: () => ({
          options: departmentOptions,
          isClearable: true,
        }),
      });
    }

    // TODO: add location override

    if (hasClasses) {
      columns.push({
        headerName: "Class override",
        headerTooltip: "This will override the class from the team member or job.",
        field: "class_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
          const classObj = params.value;
          return classObj?.name || "";
        },
        valueGetter: (params) => {
          const classIdOrObj = params.data?.class_id;
          if (typeof classIdOrObj === "string") return lookupClass(classIdOrObj);
          return classIdOrObj;
        },
        cellEditorParams: () => ({
          options: classOptions,
          isClearable: true,
        }),
      });
    }

    if (notesFieldRequirement !== "hidden") {
      columns.push({
        headerName: "Notes",
        field: "notes",
        tooltipField: "notes",
        dataType: "string",
        editable: true,
        minWidth: 150,
      });
    }
    if (equipmentFieldRequirement !== "hidden" && hasAccessToEquipmentTracking) {
      columns.push({
        headerName: "Equipment",
        field: "equipment_options",
        dataType: "string",
        editable: true,
        editorType: "multiselect",
        valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
          const equipmentOptions = params.value || [];
          const equipmentNames = equipmentOptions.length
            ? equipmentOptions.map((equipmentOption) => equipmentOption?.label).join(", ")
            : null;
          return equipmentNames || "";
        },
        cellEditorParams: () => ({ options: equipmentOptions, isClearable: true }),
      });
    }
    if (costTypeFieldRequirement !== "hidden") {
      columns.push({
        headerName: "Cost type",
        field: "cost_type_id",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueGetter: (params) => {
          const costType = params.data?.cost_type_id;
          if (typeof costType === "string") return lookupCostType(costType);
          return costType;
        },
        valueFormatter: (params) => {
          const costType = params.value;
          return costType?.label || "";
        },
        cellEditorParams: () => ({ options: costTypeOptions, isClearable: true }),
      });
    }
    if (enableAdminWcCode) {
      columns.push({
        headerName: "Workers' comp code",
        field: "wc_code",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueGetter: (params) => {
          const wcCode = params.data?.wc_code;
          if (typeof wcCode === "string") return lookupWcCode(wcCode);
          return wcCode;
        },
        valueFormatter: (params) => {
          const wcCode = params.value;
          return wcCode?.label || "";
        },
        cellEditorParams: () => ({ options: wcCodeOptions, isClearable: true }),
      });
    }
    if (showEarningType) {
      columns.push({
        headerName: "Earning type",
        field: "earning_type",
        dataType: "string",
        editable: true,
        editorType: "select",
        valueGetter: (params: ValueGetterParams<DraftTimesheet>) => {
          if (!params.data?.earning_type) return "Auto";
          const earningTypeParams = getEarningTypeParamsFromAlias(params.data?.earning_type);
          return earningTypeLabeler({
            ...params.data,
            ...earningTypeParams,
          });
        },
        cellEditorParams: (params: { data: { team_member?: string } }) => ({
          options: getEarningTypeOptionsForTmFlattened(
            lookupTimeOffPolicy,
            "timesheet",
            lookupTeam(params?.data?.team_member)
          ),
          isClearable: true,
        }),
      });
    }

    if (tsCustomFields.length > 0) {
      tsCustomFields.forEach((cf) => {
        columns.push({
          headerName: cf.name,
          field: `custom_field_values.${cf._id}`,
          editable: true,
          dataType: getTableDataTypeForCustomField(cf),
          editorType: getTableEditorTypeForCustomField(cf),
          valueFormatter: (params: ValueFormatterParams<DraftTimesheet>) => {
            if (Array.isArray(params.value) && params.value.length)
              return params.value.map((v) => v.value).join(", ");
            if (cf.type === "checkbox") return !!params.value;
            return params.value?.value || params.value || "-";
          },
          cellEditorParams: () => ({
            options: getOptionsForCustomField(cf),
            isClearable: true,
          }),
          validations: getValidatorForCustomField(cf),
          valueSetter: (params: ValueSetterParams<DraftTimesheet>) => {
            if (!params.data.custom_field_values) params.data.custom_field_values = {};
            if (params.newValue.value) {
              params.data.custom_field_values[cf._id] = params.newValue.value;
            } else {
              params.data.custom_field_values[cf._id] = params.newValue;
            }
            return true;
          },
        });
      });
    }

    columns.push({
      headerName: "",
      field: "_id",
      pinned: "right",
      maxWidth: 80,
      cellRenderer: (params: ICellRendererParams<DraftTimesheet>) => {
        if (!params.node.allChildrenCount) {
          return (
            <div className="flex justify-content-center width-100-percent">
              <button
                onClick={() => params.data && handleDuplicateRow(params.data, params.node.rowIndex!)}
                className="black-link no-margin button-text hover-purple-link"
                style={{ marginRight: 10, fontSize: "1.2rem", opacity: 1 }}
              >
                <Copy style={{ marginTop: 3 }} />
              </button>
              <button
                onClick={() => params.data && handleDeleteRow(params.data)}
                className="black-link no-margin button-text hover-purple-link"
                style={{ marginRight: 5, fontSize: "1.2rem", opacity: 1 }}
              >
                <X style={{ marginTop: 3 }} />
              </button>
            </div>
          );
        } else {
          return null;
        }
      },
    });

    if (showPayRate) {
      columns.push({
        headerName: "Pay rate",
        dataType: "string",
        editable: false,
        cellRenderer: PayRateCellRenderer,
        editorType: "select",
      });
    }

    return columns;
  }, [
    manualTimeInput,
    hasAdvancedBreakTime,
    jobFieldRequirement,
    activityFieldRequirement,
    classificationFieldRequirement,
    hasDepartments,
    hasClasses,
    notesFieldRequirement,
    equipmentFieldRequirement,
    hasAccessToEquipmentTracking,
    costTypeFieldRequirement,
    enableAdminWcCode,
    showEarningType,
    tsCustomFields,
    showPayRate,
    getClassificationOptions,
    lookupTeam,
    teamMemberOptions,
    breakTypesObject,
    breakTypeOptions,
    jobOptions,
    activityOptionsMappedByJob,
    renderActivityLabel,
    lookupActivity,
    isAccessFilterScopedWithTeamMember,
    lookupClassification,
    lookupDepartment,
    departmentOptions,
    lookupClass,
    classOptions,
    equipmentOptions,
    lookupCostType,
    costTypeOptions,
    lookupWcCode,
    wcCodeOptions,
    earningTypeLabeler,
    lookupTimeOffPolicy,
    handleDuplicateRow,
    handleDeleteRow,
  ]);
};
