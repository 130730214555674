import React, { useState, useMemo } from "react";
import PayrollContext from "./payrollContext";
import { usdString } from "ui";
import PaymentModal from "./PaymentModal/PaymentModal";
import { capitalize } from "lodash";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";

type WarningTableEntry = {
  _id: string;
  tmId: string;
  tmName: string;
  description: string;
  type: string;
  expectedAmt: string;
  actualAmt: string;
  reason: string;
};

export const PayrollCheckWarnings: React.FC = () => {
  const { payroll, isLoading } = React.useContext(PayrollContext);

  // State
  const [activePayment, setActivePayment] = useState<string>();

  const checkWarnings: WarningTableEntry[] = useMemo(() => {
    return (
      payroll?.miter_payments.flatMap(
        (mp) =>
          mp.check_item?.warnings?.map((w) => {
            const warningType =
              w.deduction_type === "post_tax_deduction" ? "Post-tax deduction" : capitalize(w.deduction_type);
            let desc = warningType;
            if (w.deduction_type === "tax") {
              const tax = mp.check_item?.taxes?.find((t) => t.tax === w.deduction);
              if (tax) desc = tax.description;
            } else if (w.deduction_type === "benefit") {
              const ben = mp.check_item?.benefits?.find((b) => b.id === w.deduction);
              if (ben) desc = ben.description;
            } else if (w.deduction_type === "post_tax_deduction") {
              const ptd = mp.check_item?.post_tax_deductions?.find(
                (p) => p.post_tax_deduction === w.deduction
              );
              if (ptd) desc = ptd.description;
            }

            return {
              _id: mp.team_member._id + w.deduction,
              tmId: mp.team_member._id,
              tmName: mp.team_member.full_name,
              description: desc,
              type: warningType,
              expectedAmt: usdString(w.expected_deduction_amount),
              actualAmt: usdString(w.actual_deduction_amount),
              reason:
                w.reason === "negative_net_pay"
                  ? "Would have made net pay negative"
                  : "Reached max percentage allowed",
            };
          }) || []
      ) || []
    );
  }, [payroll]);

  return (
    <div>
      <span className="run-payroll-subheader">
        {`The following team members have warnings associated with their benefits, deductions, or taxes.`}
      </span>
      <TableV2
        id="payroll-check-warnings-table"
        resource="warnings"
        data={checkWarnings}
        columns={checkWarningCols}
        onClick={(e) => setActivePayment(e.tmId)}
        isLoading={isLoading}
        hideSecondaryActions
        hideSearch
        hideSelectColumn
        hideFooter
      />
      {activePayment && (
        <PaymentModal tmId={activePayment} editing={false} hide={() => setActivePayment(undefined)} />
      )}
      <div className="vertical-spacer-small" />
    </div>
  );
};

const checkWarningCols: ColumnConfig<WarningTableEntry>[] = [
  { field: "tmName", headerName: "Team member", dataType: "string" },
  { field: "type", headerName: "Type", displayType: "badge" },
  { field: "description", headerName: "Description", dataType: "string" },
  { field: "expectedAmt", headerName: "Expected", dataType: "string" },
  { field: "actualAmt", headerName: "Actual", dataType: "string" },
  { field: "reason", headerName: "Reason", dataType: "string", minWidth: 300 },
];
