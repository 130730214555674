import React from "react";
import TeamChatContainer from "dashboard/components/chat/team/TeamChatContainer";
import { FC } from "react";
import { Helmet } from "react-helmet";

const Chat: FC = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>Chat | Miter</title>
      </Helmet>
      <TeamChatContainer />
    </div>
  );
};

export default Chat;
