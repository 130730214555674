import AppContext from "dashboard/contexts/app-context";
import { Department, MiterIntegrationForCompany } from "dashboard/miter";
import { useContext } from "react";

export const useDepartmentSourceSystem = (
  department?: Department
): MiterIntegrationForCompany | undefined => {
  const { integrations } = useContext(AppContext);

  let sourceSystem: MiterIntegrationForCompany | undefined;
  if (department?.integrations) {
    const key = Object.keys(department.integrations)[0];
    if (key) sourceSystem = integrations.find((i) => i.key === key);
  }

  return sourceSystem;
};
