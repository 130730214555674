import React, { useState } from "react";
import TimesheetContext from "./timesheetContext";
import { useForm } from "react-hook-form";
import { Button, Formblock, Notifier } from "ui";
import { FaExclamationTriangle } from "react-icons/fa";
import { ESignatureItem, MiterAPI } from "dashboard/miter";
import { ESignatureModal } from "dashboard/components/esignatures/ESignatureModal";
import { DateTime } from "luxon";
import { useLookupPolicy } from "dashboard/hooks/atom-hooks";

export const TimesheetSignOff: React.FC = () => {
  const lookupPolicy = useLookupPolicy();
  const { timesheet } = React.useContext(TimesheetContext);
  const form = useForm();

  const [gettingSignature, setGettingSignature] = useState(false);
  const [esignatureItem, setEsignatureItem] = useState<ESignatureItem | undefined>();
  const [showSigModal, setShowSigModal] = useState(false);

  const isMissingSignOff = timesheet?.sign_off?.status === "missing";
  const timesheetPolicy = lookupPolicy(timesheet?.approval_stage?.policy_id);
  const signedAtTimestamp = timesheet?.sign_off?.timestamp;
  const signedAt = signedAtTimestamp
    ? DateTime.fromSeconds(signedAtTimestamp).toLocaleString(DateTime.DATETIME_MED)
    : "N/A";

  const viewSignature = async () => {
    if (!timesheet?.sign_off?.esignature_item_id && timesheet?.sign_off?.data) return setShowSigModal(true);

    if (!timesheet?.sign_off?.esignature_item_id) return;
    if (esignatureItem) return setShowSigModal(true);

    setGettingSignature(true);

    try {
      const response = await MiterAPI.esignature_items.retrieve(timesheet?.sign_off?.esignature_item_id);
      if (response.error) throw new Error(response.error);

      setEsignatureItem(response);
      setShowSigModal(true);
    } catch (e) {
      console.error(e);
      Notifier.error("Failed to retrieve signature.");
    }
    setGettingSignature(false);
  };

  return (
    <div className="width-100">
      <div className="payment-active-view-header">
        <div className="">Timesheet sign-off</div>
        <div className="flex-1"></div>
      </div>
      {isMissingSignOff && (
        <>
          <div className="yellow-text-container flex" style={{ maxWidth: 800 }}>
            <FaExclamationTriangle style={{ marginLeft: 10, marginRight: 10 }} color="#a59613" />
            <div>{`${timesheet.team_member.full_name} has not yet signed off on this timesheet.`}</div>
          </div>
          <div style={{ height: 15 }}></div>
        </>
      )}
      <Formblock
        type="text"
        defaultString={`${timesheetPolicy?.name || "Unknown"}`}
        form={form}
        label="Timesheet policy"
      />
      <Formblock type="text" defaultString={signedAt} form={form} label="Signed at" />
      {(timesheet?.sign_off?.esignature_item_id || timesheet?.sign_off?.data) && (
        <Button
          className="button-1 no-margin"
          text="View signature"
          onClick={viewSignature}
          loading={gettingSignature}
        />
      )}
      {showSigModal && (esignatureItem || timesheet?.sign_off?.data) && (
        <ESignatureModal
          onHide={() => setShowSigModal(false)}
          esignatureItem={esignatureItem}
          imageData={!esignatureItem ? timesheet?.sign_off?.data : undefined}
        />
      )}
      <div style={{ height: 50 }}></div>
    </div>
  );
};
