import React from "react";
import { useNavigate } from "react-router-dom";
import Banner from "dashboard/components/shared/Banner";
import { useActiveCompany, usePayrollReadiness } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

type Props = {
  homePage?: boolean;
};

const PayrollBanner: React.FC<Props> = ({ homePage }) => {
  const navigate = useNavigate();
  const payrollReadiness = usePayrollReadiness();
  const { cannot } = useMiterAbilities();
  const className = "top-20";
  const activeCompany = useActiveCompany();

  if (cannot("payrolls:approve")) return null;

  switch (payrollReadiness?.status) {
    case "onboarding_blocking":
      let blockingText =
        "Ready to approve your draft payrolls? Click here to finish your onboarding process.";
      if (!activeCompany?.has_payroll)
        blockingText = `${activeCompany?.check_company.trade_name} is not yet onboarded. Click here to complete your onboarding.`;
      return (
        <Banner
          content={blockingText}
          onClick={() => navigate("/onboarding")}
          className={className}
          type="warning"
        />
      );

    case "bad_invoice":
      return (
        <Banner
          content={
            "There was an error processing one of your recent invoices. To run payroll, please visit the Billing module to pay any outstanding invoices."
          }
          type="error"
          className={className}
          onClick={() => navigate("/settings/billing")}
        />
      );

    case "check_not_good_standing":
      return (
        <Banner
          content={
            "We were unable to withdraw funds from your bank account for a recent payroll. Payroll is disabled until payment is made. Please navigate to the failed payroll to learn more."
          }
          type="error"
          onClick={homePage ? () => navigate("/payrolls") : undefined}
          className={className}
        />
      );

    case "active_needs_attention":
      return (
        <Banner
          content={
            "You are able to run payroll, but your onboarding is incomplete. Please provide the necessary information as soon as possible."
          }
          onClick={() => navigate("/onboarding")}
          className={className}
        />
      );
  }
  return null;
};

export default PayrollBanner;
