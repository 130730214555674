import React, { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { Control, DeepMap, FieldError, FieldValues, useForm } from "react-hook-form";
import { Formblock, Loader, ModalSidebar } from "ui";
import { JobIntegrationsProps } from "./JobIntegrations";
import { QBCustomerOption, useQBJobMapping } from "dashboard/utils/useQBJobMapping";
import { QBDCustomer } from "backend/services/qbd/qbd-types";
import { QBOCustomer } from "backend/services/qbo/qbo-types";
import styles from "ui/modal/modalWithSidebar.module.css";
import {
  AggregatedJob,
  IntegrationConnectionForQB,
  Job,
  MiterIntegrationForCompanyWithConnection,
} from "dashboard/miter";
import { DeepPartial } from "utility-types";
import { MenuItemWithoutComponent } from "ui/modal/ModalWithSidebar";
import checkmark from "dashboard/assets/check-mark.png";
import yellow_warning from "dashboard/assets/yellow-warning.png";
import x from "dashboard/assets/x.png";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useClassAndItemMappingOptions } from "dashboard/hooks/integrations/quickbooks/useClassAndItemMappingOptions";

export const JobQuickBooksMappingModal: React.FC<
  JobIntegrationsProps & {
    hide: () => void;
    connectedQBIntegrations: MiterIntegrationForCompanyWithConnection<IntegrationConnectionForQB>[];
  }
> = ({ job, updateJob, updatingJob, hide, errorUpdatingJob, connectedQBIntegrations }) => {
  const { errors, control } = useForm();

  const handleToggle = (path: string) => {
    const activeItem = menuItems.find((item) => item.path === path);
    setActiveMenuItem(activeItem);
  };

  const qboConnection = connectedQBIntegrations.find(
    (integrationConnection) => integrationConnection.connection.integration_key === "qbo"
  );
  const qbdConnection = connectedQBIntegrations.find(
    (integrationConnection) => integrationConnection.connection.integration_key === "qbd"
  );

  const menuItems = buildMenuItems(!!qboConnection, !!qbdConnection);

  const [activeMenuItem, setActiveMenuItem] = useState<MenuItemWithoutComponent | undefined>(menuItems[0]);

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => hide()}>
        <div className={styles["modal-wrapper"]}>
          <div className={styles["header"]}>
            <div className="width-100 flex">
              <div>Connect QuickBooks Customer</div>
              <div className="flex-1"></div>
              {updatingJob ? (
                <Loader className="small-text" />
              ) : (
                <img
                  src={errorUpdatingJob ? yellow_warning : checkmark}
                  style={{ height: 15, marginTop: 2 }}
                />
              )}
              <div style={{ width: 10 }}></div>
            </div>
            <div className="flex-1"></div>

            <img src={x} style={{ height: 12, cursor: "pointer" }} onClick={hide} />
          </div>
          <div className={styles["below"]}>
            <ModalSidebar config={menuItems} active={activeMenuItem?.path} toggle={handleToggle} />
            <div className={styles["active-view"]}>
              {activeMenuItem?.path === "qbo" && qboConnection && (
                <ModalContent
                  job={job}
                  updateJob={updateJob}
                  control={control}
                  errors={errors}
                  integrationConnection={qboConnection}
                />
              )}
              {activeMenuItem?.path === "qbd" && qbdConnection && (
                <ModalContent
                  job={job}
                  updateJob={updateJob}
                  control={control}
                  errors={errors}
                  integrationConnection={qbdConnection}
                />
              )}
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

type ModalContentProps = {
  integrationConnection: MiterIntegrationForCompanyWithConnection<IntegrationConnectionForQB>;
  job: AggregatedJob;
  control: Control<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  updateJob: (update: Partial<Job> & Record<string, $TSFixMe>) => Promise<void>;
};

const ModalContent: React.FC<ModalContentProps> = ({
  integrationConnection,
  control,
  errors,
  job,
  updateJob,
}) => {
  const activeCompanyId = useActiveCompanyId();

  const setSelectedCustomerOption = async (
    option: QBCustomerOption<QBDCustomer | QBOCustomer> | null
  ): Promise<void> => {
    const updateParams = getUpdateParams(
      integrationConnection.connection.integration_key,
      option?.customer ?? null
    );

    await updateJob(buildAtomicMongoUpdateFromNested(updateParams, { collapseCount: 2 }));
  };

  const handleFieldsChange = async (update: Partial<Job>): Promise<void> => {
    await updateJob(buildAtomicMongoUpdateFromNested(update, { collapseCount: 2 }));
  };

  const connectedSystem = integrationConnection.connection.integration_key;

  const {
    loadingCustomerOptions,
    qboCustomerOptions,
    qbdCustomerOptions,
    selectedQboCustomerOption,
    selectedQbdCustomerOption,
    setSelectedQboCustomerOption,
    setSelectedQbdCustomerOption,
  } = useQBJobMapping({ integrationConnection, job });

  const {
    classOptions: qbdClassOptions,
    getQbClassIdForJob,
    handleClassChange,
    loading: loadingClassOptions,
  } = useClassAndItemMappingOptions([connectedSystem], handleFieldsChange, activeCompanyId, ["classes"]);

  const isQBO = integrationConnection.connection.integration_key === "qbo";
  const isQBD = integrationConnection.connection.integration_key === "qbd";

  const showQBOFormblock = !loadingCustomerOptions && isQBO && qboCustomerOptions;
  const showQBDCustomerOptions = !loadingCustomerOptions && isQBD && qbdCustomerOptions;
  const showQBDClassOptions = !loadingClassOptions && isQBD && qbdClassOptions;

  return (
    <div className="modal-body form">
      {loadingCustomerOptions && <Loader />}
      {showQBOFormblock && (
        <Formblock
          type="select"
          name={"qbo_customer"}
          label={"QBO Customer"}
          labelInfo="Select the QBO customer you want to associate to this Job."
          control={control}
          editing={true}
          errors={errors}
          options={qboCustomerOptions}
          value={selectedQboCustomerOption}
          onChange={(option) => {
            setSelectedQboCustomerOption(option);
            setSelectedCustomerOption(option);
          }}
          className="modal"
        />
      )}
      {showQBDCustomerOptions && (
        <Formblock
          type="select"
          name={"qbd_customer"}
          label={"QBD Customer"}
          labelInfo="Select the QBD customer you want to associate to this Job."
          control={control}
          editing={true}
          errors={errors}
          options={qbdCustomerOptions}
          value={selectedQbdCustomerOption}
          onChange={(option) => {
            setSelectedQbdCustomerOption(option);
            setSelectedCustomerOption(option);
          }}
          className="modal"
          isClearable
        />
      )}
      <div className="vertical-spacer"></div>
      {showQBDClassOptions && (
        <>
          {loadingClassOptions ? (
            <Loader />
          ) : (
            <Formblock
              type="select"
              name={"qbd_class"}
              label="QBD Class"
              labelInfo="Select the QBD Class you want to associate to this Job."
              control={control}
              editing={true}
              errors={errors}
              options={qbdClassOptions || []}
              defaultValue={getQbClassIdForJob(job, connectedSystem)}
              onChange={(option) => {
                handleClassChange(option);
              }}
              className="modal"
              isClearable
            />
          )}
        </>
      )}
      {!loadingCustomerOptions && !showQBOFormblock && !showQBDCustomerOptions && !showQBDClassOptions && (
        <div>Error loading Customers from QB</div>
      )}
    </div>
  );
};

const getUpdateParams = (
  connectedSystem: "qbd" | "qbo",
  optionValue: QBOCustomer | QBDCustomer | null | undefined
): DeepPartial<Job> => {
  return {
    integrations: {
      [connectedSystem]: {
        customer: optionValue,
      },
    },
  };
};

type MenuItems = {
  label: string;
  path: string;
};

const buildMenuItems = (hasQBO: boolean, hasQBD: boolean): MenuItems[] => {
  const menuItems: MenuItems[] = [];
  if (hasQBO) {
    menuItems.push({
      label: "QuickBooks Online",
      path: "qbo",
    });
  }

  if (hasQBD) {
    menuItems.push({
      label: "QuickBooks Desktop",
      path: "qbd",
    });
  }

  return menuItems;
};
