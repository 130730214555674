import React from "react";
import styles from "./DescriptionTable.module.css";

type DescriptionTableItem = {
  label: string;
  value: string;
};

type DescriptionTableProps = {
  items: DescriptionTableItem[];
};

export const DescriptionTable: React.FC<DescriptionTableProps> = ({ items }) => {
  return (
    <table className={styles["description-table"]} cellSpacing={0}>
      <tbody>
        {items.map((item, index) => {
          return (
            <tr key={item.label + item.value + index} className={styles["description-table-item"]}>
              <td className={styles["description-table-item-label"]}>{item.label}</td>
              <td className={styles["description-table-item-value"]}>{item.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
