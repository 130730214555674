import { LeaveType, LeaveTypeRequest, MiterAPI, MiterError } from "dashboard/miter";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Label } from "ui";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";

type Props = {
  hide: () => void;
  leaveType?: LeaveType;
};

export const LeaveTypeModal: React.FC<Props> = ({ hide, leaveType }) => {
  const [loading, setLoading] = useState(false);

  const { register, errors, control, handleSubmit } = useForm({ defaultValues: leaveType });

  const saveType = async (data: LeaveTypeRequest) => {
    setLoading(true);
    try {
      let response: LeaveType & MiterError;

      if (leaveType) {
        response = await MiterAPI.time_off.leave.update_type(leaveType._id, {
          label: data.label,
          config: data.config,
          archived: false,
        });
      } else {
        response = await MiterAPI.time_off.leave.create_type(data);
      }
      if (response.error) throw new Error(response.error);
      Notifier.success("Leave type was successfully saved.");
      hide();
    } catch (e) {
      Notifier.error("There was a problem saving leave type. We're looking into it!");
    }
    setLoading(false);
  };
  return (
    <div style={{ zIndex: 4 }}>
      <ActionModal
        onHide={hide}
        onCancel={hide}
        onSubmit={handleSubmit(saveType)}
        submitText={leaveType ? "Update" : "Save"}
        headerText={`Set leave type`}
        loading={loading}
        showCancel={true}
        showSubmit={true}
        wrapperStyle={{ width: 400 }}
        bodyStyle={{ maxHeight: 580 }}
      >
        <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
          <Formblock
            label="Name*"
            type="text"
            control={control}
            name="label"
            register={register(vals.required)}
            className={"modal "}
            errors={errors}
            editing={true}
          />
          <Label style={{ height: "auto", marginBottom: 10, marginTop: 10 }} label="Configurations"></Label>
          <Formblock
            name="config.pause_payroll"
            type="checkbox"
            text="Pause payroll"
            errors={errors}
            defaultValue={false}
            editing={true}
            register={register()}
            style={{ marginBottom: 0 }}
          />
          <Formblock
            name="config.pause_notifications"
            type="checkbox"
            text="Pause notifications"
            errors={errors}
            defaultValue={false}
            editing={true}
            style={{ marginBottom: 0 }}
            register={register()}
          />
          <Formblock
            name="config.time_off.pause_accruals"
            type="checkbox"
            text="Pause fixed time off accruals"
            errors={errors}
            defaultValue={false}
            editing={true}
            register={register()}
            style={{ marginBottom: 0 }}
          />
          <Formblock
            name="config.time_off.pause_level_changes"
            type="checkbox"
            text="Pause time off policy level changes"
            errors={errors}
            defaultValue={false}
            editing={true}
            register={register()}
            style={{ marginBottom: 0 }}
          />
          <Formblock
            name="config.time_off.pause_applying_carryover_limits"
            type="checkbox"
            text="Pause applying any time off carry over limits"
            errors={errors}
            defaultValue={false}
            editing={true}
            register={register()}
            style={{ marginBottom: 0 }}
          />
          <div className="vertical-spacer"></div>
        </div>
      </ActionModal>
    </div>
  );
};
