import React from "react";
import TimesheetContext from "./timesheetContext";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { ValueGetterParams } from "ag-grid-community";
import { roundTo } from "miter-utils";
import { TimesheetSection } from "dashboard/miter";
import ObjectID from "bson-objectid";
import {
  TimesheetSectionTimeType,
  timeTypeFromTimesheetSection,
  timesheetSectionTimeTypeColors,
} from "../TimesheetsByPayPeriod/timesheetsByPayPeriodUtils";

type TimesheetSectionTableEntry = TimesheetSection & {
  _id: string;
};

export const TimesheetSections: React.FC = () => {
  const { timesheet, loading } = React.useContext(TimesheetContext);

  const data =
    timesheet?.sections
      ?.map((tss) => {
        return { ...tss, _id: new ObjectID().toHexString() };
      })
      .sort((a, b) => a.clock_in - b.clock_in) || [];

  const columns: ColumnConfig<TimesheetSectionTableEntry>[] = [
    {
      field: "clock_in",
      headerName: "Start",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "h:mm a",
    },
    {
      field: "clock_out",
      headerName: "End",
      dataType: "date",
      dateType: "timestamp",
      dateFormat: "h:mm a",
    },
    {
      field: "hours",
      headerName: "Hours",
      dataType: "number",
      valueGetter: (params: ValueGetterParams<TimesheetSectionTableEntry>): number => {
        const hours = params?.data?.hours;
        if (!hours) {
          return 0;
        }
        // Handle values close to 0 without ever rounding down exactly to 0.
        return roundTo(Math.max(hours, 0.01));
      },
    },
    {
      field: "time_type",
      headerName: "Time type",
      dataType: "string",
      displayType: "badge",
      filter: "agSetColumnFilter",
      colors: timesheetSectionTimeTypeColors,
      valueGetter: (
        params: ValueGetterParams<TimesheetSectionTableEntry>
      ): TimesheetSectionTimeType | undefined => {
        return timeTypeFromTimesheetSection(params.data);
      },
    },
  ];

  return (
    <>
      <div className="payment-active-view-header">
        <div className="">Sections</div>
      </div>
      <TableV2
        id={`timesheet-sections-table`}
        resource={`timesheet sections`}
        isLoading={loading}
        data={data}
        columns={columns}
        wrapperClassName="base-ssr-table"
        groupDefaultExpanded={1}
        showTotals={false}
        hideSearch={true}
        hideSecondaryActions={true}
      />
    </>
  );
};
