import InfoButton from "dashboard/components/information/information";
import { TimesheetSettings } from "./TimesheetSettings";

interface TimesheetsTableSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetsTableSettings: React.FC<TimesheetsTableSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Timesheets table</div>
        <InfoButton text="This setting allows you to sort by first and last name" />
      </div>
      <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
        <div className="vertical-spacer-small"></div>
        <div className="flex">
          <input
            type="radio"
            style={{ margin: "0px", marginRight: 12 }}
            onChange={() => handleChange({ break_name_into_first_and_last: true })}
            checked={timesheetSettings.break_name_into_first_and_last}
          />
          <label>
            <span className="bold">First and last name columns</span>: Separate the first/last name of the
            team member into two columns in the table.
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px", marginRight: 12 }}
            onChange={() => handleChange({ break_name_into_first_and_last: false })}
            checked={!timesheetSettings.break_name_into_first_and_last}
          />
          <label>
            <span className="bold">Full name column</span>: Show the full name of the team member as one
            column in the table.
          </label>
        </div>
      </div>
      <div className="vertical-spacer"></div>
      <div className="flex">
        <input
          style={{ marginLeft: 0 }}
          type="checkbox"
          checked={timesheetSettings.show_timezone_selector}
          onChange={(e) => handleChange({ show_timezone_selector: e.target.checked })}
        />
        <span style={{ marginLeft: 12 }}>
          Show a timezone selector in the timesheets table to allow you to view timesheets in different
          timezones.
        </span>
      </div>
      <div className="vertical-spacer-small"></div>
      <div className="flex">
        <input
          style={{ marginLeft: 0 }}
          type="checkbox"
          checked={timesheetSettings.expand_table_groups_on_select}
          onChange={(e) => handleChange({ expand_table_groups_on_select: e.target.checked })}
        />
        <span style={{ marginLeft: 12 }}>
          Expand grouped rows in the timesheets table when you select them
        </span>
      </div>
      <div className="vertical-spacer-small"></div>
      <div className="flex">
        <input
          style={{ marginLeft: 0 }}
          type="checkbox"
          checked={timesheetSettings.table_select_all_selects_loaded_rows}
          onChange={(e) => handleChange({ table_select_all_selects_loaded_rows: e.target.checked })}
        />
        <span style={{ marginLeft: 12 }}>
          When selecting all rows in the timesheets table, only select the rows that have been loaded in
        </span>
      </div>
    </div>
  );
};

export default TimesheetsTableSettings;
