import React, { useMemo, useState } from "react";
import { BasicModal } from "ui";
import { ActivityBenefitContribution } from "backend/models/activity";
import { ActivityBenefitContributionModal } from "./ActivityBenefitContributionModal";
import InfoButton from "../information/information";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { ActivityModalTabProps } from "./TabbedActivityModal";
import { usdString } from "ui";

export type ActivityBenefitContributionTableEntry = ActivityBenefitContribution & {
  amountLabel: string;
  categoryLabel: string;
};

export const benefitTypeLookup = {
  health_and_welfare: "Health & Welfare",
  pension: "Pension",
  sub: "Supplemental Unemployment Benefit",
  training: "Training fees",
  vacation_holiday: "Vacation & Holiday",
  travel_subsistence: "Travel & Subsistence",
  other: "Other",
};

export const ActivityContributions: React.FC<ActivityModalTabProps> = ({
  selectedActivity,
  handleFieldsChange,
  readonly,
}) => {
  const [editing, setEditing] = useState<ActivityBenefitContributionTableEntry | undefined>();
  const [creating, setCreating] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ActivityBenefitContributionTableEntry[]>([]);
  const [deleting, setDeleting] = useState(false);

  const tableData: ActivityBenefitContributionTableEntry[] = useMemo(() => {
    return (
      selectedActivity.benefit_contributions?.map((f) => {
        return {
          ...f,
          categoryLabel: benefitTypeLookup[f.category],
          amountLabel: f.dynamic_offset ? "Dynamic offset" : `${usdString(f.amount)}/hour`,
        };
      }) || []
    );
  }, [selectedActivity]);

  const deleteContributions = async () => {
    if (readonly) return;

    setDeleting(true);
    const selectedRowIds = selectedRows.map((r) => r._id);
    const newContributions = [...(selectedActivity.benefit_contributions || [])].filter((b) => {
      if (!selectedRowIds.includes(b._id)) {
        return true;
      }
    });
    handleFieldsChange({ benefit_contributions: newContributions });
    setSelectedRows([]);
    setDeleteModal(false);
    setDeleting(false);
  };

  const handleFringeModalHide = () => {
    setCreating(false);
    setEditing(undefined);
  };

  const columns: ColumnConfig<ActivityBenefitContributionTableEntry>[] = [
    {
      headerName: "Label",
      field: "label",
      width: 100,
      dataType: "string",
    },
    {
      headerName: "Category",
      field: "categoryLabel",
      minWidth: 150,
      dataType: "string",
    },
    {
      headerName: "Amount",
      field: "amountLabel",
      dataType: "string",
    },
  ];

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add",
        className: "button-2 no-margin",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !readonly,
      },
    ],
    [readonly]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setDeleteModal(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => !readonly,
      },
    ],
    [readonly]
  );

  return (
    <div>
      <div className="vertical-spacer-small"></div>
      {(creating || editing) && (
        <ActivityBenefitContributionModal
          selectedActivity={selectedActivity}
          handleFieldsChange={handleFieldsChange}
          hide={handleFringeModalHide}
          contributionToUpdate={editing}
        />
      )}
      {deleteModal && (
        <BasicModal
          headerText="Delete benefit contributions?"
          yellowBodyText={true}
          loading={deleting}
          bodyText={`Are you sure you want to delete ${
            selectedRows.length > 1 ? "these contributions" : "this contribution"
          }?`}
          button2Action={deleteContributions}
          button2Text="Delete"
          button1Action={() => setDeleteModal(false)}
        />
      )}
      <div className="flex">
        <div style={{ fontSize: 19, fontWeight: "bold" }}>Activity-level contributions</div>
        <InfoButton
          text="These employer benefit contributions apply ONLY to this activity. These contributions will be applied in addition to employee-level benefits and union fringes. Need
        help? Reach out to support@miter.com."
        />
      </div>

      <TableV2
        id={"activity-benefit-contributions-table"}
        resource="contributions"
        data={tableData}
        columns={columns}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        onClick={(r) => setEditing(r)}
        hideSearch={true}
        hideSecondaryActions={true}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        containerStyle={{ marginTop: -50, paddingBottom: 0 }}
      />
    </div>
  );
};
