import { AggregatedTimeOffRequest, Company, FrontendModel, TimeOffRequest } from "dashboard/miter";
import {
  DAY_COL_PREFIX,
  getEarningTypeAliasForTor,
  getEarningTypeParamsFromAlias,
} from "../timesheetsByPayPeriodUtils";
import { TimeOffRequestDay as TimeOffRequestDay_ } from "backend/models/time-off-request";
import { notNullish } from "miter-utils";
import { EditPayPeriodTimesheetRow } from "../TimesheetsByPayPeriodEditor";
import { Assign } from "utility-types";
import { createObjectMap } from "dashboard/utils";

export type TimeOffRequestDay = FrontendModel<TimeOffRequestDay_>;

export type UnfurledTimeOffRequest = {
  date: string;
  team_member: string;
  job?: string;
  activity?: string;
  earningTypeAlias?: string;
  hours: number;
  time_off_request_id?: string;
  time_off_policy_id: string;
  status: TimeOffRequest["status"];
};

export type UnfurledTimeOffRequestForUpdate = Assign<UnfurledTimeOffRequest, { time_off_request_id: string }>;

export type PrepTimeOffRequestChangeOutput = {
  creates: Partial<TimeOffRequest>[];
  updates: {
    _id: string;
    update: {
      start_date: string;
      end_date: string;
      total_hours: number;
      schedule: TimeOffRequestDay[];
    };
  }[];
  idsToArchive: string[];
};

type TimeOffRequestReducedData = {
  _id: string;
  date: string;
  tmId: string;
  jobId?: string;
  activityId?: string;
  earningTypeAlias?: string;
  hours: number;
  status: TimeOffRequest["status"];
};

export const prepTimeOffRequestChanges = (
  tableData: EditPayPeriodTimesheetRow[],
  timeOffRequests: AggregatedTimeOffRequest[],
  activeCompany: Company,
  periodStart: string,
  periodEnd: string
): PrepTimeOffRequestChangeOutput => {
  const creates: UnfurledTimeOffRequest[] = [];
  const updates: UnfurledTimeOffRequestForUpdate[] = [];
  const idsToArchiveSet: Set<string> = new Set();
  const idsToRemoveFromPayPeriodSet: Set<string> = new Set();

  const payPeriodTimeOffRequests = timeOffRequests.filter((ts) =>
    ["unapproved", "approved"].includes(ts.status)
  );

  const matchedTimeOffRequestsSet: Set<AggregatedTimeOffRequest> = new Set();

  // Reduce the table data to a map of unique combinations of team member, job, activity, date, and earning type
  const reducedDataMap = buildReducedTableData(tableData);
  const reducedData = Array.from(reducedDataMap.values());

  // For each row in the reduced data, find the matching time off request(s)
  // Determine if time off requests should be updated, created, or archived
  for (const row of reducedData) {
    const { date: rowDate, jobId, activityId, hours, tmId, earningTypeAlias, status } = row;

    const matchingTimeOffRequestsForRow: AggregatedTimeOffRequest[] = [];
    payPeriodTimeOffRequests.forEach((tor) => {
      const torEarningTypeAlias = getEarningTypeAliasForTor(tor);

      // This time off request will apply to this day if team member and the policy are the same, regardless of date, job, or activity because one time
      // off request can have multiple dates, jobs, and activities.
      const hasEmployee = tor.employee._id === tmId;
      const hasEarningAlias = torEarningTypeAlias === earningTypeAlias;
      const hasSameStatus = tor.status === status;

      if (hasEmployee && hasEarningAlias && hasSameStatus) {
        matchingTimeOffRequestsForRow.push(tor);
        matchedTimeOffRequestsSet.add(tor);
      }
    });

    const earningTypeParams = getEarningTypeParamsFromAlias(earningTypeAlias || "");

    const timeOffPolicyId = earningTypeParams.time_off_policy_id;
    if (!timeOffPolicyId) {
      throw new Error(`No time off policy id found for earning type alias ${earningTypeAlias}`);
    }

    // If there is only one matching time off request, we can update it with the updated hours from this pay period
    if (matchingTimeOffRequestsForRow.length === 1) {
      const tor = matchingTimeOffRequestsForRow[0];

      if (tor && timeOffPolicyId) {
        updates.push({
          job: jobId,
          activity: activityId,
          team_member: tmId,
          hours,
          date: rowDate,
          time_off_request_id: tor._id,
          time_off_policy_id: timeOffPolicyId,
          status: row.status || "unapproved",
        });
      }
    } else {
      // We're only here if there are no matching timesheets or if we've archived multiple timesheets
      // ...so we can create a new one
      creates.push({
        ...earningTypeParams,
        time_off_policy_id: timeOffPolicyId,
        team_member: tmId,
        job: jobId,
        activity: activityId,
        hours,
        date: rowDate,
        status: row.status || "unapproved",
      });

      // If we have more than one matching time off request, we will create a new one and archive the old ones because this means
      // we need to merge multiple time off requests into one
      if (matchingTimeOffRequestsForRow.length > 1) {
        // Iterate through the matching time off requests for the row
        matchingTimeOffRequestsForRow.forEach((tor) => {
          // If the request is completely within the pay period, archive it
          if (tor.start_date >= rowDate && tor.end_date <= rowDate) {
            idsToArchiveSet.add(tor._id);

            // Otherwise, add it to the set of time off requests who's time we should remove from the pay period
          } else {
            idsToRemoveFromPayPeriodSet.add(tor._id);
          }
        });

        matchingTimeOffRequestsForRow.forEach((tor) => idsToArchiveSet.add(tor._id));
      }
    }
  }

  // Build up the time of requests that have to be create by grouping them by team member + time off policy and then converting the dates into a days array
  const timeOffRequestsForCreationMap: Record<string, TimeOffRequestDay[]> = creates.reduce((acc, row) => {
    const key = `${row.team_member}-${row.time_off_policy_id}`;
    const item = acc[key] || [];

    item.push({
      date: row.date,
      hours: row.hours,
      job_id: row.job,
      activity_id: row.activity,
      status: row.status,
    });

    acc[key] = item;
    return acc;
  }, {} as Record<string, TimeOffRequestDay[]>);

  // Convert the time off requests for creation into an array of time off requests params we can use to then create the time off requests
  const timeOffRequestsForCreation: Partial<TimeOffRequest>[] = Object.entries(timeOffRequestsForCreationMap)
    .map(([key, days]) => {
      const finalDays = days.filter((day) => day.hours).sort((a, b) => (a.date < b.date ? -1 : 1));

      const [teamMemberId, timeOffPolicyId] = key.split("-");
      if (!teamMemberId || !timeOffPolicyId) return;

      const firstDay = finalDays[0];
      const lastDay = finalDays[finalDays.length - 1];

      if (!firstDay || !lastDay) return;

      const totalHours = finalDays.reduce((acc, day) => acc + day.hours, 0);
      const status = finalDays[0]?.status || "unapproved";

      return {
        company: activeCompany._id,
        employee: teamMemberId,
        time_off_policy: timeOffPolicyId,
        start_date: firstDay.date,
        end_date: lastDay.date,
        total_hours: totalHours,
        status,
        schedule: days,
        archived: false,
      };
    })
    .filter(notNullish);

  /**
   * Get the time off updates and group by time off request _id and replace the current dates in their schedules with the updated dates
   * - If the time off request is in multiple pay periods, we want to make sure to ignore the dates that are not in the pay period
   */
  const timeOffRequestsForUpdateMap: Record<string, TimeOffRequestDay[]> = updates.reduce((acc, row) => {
    const key = row.time_off_request_id;
    const item = acc[key] || [];

    item.push({
      date: row.date,
      hours: row.hours,
      job_id: row.job,
      activity_id: row.activity,
    });

    acc[key] = item;
    return acc;
  }, {} as Record<string, TimeOffRequestDay[]>);

  const lookupTimeOffRequest = timeOffRequests.reduce((acc, tor) => {
    acc[tor._id] = tor;
    return acc;
  }, {} as Record<string, AggregatedTimeOffRequest>);

  // Convert the time off requests for update into an array of time off requests params we can use to then update the time off requests
  const timeOffRequestsForUpdate = Object.entries(timeOffRequestsForUpdateMap)
    .map(([key, days]) => {
      const tor = lookupTimeOffRequest[key];
      if (!tor) return;

      // get the days before the pay period
      const daysBeforePayPeriod = tor.schedule.filter((sDay) => sDay.date < periodStart);

      // get the days after the pay period
      const daysAfterPayPeriod = tor.schedule.filter((sDay) => sDay.date > periodEnd);

      // get the days in the pay period that have hours greater than 0 and sort them by date
      const updatedDaysInPayPeriod = days
        .filter((day) => day?.hours)
        .filter(notNullish)
        .sort((a, b) => (a.date < b.date ? -1 : 1));

      // combine the days before, during, and after the pay period
      const newSchedule = [...daysBeforePayPeriod, ...updatedDaysInPayPeriod, ...daysAfterPayPeriod];

      const firstDay = newSchedule[0];
      const lastDay = newSchedule[newSchedule.length - 1];
      const totalHours = newSchedule.reduce((acc, day) => acc + day.hours, 0);

      if (!firstDay || !lastDay) return;

      return {
        _id: tor._id,
        update: {
          start_date: firstDay.date,
          end_date: lastDay.date,
          total_hours: totalHours,
          schedule: newSchedule,
        },
      };
    })
    .filter(notNullish);

  /**
   * For any time off requests that have no hours in this pay period BUT have hours in other pay periods, we want to remove the days in this pay period
   * from the time off request and update the start and end dates and total hours.
   */
  const timeOffRequestsToRemoveFromPayPeriod = Array.from(idsToRemoveFromPayPeriodSet).map((id) => {
    const tor = lookupTimeOffRequest[id];
    if (!tor) return;

    const daysBeforePayPeriod = tor.schedule.filter((day) => day.date < tor.start_date);
    const daysAfterPayPeriod = tor.schedule.filter((day) => day.date > tor.end_date);

    const newSchedule = [...daysBeforePayPeriod, ...daysAfterPayPeriod];

    const firstDay = newSchedule[0];
    const lastDay = newSchedule[newSchedule.length - 1];
    const totalHours = newSchedule.reduce((acc, day) => acc + day.hours, 0);

    if (!firstDay || !lastDay) return;

    return {
      _id: tor._id,
      update: {
        start_date: firstDay.date,
        end_date: lastDay.date,
        total_hours: totalHours,
        schedule: newSchedule,
      },
    };
  });

  // Add the time off requests to update to the time off requests for update array
  timeOffRequestsForUpdate.push(...timeOffRequestsToRemoveFromPayPeriod.filter(notNullish));

  // Get the time off requests for update that have 0 total hours and archive them
  timeOffRequestsForUpdate.forEach((tor) => {
    if (tor && tor.update.total_hours === 0) {
      idsToArchiveSet.add(tor._id);
    }
  });

  const matchedTimeOffRequestsLookup = createObjectMap(
    Array.from(matchedTimeOffRequestsSet),
    (tor) => tor._id
  );

  const unmatchedTimeOffRequests = payPeriodTimeOffRequests.filter(
    (tor) => !matchedTimeOffRequestsLookup[tor._id]
  );

  // Find time off requests that don't match any of the reduced data rows
  unmatchedTimeOffRequests.forEach((ts) => {
    idsToArchiveSet.add(ts._id);
  });

  return {
    creates: timeOffRequestsForCreation,
    updates: timeOffRequestsForUpdate,
    idsToArchive: Array.from(idsToArchiveSet),
  };
};

/**
 * Turns the table rows into a map of unique line items of team member, job, activity, date, and earning type. This
 * is an intermediate step in the process of determining which time off requests should be created, updated, or archived based
 * on the table data.
 *
 * @param tableData
 * @returns Map<string, TimeOffRequestReducedData>
 */
const buildReducedTableData = (
  tableData: EditPayPeriodTimesheetRow[]
): Map<string, TimeOffRequestReducedData> => {
  const timeOffRequestRows = tableData.filter((row) => row.source === "time_off_request");
  const reducedDataMap: Map<string, TimeOffRequestReducedData> = timeOffRequestRows.reduce((acc, row) => {
    if (!["approved", "unapproved"].includes(row.status)) return acc;

    const tmId = row.team_member_id;
    const jobId = row.job_id || undefined;
    const activityId = row.activity_id || undefined;
    const earningTypeAlias = row.earning_type_alias;
    const dayColStrings = Object.keys(row).filter((prop) => prop.includes(DAY_COL_PREFIX));

    dayColStrings.forEach((dayColString) => {
      const isoDay = dayColString.replace(DAY_COL_PREFIX, "");
      const dayHours = Number(row[dayColString] || 0) as number;
      const key = `${row.team_member_id}${jobId}${activityId}${isoDay}${earningTypeAlias}`;
      const item = acc.get(key) || {
        _id: key,
        date: isoDay,
        status: row.status,
        tmId,
        jobId,
        activityId,
        hours: 0,
        earningTypeAlias,
      };
      item.hours += dayHours;
      acc.set(key, item);
    });

    return acc;
  }, new Map());

  return reducedDataMap;
};
