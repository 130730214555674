import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { Formblock, ActionModal } from "ui";
import ObjectID from "bson-objectid";
import { ActivityBenefitContributionTableEntry } from "./ActivityContributions";
import { ActivityModalTabProps } from "./TabbedActivityModal";
import AppContext from "dashboard/contexts/app-context";

type Props = {
  hide: () => void;
  contributionToUpdate?: ActivityBenefitContributionTableEntry;
} & ActivityModalTabProps;

export const ActivityBenefitContributionModal: React.FC<Props> = ({
  hide,
  contributionToUpdate,
  selectedActivity,
  handleFieldsChange,
}) => {
  const { register, errors, control, handleSubmit } = useForm();

  const otherAlbcs = selectedActivity.benefit_contributions?.filter(
    (abc) => abc._id !== contributionToUpdate?._id
  );
  const activityHasDynamicAlbc = otherAlbcs?.some((abc) => abc.dynamic_offset);

  const defaultTypeOption = contributionToUpdate?.dynamic_offset ? "dynamic" : "amount_per_hour";
  const [selectedContributionType, setSelectedContributionType] = useState(
    contributionTypeOptions.find((o) => o.value === defaultTypeOption)
  );
  const [selectedCategory, setSelectedCategory] = useState(
    categoryOptions.find((o) => o.value === contributionToUpdate?.category)
  );

  const { integrations } = useContext(AppContext);

  const hasContractorsPlan = integrations.find(
    (i) => i.key === "contractors_plan" && i.connection && !i.connection.pending_setup
  );

  const [isContractorsPlan, setIsContractorsPlan] = useState(
    contributionToUpdate?.integrations?.contractors_plan?.is_cp_benefit
  );

  const [updating, setUpdating] = useState(false);

  const save = async (data) => {
    setUpdating(true);
    const cleanedContribution = {
      ...data,
      amount: Number(data.amount),
      category: data.category.value,
      _id: contributionToUpdate?._id || ObjectID().toString(),
      dynamic_offset: selectedContributionType?.value === "dynamic" ? true : false,
      integrations: {
        ...contributionToUpdate?.integrations,
        contractors_plan: {
          is_cp_benefit: hasContractorsPlan && selectedCategory?.value === "pension" && !!isContractorsPlan,
        },
      },
    };
    let newContributions = [...(selectedActivity.benefit_contributions || [])];
    if (contributionToUpdate) {
      newContributions = newContributions.map((c) => {
        if (c._id === contributionToUpdate?._id) {
          return cleanedContribution;
        } else {
          return c;
        }
      });
    } else {
      newContributions.push(cleanedContribution);
    }
    handleFieldsChange({ benefit_contributions: newContributions });
    hide();
    setUpdating(false);
  };

  return (
    <>
      <ActionModal
        submitText={contributionToUpdate ? "Update" : "Create"}
        onHide={hide}
        cancelText="Cancel"
        onCancel={hide}
        onSubmit={handleSubmit(save)}
        loading={updating}
        headerText={`${contributionToUpdate ? "Update" : "Create"} contribution`}
        showSubmit={true}
        showCancel={true}
      >
        <div className="vertical-spacer"></div>
        <Formblock
          label="Label*"
          className="modal"
          type="text"
          register={register(vals.required)}
          name="label"
          errors={errors}
          defaultValue={contributionToUpdate?.label}
          editing={true}
        />
        <Formblock
          label="Category*"
          className="modal"
          type="select"
          options={categoryOptions}
          requiredSelect={true}
          defaultValue={contributionToUpdate?.category}
          name="category"
          control={control}
          errors={errors}
          value={selectedCategory}
          onChange={setSelectedCategory}
          editing={true}
          disabled={!!contributionToUpdate}
        />
        <Formblock
          label="Contribution rate type*"
          className="modal"
          type="select"
          options={contributionTypeOptions}
          name="contribution_type"
          control={control}
          errors={errors}
          value={selectedContributionType}
          onChange={setSelectedContributionType}
          labelInfo="Dynamic contributions offset the entirety of the prevailing wage fringe, minus other employer benefit contributions, ensuring no fringes are paid in cash."
          disabled={activityHasDynamicAlbc}
          editing={true}
        />
        {selectedContributionType?.value === "amount_per_hour" ? (
          <>
            <Formblock
              label="Amount per hour*"
              className="modal benefit"
              type="unit"
              unit="$"
              register={register(vals.dollar)}
              name="amount"
              defaultValue={contributionToUpdate?.amount}
              errors={errors}
              editing={true}
            />
            <Formblock
              name="non_bonafide"
              type="checkbox"
              text="This benefit should not offset prevailing wage fringe rates."
              errors={errors}
              defaultValue={contributionToUpdate?.non_bonafide}
              editing={true}
              register={register}
              className="modal"
            />
          </>
        ) : (
          <>
            <div className="yellow-text-container">
              Miter will ensure the contribution amount offsets the entirety of the prevailing wage fringe
              rate, but not more.
            </div>
          </>
        )}
        {hasContractorsPlan && selectedCategory?.value === "pension" && (
          <Formblock
            name="is_contractors_plan"
            style={{ marginTop: 5 }}
            type="checkbox"
            text="Report contributions to The Contractors' Plan."
            errors={errors}
            checked={isContractorsPlan}
            onChange={(e) => setIsContractorsPlan(e.target.checked)}
            editing={true}
            register={register}
            className="modal"
          />
        )}
        <div className="vertical-spacer"></div>
      </ActionModal>
    </>
  );
};

const categoryOptions = [
  { label: "Health & Welfare", value: "health_and_welfare" },
  { label: "Pension", value: "pension" },
  { label: "Training fees", value: "training" },
  { label: "Vacation/holiday", value: "vacation_holiday" },
  { label: "Travel/subsistence", value: "travel_subsistence" },
  { label: "Supplemental unemployment benefit", value: "sub" },
  { label: "Other", value: "other" },
];

const contributionTypeOptions = [
  { label: "$/hour", value: "amount_per_hour" },
  { label: "Dynamic offset", value: "dynamic" },
];
