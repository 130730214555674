import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { MiterAPI, File } from "../../../dashboard/src/miter";
import Notifier from "../../../dashboard/src/utils/notifier";
import fileIcon from "../../../dashboard/src/assets/file.png";
import { FilePickerFile } from "../form/FilePicker";

type Props = {
  file_ids?: string[];
  files?: File[];
};

const FileIconList: React.FC<Props> = ({ file_ids, files }) => {
  const [loading, setLoading] = useState(false);

  const [loadedFiles, setLoadedFiles] = useState<File[] | FilePickerFile[] | undefined>();
  const [additionalFiles, setAdditionalFiles] = useState<number | undefined>();

  const getFileData = async () => {
    setLoading(true);
    if (files) {
      setLoadedFiles(files);
    } else if (file_ids && file_ids.length > 0) {
      try {
        const filter = [{ field: "_id", comparisonType: "in" as const, value: file_ids }];
        const filesRaw = await MiterAPI.files.list(filter);
        if (filesRaw.error) throw new Error("Error fetching file data.");
        const files = filesRaw as File[] | FilePickerFile[];
        if (files.length > 3) {
          setAdditionalFiles(files.length - 3);
          setLoadedFiles(files.slice(0, 3));
        } else {
          setLoadedFiles(files);
        }
      } catch (e) {
        Notifier.error("");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getFileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex">
      {!loading &&
        loadedFiles?.map(() => {
          return (
            <div className="flex" style={{ marginRight: 3 }}>
              <img src={fileIcon} style={{ height: 15, width: 15 }} />
              {/* <ImageWithTooltip src={fileIcon} tooltip={""} height={15} width={15} /> */}
            </div>
          );
        })}
      {additionalFiles && <span style={{ marginLeft: 2 }}>{`+${additionalFiles.toString()}`}</span>}
    </div>
  );
};

export default FileIconList;
