import { buildTenureString } from "miter-utils";
import { Plus } from "phosphor-react";
import React, { FC, useMemo, useState } from "react";
import { ActionModal, Formblock, Label, Notifier, TableV2 } from "ui";
import { CompanySettings } from "backend/models/company";

type Props = {
  onChange: (newFields: Partial<CompanySettings["team"]>) => void;
  teamSettings: CompanySettings["team"];
  loading: boolean;
};

type TenureAlert = NonNullable<CompanySettings["team"]["tenure_alerts"]>[number] & {
  _id: string;
  tenure?: string;
};

const TenureAlerts: FC<Props> = ({ teamSettings, onChange, loading }) => {
  const [mode, setMode] = useState<"add" | "edit">();
  const [curAlert, setCurrentAlert] = useState<TenureAlert>();

  const tenureAlerts: TenureAlert[] = useMemo(() => {
    const arr =
      teamSettings.tenure_alerts?.map((alert, i) => ({
        ...alert,
        _id: i + "",
        tenure: buildTenureString(alert),
      })) || [];
    arr.sort((a, b) => calcTotalDays(a) - calcTotalDays(b));
    return arr;
  }, [teamSettings.tenure_alerts]);

  const handleRowClick = (alert: TenureAlert) => {
    setCurrentAlert(alert);
    setMode("edit");
  };

  const handleAdd = () => {
    setCurrentAlert({ _id: tenureAlerts.length + "", label: "" });
    setMode("add");
  };

  const handleHideModal = () => {
    setCurrentAlert(undefined);
  };

  const handleSave = async () => {
    const newTenureAlerts = [...tenureAlerts];

    if (!curAlert?.label || curAlert.label === "") {
      Notifier.error("Please enter a label");
      return;
    }

    if (!curAlert?.years && !curAlert?.months && !curAlert?.days) {
      Notifier.error("Please enter a tenure");
      return;
    }

    const hasAlertWithSameTenure = newTenureAlerts.some(
      (a) => a.years === curAlert?.years && a.months === curAlert?.months && a.days === curAlert?.days
    );
    if (hasAlertWithSameTenure) {
      Notifier.error("You already have an alert with the same tenure");
      return;
    }

    if (mode === "add") {
      newTenureAlerts.push(curAlert!);
    } else {
      const index = newTenureAlerts.findIndex((alert) => alert._id === curAlert?._id);
      newTenureAlerts[index] = curAlert!;
    }

    const newTeamSettings = { ...teamSettings, tenure_alerts: cleanTenureAlertsForSave(newTenureAlerts) };
    onChange(newTeamSettings);
    handleHideModal();
  };

  const handleDelete = async () => {
    const newTenureAlerts = tenureAlerts.filter((alert) => alert._id !== curAlert?._id);
    const newTeamSettings = { ...teamSettings, tenure_alerts: cleanTenureAlertsForSave(newTenureAlerts) };
    onChange(newTeamSettings);
    handleHideModal();
  };

  const renderModal = () => {
    if (!curAlert) return;

    return (
      <ActionModal
        headerText={`${mode === "edit" ? "Edit" : "Add"} tenure alert`}
        showSubmit={true}
        showDelete={mode === "edit"}
        submitText={"Save"}
        deleteText={"Delete"}
        onHide={handleHideModal}
        onDelete={handleDelete}
        onSubmit={handleSave}
        loading={loading}
      >
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            label="Label"
            type="text"
            className="modal"
            onChange={(e) => setCurrentAlert({ ...curAlert, label: e.target.value })}
            name="label"
            value={curAlert.label}
            editing={true}
            placeholder="Enter label"
          />
          <Label
            label="Tenure"
            style={{ marginBottom: 10 }}
            labelInfo="After how long should this alert be triggered for team members?"
          />
          <Formblock
            type="append"
            className="modal append-v2"
            onChange={(e) => setCurrentAlert({ ...curAlert, years: e.target.value })}
            name="label"
            value={curAlert.years}
            min={0}
            max={100}
            editing={true}
            placeholder="0"
            appendText="years"
          />
          <Formblock
            type="append"
            className="modal append-v2"
            onChange={(e) => setCurrentAlert({ ...curAlert, months: e.target.value })}
            name="label"
            value={curAlert.months}
            min={0}
            max={12}
            editing={true}
            placeholder="0"
            appendText="months"
          />
          <Formblock
            type="append"
            className="modal append-v2"
            onChange={(e) => setCurrentAlert({ ...curAlert, days: e.target.value })}
            name="label"
            value={curAlert.days}
            min={0}
            max={31}
            editing={true}
            placeholder="0"
            appendText="days"
          />
        </div>
      </ActionModal>
    );
  };

  return (
    <>
      <TableV2
        id={"team-settings-tenure-alerts-table"}
        resource="tenure alerts"
        data={tenureAlerts}
        columns={[
          { headerName: "Label", field: "label", dataType: "string" },
          { headerName: "Tenure", field: "tenure" },
        ]}
        hideFooter={true}
        staticActions={[
          {
            label: "Add tenure alert",
            className: "button-2 no-margin",
            action: handleAdd,
            important: true,
            icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
          },
        ]}
        onClick={handleRowClick}
        containerStyle={{ paddingBottom: 0, paddingTop: 0 }}
      />
      {renderModal()}
    </>
  );
};

export default TenureAlerts;

const cleanTenureAlertsForSave = (alerts: TenureAlert[]) => {
  return alerts.map((alert) => {
    const { _id, tenure, ...rest } = alert;
    return {
      label: rest.label,
      years: Number(rest.years) || undefined,
      months: Number(rest.months) || undefined,
      days: Number(rest.days) || undefined,
    };
  });
};

const calcTotalDays = (a: TenureAlert) => ((a.years || 0) + (a.months || 0) / 12) * 365 + (a.days || 0);
