import { CertificationType } from "dashboard/types/certification-types";
import { CollectFormFields } from "miter-components";
import { FormField } from "miter-utils";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import styles from "./CertificationTypes.module.css";

type Props = {
  certificationType: CertificationType;
  name: string;
};

export const PreviewCertificationCollection: FC<Props> = ({ certificationType, name }) => {
  const { setCanNext, handleComplete, screens, curIndex } = useWizard();

  const form = useForm();

  setCanNext(true);

  const fields = [
    ...certificationType.custom_fields,
    ...certificationType.file_uploads,
    ...(certificationType.expires
      ? [
          {
            _id: "expiration_date",
            type: "date",
            name: "Certification expiry date",
            label: "Expiration Date",
            validations: {
              required: true,
            },
          },
        ]
      : []),
  ];

  const onNext = async () => {
    if (curIndex === screens.length - 1) {
      handleComplete();
    }
  };

  return (
    <div>
      <WizardScreen onNext={onNext} name={name}>
        <div className={styles["form-builder"]}>
          <div style={{ fontSize: 16, marginBottom: 20 }} className={styles["preview-header"]}>
            This is a preview of the certification collection form.
          </div>
          {certificationType.description ? (
            <div style={{ marginBottom: 20 }}>{certificationType.description}</div>
          ) : null}
          <FormProvider {...form}>
            <CollectFormFields
              formFields={fields as FormField[]}
              formAnswers={[]}
              readonly={false}
              disabled={false}
            />
          </FormProvider>
        </div>
      </WizardScreen>
    </div>
  );
};
