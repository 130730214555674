import React from "react";
import ActivitiesTable from "dashboard/components/tables/ActivitiesTable";
import { Helmet } from "react-helmet";
import { Button } from "ui";
import { useNavigate } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

export const ActivitiesPage: React.FC = () => {
  const navigate = useNavigate();
  const { can } = useMiterAbilities();

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Activities | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="page-content-header flex">
          <h1 className="view-title title-with-toggler">Activities</h1>
          <div className="flex-1"></div>
          {can("lists:activities:manage") && (
            <Button className="button-1" text="Settings" onClick={() => navigate("/activities/settings")} />
          )}
        </div>
        <ActivitiesTable />
      </div>
    </div>
  );
};
