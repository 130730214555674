import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Formblock, usdString } from "ui";
import * as vals from "dashboard/utils/validators";
import { DateTime } from "luxon";
import { ActivityModalTabProps } from "./TabbedActivityModal";
import { useApprenticeConfirmation } from "dashboard/utils/qualificationUtils";
import { license_status } from "dashboard/pages/team-members/forms/options";
import { Option } from "ui/form/Input";
import { activityPayRateTypeLookup } from "./activityModalUtils";
import EditIcon from "dashboard/assets/edit.png";
import { ActivityPayTypeSelector } from "./ActivityPayTypeSelector";
import { Notifier } from "dashboard/utils";
import { ActivityPayRateType } from "backend/models/activity";
import {
  useOtRuleOptions,
  usePrgOptions,
  useLookupPrg,
  useClassificationOptions,
  useActiveCompany,
  useRateDifferentialOptions,
} from "dashboard/hooks/atom-hooks";
import { Activity } from "dashboard/miter";

const MANUAL_OT_VALUE = "manual";

export const ActivityPayRate: React.FC<ActivityModalTabProps> = ({
  selectedActivity,
  handleFieldsChange,
  readonly,
}) => {
  const activeCompany = useActiveCompany();
  const lookupPrg = useLookupPrg();
  const prgOptions = usePrgOptions();
  const rateDifferentialOptions = useRateDifferentialOptions();
  const otRuleOptions = useOtRuleOptions().concat([
    { label: "(calculate manually)", value: MANUAL_OT_VALUE },
  ]);

  const companyState = activeCompany?.check_company.address.state;

  const [isChangingPayRateType, setIsChangingPayRateType] = useState(false);

  const defaultIncreaseDate = selectedActivity?.date_of_rate_increase
    ? DateTime.fromISO(selectedActivity?.date_of_rate_increase)
    : undefined;
  const [increaseDate, setIncreaseDate] = useState<DateTime | undefined>(defaultIncreaseDate);

  const { register, control, errors, setValue, watch } = useForm();
  const formData = watch();

  const { handleQualificationChange, ApprenticeConfirmationModal } = useApprenticeConfirmation(
    "qualification_level",
    formData,
    setValue,
    (newQualification) => handleFieldsChange({ qualification_level: newQualification || null })
  );

  const selectedPrg = lookupPrg(selectedActivity.pay_rate_group);

  const classificationOptions: Option<string>[] = useClassificationOptions({
    prgId: selectedPrg?._id.toString(),
    opts: {
      includeCustomOption: { label: "Team member's default classification", value: "" },
    },
  });

  // If `union_rate_id` is not specified on the selectedActivity, fallback to the null option (TM's default classification)
  const selectedClassificationOption = classificationOptions.find(
    (c) => c.value === (selectedActivity.union_rate_id || "")
  );

  const handleCustomOtChange = (newCustomOtOption: Option<string> | null) => {
    const nonStandardOvertime = !!newCustomOtOption?.value; // this include the manual OT value
    const overtimeRuleId =
      !newCustomOtOption?.value || newCustomOtOption?.value === MANUAL_OT_VALUE
        ? null
        : newCustomOtOption.value;
    handleFieldsChange({
      non_standard_overtime: nonStandardOvertime,
      overtime_rule_id: overtimeRuleId,
    });
  };

  const handleIncreaseDateChange = (newDt?: DateTime) => {
    if (increaseDate && newDt?.equals(increaseDate)) return;

    setIncreaseDate(newDt);
    handleFieldsChange({ date_of_rate_increase: newDt?.toISODate() || null });
  };

  const handlePayRateChange = (e) => {
    if (typeof Number(e) === "number" && Number(e) >= 0) {
      handleFieldsChange({ pay_rate: Number(e) });
    } else {
      Notifier.error("Input must equal a positive number.");
    }
  };

  const handleFringeRateChange = (event) => {
    const e = event.target.value;
    if (typeof Number(e) === "number" && Number(e) >= 0) {
      handleFieldsChange({ fringe_rate: Number(e) });
    } else {
      Notifier.error("Input must equal a positive number.");
    }
  };

  const renderPayRateInput = () => {
    return (
      <Formblock
        label={"Hourly base pay rate"}
        labelInfo="The hourly pay rate for this activity."
        type="unit"
        unit="$"
        register={register(vals.isPositiveNumber)}
        className="tabbed-modal"
        onChange={(e) => handlePayRateChange(e.target.value)}
        name="pay_rate"
        defaultValue={selectedActivity?.pay_rate ?? undefined}
        defaultString={selectedActivity?.pay_rate ? usdString(selectedActivity?.pay_rate) : "-"}
        errors={errors}
        editing={true}
        disabled={readonly}
      />
    );
  };

  const renderPrevailingWageOptions = () => {
    return (
      <>
        {renderPayRateInput()}
        <Formblock
          label="Hourly fringe rate"
          underlineTooltip={true}
          labelInfo="Hourly fringe amount, including the training fee, owed for this classification. Miter will deduct employer benefit contributions from this amount during payroll processing."
          type="unit"
          unit="$"
          register={register(vals.isNumberButNotRequired)}
          name="fringe_rate"
          defaultValue={selectedActivity?.fringe_rate}
          className="tabbed-modal"
          defaultString={selectedActivity?.fringe_rate ? usdString(selectedActivity?.fringe_rate) : "-"}
          errors={errors}
          editing={true}
          onChange={handleFringeRateChange}
          disabled={readonly}
        />
        <Formblock
          label="Date of rate increase"
          labelInfo="Miter will prompt you to update the rate as the expiration date nears."
          type="datetime"
          control={control}
          name="date_of_rate_increase"
          underlineTooltip={true}
          errors={errors}
          defaultValue={increaseDate}
          className="tabbed-modal"
          onChange={handleIncreaseDateChange}
          editing={true}
          disabled={readonly}
          dateOnly
        />
        <Formblock
          label="Qualification level*"
          labelInfo="Is this activity specific to journeymen or apprentices?"
          type="select"
          name="qualification_level"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          options={license_status}
          defaultValue={selectedActivity?.qualification_level || "journeyman"}
          requiredSelect
          errors={errors}
          editing={true}
          onChange={handleQualificationChange}
          disabled={readonly}
        />
        <Formblock
          label="Classification"
          labelInfo="The work classification used in Certified Payroll Reports"
          type="text"
          underlineTooltip={true}
          register={register}
          className="tabbed-modal"
          name="classification"
          defaultValue={selectedActivity?.classification}
          onChange={(e) => handleClassificationChange(e.target.value)}
          errors={errors}
          editing={true}
          disabled={readonly}
        />
        <Formblock
          label="Custom overtime rule"
          labelInfo={
            `How Miter should calculate overtime for team members when they do this activity. ` +
            `If "calculate manually" is selected, Miter will not auto-calculate pay for any payrolls where the relevant employee worked this activity.`
          }
          type="select"
          name="overtime_rule_id"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          onChange={handleCustomOtChange}
          options={otRuleOptions}
          defaultValue={
            selectedActivity?.overtime_rule_id ||
            (selectedActivity?.non_standard_overtime ? MANUAL_OT_VALUE : undefined)
          }
          errors={errors}
          editing={true}
          isClearable
          disabled={readonly}
        />
        <Formblock
          label="Always pay"
          labelInfo="If true, relevant timesheets will always use this activity's pay rate when auto-calculating earnings, even if it's lower than the team member's default pay rate."
          type="select"
          name="pay_rate_always_on"
          underlineTooltip={true}
          className="tabbed-modal"
          control={control}
          options={booleanOptions}
          defaultValue={selectedActivity?.pay_rate_always_on ? "true" : "false"}
          onChange={(option) => handleFieldsChange({ pay_rate_always_on: option.value === "true" })}
          errors={errors}
          editing={true}
          disabled={readonly}
        />
      </>
    );
  };

  const handlePayTypeSubmit = (newType: ActivityPayRateType) => {
    const data: Partial<Activity> = {
      pay_rate_type: newType,
      pay_rate: null,
      fringe_rate: null,
      pay_rate_always_on: newType === "custom",
      prevailing_wage: newType === "pw",
      always_pay_tm_default_rate: false,
      pay_rate_group: null,
      union_rate_id: null,
      overtime_rule_id: null,
      non_standard_overtime: false,
      classification: null,
      qualification_level: newType === "pw" ? "journeyman" : null,
      rate_differential_id: null,
    };
    handleFieldsChange(data);

    control.setValue("rate_differential_id", null);

    setIsChangingPayRateType(false);
  };

  const handleClassificationChange = (newClassification) => {
    handleFieldsChange({
      classification: newClassification,
    });
  };

  return (
    <div>
      <div className="vertical-spacer-small"></div>
      {isChangingPayRateType && (
        <ActivityPayTypeSelector
          defaultPayType={selectedActivity.pay_rate_type}
          hide={() => setIsChangingPayRateType(false)}
          onSubmit={handlePayTypeSubmit}
        />
      )}
      <div>
        <Formblock
          label="Pay rate type"
          underlineTooltip={true}
          className="tabbed-modal"
          type="text"
          labelInfo="How the pay rate will be determined for this activity."
          locked={true}
          disabled={readonly}
        >
          <div className="flex">
            <span>{activityPayRateTypeLookup[selectedActivity.pay_rate_type]}</span>
            {!readonly && (
              <img
                className="formblock-edit-icon"
                src={EditIcon}
                onClick={() => setIsChangingPayRateType(true)}
              />
            )}
          </div>
        </Formblock>
        {selectedActivity.pay_rate_type === "prg" && (
          <>
            <Formblock
              label="Pay rate group"
              type="select"
              name="pay_rate_group"
              underlineTooltip={true}
              className="tabbed-modal"
              control={control}
              options={prgOptions}
              defaultValue={selectedActivity?.pay_rate_group || undefined}
              errors={errors}
              editing={true}
              onChange={(e) => handleFieldsChange({ pay_rate_group: e.value, union_rate_id: null })}
              disabled={readonly}
            />
            <Formblock
              label="Classification"
              type="select"
              name="union_rate_id"
              underlineTooltip={true}
              className="tabbed-modal"
              control={control}
              options={classificationOptions}
              value={selectedClassificationOption}
              errors={errors}
              editing={true}
              onChange={(e) => handleFieldsChange({ union_rate_id: e.value })}
              disabled={readonly}
            />
            {formData.pay_rate_group?.value && selectedClassificationOption?.value === "" && (
              <>
                <div className="vertical-spacer"></div>
                <div className="yellow-text-container">{`Team members performing this activity will be paid their assigned classification within ${selectedPrg?.label}.`}</div>
              </>
            )}
          </>
        )}
        {selectedActivity.pay_rate_type !== "custom" && rateDifferentialOptions.length > 0 && (
          <Formblock
            label="Rate differential"
            labelInfo="This rate differential will apply when team members perform this activity."
            type="select"
            name="rate_differential_id"
            underlineTooltip={true}
            className="tabbed-modal"
            control={control}
            options={rateDifferentialOptions}
            defaultValue={selectedActivity?.rate_differential_id}
            requiredSelect
            errors={errors}
            isClearable={true}
            editing={true}
            onChange={(o) => handleFieldsChange({ rate_differential_id: o?.value || null })}
            disabled={readonly}
          />
        )}
        {selectedActivity.pay_rate_type === "pw" ? (
          <>
            {" "}
            {renderPrevailingWageOptions()}
            {selectedActivity.non_standard_overtime && !selectedActivity.overtime_rule_id && (
              <div className="yellow-text-container">
                <span className="bold">Note</span>: When manual overtime is selected, Miter will not calculate
                gross pay for the employee for pay periods that include this activity.
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        {selectedActivity.pay_rate_type === "custom" ? renderPayRateInput() : <></>}
      </div>
      <ApprenticeConfirmationModal companyState={companyState || ""} />
    </div>
  );
};

const booleanOptions = [
  { label: "True", value: "true" },
  { label: "False", value: "false" },
];
