import React from "react";
import { TimesheetSettings } from "./TimesheetSettings";
import InfoButton from "dashboard/components/information/information";
import { Formblock } from "ui";
import { UseFormMethods } from "react-hook-form";
import { useDepartmentOptions } from "dashboard/hooks/atom-hooks";

interface MobileTimesheetCreationSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
  form: UseFormMethods;
}

const MobileTimesheetCreationSettings: React.FC<MobileTimesheetCreationSettingsProps> = ({
  timesheetSettings,
  handleChange,
  form,
}) => {
  const departmentOptions = useDepartmentOptions();

  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Mobile timesheet creation</div>
        <InfoButton text="This setting allows you specify how team members can create timesheets in the mobile app." />
      </div>

      <div style={{ color: "rgb(51,51,51)", marginTop: 5 }}>
        <div className="vertical-spacer-small"></div>

        <div className="flex" style={{ marginBottom: 10 }}>
          Prevent team members in the following departments from creating timesheets in the mobile app (in
          non-kiosk mode).
        </div>

        <Formblock
          type="multiselect"
          name="disable_timesheet_creation_by_department"
          form={form}
          editing={true}
          className="modal wizard"
          placeholder={"Select departments..."}
          options={departmentOptions}
          onChange={(e) => {
            handleChange({
              disable_all_mobile_app_timesheet_creation: e?.map((department) => department.value) || [],
            });
          }}
          height="unset"
          defaultValue={timesheetSettings.disable_all_mobile_app_timesheet_creation}
          style={{ width: "90%" }}
        />

        <div className="flex" style={{ marginTop: 5 }}>
          <input
            style={{ marginLeft: 10 }}
            type="checkbox"
            checked={timesheetSettings.disable_team_member_manual_timesheet_creation}
            onChange={(e) => {
              handleChange({
                disable_team_member_manual_timesheet_creation: e.target.checked,
              });
            }}
          />
          <span style={{ marginLeft: 10 }}>
            Only allow supervisors, superintendents, and crew leads to manually create timesheets in the
            mobile app
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileTimesheetCreationSettings;
