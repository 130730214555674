import React, { useState } from "react";
import { Notifier, Wizard } from "ui";
import { useRefetchCertificationTypes } from "dashboard/hooks/atom-hooks";
import { CertificationType } from "dashboard/types/certification-types";
import { CertificationTypeScreen } from "./CertificationTypeScreen";
import { FormlessFormBuilderScreen } from "../forms/formless/FormlessFormBuilderScreen";
import { FormField } from "dashboard/types/form-types";
import { MiterAPI } from "dashboard/miter";
import { PreviewCertificationCollection } from "./PreviewCertificationCollection";

export type CertificationTypeWizardMode = "edit" | "preview" | "all";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  mode: CertificationTypeWizardMode;
  certificationTypeParam?: CertificationType;
};

export const CertificationTypeWizard: React.FC<Props> = ({
  onExit,
  onComplete,
  certificationTypeParam,
  mode,
}) => {
  const [certificationType, setCertificationType] = useState<CertificationType | undefined>(
    certificationTypeParam
  );
  const refetchCertificationType = useRefetchCertificationTypes();

  const title = certificationType ? "Edit certification" : "Create certification";

  const handleComplete = async () => {
    onComplete();
    refetchCertificationType();
  };

  const saveFormFields = async (formFields: FormField[], field: "custom_fields" | "file_uploads") => {
    const certificationTypeId = certificationType?._id;
    if (!certificationTypeId) {
      return Notifier.error("Certification type not found");
    }
    try {
      const res = await MiterAPI.certification_types.update(certificationTypeId, {
        [field]: formFields,
      });
      if (res.error) throw new Error(res.error);
      setCertificationType(res);
      Notifier.success("Certification successfully saved!");
    } catch (error: $TSFixMe) {
      Notifier.error("Failed to save custom fields: " + error.message);
    }
  };

  const saveCustomFields = async (formFields: FormField[]) => {
    await saveFormFields(formFields, "custom_fields");
  };

  const saveFileUploads = async (formFields: FormField[]) => {
    await saveFormFields(formFields, "file_uploads");
  };

  return (
    <Wizard onExit={onExit} onComplete={handleComplete} configurations={{ initiallyShowSidebar: true }}>
      {mode === "all" || mode === "edit" ? (
        <CertificationTypeScreen
          name={title}
          certificationType={certificationType}
          setCertificationType={setCertificationType}
        />
      ) : null}
      {mode === "all" ? (
        <FormlessFormBuilderScreen
          name="Track custom fields"
          fields={certificationType?.custom_fields || []}
          onSave={saveCustomFields}
          acceptableFieldTypes={["text"]}
          parentType="certification_type"
          description="Add custom fields to track additional information on this certification."
        />
      ) : null}
      {mode === "all" ? (
        <FormlessFormBuilderScreen
          name="Manage file uploads"
          fields={certificationType?.file_uploads || []}
          onSave={saveFileUploads}
          acceptableFieldTypes={["file"]}
          parentType="certification_type"
          description="Require file uploads as part of this certification."
          configurations={{
            validations: {
              minFields: 1,
            },
          }}
        />
      ) : null}
      {certificationType && mode === "preview" ? (
        <PreviewCertificationCollection
          certificationType={certificationType}
          name={certificationType.title}
        />
      ) : null}
    </Wizard>
  );
};
