import {
  useMessageTemplates,
  useActiveCompanyId,
  useRefetchMessageTemplates,
} from "dashboard/hooks/atom-hooks";
import { MessageTemplate } from "dashboard/types/message-template";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { Plus, Trash } from "phosphor-react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { MessageTemplateModal } from "./MessageTemplateModal";
import { Notifier } from "dashboard/utils";
import { MiterAPI } from "dashboard/miter";
const messageTemplateColumns: ColumnConfig<MessageTemplate>[] = [
  {
    field: "name",
    headerName: "Name",
    width: 250,
    dataType: "string" as const,
  },
  {
    field: "updated_at",
    headerName: "Last edited",
    dateType: "timestamp",
    dataType: "date" as const,
  },
];

export const MessageTemplatesTable: FC = () => {
  const messageTemplates = useMessageTemplates();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMessageTemplateId, setSelectedMessageTemplateId] = useState<string>();
  const refetchMessageTemplates = useRefetchMessageTemplates();
  const companyId = useActiveCompanyId();
  const [selectedRows, setSelectedRows] = useState<MessageTemplate[]>([]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMessageTemplateId(undefined);
  };

  const updateMessageTemplate = (messageTemplate: MessageTemplate) => {
    setSelectedMessageTemplateId(messageTemplate._id);
    openModal();
  };

  const deleteMessageTemplates = useCallback(async () => {
    try {
      if (!companyId) {
        throw new Error("Company ID is not set");
      }
      const res = await MiterAPI.message_templates.archive({
        company_id: companyId,
        ids: selectedRows.map((row) => row._id),
      });
      if (res.error) {
        throw res.error;
      }
      setSelectedRows([]);
      refetchMessageTemplates();
      Notifier.success("Message templates deleted!");
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to delete message templates: ${e.message}`);
    }
  }, [companyId, selectedRows, refetchMessageTemplates]);

  const staticActions = useMemo(() => {
    return [
      {
        label: "Create template",
        action: openModal,
        important: true,
        className: "button-2 table-button",
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [openModal]);

  const dynamicActions = useMemo(() => {
    return [
      {
        label: "Delete",
        action: deleteMessageTemplates,
        important: true,
        className: "button-3 table-button",
        icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [deleteMessageTemplates]);

  return (
    <div>
      <TableV2
        id="message-templates-table"
        columns={messageTemplateColumns}
        data={messageTemplates}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        hideSecondaryActions={true}
        resource="message templates"
        onClick={updateMessageTemplate}
        onSelect={setSelectedRows}
        hideFooter={true}
      />
      {isModalOpen ? (
        <MessageTemplateModal onClose={closeModal} messageTemplateId={selectedMessageTemplateId} />
      ) : null}
    </div>
  );
};
