import styles from "../TeamMemberChecklistBuilder.module.css";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { I9EmployeeOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const I9EmployeeTaskContent: React.FC<ChecklistTaskContentProps<I9EmployeeOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  return (
    <div className={styles["team-member-checklist-task-content"]}>
      Employee must complete Section 1 of Form I-9.
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
