import { OfferTemplate } from "dashboard/types/offers-types";
import { FC, useEffect, useState } from "react";
import { Wizard } from "ui";
import { CreateOfferTemplateWizardScreen } from "./CreateOfferTemplateWizardScreen";

type Props = {
  offerTemplate?: OfferTemplate;
  onExit: () => void;
  onComplete: () => void;
};

export const OfferTemplateWizard: FC<Props> = ({ offerTemplate, onExit, onComplete }) => {
  const [activeOfferTemplate, setActiveOfferTemplate] = useState<OfferTemplate | undefined>(offerTemplate);

  useEffect(() => {
    setActiveOfferTemplate(offerTemplate);
  }, [offerTemplate]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      <CreateOfferTemplateWizardScreen
        offerTemplate={activeOfferTemplate}
        name="Create template"
        setOfferTemplate={setActiveOfferTemplate}
      />
    </Wizard>
  );
};
