import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { Company } from "backend/models";
import { SettingsCard } from "ui/settings/SettingsCard";
import { ExpensesAccountingSettings } from "../accounting/AccountingSettings/ExpensesAccountingSettings";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import ExpenseReimbursementCategorySettings from "./ExpenseReimbursementCategorySettings";
import CardTransactionCategorySettings from "./CardTransactionCategorySettings";
import { SpendProduct } from "./ExpenseSettingsV2";

type ExpenseSettingUpdateParams = Company["settings"]["expense_reimbursements"];

export const ExpenseSettings: React.FC<{ product: SpendProduct }> = ({ product }) => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const expenseSetting = activeCompany?.settings?.expense_reimbursements || null;
  const form = useForm({
    defaultValues: {
      default_unapproved_from_dashboard: !!expenseSetting?.default_unapproved_from_dashboard,
    },
  });
  const { handleSubmit } = form;

  const updateSettings = async (data: ExpenseSettingUpdateParams): Promise<void> => {
    try {
      if (!activeCompanyId) throw Error(`Could not update settings for company.`);
      const response = await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.expense_reimbursements": data },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated.");
      fetchUserData();
    } catch (e) {
      console.error(`Error updating spend management settings for company: ${activeCompanyId!}`, e);
      Notifier.error(
        "There was an error updating spend management settings. Our engineers are looking into it!"
      );
    }
  };

  const updateSettingsDebounced = useDebouncedCallback(() => {
    handleSubmit(updateSettings)();
  }, 500);

  return (
    <div>
      <div>
        {product === "reimbursements" && (
          <SettingsCard title={"Approvals"}>
            <Formblock
              type="checkbox"
              name="default_unapproved_from_dashboard"
              className="align-items-center"
              form={form}
              sideBySideInput={false}
              editing={true}
              onChange={updateSettingsDebounced}
              text='Set admin-created reimbursements to "unapproved" by default'
            />
          </SettingsCard>
        )}
        <ExpensesAccountingSettings />

        {product === "cards" && <CardTransactionCategorySettings />}
        {product === "reimbursements" && <ExpenseReimbursementCategorySettings />}
      </div>
    </div>
  );
};
