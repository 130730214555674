import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";

import { Department, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import * as vals from "dashboard/utils/validators";
import Banner from "../shared/Banner";
import { useDepartmentSourceSystem } from "./departmentUtils";
import {
  useActiveCompanyId,
  useExpensePolicyOptions,
  useLedgerMappingOptions,
  useLookupDepartment,
  useRefetchActionableItems,
  useReimbursementPolicyOptions,
  useSetDepartments,
  useTeamMemberChangeRequestPolicyOptions,
  useTeamOptions,
  useTimeOffRequestPolicyOptions,
  useTimesheetPolicyOptions,
} from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { PolicyType } from "backend/services/approvals/types";
import { PolicyModal } from "../policies/PolicyModal";

type Props = {
  departmentID?: string;
  onFinish: (department: Department) => void;
  onHide: () => void;
};

const DepartmentModal: React.FC<Props> = ({ departmentID, onFinish, onHide }) => {
  const form = useForm();
  const lookupDept = useLookupDepartment();
  const setDepartments = useSetDepartments();
  const { can, cannot } = useMiterAbilities();
  const glMappingOptions = useLedgerMappingOptions();
  const tmOptions = useTeamOptions();
  const refetchActionableItems = useRefetchActionableItems();

  const timesheetPolicyOptions = useTimesheetPolicyOptions();
  const expensePolicyOptions = useExpensePolicyOptions();
  const timeOffRequestPolicyOptions = useTimeOffRequestPolicyOptions();
  const reimbursementPolicyOptions = useReimbursementPolicyOptions();
  const teamMemberChangeRequestPolicyOptions = useTeamMemberChangeRequestPolicyOptions();

  const [loading, setLoading] = useState(false);
  const [creatingPolicy, setCreatingPolicy] = useState<PolicyType>();
  const { control, register, errors, handleSubmit } = form;

  const selectedDepartment = lookupDept(departmentID);
  const sourceSystem = useDepartmentSourceSystem(selectedDepartment);
  const activeCompanyId = useActiveCompanyId();

  const createDepartment = async (data) => {
    if (selectedDepartment || !activeCompanyId || cannot("lists:departments:manage")) return;
    setLoading(true);
    try {
      const res = await MiterAPI.departments.create({
        ...data,
        company_id: activeCompanyId,
        department_head_id: data.department_head_id?.value || null,
        timesheet_policy_id: data.timesheet_policy_id?.value || null,
        time_off_request_policy_id: data.time_off_request_policy_id?.value || null,
        team_member_change_request_policy_id: data.team_member_change_request_policy_id?.value || null,
        expense_policy_id: data.expense_policy_id?.value || null,
        reimbursement_policy_id: data.reimbursement_policy_id?.value || null,
        ledger_mapping_id: data.ledger_mapping_id?.value || null,
      });
      if (res.error) throw new Error(res.error);

      Notifier.success("Department created successfully");

      setDepartments((prev) => prev.concat(res));
      onFinish(res);
      onHide();
      refetchActionableItems();
    } catch (e: $TSFixMe) {
      console.error("Error creating department:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const updateDepartment = async (data) => {
    if (!selectedDepartment || cannot("lists:departments:manage")) return;
    setLoading(true);
    try {
      const res = await MiterAPI.departments.update(selectedDepartment._id, {
        ...data,
        department_head_id: data.department_head_id?.value || null,
        timesheet_policy_id: data.timesheet_policy_id?.value || null,
        time_off_request_policy_id: data.time_off_request_policy_id?.value || null,
        team_member_change_request_policy_id: data.team_member_change_request_policy_id?.value || null,
        expense_policy_id: data.expense_policy_id?.value || null,
        reimbursement_policy_id: data.reimbursement_policy_id?.value || null,
        ledger_mapping_id: data.ledger_mapping_id?.value || null,
      });
      if (res.error) throw new Error(res.error);

      setDepartments((prev) => prev.concat(res));
      onFinish(res);
      onHide();
      refetchActionableItems();

      Notifier.success("Department updated successfully");
    } catch (e: $TSFixMe) {
      console.error("Error updating department:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    if (selectedDepartment) {
      handleSubmit(updateDepartment)();
    } else {
      handleSubmit(createDepartment)();
    }
  };

  return (
    <>
      <ActionModal
        headerText={selectedDepartment ? "Update department" : "Create department"}
        showSubmit={can("lists:departments:manage")}
        showCancel={true}
        cancelText={"Close"}
        onCancel={onHide}
        submitText={"Save"}
        onHide={onHide}
        onSubmit={submit}
        loading={loading}
      >
        {sourceSystem && (
          <Banner
            style={{ borderBottom: "1px solid #d6d6d6", marginRight: -20, marginLeft: -20 }}
            type="modal"
          >
            <span className="flex">{`Note: this department was imported from ${sourceSystem.label}.`}</span>
          </Banner>
        )}
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            type="text"
            name="name"
            label="Name*"
            control={control}
            register={register(vals.required)}
            editing={true}
            errors={errors}
            defaultValue={selectedDepartment?.name}
            className="modal"
            disabled={!!sourceSystem || cannot("lists:departments:manage")}
          />
          <Formblock
            type="text"
            name="identifier"
            label="Identifier"
            labelInfo="Optional identifier you can use to identify this department"
            control={control}
            register={register}
            editing={true}
            errors={errors}
            defaultValue={selectedDepartment?.identifier}
            className="modal"
            disabled={!!sourceSystem || cannot("lists:departments:manage")}
          />
          <Formblock
            type="select"
            name="department_head_id"
            label="Department head"
            form={form}
            editing={true}
            options={tmOptions}
            isClearable={true}
            defaultValue={selectedDepartment?.department_head_id}
            className="modal"
            disabled={cannot("lists:departments:manage")}
          />

          <Formblock
            type="select"
            name="timesheet_policy_id"
            label="Timesheet policy"
            placeholder="Select a timesheet policy"
            options={timesheetPolicyOptions}
            defaultValue={selectedDepartment?.timesheet_policy_id}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("lists:departments:manage")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("timesheet")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="time_off_request_policy_id"
            label="Time off request policy"
            placeholder="Select a time off request policy"
            options={timeOffRequestPolicyOptions}
            defaultValue={selectedDepartment?.time_off_request_policy_id}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("lists:departments:manage")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("time_off_request")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="expense_policy_id"
            label="Card transaction policy"
            placeholder="Select a card transaction policy"
            options={expensePolicyOptions}
            defaultValue={selectedDepartment?.expense_policy_id}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("lists:departments:manage")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("expense")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="reimbursement_policy_id"
            label="Reimbursement policy"
            placeholder="Select a reimbursement policy"
            options={reimbursementPolicyOptions}
            defaultValue={selectedDepartment?.reimbursement_policy_id}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("lists:departments:manage")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("expense_reimbursement")}
            isClearable={true}
          />
          <Formblock
            type="select"
            name="team_member_change_request_policy_id"
            label="Team member profile change policy"
            placeholder="Select a team member profile change policy"
            options={teamMemberChangeRequestPolicyOptions}
            defaultValue={selectedDepartment?.team_member_change_request_policy_id}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("lists:departments:manage")}
            labelButtonText="+ Add"
            labelButtonClick={() => setCreatingPolicy("team_member_change_request")}
            isClearable={true}
          />
          <Formblock
            labelStyle={{ minWidth: 150 }}
            label="GL account mapping"
            type="select"
            name="ledger_mapping_id"
            form={form}
            isClearable={true}
            options={glMappingOptions}
            defaultValue={selectedDepartment?.ledger_mapping_id}
            editing={true}
            disabled={cannot("accounting:settings")}
            className="modal"
          />
        </div>
      </ActionModal>
      {creatingPolicy && <PolicyModal type={creatingPolicy} onHide={() => setCreatingPolicy(undefined)} />}
    </>
  );
};

export default DepartmentModal;
