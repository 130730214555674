import React from "react";
import { Helmet } from "react-helmet";
import TeamMembersTable from "./TeamMembersTable";
import { CompanyDefinedAttributesBanner } from "./teamCompanyDefinedAttributes";
import { Button } from "ui";
import { useNavigate } from "react-router-dom";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TreeStructure } from "phosphor-react";

export const SEARCH_FIELDS = [
  "friendly_id",
  "full_name",
  "employment_type",
  "phone",
  "email",
  "title",
  "pay_type",
  "check_tm.onboard_status",
];

const TeamMembers: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();

  const navigate = useNavigate();

  const companyFilter = [{ field: "company", type: "string" as const, value: activeCompanyId || "none" }];

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Team | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Team Members</h1>
          <div className="flex-1"></div>
          {can("team:read") && (
            <Button className="button-1" onClick={() => navigate("/team/org-chart")}>
              <TreeStructure style={{ marginBottom: -2, marginRight: 5 }} /> Org chart
            </Button>
          )}
          {can("team:settings") && (
            <Button className="button-1" text="Settings" onClick={() => navigate("/team-members/settings")} />
          )}
        </div>
        <CompanyDefinedAttributesBanner />
        <TeamMembersTable
          defaultFilters={companyFilter}
          showToggler={true}
          showMiniTitle={false}
          showButtons={true}
          showSearch={true}
          searchFields={SEARCH_FIELDS}
        />
      </div>
    </div>
  );
};

export default TeamMembers;
