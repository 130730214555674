import React, { useState } from "react";
import Notifier from "../../../../utils/notifier";
import { MiterAPI } from "../../../../miter";
import { Button, Formblock } from "ui";
import PayrollContext from "../payrollContext";
import { ClickAwayListener } from "@material-ui/core";
import { downloadBlob } from "dashboard/utils";
import { EnhancedMiterPayment } from "../../payrollTypes";
import PaymentContext from "../PaymentModal/paymentContext";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { DateTime } from "luxon";
import { useHasAccessToMiterPaystubsAndChecks } from "dashboard/gating";

/* This modal enables user to download paper checks for an entire payroll
or a paper check for a single team member payment */

type Props = {
  payment?: EnhancedMiterPayment;
  hide: () => void;
};

const DownloadChecksModal: React.FC<Props> = ({ payment, hide }) => {
  const payrollContext = React.useContext(PayrollContext);
  const paymentContext = React.useContext(PaymentContext);
  const payroll = payment ? paymentContext.payroll : payrollContext.payroll;

  const paymentId = payment?.check_item?.id || payment?.check_cp?.id;

  const activeCompany = useActiveCompany();
  const hasAccessToMiterPayStubs = useHasAccessToMiterPaystubsAndChecks();
  const nowIso = DateTime.now().toISODate();

  const checkFormat =
    nowIso > "2024-01-31" ? "DLT159" : activeCompany?.settings.payroll.paper_check_format.toUpperCase();

  const [downloading, setDownloading] = useState(false);
  const [sortByLastJobWorked, setSortByLastJobWorked] = useState(false);

  const downloadChecks = async () => {
    // const newWindow = window.open("", "_blank");
    setDownloading(true);
    try {
      let response;

      if (hasAccessToMiterPayStubs) {
        response = await MiterAPI.payrolls.download_miter_checks(
          payroll!._id,
          payment?._id,
          sortByLastJobWorked
        );
      } else {
        response = await (payment
          ? MiterAPI.payrolls.download_check(paymentId)
          : MiterAPI.payrolls.download_checks(payroll!._id));
      }

      if (response.error) throw Error(response.error);

      const paydayText = payroll?.check_payroll.payday ? payroll.check_payroll.payday + " " : "";

      const blob = await response.blob();
      const fileName =
        paydayText +
        (payment
          ? payment.team_member.full_name + " paper check.pdf"
          : payroll!.type + " payroll paper checks.pdf");
      downloadBlob(blob, fileName);
      Notifier.success("Paper checks successfully downloaded.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error downloading this payroll's checks: ${e.message}`);
    }
    hide();
    setDownloading(false);
  };

  if (!checkFormat) return null;

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={hide}>
        <div className="modal-wrapper" style={{ width: 500 }}>
          <div className="flex">
            <h3 style={{ marginBottom: 0 }}>{`Download paper check${!payment ? "s" : ""}`}</h3>
            <div className="flex-1"></div>
          </div>
          <div className="modal-body">
            {!downloading && (
              <div className="payroll-approval-text-wrapper">
                {payment ? (
                  <span>{`Upon confirmation, Miter will generate a PDF containing a printable paper check in ${checkFormat} check format.`}</span>
                ) : (
                  <div>
                    <span>{`Upon confirmation, Miter will generate a PDF containing printable paper checks for each employee and contractor payment with a manual payment method.`}</span>
                    <br></br>
                    <br></br>
                    <span>Checks will be generated in {checkFormat} format.</span>
                  </div>
                )}
              </div>
            )}
            {downloading && (
              <div>
                <div className="payroll-approval-text-wrapper">
                  <span className="bold">Note:</span>&nbsp;
                  <span>Depending on the number of payments, this process can take a few minutes.</span>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            {!paymentId && (
              <Formblock
                name="sortByLastJob"
                type="checkbox"
                text="Sort by last job worked."
                defaultValue={false}
                editing={!downloading}
                onChange={(e) => setSortByLastJobWorked(e.target.checked)}
                className="modal"
              />
            )}
            <div className="flex-1"></div>
            <button
              className={downloading ? "button-1 inactive" : "button-1"}
              onClick={downloading ? () => {} : hide}
            >
              Cancel
            </button>
            <Button
              className="button-2 no-margin"
              onClick={downloadChecks}
              text="Download"
              loading={downloading}
            />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DownloadChecksModal;
