import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedFillableTemplate, FillableTemplate } from "dashboard/types/fillable-template-types";

/*
 * FILLABLE TEMPLATES
 */
type FillableTemplateParams =
  | FillableTemplate
  | AggregatedFillableTemplate
  | AggregatedFillableTemplate[]
  | undefined;

type FillableTemplateAction = "create" | "read" | "update" | "delete" | "read_sensitive";
type FillableTemplateAbilities = {
  can: (action: FillableTemplateAction, items: FillableTemplateParams) => boolean;
  cannot: (action: FillableTemplateAction, items: FillableTemplateParams) => boolean;
};

export const useFillableTemplateAbilities = (): FillableTemplateAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: FillableTemplateAction, items?: FillableTemplateParams) => {
      if (!items) return false;
      const fillableTemplateFillableTemplates = Array.isArray(items) ? items : [items];

      return fillableTemplateFillableTemplates.every((_ct) => {
        switch (action) {
          case "create":
            // @ts-expect-error fix me
            return can_("documents:fillable_templates:create");
          case "read":
            // @ts-expect-error fix me
            return can_("documents:fillable_templates:read");
          case "read_sensitive":
            // @ts-expect-error fix me
            return can_("documents:fillable_templates:read_sensitive");
          case "update":
            // @ts-expect-error fix me
            return can_("documents:fillable_templates:update");
          case "delete":
            // @ts-expect-error fix me
            return can_("documents:fillable_templates:delete");
          default:
            return false;
        }
      });
    },
    [can_]
  );

  const cannot = useCallback(
    (action: FillableTemplateAction, items: FillableTemplateParams) => {
      return !can(action, items);
    },
    [can]
  );

  return useMemo(() => ({ can, cannot }), [can, cannot]);
};
