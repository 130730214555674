import React, { useEffect, FC, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Loader, Notifier } from "ui";
import EmptyChat from "../../../assets/empty-chat.svg";
import styles from "../Chat.module.css";
import {
  useActiveCompany,
  useActiveRecruitingConversation,
  usePaginatedRecruitingConversations,
  useRecruitingChatInitialized,
  useSetActiveRecruitingConversation,
} from "dashboard/hooks/atom-hooks";
import { CHAT_DEBOUNCE } from "dashboard/utils/chat";
import { RecruitingConversationList } from "./RecruitingConversationList";
import { RecruitingConversationContainer } from "./RecruitingConversationContainer";
import { RecruitingChatProfile } from "./RecruitingChatProfile";
import { useDebounce } from "use-debounce";
import { AggregatedCandidate } from "dashboard/types/ats";
import { MiterAPI } from "dashboard/miter";

export const RecruitingChatContainer: FC = ({}) => {
  /** Atom hooks */
  const recruitingConversations = usePaginatedRecruitingConversations();
  const setActiveRecruitingConversation = useSetActiveRecruitingConversation();
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const isRecruitingChatInitialized = useRecruitingChatInitialized();
  const [candidate, setCandidate] = useState<AggregatedCandidate>();
  const activeCompany = useActiveCompany();

  /** General hooks */
  const params = useParams<{ id: string }>();

  /** Helper state */
  const conversationId = params.id;
  const [hasConversations] = useDebounce(recruitingConversations.length > 0, CHAT_DEBOUNCE);
  const noSelectedConversation = !conversationId;
  const hasChat = !!activeCompany?.candidate_chat?.conversations_service_sid;

  useEffect(() => {
    const conversation =
      (recruitingConversations || []).find((c) => c._id === conversationId) || recruitingConversations[0];
    if (!conversation) return;
    setActiveRecruitingConversation(conversation);
  }, [
    conversationId,
    recruitingConversations.length,
    noSelectedConversation,
    recruitingConversations,
    setActiveRecruitingConversation,
  ]);

  const getCandidateData = useCallback(async () => {
    const candidateId = activeRecruitingConversation?.candidate_id;
    if (!candidateId) {
      return;
    }
    try {
      const response = await MiterAPI.candidates.retrieve_many([
        {
          field: "_id",
          value: candidateId,
          type: "_id",
        },
      ]);
      if (response.error) throw new Error(response.error);
      setCandidate(response[0] || undefined);
    } catch (e: $TSFixMe) {
      Notifier.error("We are unable to access chat at this time. Please reach out to support@miter.com");
    }
  }, [activeRecruitingConversation?.candidate_id]);

  useEffect(() => {
    getCandidateData();
  }, [getCandidateData]);

  const renderEmptyState = () => {
    return (
      <div className={styles["conversation-container-empty-state"]}>
        {isRecruitingChatInitialized && (
          <>
            <img src={EmptyChat} alt="No chats created" />
            <p>Click the plus button and send your first message!</p>
          </>
        )}
        {!isRecruitingChatInitialized && hasChat && <Loader />}
      </div>
    );
  };

  return (
    <div className={styles["chat-container"]}>
      <RecruitingConversationList />
      {hasConversations && candidate && <RecruitingConversationContainer candidate={candidate} />}
      {hasConversations && candidate && <RecruitingChatProfile candidate={candidate} />}
      {!hasConversations && renderEmptyState()}
    </div>
  );
};
