import React from "react";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { useForm } from "react-hook-form";
import { Formblock, SettingsCard } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { QBOConnectionMetaData } from "backend/services/qbo/qbo-types";
import { DeepPartial } from "utility-types";
import { Option } from "ui/form/Input";

const customerAddressFieldOptions: Option<QBOConnectionMetaData["customer_address_field"]>[] = [
  { label: "Shipping", value: "shipping" },
  { label: "Billing", value: "billing" },
];

export const QboIntegrationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const defaultPullCustomersAsJobs = integration.connection?.metadata?.qbo?.pull_customers_as_jobs;
  const defaultClassesAsDepartments = integration.connection?.metadata?.qbo?.classes_as_departments;
  const defaultUseFullName = integration.connection?.metadata?.qbo?.use_full_name_as_job ?? false;
  const defaultAddressConfiguration = integration.connection?.metadata?.qbo?.customer_address_field;

  const handleChange = async (update: DeepPartial<QBOConnectionMetaData>) => {
    const rawUpdate = {
      metadata: {
        qbo: update,
      },
    };

    await updateIntegrationConnection(buildAtomicMongoUpdateFromNested(rawUpdate));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Jobs">
        <Formblock
          form={form}
          underlineTooltip={true}
          name="pull_customers_as_jobs"
          type="checkbox"
          label="Import top-level QB customers as Miter jobs"
          defaultValue={defaultPullCustomersAsJobs}
          onChange={(e) => handleChange({ pull_customers_as_jobs: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="By default, Miter only imports QuickBooks sub-jobs and projects as Miter Jobs. Set this setting to true to import top-level customers as Miter Jobs."
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          name="use_full_name_as_job"
          type="checkbox"
          label="Use QuickBooks Full Name as Miter Job Name"
          defaultValue={defaultUseFullName}
          onChange={(e) => handleChange({ use_full_name_as_job: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="If selected, the job name will be of the format 'Customer:Job Name'."
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          options={customerAddressFieldOptions}
          name="customer_address_field"
          type="select"
          label="Customer address field"
          labelInfo="Which Customer field Miter should look at to determine the Miter job address"
          defaultValue={defaultAddressConfiguration}
          onChange={(o) => handleChange({ customer_address_field: o.value })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          inputProps={{}}
          style={{
            maxWidth: 350,
          }}
        />
      </SettingsCard>
      <div className="vertical-spacer-small"></div>
      <SettingsCard title="Departments">
        <Formblock
          form={form}
          underlineTooltip={true}
          name="classes_as_departments"
          type="checkbox"
          label="Import QBO classes as departments"
          defaultValue={defaultClassesAsDepartments}
          onChange={(e) => handleChange({ classes_as_departments: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="If this is set to true, QBO Classes will be created as Departments in Miter."
        />
      </SettingsCard>
      <SettingsCard title="Timesheets">
        <Formblock
          form={form}
          underlineTooltip={true}
          name="mark_timesheets_as_billable"
          type="checkbox"
          label="Mark Timesheets as Billable"
          defaultValue={defaultClassesAsDepartments}
          onChange={(e) => handleChange({ mark_timesheets_as_billable: e.target.checked })}
          editing={true}
          labelStyle={{ minWidth: 215 }}
          labelInfo="If this is set to true, Miter will mark Timesheets as Billable when syncing to QBO."
        />
      </SettingsCard>
    </div>
  );
};
