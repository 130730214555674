import { ClickAwayListener } from "@material-ui/core";
import React, { useMemo } from "react";
import InfoButton from "../information/information";
import styles from "./pwPayRateOverview.module.css";
import xPng from "dashboard/assets/x.png";
import { getTimeTypeAdjustedRateByMult } from "dashboard/utils";
import { TimeType, TimeTypeNumberMap } from "backend/utils/time-type-utils";
import { usdString } from "ui";
import { cleanFloatingPointErrors } from "miter-utils";
import { PayRateItem } from "dashboard/miter";

type Props = { payRateItem: PayRateItem };

const timeTypes = ["reg", "ot", "dot"] as const;

const PayRateOverviewColumnNames: React.FC = () => {
  return (
    <div className={styles["fringe-offset-line"] + " bold"}>
      <div className={`${styles["fringe-offset-line-filler"]} ${styles["hide"]}`}></div>
      {timeTypes.map((timeType) => (
        <div key={timeType} className={styles["fringe-offset-line-dollar"]}>
          <div className="flex-1"></div>
          <div style={{ textDecoration: "underline" }}>{timeType.toUpperCase()}</div>
        </div>
      ))}
    </div>
  );
};

const PayRateOverviewLine: React.FC<{
  text: string;
  value: TimeTypeNumberMap;
  info?: string;
  operator?: "+" | "-";
  className?: string;
}> = ({ text, info, value, operator, className }) => {
  return (
    <div className={`${styles["fringe-offset-line"]} ${className || ""}`}>
      <div>{text}</div>
      {info && <InfoButton text={info} />}
      <div className={styles["fringe-offset-line-filler"]} />
      {operator}
      {timeTypes.map((tt) => {
        return (
          <div key={tt} className={styles["fringe-offset-line-dollar"]}>
            <div className="flex-1" />
            {usdString(value[tt])}
          </div>
        );
      })}
    </div>
  );
};

export const PayRateOverview: React.FC<Props> = ({ payRateItem }) => {
  const pwRef = payRateItem.pw_reference;
  const methodRef = payRateItem.method_reference;

  // Only check for rate differentials actually used on this pay rate item! If we check whether this company has any rate differentials at all, then we'll show a base rate of $0 in situations where no rate diffs were applied
  const usingRateDiffs = !!payRateItem.rate_differential_reference?.rate_differentials.length;

  // If there's a union rate base rate and the method is union_rate/team_member, then that means there's a union rate in play for (pay the higher of these two rates) logic and we may want to show both. This could be true even without a PW context, if the UnionRate has no fringe_rate
  const unionRateIsPossible =
    (payRateItem.method === "union_rate" || payRateItem.method === "team_member") &&
    payRateItem.union_rate_reference?.base_rate != null;

  // Nominal rate = there's a special rate that this could be, but it also could get overwritten by the employee's default rate
  const mayHaveNominalRate = !!pwRef || unionRateIsPossible || payRateItem.method === "constant_salary";

  // Show the base rate if rate differentials are a thing for this company, or if we may have a nominal rate in play
  const hasBaseRate = !!pwRef || usingRateDiffs;

  const rateInfo = useMemo(() => {
    // Base rate only applies in the context of rate differentials or PW
    const originalBaseRate = payRateItem.rate_differential_reference?.original_base_rate ?? pwRef?.base_rate;

    const base: TimeTypeNumberMap = { reg: 0, ot: 0, dot: 0 };
    const contributions: TimeTypeNumberMap = { ...base };
    const nominal: TimeTypeNumberMap = { ...base };
    const rateDiff: TimeTypeNumberMap = { ...base };

    for (const tt of timeTypes) {
      // Build the "base rate" row
      if (originalBaseRate) {
        base[tt] = getTimeTypeAdjustedRateByMult(originalBaseRate, tt);
      }

      // Build the "contributions" row. Cap them with the fringe rate. Only relevant in a PW context
      if (pwRef) {
        const cappedContribution = Math.min(
          pwRef.fringe_rates?.[tt] || 0,
          pwRef.employer_fringe_contribution?.[tt] || 0
        );
        contributions[tt] = cappedContribution;
      }

      if (mayHaveNominalRate) {
        const nominalRate = getNominalRate(payRateItem, tt);
        nominal[tt] = nominalRate;
      }

      payRateItem.rate_differential_reference?.rate_differentials.forEach((rd) => {
        rateDiff[tt] += rd.differential[tt];
      });
    }

    let finalDesc: string | undefined,
      showFinalRow = true;
    if (mayHaveNominalRate) {
      // If we've chosen the team member rate despite it being a PW context, then we should show both the PW rate and the final TM rate
      if (payRateItem.method === "team_member") {
        if (methodRef.compared_base && payRateItem.pw_reference) {
          const cashFringe: TimeTypeNumberMap | undefined = payRateItem.pw_reference.fringe_paid_as_cash;
          if (Object.values(cashFringe || {}).some((x) => x > 0)) {
            finalDesc = "employee default + cash fringe";
          }
        }
        if (!finalDesc) finalDesc = "employee default";
      }
      // If we're holding constant, then we should let them know because the hourly rate will be different than the implied rate based on 40 hours
      else if (payRateItem.method === "constant_salary") {
        finalDesc = "salary, held constant";
      }
      // Don't need to show the final row if the nominal rate is the same as the final rate
      else if (payRateItem.method === "union_rate" || payRateItem.method === "activity") {
        showFinalRow = false;
      }
    }

    return { base, contributions, nominal, rateDiff, finalDesc, showFinalRow };
  }, [mayHaveNominalRate, methodRef.compared_base, payRateItem, pwRef]);

  return (
    <div>
      <div className={styles["fringe-offset-wrapper"]}>
        {payRateItem.classification && (
          <div className={styles["fringe-offset-header"]}>{payRateItem.classification.label}</div>
        )}
        <PayRateOverviewColumnNames />

        {hasBaseRate && (
          <PayRateOverviewLine
            text="Base rate"
            info={pwRef ? "The required hourly cash rate" : undefined}
            operator="+"
            value={rateInfo.base}
          />
        )}
        {usingRateDiffs && (
          <PayRateOverviewLine text="Rate differentials" operator="+" value={rateInfo.rateDiff} />
        )}
        {pwRef && (
          <>
            <PayRateOverviewLine
              text="Fringe rate"
              info="The required hourly fringe rate"
              value={pwRef.fringe_rates}
              operator="+"
            />
            <PayRateOverviewLine
              text="Employer fringe contributions"
              info="Employer contributions to bonafide benefit plans during this timesheet's pay period, expressed as an hourly rate"
              value={rateInfo.contributions}
              operator="-"
            />
          </>
        )}
        {mayHaveNominalRate && (
          <>
            <div className={styles["total-divider-line"]}></div>
            <PayRateOverviewLine
              text={rateInfo.showFinalRow ? "Nominal rate" : "Final rate"}
              value={rateInfo.showFinalRow ? rateInfo.nominal : payRateItem.rates}
              className="bold"
            />
          </>
        )}
        {pwRef && (
          <PayRateOverviewLine
            text="Wage rate in lieu of fringes"
            info="Hourly cash payment covering the gap between fringes owed and employer benefit contributions."
            value={pwRef.fringe_paid_as_cash}
            className={styles["italic"]}
          />
        )}
        {rateInfo.showFinalRow && (
          <>
            <div className={styles["total-divider-line"]}></div>
            <PayRateOverviewLine
              text={`Final rate${rateInfo.finalDesc ? ` (${rateInfo.finalDesc})` : ""}`}
              value={payRateItem.rates}
              className="bold"
            />
          </>
        )}
      </div>
    </div>
  );
};

export const PayRateModal: React.FC<Props & { hide: () => void }> = ({ payRateItem, hide }) => {
  const comp = <PayRateOverview payRateItem={payRateItem} />;
  if (!comp) return null;

  return (
    <>
      <div className="modal-background">
        <ClickAwayListener onClickAway={hide}>
          <div className={styles["modal-wrapper"]}>
            <div className="flex width-100" style={{ marginBottom: 20 }}>
              <div style={{ fontWeight: 600, fontSize: 20 }}>Prevailing wage rate overview</div>
              <div className="flex-1"></div>
              <img className="exit-icon" src={xPng} onClick={hide} />
            </div>
            {comp}
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
};

const getNominalRate = (payRateItem: PayRateItem, tt: TimeType): number => {
  const methodRef = payRateItem.method_reference,
    pwRef = payRateItem.pw_reference;

  if (payRateItem.method === "constant_salary") {
    // For constant-salary, then the nominal rate is the TM's normal hourly rate. It gets adjusted down if they work more than 40 hours per week
    if (methodRef.team_member_rates) {
      // We didn't always have `team_member_rates`, so need to check for it
      // Otherwise use the old method of taking the TM default and multiplying by the OT factor
      // This is not accurate for new timesheets because of rate differentials
      return methodRef.team_member_rates[tt];
    } else {
      return getTimeTypeAdjustedRateByMult(methodRef.default_tm_rate!, tt);
    }
  } else if (methodRef.comparison_rates) {
    // If we have comparison rates (don't exist in older pay rate items), then use them directly
    return methodRef.comparison_rates[tt];
  } else {
    // This is for older timesheets that don't have the comparison_rates property. It's not accurate anymore for new timesheets because of rate differentials
    const b = pwRef?.base_rate ?? payRateItem.union_rate_reference?.base_rate;
    return cleanFloatingPointErrors(
      getTimeTypeAdjustedRateByMult(b || 0, tt) + (pwRef?.fringe_paid_as_cash?.[tt] || 0)
    );
  }
};
