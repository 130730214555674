import React, { useEffect, useState } from "react";
import { Wizard } from "ui";
import { Form, FormParentType } from "dashboard/miter";
import { FormBuilderScreen } from "./FormBuilderScreen";
import FormSettingsScreen from "./FormSettingsScreen";

export type FormWizardMode = "create" | "edit-questions" | "edit-settings";

type Props = {
  onExit: () => void;
  onComplete: () => void;
  form?: Form;
  mode: FormWizardMode;
  parentType?: FormParentType;
  parentId?: string;
  name?: string;
};

const FormWizard: React.FC<Props> = ({ onExit, onComplete, mode, parentType, parentId, name, ...props }) => {
  const [form, setForm] = useState<Form | undefined>(props.form);

  useEffect(() => {
    setForm(props.form);
  }, [props.form]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      {(mode === "create" || mode === "edit-questions") && (
        <FormBuilderScreen
          name={name || "Build the form"}
          formItem={form}
          setFormItem={setForm}
          parentType={parentType}
          parentId={parentId}
        />
      )}
      {(mode === "create" || mode === "edit-settings") && (
        <FormSettingsScreen formItem={form} name={"Form Settings"} />
      )}
    </Wizard>
  );
};

export default FormWizard;
