import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Notifier, Toggler } from "ui";
import { Helmet } from "react-helmet";
import { TogglerConfigItem } from "ui/toggler/Toggler";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { TeamSettings } from "./TeamSettings";
import { TeamMemberOnboardingSettings } from "./TeamMemberOnboardingSettings";
import { useHasAccessToOnboardingV2 } from "dashboard/gating";

const TeamSettingsSelector: React.FC = () => {
  const navigate = useNavigate();
  const { cannot } = useMiterAbilities();

  useEffect(() => {
    if (cannot("team:settings")) {
      navigate("/home");
      Notifier.error("You do not have permission to view this page.");
    }
  }, [cannot, navigate]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Cards | Miter</title>
      </Helmet>
      <div className="page-content">
        <div>
          <div className="blue-link" onClick={() => navigate("/team-members")}>
            {"Back to team directory"}
          </div>
          <div className="flex">
            <h1>Team settings</h1>
            <div className="flex-1"></div>
          </div>

          <TeamSettingsWrapper />
        </div>
      </div>
    </div>
  );
};

export const TeamSettingsWrapper: React.FC = () => {
  const { view } = useParams<Params>();
  const navigate = useNavigate();
  const hasAccessToOnboardingV2 = useHasAccessToOnboardingV2();

  const toggle = (page: string) => navigate("/team-members/settings/" + page, { replace: true });

  const renderView = () => {
    if (!view || view === "settings") {
      return <TeamSettings />;
    } else if (view === "onboarding") {
      return <TeamMemberOnboardingSettings />;
    }
  };

  const fullTogglerConfig: TogglerConfigItem[] = [
    { path: "settings", label: "General settings" },
    ...(hasAccessToOnboardingV2 ? [{ path: "onboarding", label: "Onboarding" }] : []),
  ];

  return (
    <>
      <Toggler config={fullTogglerConfig} active={view || "settings"} toggle={toggle} />
      {renderView()}
    </>
  );
};

type Params = {
  view?: "settings" | "onboarding";
};

export default TeamSettingsSelector;
