import { ClickAwayListener } from "@material-ui/core";
import React, { useState } from "react";
import Notifier from "dashboard/utils/notifier";
import PayrollContext from "../payrollContext";
import { Button } from "ui";
import { PayrollAdjustment } from "../../payrollTypes";

const DeletePaymentsModal: React.FC<{ hide: () => void; selected: string[] }> = (props) => {
  // Hooks
  const { payroll, recalculatePayroll } = React.useContext(PayrollContext);

  // State
  const [isDeleting, setIsDeleting] = useState(false);

  const deletePayments = async () => {
    if (!payroll) return;
    setIsDeleting(true);
    try {
      // Loop through adjustments, retaining only those that aren't selected
      const newAdjustments: PayrollAdjustment[] = [];
      for (const adj of payroll.adjustments) {
        if (!props.selected.includes(adj.team_member)) {
          newAdjustments.push(adj);
        }
      }

      // Recalculate payroll for all TMs
      await recalculatePayroll({ adjustments: newAdjustments, tms: "all" });
      Notifier.success("Payments successfully deleted.");
    } catch (e) {
      Notifier.error("There was an error deleting payments. We're looking into it.");
    }
    props.hide();
    setIsDeleting(false);
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={props.hide}>
        <div className="modal-wrapper">
          <div className="modal-header">
            <div>{props.selected.length === 1 ? "Delete payment" : "Delete payments"}</div>
            <div className="flex-1"> </div>
          </div>
          <div className="modal-body">
            <span>You are about to delete</span>&nbsp;
            <span>{props.selected.length}</span>
            <span>{props.selected.length === 1 ? " payment " : " payments "}</span>
            <span>from the payroll.</span>
          </div>
          <div className="modal-footer">
            <button className="button-1" onClick={props.hide}>
              Cancel
            </button>
            <Button className={"button-3"} onClick={deletePayments} text="Delete" loading={isDeleting} />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DeletePaymentsModal;
