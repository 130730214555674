import { MiterAPI, Trade } from "dashboard/miter";
import { createObjectMap } from "dashboard/utils";
import { useEffect, useState, useCallback, useMemo } from "react";
import { Notifier } from "ui";
import { Option } from "ui/form/Input";

type UseTradesData = {
  tradesLookup: Record<string, Trade>;
  tradeOptions: Option<string>[];
  trades: Trade[] | undefined;
  loading: boolean;
  error: unknown | null;
  fetchTrades: () => void;
};

export const useTrades = (): UseTradesData => {
  const [trades, setTrades] = useState<Trade[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchTrades = useCallback(async () => {
    setLoading(true);
    try {
      const res = await MiterAPI.trades.get();
      if (res.error) throw new Error(res.error);

      setTrades(res);
    } catch (e: $TSFixMe) {
      Notifier.error("Error fetching trades.");
      setError(e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchTrades();
  }, [fetchTrades]);

  const tradesLookup = useMemo(() => {
    return createObjectMap(trades || [], (trade) => trade._id);
  }, [trades]);

  const tradeOptions: Option<string>[] | undefined = useMemo(() => {
    return (
      trades?.map((trade) => {
        return { value: trade._id, label: trade.name };
      }) || []
    );
  }, [trades]);

  return {
    trades,
    loading,
    error,
    fetchTrades,
    tradeOptions,
    tradesLookup,
  };
};
