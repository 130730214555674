import AppContext from "dashboard/contexts/app-context";
import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui";
import { IS_PRODUCTION } from "dashboard/utils/environment";

export const PayRateAndFringeSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const [useBaseSelected, setUseBaseSelected] = React.useState(
    activeCompany?.settings.payroll.use_base_rate_for_comparison
  );

  const [useHoursWorkedForFringeOffsets, setUseHoursWorkedForFringeOffsets] = React.useState(
    activeCompany?.settings.payroll.use_hours_worked_for_fringe_offsets
  );

  const handleChange = async (
    e: ChangeEvent<HTMLInputElement>,
    settingId: string,
    setSelected: Dispatch<SetStateAction<boolean | undefined>>
  ) => {
    setSelected(e.target.checked);
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { [settingId]: e.target.checked },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
  };

  return (
    <>
      <SettingsCard title="Pay rate and fringe settings" contentStyle={{ color: "rgb(51,51,51)" }}>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="checkbox"
            style={{ margin: "0px 12px" }}
            onChange={(e) =>
              handleChange(e, "settings.payroll.use_base_rate_for_comparison", setUseBaseSelected)
            }
            checked={useBaseSelected}
          />
          <label>
            When choosing the higher between a classification rate and a team member&apos;s default rate,
            compare against the classification base rate only (not the base rate plus cash-in-lieu-of-fringe).
          </label>
        </div>
      </SettingsCard>
      {!IS_PRODUCTION && (
        <SettingsCard
          title="Use hours worked for fringe offset calculations"
          subtitle="By default, Miter uses a standard 40-hour workweek to calculate fringe offsets."
          info="Miter may both increase hourly offsets when working fewer than 40 hours, and decrease hourly offsets when working over 40 hours."
          contentStyle={{ color: "rgb(51,51,51)" }}
        >
          <div className="flex" style={{ marginTop: 7 }}>
            <input
              type="checkbox"
              style={{ margin: "0px 12px" }}
              onChange={(e) =>
                handleChange(
                  e,
                  "settings.payroll.use_hours_worked_for_fringe_offsets",
                  setUseHoursWorkedForFringeOffsets
                )
              }
              checked={useHoursWorkedForFringeOffsets}
            />
            <label>
              If checked, Miter will instead calculate fringe offsets for benefit contributions, time off
              policies, and holiday schedules using actual hours worked.
            </label>
          </div>
        </SettingsCard>
      )}
    </>
  );
};
