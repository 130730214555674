import { useQuery } from "miter-utils";
import { useRef } from "react";

export const useTargetCompany = (): React.MutableRefObject<string | null> => {
  const query = useQuery();
  const targetCompany = query.get("cid");

  // Holding in ref so internal navigation doesn't trigger a re-render by removing the query param from the url
  const targetCompanyRef = useRef(targetCompany);

  return targetCompanyRef;
};
