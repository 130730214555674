import { DateTime } from "luxon";
import { FilePickerFile } from "packages/ui/form/FilePicker";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { MiterAPI, RetrieveFileResponse } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { ActionModal, Formblock } from "ui";
import { OfferLetter } from "dashboard/types/offers-types";
import { formatFilesForUpload } from "miter-utils";
import * as vals from "dashboard/utils/validators";

type UploadFormParams = {
  files: FilePickerFile[];
  date: DateTime;
};

export const ManuallyUploadSignedLetterModal: FC<{
  handleClose: () => void;
  handleSuccess: () => Promise<void>;
  offerLetter: OfferLetter;
  existingOfferLetter: RetrieveFileResponse;
}> = ({ handleClose, offerLetter, existingOfferLetter, handleSuccess }) => {
  const form = useForm<UploadFormParams>();
  const { handleSubmit } = form;
  const [saving, setSaving] = useState(false);

  const onSubmit = async () => {
    handleSubmit(uploadSignedLetter)();
  };

  const uploadSignedLetter = async (data: UploadFormParams) => {
    try {
      setSaving(true);
      const formattedFileParams = formatFilesForUpload(data.files, offerLetter.company_id, {
        parent_type: "offer_letter",
        parent_id: offerLetter._id,
        label: `${existingOfferLetter.originalname} (signed)`,
      });
      const createFileRes = await MiterAPI.files.upload({ files: formattedFileParams });
      if (!createFileRes[0]) throw new Error("Unable to upload signed offer letter");
      const file = createFileRes[0].file;
      if (!file) throw new Error("Unable to upload signed offer letter");
      const updateOfferLetterRes = await MiterAPI.offer_letters.update(offerLetter._id, {
        file_id: createFileRes[0].file._id,
        completion_method: "manual_upload",
        status: "signed",
        events: [
          ...offerLetter.events,
          {
            type: "signed",
            timestamp: data.date.toSeconds(),
          },
        ],
      });
      if (updateOfferLetterRes.error) throw Error(updateOfferLetterRes.error);
      Notifier.success("Offer letter manually uploaded.");
      await handleSuccess();
      setSaving(false);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }

    handleClose();
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          name={"files"}
          className="modal wizard"
          label="Upload signed offer letter"
          type="file"
          form={form}
          multiple={true}
          maxFilesAllowed={1}
          variant="dropzone"
          editing={true}
          compact={true}
          dropzoneLabel="Drag and drop or click to manually upload the signed offer letter"
          val={vals.required}
          labelStyle={{ marginBottom: 10 }}
        />
        <Formblock
          label="Date the candidate signed the offer letter"
          type="datetime"
          dateOnly={true}
          name="date"
          rules={vals.required}
          form={form}
          className="double time-off-request-date"
          editing={true}
          min={DateTime.fromSeconds(offerLetter.created_at)}
          max={DateTime.now()}
          defaultValue={null}
          customFormat="MMM dd, yyyy"
        />
      </div>
    );
  };
  return (
    <ActionModal
      headerText={"Manual upload"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Cancel"}
      onCancel={handleClose}
      submitText={"Submit"}
      onHide={handleClose}
      onSubmit={onSubmit}
      loading={saving}
      wrapperStyle={{ width: 500 }}
    >
      {renderForm()}
    </ActionModal>
  );
};
