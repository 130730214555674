import React from "react";
import { OnboardingCard } from "./OnboardingCard";
import styles from "./Onboarding.module.css";
import { Button } from "ui";
import { ArrowCircleRight, BookOpen } from "phosphor-react";
import { goToMiterGuide } from "dashboard/utils/miterGuides";

type Props = {
  title: string;
  subheader: string;
  usecases: OnboardingCard[];
  setup: OnboardingCard[];
  continueOnClick?: () => void;
};

export const Onboarding: React.FC<Props> = ({ title, subheader, usecases, setup, continueOnClick }) => {
  return (
    <div className={styles["main-container"]}>
      <div className={styles["onboarding-header"]}>
        <h1 className={styles["onboarding-title"]}>{title}</h1>
        <p className={styles["onboarding-subheader"]}>{subheader}</p>
      </div>
      <div className={styles["onboarding-cards-container"]}>
        {usecases.map((card, index) => (
          <OnboardingCard key={index} {...card} />
        ))}
      </div>
      <div className={styles["onboarding-actions"]}>
        <Button
          className={"button-2 " + styles["btn-inner-style"]}
          onClick={() => goToMiterGuide("performance/introduction")}
        >
          <BookOpen weight="bold" style={{ marginRight: 7 }} />
          Read the guide
        </Button>

        <Button className={"button-1 " + styles["btn-inner-style"]} onClick={continueOnClick}>
          <ArrowCircleRight weight="bold" style={{ marginRight: 7 }} />
          Get started
        </Button>
      </div>
      <div className={styles["onboarding-midpage-header"]}>Getting started</div>
      <div className={styles["onboarding-cards-container"]}>
        {setup.map((card, index) => (
          <OnboardingCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};
