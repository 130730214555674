import React, { useEffect, useMemo, useRef, useState } from "react";
import { TableV2 } from "ui";
import { HolidayTabProps, predefinedHolidaySchemas } from "./utils";
import Banner from "dashboard/components/shared/Banner";
import { PredefinedHoliday } from "backend/models/holiday-schedule";
import { useDebouncedCallback } from "use-debounce";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { ColumnConfig } from "ui/table-v2/Table";
import { useLookupOtRule, useOtRuleOptions } from "dashboard/hooks/atom-hooks";
import { ValueFormatterParams } from "ag-grid-community";
import { FederalHolidayModal } from "./FederalHolidayModal";

export type FederalHolidayTableRow = {
  label: string;
  _id: string;
  date: string;
  observed: boolean;
  overtime_rule_id?: string;
};

export const FederalHolidays: React.FC<HolidayTabProps> = ({
  originalHolidaySchedule,
  updateHolidaySchedule,
}) => {
  const mounted = useRef(false);
  const holidayRef = useRef(originalHolidaySchedule.enabled_predefined_holidays);
  const { cannot } = useMiterAbilities();

  const [enabledHolidays, setEnabledHolidays] = useState(holidayRef.current);
  const [selectedFederalHoliday, setSelectedFederalHoliday] = useState<FederalHolidayTableRow | null>(null);

  const updateHolidayScheduleDebounced = useDebouncedCallback(updateHolidaySchedule, 2000);

  const otRuleOptions = useOtRuleOptions();
  const lookupOtRule = useLookupOtRule();

  const CheckboxItem: React.FC<{ holiday: PredefinedHoliday; disabled?: boolean }> = ({
    holiday,
    disabled,
  }) => {
    return (
      <input
        type={"checkbox"}
        checked={enabledHolidays.some((h) => h.key === holiday.key)}
        onChange={(e) => onSave(e, holiday)}
        disabled={disabled}
      />
    );
  };

  const federalHolidayColumns: ColumnConfig<FederalHolidayTableRow>[] = [
    {
      field: "label",
      headerName: "Holiday",
      dataType: "string",
    },
    {
      field: "date",
      headerName: "Upcoming date",
      type: "string",
    },
    {
      field: "observed",
      headerName: "Observed",
      dataType: "component",
      disableCellClick: true,
      cellRenderer: (params) => {
        return (
          <CheckboxItem holiday={{ key: params.data._id }} disabled={cannot("time_off:holidays:manage")} />
        );
      },
    },
    ...(!!otRuleOptions.length
      ? [
          {
            field: "overtime_rule_id",
            headerName: "Overtime rule",
            dataType: "string" as const,
            valueFormatter: (params: ValueFormatterParams<FederalHolidayTableRow>): string => {
              const rule = lookupOtRule(params.data?.overtime_rule_id);
              return rule?.label || "-";
            },
            editable: true,
            editorType: "select" as const,
            cellEditorParams: () => ({ options: otRuleOptions, isClearable: true }),
          },
        ]
      : []),
  ];

  const onSave = async (e: React.ChangeEvent<HTMLInputElement>, holiday: PredefinedHoliday) => {
    let newEnabledHolidays = [...enabledHolidays];
    const holidayAlreadyEnabled = newEnabledHolidays.some((h) => holiday.key === h.key);
    const checkBoxSelected = e.target.checked;
    // avoid redundant operation
    if ((holidayAlreadyEnabled && checkBoxSelected) || (!holidayAlreadyEnabled && !checkBoxSelected)) {
      return;
    }
    if (checkBoxSelected) {
      newEnabledHolidays.push(holiday);
    } else {
      newEnabledHolidays = newEnabledHolidays.filter((federalHoliday) => federalHoliday.key !== holiday.key);
    }
    setEnabledHolidays(newEnabledHolidays);
    holidayRef.current = newEnabledHolidays;
  };

  const tableData = useMemo(() => {
    return predefinedHolidaySchemas.map((holiday) => {
      const observedHoliday = enabledHolidays.find((h) => h.key === holiday.id);
      return {
        _id: holiday.id,
        label: holiday.name,
        date: holiday.getUpcomingDate().toFormat("DDDD"),
        observed: !!observedHoliday,
        overtime_rule_id: observedHoliday?.overtime_rule_id,
      };
    });
  }, [enabledHolidays]);

  // Update holiday schedule when enabled holidays change, accounting for debounces, and then accounting for if they unmount before we've saved down the latest changes
  useEffect(() => {
    if (mounted.current) {
      updateHolidayScheduleDebounced({
        enabled_predefined_holidays: enabledHolidays,
      });
    } else {
      mounted.current = true;
    }
    return () => {
      updateHolidayScheduleDebounced({
        enabled_predefined_holidays: holidayRef.current,
      });
    };
  }, [updateHolidayScheduleDebounced, enabledHolidays, holidayRef]);

  return (
    <div>
      <Banner
        content="Miter will ensure team members are paid a full day on observed holidays."
        type={"info"}
      ></Banner>
      <div className="flex">
        <div className="flex-1"></div>
      </div>
      {selectedFederalHoliday && (
        <FederalHolidayModal
          selectedHoliday={selectedFederalHoliday}
          updateHolidays={async (newEnabledHolidays: PredefinedHoliday[]) => {
            setEnabledHolidays(newEnabledHolidays);
            holidayRef.current = newEnabledHolidays;
          }}
          originalHolidaySchedule={originalHolidaySchedule}
          hide={() => setSelectedFederalHoliday(null)}
        />
      )}
      <TableV2
        id={"federal-holidays-table"}
        data={tableData}
        columns={federalHolidayColumns}
        onClick={setSelectedFederalHoliday}
        rowClickDisabled={(data) => cannot("time_off:holidays:manage") || !data.observed}
        hideSearch={true}
        hideSecondaryActions={true}
        containerClassName={"timesheets-table-container"}
        resource="holidays"
        paginationPageSize={5}
      />
    </div>
  );
};
