import { ClickAwayListener } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "ui";
import { ActivityPayRateType } from "backend/models/activity";

type Props = {
  defaultPayType: ActivityPayRateType;
  onSubmit: (option: ActivityPayRateType) => void;
  hide: () => void;
};

type PayTypeOption = {
  value: ActivityPayRateType;
  header: string;
  subheader: string;
  style?: React.CSSProperties;
};

const options: PayTypeOption[] = [
  {
    value: "default",
    header: "Default",
    subheader: "Team members are paid according to the normal pay rate determination logic.",
  },
  {
    value: "custom",
    header: "Custom",
    subheader: "Team members are paid a specific, custom hourly rate when doing this activity.",
  },
  {
    value: "prg",
    header: "Pay Rate Group",
    subheader: "Team members are paid according to their classification within the specified pay rate group.",
  },
  {
    value: "pw",
    header: "Prevailing Wage (deprecated)",
    subheader:
      "We now recommend using pay rate groups to properly pay prevailing wage rates, but if you select this type, team members will be paid the higher of their default hourly rate or the prevailing wage.",
    style: { color: "gray" },
  },
];

export const ActivityPayTypeSelector: React.FC<Props> = ({ hide, onSubmit, defaultPayType }) => {
  const [selectedPayType, setSelectedPayType] = useState(defaultPayType);

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={hide}>
        <div className="modal-wrapper form">
          <div className="modal-header form">Pay rate options</div>

          <div className="modal-body form">
            <div className="vertical-spacer"></div>
            {options.map((option, index) => {
              const className = option.value === selectedPayType ? "selected" : "";
              return (
                <div
                  key={index}
                  onClick={() => setSelectedPayType(option.value)}
                  className={"calculation_option_wrapper " + className}
                  style={option.style}
                >
                  <div className="calculation_option_header">{option.header}</div>
                  <div className="calculation_option_subheader">{option.subheader}</div>
                </div>
              );
            })}
            <div className="vertical-spacer"></div>
          </div>
          <div className="modal-footer form">
            <button className="button-1" onClick={hide}>
              Cancel
            </button>
            <Button className="button-2" text="Save" onClick={() => onSubmit(selectedPayType)} />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
