import React from "react";

import { ActionModal, Formblock, Notifier } from "ui";
import * as vals from "dashboard/utils/validators";

import { AggregatedTeamMember } from "dashboard/miter";
import { UseFormMethods } from "react-hook-form";

type Props = {
  defaultData: AggregatedTeamMember;
  form: UseFormMethods;
  onHide: () => void;
  onSubmit: (data: $TSFixMe, scheduleChanges: boolean) => Promise<void>;
  submitting: boolean;
  submitText?: string;
};

export const EmergencyContactsForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  form,
  submitText,
}) => {
  const { register, control, errors } = form;

  const save = (scheduleChanges: boolean) => {
    let emContactsEqual = true;
    const newContacts = form.getValues().emergency_contacts;
    for (let i = 0; i < 2; i++) {
      for (const key in newContacts[i]) {
        if (
          (!!newContacts[i][key] || !!defaultData?.emergency_contacts?.[i]?.[key]) &&
          newContacts[i][key] !== defaultData?.emergency_contacts?.[i]?.[key]
        ) {
          emContactsEqual = false;
          break;
        }
      }
      if (emContactsEqual) {
        Notifier.error("No changes detected");
        return;
      }
    }
    onSubmit(form.getValues(), scheduleChanges);
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <h2 style={{ fontSize: 16, marginTop: 0, fontWeight: "bolder" }}>Emergency contact #1</h2>
        <Formblock
          type="text"
          name="emergency_contacts[0].name"
          label="Name*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={defaultData?.emergency_contacts?.[0]?.name}
          className="modal"
        />
        <Formblock
          type="text"
          name="emergency_contacts[0].relationship"
          label="Relationship*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={defaultData?.emergency_contacts?.[0]?.relationship}
          className="modal"
        />
        <Formblock
          type="phone"
          name="emergency_contacts[0].phone"
          label="Phone*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={defaultData?.emergency_contacts?.[0]?.phone}
          className="modal"
        />
        <h2 style={{ fontSize: 16, marginTop: 20, fontWeight: "bolder" }}>Emergency contact #2</h2>
        <Formblock
          type="text"
          name="emergency_contacts[1].name"
          label="Name*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={defaultData?.emergency_contacts?.[1]?.name}
          className="modal"
        />
        <Formblock
          type="text"
          name="emergency_contacts[1].relationship"
          label="Relationship*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={defaultData?.emergency_contacts?.[1]?.relationship}
          className="modal"
        />
        <Formblock
          type="phone"
          name="emergency_contacts[1].phone"
          label="Phone*"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={defaultData?.emergency_contacts?.[1]?.phone}
          className="modal"
        />
      </div>
    );
  };
  return (
    <ActionModal
      headerText={"Edit emergency contacts"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={onHide}
      submitText={submitText || "Save"}
      onHide={onHide}
      onSubmit={() => save(false)}
      loading={submitting}
      showEdit={submitText !== "Request changes"}
      editText={"Schedule changes"}
      onEdit={() => save(true)}
    >
      {renderForm()}
    </ActionModal>
  );
};
