export const MAP_TASK_TO_MILESTONE = {
  personal_information: "payroll",
  payment_method: "payroll",
  withholding_information: "payroll",
  employment_information: "payroll",
  phone_number: "mobile_app",
  i9_employee: "work_authorization",
  i9_employer: "work_authorization",
  bank_account: "ach",
};

export const MAP_MILESTONE_TO_TASKS = {
  payroll: ["personal_information", "payment_method", "employment_information", "withholding_information"],
  mobile_app: ["phone_number"],
  work_authorization: ["i9_employee", "i9_employer"],
  ach: ["bank_account"],
};

export const MAP_TASK_LABEL = {
  eeo_information: "EEO information",
  personal_information: "Personal information",
  payment_method: "Payment method",
  withholding_information: "Withholding information",
  employment_information: "Employment information",
  phone_number: "Phone number",
  i9_employee: "Employee I-9",
  i9_employer: "Employer I-9",
  bank_account: "ACH bank",
  custom_fields: "Custom fields",
  documents: "Documents",
  forms: "Forms",
};

export const MAP_TASK_TO_ACTION_TITLE = {
  eeo_information: "Complete EEO information",
  personal_information: "Complete personal information",
  payment_method: "Complete payment method",
  withholding_information: "Complete withholding information",
  employment_information: "Complete employment information",
  phone_number: "Set phone number",
  i9_employee: "Employee fills their section of the I-9",
  i9_employer: "Admin completes the I-9",
  bank_account: "Add bank account",
  custom_fields: "Complete custom profile questions",
  documents: "Sign or view documents",
  forms: "Complete forms",
  certifications: "Complete certifications",
  custom: "Custom task",
};

export const MAP_TASK_TO_DESCRIPTION = {
  eeo_information:
    "Please fill out your Equal Employment Opportunity information: your ethnicity, veteran status, disability status, etc.",
  personal_information: "Complete your personal information such as your address, birthday, and SSN.",
  payment_method:
    "Set up your payment method preference (direct deposit or check) and fill out your bank information.",
  withholding_information:
    "Decide how much you want to withhold from your paycheck. This will affect how much you eventually pay in taxes.",
  employment_information: "Set up the new hire's pay schedule and pay rate.",
  phone_number:
    "Set a phone number for the new hire so that they can access the mobile app and receive text messages.",
  i9_employee: "Follow the interactive steps to complete your section of the I-9.",
  i9_employer:
    "Once the employee is done with their part of the I-9, you can complete the remaining fields and generate a finished document.",
  bank_account: "Add and track employee bank accounts within Miter.",
  custom_fields: "Complete custom field questions from team portal",
  documents: "Read through and/or sign documents from the team portal.",
  forms: "Complete your assigned forms.",
  certifications: "Upload any certifications or licenses.",
  custom:
    "Custom description for this task - assignee can manually mark the task as complete. eg. 'Grab coffee with manager!'",
};

export const DEFAULT_MILESTONES = {
  payroll: false,
  mobile_app: false,
  ach: false,
  work_authorization: false,
};
