import React from "react";
import styles from "./PolicyHeader.module.css";

type Props = {
  label: string;
  subLabel?: string;
  style?: React.CSSProperties;
};

export const PolicyHeader: React.FC<Props> = ({ label, subLabel, style }) => {
  return (
    <div className={styles["policy-header"]} style={style}>
      <p className={styles["policy-header-label"]}>{label}</p>
      {subLabel && <p className={styles["policy-header-sub-label"]}>{subLabel}</p>}
    </div>
  );
};
