import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Notifier } from "ui";
import { AggregatedJob, MiterAPI } from "dashboard/miter";
import { usePerDiemRateOptions } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import PerDiemRateModal from "./PerDiemRateModal";

type AddPerDiemRateToJobFormData = {
  per_diem_rate_id: Option<string>;
};

type Props = {
  job: AggregatedJob;
  onHide: () => void;
  onSave: () => void;
};

const AddPerDiemRateToJobModal: React.FC<Props> = ({ job, onHide, onSave }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [showCreatePerDiemRateModal, setShowCreatePerDiemRateModal] = useState<boolean>(false);

  const { control, errors, handleSubmit } = useForm<AddPerDiemRateToJobFormData>();

  const options = usePerDiemRateOptions();

  // disable options that are already added to the job
  const perDiemRateOptions = options.map((option) => {
    const isDisabled = job.per_diem_rate_ids?.includes(option.value);
    return { ...option, isDisabled };
  });

  const handleUpdateJob = async (data: AddPerDiemRateToJobFormData) => {
    setLoading(true);
    try {
      const response = await MiterAPI.jobs.update(job._id, {
        per_diem_rate_ids: [...(job.per_diem_rate_ids || []), data.per_diem_rate_id.value],
      });
      if (response.error) throw new Error(response.error);

      Notifier.success(
        "Per diem rate added to job. This will take into affect for all newly approved timesheets."
      );

      onSave();
      onHide();
    } catch (e: $TSFixMe) {
      Notifier.error("Could not add per diem rate.");
      console.error(e.message);
    }
    setLoading(false);
  };

  return (
    <>
      <ActionModal
        headerText={"Add per diem rate to job"}
        loading={loading}
        // submit
        showSubmit={true}
        submitDisabled={perDiemRateOptions.every((option) => option.isDisabled)} // only show if there are valid options
        submitText={"Save"}
        onSubmit={handleSubmit(handleUpdateJob)}
        // cancel
        showCancel={true}
        cancelText={"Cancel"}
        onCancel={onHide}
        onHide={onHide}
        // create new (opens another modal)
        showEdit={true}
        editText={"Create new per diem rate"}
        onEdit={() => setShowCreatePerDiemRateModal(true)}
      >
        <div className="margin-top-15">
          <Formblock
            type="select"
            name="per_diem_rate_id"
            label="Per diem rate"
            className="modal"
            options={perDiemRateOptions}
            control={control}
            requiredSelect={true}
            editing={true}
            errors={errors}
          />
        </div>
      </ActionModal>
      {showCreatePerDiemRateModal && (
        <PerDiemRateModal
          onHide={() => setShowCreatePerDiemRateModal(false)}
          onSave={() => {
            // refresh list of eligible per diem rates
            setRefreshCounter(refreshCounter + 1);
          }}
        />
      )}
    </>
  );
};

export default AddPerDiemRateToJobModal;
