import { PaySchedule } from "dashboard/miter";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { Button, Formblock } from "ui";
import { Option } from "ui/form/Input";
import { UpdatePayScheduleParams } from "backend/services/pay-schedule-service";
import * as vals from "dashboard/utils/validators";
import { FieldValues, UseFormMethods } from "react-hook-form";
import { isWeeklyOrBiweekly, validPayScheduleFreqOptions } from "dashboard/utils/paySchedules";
import { CheckPayFrequency } from "backend/utils/check/check-types";

type Props = {
  originalPaySchedule: PaySchedule;
  updatePaySchedule: (p: UpdatePayScheduleParams) => Promise<boolean>;
  form: UseFormMethods<FieldValues>;
};

const workweekEndDayOptions: Option<number>[] = [
  { label: "Sundays (recommended)", value: 7 },
  { label: "Mondays", value: 1 },
  { label: "Tuesdays", value: 2 },
  { label: "Wednesdays", value: 3 },
  { label: "Thursdays", value: 4 },
  { label: "Fridays", value: 5 },
  { label: "Saturdays", value: 6 },
];

export const PayScheduleBasics: React.FC<Props> = ({ originalPaySchedule, updatePaySchedule, form }) => {
  const checkPs = originalPaySchedule.check_pay_schedule;

  // Defaults
  const [
    defaultPayFrequencyOption,
    defaultFirstPeriodEnd,
    defaultFirstPayday,
    defaultSecondPayday,
    defaultWorkweekEndDayOption,
  ] = useMemo(() => {
    const freq =
      validPayScheduleFreqOptions.find((o) => o.value === checkPs.pay_frequency) ||
      validPayScheduleFreqOptions[0]!;

    const periodEnd = DateTime.fromISO(checkPs.first_period_end);

    const firstPay = DateTime.fromISO(checkPs.first_payday);

    let secondPay: DateTime | undefined;
    if (checkPs.second_payday) {
      secondPay = DateTime.fromISO(checkPs.second_payday);
    } else if (checkPs.pay_frequency === "semimonthly") {
      if (firstPay.day === 15) {
        secondPay = firstPay.endOf("month");
      } else {
        secondPay = firstPay.plus({ months: 1 }).set({ day: 15 });
      }
    }

    const workweekEnd =
      workweekEndDayOptions.find((o) => o.value === originalPaySchedule.workweek_end_day) ||
      workweekEndDayOptions[0]!;

    return [freq, periodEnd, firstPay, secondPay, workweekEnd];
  }, [originalPaySchedule, checkPs]);

  // State
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [label, setLabel] = useState(originalPaySchedule.label);
  const [active, setActive] = useState(!originalPaySchedule.inactive);
  const [selectedPayFrequencyOption, setSelectedPayFrequencyOption] = useState(defaultPayFrequencyOption);
  const [firstPeriodEnd, setFirstPeriodEnd] = useState<DateTime | undefined>(defaultFirstPeriodEnd);
  const [firstPayday, setFirstPayday] = useState<DateTime | undefined>(defaultFirstPayday);
  const [secondPayday, setSecondPayday] = useState<DateTime | undefined>(defaultSecondPayday);
  const [selectedWorkweekEndDayOption, setSelectedWorkweekEndDayOption] =
    useState(defaultWorkweekEndDayOption);
  const [createHistoricalPayrolls, setCreateHistoricalPayrolls] = useState(false);

  // Helpers
  const firstPeriodEndIso = firstPeriodEnd?.toISODate();
  const firstPaydayOn15OrLast = firstPayday?.day === 15 || firstPayday?.day === firstPayday?.daysInMonth;
  const firstPaydayIso = firstPayday?.toISODate();
  const secondPaydayIso = secondPayday?.toISODate() || null;
  const selectedPayFrequency = selectedPayFrequencyOption.value;
  const isSemimonthly = selectedPayFrequency === "semimonthly";

  const isNewPs = useMemo(() => {
    return (
      selectedPayFrequency !== checkPs.pay_frequency ||
      firstPeriodEndIso !== checkPs.first_period_end ||
      firstPaydayIso !== checkPs.first_payday ||
      (secondPaydayIso && checkPs.second_payday && secondPaydayIso !== checkPs.second_payday)
    );
  }, [selectedPayFrequency, firstPeriodEndIso, firstPaydayIso, secondPaydayIso, checkPs]);

  const onSave = async () => {
    if (!firstPayday) {
      form.setError("first_payday", { message: "Required." });
      return;
    }

    if (!firstPeriodEnd) {
      form.setError("first_period_end", { message: "Required." });
      return;
    }

    if (isSemimonthly) {
      if (firstPayday.day !== 15 && firstPayday.day !== firstPayday.daysInMonth && !secondPayday) {
        form.setError("second_payday", {
          message: "Must set second payday if first payday is not on 15th or last day of month.",
        });
        return;
      }
    } else if (isWeeklyOrBiweekly(selectedPayFrequency)) {
      if (firstPayday.weekday > 5) {
        form.setError("first_payday", { message: "Must be a weekday." });
        return;
      }
    }

    if (!label?.trim()) {
      form.setError("label", { message: "Required." });
      return;
    }

    // Check's logic is a little weird and if you set a second payday, even if it's on the last day of the month, it'll take it literally and always use that day (e.g., the 30th) instead of the last day. Only way to guarantee last day is to set the second payday to null.
    let finalSecondPaydayIso: string | null = secondPaydayIso;
    if (isSemimonthly && secondPayday) {
      if (firstPayday.day === 15 && secondPayday.day === secondPayday.daysInMonth) {
        finalSecondPaydayIso = null;
      } else if (secondPayday.day === 15 && firstPayday.day === firstPayday.daysInMonth) {
        finalSecondPaydayIso = null;
      }
    } else {
      finalSecondPaydayIso = null;
    }

    setSaving(true);
    const success = await updatePaySchedule({
      label,
      inactive: !active,
      workweek_end_day: isSemimonthly ? selectedWorkweekEndDayOption.value : firstPeriodEnd.weekday,
      pay_frequency: selectedPayFrequency,
      first_period_end: firstPeriodEndIso,
      first_payday: firstPaydayIso,
      second_payday: finalSecondPaydayIso,
      create_payrolls_from_start: createHistoricalPayrolls,
    });
    if (success) setEditing(false);
    setSaving(false);
  };

  const onCancel = () => {
    setEditing(false);
    setSelectedPayFrequencyOption(defaultPayFrequencyOption);
    setFirstPeriodEnd(defaultFirstPeriodEnd);
    setFirstPayday(defaultFirstPayday);
    setSecondPayday(defaultSecondPayday);
    setSelectedWorkweekEndDayOption(defaultWorkweekEndDayOption);
    setActive(!originalPaySchedule.inactive);
  };

  const handleFirstPaydayChange = (dt: DateTime) => {
    form.clearErrors("second_payday");
    setFirstPayday(dt);
  };

  const handlePayFrequencyChange = (option: Option<CheckPayFrequency>) => {
    form.clearErrors();
    setSelectedPayFrequencyOption(option);
  };

  return (
    <div>
      <div className="flex" style={{ marginTop: 10, marginBottom: 15 }}>
        <div className="flex-1"></div>
        {editing ? (
          <div className="flex">
            <Button text="Cancel" className="button-1" onClick={onCancel} />
            <Button text="Save" className="button-2" onClick={onSave} loading={saving} />
          </div>
        ) : (
          <Button text="Edit" className="button-1" onClick={() => setEditing(true)} />
        )}
      </div>
      <Formblock
        label="Name*"
        labelInfo="A human-friendly name used to identify the pay schedule"
        type="text"
        form={form}
        onChange={(e) => setLabel(e.target.value)}
        name="label"
        defaultValue={originalPaySchedule?.label}
        editing={editing}
      />
      <Formblock
        label="Pay frequency*"
        type="select"
        form={form}
        val={vals.required}
        options={validPayScheduleFreqOptions}
        isOptionDisabled={(o) => o.disabled}
        value={selectedPayFrequencyOption}
        onChange={handlePayFrequencyChange}
        name="pay_frequency"
        defaultValue={checkPs.pay_frequency}
        editing={editing}
      />
      <Formblock
        label="First period end*"
        type="datetime"
        dateOnly
        form={form}
        val={vals.required}
        name="first_period_end"
        defaultValue={defaultFirstPeriodEnd}
        onChange={setFirstPeriodEnd}
        editing={editing}
      />
      <Formblock
        label="First payday*"
        type="datetime"
        dateOnly
        form={form}
        val={vals.required}
        name="first_payday"
        defaultValue={defaultFirstPayday}
        onChange={handleFirstPaydayChange}
        editing={editing}
      />
      {isSemimonthly && (
        <>
          <Formblock
            label={`Second payday${firstPaydayOn15OrLast ? "" : "*"}`}
            labelInfo="Required if the first payday is not on the 15th or last day of the month"
            type="datetime"
            dateOnly
            form={form}
            val={firstPaydayOn15OrLast ? undefined : vals.required}
            name="second_payday"
            defaultValue={defaultSecondPayday}
            onChange={setSecondPayday}
            editing={editing}
          />
          <Formblock
            label="Workweek end*"
            labelInfo="For the purposes of calculating weekly OT, how the workweek should be defined"
            type="select"
            form={form}
            val={vals.required}
            options={workweekEndDayOptions}
            value={selectedWorkweekEndDayOption}
            defaultValue={originalPaySchedule.workweek_end_day || 7}
            onChange={setSelectedWorkweekEndDayOption}
            name="workweek_end_day"
            editing={editing}
          />
        </>
      )}
      <Formblock
        label="Active"
        labelInfo="If the pay schedule is inactive, no new payrolls will be created"
        type="checkbox"
        form={form}
        checked={active}
        defaultString={originalPaySchedule.inactive ? "False" : "True"}
        onChange={(e) => setActive(e.target.checked)}
        name="active"
        editing={editing}
      />
      {isNewPs && editing && (
        <div style={{ marginTop: 15 }}>
          <div className="yellow-text-container">
            {`You've made changes to this pay schedule.`}
            {selectedPayFrequency === "monthly" &&
              firstPeriodEnd &&
              firstPeriodEnd?.day === firstPeriodEnd?.daysInMonth &&
              firstPeriodEnd.day < 31 &&
              ` Please note: if you want this pay schedule to end on the last day of each month, choose a "first period end" in a month with 31 days.`}
            <div style={{ height: 10 }}></div>
            <Formblock
              type="checkbox"
              label="Create historical payrolls"
              labelInfo="Whether Miter should create historical payrolls starting from the first payday. If you don't select this, Miter will only create payrolls starting from the first period after today."
              underlineTooltip={true}
              form={form}
              onChange={() => setCreateHistoricalPayrolls(!createHistoricalPayrolls)}
              name="historical_payrolls"
              checked={createHistoricalPayrolls}
              editing
              labelStyle={{ width: 300, display: "flex" }}
              style={{ display: "flex", alignItems: "center" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
