import { AggregatedTimesheet, Timesheet } from "dashboard/miter";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";
import React from "react";

type TimesheetContextType = {
  timesheet?: AggregatedTimesheet;
  updateTimesheet: (update: Partial<Timesheet>) => Promise<boolean | void>;
  loading: boolean;
  getTimesheet: () => Promise<void>;
  changeTimesheetEditStatus: (status: boolean) => void;
  handleChange?: () => void;
  inboxMode?: InboxMode;

  // This property is used for daily reports to determine if the timesheet is editable
  // based on the daily report being approved or unapproved.
  readOnly?: boolean;
};

const TimesheetContext = React.createContext<TimesheetContextType>({
  updateTimesheet: async () => {},
  loading: false,
  getTimesheet: async () => {},
  changeTimesheetEditStatus: () => {},
  handleChange: () => {},
  readOnly: false,
  inboxMode: undefined,
});

export default TimesheetContext;
