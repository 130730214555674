import React from "react";
import styles from "../../not-found/NotFound.module.css";
import SignedIcon from "../../../assets/404.svg"; // You might want to replace this with a more appropriate icon

const OfferWasSigned: React.FC = () => {
  return (
    <div className={styles["not-found-container"]}>
      <div className={styles["not-found"]}>
        <img src={SignedIcon} alt="Offer Signed" />
        <h1>This offer has already been signed.</h1>
        <p style={{ width: "80vw" }} className={styles["subtitle"]}>
          Great news! The offer letter you&apos;re trying to access has already been signed. If you need any
          further information or have any questions, please contact the hiring manager or HR representative.
        </p>
      </div>
    </div>
  );
};

export default OfferWasSigned;
