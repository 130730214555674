import React from "react";
import styles from "./OptionCards.module.css";

export type OptionCardConfig<T extends string | string[]> = {
  value: T;
  header: string;
  subheader: string;
  style?: React.CSSProperties;
};

type Props<T extends string | string[]> = {
  options: OptionCardConfig<T>[];
  setSelectedValue: (val: T) => void;
  selectedValue: T;
  style?: React.CSSProperties;
};

export const OptionCards: <T extends string | string[]>(props: Props<T>) => JSX.Element = (props) => {
  const { options, setSelectedValue, selectedValue, style } = props;

  return (
    <div>
      {options.map((option) => {
        const className = option.value === selectedValue ? styles["selected"] : "";
        return (
          <div
            key={option.value.toString()}
            onClick={() => setSelectedValue(option.value)}
            className={styles["option_wrapper"] + " " + className}
            style={{ ...style, ...option.style }}
          >
            <div className={styles["option_header"]}>{option.header}</div>
            <div className={styles["option_subheader"]}>{option.subheader}</div>
          </div>
        );
      })}
    </div>
  );
};
