import React, { FC, LegacyRef, ReactElement, useRef } from "react";
import { Button } from "ui";

type Props = {
  label: ReactElement;
  className?: string;
  multiple: boolean;
  onChange: (files: FileList | null) => void;
};

const FileInput: FC<Props> = ({ label, className, multiple, onChange }) => {
  const fileInputRef = useRef<HTMLInputElement>();

  return (
    <>
      <input
        type="file"
        ref={fileInputRef as unknown as LegacyRef<HTMLInputElement>}
        hidden
        onChange={(e) => onChange(e.target.files)}
        multiple={multiple}
      />
      <Button className={className} onClick={() => fileInputRef?.current?.click()}>
        {label}
      </Button>
    </>
  );
};

export default FileInput;
