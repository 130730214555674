import { validateRoutingNum } from "dashboard/utils/flatfile";
import React, { useMemo } from "react";
import { ImportField, Importer } from "../importer/Importer";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { FlatfileResults } from "@flatfile/react";
import { Notifier } from "ui";
import { CreateOrUpdateVendorParams } from "backend/services/vendor-service";

type PrelimVendorImportRow = {
  name: string;
  description?: string;
  external_id?: string;

  // (Optional) bank account creation or update params
  bank_account_number?: string;
  bank_routing_number?: string;
};

type Props = {
  onFinish: () => void;
};

export const VendorImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/

  const activeCompanyId = useActiveCompanyId();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/

  const buildVendorParams = (row: PrelimVendorImportRow): CreateOrUpdateVendorParams => {
    if (!activeCompanyId) throw new Error("No active company ID");
    const { name, description, external_id, bank_account_number, bank_routing_number } = row;

    const output: CreateOrUpdateVendorParams = {
      company_id: activeCompanyId,
      name,
      description,
      external_id,

      // (Optional) bank account creation or update params
      bank_account_params:
        bank_account_number && bank_routing_number
          ? {
              account_number: bank_account_number,
              routing_number: bank_routing_number.padStart(9, "0"),
            }
          : undefined,
    };

    return output;
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedVendors = results.validData.map(buildVendorParams);

      const response = await MiterAPI.vendors.import({
        clean_inputs: preppedVendors,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successCount = response.results.successes.length;
      const errorCount = response.results.errors.length;
      const warningCount = response.results.warnings.length;

      if (successCount === 0) {
        Notifier.error(`Import failed. ${errorCount} errors and ${warningCount} warnings.`);
      } else if (errorCount > 0) {
        Notifier.error(
          `Imported ${successCount} vendors with ${errorCount} errors and ${warningCount} warnings.`
        );
      } else {
        Notifier.success(`Imported ${successCount} vendors with ${warningCount} warnings.`);
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the vendors.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/
  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Name",
        type: "string",
        key: "name",
        validators: [{ validate: "required" }],
      },
      {
        label: "Description",
        type: "string",
        key: "description",
      },
      {
        label: "External ID",
        type: "string",
        key: "external_id",
        description:
          "Optional. Add this if the same vendor exists is another system (ERP, etc) and both should be consistent.",
      },
      {
        label: "Account number",
        type: "string",
        key: "bank_account_number",
        validators: [
          {
            validate: "regex_matches",
            regex: "^[0-9]{4,17}$",
            error: "Please enter a number between 4 and 17 digits.",
          },
          {
            validate: "required_with",
            fields: ["bank_routing_number"],
          },
        ],
      },
      {
        label: "Routing number",
        key: "bank_routing_number",
        validators: [
          {
            validate: "required_with",
            fields: ["bank_account_number"],
          },
        ],
        hook: (row) =>
          typeof row === "string" ? validateRoutingNum(row) : validateRoutingNum(row.bank_routing_number),
      },
    ];

    return fieldList;
  }, []);

  return (
    <Importer id="vendors" resource="vendors" onSave={handleSubmit} fields={fields} title="Import vendors" />
  );
};
