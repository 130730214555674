import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompany, useSetActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard, TextWithTooltip } from "ui";

export const OvertimeSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const setActiveCompany = useSetActiveCompany();
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const defaultOtHourAllocation =
    activeCompany?.settings.payroll?.ot_configuration?.ot_hour_allocation || "in_effect";
  const defaultRateDeterminationMethod =
    activeCompany?.settings.payroll?.ot_configuration?.ot_rate_determination || "earning_rate";
  const defaultClassificationRatePreference =
    activeCompany?.settings.payroll?.ot_configuration?.classification_rate_preference || "calculated_rate";
  const [selectedAllocationMethod, setSelectedAllocationMethod] = useState(defaultOtHourAllocation);
  const [selectedRateDeterminationMethod, setSelectedRateDeterminationMethod] = useState(
    defaultRateDeterminationMethod
  );
  const [selectedClassificationRatePreference, setSelectedClassificationRatePreference] = useState(
    defaultClassificationRatePreference
  );

  const handleOtConfigurationChange = async ({
    field,
    value,
    silent,
  }: {
    field: "ot_hour_allocation" | "ot_rate_determination" | "classification_rate_preference";
    value: string;
    silent?: boolean;
  }) => {
    try {
      const key = `settings.payroll.ot_configuration.${field}`;
      const response = await MiterAPI.companies.update(activeCompanyId!, {
        $set: { [key]: value },
      });
      if (response.error) throw new Error(response.error);
      if (!silent) {
        Notifier.success("Overtime configuration updated successfully.");
      }
      fetchUserData();
      setActiveCompany(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your overtime configuration settings.");
    }
  };

  const handleAllocationMethodChange = async (e) => {
    setSelectedAllocationMethod(e.target.value);
    await handleOtConfigurationChange({ field: "ot_hour_allocation", value: e.target.value });
  };

  const handleRateDeterminationChange = async (e) => {
    setSelectedRateDeterminationMethod(e.target.value);
    await handleOtConfigurationChange({ field: "ot_rate_determination", value: e.target.value });
    // Reset classification rate preference regardless if rate determination has been changed
    if (selectedClassificationRatePreference !== "calculated_rate") {
      setSelectedClassificationRatePreference("calculated_rate");
      await handleOtConfigurationChange({
        field: "classification_rate_preference",
        value: "calculated_rate",
        silent: true,
      });
    }
  };

  const handleClassificationRatePreferenceChange = async (e) => {
    setSelectedClassificationRatePreference(e.target.value);
    await handleOtConfigurationChange({ field: "classification_rate_preference", value: e.target.value });
  };

  return (
    <SettingsCard title="Overtime" contentStyle={{ color: "rgb(51,51,51)" }}>
      <>
        <div>
          <TextWithTooltip
            id={"ot_hour_allocation"}
            text={"Overtime hour allocation"}
            tooltip={"How overtime hours should be allocated across the week"}
          />
          <div className="flex" style={{ marginTop: 10 }}>
            <input
              type="radio"
              style={{ margin: "0px 12px" }}
              id="in_effect"
              value="in_effect"
              onChange={handleAllocationMethodChange}
              checked={selectedAllocationMethod === "in_effect"}
            />
            <label>
              <span className="bold">In effect</span>: Overtime hours are only applied to time occurring after
              an overtime threshold has been met.
            </label>
          </div>
          <div className="flex" style={{ marginTop: 10 }}>
            <input
              type="radio"
              style={{ margin: "0px 12px" }}
              id="spread_evenly"
              value="spread_evenly"
              onChange={handleAllocationMethodChange}
              checked={selectedAllocationMethod === "spread_evenly"}
            />
            <label>
              <span className="bold">Spread evenly</span>: Overtime hours are allocated proportionally across
              all timesheets within the period.
            </label>
          </div>
        </div>
        <div>
          <div className="flex" style={{ marginTop: 20 }}></div>
          <TextWithTooltip
            id={"ot_rate_determination"}
            text={"Overtime rate determination"}
            tooltip={"How overtime pay rates should be calculated"}
          />
          <div className="flex" style={{ marginTop: 10 }}>
            <input
              type="radio"
              style={{ margin: "0px 12px" }}
              id="earning_rate"
              value="earning_rate"
              onChange={handleRateDeterminationChange}
              checked={selectedRateDeterminationMethod === "earning_rate"}
            />
            <label>
              <span className="bold">Earning rate</span>: Use the nominal pay rate of the earning (i.e., OT
              rate is 1.5x the REG rate).
            </label>
          </div>
          <div className="flex" style={{ marginTop: 10 }}>
            <input
              type="radio"
              style={{ margin: "0px 12px" }}
              id="weighted_average"
              value="weighted_average"
              onChange={handleRateDeterminationChange}
              checked={selectedRateDeterminationMethod === "weighted_average"}
            />
            <label>
              <span className="bold">Weighted average</span>: Calculate the regular rate of pay, and use that
              to calculate a weighted average for all timesheets.
            </label>
          </div>
        </div>
        {selectedRateDeterminationMethod === "weighted_average" && (
          <div>
            <div className="flex" style={{ marginTop: 20 }}>
              <TextWithTooltip
                id={"classification_rate_preference"}
                text={"Classification rate preference"}
                tooltip={
                  "Using weighted average overtime rates can cause the overtime rate for earnings tagged with classifications to change. This can affect compliance with prevailing wage or union rates."
                }
              />
            </div>
            <div className="flex" style={{ marginTop: 10 }}>
              <input
                type="radio"
                style={{ margin: "0px 12px" }}
                id="calculated_rate"
                value="calculated_rate"
                onChange={handleClassificationRatePreferenceChange}
                checked={selectedClassificationRatePreference === "calculated_rate"}
              />
              <label>
                <span className="bold">Calculated rate</span>: Always use the calculated weighted average
                overtime rate, even if the classification rate is different.
              </label>
            </div>
            <div className="flex" style={{ marginTop: 10 }}>
              <input
                type="radio"
                style={{ margin: "0px 12px" }}
                id="higher_rate"
                value="higher_rate"
                onChange={handleClassificationRatePreferenceChange}
                checked={selectedClassificationRatePreference === "higher_rate"}
              />
              <label>
                <span className="bold">Higher rate</span>: Use the higher of the calculated and
                classification-specified overtime rates.
              </label>
            </div>
          </div>
        )}
      </>
    </SettingsCard>
  );
};
