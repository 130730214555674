import { ChecklistTaskItem } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { Option } from "packages/ui/form/Input";
import { TeamMemberGroup } from "dashboard/miter";
import {
  TeamMemberGroupSelectValue,
  TeamMemberOptionGroup,
  useTeamMemberGroupOptions,
} from "../useTeamMemberGroupOptions";
import { notNullish } from "miter-utils";
import { useMemo } from "react";
import { TeamMemberOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

type AssigneeFormProps<T extends TeamMemberOnboardingTaskForm> = {
  task: ChecklistTaskItem<T>;
  setTask: (task: ChecklistTaskItem<T>) => void;
};

export const AssigneeForm = <T extends TeamMemberOnboardingTaskForm>({
  task,
  setTask,
}: AssigneeFormProps<T>): JSX.Element => {
  const teamMemberGroupOptions = useTeamMemberGroupOptions({
    excludedTypes: ["self"],
    labelOverrides: { self: "New Hire", direct_manager: "Direct Manager" },
  });

  const teamMemberOptions = useMemo(
    () => flattenTeamMemberOptions(teamMemberGroupOptions),
    [teamMemberGroupOptions]
  );

  const defaultAssignee = useMemo(
    () => getDefaultAssignee(task.data.assignee || [], teamMemberOptions),
    [task.data.assignee, teamMemberOptions]
  );

  /** Handler to update the task when due changes */
  const handleAssigneeChange = (assignee: Option<TeamMemberGroup>[] | null) => {
    const assigneeGroups = assignee?.map((option) => option.value!) || [];
    setTask({ ...task, data: { ...task.data, assignee: assigneeGroups } });
  };

  return (
    <div>
      <div className="vertical-spacer-small" />
      <Formblock
        type="multiselect"
        name="assignee"
        label="Assignee(s)"
        labelInfo="Select assignee(s). This admin task will be assigned to the selected assignee(s) and show up in their Needs Attention inbox."
        editing={true}
        className="modal"
        placeholder="Select assignee groups"
        options={teamMemberGroupOptions}
        height="unset"
        onChange={(options) => handleAssigneeChange(options)}
        value={defaultAssignee}
      />
    </div>
  );
};

const flattenTeamMemberOptions = (groupOptions: TeamMemberOptionGroup[]) => {
  return groupOptions.flatMap((group) => group.options).filter((o) => o.value.type !== "self");
};

const getDefaultAssignee = (
  assigneeGroups: TeamMemberGroup[],
  teamMemberOptions: Option<TeamMemberGroupSelectValue>[]
) => {
  return assigneeGroups
    .map((group) => {
      return teamMemberOptions.find((tm) => tm.value?.type === group.type && tm.value?.value === group.value);
    })
    .filter(notNullish);
};
