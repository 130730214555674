import {
  AggregatedJob,
  AggregatedTeamMember,
  Job,
  PermissionGroup,
  PermissionPaths,
  TeamMember,
} from "dashboard/miter";
import { useCallback } from "react";
import { Notifier } from "ui";
import { useHydratedPermissionGroups, useIsSuperAdmin } from "../atom-hooks";
import { PermissionGroupModuleScopeType } from "backend/models/permission-group";
import { AggregatedJobPosting, JobPosting } from "dashboard/types/ats";
import { has } from "lodash";

type CanOptions = {
  throwError?: boolean;
  showToast?: boolean;
  message?: string;
  teamMember?: string | AggregatedTeamMember | TeamMember | null | undefined;
  job?: string | AggregatedJob | Job | null | undefined;

  /** If true, this can is being called in the teamPredicate or jobPredicate */
  isPredicate?: boolean;

  moduleParams?: {
    recruiting?: {
      jobPosting?: string | JobPosting | AggregatedJobPosting | null | undefined;
    };
  };
};

type CannotOptions = CanOptions;

/**
 * Hook that returns the abilities for the current user
 * - Can/Cannot must be regular functions so abilities can keep the this
 * @returns {object} - Object containing the can and cannot functions, as well as a boolean indicating whether the abilities have been fetched yet
 */
export const useMiterAbilities = (): {
  /**  Pass in a permission path (i.e. "jobs:create") and it will return true/false if the user can/cannot do that action */
  can: (action: PermissionPaths, opts?: CanOptions) => boolean;
  /** Pass in a permission path (i.e. "jobs:create") and it will return true/false if the user can/cannot do that action */
  cannot: (action: PermissionPaths, opts?: CannotOptions) => boolean;
} => {
  const hydratedPermissionGroups = useHydratedPermissionGroups();
  const isSuperAdmin = useIsSuperAdmin();

  const can = useCallback(
    function (action: PermissionPaths, opts?: CanOptions): boolean {
      if (!action) return false;

      // If the user is a super admin, they can do anything
      if (isSuperAdmin) return true;

      const { isPredicate } = opts || {};
      const teamMemberId = getTeamMemberId(opts);
      const jobId = getJobId(opts);
      const jobPostingId = getJobPostingId(opts);

      const hasTeamMemberOption = opts && "teamMember" in opts;
      const hasJobOption = opts && "job" in opts;
      const hasJobPostingOption = has(opts, "moduleParams.recruiting.jobPosting");

      const accessible = hydratedPermissionGroups.some((group) => {
        // Make sure the user has the permission
        const hasBasePermission = group.abilities.can(action);
        if (!hasBasePermission) return false;

        /**
         * If scopes are not provided, then we can just return the base permission
         *
         * Ex. can("jobs:create") should return true if the user has the base permission
         * used for places like routes.tsx where we don't have a team member or job to check
         */
        const shouldCheckScope = hasTeamMemberOption || hasJobOption || hasJobPostingOption;
        if (!shouldCheckScope) return hasBasePermission;

        // If there the module doesn't have scopes, then we can just return the base permission
        const scopedModule = lookupScopedModule(action);
        if (!scopedModule) return hasBasePermission;

        // Check what type of scopes the permission group has
        const isTeamScopedPermissionGroup = isTeamMemberScoped(group.permissionGroup);
        const isJobScopedPermissionGroup = isJobScoped(group.permissionGroup);
        const isJobPostingScopedPermissionGroup = isJobPostingScoped(group.permissionGroup);

        // If there are no scopes, then we can just return the base permission
        if (
          !isTeamScopedPermissionGroup &&
          !isJobScopedPermissionGroup &&
          !isJobPostingScopedPermissionGroup
        ) {
          return hasBasePermission;
        }

        /**
         * If can is being used in a predicate, we check which type of item is being passed in
         * - If a team member is passed in, this is a teamPredicate
         * - If a job is passed in, this is a jobPredicate
         *
         * If this is a team/job predicate, we should check if the user has scoped access to the team member/job
         * regardless of the type of scopes the permission group has because we will have implicit
         * access to the team member/job in the dropdown regardless of the type of scopes the permission group has.
         *
         * For example, let say:
         * - The permission group says the user can access data for jobs they manage (i.e. job scoped)
         * - The permission groups says they can create/view timesheets
         *
         * This means that the user can create timesheets for any team member as long as the job on the timesheet is
         * a job they manage.
         *
         * The teamPredicate used for showing which team members are available to create timesheets for should
         * show all team members because technically they can create timesheets for any team member, even though
         * they can only view data for jobs they manage.
         *
         * If we made sure the permission group was team scoped before checking the scoped access, then the
         * teamPredicate would not show any team members because the permission group is job scoped
         */
        if (isPredicate) {
          if (hasTeamMemberOption) {
            return group.hasScopedTeamMember(scopedModule, teamMemberId);
          }

          if (hasJobOption) {
            return group.hasScopedJob(scopedModule, jobId);
          }

          /**
           * If this isn't being used in a predicate, we should check, based on the type of scopes the permission group has,
           * if the user has access to the scoped item (team member or job).
           *
           * We also check for hasTeamMemberOption and hasJobOption because we need a way to skip a check if the module
           * doesn't have the requisite scopes.
           *
           * For example, let say:
           * - The permission group says that the user can manage miter cards
           * - The permission group is scoped to the user's department
           * - Miter cards are scoped by team members and not jobs
           *
           * In this situation, we need to make sure that even if the permission group is scoped by jobs, we don't
           * try to check if the user has access to the job because the miter card is scoped by team members. This is why
           * we check if a job is passed in (hasJobOption) before checking if the user has access to the job (l.hasScopedJob)
           */
          return hasBasePermission;
        } else {
          let globalScopedAccess = true;
          let moduleScopedAccess = true;

          if (isTeamScopedPermissionGroup && hasTeamMemberOption) {
            globalScopedAccess = group.hasScopedTeamMember(scopedModule, teamMemberId);
          }

          if (isJobScopedPermissionGroup && hasJobOption) {
            globalScopedAccess = group.hasScopedJob(scopedModule, jobId);
          }

          if (isJobPostingScopedPermissionGroup && hasJobPostingOption) {
            moduleScopedAccess =
              moduleScopedAccess && group.moduleHelpers.recruiting.hasScopedJobPosting(jobPostingId);
          }

          return globalScopedAccess && moduleScopedAccess && hasBasePermission;
        }
      });

      if (!accessible && opts?.throwError) {
        throw new Error(opts.message || "Permission denied!");
      }
      if (!accessible && opts?.showToast) {
        Notifier.error(opts.message || "Permission denied!");
      }

      return accessible;
    },
    [hydratedPermissionGroups, isSuperAdmin]
  );

  const cannot = useCallback(
    function (action: PermissionPaths, opts?: CannotOptions): boolean {
      return !can(action, opts);
    },
    [can]
  );

  return { can, cannot };
};

const getTeamMemberId = (opts?: CanOptions): string | undefined => {
  return opts?.teamMember
    ? typeof opts.teamMember === "string"
      ? opts.teamMember
      : opts.teamMember._id
    : undefined;
};

const getJobId = (opts?: CanOptions): string | undefined => {
  return opts?.job ? (typeof opts.job === "string" ? opts.job : opts.job._id) : undefined;
};

const getJobPostingId = (opts?: CanOptions): string | undefined => {
  const jobPosting = opts?.moduleParams?.recruiting?.jobPosting;
  return jobPosting ? (typeof jobPosting === "string" ? jobPosting : jobPosting._id) : undefined;
};

export const HR_MODULE_PERMISSIONS = new Set(["time_off", "performance", "team", "recruiting", "documents"]);

export const PAYROLL_MODULE_PERMISSIONS = new Set([
  "payrolls",
  "benefits",
  "post_tax_deductions",
  "allowances",
]);

export const EXPENSE_MODULE_PERMISSIONS = new Set([
  "expenses",
  "card_transactions",
  "reimbursements",
  "miter_cards",
  "third_party_cards",
]);

export const WFM_MODULE_PERMISSIONS = new Set(["timesheets", "assignments", "daily_reports", "jobs"]);

export const COMPANY_MODULE_PERMISSIONS = new Set(["forms", "chat"]);

const lookupScopedModule = (action: PermissionPaths): PermissionGroupModuleScopeType | null => {
  if (!action) return null;

  const subModule = action.split(":")[0];

  if (!subModule) return null;

  if (HR_MODULE_PERMISSIONS.has(subModule)) return "human_resources";
  if (PAYROLL_MODULE_PERMISSIONS.has(subModule)) return "payroll_and_compliance";
  if (EXPENSE_MODULE_PERMISSIONS.has(subModule)) return "expense_management";
  if (WFM_MODULE_PERMISSIONS.has(subModule)) return "workforce_management";
  if (COMPANY_MODULE_PERMISSIONS.has(subModule)) return "company";

  return null;
};

/** Check if the permission group has team member scopes */
const isTeamMemberScoped = (permissionGroup: PermissionGroup): boolean => {
  return !!permissionGroup.scopes?.global?.team_member?.length;
};

/** Check if the permission group has job scopes */
const isJobScoped = (permissionGroup: PermissionGroup): boolean => {
  return !!permissionGroup.scopes?.global?.job?.length;
};

/** Check if the permission group has job posting scopes */
const isJobPostingScoped = (permissionGroup: PermissionGroup): boolean => {
  return !!permissionGroup.scopes?.modules?.recruiting?.job_postings?.length;
};
