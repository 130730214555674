import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import JobsTable from "dashboard/components/tables/JobsTable";
import { Helmet } from "react-helmet";
import { DropdownButton } from "ui";
import { ActionLink } from "ui/action-menu/ActionMenu";
import { useActiveCompany, useActiveJobs } from "dashboard/hooks/atom-hooks";
import Banner from "dashboard/components/shared/Banner";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { isEmptyAddress } from "dashboard/utils";

const Jobs: React.FC = () => {
  const activeCompany = useActiveCompany();
  const multiWorkplacesEnabled = !!activeCompany?.settings.payroll.multi_workplace_payrolls_enabled;
  const navigate = useNavigate();
  const activeJobs = useActiveJobs();

  const { can } = useMiterAbilities();

  const jobsWithAddressesButNoWorkplaces = useMemo(() => {
    if (!multiWorkplacesEnabled) return 0;
    return activeJobs.reduce((acc, job) => {
      if (!isEmptyAddress(job.address) && !job.workplace_id && !job.ignore_workplace) return acc + 1;
      return acc;
    }, 0);
  }, [multiWorkplacesEnabled, activeJobs]);

  const dropdownItems: ActionLink[] = [
    {
      label: "Settings",
      action: () => navigate("/jobs/settings"),
      shouldShow: () => can("jobs:settings"),
    },
    {
      label: "Customers",
      action: () => navigate("/customers"),
      shouldShow: () => can("lists:customers:read"),
    },
  ];

  return (
    <div className="page-content">
      <Helmet>
        <title>Jobs | Miter</title>
      </Helmet>
      <div className="page-content-header flex">
        <h1>Jobs</h1>
        <div className="flex-1"></div>
        <DropdownButton className="button-1 no-margin" label="Options" options={dropdownItems} />
      </div>
      {!!jobsWithAddressesButNoWorkplaces && (
        <Banner
          content={`There ${
            jobsWithAddressesButNoWorkplaces == 1
              ? "is 1 active job"
              : "are " + jobsWithAddressesButNoWorkplaces + " active jobs"
          } with an address but no tax jurisdiction. See the "tax jurisdiction" column in the table below (hidden by default).`}
          style={{ marginBottom: -10, marginTop: 10 }}
          type="warning"
        />
      )}
      <JobsTable />
    </div>
  );
};

export default Jobs;
