import {
  useActiveCompany,
  useActiveRecruitingConversation,
  usePaginatedRecruitingConversations,
  useRecruitingChatInitialized,
} from "dashboard/hooks/atom-hooks";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Chat.module.css";
import { Loader } from "ui";
import EmptyConversations from "../../../assets/empty-conversations.svg";
import { AggregatedRecruitingConversation } from "dashboard/types/chat";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaPlus } from "react-icons/fa";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { RecruitingCreateConversationModal } from "./RecruitingCreateConversationModal";
import { useDebounce } from "use-debounce";
import { CHAT_DEBOUNCE } from "dashboard/utils/chat";
import { ConversationItem } from "../ConversationItem";
import { useRecruitingChatUtilities } from "dashboard/hooks/chat/useRecruitingChatUtilities";

export const RecruitingConversationList: FC = () => {
  /** Atom hooks */
  const activeRecruitingConversation = useActiveRecruitingConversation();
  const recruitingConversations = usePaginatedRecruitingConversations();
  const isRecruitingChatInitialized = useRecruitingChatInitialized();
  const activeCompany = useActiveCompany();

  /** General hooks */
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [debouncedSearch] = useDebounce(search, CHAT_DEBOUNCE);
  const { fetchMoreConversations, hasNextPage, markConversationAsRead } = useRecruitingChatUtilities({
    recruitingSearch: debouncedSearch,
  });
  const miterAbilities = useMiterAbilities();

  /** Helper state */
  const hasChat = !!activeCompany?.candidate_chat?.conversations_service_sid;
  const [showCreateConversationModal, setShowCreateConversationModal] = useState(false);

  /** Logical helpers */

  const handleClick = (conversation: AggregatedRecruitingConversation) => {
    markConversationAsRead(conversation);
    navigate("/recruiting/chat/" + conversation._id);
  };

  const handleOpenCreateConversationModal = () => {
    setShowCreateConversationModal(true);
  };

  const handleCloseCreateConversationModal = () => {
    setShowCreateConversationModal(false);
  };

  /** Render functions */

  const renderConversationSearchAndCreate = () => {
    return (
      <div className={styles["conversation-search-and-add-container"]}>
        <input
          className={"form2-text " + styles["conversations-search"]}
          type="text"
          placeholder="Search conversations"
          onChange={(e) => setSearch(e.target.value)}
        />
        {miterAbilities.can("chat:send") && (
          <button
            className={"button-2 no-margin " + styles["new-chat-button"]}
            onClick={handleOpenCreateConversationModal}
          >
            {<FaPlus style={{ marginTop: 3 }} />}
          </button>
        )}
      </div>
    );
  };

  const renderConversations = () => {
    if (!isRecruitingChatInitialized && hasChat) {
      return (
        <div className={styles["no-conversations"]}>
          <Loader />
        </div>
      );
    }

    if (recruitingConversations.length === 0) {
      return (
        <div className={styles["no-conversations"]}>
          <img src={EmptyConversations} alt="No conversations" />
          <p>No conversations found.</p>
        </div>
      );
    }

    return (
      <div>
        <div id="scrollable" style={{ height: 800 }}>
          <InfiniteScroll
            dataLength={recruitingConversations.length}
            next={fetchMoreConversations}
            hasMore={hasNextPage}
            loader={<Loader />}
            height={800}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {recruitingConversations.map((conversation) => {
              const id = conversation._id;
              const unread = conversation.unread;
              const lastMessage =
                conversation.last_message?.body || conversation.last_message?.media?.filename || "";
              const dateCreated =
                conversation.last_message?.dateCreated || conversation.twilio_conversation?.dateCreated;
              const title = `${conversation.primary_candidate_name}${
                conversation.candidate_names.length > 1
                  ? ` (+${conversation.candidate_names.length - 1})`
                  : ""
              }`;
              const active = conversation._id === activeRecruitingConversation?._id;
              return (
                <ConversationItem
                  key={id}
                  id={id}
                  lastMessage={lastMessage}
                  dateCreated={dateCreated}
                  name={title}
                  unread={unread}
                  active={active}
                  handleClick={() => handleClick(conversation)}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles["conversation-list-container"]}>
        <div className={styles["chat-subheader"] + " " + styles["conversations-list-subheader"]}>
          <h2 className={styles["chat-subheader-title"]}>Conversations</h2>
          {renderConversationSearchAndCreate()}
        </div>
        <div className={styles["conversations-list"]}>{renderConversations()}</div>
        <div className="chat-list-footer" />
      </div>

      {showCreateConversationModal && miterAbilities.can("chat:send") && (
        <RecruitingCreateConversationModal onExit={handleCloseCreateConversationModal} />
      )}
    </div>
  );
};
