import { FC, useEffect, useState } from "react";
import { Wizard } from "ui";
import { OfferLetter } from "dashboard/types/offers-types";
import { OfferLetterBasicInformatioWizardScreen } from "./OfferLetterBasicInformationWizardScreen";
import { OfferLetterSmartFieldWizardScreen } from "./OfferLetterSmartFieldWizardScreen";
import { OfferLetterPreviewWizardScreen } from "./OfferLetterPreviewWizardScreen";
import { OfferLetterEmailWizardScreen } from "./OfferLetterEmailWizardScreen";

export type PartialOfferLetter = Partial<OfferLetter>;
export type CandidateInfo = {
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_email: string;
  id: string;
};
type Props = {
  offerLetter?: PartialOfferLetter;
  onExit: () => void;
  onComplete: () => void;
  candidateInfo: CandidateInfo;
  jobApplicationId: string;
};

export const OfferLetterWizard: FC<Props> = ({
  offerLetter,
  onExit,
  onComplete,
  candidateInfo,
  jobApplicationId,
}) => {
  const [activeOfferLetter, setActiveOfferLetter] = useState<PartialOfferLetter | undefined>(offerLetter);

  useEffect(() => {
    setActiveOfferLetter(offerLetter);
  }, [offerLetter]);

  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      <OfferLetterBasicInformatioWizardScreen
        offerLetter={activeOfferLetter}
        name="Basic information"
        setOfferLetter={setActiveOfferLetter}
      />
      <OfferLetterSmartFieldWizardScreen
        offerLetter={activeOfferLetter}
        name="Offer details"
        setOfferLetter={setActiveOfferLetter}
      />
      <OfferLetterPreviewWizardScreen
        offerLetter={activeOfferLetter}
        name="Preview offer"
        candidateInfo={candidateInfo}
      />
      <OfferLetterEmailWizardScreen
        offerLetter={activeOfferLetter}
        name="Email offer"
        candidateInfo={candidateInfo}
        jobApplicationId={jobApplicationId}
      />
    </Wizard>
  );
};
