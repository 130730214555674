import React from "react";
import Banner from "dashboard/components/shared/Banner";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { ACHFailureReason, isPlaidAccountVerified, isPlaidBankAccount } from "../expenseUtils";
import { AggregatedExpenseReimbursement, BankAccount, PlaidBankAccount } from "dashboard/miter";
import { usdString } from "ui";
import { capitalize } from "lodash";

type ACHReimbursementBannerProps = {
  canSubmitForReimbursement: boolean;
  expenseReimbursementRequest?: AggregatedExpenseReimbursement;
  companyBankAccount?: PlaidBankAccount;
  teamMemberBankAccount?: BankAccount;
  achFailureReason?: ACHFailureReason;
  loading: boolean;
  bannerStyle?: React.CSSProperties;
};

export const ACHReimbursementBanner: React.FC<ACHReimbursementBannerProps> = ({
  canSubmitForReimbursement,
  expenseReimbursementRequest,
  companyBankAccount,
  teamMemberBankAccount,
  achFailureReason,
  loading,
  bannerStyle,
}) => {
  const activeCompany = useActiveCompany();
  if (
    !expenseReimbursementRequest ||
    expenseReimbursementRequest.payout_method !== "ach" ||
    ["approved", "failed"].includes(expenseReimbursementRequest?.status) === false
  )
    return <></>;

  const { team_member, amount, status } = expenseReimbursementRequest;
  const isApproved = status === "approved";

  let bannerText;
  if (isApproved) {
    if (canSubmitForReimbursement) {
      bannerText = `${usdString(amount)} will be sent from company account ending in ${
        companyBankAccount!.account_number_last_4
      } to ${team_member?.full_name}'s account ending in ${teamMemberBankAccount!.account_number_last_4}.`;
    } else {
      // if company hasn't set up a bank account yet, default to that banner text. doesn't matter if team member hasn't set it up either
      if (!companyBankAccount) {
        bannerText = `${activeCompany?.check_company.trade_name} has not set up a bank account for reimbursements yet. Add a new account or configure an existing one via Expenses -> Settings.`;
      } else if (!teamMemberBankAccount) {
        bannerText = `${team_member?.full_name} has not set up a bank account for reimbursements yet.`;
      } else if (
        isPlaidBankAccount(teamMemberBankAccount) &&
        isPlaidAccountVerified(teamMemberBankAccount.external_raw_data) === false
      ) {
        bannerText = `${team_member?.full_name}'s bank account ending in ${teamMemberBankAccount.account_number_last_4} has not been verified yet.`;
      }
    }
  } else {
    // failed
    if (achFailureReason) {
      const { direction, failureReason, bankAccountLast4, achStatus } = achFailureReason;

      bannerText = `${capitalize(direction)} ACH transfer ${
        direction === "inbound" ? "from" : "to"
      } bank account ending in ${bankAccountLast4} ${achStatus}. ${
        failureReason ? `Reason: ${failureReason}` : ""
      }`;
    }
  }

  return (
    <Banner
      type={isApproved ? (canSubmitForReimbursement ? "info" : "warning") : "error"}
      style={bannerStyle}
      loading={loading}
    >
      <span>{bannerText}</span>
    </Banner>
  );
};
