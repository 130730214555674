import { X } from "phosphor-react";
import React from "react";
import { Formblock, Button } from "ui";
import { Option } from "ui/form/Input";
import { usePayTypeArray } from "dashboard/hooks/integrations/useMappingOption";
import { TMPayType } from "backend/models/teamMember/team-member";

interface IntegrationPayTypeConfigProps {
  payTypeMapping?: Record<string, TMPayType | "default">;
  savePayTypeCallback: (newPayTypeMap: Record<string, TMPayType>) => Promise<void>;
  payTypeOptions: Option<string>[];
}

export const IntegrationPayTypeConfig: React.FC<IntegrationPayTypeConfigProps> = ({
  payTypeMapping,
  savePayTypeCallback,
  payTypeOptions,
}) => {
  const {
    currentPayTypeArray,
    handleAddPayTypeMapping,
    handleChangeDesignation,
    handleChangePayType,
    handleDeletePayTypeMapping,
    loading,
    handlePayTypeMapSave,
  } = usePayTypeArray(payTypeMapping);

  return (
    <div style={{ maxWidth: 600 }}>
      {currentPayTypeArray.map(({ designation, payType }, index) => {
        return (
          <div className="flex align-items-center" key={index}>
            <Formblock
              className={"modal width-100-percent"}
              placeholder={`Time Type`}
              type="text"
              name={`timeType-${index}-${designation}`}
              value={designation}
              editing={true}
              onChange={(e) => handleChangeDesignation(index, e.target.value?.trim())}
            />
            <Formblock
              className={"modal width-100-percent"}
              placeholder={`Earning type`}
              type="select"
              options={payTypeOptions}
              value={payTypeOptions.find((b) => b.value === payType)}
              editing={true}
              onChange={(o) => handleChangePayType(index, o.value)}
              style={{ marginLeft: 10 }}
            />

            <div>
              <Button className="button-text" onClick={() => handleDeletePayTypeMapping(index)}>
                <X style={{ marginLeft: 10, marginTop: -10 }} />
              </Button>
            </div>
          </div>
        );
      })}
      {!currentPayTypeArray.length && (
        <div style={{ marginBottom: 25, opacity: 0.7 }}>No options selected</div>
      )}

      <div className="flex" style={{ marginTop: 20 }}>
        <Button className="button-1" text="Add option" onClick={() => handleAddPayTypeMapping()} />
        <Button
          className="button-2 no-margin"
          text="Save changes"
          onClick={() => handlePayTypeMapSave(savePayTypeCallback)}
          loading={loading}
        />
      </div>
    </div>
  );
};
