import { useUserAtom } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BasicModal, Formblock, Notifier } from "ui";

type Props = {
  onExit: () => void;
};

export const AckPerformanceOnboardingModal: FC<Props> = ({ onExit }) => {
  const [acknowledged, setAcknowledged] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useUserAtom();

  const acknowledge = async () => {
    if (!user || user?.acknowledgements.includes("acknowledge-performance-onboarding")) return;
    try {
      const acknowledgements = user.acknowledgements.slice();
      acknowledgements.push("acknowledge-performance-onboarding");
      const response = await MiterAPI.users.update(user._id.toString(), { acknowledgements });
      if (response.error) throw new Error(response.error);
      setUser(response);
    } catch (e) {
      Notifier.error("There was an error in the performance onboarding process.");
    }
  };
  const onSubmit = async () => {
    if (acknowledged) {
      await acknowledge();
    }
    navigate("/performance/review-schedules");
  };

  return (
    <BasicModal
      button2Text="Continue"
      onHide={onExit}
      button2Action={onSubmit}
      headerText="Start using Performance"
    >
      <div style={{ display: "flex" }}>
        <Formblock
          type="checkbox"
          className="modal time-off-request-date"
          sideBySideInput={false}
          editing={true}
          onChange={() => setAcknowledged(!acknowledged)}
        />
        <div
          style={{
            marginLeft: 15,
          }}
        >
          {`Do not show this onboarding set up any longer.`}
        </div>
      </div>
    </BasicModal>
  );
};
