import React, { PropsWithChildren } from "react";
import { MiterIntegrationForCompany } from "dashboard/miter";
import { MiterGuidesIntegrationLink } from "./MiterGuidesIntegrationLink";
import { TurnOffAdAndPopUpBlockersLink } from "./TurnOffAdAndPopUpBlockersLink";

interface AuthenticateIntegrationWrapperProps extends PropsWithChildren {
  integration: MiterIntegrationForCompany;
  showPopupBlockerWarning?: boolean;
  includeChildrenInSameContainer?: boolean;
}

export const AuthenticateIntegrationWrapper: React.FC<AuthenticateIntegrationWrapperProps> = ({
  children,
  integration,
  showPopupBlockerWarning,
  includeChildrenInSameContainer,
}) => {
  return (
    <div style={{ maxWidth: 500 }}>
      <div className="vertical-spacer"></div>
      <div style={{ marginBottom: "10px" }} className="yellow-text-container">
        {integration.connection?.pending_setup && <>This integration is not yet set up.</>}
        <MiterGuidesIntegrationLink integrationLabel={integration.label} integrationKey={integration.key} />
        {showPopupBlockerWarning && (
          <>
            <div className="vertical-spacer-small"></div>
            <TurnOffAdAndPopUpBlockersLink />
          </>
        )}
        {includeChildrenInSameContainer ? children : null}
      </div>
      {includeChildrenInSameContainer ? null : children}
    </div>
  );
};
