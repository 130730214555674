import React, { ReactElement } from "react";
import qmark from "dashboard/assets/gray-question-mark.png";
import yellowWarning from "dashboard/assets/yellow-warning.png";
import check from "dashboard/assets/check-mark.png";
import { IconWithTooltip, ImageWithTooltip, TextWithTooltip } from "ui";
import {
  determineGeofenceStatusOfLiveTimesheet,
  determineGeofenceStatusOfTimesheet,
} from "dashboard/utils/geolocation";
import { AggregatedLiveTimesheet, AggregatedTimesheet } from "dashboard/miter";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

export const TimesheetGpsComponent: React.FC<{ ts: AggregatedTimesheet }> = ({ ts }) => {
  const company = useActiveCompany();
  const radiusMiles = company?.settings.timesheets.geofence_radius || 0.5;
  const geofenceStatus = determineGeofenceStatusOfTimesheet(ts, radiusMiles);

  return (
    <div className="flex">
      {geofenceStatus === "missing_job" && (
        <TextWithTooltip
          text="N/A"
          tooltip="This timesheet does not have an associated job."
          id={ts._id.toString()}
        />
      )}
      {geofenceStatus === "n/a" && (
        <TextWithTooltip text="N/A" tooltip="This timesheet was created manually." id={ts._id.toString()} />
      )}
      {geofenceStatus === "missing_job_geolocation" && (
        <ImageWithTooltip
          src={qmark}
          height={15}
          width={15}
          tooltip={"This timesheet's job is missing geolocation data."}
        />
      )}
      {geofenceStatus === "missing_timesheet_geolocation" && (
        <ImageWithTooltip
          src={qmark}
          height={15}
          width={15}
          tooltip={"This timesheet is missing geolocation data."}
        />
      )}
      {geofenceStatus === "outside_fence" && (
        <ImageWithTooltip
          src={yellowWarning}
          height={17}
          width={17}
          tooltip={
            "This timesheet's clock in or clock out was over " + radiusMiles + " mile(s) from the jobsite."
          }
        />
      )}
      {geofenceStatus === "within_fence" && (
        <ImageWithTooltip
          src={check}
          height={15}
          width={15}
          tooltip={"Clock in and clock out were within  " + radiusMiles + " mile(s) of the jobsite."}
        />
      )}
    </div>
  );
};

export const liveTimesheetLocation = (
  liveTimesheet: AggregatedLiveTimesheet,
  radiusMiles?: number
): ReactElement => {
  const geofenceStatus = determineGeofenceStatusOfLiveTimesheet(liveTimesheet, radiusMiles);
  switch (geofenceStatus) {
    case "missing_timesheet_geolocation":
      return (
        <ImageWithTooltip
          src={qmark}
          height={15}
          width={15}
          tooltip={"This timesheet is missing geolocation data."}
        />
      );
    case "within_fence":
      return (
        <ImageWithTooltip
          src={check}
          height={15}
          width={15}
          tooltip={"Most recent location is within " + radiusMiles + " mile(s) of the jobsite."}
        />
      );
    case "outside_fence":
      return (
        <ImageWithTooltip
          src={yellowWarning}
          height={17}
          width={17}
          tooltip={"Most recent location is over " + radiusMiles + " mile(s) from the jobsite."}
        />
      );
    default:
      return <IconWithTooltip Icon={FaMapMarkerAlt} tooltip={"View current location."} place="left" />;
  }
};
