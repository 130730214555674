import { TimesheetSettings } from "dashboard/pages/timesheets/TimesheetSettings/TimesheetSettings";
import { AutomaticBreakTimeSettings, TimesheetPolicyConfig } from "dashboard/miter";
import { AutomaticBreakTime } from "dashboard/pages/timesheets/TimesheetSettings/AutomaticBreakTime";

type TimesheetPolicySettingsCardProps = {
  automaticBreakTimeSettings: AutomaticBreakTimeSettings | null;
  timesheetSettings: TimesheetSettings;
  setTimesheetPolicyConfig: (config: Partial<TimesheetPolicyConfig>) => void;
};

export const TimesheetPolicySettingsCard: React.FC<TimesheetPolicySettingsCardProps> = ({
  automaticBreakTimeSettings,
  timesheetSettings,
  setTimesheetPolicyConfig,
}) => {
  return (
    <>
      <AutomaticBreakTime
        updateTimesheetSettings={setTimesheetPolicyConfig}
        automaticBreakTimeSettings={automaticBreakTimeSettings}
        breakTypes={timesheetSettings.break_types}
        advancedBreakTime={timesheetSettings.advanced_break_time}
        isPolicySettings={true}
      />
    </>
  );
};
