import React, { FC } from "react";
import styles from "./Notes.module.css";

type Props = {
  authorName: string;
};

const NoteProfileCircle: FC<Props> = ({ authorName }) => {
  const initials = authorName
    .split(" ")
    .map((n) => n[0])
    .join("")
    .toUpperCase();

  return (
    <div className={styles["note-author-circle"]}>
      <span>{initials}</span>
    </div>
  );
};

export default NoteProfileCircle;
