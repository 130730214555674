import React, { FC } from "react";
import Editor, { EditorProps } from "ui/editor/Editor";

type SMSTemplateEditorProps = Omit<EditorProps, "handleImageUpload">;

export const SMSTemplateEditor: FC<SMSTemplateEditorProps> = ({
  disabled,
  defaultHTML,
  editorRef,
  height,
  toolbar,
  smartFields,
}) => {
  return (
    <Editor
      defaultHTML={defaultHTML}
      editorRef={editorRef}
      height={height}
      toolbar={toolbar}
      disabled={disabled}
      smartFields={smartFields}
      menubar=""
    />
  );
};
