import { TimesheetSettings } from "./TimesheetSettings";

interface TimesheetBulkImportSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetBulkImportSettings: React.FC<TimesheetBulkImportSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <div className="billing-card-wrapper">
      <div style={{ fontWeight: 600, fontSize: 18 }}>Bulk import</div>

      <div className="vertical-spacer-small"></div>
      <div className="flex">
        <input
          style={{ marginLeft: 10 }}
          type="checkbox"
          checked={timesheetSettings.bulk_import?.require_all_timesheets_to_be_successful}
          onChange={(e) => {
            handleChange({
              bulk_import: { require_all_timesheets_to_be_successful: e.target.checked },
            });
          }}
        />
        <span style={{ marginLeft: 15 }}>
          Require all timesheets to be successful for the bulk import to be successful.
        </span>
      </div>
    </div>
  );
};

export default TimesheetBulkImportSettings;
