import { timeAgo } from "dashboard/utils";
import { truncate } from "lodash";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { FaCircle } from "react-icons/fa";
import styles from "./Chat.module.css";

type Props = {
  name: string;
  dateCreated?: Date;
  lastMessage: string;
  unread: boolean;
  active: boolean;
  handleClick: () => void;
  id: string;
};

export const ConversationItem: FC<Props> = ({
  name,
  dateCreated,
  lastMessage,
  active,
  handleClick,
  unread,
  id,
}) => {
  const truncatedMessage = truncate(lastMessage, { length: 30 });
  const formattedDate = dateCreated ? DateTime.fromJSDate(dateCreated) : null;
  const lastMessageTime = formattedDate && !formattedDate.invalidReason ? timeAgo(formattedDate) : "";

  const className =
    styles["conversation-list-item"] +
    " " +
    (active ? styles["active"] : "") +
    " " +
    (unread ? styles["unread"] : "");

  return (
    <div className={className} onClick={handleClick} key={id}>
      {unread && <FaCircle className={styles["conversation-read-icon"]} style={{ color: "#4d55bb" }} />}

      <h3 className={styles["conversation-friendly-name"]}>{name}</h3>
      <p className={styles["conversation-last-message"]}>{truncatedMessage}</p>
      <p className={styles["conversation-last-message-time"]}>{lastMessageTime}</p>
    </div>
  );
};
