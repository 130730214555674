import { useCallback, useMemo } from "react";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "./useMiterAbilities";
import { AggregatedTeamMember, FormSubmission } from "dashboard/miter";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

/*
 * FORM SUBMISSIONS
 */
type FormSubmissionParams = FormSubmission | FormSubmission[] | undefined;

type FormSubmissionAction = "create" | "read" | "update" | "delete";
type FormSubmissionAbilities = {
  can: (action: FormSubmissionAction, items: FormSubmissionParams) => boolean;
  cannot: (action: FormSubmissionAction, items: FormSubmissionParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: FormSubmissionAction) => (tm: AggregatedTeamMember) => boolean;
};

export const useFormSubmissionAbilities = (): FormSubmissionAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: FormSubmissionAction, items?: FormSubmissionParams) => {
      if (!items) return false;

      const submissions = Array.isArray(items) ? items : [items];

      return submissions.every((submission) => {
        const isPersonal = submission.team_member_id === activeTeamMember?._id;
        const teamMemberId = submission.team_member_id;

        if (isPersonal) {
          switch (action) {
            case "create":
              return can_("forms:submissions:personal:create");
            case "read":
              return can_("forms:submissions:personal:read");
            case "update":
              return can_("forms:submissions:personal:update");
            case "delete":
              return can_("forms:submissions:personal:delete");
            default:
              return false;
          }
        } else {
          switch (action) {
            case "create":
              return can_("forms:submissions:others:create", { teamMember: teamMemberId });
            case "read":
              return can_("forms:submissions:others:read", { teamMember: teamMemberId });
            case "update":
              return can_("forms:submissions:others:update", { teamMember: teamMemberId });
            case "delete":
              return can_("forms:submissions:others:delete", { teamMember: teamMemberId });
            default:
              return false;
          }
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: FormSubmissionAction, items: FormSubmissionParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "forms:submissions:personal",
    othersPermissionPath: "forms:submissions:others",
    teamMemberField: { fieldName: "team_member_id", fieldType: "string" },
    appModule: "company",
  });

  /** Predicate used to filter down the team members that someone can see */
  const teamPredicate = useAbilitiesTeamPredicate<FormSubmissionAction>("forms:submissions");

  return useMemo(() => ({ can, cannot, filter, teamPredicate }), [can, cannot, filter, teamPredicate]);
};
