import React from "react";
import { ActionModal } from "ui";
import styles from "./RecruitingModals.module.css";
import Banner from "dashboard/components/shared/Banner";

type DetachFormTemplateModalProps = {
  onSubmit: () => void;
  onClose: () => void;
};

export const DetachFormTemplateModal: React.FC<DetachFormTemplateModalProps> = ({ onSubmit, onClose }) => {
  return (
    <ActionModal
      headerText="Detach template"
      showSubmit={true}
      submitText="Detach"
      onSubmit={onSubmit}
      showCancel={true}
      onHide={onClose}
      onCancel={onClose}
      wrapperClassName={styles["detach-form-template-modal"]}
      bodyClassName={styles["detach-form-template-modal-body"]}
    >
      <Banner type="info">
        By detaching this form, you&apos;ll be able to customize the application questions specifically for
        this job posting. Any future changes to the original template won&apos;t affect this form.
      </Banner>
    </ActionModal>
  );
};
