import { useTeamMemberScopesBuilder } from "dashboard/hooks/useTeamMemberScopesBuilder";
import React from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock } from "ui";
import { PermissionGroupForm } from "./PermissionGroupModal";

type TeamMemberScopeInputProps = {
  form: UseFormMethods<PermissionGroupForm>;
  name: string;
  label: string;
  labelInfo?: string;
  sublabel?: string;
};

export const PermissionGroupTeamMemberScope: React.FC<TeamMemberScopeInputProps> = ({
  label,
  labelInfo,
  sublabel,
  form,
  name,
}) => {
  const { options: teamMemberScopeOptions } = useTeamMemberScopesBuilder();

  return (
    <Formblock
      type="multiselect"
      name={name}
      label={label}
      labelInfo={labelInfo}
      sublabel={sublabel}
      form={form}
      editing={true}
      className="modal"
      placeholder={"Select scopes"}
      options={teamMemberScopeOptions}
      height="unset"
      style={{ marginTop: 20 }}
    />
  );
};
