import React, { useMemo } from "react";
import { TextWithTooltip } from "ui";

import styles from "./BulkCreateTimesheets.module.css";
import { useActiveCompany, usePolicies } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { BULK_ENTRY_COLUMNS } from "./BulkCreateTimesheetsRow";
import { useHasAccessToEquipmentTracking } from "dashboard/gating";

type Props = {
  handleSelectAll: (e: $TSFixMe) => void;
  allSelected?: boolean;
  clockOutNotHours: boolean;
  setClockOutNotHours: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BulkCreateTimesheetsColumns: React.FC<Props> = ({
  clockOutNotHours,
  setClockOutNotHours,
  handleSelectAll,
  allSelected,
}) => {
  const activeCompany = useActiveCompany();
  const hasAccessToEquipmentTracking = useHasAccessToEquipmentTracking();
  const hasAdvancedBreakTime = activeCompany?.settings.timesheets.advanced_break_time;
  const timesheetDashboardFieldSettings = activeCompany?.settings.timesheets.field_requirements.dashboard;
  const enableAdminWcCode = activeCompany?.settings?.timesheets.enable_admin_wc_code;
  const showPayRate = activeCompany?.settings?.timesheets?.show_pay_rate_in_bulk_create;
  const showEarningType = activeCompany?.settings.timesheets.always_display_earning_types;

  const policies = usePolicies();

  const hasTimesheetPoliciesEnabled = useMemo(
    () => policies.filter((policy) => policy.type === "timesheet").length > 0,
    [policies]
  );

  const abilities = useMiterAbilities();
  const canReadWcCode = abilities.can("lists:workers_comp_codes:read");

  const {
    job: jobFieldRequirement,
    activity: activityFieldRequirement,
    cost_type_id: costTypeFieldRequirement,
    classification: classificationFieldRequirement,
    notes: notesFieldRequirement,
    equipment_ids: equipmentFieldRequirement,
  } = hasTimesheetPoliciesEnabled
    ? BULK_ENTRY_COLUMNS
    : timesheetDashboardFieldSettings || BULK_ENTRY_COLUMNS;

  return (
    <tr className={styles["columns-wrapper"]}>
      <th className={styles["column-header"] + " " + styles["select-column"]}>
        <input
          type="checkbox"
          key={"timesheet-select-all"}
          onClick={handleSelectAll}
          checked={!!allSelected}
        />
      </th>
      <th className={`${styles["column-header"]} ${styles["tm-column"]}`}>Team member*</th>
      <th className={styles["column-header"] + " " + styles["date-column"]}>Date*</th>
      <th className={styles["column-header"] + " " + styles["clock-in-column"]}>Clock in*</th>
      <th
        className={
          styles["column-header"] +
          " " +
          styles[clockOutNotHours ? "clock-in-column" : "hours-column"] +
          " " +
          "pointer"
        }
        onClick={() => setClockOutNotHours((p) => !p)}
      >
        <TextWithTooltip
          text={clockOutNotHours ? "Clock out*" : "Hours*"}
          spanStyle={{ fontWeight: 500 }}
          id="hours"
          tooltip="Toggle to enter duration as hours or as clock out time."
        />
      </th>
      {clockOutNotHours && !hasAdvancedBreakTime && (
        <th className={styles["column-header"] + " " + styles["break-min-column"]}>Break (mins)</th>
      )}
      {jobFieldRequirement === "hidden" ? null : (
        <th className={styles["column-header"] + " " + styles["job-column"]}>Job</th>
      )}
      {activityFieldRequirement === "hidden" ? null : (
        <th className={styles["column-header"] + " " + styles["activity-column"]}>Activity</th>
      )}
      {costTypeFieldRequirement === "hidden" ? null : (
        <th className={styles["column-header"] + " " + styles["cost-type-column"]}>Cost type</th>
      )}
      {classificationFieldRequirement === "hidden" ? null : (
        <th className={styles["column-header"] + " " + styles["classification-column"]}>Classification</th>
      )}
      {notesFieldRequirement === "hidden" ? null : (
        <th className={styles["column-header"] + " " + styles["notes-column"]}>Notes</th>
      )}
      {!showEarningType ? null : (
        <th className={styles["column-header"] + " " + styles["earning-type-column"]}>Earning type</th>
      )}
      {!(enableAdminWcCode && canReadWcCode) ? null : (
        <th className={styles["column-header"] + " " + styles["wc-column"]}>{`Workers' comp`}</th>
      )}
      {hasAccessToEquipmentTracking && equipmentFieldRequirement !== "hidden" ? (
        <th className={styles["column-header"] + " " + styles["equipment-column"]}>{`Equipment`}</th>
      ) : null}
      {!showPayRate ? null : (
        <th className={styles["column-header"] + " " + styles["pay-rate-column"]}>{`Pay rate`}</th>
      )}
      <th className={styles["column-header"] + " " + styles["options-column"]}> </th>
    </tr>
  );
};
