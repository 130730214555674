import React, { useState, Dispatch, SetStateAction } from "react";
import { Form, MiterAPI } from "dashboard/miter";
import { FormBuilderScreen } from "../forms/FormBuilderScreen";
import { Wizard, Notifier } from "ui";
import { AggregatedFormTemplate } from "dashboard/types/form-types";
import { useRefetchFormTemplates } from "dashboard/hooks/atom-hooks";

type Props = {
  onExit: () => void;
  formTemplate: AggregatedFormTemplate;
  onComplete: () => void;
};

export const FormTemplateWizard: React.FC<Props> = ({
  onExit,
  formTemplate: initialFormTemplate,
  onComplete,
}) => {
  const [form, setForm] = useState<Form | undefined>(initialFormTemplate.form);
  const refetchFormTemplates = useRefetchFormTemplates();

  const syncFormTemplateToForm = async (formItem: Form | undefined) => {
    if (!formItem) return;
    try {
      const updatedFormTemplate = await MiterAPI.form_templates.update(initialFormTemplate._id, {
        name: formItem?.name,
        company_id: formItem.company_id,
      });
      if (updatedFormTemplate.error) {
        throw updatedFormTemplate.error;
      }
      refetchFormTemplates([initialFormTemplate._id]);
    } catch (error: $TSFixMe) {
      Notifier.error("Error updating name of form template", error.message);
    }
  };

  const setFormItem = (formItem: Form | undefined) => {
    setForm(formItem);
    syncFormTemplateToForm(formItem);
  };

  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      <FormBuilderScreen
        name={"New form template"}
        formItem={form}
        setFormItem={setFormItem as Dispatch<SetStateAction<Form | undefined>>}
        parentType="template"
        disableAutoSave={true}
      />
    </Wizard>
  );
};
