import React from "react";
import { Formblock } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { buildAtomicMongoUpdateFromNested, convertUndefinedToMongoUnset } from "dashboard/utils";
import { DeepPartial } from "utility-types";

import { useForm } from "react-hook-form";
import { LedgerAccount } from "dashboard/miter";
import { useLedgerAccountOptions } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui/settings/SettingsCard";
import { KeyLabelPair } from "../Intacct/IntacctConfig";
import { Option } from "ui/form/Input";
import { TeamMemberConfig } from "./TeamMemberConfig";
import {
  Sage300ConnectionPayGroup,
  Sage300ConnectionFilingStatus,
  Sage300ConnectionMetadata,
  Sage300ConnectionTimeType,
  Sage300JobStatus,
  Sage300SalaryTimeEntryOption,
  Sage300EmployeeRefField,
} from "backend/services/sage300/sage300-types";
import { IS_PRODUCTION } from "dashboard/utils/environment";

const INPUTLENGTH = 250;
const LABELLENGTH = 300;

const timeTypesKeys: KeyLabelPair<Sage300ConnectionTimeType>[] = [
  { key: "reg", label: "Regular" },
  { key: "salary", label: "Salary" },
  { key: "ot", label: "Overtime" },
  { key: "dot", label: "Double overtime" },
  { key: "pto", label: "Vacation" },
  { key: "sick", label: "Sick" },
  { key: "paid_holiday", label: "Holiday" },
];

const payGroupKeys: KeyLabelPair<Sage300ConnectionPayGroup>[] = [
  { key: "hourly", label: "Hourly" },
  { key: "salary", label: "Salary" },
];

const filingStatus: KeyLabelPair<Sage300ConnectionFilingStatus>[] = [
  { key: "single", label: "Single" },
  { key: "married_filing_jointly", label: "Married Filing Jointly" },
  { key: "married_filing_separately", label: "Married Filing Separately" },
  { key: "non_resident_alien", label: "Non Resident Alien" },
  { key: "head_of_household", label: "Head Of Household" },
];

const dateSettingForSalaryTimeEntryOptions: Option<Sage300SalaryTimeEntryOption>[] = [
  { value: "pay_period_end", label: "Pay Period End Date" },
  { value: "pay_period_start", label: "Pay Period Start Date" },
];

const employeeRefFieldOptions: Option<Sage300EmployeeRefField>[] = [
  { value: "ref1", label: "Ref 1" },
  { value: "ref2", label: "Ref 2" },
];

export const hh2JobStatusOptions: Option<Sage300JobStatus>[] = [
  { value: "Unstarted", label: "Unstarted" },
  { value: "InProgress", label: "In Progress" },
  { value: "Complete", label: "Complete" },
  { value: "Closed", label: "Closed" },
];

const sage300AccountPred = (la: LedgerAccount) => !!la.integrations?.sage_300;

export const Sage300Config: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();
  const sage300Metadata: Sage300ConnectionMetadata = integration.connection?.metadata?.sage_300 || {};

  const updateSage300Metadata = async (metadataUpdate: DeepPartial<Sage300ConnectionMetadata>) => {
    const raw = { metadata: { sage_300: metadataUpdate } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    const finalUpdate = convertUndefinedToMongoUnset(flattened);

    await updateIntegrationConnection(finalUpdate);
  };

  const clearingAccountOptions = useLedgerAccountOptions({ predicate: sage300AccountPred });

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Accounting Settings">
        <Formblock
          form={form}
          options={clearingAccountOptions}
          name={"gl_clearing_account_id"}
          type="select"
          label={"GL clearing account"}
          labelInfo={"Used to keep the Sage 300 General Ledger in balance."}
          onChange={(o) => updateSage300Metadata({ gl_clearing_account_id: o?.value })}
          defaultValue={sage300Metadata.gl_clearing_account_id}
          editing={true}
          isClearable
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
      <SettingsCard title="Time Types">
        {timeTypesKeys.map(({ key, label, tooltip }) => {
          return (
            <Formblock
              form={form}
              // Temporarily leaving this blank until we can figure out an approach to get the
              // time types from HH2
              name={"timeTypes." + key}
              type="text"
              label={label}
              defaultValue={sage300Metadata.timeTypes?.[key]}
              onChange={(e) => updateSage300Metadata({ timeTypes: { [key]: e.target.value || undefined } })}
              editing={true}
              labelInfo={tooltip}
              inputProps={{ style: { width: INPUTLENGTH } }}
              key={key}
            />
          );
        })}
      </SettingsCard>
      <SettingsCard title="Time Entry Export Settings">
        <Formblock
          form={form}
          name="salaryDefaultTimeEntryDate"
          type="select"
          options={dateSettingForSalaryTimeEntryOptions}
          label="Default date for Salary TMs"
          defaultValue={sage300Metadata.salaryDefaultTimeEntryDate ?? "None"}
          onChange={(o: Option<Sage300SalaryTimeEntryOption> | null) =>
            updateSage300Metadata({ salaryDefaultTimeEntryDate: o?.value })
          }
          editing={true}
          labelInfo="When exporting hours for Salary team members, determines which date to use by default. Field is blank if None is set."
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
      <SettingsCard title="Job Cost Export">
        <Formblock
          form={form}
          name="employeeRefField"
          type="select"
          defaultValue={sage300Metadata.jobCostExport?.employeeRefField}
          options={employeeRefFieldOptions}
          onChange={(option) => {
            updateSage300Metadata({
              jobCostExport: { employeeRefField: option?.value },
            });
          }}
          label="Employee ref field"
          labelInfo="Select which ref field to use to designate the employee for the line item."
          editing={true}
          labelStyle={{ minWidth: LABELLENGTH }}
          inputProps={{ style: { width: INPUTLENGTH } }}
          isClearable
        />
        <Formblock
          form={form}
          name="useTMInDescription"
          type="checkbox"
          defaultValue={!!sage300Metadata.jobCostExport?.useTMInDescription}
          onChange={(event) => {
            updateSage300Metadata({ jobCostExport: { useTMInDescription: event.target.checked } });
          }}
          label="Add team member to description"
          labelInfo="Adds the team member ID and name to the description field"
          editing={true}
          labelStyle={{ minWidth: LABELLENGTH }}
          style={{ alignItems: "center" }}
        />
        <Formblock
          form={form}
          name="considerCostsToBePayrollCosts"
          type="checkbox"
          defaultValue={!!sage300Metadata.jobCostExport?.considerCostsToBePayrollCosts}
          onChange={(event) => {
            updateSage300Metadata({ jobCostExport: { considerCostsToBePayrollCosts: event.target.checked } });
          }}
          label="Use payroll costs as transaction type"
          labelInfo="Consider the transaction type to be a Payroll Cost (instead of a Job Cost)"
          editing={true}
          labelStyle={{ minWidth: LABELLENGTH }}
          style={{ alignItems: "center" }}
        />
        <Formblock
          form={form}
          name="useLastPayCycleDateForAccountingPeriod"
          type="checkbox"
          defaultValue={!!sage300Metadata.jobCostExport?.useLastPayCycleDateForAccountingPeriod}
          onChange={(event) => {
            updateSage300Metadata({
              jobCostExport: { useLastPayCycleDateForAccountingPeriod: event.target.checked },
            });
          }}
          label="Use last day for accounting date"
          labelInfo="Use the last day of the pay period as the accounting date"
          editing={true}
          labelStyle={{ minWidth: LABELLENGTH }}
          style={{ alignItems: "center" }}
        />
        <Formblock
          form={form}
          name="useStandardItems"
          type="checkbox"
          defaultValue={!!sage300Metadata.jobCostExport?.useStandardItems}
          onChange={(event) => {
            updateSage300Metadata({ jobCostExport: { useStandardItems: event.target.checked } });
          }}
          label="Use standard items"
          labelInfo="Export the Standard Item column for each line item."
          editing={true}
          labelStyle={{ minWidth: LABELLENGTH }}
          style={{ alignItems: "center" }}
        />
        <Formblock
          form={form}
          name="includeEarningDate"
          type="checkbox"
          defaultValue={!!sage300Metadata.jobCostExport?.includeEarningDate}
          onChange={(event) => {
            updateSage300Metadata({ jobCostExport: { includeEarningDate: event.target.checked } });
          }}
          label="Use earning date as transaction date"
          labelInfo="For payroll-related entries, use the earning date as the exported transaction date."
          editing={true}
          labelStyle={{ minWidth: LABELLENGTH }}
          style={{ alignItems: "center" }}
        />
      </SettingsCard>
      <SettingsCard title="Team member contact info">
        <Formblock
          form={form}
          name={"enableManualTmContactInfoUpdate"}
          type="checkbox"
          text="Allow manual overrides of team members' email and phone"
          defaultValue={sage300Metadata.enableManualTmContactInfoUpdate}
          onChange={(e) => updateSage300Metadata({ enableManualTmContactInfoUpdate: e.target.checked })}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Team member department">
        <Formblock
          form={form}
          name="syncDepartmentToTm"
          type="checkbox"
          text="Automatically assign the department to the Team Member when syncing Team Members from Sage 300"
          defaultValue={!!sage300Metadata.syncDepartmentToTm}
          onChange={(e) => updateSage300Metadata({ syncDepartmentToTm: e.target.checked })}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Auto-enroll team members in payroll">
        <Formblock
          form={form}
          name="autoEnrollInPayroll"
          type="checkbox"
          text="Automatically enroll team members in payroll when syncing from Sage 300"
          defaultValue={!!sage300Metadata.autoEnrollTmInPayroll}
          onChange={(e) => updateSage300Metadata({ autoEnrollTmInPayroll: e.target.checked })}
          editing={true}
        />
      </SettingsCard>
      <TeamMemberConfig
        form={form}
        sage300Metadata={sage300Metadata}
        updateIntegrationConnection={updateIntegrationConnection}
      />
      {!IS_PRODUCTION && (
        <>
          <SettingsCard title="Pay Groups">
            {payGroupKeys.map(({ key, label, tooltip }) => {
              return (
                <Formblock
                  form={form}
                  // Temporarily leaving this blank until we can figure out an approach to get the
                  // Pay Groups from HH2
                  options={[]}
                  name={"payGroup." + key}
                  type="select"
                  label={label}
                  defaultValue={sage300Metadata.payGroup?.[key]}
                  onChange={(o: Option<string> | null) =>
                    updateSage300Metadata({ payGroup: { [key]: o?.value || undefined } })
                  }
                  editing={true}
                  labelInfo={tooltip}
                  isClearable
                  inputProps={{ style: { width: INPUTLENGTH } }}
                  key={key}
                />
              );
            })}
          </SettingsCard>
          <SettingsCard title="Filing Statuses">
            {filingStatus.map(({ key, label, tooltip }) => {
              return (
                <Formblock
                  form={form}
                  // Temporarily leaving this blank until we can figure out an approach to get the
                  // Filing Statuses from HH2
                  options={[]}
                  name={"filingStatus." + key}
                  type="select"
                  label={label}
                  defaultValue={sage300Metadata.filingStatus?.[key]}
                  onChange={(o: Option<string> | null) =>
                    updateSage300Metadata({ filingStatus: { [key]: o?.value || undefined } })
                  }
                  editing={true}
                  labelInfo={tooltip}
                  isClearable
                  inputProps={{ style: { width: INPUTLENGTH } }}
                  key={key}
                />
              );
            })}
          </SettingsCard>
        </>
      )}
      <SettingsCard title="Job Sync">
        <Formblock
          form={form}
          options={hh2JobStatusOptions}
          name="inactiveStatusValues"
          type="multiselect"
          defaultValue={sage300Metadata.jobsSync?.inactiveJobStatuses}
          onChange={(options: Option<Sage300JobStatus>[] | null) => {
            const statuses = options?.map((o) => o.value);
            updateSage300Metadata({ jobsSync: { inactiveJobStatuses: statuses } });
          }}
          label="Inactive job statuses"
          editing={true}
          labelInfo="Sage 300 statuses Miter will consider to be 'Inactive' when syncing jobs"
          isClearable
        />
        <Formblock
          form={form}
          name="syncOnlyParentJobs"
          type="checkbox"
          defaultValue={sage300Metadata.jobsSync?.syncOnlyParentJobs}
          onChange={(event) => {
            updateSage300Metadata({ jobsSync: { syncOnlyParentJobs: !!event.target.checked } });
          }}
          label="Sync only parent jobs"
          editing={true}
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
        <Formblock
          form={form}
          name="syncJobSupervisors"
          labelInfo="Enables importing Sage300 project managers as job supervisors"
          type="checkbox"
          defaultValue={sage300Metadata.jobsSync?.syncJobSupervisors}
          onChange={(event) => {
            updateSage300Metadata({ jobsSync: { syncJobSupervisors: !!event.target.checked } });
          }}
          label="Sync job supervisors"
          editing={true}
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
      <div className="vertical-spacer-large" />
    </div>
  );
};
