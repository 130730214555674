import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { reportList } from "../reportList";
import { TeamRateHistory } from "dashboard/pages/team-members/TeamRateHistory";

const reportObject = reportList.find((report) => report.slug === "pay_rate_histories");

const PayRateHistoriesReport: React.FC = () => {
  // Important hooks
  const navigate = useNavigate();

  return (
    <div className="page-content">
      <Helmet>
        <title>Pay Rate Histories Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject!.label}</h1>
      </div>
      <div className="report-page-description">
        {reportObject!.description} Setting a date range will filter the table for all changes that were{" "}
        <strong>in effect</strong> during the range.
      </div>
      <div className="page-content-body" style={{ height: "100%" }}>
        <TeamRateHistory />
      </div>
    </div>
  );
};

export default PayRateHistoriesReport;
