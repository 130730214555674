import AppContext from "dashboard/contexts/app-context";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export const AssetSettings: React.FC = () => {
  const { fetchUserData } = useContext(AppContext);
  const activeCompany = useActiveCompany();
  const activeCompanyId = activeCompany?._id;

  const navigate = useNavigate();

  const { settings } = activeCompany!;

  const [assetSettings, setAssetSettings] = useState(settings.assets || {});

  const goBackToAssets = () => {
    navigate("/assets");
  };

  const handleChange = async (fieldName: string, newValue) => {
    const newSettings = { ...assetSettings };
    newSettings[fieldName] = newValue;
    setAssetSettings(newSettings);
    try {
      if (!activeCompanyId) throw new Error("No active company id");

      const response = await MiterAPI.companies.update(activeCompanyId, {
        $set: { "settings.assets": newSettings },
      });

      if (response.error) throw new Error(response.error);
      Notifier.success("Asset settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating asset settings. Our engineers are looking into it!");
    }
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Asset settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={goBackToAssets}>
          Back to assets
        </div>
        <div className="flex">
          <h1>Asset settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure the settings for your assets</div>
        <div className="vertical-spacer-small"></div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Equipment</div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="supervisors_can_add_equipment_from_app"
              checked={assetSettings.supervisors_can_add_equipment_from_app}
              onChange={(e) => handleChange("supervisors_can_add_equipment_from_app", e.target.checked)}
            />
            <span
              style={{ marginLeft: 15 }}
            >{`Allow supervisors to create equipment from the mobile app`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
