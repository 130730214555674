import React, { useState } from "react";
import { Button, Formblock, LoadingModal } from "ui";
import { DateTime } from "luxon";
import { useNavigate } from "react-router";
import { MiterAPI } from "dashboard/miter";
import { Notifier, downloadCsvFromBlob } from "dashboard/utils";
import { saveAs } from "file-saver";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import { reportList } from "../reportList";
import { MonthYearSelection } from "ui/form/MonthYearPicker";
import { BPI_ONEROOF_COMPANY_ID } from "dashboard/utils/constants";
const reportObject = reportList.find((report) => report.slug === "miter_card_statement");

export const CreateMiterCardStatementReport: React.FC = () => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const navigate = useNavigate();

  // State
  const [selectedMonth, setSelectedMonth] = useState<MonthYearSelection>({
    month: DateTime.now().month - 1, // Luxon months are 1-indexed, JS months are 0-indexed
    year: DateTime.now().year,
  });
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const download = async (format: "pdf" | "csv") => {
    if (!activeCompanyId) return;

    if (format === "pdf") {
      setDownloadingPDF(true);
    } else {
      setDownloadingCSV(true);
    }

    try {
      const startOfMonth = DateTime.fromObject({
        year: selectedMonth.year,
        month: selectedMonth.month + 1,
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      const payload = {
        type: "miter_card_statement",
        params: {
          startDate: startOfMonth.toISODate(),
          endDate: startOfMonth.endOf("month").toISODate(),
          companyId: activeCompanyId!,
        },
        format,
      };

      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);

      if (format === "csv") {
        downloadCsvFromBlob(response.csv, response.filename);
      } else if (format === "pdf") {
        const blob = new Blob([Uint8Array.from(response.buffer.data).buffer], { type: "application/pdf" });
        saveAs(blob, response.filename);
      }
    } catch (e) {
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }

    setDownloadingPDF(false);
    setDownloadingCSV(false);
  };

  // months are 0 indexed. there were some edge cases for generating Miter card statements in Feb 2024 and earlier due to intra-balance transfers. Don't make those months selectable.
  let minMonth = 2;

  // allow BPi to generate reports for any month
  if (activeCompanyId === BPI_ONEROOF_COMPANY_ID) {
    minMonth = 0;
  }

  return (
    <div className="page-content">
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject?.label}</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 400 }}>
        <Formblock
          label="Month"
          labelInfo="Calendar month to select."
          type="monthyear"
          name="month"
          onChange={setSelectedMonth}
          value={selectedMonth}
          min={{ year: 2024, month: minMonth }}
          max={{ year: DateTime.now().year, month: DateTime.now().month - 1 }} // current year and month. JS months are 0-indexed
          topOfInput={true}
          editing={true}
        />
      </div>
      <div className="flex" style={{ marginTop: 10, marginBottom: -33 }}>
        <Button
          text="Download PDF"
          onClick={() => download("pdf")}
          className="button-2 margin-left-0"
          loading={downloadingPDF}
        />
        <Button
          text="Download CSV"
          onClick={() => download("csv")}
          className="button-2"
          loading={downloadingCSV}
        />
      </div>
      {downloadingPDF && <LoadingModal text="Downloading PDF" />}
      {downloadingCSV && <LoadingModal text="Downloading CSV" />}
    </div>
  );
};
