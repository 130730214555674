import React, { useMemo } from "react";
import PayrollContext from "../payrolls/viewPayroll/payrollContext";
import { LedgerEntry } from "dashboard/pages/accounting/LedgerEntries/LedgerEntry";
import { reportList } from "./reportList";

const reportObject = reportList.find((report) => report.slug === "ledger_entry");

export const NonDraftPayrollLedgerEntry: React.FC = () => {
  // Hooks
  const { payroll } = React.useContext(PayrollContext);

  const filterArray = useMemo(() => {
    return [
      { field: "source_objects.payroll_id", value: payroll?._id || "" },
      { field: "entry_type", value: payroll?.void_of ? "payroll_void" : "payroll_run" },
    ];
  }, [payroll]);

  if (!payroll) return null;

  return (
    <>
      <h2 style={{ marginTop: 0, marginBottom: 5 }}>{reportObject!.label}</h2>
      <div className="report-page-description">{reportObject!.description}</div>
      <LedgerEntry
        filterArray={filterArray}
        gridHeight="calc(50vh)"
        draftPayrollPreview={payroll.check_payroll.status === "draft" ? payroll : undefined}
      />
    </>
  );
};
