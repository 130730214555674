import AppContext from "dashboard/contexts/app-context";
import { MiterAPI, User } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useState } from "react";
import { BasicModal } from "ui";

type Props = {
  user: User;
};

// Hardcoded for now.
const currentTosVersion = "1.0";
// const NEWNAV2022KEY = "newNavDec2022";

export const UserAcknowledgements: React.FC<Props> = ({ user }) => {
  const { fetchUserData } = useContext(AppContext);
  const [needsTosConsent, setNeedsTosConsent] = useState(!user.tos_consents.includes(currentTosVersion));
  const [consenting, setConsenting] = useState(false);
  const [_acknowledging, setAcknowledging] = useState(false);

  // const needsNewNavAckDec2022 = !user.acknowledgements?.includes(NEWNAV2022KEY);

  const consentToTos = async () => {
    setConsenting(true);
    try {
      const response = await MiterAPI.users.consentToTos(user._id.toString(), currentTosVersion);
      if (response.error) throw new Error(response.error);
      setNeedsTosConsent(false);
    } catch (e) {
      Notifier.error("There was an error consenting to the Terms.");
    }
    setConsenting(false);
  };

  const _addAcknowledgement = async (ackId: string) => {
    setAcknowledging(true);
    try {
      const acknowledgements = user.acknowledgements.slice();
      acknowledgements.push(ackId);
      const response = await MiterAPI.users.update(user._id.toString(), { acknowledgements });
      if (response.error) throw new Error(response.error);
      await fetchUserData();
    } catch (e) {
      Notifier.error("There was an error acknowledging data.");
    }
    setAcknowledging(false);
  };

  return (
    <div>
      {needsTosConsent && (
        <BasicModal
          headerText={`Miter's Terms of Service`}
          button2Action={consentToTos}
          loading={consenting}
          button2Text="I accept"
        >
          <div className="yellow-text-container">
            {`Miter has updated its `}
            <a
              target="_blank"
              className="blue-link"
              href="https://www.miter.com/terms-of-service"
              rel="noreferrer"
            >{`Terms of Service`}</a>
            . To continue, please review and accept the Terms.
          </div>
        </BasicModal>
      )}
      {/* {needsNewNavAckDec2022 && (
        <BasicModal
          headerText={`Making Miter easier to use!`}
          button2Action={() => _addAcknowledgement(NEWNAV2022KEY)}
          loading={_acknowledging}
          button2Text="Got it"
        >
          <div className="yellow-text-container">
            {`We've simplified our navigation menus in certain places, and some of the workflows you're used to have moved. If you need any assistance adjusting to the new layout, we're a call or email away.`}
          </div>
        </BasicModal>
      )} */}
    </div>
  );
};
