import { BasicModal, Button, ClickableText, Notifier } from "ui";
import { useState } from "react";
import { goToMiterGuide } from "dashboard/utils/miterGuides";

export const PayrollIntegrationsOnboardingModal: React.FC<{ activeCompanyId: string | null }> = ({
  activeCompanyId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const copyCompanyId = () => {
    if (!activeCompanyId)
      return Notifier.error("Unable to find ID. Reach out to support@miter.com for help.");
    navigator.clipboard.writeText(activeCompanyId);
    Notifier.success("Copied company ID to clipboard.");
  };

  return (
    <>
      <div>
        <div className="vertical-spacer-small"></div>
        Miter partners with <a href="https://www.payrollintegrations.com/">Payroll Integrations</a> to provide
        connections to{" "}
        <ClickableText onClick={() => goToMiterGuide("benefits-management/401-k-admin/401-k-partners")}>
          most large 401(k) providers.
        </ClickableText>
        <div className="vertical-spacer-small"></div>
        To connect Miter to your 401(k) provider, you will need to provide them with your unique company ID.
        <div className="vertical-spacer"></div>
        <Button className="button-1 no-margin" text={"Show ID"} onClick={() => setShowModal(true)}></Button>
      </div>
      {showModal && (
        <BasicModal
          headerText="Connect with your 401(k) provider"
          bodyText="To connect Miter to your 401(k) provider, you will need to provide them with your unique Miter ID."
          button1Text="Close"
          button1Action={() => setShowModal(false)}
          button2Text="Copy ID"
          button2Action={() => copyCompanyId()}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};
