import React, { useState } from "react";
import { formatDate } from "dashboard/utils/utils";
import PayrollContext from "../payrollContext";
import OffCyclePayrollModal from "../../OffCycleSettingsModal";
import { Button, Loader } from "ui";
import DataBox from "dashboard/components/dataBox/DataBox";
import { payFrequencyLookup } from "dashboard/utils/paySchedules";

type Props = { readOnly: boolean };

const OffCycleOptions: React.FC<Props> = ({ readOnly }) => {
  // Hooks
  const { payroll, isLoading, getPayroll } = React.useContext(PayrollContext);

  // State
  const [isEditing, setIsEditing] = useState(false);

  const renderTrueFalseSpan = (bool) => {
    const color = bool ? "dark-green" : "dark-red";
    return <span className={"data-box-section-value " + color}>{bool ? "Enabled" : "Disabled"}</span>;
  };

  if (!payroll) return null;

  const check_payroll = payroll.check_payroll;

  return (
    <div>
      {isEditing && !readOnly && (
        <OffCyclePayrollModal
          edit
          payroll={payroll}
          getPayroll={getPayroll}
          hide={() => setIsEditing(false)}
        />
      )}
      <div className="vertical-spacer-five" />
      {!readOnly && (
        <div className="flex">
          <div className="flex-1" />
          <Button className="button-1" text="Edit settings" onClick={() => setIsEditing(true)} />
        </div>
      )}
      <div className="vertical-spacer-medium" />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="paystub-section width-100">
          <DataBox title="Payroll timeline" className="flex-col no-margin-right">
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Pay period</span>
              <span className={"data-box-section-value"}>
                {formatDate(check_payroll.period_start, check_payroll.period_end)}
              </span>
            </div>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Approval deadline</span>
              <span className={"data-box-section-value"}>{formatDate(check_payroll.approval_deadline)}</span>
            </div>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Payday</span>
              <span className={"data-box-section-value"}>{formatDate(check_payroll.payday)}</span>
            </div>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Tax interval</span>
              <span className={"data-box-section-value"}>
                {payFrequencyLookup[check_payroll.pay_frequency]}
              </span>
            </div>
          </DataBox>
          <DataBox className="flex-col" title="Off-cycle settings">
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Tax all earnings as supplemental earnings</span>
              {renderTrueFalseSpan(check_payroll.off_cycle_options?.force_supplemental_withholding)}
            </div>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Apply benefit contributions</span>
              {renderTrueFalseSpan(check_payroll.off_cycle_options?.apply_benefits)}
            </div>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title"}>Apply post-tax deductions</span>
              {renderTrueFalseSpan(check_payroll.off_cycle_options?.apply_post_tax_deductions)}
            </div>
          </DataBox>
        </div>
      )}
      <div className="vertical-spacer"></div>
    </div>
  );
};

export default OffCycleOptions;
