import React from "react";
import { Helmet } from "react-helmet";
import { CustomerTable } from "./CustomerTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";

// Component

export const Customers: React.FC = () => {
  const { cannot } = useMiterAbilities();

  return (
    <div className="page-wrapper">
      <Helmet>
        <title> Customers | Miter</title>
      </Helmet>
      <div className="page-content">
        <h1 className="view-title title-with-toggler">Customers</h1>
        <div className="vertical-spacer" />
        <CustomerTable readonly={cannot("lists:customers:manage")} />
      </div>
    </div>
  );
};
