import { useForm } from "react-hook-form";
import {
  FoundationConnectionMetadata,
  FoundationJobStatus,
} from "backend/services/foundation/foundation-types";
import { buildAtomicMongoUpdateFromNested, convertUndefinedToMongoUnset } from "dashboard/utils";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { Option } from "ui/form/Input";
import { Formblock, SettingsCard } from "ui";

const INPUTLENGTH = 250;

const jobInactiveStatuses: FoundationJobStatus[] = ["Active", "Closed", "Overhead", "Bid"];
const jobStatusOptions = jobInactiveStatuses.map((value) => ({ label: value, value }));

export const FoundationConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();
  const foundationMetaData = integration.connection?.metadata?.foundation;

  const updateFoundationMetadata = async (metadataUpdate: DeepPartial<FoundationConnectionMetadata>) => {
    const raw = { metadata: { sage_300: metadataUpdate } };
    const flattened = buildAtomicMongoUpdateFromNested(raw);
    const finalUpdate = convertUndefinedToMongoUnset(flattened);

    await updateIntegrationConnection(finalUpdate);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Job Sync">
        <Formblock
          form={form}
          options={jobStatusOptions}
          name="activeStatusValues"
          type="multiselect"
          defaultValue={foundationMetaData?.jobsSync?.activeJobStatuses}
          onChange={(options: Option<FoundationJobStatus>[] | null) => {
            const statuses = options?.map((o) => o.value);
            updateFoundationMetadata({ jobsSync: { activeJobStatuses: statuses } });
          }}
          label="Active Job Statuses"
          editing={true}
          labelInfo="Foundation Job statuses Miter will consider to be 'Active' when syncing jobs"
          underlineTooltip
          isClearable
          inputProps={{ style: { width: INPUTLENGTH } }}
        />
      </SettingsCard>
    </div>
  );
};
