import React, { useMemo } from "react";
import { ConditionForm } from "./PolicyRuleBuilder";
import { Formblock, IconWithTooltip, Notifier } from "ui";
import { Option } from "ui/form/Input";
import { cloneDeep, debounce, set } from "lodash";
import { OperatorOptions, getApprovalItemType } from "./PolicyConstants";
import pixelWidth from "string-pixel-width";

import styles from "./PolicyRules.module.css";
import { UnderlinedSelectStyles } from "dashboard/pages/timesheets/BulkCreateTimesheets/SelectStyles";
import { PolicyRule } from "dashboard/miter";
import { Info } from "phosphor-react";
import { PolicyType } from "backend/services/approvals/types";
import pluralize from "pluralize";

type RuleConditionBuilderProps = {
  policyType: PolicyType;
  allRules: PolicyRule[];
  condition: ConditionForm;
  setCondition: React.Dispatch<React.SetStateAction<ConditionForm>>;
  fieldOptions: Option<string>[];
};

export const RuleConditionBuilder: React.FC<RuleConditionBuilderProps> = ({
  policyType,
  allRules,
  condition,
  setCondition,
  fieldOptions,
}) => {
  const itemType = getApprovalItemType(policyType);

  const operatorOptions = useMemo(() => {
    return OperatorOptions.filter((option) => condition?.field?.operators?.includes(option.value));
  }, [condition?.field]);

  /**
   * Updates a condition property based on the provided key and value.
   * - Resets the value if operator or field is changed.
   * - Resets the operator if the field is changed.
   */
  const handleUpdateField = (key: string, value: string | string[] | (string | undefined)[]) => {
    setCondition((prev) => {
      const newCondition = cloneDeep(prev) || { field: undefined, operator: undefined, value: undefined };
      set(newCondition, key, value);

      // Reset value if operator is changed
      if (key === "operator" || key === "field") {
        set(newCondition, "value", undefined);
        set(newCondition, "value_label", undefined);
      }

      // Reset operator if field is changed
      if (key === "field") {
        set(newCondition, "operator", null);
      }

      return newCondition;
    });
  };

  const invalidNumberNotifier = debounce(() => {
    Notifier.error(`Invalid number in rule condition. Make sure only numbers are used - no commas.`);
  }, 500);

  /**
   * Handles the update of the value based on the condition's operator field.
   * - Directly updates the value if the operator's field is "value".
   * - Updates either the start or end of a range if the operator's field is "range".
   */
  const handleUpdateValue = (props: { label?: string; value: string; index?: number }) => {
    const { label, value, index } = props;
    if (!condition) return;

    // make sure the condition field is a valid number
    if (condition?.field?.type === "number") {
      // @ts-expect-error isNaN expects a number, but works with strings!
      if (isNaN(value)) {
        invalidNumberNotifier();
        return;
      }
    }

    if (condition?.operator?.field === "value") {
      handleUpdateField("value", value);

      // also update value_label if it exists from a select field
      if (label) {
        handleUpdateField("value_label", label);
      }
    } else if (condition?.operator?.field === "range") {
      if (index === 0) {
        handleUpdateField("value", [value, condition?.value?.[1]]);
      } else if (index === 1) {
        handleUpdateField("value", [condition?.value?.[0], value]);
      } else {
        throw new Error("Invalid index");
      }
    } else {
      throw new Error("Invalid operator");
    }
  };

  const renderConditionForm = () => {
    if (!condition) return;

    // Calculate the field with to be the estimated length of the field
    const fieldWidth = condition?.field
      ? Math.min(Math.max(pixelWidth(condition.field.label, { size: 50, font: "inter" }), 80), 180)
      : 100;

    const operatorWidth = condition?.operator
      ? Math.min(Math.max(pixelWidth(condition.operator.label, { size: 50, font: "inter" }), 80), 180)
      : 100;

    return (
      <div className={"flex "}>
        <h3 className={styles["policy-rule-builder-condition-header-title"]}>If</h3>
        <Formblock
          type="select"
          name="field"
          editing={true}
          className="modal approval-flow"
          placeholder={"Field"}
          options={fieldOptions}
          style={{ marginRight: 15, marginLeft: 10, width: fieldWidth, marginBottom: -1 }}
          onChange={(o) => handleUpdateField("field", o)}
          value={condition?.field}
          selectStyles={UnderlinedSelectStyles}
        />
        <Formblock
          type="select"
          name="operator"
          editing={true}
          className="modal approval-flow"
          placeholder={"operator"}
          options={operatorOptions}
          style={{ marginRight: 15, marginBottom: -1, width: operatorWidth }}
          onChange={(o) => handleUpdateField("operator", o)}
          value={condition?.operator}
          selectStyles={UnderlinedSelectStyles}
        />
        {condition?.operator?.field === "value" &&
          (typeof condition.value === "string" || condition?.value == null) &&
          !condition.field?.opts && (
            <Formblock
              type="text"
              name="value"
              editing={true}
              className="modal approval-flow condition-input"
              placeholder={"value"}
              onChange={(e) => handleUpdateValue({ value: e.target.value })}
              value={condition?.value}
              style={{ marginBottom: -1 }}
            />
          )}
        {condition?.operator?.field === "value" &&
          (typeof condition.value === "string" || condition?.value == null) &&
          condition.field?.opts && (
            <Formblock
              type="select"
              name="value"
              editing={true}
              className="modal approval-flow"
              placeholder={"value"}
              onChange={(o) => handleUpdateValue({ value: o.value, label: o.label })}
              options={condition.field.opts}
              value={condition.field.opts.find((o) => o.value === condition.value)}
              style={{ width: 100, marginBottom: -1 }}
              selectStyles={UnderlinedSelectStyles}
            />
          )}
        {condition?.operator?.field === "range" && (
          <div className="flex align-items-center">
            <Formblock
              type="text"
              name="value[0]"
              editing={true}
              className="modal approval-flow condition-input"
              placeholder={"value"}
              onChange={(e) => handleUpdateValue({ value: e.target.value, index: 0 })}
              value={condition?.value?.[0] || ""}
              style={{ width: 70, marginBottom: -1 }}
            />
            <span
              style={{
                marginLeft: 10,
                marginRight: 10,
                color: "#333",
                fontWeight: "bold",
                fontSize: "1.08rem",
              }}
            >
              and
            </span>
            <Formblock
              type="text"
              name="value[1]"
              editing={true}
              className="modal approval-flow condition-input"
              placeholder={"value"}
              onChange={(e) => handleUpdateValue({ value: e.target.value, index: 1 })}
              value={condition?.value?.[1] || ""}
              style={{ width: 70, marginBottom: -1 }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderConditionlessHeader = () => {
    const hasCondition = !!condition?.field;
    const hasOnlyDefaultRule = allRules.length === 1 && !hasCondition;

    const label = hasOnlyDefaultRule ? "Default rule" : "Fallback rule";
    const tooltipText = hasOnlyDefaultRule
      ? `This rule will be applied to all ${pluralize(itemType)}.`
      : `This rule will be applied to all  ${pluralize(itemType)} that don't match any of the other rules.`;

    return (
      <div className="flex">
        <h3 className={styles["policy-rule-builder-condition-header-title"]}>{label}</h3>
        <IconWithTooltip
          PhosphorIcon={Info}
          tooltip={tooltipText}
          style={{ color: "#4d55bb", marginLeft: 10, marginTop: 2 }}
        />
      </div>
    );
  };

  return (
    <div className={styles["policy-rule-builder-condition"]}>
      {!condition && renderConditionlessHeader()}
      {renderConditionForm()}
    </div>
  );
};
