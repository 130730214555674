import React from "react";
import { ClickAwayListener } from "@material-ui/core";

import { Formblock, Notifier } from "ui";
import {
  eeoEthnicityCategories,
  eeoJobCategories,
  eeoGenderCategories,
  eeoVeteranStatusCategories,
  eeoMaritalStatusCategories,
  eeoDisabilityStatusCategories,
} from "miter-utils";
import { ModalHeader } from "ui";
import { ModalFooter } from "ui";
import { TeamMember } from "backend/models";
import { AggregatedTeamMember } from "dashboard/miter";
import { useEnabledDemographicQuestions } from "dashboard/utils";

type PatchTeamMemberBody = Partial<TeamMember> & {
  workplace_address?: $TSFixMe;
};

type Props = {
  defaultData: AggregatedTeamMember;
  onHide: () => void;
  onSubmit: (update: PatchTeamMemberBody, scheduleChanges: boolean) => void;
  submitting: boolean;
  form: $TSFixMe;
  submitText?: string;
};

export const DemographicsForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  form,
  submitText,
}) => {
  const { control, errors, handleSubmit, formState } = form;
  const enabledDemographicQuestions = useEnabledDemographicQuestions();

  const cleanDataForUpdate = (data, scheduleChanges: boolean) => {
    const update: PatchTeamMemberBody = {};
    // Loop through dirtied fields and make sure formatting is
    // compatible with backend.
    for (const key of Object.keys(formState.dirtyFields)) {
      update[key] = data[key]?.value;
    }

    if (Object.keys(update).length === 0) {
      Notifier.error("No changes detected");
      return;
    }

    onSubmit({ demographics: { ...defaultData.demographics, ...update } }, scheduleChanges);
  };

  return (
    <ClickAwayListener onClickAway={() => {}}>
      <div className="modal-wrapper form">
        <ModalHeader onHide={onHide} heading={defaultData.first_name + "'s demographic info"} />
        <div className="modal-body form">
          <div className="vertical-spacer"></div>
          <div className="yellow-text-container">
            For more information on EEO reporting, visit the{" "}
            <span className="blue-link" onClick={() => window.open("https://www.eeoc.gov", "_blank")}>
              EEOC website
            </span>
            .
          </div>
          <div className="vertical-spacer"></div>
          {enabledDemographicQuestions["ethnicity"] && (
            <Formblock
              label="Ethnicity"
              labelInfo="This data must be self-reported by the employee"
              defaultValue={defaultData?.demographics?.ethnicity}
              type="select"
              name="ethnicity"
              options={eeoEthnicityCategories.map((c) => ({ label: c, value: c }))}
              control={control}
              className="modal small-margin"
              errors={errors}
              editing={true}
            />
          )}
          {enabledDemographicQuestions["gender"] && (
            <Formblock
              label="Gender"
              labelInfo="This data must be self-reported by the employee"
              defaultValue={defaultData?.demographics?.gender}
              type="select"
              name="gender"
              options={eeoGenderCategories.map((c) => ({ label: c, value: c }))}
              control={control}
              className="modal small-margin"
              errors={errors}
              editing={true}
            />
          )}
          {enabledDemographicQuestions["veteran_status"] && (
            <Formblock
              label="Veteran status"
              labelInfo="This data must be self-reported by the employee"
              defaultValue={defaultData?.demographics?.veteran_status}
              type="select"
              name="veteran_status"
              options={eeoVeteranStatusCategories.map((c) => ({ label: c, value: c }))}
              control={control}
              className="modal small-margin"
              errors={errors}
              editing={true}
            />
          )}
          {enabledDemographicQuestions["marital_status"] && (
            <Formblock
              label="Marital status"
              labelInfo="This data must be self-reported by the employee"
              defaultValue={defaultData?.demographics?.marital_status}
              type="select"
              name="marital_status"
              options={eeoMaritalStatusCategories.map((c) => ({ label: c, value: c }))}
              control={control}
              className="modal small-margin"
              errors={errors}
              editing={true}
            />
          )}
          {enabledDemographicQuestions["job_category"] && (
            <Formblock
              label="Job category"
              defaultValue={defaultData?.demographics?.job_category}
              type="select"
              name="job_category"
              options={eeoJobCategories.map((c) => ({ label: c, value: c }))}
              control={control}
              className="modal small-margin"
              errors={errors}
              editing={true}
            />
          )}
          {enabledDemographicQuestions["disability_status"] && (
            <Formblock
              label="Disability status"
              defaultValue={defaultData?.demographics?.disability_status}
              type="select"
              name="disability_status"
              options={eeoDisabilityStatusCategories.map((c) => ({ label: c, value: c }))}
              control={control}
              className="modal small-margin"
              errors={errors}
              editing={true}
            />
          )}

          <div className="vertical-spacer"></div>
        </div>
        <ModalFooter
          loading={submitting}
          onCancel={onHide}
          cancelText={"Cancel"}
          onSubmit={handleSubmit((data) => cleanDataForUpdate(data, false))}
          submitText={submitText || "Submit"}
          className="form"
          showEdit={submitText !== "Request changes"}
          editText={"Schedule changes"}
          onEdit={handleSubmit((data) => cleanDataForUpdate(data, true))}
        />
      </div>
    </ClickAwayListener>
  );
};
