import { SettingsCard, Formblock } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";

interface TimesheetWorkplaceSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetWorkplaceSettings: React.FC<TimesheetWorkplaceSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <SettingsCard title="Workplace settings" info={"Manage timesheet workplace settings"}>
      <Formblock
        type="checkbox"
        name="always_display_workplace_overrides"
        text="Always display workplace overrides on timesheets (dashboard only)"
        onChange={(e) => handleChange({ always_display_workplace_overrides: e.target.checked })}
        checked={timesheetSettings.always_display_workplace_overrides}
        editing={true}
        labelInfo="If enabled, we will always display workplace overrides on the timesheet modal."
      />
    </SettingsCard>
  );
};

export default TimesheetWorkplaceSettings;
