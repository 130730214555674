import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";
import { MiterAPI } from "dashboard/miter";
import { useUserAtom } from "dashboard/hooks/atom-hooks";

const UserSettingsModal: React.FC = () => {
  const form = useForm();
  const { setShowUserSettingsModal } = useContext(AppContext);
  const [user, setUser] = useUserAtom();
  const [loading, setLoading] = useState(false);

  const { control, register, errors, handleSubmit, setError } = form;

  // Remove properties that equal what exists in the user object
  const cleanParams = (data) => {
    if (!user) return {};

    if (!data.phone && !data.email) {
      throw new Error("You must have either a phone number or an email address.");
    }

    // We need to set these to null to show that we are deleting them
    if (user.phone && !data.phone) data.phone = null;
    if (user.email && !data.email) data.email = null;

    // Make sure we only send the properties that have changed
    return Object.keys(data).reduce((acc, key) => {
      if (user![key] !== data[key]) {
        acc[key] = data[key];
      }
      return acc;
    }, {});
  };

  const updateUser = async (data) => {
    if (!user) return;
    setLoading(true);
    try {
      const params = cleanParams(data);
      const res = await MiterAPI.users.update(user?._id, params);

      if (res.error_type === "user_phone_in_use_by_another_user") {
        setError("phone", {
          message:
            "This phone number is already in use by another user. If this user is your team member account, create a new role for yourself with the same email as your team member to connect your account.",
        });
      }

      if (res.error_type === "user_email_in_use_by_another_user") {
        setError("email", {
          message:
            "This email is already in use by another user. If this user is your team member account, create a new role for yourself with the same email as your team member to connect your account.",
        });
      }

      if (res.error) throw new Error(res.error);

      setUser(res);
      setShowUserSettingsModal(false);
      Notifier.success("User updated successfully");
    } catch (e: $TSFixMe) {
      console.error("Error updating user:", e);
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const submit = () => {
    handleSubmit(updateUser)();
  };

  const hide = () => {
    setShowUserSettingsModal(false);
  };

  const renderForm = () => {
    return (
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <Formblock
          type="text"
          name="first_name"
          label="First name"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={user?.first_name}
          className="modal"
        />
        <Formblock
          type="text"
          name="last_name"
          label="Last name"
          control={control}
          register={register(vals.required)}
          editing={true}
          errors={errors}
          defaultValue={user?.last_name}
          className="modal"
        />
        <Formblock
          type="text"
          name="email"
          label="Email"
          control={control}
          register={register}
          editing={true}
          errors={errors}
          defaultValue={user?.email}
          className="modal"
        />
        <Formblock
          type="phone"
          name="phone"
          label="Phone number"
          control={control}
          register={register}
          editing={true}
          errors={errors}
          defaultValue={user?.phone}
          className="modal"
        />
        <Formblock
          type="checkbox"
          name="email_notifications_disabled"
          label="Disable email notifications"
          text="I do not want to receive any Miter-related emails."
          control={control}
          register={register}
          editing={true}
          errors={errors}
          defaultValue={user?.email_notifications_disabled}
          className="modal"
        />
      </div>
    );
  };

  return (
    <ActionModal
      headerText={"User settings"}
      showSubmit={true}
      showCancel={true}
      cancelText={"Close"}
      onCancel={hide}
      submitText={"Update"}
      onHide={hide}
      onSubmit={submit}
      loading={loading}
    >
      {renderForm()}
    </ActionModal>
  );
};

export default UserSettingsModal;
