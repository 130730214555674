import { DailyReportTableRow } from "dashboard/components/daily-reports/DailyReportsTable";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useCallback, useMemo } from "react";
import { DailyReport } from "backend/models";
import { useActiveTeamMember } from "../atom-hooks";
import { AggregatedDailyReport, AggregatedJob, AggregatedTeamMember, Job } from "dashboard/miter";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";

type DailyReportParams =
  | DailyReport
  | DailyReport[]
  | AggregatedDailyReport
  | AggregatedDailyReport[]
  | DailyReportTableRow
  | DailyReportTableRow[]
  | undefined;

type DailyReportAction = "create" | "read" | "update" | "approve" | "delete";
type DailyReportAbilities = {
  can: (action: DailyReportAction, items: DailyReportParams) => boolean;
  cannot: (action: DailyReportAction, items: DailyReportParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: DailyReportAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: DailyReportAction) => (job: AggregatedJob | Job) => boolean;
};

export const useDailyReportAbilities = (): DailyReportAbilities => {
  const { can: can_ } = useMiterAbilities();
  const activeTeamMember = useActiveTeamMember();

  const can = useCallback(
    (action: DailyReportAction, items: DailyReportParams) => {
      if (!items) return false;

      const dailyReports = Array.isArray(items) ? items : [items];

      return dailyReports.every((report) => {
        const teamMemberId = getTeamMemberId(report);
        const jobId = getJobId(report);

        const isPersonal =
          typeof report.creator === "string"
            ? report.creator === activeTeamMember?._id
            : report.creator._id === activeTeamMember?._id;

        if (isPersonal) {
          switch (action) {
            case "create":
              return can_("daily_reports:personal:create");
            case "read":
              return can_("daily_reports:personal:read");
            case "update":
              return can_("daily_reports:personal:update");
            case "approve":
              return can_("daily_reports:personal:approve");
            case "delete":
              return can_("daily_reports:personal:delete");
            default:
              return false;
          }
        } else {
          const opts = { teamMember: teamMemberId, job: jobId };

          switch (action) {
            case "create":
              return can_("daily_reports:others:create", opts);
            case "read":
              return can_("daily_reports:others:read", opts);
            case "update":
              return can_("daily_reports:others:update", opts);
            case "approve":
              return can_("daily_reports:others:approve", opts);
            case "delete":
              return can_("daily_reports:others:delete", opts);
            default:
              return false;
          }
        }
      });
    },
    [can_, activeTeamMember]
  );

  const cannot = useCallback(
    (action: DailyReportAction, items: DailyReportParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "daily_reports:personal",
    othersPermissionPath: "daily_reports:others",
    teamMemberField: { fieldName: "creator._id", fieldType: "_id" },
    jobField: { fieldName: "job._id", fieldType: "_id" },
    appModule: "workforce_management",
  });

  const teamPredicate = useAbilitiesTeamPredicate<DailyReportAction>("daily_reports");

  const jobPredicate = useAbilitiesJobPredicate<DailyReportAction>("daily_reports");

  return useMemo(
    () => ({ can, cannot, filter, teamPredicate, jobPredicate }),
    [can, cannot, filter, teamPredicate, jobPredicate]
  );
};

const getTeamMemberId = (item: DailyReport | AggregatedDailyReport | DailyReportTableRow): string => {
  return typeof item.creator === "string" ? item.creator : item.creator?._id;
};

const getJobId = (item: DailyReport | AggregatedDailyReport | DailyReportTableRow): string | undefined => {
  return typeof item.job === "string" ? item.job : item.job?._id;
};
