import { useHasAccessToImmediateActionCenterNotifs } from "dashboard/gating";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { Company } from "dashboard/miter";
import React from "react";
import { Formblock, Label, SettingsCard, SettingsPage } from "ui";

type SettingsForm = NonNullable<NonNullable<Company["settings"]["action_center"]>>;

const SETTINGS_PATH = "settings.action_center";

export const ApprovalSettings: React.FC = () => {
  const { form, handleFieldUpdate } = useSettingsForm<SettingsForm>(SETTINGS_PATH);
  const hasImmediateNotifications = useHasAccessToImmediateActionCenterNotifs();

  const renderNotificationSubSettings = (
    type: "daily" | "weekly" | "immediate",
    path: "approvals" | "needs_attention"
  ) => {
    return (
      <div style={{ marginLeft: 15 }}>
        <Formblock
          type="checkbox"
          text="SMS notification"
          name={`${path}.notifications.${type}.sms`}
          className="modal "
          form={form}
          sideBySideInput={false}
          editing={true}
          onChange={(e) => handleFieldUpdate(e.target.checked, `${path}.notifications.${type}.sms`)}
          style={{ marginBottom: 0 }}
        />
        <Formblock
          type="checkbox"
          text="Email notification"
          name={`${path}.notifications.${type}.email`}
          className="modal "
          form={form}
          sideBySideInput={false}
          editing={true}
          onChange={(e) => handleFieldUpdate(e.target.checked, `${path}.notifications.${type}.email`)}
        />
      </div>
    );
  };

  return (
    <SettingsPage
      title="Approval settings"
      subtitle="Configure global approvals related settings."
      backText="Back to approvals"
      backLink="/inbox/approvals"
    >
      <SettingsCard title={"Approver Notifications"}>
        <Label
          label="Send a daily notification to approvers with their unapproved items."
          labelInfo="Daily notifications are sent at at the start of the work day in the approver's time zone."
          className="form2-label"
          style={{ width: "100%" }}
        />
        {renderNotificationSubSettings("daily", "approvals")}
        <Label
          label="Send a weekly notification to approvers with their unapproved items."
          labelInfo="Weekly notifications are sent on Monday mornings in the approver's time zone."
          className="form2-label"
          style={{ width: "100%" }}
        />
        {renderNotificationSubSettings("weekly", "approvals")}
        {hasImmediateNotifications && (
          <>
            <Label
              label="Send an immediate notification to approvers when a new item is submitted for approval."
              labelInfo="Immediate notifications are sent as soon as the item is submitted or moves to the next approval step."
              className="form2-label"
              style={{ width: "100%" }}
            />
            {renderNotificationSubSettings("immediate", "approvals")}
          </>
        )}
      </SettingsCard>
      <SettingsCard
        title={"Team Member Notifications"}
        info="A team member could also be an approver - ex. a job supervisor submits timesheets and also approves those of others"
      >
        <Label
          label="Send a daily notification to team members with their items that don't meet policy requirements."
          labelInfo="Daily notifications are sent at the start of the work day in the team member's time zone."
          className="form2-label"
          style={{ width: "100%" }}
        />
        {renderNotificationSubSettings("daily", "needs_attention")}
        <Label
          label="Send a weekly notification to team members with their items that don't meet policy requirements."
          labelInfo="Weekly notifications are sent on Monday mornings in the team member's time zone."
          className="form2-label"
          style={{ width: "100%" }}
        />
        {renderNotificationSubSettings("weekly", "needs_attention")}
      </SettingsCard>
    </SettingsPage>
  );
};
