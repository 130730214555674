import { Candidate } from "dashboard/types/ats";
import { Wizard } from "ui";
import React, { FC } from "react";
import { MergeCandidatesWizardScreen } from "./MergeCandidatesWizardScreen";

export type MergeCandidatesWizardProps = {
  primaryCandidate: Candidate;
  secondaryCandidates: Candidate[];
  onExit: () => void;
  onComplete: () => void;
};

export const MergeCandidatesWizard: FC<MergeCandidatesWizardProps> = ({
  primaryCandidate,
  secondaryCandidates,
  onExit,
  onComplete,
}) => {
  return (
    <Wizard onExit={onExit} onComplete={onComplete}>
      <MergeCandidatesWizardScreen
        primaryCandidate={primaryCandidate}
        secondaryCandidates={secondaryCandidates}
        name={"Merge candidates"}
      />
    </Wizard>
  );
};
