import { Formblock, SettingsCard } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import { useHasAccessToBulkEntryPayRates } from "dashboard/gating";

interface TimesheetsBulkCreationSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetsBulkCreationSettings: React.FC<TimesheetsBulkCreationSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  const hasAccessToBulkEntryPayRates = useHasAccessToBulkEntryPayRates();
  if (!hasAccessToBulkEntryPayRates) return null;
  return (
    <SettingsCard title="Timesheet bulk creation" info={"Configure the timesheets bulk creation settings"}>
      <Formblock
        type="checkbox"
        name="show_pay_rate_in_bulk_create"
        text="Show pay rate in the timesheets bulk creation view"
        onChange={(e) => handleChange({ show_pay_rate_in_bulk_create: e.target.checked })}
        checked={timesheetSettings.show_pay_rate_in_bulk_create}
        editing={true}
      />
    </SettingsCard>
  );
};

export default TimesheetsBulkCreationSettings;
