import { license_status } from "dashboard/pages/team-members/forms/options";
import React from "react";
import { useState } from "react";
import { BasicModal } from "ui";

type ApprenticeConfirmation = {
  handleQualificationChange: (e) => void;
  ApprenticeConfirmationModal: React.FC<{ companyState: string }>;
};

const apprenticeConfirmationText = (companyState: string): string => {
  let text = "";
  if (companyState === "CA") {
    text = `Apprentices must be in good standing on the California Apprenticeship Council database in order to perform an apprentice-level activity. `;
  }
  text += `For Davis-Bacon jobs, apprentices must have a DOL certification letter on file. Tradesman, Helper, and Tender are not considered an Apprentice.`;
  return text;
};

export const useApprenticeConfirmation = (
  apprenticeFieldName: string,
  formData: Record<string, unknown>,
  setValue: $TSFixMeFunction,
  onConfirm?: (newValue?: "journeyman" | "apprentice") => void
): ApprenticeConfirmation => {
  const [confirmingApprentice, setConfirmingApprentice] = useState(false);
  const [selectedQualification, setSelectedQualification] = useState<"apprentice" | "journeyman">();

  const handleQualificationChange = (e): void => {
    setSelectedQualification(e.value);
    if (e.value === "apprentice" && formData[apprenticeFieldName] !== e.value) {
      setConfirmingApprentice(true);
    } else {
      onConfirm?.(e.value);
    }
  };

  const handleDisconfirmingApprentice = (): void => {
    setConfirmingApprentice(false);
    setValue(
      apprenticeFieldName,
      license_status.find((o) => o.value === "journeyman")
    );
  };

  const handleButton2 = () => {
    onConfirm?.(selectedQualification);
    setConfirmingApprentice(false);
  };

  const ApprenticeConfirmationModal: React.FC<{ companyState: string }> = ({ companyState }) => {
    return confirmingApprentice ? (
      <BasicModal
        headerText="Confirm apprentice definition"
        yellowBodyText={true}
        bodyText={apprenticeConfirmationText(companyState)}
        button1Text="Cancel"
        button1Action={handleDisconfirmingApprentice}
        button2Text="Confirm"
        button2Action={handleButton2}
      />
    ) : (
      <></>
    );
  };

  return {
    handleQualificationChange,
    ApprenticeConfirmationModal,
  };
};
