import { getOrdinalSuffix } from "dashboard/utils";
import { Assignment, AggregatedJob, Crew, AggregatedTeamMember } from "dashboard/miter";
import { RecurrenceConfig } from "backend/models/assignment";
import { Option } from "ui/form/Input";
import { DateTime } from "luxon";
import { useJobNameFormatter } from "dashboard/hooks/atom-hooks";
import { useAssignmentAbilities } from "dashboard/hooks/abilities-hooks/useAssignmentAbilities";

export const getWeekOfTheMonth = (day: number): string => {
  return getOrdinalSuffix(Math.floor(day / 7) + 1);
};

export const longDaysArray = [
  "", // Need to account for days_of_the_week being 1-indexed
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Prepares the data that the form generates to be saved in the backend
export const prepareDataForBackend = (data: $TSFixMe): Partial<Assignment> => {
  if (!data) throw Error("Please fill out the form before submitting.");

  const team_members = (data.team_members || []).map((tm) => tm._id);
  const address = { ...data.address, state: data.address.state?.value };

  return {
    ...data,
    title: data.title.substring(0, 100),
    description: data.description.substring(0, 1000),
    starts_at: data.starts_at.toSeconds(),
    ends_at: data.ends_at.toSeconds(),
    job: data.job?.value || null,
    equipment_ids: data.equipment_ids?.map((e) => e.value) || [],
    address,
    team_members,
  };
};

type FindSelectedRepeatOptionResponse = {
  matchingOption: Option<string> | null;
  isNewOption: boolean;
};

export const findMatchingRepeatOption = (
  draftRecurrenceConfig: RecurrenceConfig,
  options: Option<string>[],
  startsAt: DateTime | undefined
): FindSelectedRepeatOptionResponse => {
  const {
    time_period,
    interval,
    total_occurrences,
    days_of_the_week,
    day_of_the_month,
    week_of_the_month,
    last_occurrence,
  } = draftRecurrenceConfig;
  let label = "";
  if (interval > 1) {
    label = `Every ${interval} ${time_period}s`;
  } else {
    if (time_period === "day") {
      label = "Daily";
    } else if (time_period === "year") {
      label = "Annually";
    } else {
      label = `${time_period}ly`.replace(/^\w/, (c) => c.toUpperCase());
    }
  }
  if (time_period === "week" && (days_of_the_week || [])?.length > 0) {
    const filteredDays = days_of_the_week?.map((item) => longDaysArray[item]) || [];
    label = label + ` on ${filteredDays.join(", ")}${days_of_the_week?.length === 1 ? "s" : ""}`;
  }
  if (time_period === "month") {
    if (day_of_the_month) {
      label = label + ` on day ${startsAt?.day}`;
    }
    if (week_of_the_month && startsAt) {
      label = label + ` on the ${getWeekOfTheMonth(startsAt?.day)} ${startsAt?.weekdayLong}`;
    }
  }
  if (time_period === "year") {
    label = label + ` on ${startsAt?.monthLong} ${startsAt?.day}`;
  }

  if (last_occurrence) {
    const endOnDate = DateTime.fromSeconds(last_occurrence);
    label = label + `, until ${endOnDate.monthLong} ${endOnDate.day}, ${endOnDate.year}`;
  }

  if (Number(total_occurrences) > 0) {
    label = label + `, ${total_occurrences} times`;
  }

  if (label === "Weekly on Monday, Tuesday, Wednesday, Thursday, Friday") {
    return {
      isNewOption: true,
      matchingOption: { value: "every_weekday", label: "Every weekday (Monday to Friday)" },
    };
  }

  const found = options.find((element) => element.label === label);
  if (found) return { matchingOption: found, isNewOption: false };
  const value = "custom_config";
  return { isNewOption: true, matchingOption: { value, label } };
};

export const returnAssignmentFromArray = (assignments: Assignment[], id: string): Assignment | undefined => {
  return assignments.find((assignment) => assignment.mocked_id === id || assignment._id.toString() === id);
};

export type Resource = {
  id: string;
  job?: string;
  team_member?: string;
  title: string;
  type: "job" | "team_member";
  crews?: string;

  crewList?: string[];
  departmentId?: string;
};

export const getJobTimelineResources = (
  jobs: AggregatedJob[],
  tms: AggregatedTeamMember[],
  assignments: Assignment[]
): Resource[] => {
  const resources: Resource[] = [];
  for (const job of jobs) {
    const jobAssignments = assignments.filter((a) => a.job === job._id.toString());
    if (jobAssignments.length === 0) {
      resources.push({
        id: job._id.toString(),
        job: job.name,
        title: "-",
        type: "job",
      });
    } else {
      const tmIds = jobAssignments.flatMap((a) => a.team_members);
      const uniqueTmIds = new Set([...tmIds]);
      for (const tmId of uniqueTmIds) {
        const tm = tms.find((t) => t._id.toString() === tmId);
        resources.push({
          id: String(resources.length + 1),
          job: job.name,
          title: tm?.full_name || "-",
          type: "job",
        });
      }
    }
  }
  return resources;
};

export const getTmTimelineResources = (
  jobs: AggregatedJob[],
  tms: AggregatedTeamMember[],
  assignments: Assignment[]
): Resource[] => {
  const resources: Resource[] = [];
  for (const tm of tms) {
    const tmAssignments = assignments.filter((a) => a.team_members.includes(tm._id.toString()));
    if (tmAssignments.length === 0) {
      resources.push({
        id: tm._id.toString(),
        team_member: tm.full_name,
        title: "-",
        type: "team_member" as const,
      });
    } else {
      const jobIds = tmAssignments.map((a) => a.job);
      const uniqueJobIds = new Set([...jobIds]);
      for (const jobId of uniqueJobIds) {
        const job = jobs.find((j) => j._id.toString() === jobId);
        resources.push({
          id: tm._id.toString(),
          team_member: tm.full_name,
          title: job?.name || "-",
          type: "team_member" as const,
        });
      }
    }
  }
  return resources;
};

type SchedulingResourcesParams = {
  jobs: AggregatedJob[];
  teamMembers: AggregatedTeamMember[];
  crews: Crew[];
  assignments: Assignment[];
  activeView: string | undefined;
  resourceType: "job" | "team_member";
};

type UseSchedulingResources = {
  getResources: (params: SchedulingResourcesParams) => Resource[];
};

export const useSchedulingResources = (): UseSchedulingResources => {
  const jobNameFormatter = useJobNameFormatter();
  const assignmentAbilities = useAssignmentAbilities();

  const getResources = (params: SchedulingResourcesParams): Resource[] => {
    const { jobs, teamMembers, crews, assignments, activeView, resourceType } = params;

    let resources: Resource[] = [];
    const activeJobs = jobs
      .filter((job) => job.status === "active")
      .filter((job) => assignmentAbilities.jobPredicate("read")(job));

    if (activeView === "resourceTimelineMonth") {
      if (resourceType === "team_member") {
        resources = getTmTimelineResources(activeJobs, teamMembers, assignments);
      } else if (resourceType === "job") {
        resources = getJobTimelineResources(activeJobs, teamMembers, assignments);
      }
    } else {
      if (resourceType === "team_member") {
        resources = teamMembers
          .filter((tm) => assignmentAbilities.teamPredicate("read")(tm))
          .map((tm) => {
            const tmCrews = crews.filter((crew) => crew.team_member_ids.includes(tm._id.toString()));

            const crewString =
              crews.length > 0 && typeof crews !== "string"
                ? tmCrews.map((crew) => crew.name).join(", ")
                : "";

            const subhead =
              (tm.friendly_id ? tm.friendly_id : "") +
              (tm.friendly_id && tm.title ? "   |     " : "") +
              (tm.title || "") +
              (tm.department ? "   |     " + tm.department?.name : "");

            return {
              id: tm._id.toString(),
              title: tm.full_name,
              subhead,
              crews: crewString,
              crewList: tmCrews.map((crew) => crew._id.toString()),
              departmentId: tm.department?._id.toString(),
              type: "team_member" as const,
            };
          });
      } else if (resourceType === "job") {
        resources = activeJobs.map((job) => {
          return {
            id: job._id.toString(),
            title: jobNameFormatter(job),
            type: "job" as const,
          };
        });
      }
    }
    return resources;
  };

  return { getResources };
};
