import React from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock } from "ui";
import {
  EXPENSE_ATTACHMENT_ACCEPTED_FILE_TYPES,
  EXPENSE_ATTACHMENT_MAX_FILE_SIZE,
  EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED,
} from "../expenseUtils";
import {
  EXPENSE_REIMBURSEMENT_EDIT_MODE,
  ExpenseReimbursementModalFormData,
} from "./ExpenseReimbursementModalForm";
import * as vals from "dashboard/utils/validators";
import { useExpenseReimbursementPolicy } from "dashboard/utils/policies/expense-reimbursement-policy-utils";

type Props = {
  editingMode?: EXPENSE_REIMBURSEMENT_EDIT_MODE;
  form: UseFormMethods<ExpenseReimbursementModalFormData>;
};
export const ExpenseReimbursementOutOfPocketPurchaseDataFields: React.FC<Props> = ({ editingMode, form }) => {
  const { watch, register, errors, control } = form;
  const formData = watch();
  const { isFieldHidden, isFieldLocked, isFieldRequired } = useExpenseReimbursementPolicy(formData);

  const receiptsNotEmpty = {
    validate: (_v: string) => {
      if (isFieldRequired("file_ids")) {
        return vals.filesListNotEmpty(formData["attachments"] ?? [], "Must include a receipt.");
      }
      return true;
    },
  };

  return (
    <>
      <h4>Purchase Data</h4>
      <Formblock
        type="unit"
        unit="$"
        name="amount"
        className="modal"
        label={"Amount*"}
        placeholder="0.00"
        form={form}
        editing={editingMode === "all_fields"}
        register={register(
          vals.numberValidator({
            required: true,
            excludeNegatives: true,
            excludeZero: true,
            maxDecimals: 2,
          })
        )}
        control={control}
        errors={errors}
        disabled={isFieldLocked("amount")}
        defaultValue={formData.amount}
      />
      <Formblock
        label="Merchant*"
        labelInfo="Merchant purchased from."
        type="text"
        form={form}
        register={register(vals.required)}
        control={control}
        errors={errors}
        name="vendor_name"
        className="modal"
        editing={editingMode === "all_fields"}
        placeholder="E.g., Home Depot"
        disabled={isFieldLocked("merchant_name")}
        defaultValue={formData.vendor_name}
      />
      <div style={{ marginTop: "15px" }}>
        <Formblock
          type="file"
          className="modal"
          label={`Receipts` + (isFieldRequired("file_ids") ? "*" : "")}
          name="attachments"
          editing={editingMode === "all_fields"}
          form={form}
          val={{ validate: receiptsNotEmpty }}
          maxFilesAllowed={EXPENSE_ATTACHMENT_MAX_NUMBER_OF_FILES_ALLOWED}
          compact={true}
          showPreviewModal={true}
          maxFileSize={EXPENSE_ATTACHMENT_MAX_FILE_SIZE}
          variant={"dropzone"}
          multiple={true}
          acceptedFileTypes={EXPENSE_ATTACHMENT_ACCEPTED_FILE_TYPES}
          hidden={isFieldHidden("file_ids")}
          defaultValue={formData.attachments}
        />
      </div>
    </>
  );
};
