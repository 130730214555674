import React, { FC } from "react";
import DataBox from "../dataBox/DataBox";
import { DataBoxRow } from "../dataBox/DataBoxRow";
import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";
import { DateTime } from "luxon";

type Props = {
  fillableTemplate: AggregatedFillableTemplate;
};

export const FillableTemplateDetails: FC<Props> = ({ fillableTemplate }) => {
  const { created_at, updated_at, original_file } = fillableTemplate;
  const createdAt = DateTime.fromSeconds(created_at).toLocaleString(DateTime.DATETIME_MED);
  const updatedAt = DateTime.fromSeconds(updated_at).toLocaleString(DateTime.DATETIME_MED);
  const fileSize = original_file.size ? original_file.size : 0;
  const fileSizeString =
    fileSize >= 1000000 ? `${Math.round(fileSize / 1000000)} MB` : `${Math.round(fileSize / 1000)} KB`;

  return (
    <div>
      <DataBox title="Fillable template" className="flex-col no-margin">
        <DataBoxRow label="Created at" value={createdAt} />
        <DataBoxRow label="Updated at" value={updatedAt} />
        <DataBoxRow label="File size" value={fileSizeString} />
      </DataBox>
    </div>
  );
};
