import { useClasses, useCostTypes, useDepartments, useLocations } from "./atom-hooks";

type OptionalDimensions = {
  hasClasses: boolean;
  hasDepartments: boolean;
  hasLocations: boolean;
  hasCostTypes: boolean;
};

export const useOptionalDimensionsUsage = (): OptionalDimensions => {
  const eligibleClasses = useClasses();
  const eligibleDepartments = useDepartments();
  const eligibleLocations = useLocations();
  const eligibleCostTypes = useCostTypes();

  return {
    hasClasses: eligibleClasses.length > 0,
    hasDepartments: eligibleDepartments.length > 0,
    hasLocations: eligibleLocations.length > 0,
    hasCostTypes: eligibleCostTypes.length > 0,
  };
};
