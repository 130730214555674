import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useActiveCompanyId, usePrgs, useRefetchPrgs } from "dashboard/hooks/atom-hooks";
import { MiterAPI, Trade } from "dashboard/miter";
import { createObjectMapToArray } from "dashboard/utils";
import { Notifier, SettingsCard, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TradeModal } from "./TradeModal";
import { useTrades } from "dashboard/hooks/useTrades";
import { MiterGuidesIcon } from "dashboard/components/MiterGuidesIcon";

type TradeData = Trade & {
  num_prgs: number;
};

export const Trades: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const refetchPrgs = useRefetchPrgs();
  const prgs = usePrgs();
  const { trades, fetchTrades } = useTrades();

  const { can, cannot } = useMiterAbilities();

  const [data, setData] = useState<TradeData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedTrades, setSelectedTrades] = useState<TradeData[]>([]);
  const [creating, setCreating] = useState<boolean>(false);
  const [clickedRow, setClickedRow] = useState<(Trade & { prgs: string[] }) | null>(null);

  const tradeToPrgsMap = useMemo(() => {
    return createObjectMapToArray(prgs, (prg) => prg.trade_id);
  }, [prgs]);

  const getData = useCallback(async () => {
    try {
      setData(
        trades?.map((trade) => {
          return {
            ...trade,
            num_prgs: tradeToPrgsMap[trade._id]?.length || 0,
          };
        }) || []
      );
    } catch (e: $TSFixMe) {}
  }, [tradeToPrgsMap, trades]);

  const handleSelect = (selections: TradeData[]) => {
    console.log(selections, "selections");
    setSelectedTrades(selections);
  };

  const deleteTrades = useCallback(
    async (selected: TradeData[]) => {
      setLoading(true);
      const selectedIds = selected.map((r) => r._id);

      try {
        const response = await MiterAPI.trades.delete(selectedIds);
        if (response.error) {
          throw new Error(response.error);
        }

        if (response.errors.length > 0) {
          const tradeText = response.errors.length > 1 ? " trades. " : " trade. ";
          throw new Error(
            "Failed to delete " + response.errors.length + tradeText + response.errors[0]?.message || ""
          );
        }
        Notifier.success("Deleted trades successfully.");
      } catch (e: $TSFixMe) {
        console.log(e);
        Notifier.error(e.message);
      }
      setSelectedTrades([]);
      fetchTrades();
      await refetchPrgs();
      setLoading(false);
    },
    [refetchPrgs, fetchTrades]
  );

  useEffect(() => {
    getData();
  }, [activeCompanyId, trades, getData]);

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add trade",
        className: "button-2 no-margin table-button",
        action: () => setCreating(true),
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
    ],
    [can]
  );

  const dynamicActions = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 table-button",
        action: deleteTrades,
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:pay_rate_groups:manage"),
      },
    ],
    [can, deleteTrades]
  );

  const handleHide = () => {
    setCreating(false);
    setClickedRow(null);
  };

  const onTradeCreation = async () => {
    setCreating(false);
    await refetchPrgs();
    fetchTrades();
  };

  const handleClick = (r: TradeData) => {
    const { num_prgs, ...rest } = r;
    const trade = {
      ...rest,
      prgs: tradeToPrgsMap[rest._id]?.map((prg) => prg._id) || [],
    };
    setClickedRow(trade);
    setCreating(true);
  };

  return (
    <>
      {creating && (
        <TradeModal
          readonly={cannot("lists:pay_rate_groups:manage")}
          hide={handleHide}
          onSubmit={onTradeCreation}
          trade={clickedRow}
        />
      )}
      <SettingsCard
        title={
          <div className="flex">
            Trades
            <MiterGuidesIcon path="/payroll/pay-rate-groups/trades" marginLeft={5} />
          </div>
        }
        contentStyle={{ color: "rgb(51,51,51)" }}
      >
        <TableV2
          isLoading={loading}
          id={"pay-rate-groups-table"}
          resource="pay rate groups"
          data={data}
          columns={columns}
          staticActions={staticActions}
          dynamicActions={dynamicActions}
          onSelect={handleSelect}
          defaultSelectedRows={selectedTrades}
          onClick={(r) => handleClick(r)}
        />
      </SettingsCard>
    </>
  );
};

const columns: ColumnConfig<TradeData>[] = [
  { headerName: "Trade", field: "name", dataType: "string" },
  { headerName: "# pay rate groups", field: "num_prgs", dataType: "string" },
];
