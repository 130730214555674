import { Stripe } from "dashboard/miter";
import { titleCase } from "dashboard/utils";
import React from "react";
import { useFieldArray, useForm, UseFormMethods } from "react-hook-form";
import { Formblock } from "ui";
import * as vals from "dashboard/utils/validators";
import styles from "./Expenses.module.css";

type Interval = Stripe.Issuing.Cardholder.SpendingControls.SpendingLimit.Interval;

type SpendingLimit = {
  amount: number;
  interval: Interval;
  categories: [];
};
type NullableObj<T> = { [P in keyof T]: T[P] | null };
type FormValues = {
  spending_limits: NullableObj<SpendingLimit>[];
};
export type ValidFormValues = {
  spending_limits: SpendingLimit[];
};

const blankSpendingLimit = (): NullableObj<SpendingLimit> => ({
  amount: null,
  interval: null,
  categories: null,
});

const possibleIntervals: Interval[] = [
  "per_authorization",
  "daily",
  "weekly",
  "monthly",
  "yearly",
  "all_time",
];
const intervalOptions = possibleIntervals.map((interval) => ({
  label: titleCase(interval),
  value: interval,
}));

const isValid = (val: NullableObj<SpendingLimit>): val is SpendingLimit =>
  val.amount != null && val.interval != null;

export const useSpendingLimitsForm = (
  defaultValues: FormValues = { spending_limits: [blankSpendingLimit()] }
): UseFormMethods<FormValues> => {
  const { handleSubmit: handleSubmit_, ...form } = useForm<FormValues>({ defaultValues });

  const handleSubmit: typeof handleSubmit_ = (onValid_, onInvalid) => (e) => {
    // HACK: If the form is just one blank row,
    // we want to allow it as a valid value,
    // and transform it into just "null"
    // to allow the user to delete all spending controls.
    const { spending_limits } = form.getValues();
    if (
      spending_limits?.length === 1 &&
      spending_limits[0]!.amount == null &&
      // @ts-expect-error weirdly typed.
      spending_limits[0]!.interval?.value == null
    ) {
      // @ts-expect-error this is a bit of a hack.
      return onValid_({ spending_limits: null });
    }

    const onValid = (values: ValidFormValues) => {
      const spendingLimits =
        values.spending_limits?.filter(isValid).map((val) => ({
          ...val,
          // @ts-expect-error Our select returns { value: string; label: string; } but should return just the value (a string).
          interval: val.interval.value,
        })) || null;
      // @ts-expect-error not sure what this is about.
      onValid_({ spending_limits: spendingLimits });
    };
    return handleSubmit_(onValid, onInvalid)(e);
  };
  return { ...form, handleSubmit };
};

type Props = {
  form: UseFormMethods<FormValues>;
  readonly?: boolean;
};

const ExpenseCardSpendingLimitsForm = ({ form, readonly }: Props): React.ReactElement => {
  const { control, register, errors, watch } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "spending_limits",
  });

  const values = watch("spending_limits");

  const handleRemove = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    remove(index);
    if (values.length <= 1) {
      append(blankSpendingLimit());
    }
  };

  return (
    <form className="form" onSubmit={() => append(blankSpendingLimit())}>
      <div className={"flex " + styles.margin_bottom_8 + " " + styles.small_text_on_gray}>
        <div className={styles.interval_label}>Interval</div>
        <div className={styles.margin_left_4}>Limit</div>
      </div>

      {fields.map((field, index) => (
        <div key={field.id}>
          <div className={"flex " + styles.align_items_flex_start + " " + styles.margin_bottom_12}>
            <Formblock
              placeholder="Interval"
              type="select"
              options={intervalOptions}
              defaultValue={field.interval}
              control={control}
              register={register(vals.required)}
              requiredSelect
              name={`spending_limits[${index}].interval`}
              className={styles.spending_limit_interval}
              errors={errors}
              inputProps={{ tabIndex: index * 2 + 1 }}
              editing={true}
              disabled={!!readonly}
            />
            <Formblock
              type="dollars-cents"
              defaultValue={field.amount}
              control={control}
              register={register(vals.isPositiveNumber)}
              name={`spending_limits[${index}].amount`}
              className="modal"
              errors={errors}
              editing={true}
              disabled={!!readonly}
              inputProps={{
                tabIndex: index * 2 + 2,
                onKeyDown: (e) => {
                  if (e.key === "Enter") {
                    append(blankSpendingLimit());
                  }
                  // If you press Delete on a blank field, remove it (unless it's the only field).
                  if (e.key === "Backspace" && !(e.target as $TSFixMe)?.value && values.length > 1) {
                    remove(index);
                  }
                },
              }}
            />
            {!readonly && (
              <div className={"formblock-wrapper " + styles.remove_icon}>
                <span className={styles.cursor_pointer} onClick={handleRemove(index)}>
                  &times;
                </span>
              </div>
            )}
          </div>
        </div>
      ))}

      {!fields.length && (
        <div className={`${styles.no_spending_limits_style} ${styles.small_text_is_light_gray}`}>
          <em>No spending limits.</em>
        </div>
      )}

      {!readonly && (
        <div className={"flex " + styles.justify_content_flex_end}>
          <button className="button-1 no-margin" type="button" onClick={() => append(blankSpendingLimit())}>
            {"+ Add spending limit"}
          </button>
        </div>
      )}
    </form>
  );
};

export default ExpenseCardSpendingLimitsForm;
