import { HCSSBusinessUnit } from "backend/services/hcss/hcss-types";
import { MiterAPI, MiterIntegrationForCompany } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { useState } from "react";

type SetBusinessUnitHook = {
  submitBusinessUnit: (data: HCSSBusinessUnit, callback?: () => Promise<void>) => Promise<void>;
  submittingBusinessUnit: boolean;
};

export const useSetBusinessUnit = (integration: MiterIntegrationForCompany): SetBusinessUnitHook => {
  const [submittingBusinessUnit, isSubmittingBusinessUnit] = useState(false);

  const submitBusinessUnit = async (
    data: { id: string; code: string; description?: string },
    callback?: () => Promise<void>
  ) => {
    if (!integration?.connection) return;
    isSubmittingBusinessUnit(true);
    try {
      const { id, code, description } = data;
      const { metadata } = integration.connection;
      const hcss = metadata?.hcss;

      if (!hcss) {
        throw new Error("HCSS integration missing data, please uninstall and reinstall the integration.");
      }

      hcss.hcssBusinessUnit = { id, code, description };

      const response = await MiterAPI.integrations.update_connection(integration.connection._id, {
        metadata: { hcss },
        pending_setup: false,
      });
      if (response.error) throw new Error(response.error);

      if (callback) {
        await callback();
      }
      Notifier.success("Successfully saved business unit.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error saving business unit: ${e.message}`);
    }
    isSubmittingBusinessUnit(false);
  };

  return { submitBusinessUnit, submittingBusinessUnit };
};
