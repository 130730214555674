import React, { useState } from "react";
import Notifier from "../../../../utils/notifier";
import { MiterAPI } from "../../../../miter";
import { Button } from "ui";
import PayrollContext from "../payrollContext";
import { ClickAwayListener } from "@material-ui/core";
import { downloadBlob } from "dashboard/utils";
import { useHasAccessToMiterPaystubsAndChecks } from "dashboard/gating";

/* This modal enables user to download paper stubs for an entire payroll
or a paystub for a single team member payment */

type Props = {
  hide: () => void;
};

const DownloadPaystubsModal: React.FC<Props> = ({ hide }) => {
  const { payroll } = React.useContext(PayrollContext);

  const [downloading, setDownloading] = useState(false);
  const hasAccessToMiterPayStubs = useHasAccessToMiterPaystubsAndChecks();

  const downloadPaystubs = async () => {
    setDownloading(true);
    try {
      let response;
      // todo ungate when complete + stable(or make it a company setting)
      if (hasAccessToMiterPayStubs) {
        response = await MiterAPI.payrolls.get_miter_paystubs(payroll!._id);
      } else {
        response = await MiterAPI.payrolls.get_paystubs(payroll!._id);
      }

      if (response.error) throw Error(response.error);

      const blob = await response.blob();
      const fileName = `${payroll!.check_payroll.payday} ${payroll!.type} payroll paystubs.pdf`;
      downloadBlob(blob, fileName);
      Notifier.success("Paystub downloaded successfully.");
    } catch (e: $TSFixMe) {
      console.log(e);
      Notifier.error(`Error downloading paystubs: ${e.message}`);
    }
    hide();
    setDownloading(false);
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={hide}>
        <div className="modal-wrapper" style={{ width: 500 }}>
          <div className="flex">
            <h3 style={{ marginBottom: 0, marginTop: 0 }}>{`Download paystubs`}</h3>
            <div className="flex-1"></div>
          </div>
          <div className="modal-body">
            {!downloading && (
              <div className="payroll-approval-text-wrapper">
                <span>{`Upon confirmation, Miter will generate a PDF containing paystubs for all payments in the payroll.`}</span>
              </div>
            )}
            {downloading && (
              <div>
                <div className="payroll-approval-text-wrapper">
                  <span className="bold">Note:</span>&nbsp;
                  <span>Depending on the number of payments, this process can take a few minutes.</span>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <div className="flex-1"></div>
            <button
              className={downloading ? "button-1 inactive" : "button-1"}
              onClick={downloading ? () => {} : hide}
            >
              Cancel
            </button>
            <Button
              className="button-2 no-margin"
              onClick={downloadPaystubs}
              text="Download"
              loading={downloading}
            />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DownloadPaystubsModal;
