import { useCallback, useMemo } from "react";
import { useMiterAbilities } from "./useMiterAbilities";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { FilterBuilder } from "./useAbilitiesTeamPredicate";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

/*
 * BILL PAY
 */
type BillPayAbilitiesOpts = { inboxMode?: InboxMode };

type BillPayAction = "create" | "read" | "update" | "pay" | "delete";
type BillPayAbilities = {
  can: (action: BillPayAction) => boolean;
  cannot: (action: BillPayAction) => boolean;
  filter: FilterBuilder;
};

export const useBillAbilities = (opts?: BillPayAbilitiesOpts): BillPayAbilities => {
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: BillPayAction) => {
      // If we are in inbox mode, we can always read, approve, and update anything in the inbox
      const isApprover = opts?.inboxMode === "approval";

      // either approval or needs attention mode
      const isInboxMode = opts?.inboxMode != undefined;

      switch (action) {
        case "create":
          return can_("bill_pay:bills:create");
        case "read":
          return can_("bill_pay:bills:read") || isInboxMode;
        case "pay":
          return can_("bill_pay:bills:pay");
        case "update":
          return can_("bill_pay:bills:update") || isApprover;
        case "delete":
          return can_("bill_pay:bills:delete");
        default:
          return false;
      }
    },
    [can_, opts?.inboxMode]
  );

  const cannot = useCallback(
    (action: BillPayAction) => {
      return !can(action);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    permissionPath: "bill_pay",
    inboxMode: opts?.inboxMode,
    appModule: "expense_management",
  });

  return useMemo(() => ({ can, cannot, filter }), [can, cannot, filter]);
};
