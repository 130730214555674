import { MiterAPI } from "dashboard/miter";
import { useState, useEffect } from "react";
import { Notifier } from "ui";
import { AcumaticaDataForConfig } from "backend/services/acumatica/acumatica-types";

type DataForConfigResult = {
  dataForConfig?: AcumaticaDataForConfig;
  loading: boolean;
};

export const useFetchAcumaticaDataForConfig = (integrationConnectionId?: string): DataForConfigResult => {
  const [dataForConfig, setDataForConfig] = useState<AcumaticaDataForConfig>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!integrationConnectionId) return;

      setLoading(true);
      try {
        const result = await MiterAPI.integrations.acumatica.retrieve_data_for_config(
          integrationConnectionId
        );

        if (result.error) throw Error(result.error);

        setDataForConfig(result);
      } catch (e: $TSFixMe) {
        Notifier.error("Error fetching Acumatica data for config.");
      }
      setLoading(false);
    };
    fetch();
  }, [integrationConnectionId]);

  return { dataForConfig, loading };
};
