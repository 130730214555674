import React, { useEffect, useState } from "react";
import { Formblock, Notifier } from "ui";
import { ModalHeader } from "ui";
import { ModalFooter } from "ui";
import { AggregatedTeamMember } from "dashboard/miter";
import { UseFormMethods } from "react-hook-form";
import { TeamMember } from "backend/models";
import { Option } from "ui/form/Input";
import {
  useJobOptions,
  useActivityOptions,
  useCostTypeOptions,
  useStandardClassificationOptions,
  useLedgerMappingOptions,
} from "dashboard/hooks/atom-hooks";
import {
  defaultTmActivityTooltip,
  defaultTmCostTypeTooltip,
  defaultTmJobTooltip,
  defaultTmTradeTooltip,
  stdTmClassificationTooltip,
  tmHsaLimitTooltip,
} from "../TeamUtils";
import { JobInput } from "dashboard/components/shared/JobInput";
import { useCanEditTmHsaLimitValue } from "dashboard/gating";
import { hsaContributionLimitOptions } from "dashboard/pages/benefits/BenefitFormUtils";
import { useTrades } from "dashboard/hooks/useTrades";

type Props = {
  defaultData: AggregatedTeamMember;
  form: UseFormMethods;
  onHide: () => void;
  onSubmit: (data: $TSFixMe, scheduleChanges: boolean) => Promise<void>;
  submitting: boolean;
  submitText?: string;
};

export const TeamMemberDefaultsForm: React.FC<Props> = ({
  defaultData,
  onHide,
  onSubmit,
  submitting,
  form,
  submitText,
}) => {
  const { control, errors, handleSubmit } = form;

  const ledgerMappingOptions = useLedgerMappingOptions();

  const jobOptions = useJobOptions();
  const [selectedJobOption, setSelectedJobOption] = useState<Option<string> | null>(
    () => jobOptions.find((o) => o.value === defaultData.default_job_id) || null
  );

  const activityOptions = useActivityOptions(selectedJobOption?.value);
  const [selectedActivityOption, setSelectedActivityOption] = useState<Option<string> | null>(
    () => activityOptions.find((o) => o.value === defaultData.default_activity_id) || null
  );

  const costTypeOptions = useCostTypeOptions();
  const hasCostTypes = costTypeOptions.length > 0;
  const [selectedCostTypeOption, setSelectedCostTypeOption] = useState<Option<string> | null>(
    () => costTypeOptions.find((o) => o.value === defaultData.default_cost_type_id) || null
  );

  const stdClassificationOptions = useStandardClassificationOptions();
  const [selectedStdClassificationOption, setSelectedStdClassificationOption] =
    useState<Option<string> | null>(
      () => stdClassificationOptions.find((o) => o.value === defaultData.standard_classification_id) || null
    );

  const { tradeOptions } = useTrades();
  const [selectedDefaultTradeOption, setSelectedDefaultTradeOption] = useState<Option<string> | null>(
    () => tradeOptions.find((o) => o.value === defaultData.default_trade_id) || null
  );

  useEffect(() => {
    setSelectedDefaultTradeOption(tradeOptions.find((o) => o.value === defaultData.default_trade_id) || null);
  }, [tradeOptions, defaultData]);

  useEffect(() => {
    if (!selectedActivityOption) return;
    const selectionInArr = activityOptions.some((o) => o.value === selectedActivityOption.value);
    if (!selectionInArr) {
      setSelectedActivityOption(null);
    }
  }, [activityOptions, selectedActivityOption]);

  const [selectedGlMapping, setSelectedGlMapping] = useState(defaultData.ledger_mapping_id);

  const canEditTmHsaLimitValue = useCanEditTmHsaLimitValue(defaultData.employment_type);

  const [selectedHsaLimit, setSelectedHsaLimit] = useState<Option<"single" | "family"> | null>(
    () => hsaContributionLimitOptions.find((o) => o.value === defaultData.hsa_limit) || null
  );

  const cleanDataForUpdate = (scheduleChanges: boolean) => {
    const update: Partial<TeamMember> = {
      default_job_id: selectedJobOption?.value || null,
      default_activity_id: selectedActivityOption?.value || null,
      default_cost_type_id: selectedCostTypeOption?.value || null,
      standard_classification_id: selectedStdClassificationOption?.value || null,
      ledger_mapping_id: selectedGlMapping || null,
      default_trade_id: selectedDefaultTradeOption?.value || null,
    };

    if (canEditTmHsaLimitValue) {
      update.hsa_limit = selectedHsaLimit?.value || null;
    }

    for (const key of Object.keys(update)) {
      if (update[key] === defaultData[key]) {
        delete update[key];
      }
    }

    if (Object.keys(update).length === 0) {
      Notifier.error("No changes detected");
      return;
    }

    onSubmit(update, scheduleChanges);
  };

  return (
    <div className={`modal-wrapper form`}>
      <ModalHeader onHide={onHide} heading={"Edit " + defaultData.first_name + "'s default associations"} />
      {defaultData && (
        <div className={`modal-body form`}>
          <div className="vertical-spacer" />
          <JobInput
            type="select"
            name="default_job_id"
            label="Default job"
            labelInfo={defaultTmJobTooltip}
            control={control}
            form={form}
            onChange={setSelectedJobOption}
            options={jobOptions}
            value={selectedJobOption}
            editing={true}
            className="modal small-margin"
            errors={errors}
            defaultValue={selectedJobOption?.value}
            isClearable
          />
          <Formblock
            type="select"
            name="default_activity_id"
            label="Default activity"
            labelInfo={defaultTmActivityTooltip}
            control={control}
            onChange={setSelectedActivityOption}
            options={activityOptions}
            value={selectedActivityOption}
            editing={true}
            className="modal small-margin"
            errors={errors}
            defaultValue={selectedActivityOption?.value}
            isClearable
          />
          {hasCostTypes && (
            <Formblock
              type="select"
              name="default_cost_type_id"
              label="Default cost type"
              labelInfo={defaultTmCostTypeTooltip}
              control={control}
              onChange={setSelectedCostTypeOption}
              options={costTypeOptions}
              value={selectedCostTypeOption}
              editing={true}
              className="modal small-margin"
              errors={errors}
              defaultValue={selectedCostTypeOption?.value}
              isClearable
            />
          )}
          <Formblock
            type="select"
            name="standard_classification_id"
            label="Standard classification"
            labelInfo={stdTmClassificationTooltip}
            control={control}
            onChange={setSelectedStdClassificationOption}
            options={stdClassificationOptions}
            value={selectedStdClassificationOption}
            editing={true}
            className="modal small-margin"
            errors={errors}
            defaultValue={selectedStdClassificationOption?.value}
            isClearable
          />
          <Formblock
            type="select"
            name="default_trade_id"
            label="Default trade"
            labelInfo={defaultTmTradeTooltip}
            control={control}
            onChange={setSelectedDefaultTradeOption}
            options={tradeOptions}
            value={selectedDefaultTradeOption}
            editing={true}
            className="modal small-margin"
            errors={errors}
            defaultValue={selectedDefaultTradeOption?.value}
            isClearable
          />
          {canEditTmHsaLimitValue && (
            <Formblock
              type="select"
              name="hsa_limit"
              label="Default HSA Limit"
              labelInfo={tmHsaLimitTooltip}
              control={control}
              onChange={setSelectedHsaLimit}
              options={hsaContributionLimitOptions}
              value={selectedHsaLimit}
              editing={true}
              className="modal small-margin"
              errors={errors}
              defaultValue={selectedHsaLimit?.value}
              isClearable
            />
          )}
          <Formblock
            type="select"
            name="ledger_mapping_id"
            label="GL mapping"
            control={control}
            onChange={(o) => setSelectedGlMapping(o?.value)}
            options={ledgerMappingOptions}
            value={ledgerMappingOptions.find((o) => o?.value === selectedGlMapping)}
            editing={true}
            className="modal small-margin"
            errors={errors}
            isClearable
          />
          <div className="vertical-spacer" />
        </div>
      )}
      <ModalFooter
        loading={submitting}
        onCancel={onHide}
        cancelText={"Cancel"}
        onSubmit={handleSubmit(() => cleanDataForUpdate(false))}
        submitText={submitText || "Submit"}
        className="form"
        showEdit={submitText !== "Request changes"}
        editText={"Schedule changes"}
        onEdit={handleSubmit(() => cleanDataForUpdate(true))}
      />
    </div>
  );
};
