import { PredefinedHolidayId } from "../../backend/models/holiday-schedule";
import { DateTime, DayNumbers, MonthNumbers, WeekdayNumbers } from "luxon";

/**
 * Checks to see if a DateTime is a specific month-day (e.g., Jan 1), or it's a Monday and the target month-day was the previous day (since a Sunday New Years Day is observed on Monday, for example)
 */
const isSpecificDayOrObservedMonday = (
  dt: DateTime,
  month: MonthNumbers,
  day: DayNumbers,
  observedMonday: boolean // choose whether to consider the following monday a holiday
): boolean => {
  // If it's the right month-day, then we're good
  if (dt.month === month && dt.day === day) return true;

  // Otherwise, the only way it could be the right day is if it's a Monday
  if (dt.weekday !== 1 || !observedMonday) return false;

  // Gotta grab the true full prior DateTime in order to make sure we're checking the right month and day
  const priorDay = dt.minus({ days: 1 });
  return priorDay.month === month && priorDay.day === day;
};

type WeekdayType = "first" | "second" | "third" | "fourth" | "last";

/**
 * Checks to see if a DateTime is the Nth instance of a specific weekday in a specific month, where N is first, second, third, fourth, or last
 */
const isNthWeekdayOfMonth = (
  dt: DateTime,
  month: MonthNumbers,
  weekdayType: WeekdayType,
  weekday: WeekdayNumbers
): boolean => {
  if (dt.month !== month || dt.weekday !== weekday) return false;

  const lowerDayNumberLimit = getFirstPossibleDayNumberForNthWeekday(dt, weekdayType);
  const upperDayNumberLimit = lowerDayNumberLimit + 6;

  return dt.day >= lowerDayNumberLimit && dt.day <= upperDayNumberLimit;
};

const getFirstPossibleDayNumberForNthWeekday = (dt: DateTime, weekdayType: WeekdayType): DayNumbers => {
  switch (weekdayType) {
    case "first":
      return 1;
    case "second":
      return 8;
    case "third":
      return 15;
    case "fourth":
      return 22;
    case "last":
      return (dt.daysInMonth - 6) as DayNumbers;
    default:
      throw new Error("Invalid weekday type");
  }
};

/**
 * Checks whether the given DateTime is NOT a valid US bank day, which is defined as a non-bank-holiday weekday. Bank holiday reference: https://www.frbservices.org/about/holiday-schedules
 */
export const isNotValidBankDay = (
  dt: DateTime,
  observeMonday: boolean
): PredefinedHolidayId | "weekend" | false => {
  // If it's Saturday or Sunday, then it's not a valid bank day
  if (dt.weekday === 6 || dt.weekday === 7) return "weekend";

  // If it's a bank holiday, it's not a valid bank day

  // New Years Day = Jan 1 (or Jan 2 if Jan 1 is a Sunday)
  if (isSpecificDayOrObservedMonday(dt, 1, 1, observeMonday)) return "new_years";

  // MLK Day = 3rd Monday in January
  if (isNthWeekdayOfMonth(dt, 1, "third", 1)) return "mlk";

  // Presidents Day = 3rd Monday in February
  if (isNthWeekdayOfMonth(dt, 2, "third", 1)) return "presidents";

  // Memorial Day = last Monday in May
  if (isNthWeekdayOfMonth(dt, 5, "last", 1)) return "memorial";

  // Juneteenth = June 19 (or June 20 if June 19 is a Sunday)
  if (isSpecificDayOrObservedMonday(dt, 6, 19, observeMonday)) return "juneteenth";

  // Independence Day = July 4 (or July 5 if July 4 is a Sunday)
  if (isSpecificDayOrObservedMonday(dt, 7, 4, observeMonday)) return "independence";

  // Labor Day = 1st Monday in September
  if (isNthWeekdayOfMonth(dt, 9, "first", 1)) return "labor";

  // Columbus Day = 2nd Monday in October
  if (isNthWeekdayOfMonth(dt, 10, "second", 1)) return "columbus";

  // Veterans Day = Nov 11 (or Nov 12 if Nov 11 is a Sunday)
  if (isSpecificDayOrObservedMonday(dt, 11, 11, observeMonday)) return "veterans";

  // Thanksgiving Day = 4th Thursday in November
  if (isNthWeekdayOfMonth(dt, 11, "fourth", 4)) return "thanksgiving";

  // Christmas Day = Dec 25 (or Dec 26 if Dec 25 is a Sunday)
  if (isSpecificDayOrObservedMonday(dt, 12, 25, observeMonday)) return "christmas";

  return false;
};

/**
 * Returns the next valid bank day after the provided DateTime
 */
export const getNextBankDay = (start: DateTime): DateTime => {
  let candidate = start;
  do {
    candidate = candidate.plus({ days: 1 });
  } while (isNotValidBankDay(candidate, true));

  return candidate;
};
