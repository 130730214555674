import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { Company } from "dashboard/miter";
import React from "react";
import { useWatch } from "react-hook-form";
import { Formblock, SettingsCard, SettingsPage } from "ui";

type SettingsForm = NonNullable<Company["settings"]["workers_comp"]> & {};

const SETTINGS_PATH = "settings.workers_comp";

export const WorkersCompSettings: React.FC = () => {
  const activeCompany = useActiveCompany();
  const { form, handleFieldUpdate } = useSettingsForm<SettingsForm>(SETTINGS_PATH);
  useWatch<SettingsForm>({ control: form.control });

  return (
    <SettingsPage
      title="Workers comp settings"
      subtitle="Configure how workers comp is managed in your company."
      backText="Back to workers comp"
      backLink="/settings/workers-comp"
    >
      <SettingsCard title={"General"}>
        <Formblock
          text="Enable workers comp groups"
          type="checkbox"
          form={form}
          editing={true}
          onChange={(e) => {
            handleFieldUpdate(e.target.checked, "enable_wc_groups");
          }}
          className="modal"
          defaultValue={activeCompany?.settings.workers_comp?.enable_wc_groups}
        />
      </SettingsCard>
    </SettingsPage>
  );
};
