import { ErrorOption } from "react-hook-form";
import { ErrorField } from "backend/services/error-service";
import { capitalize } from "./utils";

export const displayFieldErrors = (
  errorFields: ErrorField[],
  setError: (name: string, error: ErrorOption) => void,
  fields?: $TSFixMe[]
): void => {
  (errorFields || [])
    .filter(({ name }) => (fields ? fields.includes(name) : true))
    .forEach((ef) => {
      setError(ef.name, { message: capitalize(ef.error) });
    });
};
