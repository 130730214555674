import styles from "../TeamMemberChecklistBuilder.module.css";

import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { WithholdingsOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const WithholdingsTaskContent: React.FC<ChecklistTaskContentProps<WithholdingsOnboardingTaskForm>> = ({
  task,
  setTask,
  teamMember,
}) => {
  return (
    <div className={styles["team-member-checklist-task-content"]}>
      {teamMember?.employment_type === "employee"
        ? "Require this employee to fill out federal and state withholding forms."
        : "Require this contractor to fill out their W-9 form."}
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
