import { ColumnConfig } from "ui/table-v2/Table";
import { InboundTransferTableEntry } from "../../pages/cards/InboundTransfersTable";

export const inboundTransfersColumns: ColumnConfig<InboundTransferTableEntry>[] = [
  {
    field: "amount",
    headerName: "Amount",
    dataType: "string",
  },
  {
    field: "account",
    headerName: "Account",
    dataType: "string",
  },

  { field: "created", headerName: "Date initiated", dataType: "date", dateType: "iso", sort: "desc" },
  {
    field: "status",
    headerName: "Status",
    displayType: "badge",
    colors: {
      processing: "yellow",
      succeeded: "green",
      failed: "red",
    },
  },
];
