import { Screening } from "dashboard/types/screening-types";

type ReportCompletionStatus =
  | "report_complete"
  | "report_complete_with_canceled"
  | "report_canceled"
  | "report_suspended"
  | "report_pending"
  | "report_disputed"
  | "invitation_expired"
  | "invitation_completed"
  | "invitation_pending";

/**
 * This function generates a joint status for the screening based on the report status and the invitation status.
 * This is in line with what Checkr reccommends.
 */
const createReportCompletionStatus = (screening: Screening): ReportCompletionStatus => {
  if (screening.report_status === "complete") {
    if (screening.includes_canceled) {
      if (screening.result) {
        return "report_complete_with_canceled";
      } else {
        return "report_canceled";
      }
    }
    return "report_complete";
  } else if (screening.report_status === "canceled") {
    return "report_canceled";
  } else if (screening.report_status === "suspended") {
    return "report_suspended";
  } else if (screening.report_status === "pending") {
    return "report_pending";
  } else if (screening.report_status === "dispute") {
    return "report_disputed";
  } else if (screening.invitation_status === "expired") {
    return "invitation_expired";
  } else if (screening.invitation_status === "completed") {
    return "invitation_completed";
  } else {
    return "invitation_pending";
  }
};

export type ScreeningRow = Screening & {
  status: ReportCompletionStatus;
};

export const mapScreeningsToTableRow = (screenings: Screening[]): ScreeningRow[] => {
  return screenings.map((screening) => {
    return {
      ...screening,
      status: createReportCompletionStatus(screening),
    };
  });
};

export const packageMap = {
  miter_basic_plus: "Miter Basic Plus",
  miter_essential: "Miter Essential",
  miter_basic_plus_with_mvr: "Miter Basic Plus with MVR",
  miter_essential_with_mvr: "Miter Essential with MVR",
};

// Create the options out of the ReportCompletionStatus enum

export const statusOptions = [
  { value: "report_complete", label: "Complete" },
  { value: "report_complete_with_canceled", label: "Completed with canceled screenings" },
  { value: "report_canceled", label: "Canceled" },
  { value: "report_suspended", label: "Suspended" },
  { value: "report_pending", label: "Pending" },
  { value: "report_disputed", label: "Disputed" },
  { value: "invitation_expired", label: "Invitation expired" },
  { value: "invitation_completed", label: "Invitation completed" },
  { value: "invitation_pending", label: "Invitation pending" },
];

export const resultOptions = [
  {
    value: "clear",
    label: "Clear, no infraction found",
  },
  {
    value: "consider",
    label: "Needs further review, infractions found",
  },
];

export const adjudicationOptions = [
  {
    value: "engaged",
    label: "Moving forward in the hiring process",
  },
  {
    value: "pre_adverse_action",
    label: "Candidate was sent an initial notice of a no-hire decision",
  },
  {
    value: "post_adverse_notice",
    label: "Waiting period elapsed, final no-hire notice sent",
  },
];
