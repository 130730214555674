import AnvilEmbedFrame from "@anvilco/anvil-embed-frame";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { Loader } from "ui";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { OfferLetterWithAnvilLink } from "dashboard/types/offers-types";
import OfferWasSigned from "dashboard/components/offers/signing/OfferWasSigned";
import OfferExpired from "dashboard/components/offers/signing/OfferExpired";

export const SignOfferLetter: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [status, setStatus] = useState<"loading" | "signed" | "expired" | "available" | "error">("loading");
  const [offerLetter, setOfferLetter] = useState<OfferLetterWithAnvilLink | null>(null);

  useEffect(() => {
    if (!id) return;
    const fetchEtchPacketURL = async () => {
      const response = await MiterAPI.public_offer_letters.retrieve_with_anvil_link(id);
      if (response.error) {
        Notifier.error(response.error);
        setStatus("error");
      } else if (response.status === "signed") {
        setStatus("signed");
      } else if (response.expires_on && response.expires_on < DateTime.now().toSeconds()) {
        setStatus("expired");
      } else {
        setStatus("available");
        setOfferLetter(response);
      }
    };
    fetchEtchPacketURL();
  }, [id]);

  const markAsViewed = async () => {
    try {
      if (!id) return;
      const response = await MiterAPI.public_offer_letters.mark_as_viewed(id);
      if (response.error) {
        Notifier.error(response.error);
      }
    } catch (error) {
      Notifier.error(error as string);
    }
  };

  const markAsSigned = async () => {
    try {
      if (!id) return;
      const response = await MiterAPI.public_offer_letters.mark_as_signed(id);
      if (response.error) {
        Notifier.error(response.error);
      }
    } catch (error: $TSFixMe) {
      Notifier.error(error);
    }
  };

  const renderContent = () => {
    if (status === "signed") {
      return <OfferWasSigned />;
    } else if (status === "expired") {
      return <OfferExpired />;
    } else if (status === "available" && offerLetter?.anvil_link) {
      return (
        <AnvilEmbedFrame
          iframeURL={offerLetter.anvil_link}
          scroll="auto"
          onEvent={(eventObject) => {
            if (eventObject.action === "signerLoad") {
              markAsViewed();
            } else if (eventObject.action === "signerComplete") {
              markAsSigned();
            }
          }}
          style={{ width: "100vw", height: "100vh", border: "none" }}
        />
      );
    }
    return <Loader />;
  };

  return <div style={{ width: "100vw", height: "100vh", border: "none" }}>{renderContent()}</div>;
};
