import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useActiveCompanyId,
  useActivityLabelFormatter,
  useLookupCostType,
  useLookupExpenseReimbursementCategories,
  useLedgerAccountLabeler,
  useRefetchJobs,
} from "../../hooks/atom-hooks";
import { TableV2, ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { AggregatedJob, MiterAPI, MiterFilterArray, PerDiemRate } from "../../miter";
import { Notifier } from "ui/notifier";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Plus, Trash, X } from "phosphor-react";
import { usdString } from "ui";
import { capitalize } from "lodash";
import { deparameterize } from "miter-utils";
import { ExpenseReimbursementPayoutMethod } from "backend/models/expense-reimbursement";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";
import AddPerDiemRateToJobModal from "./modals/AddPerDiemRateToJobModal";
import PerDiemRateModal from "./modals/PerDiemRateModal";
import Banner from "dashboard/components/shared/Banner";
import { goToMiterGuide } from "dashboard/utils/miterGuides";
import { useFetchUserData } from "dashboard/hooks/useFetchUserData";
import pluralize from "pluralize";

// also show category information
export type PerDiemRatesTableRow = PerDiemRate & {
  category_amount?: number;
  category_name?: string;
  category_activity?: string;
  category_cost_type?: string;
  category_gl_account?: string;
  category_payout_method?: ExpenseReimbursementPayoutMethod;
};

type Props = {
  job?: AggregatedJob;
  onUpdate?: () => void; // triggered whenever a per diem is added or deleted
};

export const PerDiemRatesTable: React.FC<Props> = ({ job, onUpdate }) => {
  const activeCompanyId = useActiveCompanyId();
  const { can } = useMiterAbilities();
  const refetchJobs = useRefetchJobs();
  const fetchUserData = useFetchUserData();

  // lookups
  const lookupReimbursementCategory = useLookupExpenseReimbursementCategories();
  const activityLabelFormatter = useActivityLabelFormatter();
  const lookupCostType = useLookupCostType();
  const ledgerAccountLabelFormatter = useLedgerAccountLabeler();

  const [perDiemRates, setPerDiemRates] = useState<PerDiemRatesTableRow[]>();
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [removingFromJob, setRemovingFromJob] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<PerDiemRatesTableRow[]>([]);
  const [clickedRow, setClickedRow] = useState<PerDiemRatesTableRow>();

  const { groupLabeler } = useTeamMemberGroupLabeler();

  // modals
  const [showAddPerDiemRateToJobModal, setShowAddPerDiemRateToJobModal] = useState<boolean>(false);
  const [showCreatePerDiemRateModal, setShowCreatePerDiemRateModal] = useState<boolean>(false);

  useEffect(() => {
    const getPerDiemRates = async () => {
      if (!activeCompanyId) return;
      setIsTableLoading(true);

      try {
        const filter: MiterFilterArray = [{ field: "company_id", value: activeCompanyId }];
        if (job) {
          filter.push({
            field: "_id",
            value: job.per_diem_rate_ids || [],
            comparisonType: "in",
            type: "_id",
          });
        }

        const perDiemRates = await MiterAPI.expense_reimbursements.per_diem_rates.list({ filter });

        const tableRows: PerDiemRatesTableRow[] = perDiemRates.map((perDiemRate: PerDiemRate) => {
          const category = lookupReimbursementCategory(perDiemRate.expense_reimbursement_category_id);
          return {
            ...perDiemRate,
            category_amount: category?.amount?.value,
            category_name: category?.name,
            category_activity: category?.activity?.id,
            category_cost_type: category?.cost_type?.id,
            category_gl_account: category?.expense_account?.id,
            category_payout_method: category?.payout_method || undefined,
          };
        });

        setPerDiemRates(tableRows);
      } catch (e: $TSFixMe) {
        console.error(e.message);
        Notifier.error(e.message);
      }
      setIsTableLoading(false);
    };

    getPerDiemRates();
  }, [activeCompanyId, job, lookupReimbursementCategory, refreshCounter]);

  const columns: ColumnConfig<PerDiemRatesTableRow>[] = useMemo(() => {
    const columns: ColumnConfig<PerDiemRatesTableRow>[] = [
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        headerName: "Amount",
        field: "category_amount",
        dataType: "string",
        valueFormatter: (row) => {
          return usdString(row.value);
        },
      },
      {
        headerName: "Cadence",
        field: "cadence",
        dataType: "string",
        valueFormatter: (row) => {
          return capitalize(row.value);
        },
      },
      {
        headerName: "Eligible team members",
        field: "eligible_team_member_groups",
        dataType: "string",
        minWidth: 250,
        valueFormatter: (row) => {
          return row.value.map((group) => groupLabeler(group)).join(", ");
        },
      },
      {
        headerName: "Eligible based on distance",
        field: "enable_distance_based_eligibility",
        dataType: "boolean",
      },
      {
        headerName: "Min distance",
        field: "minimum_jobsite_distance_from_home",
        dataType: "number",
        valueFormatter: (row) => {
          return row.value ? `${row.value} miles` : "";
        },
      },
      {
        headerName: "Max distance",
        field: "maximum_jobsite_distance_from_home",
        dataType: "number",
        valueFormatter: (row) => {
          return row.value ? `${row.value} miles` : "";
        },
      },
      {
        headerName: "Activity",
        field: "category_activity",
        dataType: "string",
        valueFormatter: (row) => {
          return activityLabelFormatter(row.value);
        },
      },
      {
        headerName: "Cost type",
        field: "category_cost_type",
        dataType: "string",
        valueFormatter: (row) => {
          return lookupCostType(row.value)?.label || "";
        },
      },
      {
        headerName: "GL account",
        field: "category_gl_account",
        dataType: "string",
        valueFormatter: (row) => {
          return ledgerAccountLabelFormatter(row.value);
        },
      },
      {
        headerName: "Payout method",
        field: "category_payout_method",
        dataType: "string",
        valueFormatter: (row) => {
          return deparameterize(row.value);
        },
      },
    ];

    return columns;
  }, [activityLabelFormatter, groupLabeler, ledgerAccountLabelFormatter, lookupCostType]);

  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add per diem rate to this job",
        className: "button-2",
        action: () => {
          setShowAddPerDiemRateToJobModal(true);
        },
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 5 }} />,
        loading: false,
        shouldShow: () => {
          return !!job && can("jobs:update");
        },
      },
      {
        label: "Create new per diem rate",
        className: "button-2",
        action: () => {
          setShowCreatePerDiemRateModal(true);
        },
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 5 }} />,
        loading: false,
        shouldShow: () => {
          return !job;
        },
      },
    ],
    [can, job]
  );

  // remove all per diem rates in selected rows from job
  const handleRemoveFromJob = useCallback(async () => {
    if (!job) return;
    setRemovingFromJob(true);

    const selectedIdsToRemove = selectedRows.map((r) => r._id);

    const ratesToKeep = job.per_diem_rate_ids?.filter((id) => !selectedIdsToRemove.includes(id));

    try {
      const response = await MiterAPI.jobs.update(job._id, {
        per_diem_rate_ids: ratesToKeep,
      });
      if (response.error) throw new Error(response.error);

      Notifier.success(`Removed per diem ${pluralize("rate", selectedIdsToRemove.length)} from the job.`);
      refetchJobs([job._id]);
      setSelectedRows([]);
    } catch (err) {
      Notifier.error("Could not remove these per diem rates from the job.");
    }
    setRemovingFromJob(false);
  }, [job, refetchJobs, selectedRows]);

  const handleArchive = useCallback(async () => {
    setArchiving(true);
    const selectedIdsToRemove = selectedRows.map((r) => r._id);

    let successes = 0;
    await Promise.all(
      selectedIdsToRemove.map(async (id) => {
        try {
          const res = await MiterAPI.expense_reimbursements.per_diem_rates.archive(id);
          if (res.error) throw new Error(res.error);
          successes++;
        } catch (e: $TSFixMe) {
          Notifier.error(e.message);
        }
      })
    );

    if (successes > 0) {
      Notifier.success(`Per diem ${pluralize("rate", successes)} deleted.`);
    }
    // refresh since there's a side effect of updating company settings
    fetchUserData();
    setRefreshCounter(refreshCounter + 1);
    setArchiving(false);
  }, [fetchUserData, refreshCounter, selectedRows]);

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Remove from job",
        className: "button-3",
        action: handleRemoveFromJob,
        important: true,
        icon: <X weight="bold" style={{ marginRight: 5 }} />,
        loading: removingFromJob,
        shouldShow: () => {
          return !!job && can("jobs:update");
        },
      },
      {
        label: "Delete",
        className: "button-3",
        action: handleArchive,
        important: true,
        icon: <Trash weight="bold" />,
        loading: archiving,
        shouldShow: () => {
          // only show if not from the job table
          return !job;
        },
      },
    ],
    [handleRemoveFromJob, removingFromJob, handleArchive, archiving, job, can]
  );

  return (
    <>
      {
        <>
          <div className="vertical-spacer-small"></div>
          <Banner
            type="info"
            onClick={() => goToMiterGuide("/spend-management/per-diems")}
            content="Click here for more documentation about Miter's per diem management system!"
          />
        </>
      }
      <TableV2
        id="per-diem-rates-table"
        resource="per diem rates"
        data={perDiemRates}
        columns={columns}
        isLoading={isTableLoading}
        showReportViews={true}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onSelect={setSelectedRows}
        onClick={(r) => setClickedRow(r)}
      />
      {showAddPerDiemRateToJobModal && job && (
        <AddPerDiemRateToJobModal
          job={job}
          onHide={() => setShowAddPerDiemRateToJobModal(false)}
          onSave={() => {
            if (job) {
              refetchJobs([job._id]);
            } else {
              setRefreshCounter(refreshCounter + 1);
            }
          }}
        />
      )}
      {(showCreatePerDiemRateModal || clickedRow) && (
        <PerDiemRateModal
          selectedPerDiemRate={clickedRow}
          onHide={() => {
            setShowCreatePerDiemRateModal(false);
            setClickedRow(undefined);
          }}
          onSave={() => {
            if (job) {
              refetchJobs([job._id]);
            } else {
              setRefreshCounter(refreshCounter + 1);
            }
            onUpdate?.();
          }}
        />
      )}
    </>
  );
};
