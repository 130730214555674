import React from "react";
import { UseFormMethods } from "react-hook-form";
import { Label, Formblock } from "ui";
import { PermissionGroupForm } from "./PermissionGroupModal";
import { PermissionGroupTeamMemberScope } from "./PermissionGroupTeamMemberScope";

type PermissionGroupScopeSectionProps = {
  title?: string;
  labelInfo?: string;
  form: UseFormMethods<PermissionGroupForm>;
  teamMemberScopeName: string;
  jobScopeName?: string; // Optional because not all sections have job scopes
  scopeType: "job" | "team_member";
};

export const PermissionGroupScopeSection: React.FC<PermissionGroupScopeSectionProps> = ({
  title,
  labelInfo,
  form,
  teamMemberScopeName,
  jobScopeName,
  scopeType,
}) => (
  <div>
    {title && <Label label={title} labelInfo={labelInfo} style={{ marginBottom: 7, width: "100%" }} />}

    {teamMemberScopeName && scopeType === "team_member" && (
      <PermissionGroupTeamMemberScope form={form} name={teamMemberScopeName} label="Team member scopes" />
    )}

    {jobScopeName && scopeType === "job" && (
      <Formblock
        type="multiselect"
        name={jobScopeName}
        label="Job scopes"
        form={form}
        editing={true}
        className="modal "
        placeholder="Select scopes"
        options={JOB_SCOPE_OPTIONS}
        height="unset"
        style={{ marginTop: 20 }}
      />
    )}
  </div>
);

export const JOB_SCOPE_OPTIONS = [
  {
    label: (
      <>
        <b>Managing</b> - Jobs the user is a supervisor or a superintendent for
      </>
    ),
    value: "jobs_managing",
  },
  {
    label: (
      <>
        <b>Department</b> -Jobs assigned to the user&apos;s department
      </>
    ),
    value: "jobs_in_department",
  },
  {
    label: (
      <>
        <b>Location</b> - Jobs assigned to the user&apos;s location
      </>
    ),
    value: "jobs_in_location",
  },
  {
    label: (
      <>
        <b>Manually Assigned</b> - Jobs the user has been manually assigned to
      </>
    ),
    value: "jobs_manually_assigned",
  },
  {
    label: (
      <>
        <b>All Jobs</b>
      </>
    ),
    value: "all_jobs",
  },
];
