import { useContext, useMemo } from "react";
import AppContext from "dashboard/contexts/app-context";
import { IntegrationConnection, MiterIntegrationForCompany } from "dashboard/miter";
import { SYNERGY_COMPANY_ID } from "dashboard/utils";
import { MiterIntegrationKey } from "backend/models/integrations/integrations";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

export const useHasIntegration = (): Map<MiterIntegrationKey, IntegrationConnection> => {
  const { integrations } = useContext(AppContext);
  const activeCompanyId = useActiveCompanyId();

  return useMemo(() => {
    const integrationKeyToIntegrationMap = new Map<MiterIntegrationKey, IntegrationConnection>();

    integrations?.forEach((integration) => {
      const integrationConnection = getIntegrationConnectionId(activeCompanyId, integration);
      if (integrationConnection) {
        integrationKeyToIntegrationMap.set(integration.key, integrationConnection);
      }
    });
    return integrationKeyToIntegrationMap;
  }, [integrations, activeCompanyId]);
};

const getIntegrationConnectionId = (
  activeCompanyId: string | null,
  integration?: MiterIntegrationForCompany
): IntegrationConnection | undefined => {
  if (!integration?.connection || integration?.connection.pending_setup || integration.connection.archived) {
    return;
  }

  if (integration?.key === "vista" && activeCompanyId === SYNERGY_COMPANY_ID) {
    return;
  }

  return integration.connection;
};
