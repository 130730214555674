const primaryButton = {
  backgroundColor: "#4d55bb",
  color: "#FFFFFF",
  border: "none",
  fontSize: "15px",
  fontWeight: "600",
  borderRadius: "4px",
  ":hover": {
    boxShadow: "0px 1px 6px #000000",
  },
};

export const themeObject = {
  integrations: { googleFonts: "Karla:wght@200;300;400;500;600;700;800" },
  theme: { global: { fontFamily: "Karla" }, buttons: { primary: primaryButton } },
};
