import React from "react";
import { UseFormMethods } from "react-hook-form";
import { Formblock } from "ui";
import { SettingsCard } from "ui/settings/SettingsCard";
import { CPRSettings } from "backend/models/company/company-settings";

export const CPRGeneralSettings: React.FC<{
  form: UseFormMethods;
  settings: CPRSettings | undefined;
  updateSettings: () => void;
}> = ({ form, settings, updateSettings }) => {
  return (
    <>
      <SettingsCard title={"General"}>
        <div style={{ marginBottom: -10 }}>
          <Formblock
            type="checkbox"
            name="use_active_role_email"
            form={form}
            editing={true}
            onChange={updateSettings}
            defaultValue={settings?.use_active_role_email}
          >
            <div>{`Use the active user's email on the CPR cover sheet`}</div>
          </Formblock>
          <Formblock
            type="checkbox"
            name="flatten_pdfs"
            form={form}
            editing={true}
            onChange={updateSettings}
            defaultValue={settings?.flatten_pdfs}
          >
            <div>{`Download PDFs as read-only files`}</div>
          </Formblock>
        </div>
      </SettingsCard>
      <SettingsCard title={"Defaults"}>
        <div>
          <Formblock
            label="Default signer name"
            type="text"
            name="default_signer_name"
            form={form}
            editing={true}
            onChange={updateSettings}
            defaultValue={settings?.default_signer_name}
          />
        </div>
        <div>
          <Formblock
            label="Default signer title"
            type="text"
            name="default_signer_title"
            form={form}
            editing={true}
            onChange={updateSettings}
            defaultValue={settings?.default_signer_title}
          />
        </div>
      </SettingsCard>
    </>
  );
};
