import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { useCheckrCompanyPackages } from "dashboard/components/screenings/useCheckrPackages";
import { useForm } from "react-hook-form";
import { AddScreeningForm, ScreeningForm } from "dashboard/components/screenings/AddScreeningForm";
import { Loader } from "ui";
import { ScreeningOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const ScreeningTaskContent: React.FC<ChecklistTaskContentProps<ScreeningOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  const form = useForm<ScreeningForm>();
  const { loading, companyPackages, mapOfNodesToPackages } = useCheckrCompanyPackages();

  const handleChange = (data: ScreeningForm) => {
    const screeningData = {
      node_id: data?.node_id?.value,
      checkr_package: data?.checkr_package?.value,
      state: data?.state?.value,
    };

    setTask({ ...task, data: { ...task.data, ...screeningData } });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      {!loading && (
        <AddScreeningForm
          form={form}
          mapOfNodesToPackages={mapOfNodesToPackages}
          companyPackages={companyPackages}
          hideState={false}
          defaultValues={task.data}
          onChange={handleChange}
        />
      )}
      {loading && <Loader />}
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
