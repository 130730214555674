import { useMemo } from "react";
import { useJobs } from "./atom-hooks";
import { notNullish } from "miter-utils";

export const usePrgReferencedByJobTrade = (): Set<string> => {
  const jobs = useJobs();

  const prgsReferencedByJobTrade = useMemo(() => {
    const jobReferencedTradeIds: Set<string> = new Set();

    jobs.forEach((job) => {
      Object.values(job.trades || {})
        .filter(notNullish)
        .forEach((prgId) => {
          jobReferencedTradeIds.add(prgId);
        });
    });
    return jobReferencedTradeIds;
  }, [jobs]);

  return prgsReferencedByJobTrade;
};
