import React from "react";
import { ActionModal, Formblock } from "ui";
import { AggregatedTeamMemberWithI9 } from "dashboard/miter";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useForm } from "react-hook-form";
import { booleanOptions } from "dashboard/utils";

type Props = {
  data: AggregatedTeamMemberWithI9;
  onHide: () => void;
  onSubmit: (data) => Promise<void>;
  submitting: boolean;
};

export const TeamMemberCprOptionsForm: React.FC<Props> = ({ data, onHide, onSubmit, submitting }) => {
  const { cannot, can } = useMiterAbilities();

  const form = useForm();

  const handleSave = async (data) => {
    const params = {
      ignore_in_cprs: data.ignore_in_cprs?.value === "true",
      disable_fringe_offsets: data.disable_fringe_offsets?.value === "true",
    };
    await onSubmit(params);
  };

  return (
    <>
      <ActionModal
        headerText={"Edit Certified Payroll options"}
        onHide={onHide}
        showSubmit={can("team:update")}
        submitText={"Save"}
        onSubmit={form.handleSubmit(handleSave)}
        onCancel={onHide}
        showCancel={true}
        loading={submitting}
      >
        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Formblock
            type="select"
            name="ignore_in_cprs"
            label="Ignore on Certified Payroll Reports"
            options={booleanOptions}
            defaultValue={data.ignore_in_cprs ? "true" : "false"}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
          />
          <Formblock
            type="select"
            name="disable_fringe_offsets"
            label="Disable fringe offsets"
            options={booleanOptions}
            defaultValue={data.disable_fringe_offsets ? "true" : "false"}
            form={form}
            editing={true}
            className="modal"
            disabled={cannot("team:update")}
          />
        </div>
      </ActionModal>
    </>
  );
};
