import pluralize from "pluralize";
import React, { FC, useState } from "react";
import { Button } from "ui";

type Props = {
  numCardsToIssue: number;
  onSubmit: () => Promise<void>;
};

export const IssueMiterCardModalFooter: FC<Props> = ({ numCardsToIssue, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <div className={"modal-footer"}>
        <p className="no-margin">
          By continuing, you agree to the{" "}
          <a
            href="https://stripe.com/legal/issuing/celtic-authorized-user-terms?"
            target="_blank"
            rel="noreferrer"
            className="purple-link"
          >
            User Terms.
          </a>
        </p>
        <div className="flex-1"></div>
        <Button
          className="button-2"
          onClick={async () => {
            setIsSubmitting(true);
            await onSubmit();
            setIsSubmitting(false);
          }}
          text={`Issue ${numCardsToIssue > 0 ? numCardsToIssue : ""} ${pluralize("Card", numCardsToIssue)}`}
          loading={isSubmitting}
          disabled={numCardsToIssue === 0 || isSubmitting}
        />
      </div>
    </>
  );
};
