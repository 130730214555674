import { useOnboardingChecklistOptions, useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { AggregatedOnboardingChecklistItem } from "dashboard/types/onboarding-types";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { BasicModal, Formblock, Notifier } from "ui";

type Props = {
  teamMember: AggregatedTeamMember;
  onboardingChecklistItem: AggregatedOnboardingChecklistItem | null;
  onExit: () => void;
};

const ChecklistSelectionModal: FC<Props> = ({ teamMember, onExit, onboardingChecklistItem }) => {
  const [loading, setLoading] = useState(false);
  const checklistOptions = useOnboardingChecklistOptions();
  const checklistOption = checklistOptions.find((c) => {
    return c.value === onboardingChecklistItem?.cached_onboarding_checklist?._id;
  });
  const refetchTm = useRefetchTeam();

  const form = useForm({
    defaultValues: {
      onboarding_checklist_id: checklistOption,
    },
  });
  const { handleSubmit } = form;

  const title = onboardingChecklistItem ? "Change" : "Select";

  const onSubmit = async (data) => {
    console.log(data);
    setLoading(true);
    try {
      const assignResponse = await MiterAPI.onboarding_checklist_items.assign({
        tm_id: teamMember._id,
        checklist_id: data.onboarding_checklist_id.value,
      });
      if (assignResponse.error) {
        throw new Error(assignResponse.error);
      }

      const updateTeamMemberResponse = await MiterAPI.onboarding_checklist_items.update_team_member({
        tm_id: teamMember._id,
        checklist_item_id: assignResponse._id,
      });
      if (updateTeamMemberResponse.error) {
        throw new Error(updateTeamMemberResponse.error);
      }
      refetchTm(teamMember._id);
      onExit();
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  return (
    <BasicModal
      onHide={onExit}
      headerText={onboardingChecklistItem ? "Change onboarding checklist" : "Select onboarding checklist"}
      button2Text={title}
      button2Action={handleSubmit(onSubmit)}
      loading={loading}
    >
      <Formblock
        type="select"
        name="onboarding_checklist_id"
        label={"Onboarding checklist"}
        form={form}
        options={checklistOptions}
        editing={true}
        className="modal wizard"
      />
    </BasicModal>
  );
};

export default ChecklistSelectionModal;
