import { useActiveAccount, useActiveCompany, useStripeConnectedAccount } from "dashboard/hooks/atom-hooks";
import { MiterAPI } from "dashboard/miter";
import { SLACK_CHANNEL } from "dashboard/utils";
import { initializeStripeAccount } from "dashboard/utils/expenses";
import { isMiterCardsAccountFullyActive } from "./expenseUtils";
import { useState } from "react";
import { Button, ConfirmModal, Formblock } from "ui";

export const StripeOnboardingButton: React.FC<{ text: string }> = ({ text }) => {
  const activeCompany = useActiveCompany();
  const activeUser = useActiveAccount();
  const stripeAccount = useStripeConnectedAccount();
  const isStripeAccountSetupComplete = isMiterCardsAccountFullyActive(stripeAccount);
  const [loadingStripe, setLoadingStripe] = useState(false);

  // modals
  const [stripeOnboardingModalOpen, setStripeOnboardingModalOpen] = useState<string>();
  const [stripeOnboardingUseCase, setStripeOnboardingUseCase] = useState<string>();

  const handleRedirectToStripe = async () => {
    setLoadingStripe(true);

    if (!stripeAccount) {
      // send to slack
      await MiterAPI.slack.send_message(
        `${activeUser?.email || activeUser?._id} from ${
          activeCompany?.check_company.trade_name
        } started Stripe onboarding for ${stripeOnboardingModalOpen}. Use case: ${stripeOnboardingUseCase}`,
        SLACK_CHANNEL.STRIPE_ONBOARDING
      );
    }

    await initializeStripeAccount({
      company: activeCompany!._id,
      account: stripeAccount,
      source: `Get started with ${stripeOnboardingModalOpen} button`,
      setLoading: setLoadingStripe,
    });

    // reset
    setStripeOnboardingUseCase(undefined);
    setStripeOnboardingModalOpen(undefined);
  };

  if (isStripeAccountSetupComplete) return <></>;

  return (
    <>
      <Button
        className="button-2"
        text={`${!stripeAccount ? `Get started with ${text}` : `Continue ${text} onboarding`}`}
        onClick={() => (stripeAccount ? handleRedirectToStripe() : setStripeOnboardingModalOpen(text))}
        loading={loadingStripe}
      />
      {stripeOnboardingModalOpen && (
        <ConfirmModal
          title="Confirmation"
          body={
            <>
              <Formblock
                type="text"
                editing={true}
                label="What is your use case?"
                className="modal"
                onChange={(e) => setStripeOnboardingUseCase(e.target.value)}
              />
              <div className="yellow-text-container">
                <p>
                  You will be redirected from Miter to pass required compliance steps before you can start
                  using {stripeOnboardingModalOpen}.
                  <br />
                  <br />
                  If you have any questions, please reach out to your dedicated Launch Manager or{" "}
                  <a href="mailto:justin@miter.com">justin@miter.com</a>.
                </p>
              </div>
            </>
          }
          onYes={handleRedirectToStripe}
          onNo={() => setStripeOnboardingModalOpen(undefined)}
          yesText="Continue"
          yesDisabled={!stripeOnboardingUseCase}
          loading={loadingStripe}
        />
      )}
    </>
  );
};
