import { TimesheetTableEntry } from "dashboard/utils/timesheetUtils";
import { useCallback, useMemo } from "react";
import {
  AggregatedTimesheet,
  AggregatedTeamMember,
  AggregatedJob,
  Job,
  AggregatedMiterEarning,
} from "dashboard/miter";
import { Timesheet } from "backend/models";
import { useActiveTeamMember } from "../atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { EditPayPeriodTimesheetRow } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/TimesheetsByPayPeriodEditor";
import { PayPeriodHoursRow } from "dashboard/pages/timesheets/TimesheetsByPayPeriod/TimesheetsByPayPeriod";
import { FilterBuilder, useAbilitiesTeamPredicate } from "./useAbilitiesTeamPredicate";
import { useAbilitiesBackendFilter } from "./useAbilitiesBackendFilter";
import { useAbilitiesJobPredicate } from "./useAbilitiesJobPredicate";
import { InboxMode } from "dashboard/pages/approvals/inboxUtils";

type TimesheetAbilitiesOpts = { inboxMode?: InboxMode };

type TimesheetParams =
  | AggregatedTimesheet
  | AggregatedTimesheet[]
  | Timesheet
  | Timesheet[]
  | TimesheetTableEntry
  | TimesheetTableEntry[]
  | EditPayPeriodTimesheetRow
  | EditPayPeriodTimesheetRow[]
  | PayPeriodHoursRow
  | PayPeriodHoursRow[]
  | AggregatedMiterEarning
  | AggregatedMiterEarning[]
  | undefined;

export type TimesheetAction =
  | "create"
  | "read"
  | "update"
  | "approve"
  | "delete"
  | "read_sensitive"
  | "update_sensitive"
  | "delete_sensitive";

export type TimesheetAbilities = {
  can: (action: TimesheetAction, items: TimesheetParams) => boolean;
  cannot: (action: TimesheetAction, items: TimesheetParams) => boolean;
  filter: FilterBuilder;
  teamPredicate: (action?: TimesheetAction) => (tm: AggregatedTeamMember) => boolean;
  jobPredicate: (action?: TimesheetAction) => (job: AggregatedJob | Job) => boolean;
};

export const useTimesheetAbilities = (opts?: TimesheetAbilitiesOpts): TimesheetAbilities => {
  const activeTeamMember = useActiveTeamMember();
  const { can: can_ } = useMiterAbilities();

  const can = useCallback(
    (action: TimesheetAction, items: TimesheetParams) => {
      if (!items) return false;
      const timesheets = Array.isArray(items) ? items : [items];

      // If we are in inbox mode, we can always read, approve, and update anything in the inbox
      const isApprover = opts?.inboxMode === "approval";

      // either approval or needs attention mode
      const isInboxMode = opts?.inboxMode != undefined;

      return timesheets.every((timesheet) => {
        const teamMemberId = getTeamMemberId(timesheet);
        const jobId = getJobId(timesheet);

        const isPersonal = teamMemberId === activeTeamMember?._id;

        if (isPersonal) {
          switch (action) {
            case "approve":
              return can_("timesheets:personal:approve") || isApprover;
            case "create":
              return can_("timesheets:personal:create");
            case "read":
              return can_("timesheets:personal:read") || isInboxMode;
            case "update":
              return can_("timesheets:personal:update") || isInboxMode;
            case "delete":
              return can_("timesheets:personal:delete");
            case "read_sensitive":
              return can_("timesheets:personal:read_sensitive");
            case "update_sensitive":
              return can_("timesheets:personal:update_sensitive");
            case "delete_sensitive":
              return can_("timesheets:personal:delete_sensitive");
            default:
              return false;
          }
        } else {
          const opts = { teamMember: teamMemberId, job: jobId };

          switch (action) {
            case "approve":
              return can_("timesheets:others:approve", opts) || isApprover;
            case "create":
              return can_("timesheets:others:create", opts);
            case "read":
              return can_("timesheets:others:read", opts) || isApprover;
            case "update":
              return can_("timesheets:others:update", opts) || isApprover;
            case "delete":
              return can_("timesheets:others:delete", opts);
            case "read_sensitive":
              return can_("timesheets:others:read_sensitive", opts);
            case "update_sensitive":
              return can_("timesheets:others:update_sensitive", opts);
            case "delete_sensitive":
              return can_("timesheets:others:delete_sensitive", opts);
            default:
              return false;
          }
        }
      });
    },
    [activeTeamMember, opts?.inboxMode, can_]
  );

  const cannot = useCallback(
    (action: TimesheetAction, items: TimesheetParams) => {
      return !can(action, items);
    },
    [can]
  );

  /** Filter used to narrow down the visible data that someone can see */
  const filter = useAbilitiesBackendFilter({
    personalPermissionPath: "timesheets:personal",
    othersPermissionPath: "timesheets:others",
    teamMemberField: { fieldName: "team_member", fieldType: "string" },
    jobField: { fieldName: "job", fieldType: "string" },
    inboxMode: opts?.inboxMode,
    appModule: "workforce_management",
  });

  /** Team member filter predicate */
  const teamPredicate = useAbilitiesTeamPredicate<TimesheetAction>("timesheets");

  /** Job filter predicate */
  const jobPredicate = useAbilitiesJobPredicate<TimesheetAction>("timesheets");

  return useMemo(
    () => ({ can, cannot, filter, teamPredicate, jobPredicate }),
    [can, cannot, filter, teamPredicate, jobPredicate]
  );
};

const getTeamMemberId = (
  item:
    | Timesheet
    | AggregatedTimesheet
    | TimesheetTableEntry
    | EditPayPeriodTimesheetRow
    | PayPeriodHoursRow
    | AggregatedMiterEarning
): string | undefined => {
  if ("team_member_id" in item) {
    return item.team_member_id;
  } else if ("team_member" in item) {
    return typeof item.team_member === "string" ? item.team_member : item.team_member?._id;
  } else {
    return undefined;
  }
};

const getJobId = (
  item:
    | Timesheet
    | AggregatedTimesheet
    | TimesheetTableEntry
    | EditPayPeriodTimesheetRow
    | PayPeriodHoursRow
    | AggregatedMiterEarning
): string | undefined => {
  if ("job_id" in item) {
    return item.job_id;
  } else if ("job" in item) {
    return typeof item.job === "string" ? item.job : item.job?._id;
  } else {
    return undefined;
  }
};
