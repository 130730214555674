import { LeaveType, MiterAPI } from "dashboard/miter";
import { Plus, TrashSimple } from "phosphor-react";
import React, { useState } from "react";
import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";

import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { LeaveTypeModal } from "./LeaveTypeModal";
import { useRefetchLeaveTypes } from "dashboard/hooks/atom-hooks";
import { Notifier } from "dashboard/utils";

type Props = {
  leaveTypes: LeaveType[];
};

export const LeaveTypesTable: React.FC<Props> = ({ leaveTypes }) => {
  const { can } = useMiterAbilities();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedLeaveType, setClickedLeaveType] = useState<LeaveType | undefined>(undefined);
  const [selectedLeaveTypes, setSelectedLeaveTypes] = useState<LeaveType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const refetchLeaveTypes = useRefetchLeaveTypes();

  const staticActions: TableActionLink[] = [
    {
      label: "Add leave type",
      className: "button-2 no-margin",
      action: () => setShowModal(true),
      important: true,
      icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      shouldShow: () => can("time_off:leave:manage"),
    },
  ];

  const handleRowClick = (row: LeaveType) => {
    setClickedLeaveType(row);
    setShowModal(true);
  };

  const hideModal = () => {
    refetchLeaveTypes();
    setShowModal(false);
    setClickedLeaveType(undefined);
  };

  const handleDelete = async () => {
    setLoading(true);
    await Promise.all(
      selectedLeaveTypes.map(async (leaveType) => {
        try {
          const response = await MiterAPI.time_off.leave.update_type(leaveType._id, {
            ...leaveType,
            archived: true,
          });
          if (response.error) {
            throw new Error(response.error);
          }
          Notifier.success("Deleted leave type.");
        } catch (e: $TSFixMe) {
          console.log(e);
          Notifier.error(e.message);
        }
      })
    );

    setLoading(false);
    refetchLeaveTypes();
    setSelectedLeaveTypes([]);
  };

  const handleSelect = (selections: LeaveType[]) => {
    setSelectedLeaveTypes(selections);
  };

  const dynamicActions = [
    {
      label: "Delete",
      action: () => handleDelete(),
      className: "button-1 table-button",
      icon: <TrashSimple weight="bold" style={{ marginRight: 5, marginBottom: -2 }} />,
    },
  ];

  const renderTable = () => {
    return (
      <>
        {showModal && <LeaveTypeModal hide={hideModal} leaveType={clickedLeaveType} />}
        <TableV2
          id={"leave-types-table"}
          resource="leave types"
          data={leaveTypes}
          dynamicActions={dynamicActions}
          onSelect={handleSelect}
          defaultSelectedRows={selectedLeaveTypes}
          columns={leaveTypeColumns}
          staticActions={staticActions}
          onClick={handleRowClick}
          isLoading={loading}
        />
      </>
    );
  };

  return <div>{renderTable()}</div>;
};

const leaveTypeColumns: ColumnConfig<LeaveType>[] = [
  {
    field: "label",
    headerName: "Name",
    dataType: "string",
  },
  {
    field: "config.pause_notifications",
    headerName: "Pause notifications",
    dataType: "boolean",
    width: 220,
  },
  {
    field: "config.pause_payroll",
    headerName: "Pause payroll",
    dataType: "boolean",
  },
  {
    field: "config.time_off.pause_accruals",
    headerName: "Pause fixed accruals",
    dataType: "boolean",
    width: 220,
  },
  {
    field: "config.time_off.pause_level_changes",
    headerName: "Pause level changes",
    dataType: "boolean",
    width: 220,
  },
  {
    field: "config.time_off.pause_applying_carryover_limits",
    headerName: "Pause apply carryover limits",
    dataType: "boolean",
    width: 260,
  },
];
