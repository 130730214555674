import { AggregatedTeamMember, CreateFilesWithoutBlobParams, Account } from "dashboard/miter";
import { AggregatedFillableTemplate } from "dashboard/types/fillable-template-types";

type CreateFillableDocumentParams = {
  teamMembers: AggregatedTeamMember[];
  fillableTemplates: AggregatedFillableTemplate[];
  account: Account;
  onboarding?: boolean;
};

export const createFillableDocumentParams = (
  input: CreateFillableDocumentParams
): CreateFilesWithoutBlobParams => {
  const { teamMembers, fillableTemplates, account, onboarding } = input;
  const roleId = account.type === "role" ? account.role._id : undefined;
  const teamMemberId = account.type === "team_member" ? account.team_member._id : undefined;
  const files = fillableTemplates.flatMap((fillableTemplate) => {
    return teamMembers.map((teamMember) => {
      const label = `${fillableTemplate.name} - ${teamMember.full_name}`;
      return {
        originalname: label,
        type: "application/pdf",
        label,
        size: 0,
        company_id: fillableTemplate.company_id,
        archived: false,
        parent_type: "team_member" as const,
        parent_id: teamMember._id,
        fillable_template_id: fillableTemplate._id,
        fill_status: "pending" as const,
        tag_ids: [],
        answers: [],
        requestor: {
          team_member_id: teamMemberId,
          role_id: roleId,
        },
        existing_tm_document: true,
        onboarding_document: !!onboarding,
      };
    });
  });
  return { files };
};
