import styles from "../TeamMemberChecklistBuilder.module.css";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { I9EmployerOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";
import { AssigneeForm } from "./TeamMemberChecklistAssigneeForm";

export const I9EmployerTaskContent: React.FC<ChecklistTaskContentProps<I9EmployerOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  return (
    <div className={styles["team-member-checklist-task-content"]}>
      Admin must complete the I-9 form for the new hire within 3 days of their start date.
      <DueDateForm task={task} setTask={setTask} />
      <AssigneeForm task={task} setTask={setTask} />
    </div>
  );
};
