import { ColumnConfig } from "ui/table-v2/Table";
import { toDollarFormat } from "../../reportUtils";
import { PayrollEntry } from "backend/utils/reports/payrollJournal";
import { ValueGetterParams } from "ag-grid-community";
import { PayrollJournalEntry } from "./CreatePayrollJournal";
import { useMemo } from "react";

export const outputTypeOptions = [
  { label: "Payrolls", value: "payrolls" },
  { label: "Employee payments", value: "employee_payments" },
  { label: "Contractor payments", value: "contractor_payments" },
];

type UsePayrollJournalColumnDefs = {
  payrolls: ColumnConfig<PayrollEntry>[];
  employee_payments: ColumnConfig<PayrollJournalEntry>[];
  contractor_payments: ColumnConfig<PayrollJournalEntry>[];
};

export const usePayrollJournalColumnDefs = (): UsePayrollJournalColumnDefs => {
  return useMemo(
    () => ({
      employee_payments: [
        {
          field: "item_id",
          filter: "agTextColumnFilter",
          headerName: "Payment ID",
          initialHide: true,
        },
        {
          field: "team_member.friendly_id",
          filter: "agTextColumnFilter",
          headerName: "Employee ID",
          enableRowGroup: true,
        },
        {
          field: "full_name",
          filter: "agTextColumnFilter",
          headerName: "Employee",
          enableRowGroup: true,
        },
        {
          field: "first_name",
          headerName: "First name",
          initialHide: true,
          filter: "agTextColumnFilter",
          enableRowGroup: true,
        },
        {
          field: "last_name",
          headerName: "Last name",
          initialHide: true,
          filter: "agTextColumnFilter",
          enableRowGroup: true,
        },
        {
          field: "department",
          filter: true,
          headerName: "Employee department",
          enableRowGroup: true,
        },
        {
          field: "team_member.union_rate.classification",
          filter: true,
          headerName: "Employee classification",
          enableRowGroup: true,
        },
        {
          field: "wc_code",
          filter: true,
          headerName: "Workers' comp code",
          enableRowGroup: true,
        },
        {
          field: "status",
          filter: "agTextColumnFilter",
          headerName: "Status",
          enableRowGroup: true,
        },
        {
          field: "pay_type",
          filter: "agTextColumnFilter",
          headerName: "Pay type",
          enableRowGroup: true,
        },
        {
          field: "period_start",
          headerName: "Period start",
        },
        {
          field: "period_end",
          headerName: "Period end",
        },
        {
          field: "payday",
          headerName: "Payday",
        },
        {
          field: "payment_method",
          headerName: "Payment method",
          filter: true,
        },
        {
          field: "paper_check_number",
          headerName: "Paper check number",
        },
        {
          field: "reg_hours",
          headerName: "REG hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "ot_hours",
          headerName: "OT hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "dot_hours",
          headerName: "DOT hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "sick_hours",
          headerName: "Sick hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "vacation_hours",
          headerName: "Vacation hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "holiday_hours",
          headerName: "Holiday hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "total_hours",
          headerName: "Total hours",
          aggFunc: "sumValues",
          sumRow: true,
        },
        {
          field: "gross_earnings",
          headerName: "Gross earnings",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "fit_taxable_wages",
          headerName: "FIT taxable wages",
          aggFunc: "sumValues",
          valueGetter: (params: ValueGetterParams<PayrollJournalEntry>): number => {
            if (!params.data) return 0;
            if (!("pre_tax_emp_benefit_contributions" in params.data)) return params.data.gross_earnings;

            return params.data.gross_earnings - params.data.pre_tax_emp_benefit_contributions;
          },
          valueFormatter: toDollarFormat,
          sumRow: true,
          initialHide: true,
        },
        {
          field: "medicare_taxable_wages",
          headerName: "Medicare taxable wages",
          aggFunc: "sumValues",
          valueGetter: (params: ValueGetterParams<PayrollJournalEntry>): number => {
            if (!params.data) return 0;
            if (!("pre_tax_emp_benefit_contributions" in params.data)) return params.data.gross_earnings;

            return (
              params.data.gross_earnings -
              params.data.pre_tax_emp_benefit_contributions +
              params.data.pre_tax_emp_retirement_contributions
            );
          },
          valueFormatter: toDollarFormat,
          sumRow: true,
          initialHide: true,
        },
        {
          field: "net_earnings",
          headerName: "Net earnings",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "reimbursements",
          headerName: "Reimbursements",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "employee_taxes",
          headerName: "Employee taxes",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "company_taxes",
          headerName: "Company taxes",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "company_benefits",
          headerName: "Employer benefit contributions",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "employee_benefits",
          headerName: "Employee benefit contributions",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "pre_tax_emp_benefit_contributions",
          headerName: "Pre-tax employee benefit contributions",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
          initialHide: true,
        },
        {
          field: "pre_tax_emp_retirement_contributions",
          headerName: "Pre-tax employee retirement contributions",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
          initialHide: true,
        },
        {
          field: "deductions",
          headerName: "Deductions",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
        {
          field: "managedDeductions",
          headerName: "Managed deductions",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
          sumRow: true,
        },
      ],
      contractor_payments: [
        {
          field: "cp_id",
          headerName: "Contractor ID",
          hide: true,
        },
        {
          field: "full_name",
          filter: "agTextColumnFilter",
          headerName: "Contractor",
          enableRowGroup: true,
        },
        {
          field: "status",
          filter: true,
          headerName: "Status",
          enableRowGroup: true,
        },
        {
          field: "period_start",
          headerName: "Period start",
        },
        {
          field: "period_end",
          headerName: "Period end",
        },
        {
          field: "payday",
          headerName: "Payday",
        },
        {
          field: "payment_method",
          headerName: "Payment method",
          filter: true,
        },
        {
          field: "paper_check_number",
          headerName: "Paper check number",
        },
        {
          field: "gross_earnings",
          headerName: "Gross earnings",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
        },
        {
          field: "reimbursements",
          headerName: "Reimbursements",
          aggFunc: "sumValues",
          valueFormatter: toDollarFormat,
        },
      ],
      payrolls: [
        {
          field: "_id",
          headerName: "Payroll ID",
        },
        {
          field: "payroll_type",
          headerName: "Payroll type",
        },
        {
          field: "status",
          filter: "agTextColumnFilter",
          headerName: "Status",
          enableRowGroup: true,
        },
        {
          field: "check_payroll.period_start",
          headerName: "Period start",
        },
        {
          field: "check_payroll.period_end",
          headerName: "Period end",
        },
        {
          field: "check_payroll.payday",
          headerName: "Payday",
        },
        {
          field: "payrollTotals.employee_gross",
          headerName: "Employee earnings",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.employee_reimbursements",
          headerName: "Employee reimbursements",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.post_tax_deductions",
          headerName: "Employee deductions",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.contractor_gross",
          headerName: "Contractor earnings",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.contractor_reimbursements",
          headerName: "Contractor reimbursements",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.employee_taxes",
          headerName: "Employee taxes",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.company_taxes",
          headerName: "Employer taxes",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.company_benefits",
          headerName: "Employer benefit contributions",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.employee_benefits",
          headerName: "Employee benefit contributions",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "payrollTotals.cash_requirement",
          headerName: "Cash requirement",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "directDeposits",
          headerName: "Direct deposits",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "managedDeductions",
          headerName: "Managed deductions",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
        {
          field: "paperCheckNetPay",
          headerName: "Paper check net pay",
          valueFormatter: toDollarFormat,
          aggFunc: "sumValues",
        },
      ],
    }),
    []
  );
};
