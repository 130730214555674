import React, { FC } from "react";
import DataBox from "../dataBox/DataBox";
import { DataBoxRow } from "../dataBox/DataBoxRow";
import { CertificationType } from "dashboard/types/certification-types";
import { sortBy, truncate } from "lodash";
import { useCertificationTypeAbilities } from "dashboard/hooks/abilities-hooks/useCertificationTypeAbilities";
import { joinWithAnd } from "miter-utils";

type Props = {
  certificationType: CertificationType;
  onEdit: () => void;
};

export const mapNotificationToString = {
  "1_week": "1 week",
  "2_weeks": "2 weeks",
  "3_weeks": "3 weeks",
  day_of: "day of",
};

export const CertificationTypeDetails: FC<Props> = ({ certificationType, onEdit }) => {
  const { can } = useCertificationTypeAbilities();
  const { title, description, expires, automatic_request_on_expiration, expiration_notification } =
    certificationType;

  const { notify_before } = expiration_notification || {};
  const notificationString =
    joinWithAnd(sortBy(notify_before || []).map((v) => mapNotificationToString[v])) || "";

  return (
    <div>
      <DataBox
        title="Certification details"
        className="flex-col no-margin"
        onEdit={can("update", certificationType) ? () => onEdit() : undefined}
      >
        <DataBoxRow label="Title" value={title} />
        <DataBoxRow label="Description" value={truncate(description, { length: 20 })} />
        <DataBoxRow label="Certification expires" value={expires ? "Yes" : "No"} />
        {expires && <DataBoxRow label="Notifications sent before expiry" value={notificationString} />}

        {expires && (
          <DataBoxRow
            label="Automatic renewal requests on expiry"
            value={automatic_request_on_expiration ? "Enabled" : "Disabled"}
          />
        )}
      </DataBox>
    </div>
  );
};
