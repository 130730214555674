import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { Controller, UseFormMethods } from "react-hook-form";
import Select from "react-select";
import { Formblock, Label, ModalFooter, ModalHeader } from "ui";
import { stateStyles } from "ui/form/styles";
import { Assignment } from "dashboard/miter";
import { RecurrenceConfig } from "backend/models/assignment";
import styles from "./AssignmentForm.module.css";
import { getWeekOfTheMonth } from "./utils";

type Props = {
  hide: () => void;
  form: UseFormMethods;
  assignment: Assignment | undefined;
  draftRecurrenceConfig: RecurrenceConfig | null | undefined;
  setDraftRecurrenceConfig: (config: RecurrenceConfig | undefined) => void;
};

export const CustomRecurrenceModal: React.FC<Props> = ({
  hide,
  form,
  draftRecurrenceConfig,
  setDraftRecurrenceConfig,
  assignment,
}) => {
  const { setValue, control, watch, register, errors } = form;
  const formData = watch();

  const startsAt: DateTime | undefined =
    formData.starts_at || (assignment?.starts_at ? DateTime.fromSeconds(assignment.starts_at) : undefined);

  const [numOccurrences, setNumOccurrences] = useState<number>();

  const initialEndOnDate = draftRecurrenceConfig?.last_occurrence
    ? DateTime.fromSeconds(draftRecurrenceConfig?.last_occurrence)
    : undefined;

  const [endOnDate, setEndOnDate] = useState<DateTime | undefined>(initialEndOnDate);
  const [timePeriod, setTimePeriod] = useState(draftRecurrenceConfig?.time_period || "day");
  const [monthPeriod, setMonthPeriod] = useState();
  const [daysOfTheWeek, setDaysOfTheWeek] = useState<number[]>(draftRecurrenceConfig?.days_of_the_week || []);
  const [recurInterval, setRecurInterval] = useState(draftRecurrenceConfig?.interval || 1);

  let initialEnds: "never" | "on" | "after" = "never" as const;
  if (draftRecurrenceConfig?.last_occurrence) {
    initialEnds = "on" as const;
  } else if (draftRecurrenceConfig?.total_occurrences) {
    initialEnds = "after" as const;
  }

  const [ends, setEnds] = useState<"never" | "on" | "after">(initialEnds);

  const handleEndsChange = (e) => {
    setEnds(e.target.value);
  };

  const handleOccurrenceChange = (event) => {
    setNumOccurrences(parseInt(event.target.value));
    setValue("total_occurrences", event.target.value);
  };

  const timeOptions = useMemo(() => {
    return [
      { label: `day${recurInterval > 1 ? "s" : ""}`, value: "day" },
      { label: `week${recurInterval > 1 ? "s" : ""}`, value: "week" },
      { label: `month${recurInterval > 1 ? "s" : ""}`, value: "month" },
      { label: `year${recurInterval > 1 ? "s" : ""}`, value: "year" },
    ];
  }, [recurInterval]);

  const daysArray = ["S", "M", "T", "W", "T", "F", "S"];

  const setI = (e) => {
    setValue("interval", Number(e.target.value));
    setRecurInterval(Number(e.target.value));
  };
  const setTP = (e) => {
    setTimePeriod(e.value);
  };

  const handleMonthChange = (e) => {
    setValue("month_period", e);
    setMonthPeriod(e);
  };

  const handleSubmit = () => {
    setDraftRecurrenceConfig({
      time_period: timePeriod || "day",
      interval: recurInterval,
      days_of_the_week: daysOfTheWeek.length && timePeriod === "week" ? daysOfTheWeek : undefined,
      day_of_the_month: monthPeriod === "monthly_by_date" ? startsAt?.day : undefined,
      week_of_the_month: monthPeriod === "monthly_by_weekday" ? startsAt?.day : undefined,
      total_occurrences: numOccurrences,
      last_occurrence: ends === "on" ? endOnDate?.toSeconds() : null,
    });
    hide();
  };
  const monthlyOptions = startsAt
    ? [
        {
          value: "monthly_by_date",
          label: `Monthly on day ${startsAt?.day}`,
        },
        {
          value: "monthly_by_weekday",
          label: `Monthly on the ${getWeekOfTheMonth(startsAt?.day)} ${startsAt?.weekdayLong}`,
        },
      ]
    : [];

  const updateArray = (idx) => {
    if (daysOfTheWeek.includes(idx)) {
      daysOfTheWeek.splice(daysOfTheWeek.indexOf(idx), 1);
    } else {
      daysOfTheWeek.push(idx);
    }
    // @ts-expect-error Not sure
    setValue(daysOfTheWeek);
    setDaysOfTheWeek(daysOfTheWeek);
  };

  return (
    <div className="modal-background">
      <div className={"modal-wrapper form"}>
        <ModalHeader heading="Custom recurrence" onHide={hide} className={"time-off-request"} />
        <div className="vertical-padding-20 modal-body form overflow-visible">
          <>
            <Label label="Repeats every" className="modal" />
            <div className="form-2-unit-wrapper margin-bottom-12">
              <input
                className={"form2-text"}
                autoComplete="no"
                name="interval"
                min="1"
                type="number"
                defaultValue={recurInterval}
                onChange={setI}
                value={recurInterval}
              />
              <Controller
                register={register}
                name={"timePeriod"}
                control={control}
                defaultValue={timePeriod}
                onChange={setTP}
                render={() => (
                  <Select
                    value={{ label: `${timePeriod}${recurInterval > 1 ? "s" : ""}`, value: timePeriod }}
                    onChange={setTP}
                    className={styles["time-period-select"]}
                    options={timeOptions}
                    height="32px"
                    width="100%"
                    styles={stateStyles}
                  />
                )}
              />
            </div>
            {timePeriod === "week" && (
              <>
                <Label label="Repeat on" className="modal" />
                <div className="flex margin-bottom-12">
                  {daysArray.map((text, idx) => (
                    <div
                      onClick={() => updateArray(idx)}
                      key={idx}
                      className={`round-button ${daysOfTheWeek.includes(idx) ? "button-2" : "button-1"}`}
                    >
                      {text}
                    </div>
                  ))}
                </div>
              </>
            )}
            {timePeriod === "month" && formData.starts_at && (
              <Formblock
                type="select"
                control={control}
                name="month_period"
                options={monthlyOptions}
                onChange={handleMonthChange}
                defaultValue={undefined}
                className="modal "
                errors={errors}
                editing={true}
              />
            )}
            <Label label="Ends" className="modal no-margin-important" />
            <div>
              <div className="flex checkbox-input-wrapper flex radio">
                <input
                  onChange={handleEndsChange}
                  type="radio"
                  name="never"
                  className="margin-left-0"
                  value="never"
                  ref={register()}
                  checked={ends === "never"}
                />
                <div className="flex space-between width-100-percent">
                  <div className="checkbox-text radio-text">Never</div>
                  <div style={{ height: 32, width: 2 }} />
                </div>
              </div>
              <div className="flex checkbox-input-wrapper flex radio">
                <input
                  type="radio"
                  ref={register()}
                  value="on"
                  name="on"
                  className="margin-left-0"
                  onChange={handleEndsChange}
                  checked={ends === "on"}
                />
                <div className="flex space-between width-100-percent">
                  <div className="checkbox-text radio-text">On</div>
                  <Formblock
                    type="datetime"
                    control={control}
                    onChange={setEndOnDate}
                    name="on"
                    defaultValue={endOnDate}
                    className="modal date-recurring"
                    errors={errors}
                    editing={true}
                    disabled={ends !== "on"}
                    min={form.watch("starts_at")?.plus({ days: 1 })}
                    dateOnly
                  />
                </div>
              </div>

              <div className="flex checkbox-input-wrapper flex radio">
                <input
                  ref={register()}
                  type="radio"
                  value="after"
                  onChange={handleEndsChange}
                  name="after"
                  className="margin-left-0"
                  checked={ends === "after"}
                />
                <div className="flex space-between width-100-percent">
                  <div className="checkbox-text radio-text">After</div>
                  <div className="date-recurring flex" id="total_occurrences">
                    <input
                      autoComplete="no"
                      className="form2-text occurrences-number"
                      name={"total_occurrences"}
                      disabled={ends !== "after"}
                      defaultValue={draftRecurrenceConfig?.total_occurrences}
                      type="number"
                      min="1"
                      onChange={handleOccurrenceChange}
                      value={numOccurrences}
                    />
                    <span
                      className={`form2-text occurrences date-recurring ${
                        formData.ends !== "ends_after" && "span-disabled"
                      }`}
                    >
                      occurrences
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <ModalFooter
          className="form"
          cancelText="Cancel"
          onCancel={hide}
          submitText={"Submit"}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};
