import { MiterAPI, User } from "dashboard/miter";
import { Notifier, npsOptions } from "dashboard/utils";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { UserSurveyAnswers } from "backend/models/user-survey";

type Props = {
  setNeedsNps: Dispatch<SetStateAction<boolean>>;
  user: User;
};

export const RegularNPSSurvey: React.FC<Props> = ({ user, setNeedsNps }) => {
  const [saving, setSaving] = useState(false);
  const [formAnswers, setFormAnswers] = useState<UserSurveyAnswers | undefined>();
  const form = useForm();

  const isDetractor = formAnswers?.how_likely && parseInt(formAnswers.how_likely) < 7;

  const createSurvey = async (dismissed?: boolean) => {
    if (!dismissed) {
      setSaving(true);
    } else {
      setNeedsNps(false);
    }

    const trimmedExplanation = formAnswers?.explanation?.trim();
    const isValidExplanation = trimmedExplanation && trimmedExplanation.length > 3;

    // If the survey is less than 7, require an explanation
    if (isDetractor && !isValidExplanation && !dismissed) {
      Notifier.error("Please provide an explanation for your score.");
      setSaving(false);
      return;
    }

    try {
      const response = await MiterAPI.user_surveys.create({
        user_id: user._id.toString(),
        dismissed,
        answers: formAnswers,
        survey_id: "regular_nps",
      });
      if (response.error) throw new Error(response.error);
      if (!dismissed) Notifier.success("Your response was saved successfully.");
      setNeedsNps(false);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error saving your response. We're looking into it!");
    }
    setSaving(false);
  };

  const updateFormAnswers = (params: Partial<UserSurveyAnswers>) => {
    setFormAnswers((prev) => {
      return { ...prev, ...params };
    });
  };

  return (
    <div>
      <ActionModal
        headerText="We need your feedback!"
        onHide={() => createSurvey(true)}
        onCancel={() => createSurvey(true)}
        showSubmit={true}
        submitText="Save"
        onSubmit={() => createSurvey()}
        loading={saving}
      >
        <div className="vertical-spacer"></div>
        <Formblock
          type="select"
          name="how_likely"
          label="How likely would you be to recommend Miter to a friend or colleague?*"
          form={form}
          editing={true}
          options={npsOptions}
          requiredSelect={true}
          onChange={(option) => updateFormAnswers({ how_likely: option.value })}
          className="modal"
        />
        {!!formAnswers?.how_likely && (
          <>
            <Formblock
              type="paragraph"
              name="explanation"
              label={"Why did you give that answer?" + (isDetractor ? " (required)" : "")}
              form={form}
              editing={true}
              className="modal"
              onChange={(e) => updateFormAnswers({ explanation: e.target.value })}
            />
            <Formblock
              type="multiselect"
              name="areas_to_improve"
              label="What modules need the most improvement?"
              form={form}
              editing={true}
              options={AREAS_TO_IMPROVE_OPTIONS}
              className="modal"
              onChange={(options) => updateFormAnswers({ areas_to_improve: options.map((o) => o.value) })}
              height={"unset"}
            />
            <Formblock
              type="paragraph"
              name="feature_ideas"
              label="What is Miter missing? What should Miter build next?"
              form={form}
              editing={true}
              className="modal"
              onChange={(e) => updateFormAnswers({ feature_ideas: e.target.value })}
            />
          </>
        )}

        <div className="vertical-spacer"></div>
      </ActionModal>
    </div>
  );
};

const AREAS_TO_IMPROVE_OPTIONS = [
  { label: "Timesheets, Daily Reports, or Scheduling", value: "WFM" },
  { label: "Payroll and Compliance", value: "Payroll" },
  { label: "Onboarding, Documents, Recruiting, and Performance", value: "HR" },
  { label: "Benefits and Insurance", value: "Benefits" },
  { label: "Cards, Reimbursements, and Per Diems", value: "Expenses" },
  { label: "Reports and Analytics", value: "Reports" },
  { label: "Mobile App", value: "Mobile" },
  { label: "Integrations", value: "Integrations" },
  { label: "Other", value: "Other" },
];
