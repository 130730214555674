import { AggregatedPayRateGroup, MiterAPI } from "dashboard/miter";
import React, { useState } from "react";
import { ActionModal, Formblock } from "ui";
import { useForm } from "react-hook-form";
import * as vals from "dashboard/utils/validators";
import { Notifier } from "dashboard/utils";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";

type Props = {
  hide: () => void;
  onSubmit: (prg: AggregatedPayRateGroup) => void;
};

export const CreatePayRateGroupModal: React.FC<Props> = ({ hide, onSubmit }) => {
  // Hooks
  const activeCompanyId = useActiveCompanyId();
  const { register, errors, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const save = async (data: { label: string }) => {
    if (!activeCompanyId) return;
    setLoading(true);
    try {
      const response = await MiterAPI.pay_rate_groups.create({
        company: activeCompanyId,
        label: data.label,
      });
      if (response.error) throw new Error(response.error);
      onSubmit({
        ...response,
        union_rates: [],
      });
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the rate group. We're looking into it!");
    }
    setLoading(false);
  };

  return (
    <ActionModal
      headerText={"Create rate group"}
      onHide={hide}
      showCancel={true}
      onCancel={hide}
      bodyStyle={{ maxHeight: 50 }}
      showSubmit={true}
      onSubmit={handleSubmit(save)}
      submitText={"Create"}
      loading={loading}
    >
      <div className="vertical-spacer"></div>
      <Formblock
        label="Label*"
        labelInfo="The name of the rate group (i.e., IBEW Local 89)."
        className="modal"
        type="text"
        register={register(vals.required)}
        name="label"
        errors={errors}
        editing={true}
      />
      <div className="vertical-spacer"></div>
    </ActionModal>
  );
};
