import {
  useActiveCompanyId,
  useLookupMessageTemplate,
  useRefetchMessageTemplates,
} from "dashboard/hooks/atom-hooks";
import { ActionModal, Formblock, Notifier } from "ui";
import React, { FC, useRef } from "react";
import { useForm } from "react-hook-form";
import { SMSTemplateEditor } from "./SMSTemplateEditor";
import * as vals from "dashboard/utils/validators";
import { MiterAPI, MiterError } from "dashboard/miter";
import { getSmartFieldsFromHTML, messageTemplateSmartFields } from "dashboard/utils/message-templates";
import { MessageTemplate } from "dashboard/types/message-template";
import styles from "./MessageTemplate.module.css";

type Props = {
  onClose: () => void;
  messageTemplateId?: string;
};

type MessageTemplateForm = {
  name: string;
};

const DEFAULT_TOOLBAR = "mergetags";

export const MessageTemplateModal: FC<Props> = ({ messageTemplateId, onClose }) => {
  const lookupMessageTemplate = useLookupMessageTemplate();
  const activeCompanyId = useActiveCompanyId();
  const messageTemplate = lookupMessageTemplate(messageTemplateId);
  const refetchMessageTemplates = useRefetchMessageTemplates();
  const form = useForm<MessageTemplateForm>({
    defaultValues: {
      name: messageTemplate?.name,
    },
    reValidateMode: "onChange",
    mode: "all",
  });
  const { register, watch } = form;
  const editorRef = useRef();
  const name = watch("name");

  const onSubmit = async () => {
    try {
      if (!activeCompanyId) {
        Notifier.error("Your browser session expired. Please refresh your page and try again.");
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Test
      const html = editorRef.current?.getContent();
      if (!name) {
        Notifier.error("Please enter a template name");
        return;
      }
      if (!html) {
        Notifier.error("Please enter a template body.");
        return;
      }
      let res: (MessageTemplate & MiterError) | undefined = undefined;

      const smartFields = getSmartFieldsFromHTML(html);

      if (messageTemplate) {
        res = await MiterAPI.message_templates.update(messageTemplate._id, {
          name,
          raw_html: html,
          smart_fields: smartFields,
        });
      } else {
        res = await MiterAPI.message_templates.create({
          company_id: activeCompanyId,
          module: "recruiting",
          name,
          raw_html: html,
          smart_fields: smartFields,
          archived: false,
        });
      }
      if (res.error) {
        throw new Error(res.error);
      }
      Notifier.success(`Message template ${messageTemplate ? "updated" : "created"} successfully.`);
      onClose();
      refetchMessageTemplates();
    } catch (error: $TSFixMe) {
      Notifier.error(`Failed to ${messageTemplate ? "update" : "create"} message template: ${error.message}`);
    }
  };

  return (
    <ActionModal
      headerText={messageTemplateId ? "Edit message template" : "Create message template"}
      showSubmit={true}
      submitText="Save"
      showCancel={true}
      cancelText="Cancel"
      onCancel={onClose}
      onHide={onClose}
      onSubmit={onSubmit}
      wrapperClassName={styles["message-template-modal"]}
    >
      <div className={styles["message-template-modal-content"]}>
        <Formblock
          label="Template name"
          type="text"
          defaultValue={messageTemplate?.name}
          placeholder="Enter template name"
          editing={true}
          form={form}
          className="modal"
          name="name"
          register={register(vals.required)}
        />
        <SMSTemplateEditor
          defaultHTML={messageTemplate?.raw_html}
          toolbar={DEFAULT_TOOLBAR}
          height={400}
          smartFields={messageTemplateSmartFields}
          editorRef={editorRef}
        />
      </div>
    </ActionModal>
  );
};
