import styles from "../TeamMemberChecklistBuilder.module.css";
import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { PersonalInfoOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { Formblock } from "ui";
import { isEmptyAddress } from "dashboard/utils";
import { useEffect, useCallback, useState } from "react";
import { PERSONAL_INFO_FIELD_OPTIONS } from "dashboard/utils/onboardingV2";

export const PersonalInfoTaskContent: React.FC<ChecklistTaskContentProps<PersonalInfoOnboardingTaskForm>> = ({
  task,
  setTask,
  teamMember,
}) => {
  const [settingDefaults, setSettingDefaults] = useState(true);

  const tmIsEnrolledInPayroll = teamMember?.check_tm != null;

  const isSSNRequired = tmIsEnrolledInPayroll && !teamMember?.ssn_last_four;
  const isDOBRequired = tmIsEnrolledInPayroll && !teamMember?.dob;
  const isAddressRequired = tmIsEnrolledInPayroll && isEmptyAddress(teamMember?.address);

  const handleFieldChange = useCallback(
    (field: string, value: boolean) => {
      if (!setTask) return;

      setTask({
        ...task,
        data: {
          ...task.data,
          fields: {
            ...DEFAULT_PERSONAL_INFO_FIELDS,
            ...task.data?.fields,
            [field]: value,
          },
        },
      });
    },
    [setTask, task]
  );

  useEffect(() => {
    if (!setTask || !teamMember || !settingDefaults) return;

    const requiredFields = {
      ssn: isSSNRequired,
      dob: isDOBRequired,
      address: isAddressRequired,
    };

    const updatedFields = { ...task.data?.fields };
    let hasChanges = false;

    Object.entries(requiredFields).forEach(([field, isRequired]) => {
      if (isRequired && !updatedFields[field]) {
        updatedFields[field] = true;
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setTask({
        ...task,
        data: {
          ...task.data,
          fields: {
            ...DEFAULT_PERSONAL_INFO_FIELDS,
            ...task.data?.fields,
            ...updatedFields,
          },
        },
      });
    }
    setSettingDefaults(false);
  }, [
    setTask,
    task,
    teamMember,
    tmIsEnrolledInPayroll,
    isSSNRequired,
    isDOBRequired,
    isAddressRequired,
    settingDefaults,
  ]);

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <div className={styles["checklist-task-content"]}>
        {PERSONAL_INFO_FIELD_OPTIONS.map(({ value: field, label }) => {
          const isRequiredField =
            (field === "ssn" && isSSNRequired) ||
            (field === "dob" && isDOBRequired) ||
            (field === "address" && isAddressRequired);

          return (
            <Formblock
              key={"personal-info-" + field}
              name={label}
              type="checkbox"
              text={label}
              defaultValue={task.data?.fields?.[field]}
              disabled={isRequiredField}
              tooltipText={isRequiredField ? "This field is required for payroll enrollment." : undefined}
              tooltipPlace="right"
              editing={true}
              className="modal"
              style={{ marginBottom: 0, marginTop: 0 }}
              onChange={(e) => handleFieldChange(field, e.target.checked)}
            />
          );
        })}
        <DueDateForm task={task} setTask={setTask} />
      </div>
    </div>
  );
};

const DEFAULT_PERSONAL_INFO_FIELDS = {
  ssn: true,
  dob: true,
  address: true,
  language: true,
  emergency_contacts: true,
  profile_picture: true,
};
