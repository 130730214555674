import React from "react";
import { DateTime } from "luxon";
import { ScheduleEvent } from "backend/services/schedule-service";

export type FetchScheduleEventsParams = {
  starts_at?: number[];
  ends_at?: number[];
  job?: string;
  team_member?: string | string[];
};

type SchedulingContext = {
  scheduleEvents: ScheduleEvent[];
  fetchScheduleEvents: (params?: FetchScheduleEventsParams) => Promise<void>;
  setScheduleEvents: React.Dispatch<React.SetStateAction<ScheduleEvent[]>>;
  isCompanyWide?: boolean;
  lastQueriedStart: DateTime;
  setLastQueriedStart: React.Dispatch<React.SetStateAction<DateTime>>;
  lastQueriedEnd: DateTime;
  setLastQueriedEnd: React.Dispatch<React.SetStateAction<DateTime>>;
};

const SchedulingContext = React.createContext<SchedulingContext>({
  scheduleEvents: [],
  fetchScheduleEvents: async () => {},
  setScheduleEvents: () => {},
  isCompanyWide: true,
  lastQueriedStart: DateTime.now(),
  setLastQueriedStart: () => {},
  lastQueriedEnd: DateTime.now(),
  setLastQueriedEnd: () => {},
});

export default SchedulingContext;
