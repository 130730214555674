import { AggregatedMiterEarning, MiterAPI, MiterIntegrationForCompany, TimeOffPolicy } from "dashboard/miter";
import { Notifier } from "ui";
import { Parser } from "json2csv";
import { WbsReportRow } from "backend/utils/reports/wbsExport";
import { downloadCsvFromBlob, downloadPlainTextFromBlob, getIntegrationConnectionByKey } from ".";
import { NotFound } from "http-errors";
import { DateTime } from "luxon";
import { LookupAtomFunction } from "dashboard/atoms";
import { CsvDownloadObj } from "backend/utils";

export const downloadFoundationCsv = async (inputs: {
  ledgerEntryIds: string[];
  companyId: string;
}): Promise<void> => {
  try {
    const rows = await MiterAPI.ledger_entries.get_foundation_csv(inputs);
    if (rows.error) throw new Error(rows.error);
    const data = rows.map((r) => {
      return {
        Account: r.glAccountNum,
        "Division 1": "",
        "Division 2": "",
        "Division 3": "",
        "Division 4": "",
        "Db/Cr": r.dbCr,
        Amount: r.amount,
        Description: r.description,
        Units: r.units ?? "",
        Job: r.job ?? "",
        Phase: "",
        "Cost Code": r.cost_code ?? "",
        "Cost Class": "",
      };
    });
    const firstRow = data[0] || {};
    const json2csvParser = new Parser({
      fields: Object.keys(firstRow),
      header: false,
    });
    const csv = json2csvParser.parse(data);
    downloadCsvFromBlob(csv, `Miter to Foundation GL Entry upload`);
  } catch (e: $TSFixMe) {
    console.error(e);
    Notifier.error(e.message);
  }
};

export const downloadFromCsvObj = (csvObj: CsvDownloadObj): void => {
  const { data, fileName, includeHeader, columns } = csvObj;
  const glParser = new Parser({
    fields: columns,
    header: includeHeader,
  });
  const glCsv = glParser.parse(data);
  downloadCsvFromBlob(glCsv, fileName);
};

export const downloadWbsCsv = async (inputs: {
  companyId: string;
  periodStart: string;
  periodEnd: string;
  payScheduleId: string;
}): Promise<void> => {
  let reportRows: WbsReportRow[] = [];
  const { periodStart, periodEnd } = inputs;
  try {
    const response = await MiterAPI.reports.create({
      type: "wbs_export",
      format: "json",
      params: inputs,
    });
    if (response.error) throw new Error(response.error);
    reportRows = response;
  } catch (e) {
    console.error(e);
    Notifier.error(`There was an error creating the WBS export. We're looking into it!`);
  }
  const data = reportRows.map((r) => {
    return {
      "Employee ID": r.employeeId,
      "Check Type": "C",
      "Check Sequence": "01",
      "Code Type": r.codeType,
      "Earning/Deduction Code": r.earningDeductionCode,
      "Earning Sequence": "",
      "Job Code": r.jobCode,
      "Department Code": r.departmentCode,
      "Workers Comp State & Code": "",
      Hours: r.hours,
      Rate: "0",
      Amount: "0",
      "Employee Name": r.employeeName,
      "Job Name": r.jobName,
    };
  });
  const firstRow = data[0] || {};
  const json2csvParser = new Parser({
    fields: Object.keys(firstRow),
    header: false,
  });
  const csv = json2csvParser.parse(data);
  downloadCsvFromBlob(csv, `WBS export for pay period ${periodStart} to ${periodEnd}`);
};

export const downloadVistaCsv = (earnings: AggregatedMiterEarning[], periodEnd: string): void => {
  if (!earnings) return;
  const fields = [
    "PP End",
    "VP Company",
    "EE ID",
    "Date Worked",
    "Job",
    "CC",
    "RT or OT",
    "Hours",
    "Employee Name",
  ];
  const data = earnings.map((e) => {
    return {
      "PP End": periodEnd,
      "VP Company": "Miter",
      "EE ID": e.team_member.friendly_id,
      "Date Worked": e.date,
      Job: e.job?.code,
      CC: e.activity?.cost_code,
      "RT or OT": e.check_type == "overtime" || e.check_type == "double_overtime" ? "2" : "1",
      Hours: e.hours,
      "Employee Name": e.team_member.full_name,
    };
  });
  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(data);
  const cleanCsv = csv.replaceAll(`"`, "");
  downloadCsvFromBlob(cleanCsv, "Hours by pay period week ending " + periodEnd);
};

export const downloadJonasJournalEntriesCsv = async (inputs: {
  ledgerEntryIds: string[];
  icId: string;
}): Promise<void> => {
  try {
    const { csv, error } = await MiterAPI.ledger_entries.get_jonas_csv(inputs.ledgerEntryIds, inputs.icId);
    if (error) throw new Error(error);

    downloadCsvFromBlob(csv, `Miter to Jonas GL Entry upload`);
  } catch (e) {
    console.error(e);
    Notifier.error(`Error downloading the Jonas CSV. Please contact support.`);
  }
};

export const downloadJonasTimesheetCsv = (earnings: AggregatedMiterEarning[], periodEnd: string): void => {
  if (!earnings) return;
  const fields = [
    "Date",
    "Employee Number",
    "Employee Name",
    "Job Number",
    "Payroll Code",
    "Cost Item Code",
    "Hours",
    "Cost Type Code",
    "Department Code",
    "Location Code",
    "GL Account",
    "GL Division",
    "GL Sub Account",
    "Occupation",
    "Group",
    "Description",
    "Reference",
    "Miter Time Type",
    "Miter Job",
    "Miter Activity",
  ];
  const data = earnings.map((e) => {
    return {
      Date: e.date,
      "Employee Number": e.team_member.friendly_id,
      "Employee Name": e.team_member.full_name,
      "Job Number": e.job?.code,
      "Payroll Code": "",
      "Cost Item Code": e.activity?.cost_code,
      Hours: e.hours,
      "Cost Type Code": "L",
      "Department Code": e.department?.identifier,
      "Location Code": "",
      "Miter Time Type": e.check_type,
      "Miter Job": e.job?.name,
      "Miter Activity": e.activity?.label,
    };
  });
  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(data);
  const cleanCsv = csv.replaceAll(`"`, "");
  downloadCsvFromBlob(cleanCsv, "Jonas timesheet CSV for week ending " + periodEnd);
};

export const downloadSage300TimeEntryCSV = async (
  earnings: AggregatedMiterEarning[],
  companyId: string,
  periodStart: string,
  periodEnd: string,
  integrations: MiterIntegrationForCompany[]
): Promise<void> => {
  try {
    const sage300Integration = getIntegrationConnectionByKey("sage_300", integrations);
    const integrationConnectionId = sage300Integration?.connection?._id.toString();

    if (!integrationConnectionId) {
      throw new NotFound(`Sage300 Integration Connection not found for company: ${companyId}`);
    }

    const { csv, error } = await MiterAPI.ledger_entries.get_sage_300_time_entry_csv(
      earnings,
      companyId,
      integrationConnectionId,
      periodStart,
      periodEnd
    );
    if (error) throw new Error(error);

    downloadCsvFromBlob(csv, `Sage300 Time Entries Export ${periodStart} to ${periodEnd}`);
  } catch (e) {
    console.error(e);
    Notifier.error(`Error downloading the Sage300 CSV. Please contact support.`);
  }
};

export const getTruebeckPayTypeFromEarning = (
  earning: AggregatedMiterEarning,
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>
): string => {
  let payType = "REG";
  if (earning.check_type === "overtime") {
    payType = "OT";
  } else if (earning.check_type === "double_overtime") {
    payType = "DT";
  } else if (earning.check_type === "pto") {
    payType = "PTO";
    if (earning.time_off_policy_id) {
      const policy = lookupTimeOffPolicy(earning.time_off_policy_id);
      if (policy?.custom_earning_code) {
        payType = policy.custom_earning_code;
      }
    }
  } else if (earning.check_type === "sick") {
    payType = "SICK";
    if (earning.time_off_policy_id) {
      const policy = lookupTimeOffPolicy(earning.time_off_policy_id);
      if (policy?.custom_earning_code) {
        payType = policy.custom_earning_code;
      }
    }
  } else if (earning.check_type === "paid_holiday") {
    payType = "HOL";
  }
  return payType;
};

export const downloadTruebeckUkgCsv = (
  earnings: AggregatedMiterEarning[],
  periodEnd: string,
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>
): void => {
  const fields = ["Employee Number", "Project Number", "Pay Type", "Date", "Hours"];
  const data = earnings.map((e) => {
    return {
      "Employee Number": e.team_member.friendly_id,
      "Project Number": e.job?.code?.replace(".", ""),
      "Pay Type": getTruebeckPayTypeFromEarning(e, lookupTimeOffPolicy),
      Date: e.date ? DateTime.fromISO(e.date).toFormat("MM/dd/yyyy") : null,
      Hours: e.hours || 0,
    };
  });
  const json2csvParser = new Parser({ fields, header: false });
  const csv = json2csvParser.parse(data);
  const cleanCsv = csv.replaceAll(`"`, "");
  downloadCsvFromBlob(cleanCsv, "Truebeck hours export for week ending " + periodEnd);
};

export const downloadTruebeckBridgeTxtFile = (
  earnings: AggregatedMiterEarning[],
  periodEnd: string,
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>
): void => {
  let txt = "";
  earnings.forEach((e) => {
    if (!e.hours) return;
    let row = "";
    // Employee ID
    row += e.team_member.friendly_id + ",";

    // Project Number
    row += (e.job?.code || "") + ",";

    // Extra blank
    row += ",";

    // Activity
    row += e.activity?.cost_code + ",";

    // Pay type
    row += getTruebeckPayTypeFromEarning(e, lookupTimeOffPolicy) + ",";

    // Date
    row += DateTime.fromISO(e.date || periodEnd).toFormat("MM/dd/yyyy") + ",";

    // Hours
    row += e.hours.toFixed(2) + ",";

    // Workers comp code
    row += e.wc_code?.code || "";

    // Add row to txt
    txt += row + "\n";
  });
  downloadPlainTextFromBlob(txt, "Truebeck hours export for week ending " + periodEnd + ".txt");
};

export const downloadMiterCsv = (
  earnings: AggregatedMiterEarning[],
  periodEnd: string,
  lookupTimeOffPolicy: LookupAtomFunction<TimeOffPolicy>
): void => {
  const fields = [
    "Employee Number",
    "Project Number",
    "Cost Code",
    "Workers' Comp Code",
    "Pay Type",
    "Date",
    "Hours",
  ];
  const data = earnings.map((e) => {
    return {
      "Employee Number": e.team_member.friendly_id,
      "Project Number": e.job?.code,
      "Cost Code": e.activity?.cost_code,
      "Pay Type": getTruebeckPayTypeFromEarning(e, lookupTimeOffPolicy),
      Date: e.date ? DateTime.fromISO(e.date).toFormat("MM/dd/yyyy") : null,
      Hours: e.hours || 0,
      "Workers' Comp Code": e.wc_code?.code,
    };
  });
  const json2csvParser = new Parser({ fields });
  const csv = json2csvParser.parse(data);
  const cleanCsv = csv.replaceAll(`"`, "");
  downloadCsvFromBlob(cleanCsv, "Miter hours export for week ending " + periodEnd);
};
