import styles from "../TeamMemberChecklistBuilder.module.css";

import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { EE_EEO_FIELDS, deparameterize } from "miter-utils";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import { EEOOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const EEOTaskContent: React.FC<ChecklistTaskContentProps<EEOOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  return (
    <div className={styles["team-member-checklist-task-content"]}>
      Fields include:&nbsp;
      {EE_EEO_FIELDS.map((field) => deparameterize(field).toLocaleLowerCase()).join(", ")}
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
