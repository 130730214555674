import { OptionCardConfig, OptionCards } from "dashboard/components/option-cards/OptionCards";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useActiveCompanyId, useRefetchWcGroups, useWcCodeOptions } from "dashboard/hooks/atom-hooks";
import {
  MiterAPI,
  WorkersCompGroup,
  CreateWorkersCompGroupParams,
  UpdateWorkersCompGroupParams,
  MiterError,
} from "dashboard/miter";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock, Label, Notifier } from "ui";
import * as vals from "dashboard/utils/validators";
import { WorkersCompGroupType } from "backend/models/wc-group";
import { Option } from "packages/ui/form/Input";

type Props = {
  wcGroup?: WorkersCompGroup;
  onHide: () => void;
  onSubmit: (wcGroup: WorkersCompGroup) => void;
};

type WcGroupForm = {
  name: string;
  type: WorkersCompGroupType;
  default_wc_code_id?: Option<string>;
};

export const WcGroupModal: React.FC<Props> = ({ wcGroup, onHide, onSubmit }) => {
  const activeCompanyId = useActiveCompanyId();
  const { cannot } = useMiterAbilities();
  const refetchWcGroups = useRefetchWcGroups();
  const wcCodeOptions = useWcCodeOptions();

  const form = useForm<WcGroupForm>({
    defaultValues: { name: wcGroup?.name || "", type: wcGroup?.type },
    shouldUnregister: false,
  });

  const [saving, setSaving] = useState(false);

  const handleSave = async (data: WcGroupForm) => {
    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to create workers' comp groups.");
      return;
    }

    if (!activeCompanyId) {
      Notifier.error("No active company found.");
      return;
    }

    setSaving(true);
    try {
      const params: CreateWorkersCompGroupParams | UpdateWorkersCompGroupParams = {
        ...data,
        company_id: activeCompanyId,
        default_wc_code_id: data.default_wc_code_id?.value || null,
      };

      let response: WorkersCompGroup & MiterError;
      if (wcGroup) {
        response = await MiterAPI.wc_groups.update(wcGroup._id, params);
      } else {
        params.mappings = [];
        params.wc_code_lookup = {};

        response = await MiterAPI.wc_groups.create(params as CreateWorkersCompGroupParams);
      }

      if (response.error) throw new Error(response.error);

      await refetchWcGroups();
      Notifier.success(`Workers' comp group ${wcGroup ? "updated" : "created"}`);
      onSubmit(response);
    } catch (e) {
      Notifier.error("There was an error creating the workers' comp group. We're looking into it.");
    }
    setSaving(false);
  };

  return (
    <ActionModal
      headerText={"Add workers' comp group"}
      showSubmit={true}
      showCancel={true}
      onCancel={onHide}
      submitText={"Save"}
      onHide={onHide}
      onSubmit={form.handleSubmit((data) => handleSave(data))}
      loading={saving}
    >
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <Formblock
          label="Name*"
          labelInfo="The name of the workers comp group "
          className="modal"
          type="text"
          name="name"
          editing={true}
          form={form}
          register={form.register(vals.required)}
        />
        <Formblock
          label="Default workers comp code"
          labelInfo="The default workers comp code for this group. This code will be used when no other mapping is found."
          className="modal"
          type="select"
          name="default_wc_code_id"
          options={wcCodeOptions}
          requiredSelect={true}
          editing={true}
          form={form}
          register={form.register(vals.required)}
          defaultValue={wcGroup?.default_wc_code_id}
        />
        <Label
          label="Configuration"
          sublabel="How would you like to configure this workers comp group?"
          className="modal"
        />
        <OptionCards
          options={OPTIONS}
          selectedValue={form.watch()["type"]}
          setSelectedValue={(value) => form.setValue("type", value)}
          style={{ fontSize: 14 }}
        />
      </div>
    </ActionModal>
  );
};

const OPTIONS: OptionCardConfig<WorkersCompGroupType>[] = [
  {
    header: "State",
    subheader: "Workers comp codes vary by state",
    value: "state",
  },
  {
    header: "Pay rate",
    subheader: "Workers comp codes vary by pay rate",
    value: "pay_rate",
  },
  {
    header: "State and pay rate",
    subheader: "Workers comp codes vary by state and pay rate",
    value: "state_and_pay_rate",
  },
  {
    header: "Job and cost codes",
    subheader: "Workers comp codes vary by job and cost code",
    value: "job_and_cost_code",
  },
];
