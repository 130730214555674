import React, { FC } from "react";
import { AggregatedJobPosting } from "dashboard/types/ats";
import DataBox from "dashboard/components/dataBox/DataBox";
import { DataBoxRow } from "dashboard/components/dataBox/DataBoxRow";
import { useLookupTeam } from "dashboard/hooks/atom-hooks";
import { useTeamMemberGroupLabeler } from "dashboard/utils/approvals";

type Props = {
  jobPosting: AggregatedJobPosting;
};

export const JobPostingDetails: FC<Props> = ({ jobPosting }) => {
  const lookupTeam = useLookupTeam();
  const { groupLabeler } = useTeamMemberGroupLabeler();
  return (
    <div>
      <DataBox title="Details" className="flex-col no-margin">
        <DataBoxRow
          label="Requisition ID"
          value={jobPosting.requisition_id || "No requisition ID"}
        ></DataBoxRow>
        <DataBoxRow
          label="Hiring manager"
          value={lookupTeam(jobPosting.hiring_manager_id)?.full_name || "No hiring manager"}
        ></DataBoxRow>
        <DataBoxRow
          label="Hiring team"
          value={groupLabeler(jobPosting.hiring_team || []) || "No hiring team"}
        ></DataBoxRow>
        <DataBoxRow
          label="Is generic interest form"
          value={!!jobPosting.interest_form ? "Yes" : "No"}
        ></DataBoxRow>
      </DataBox>
    </div>
  );
};
