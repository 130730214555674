import { useLookupFillableTemplates, useRefetchFillableTemplates } from "dashboard/hooks/atom-hooks";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, Loader, Notifier, Toggler } from "ui";
import { FillableDocumentsTable } from "./FillableDocumentsTable";
import { FillableTemplateDetails } from "./FillableTemplateDetails";
import { FillableTemplateWizard } from "./wizard/FillableTemplateWizard";

type Props = {};

export const FillableTemplate: FC<Props> = ({}) => {
  const { id, view } = useParams<{ id: string; view: string }>();
  const lookupFillableTemplate = useLookupFillableTemplates();
  const fillableTemplate = lookupFillableTemplate(id);
  const refetechFillableTemplate = useRefetchFillableTemplates();
  const navigate = useNavigate();
  const [editTemplate, setEditTemplate] = useState(false);

  const renderBreadcrumbs = () => {
    if (!fillableTemplate) return;
    return (
      <Breadcrumbs
        crumbs={[
          { label: "Fillable templates", path: "/documents?view=fillable-templates" },
          { label: fillableTemplate.name, path: `/fillable-templates/${id}/requests` },
        ]}
      />
    );
  };
  const { name } = fillableTemplate || {};

  const togglerConfig = [
    { path: "requests", label: "Requests" },
    { path: "details", label: "Details" },
  ];

  const toggle = (page) => {
    navigate(`/fillable-templates/${id}/${page}`, {
      replace: true,
    });
  };

  const renderToggler = () => <Toggler config={togglerConfig} active={view || "requests"} toggle={toggle} />;
  const renderView = () => {
    if (!fillableTemplate) return;
    switch (view) {
      case "details":
        return (
          <div style={{ marginTop: 20 }}>
            <FillableTemplateDetails fillableTemplate={fillableTemplate} />
          </div>
        );
      default:
        return <FillableDocumentsTable fillableTemplate={fillableTemplate} />;
    }
  };

  const openTemplateEditor = () => {
    setEditTemplate(true);
  };

  const closeTemplateEditor = () => {
    refetechFillableTemplate(id);
    setEditTemplate(false);
  };

  const onEditComplete = () => {
    closeTemplateEditor();
    Notifier.success("Template successfully saved.");
  };

  return fillableTemplate ? (
    <div className="page-content">
      <Helmet>
        <title>{name} | Miter</title>
      </Helmet>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
        <div className="page-content-header flex">
          <div>
            {renderBreadcrumbs()}
            <h1>{name}</h1>
          </div>
        </div>
        <Button className="button-2" onClick={openTemplateEditor}>
          Edit
        </Button>
      </div>
      {renderToggler()}
      {renderView()}
      {editTemplate && (
        <FillableTemplateWizard
          fillableTemplate={fillableTemplate}
          onExit={closeTemplateEditor}
          onComplete={onEditComplete}
        />
      )}
    </div>
  ) : (
    <Loader />
  );
};
