import React, { useMemo, useState } from "react";
import { Badge, Button } from "ui";
import { MiterAPI, PaySchedule } from "dashboard/miter";
import { PayScheduleModal } from "./PayScheduleModal";
import { Notifier } from "dashboard/utils";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridTable } from "dashboard/components/agGridTable/AgGridTable";
import { verticalCenteredCellStyle } from "dashboard/components/agGridTable/agGridUtils";
import { useActiveCompanyId, useActiveTeam, usePaySchedulesAtom } from "dashboard/hooks/atom-hooks";
import { payFrequencyLookup } from "dashboard/utils/paySchedules";
import { useEnhancedSearchParams } from "miter-utils";

export type PayScheduleTableEntry = PaySchedule & { payFrequencyString: string; tmCount: number };

export const PaySchedules: React.FC = () => {
  const [paySchedules, setPaySchedules] = usePaySchedulesAtom();
  const activeCompanyId = useActiveCompanyId();
  const activeTms = useActiveTeam();

  const { searchParams, setSearchParams } = useEnhancedSearchParams();
  const payScheduleModalId = searchParams.get("psid");

  const setPsId = (s: string | undefined) => {
    setSearchParams({ psid: s });
  };

  const [creatingNew, setCreatingNew] = useState(false);

  const renderRowActions = (params: ICellRendererParams<PayScheduleTableEntry>): React.ReactElement => {
    if (params.node.level !== 0) return <></>;

    return (
      <div className="flex">
        <Button className="button-1" text="Manage" onClick={() => setPsId(params.data?._id)} />
      </div>
    );
  };

  const tableData: PayScheduleTableEntry[] = useMemo(() => {
    return paySchedules
      .filter((pg) => !pg.archived)
      .map((pg) => {
        return {
          ...pg,
          payFrequencyString: payFrequencyLookup[pg.check_pay_schedule.pay_frequency],
          tmCount: activeTms.reduce((c, tm) => c + (tm.pay_schedule_id === pg._id ? 1 : 0), 0),
        };
      });
  }, [paySchedules, activeTms]);

  const createNewPaySchedule = async () => {
    if (!activeCompanyId) return;
    setCreatingNew(true);
    try {
      const response = await MiterAPI.pay_schedules.create({
        company_id: activeCompanyId,
        label: "New pay schedule",
      });
      if (response.error) throw new Error(response.error);
      setPaySchedules((prev) => prev.concat(response));
      setPsId(response._id);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the pay schedule. We're looking into it!");
    }
    setCreatingNew(false);
  };

  const columns: ColDef<PayScheduleTableEntry>[] = [
    {
      field: "label",
      headerName: "Name",
      minWidth: 250,
      sortable: false,
    },
    { field: "payFrequencyString", headerName: "Pay frequency", minWidth: 150, sortable: false },
    { field: "tmCount", headerName: "# team members", minWidth: 150, sortable: false },
    {
      field: "default",
      headerName: "Default",
      minWidth: 100,
      sortable: false,
      cellRenderer: (params: ICellRendererParams<PayScheduleTableEntry>) => {
        return params.data?.default ? <Badge className="no-margin" text="✓" color="green" /> : null;
      },
      cellStyle: { overflow: "visible", ...verticalCenteredCellStyle },
    },
    {
      field: "inactive",
      headerName: "Active",
      minWidth: 100,
      sortable: false,
      cellRenderer: (params: ICellRendererParams<PayScheduleTableEntry>) => {
        return !params.data?.inactive ? <Badge className="no-margin" text="✓" color="green" /> : null;
      },
      cellStyle: { overflow: "visible", ...verticalCenteredCellStyle },
    },
    {
      headerName: " ",
      width: 100,
      cellRenderer: renderRowActions,
      cellStyle: { overflow: "visible", ...verticalCenteredCellStyle },
    },
  ];

  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Pay schedules</div>
        <div className="flex-1"></div>

        <Button loading={creatingNew} className="button-2" text="+ New" onClick={createNewPaySchedule} />
      </div>
      <div className="vertical-spacer-small"> </div>
      <div>
        {tableData && (
          <>
            <AgGridTable
              columnDefs={columns}
              data={tableData}
              gridHeight={200}
              hideSidebar={true}
              containerStyle={{ marginBottom: 10 }}
              hideDownloadCSV={true}
            />
          </>
        )}
        {payScheduleModalId && (
          <PayScheduleModal payScheduleId={payScheduleModalId} hide={() => setPsId(undefined)} />
        )}
      </div>
    </div>
  );
};
