import React, { FC, ReactElement } from "react";

type FormContentBoxProps = {
  title: string;
  actions?: ReactElement;
  children: ReactElement;
};

const FormContentBox: FC<FormContentBoxProps> = ({ title, actions, children }) => {
  return (
    <div className={"form-content-box"}>
      <div className={"form-content-box-header"}>
        <h2>{title}</h2>
        {actions && <div className={"form-content-box-actions"}>{actions}</div>}
      </div>
      <div className={"form-content-box-content"}>{children}</div>
    </div>
  );
};

export default FormContentBox;
