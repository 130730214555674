import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { AssigneeForm } from "./TeamMemberChecklistAssigneeForm";
import { CustomOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const CustomTaskContent: React.FC<ChecklistTaskContentProps<CustomOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTask({ ...task, data: { ...task.data, description: e.target.value } });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTask({
      ...task,
      title: e.target.value,
      data: { ...task.data, title: e.target.value },
    });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <Formblock
        type="text"
        name="title"
        label="Title"
        placeholder="Enter task title"
        editing={true}
        defaultValue={task.data?.title}
        className="modal"
        onChange={handleTitleChange}
        /** If this is an imported custom task, disable editing */
        disabled={!!task.data.custom_task_id}
        labelInfo={
          task.data.custom_task_id ? "Editing disabled for imported default custom tasks." : undefined
        }
      />
      <Formblock
        type="paragraph"
        name="description"
        label="Description"
        placeholder="Enter description"
        editing={true}
        defaultValue={task.data?.description}
        className="modal"
        onChange={handleDescriptionChange}
        style={{ marginBottom: 0 }}
        /** If this is an imported custom task, disable editing */
        disabled={!!task.data.custom_task_id}
        labelInfo={
          task.data.custom_task_id ? "Editing disabled for imported default custom tasks." : undefined
        }
      />
      <DueDateForm task={task} setTask={setTask} />
      {task.data.assignee_type === "admin" && <AssigneeForm task={task} setTask={setTask} />}
    </div>
  );
};
