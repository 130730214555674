import React, { useState } from "react";
import { Formblock } from "ui";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { FieldValues, UseFormMethods } from "react-hook-form";
import {
  AcumaticaConnectionMetadata,
  ProjectTaskRestrictionBasis,
} from "backend/services/acumatica/acumatica-types";
import { Option } from "ui/form/Input";
import { ACUMATICA_INPUT_LENGTH, DEFAULT_ACUMATICA_LABEL_STYLE } from "./AcumaticaConfig";

type Props = {
  integrationConnnection: IntegrationConnection | undefined;
  updateAcumaticaMetadata: (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    options?: { collapseCount?: number }
  ) => Promise<void>;
  form: UseFormMethods<FieldValues>;
};

export const syncProjectTasksAsOptions = [
  { value: "sub_jobs", label: "Sub-jobs" },
  { value: "activities", label: "Activities" },
  { value: "do_not_import", label: "Do not import" },
];

export const projectTaskRestrictionFieldOptions: Option<ProjectTaskRestrictionBasis>[] = [
  { label: "Visibility Settings", value: "visibility_settings" },
  { label: "Attributes", value: "attributes" },
];

export const AcumaticaWbsSettings: React.FC<Props> = ({
  integrationConnnection,
  updateAcumaticaMetadata,
  form,
}) => {
  const configObject = integrationConnnection?.metadata?.acumatica?.configObject;
  const { syncProjectTasksAsSubJobs } = configObject?.jobs || {};
  const { useApproversOnProjectTasks, restrictionFieldForProjectTasks } = configObject?.activities || {};

  const [selectedSyncProjectTasksAs, setSelectedSyncProjectTasksAs] = useState(
    syncProjectTasksAsSubJobs
      ? "sub_jobs"
      : configObject?.activities?.costCodeField === "project_task"
      ? "activities"
      : "do_not_import"
  );
  const [importCostCodesAsActivities, setImportCostCodesAsActivities] = useState(
    configObject?.activities?.costCodeField === "cost_code"
  );

  const handleSyncProjectTasksAsSubJobsChange = async (
    value: "sub_jobs" | "activities" | "do_not_import"
  ) => {
    setSelectedSyncProjectTasksAs(value);
    const configObject = {};
    if (value === "sub_jobs") {
      configObject["jobs"] = { syncProjectTasksAsSubJobs: true };
      setImportCostCodesAsActivities(true);
      configObject["activities"] = { costCodeField: "cost_code" };
    } else if (value === "activities") {
      configObject["jobs"] = { syncProjectTasksAsSubJobs: false };
      setImportCostCodesAsActivities(false);
      configObject["activities"] = { costCodeField: "project_task" };
    } else if (value === "do_not_import") {
      configObject["jobs"] = { syncProjectTasksAsSubJobs: false };
      setImportCostCodesAsActivities(true);
      configObject["activities"] = { costCodeField: "cost_code" };
    }

    await updateAcumaticaMetadata({ configObject }, { collapseCount: 2 });
  };

  const handleImportCostCodesChange = async (e) => {
    setImportCostCodesAsActivities(e.target.checked);
    const update: DeepPartial<AcumaticaConnectionMetadata> = {
      configObject: { activities: { costCodeField: e.target.checked ? "cost_code" : null } },
    };

    await updateAcumaticaMetadata(update, { collapseCount: 2 });
  };

  return (
    <>
      <Formblock
        form={form}
        underlineTooltip={true}
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        options={syncProjectTasksAsOptions}
        name="syncProjectTasksAsSubJobs"
        type="select"
        value={syncProjectTasksAsOptions.find((o) => o.value === selectedSyncProjectTasksAs)}
        labelInfo="How Project Tasks will be represented in Miter."
        label="Import Project Tasks as"
        onChange={(o) => handleSyncProjectTasksAsSubJobsChange(o.value)}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        editing={true}
        style={{ alignItems: "center" }}
      />
      <Formblock
        form={form}
        options={projectTaskRestrictionFieldOptions}
        name="restrictionFieldForProjectTasks"
        type="select"
        defaultValue={restrictionFieldForProjectTasks}
        onChange={(option: Option<ProjectTaskRestrictionBasis> | null) => {
          updateAcumaticaMetadata(
            {
              configObject: { activities: { restrictionFieldForProjectTasks: option?.value || null } },
            },
            { collapseCount: 2 }
          );
        }}
        editing={true}
        labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
        label="Project Task Restriction"
        labelInfo="The field on the Project Task Miter will use to restrict non labor related Activities when pulling."
        underlineTooltip
        inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        isClearable
        style={{ alignItems: "center" }}
      />
      {selectedSyncProjectTasksAs === "sub_jobs" && (
        <>
          <Formblock
            form={form}
            underlineTooltip={true}
            inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
            name="useApproversOnProjectTasks"
            type="checkbox"
            labelInfo="Use Project Task approver as Supervisor on Miter Sub-Jobs."
            label="Import Project Task approver"
            defaultValue={!!useApproversOnProjectTasks}
            onChange={(e) =>
              updateAcumaticaMetadata(
                {
                  configObject: { activities: { useApproversOnProjectTasks: e.target.checked } },
                },
                { collapseCount: 2 }
              )
            }
            labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
            editing={true}
            style={{ alignItems: "center" }}
          />
        </>
      )}
      {selectedSyncProjectTasksAs !== "activities" && (
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="activityField"
          type="checkbox"
          label="Import Cost Codes as Activities"
          checked={importCostCodesAsActivities}
          onChange={handleImportCostCodesChange}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
          style={{ alignItems: "center" }}
        />
      )}
    </>
  );
};
