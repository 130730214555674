import { ApprenticeshipProgram, MiterAPI, AggregatedJob } from "dashboard/miter";
import { downloadPdfFromBase64String, shorten } from "dashboard/utils";
import Notifier from "dashboard/utils/notifier";
import React, { useState } from "react";
import { Button } from "ui";
import AnvilSignatureModal from "@anvilco/react-signature-modal";
import signIcon from "dashboard/assets/contract.png";
import sendIcon from "dashboard/assets/mail-send.png";

import styles from "./das140.module.css";
import QueryString from "qs";
import { SendMessageModal } from "dashboard/components/sendMessageModal/SendMessageModal";
import { useActiveCompanyId, useActiveCompany, useUser } from "dashboard/hooks/atom-hooks";

type ProgramOptionProps = {
  program: ApprenticeshipProgram;
  formData: $TSFixMe;
  selectedJob?: AggregatedJob;
};

const ProgramOption: React.FC<ProgramOptionProps> = ({ program, formData, selectedJob }) => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const activeUser = useUser();
  const companyName = activeCompany!.check_company.trade_name;
  const className = `program-option-wrapper`;

  const [signAndSaveLoading, setSignAndSaveLoading] = useState(false);
  const [signUrl, setSignUrl] = useState<string | undefined>();
  const [isSigning, setIsSigning] = useState(false);
  const [signedFileId, setSignedFileId] = useState<string | undefined>();
  const [messageSent, setMessageSent] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSign = async () => {
    setSignAndSaveLoading(true);
    try {
      const payload = {
        type: "das_140",
        params: {
          rawData: { ...formData, apprentice_program: program },
          redirect_path: "/reports/das-140",
          email: activeUser?.email,
        },
        format: "generate_sign_url",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error || !response.signUrl)
        throw new Error(
          response.error || "An error occured. Please contact support@miter.com if the problem persists."
        );
      setSignUrl(response.signUrl);
      setIsSigning(true);
    } catch (e) {
      // @ts-expect-error Error cleanup
      Notifier.error(e.message);
    }
    setSignAndSaveLoading(false);
  };

  const handleSignFinish = async (redirectUrl) => {
    setIsSigning(false);
    setSignAndSaveLoading(true);
    const arr = redirectUrl.split("?");
    const queryParamString = arr[arr.length - 1];
    const queryParams = QueryString.parse(queryParamString);
    try {
      const payload = {
        type: "das_140",
        params: {
          documentGroupEid: queryParams?.documentGroupEid,
          company: activeCompanyId!,
          company_id: activeCompanyId!,
          job: selectedJob,
          occupation: formData?.background?.occupation?.value,
        },
        format: "save_signed",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      setSignedFileId(response.file._id);
      setDownloaded(false);
      Notifier.success("Form signed and saved successfully.");
    } catch (e) {
      // @ts-expect-error error cleanup
      Notifier.error(e.message);
    }
    setSignAndSaveLoading(false);
  };

  const downloadUnsigned = async () => {
    setDownloading(true);
    try {
      const payload = {
        type: "das_140",
        params: {
          rawData: { ...formData, apprentice_program: program },
          redirect_path: "/reports/das-140",
          email: activeUser?.email,
        },
        format: "download_unsigned",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error || "There was an error downloading the form");
      downloadPdfFromBase64String(response.data, "Unsigned DAS 140 form.pdf");
      setDownloaded(true);
      Notifier.success("Unsigned form downloaded successfully.");
    } catch (e) {
      Notifier.error("There was an error downloading the form.");
    }
    setDownloading(false);
  };

  const downloadSigned = async () => {
    setDownloading(true);
    try {
      if (!signedFileId) throw new Error("Couldn't find the signed form.");
      const response = await MiterAPI.files.retrieve(signedFileId);
      if (response.error) throw new Error(response.error);
      Notifier.success("File successfully downloaded.");
      setDownloaded(true);
      window.open(response.url, "_blank");
    } catch (e) {
      Notifier.error("There was an error downloading the form");
    }
    setDownloading(false);
  };

  const handleSendClick = () => {
    if (!signedFileId) {
      Notifier.error("You need to sign the form before you can send it!");
    }
    setIsSending(true);
  };

  return (
    <div className={styles[className]}>
      {selectedJob ? (
        <>
          {isSending && signedFileId && (
            <SendMessageModal
              header="Send DAS 140 form"
              file_ids={[signedFileId]}
              onSend={() => setMessageSent(true)}
              job_id={selectedJob?._id}
              content_type="das_140"
              email_params={{
                to: program.contact_email || undefined,
                subject: `DAS 140 form for ${selectedJob?.name} from ${companyName}`,
                text: `Hi, please find attached a signed DAS 140 form for ${selectedJob.name} from ${companyName}.`,
              }}
              mail_params={{
                toAddress: { ...program.address, postal_name: program.committee },
              }}
              hide={() => setIsSending(false)}
            />
          )}
          <div className="flex">
            <AnvilSignatureModal
              signURL={signUrl}
              isOpen={isSigning}
              onClose={() => setIsSigning(false)}
              onFinish={(redirectURL) => handleSignFinish(redirectURL)}
            />
            <div className="flex">
              <div style={{ width: 425 }}>
                <div className="bold">{shorten(program.committee, 100)}</div>
                <div>
                  {program.address.line1 +
                    ", " +
                    program.address.city +
                    ", " +
                    "CA " +
                    program.address.postal_code}
                </div>
                {program.contact_email && program.contact_email !== "0" && <div>{program.contact_email}</div>}
                <div style={{ display: "flex", marginTop: 25 }}>
                  <Button
                    text={downloaded ? "Downloaded" : "Download"}
                    onClick={signedFileId ? downloadSigned : downloadUnsigned}
                    loading={downloading}
                    disabled={downloaded}
                    className="button-1 width-100 no-margin"
                  />
                  {!signedFileId && formData.sign.signer_name && formData.sign.signer_title && (
                    <div style={{ marginLeft: 15 }}>
                      <Button
                        text="Sign"
                        onClick={handleSign}
                        loading={signAndSaveLoading}
                        className="button-2 width-100 no-margin"
                      />
                    </div>
                  )}
                  {signedFileId && (
                    <div style={{ marginLeft: 15 }}>
                      <Button
                        text={messageSent ? "Sent" : "Send"}
                        onClick={handleSendClick}
                        loading={false}
                        disabled={messageSent}
                        className={"width-100 button-4 no-margin"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-1"></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: 15,
                width: 150,
              }}
            >
              <div className="flex">
                <img
                  src={signIcon}
                  style={{
                    height: 30,
                    marginRight: 10,
                    opacity: signedFileId ? 1 : 0.3,
                  }}
                />
                <img src={sendIcon} style={{ height: 45, opacity: messageSent ? 1 : 0.3 }} />
              </div>

              <div style={{ marginTop: 15, fontSize: 15, fontWeight: 600 }}>
                {signedFileId ? (messageSent ? "Signed and sent!" : "Signed but not sent") : "Not yet signed"}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

type Props = {
  programs: ApprenticeshipProgram[];
  county: string;
  occupation: string;
  formData: $TSFixMe;
  selectedJob?: AggregatedJob;
};

export const SignAndSend: React.FC<Props> = ({ programs, county, occupation, formData, selectedJob }) => {
  const matchingPrograms = programs.filter((p) => p.county === county && p.occupation === occupation);

  const goToDir = () => {
    window.open("https://www.dir.ca.gov/das/publicworks.html", "_blank");
  };

  return (
    <div>
      {selectedJob ? (
        <>
          <div className="bold" style={{ fontSize: 18, marginTop: 55, marginBottom: 10 }}>
            Sign and send your completed DAS 140 form
          </div>
          <div>
            {`Showing all apprenticeship programs for ${occupation} in ${county} county. All signed and sent forms will be saved automatically to ${selectedJob.name}'s `}
            <span
              onClick={() => window.open(process.env.REACT_APP_URL + "/jobs/" + selectedJob._id + "/files")}
              className="blue-link"
            >{`files folder`}</span>{" "}
            and{" "}
            <span
              className="blue-link"
              onClick={() => window.open(process.env.REACT_APP_URL + "/jobs/" + selectedJob._id + "/outbox")}
            >
              outbox
            </span>
            .<span>{` Please refer to the `}</span>
            <span className="blue-link" onClick={goToDir}>
              DIR website
            </span>
            <span>{` for the latest overview of contractors' responsibilities.`}</span>
          </div>
          <div className="vertical-spacer"></div>
          {matchingPrograms.map((p) => {
            return <ProgramOption key={p._id} program={p} formData={formData} selectedJob={selectedJob} />;
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
