import { BasicModal, Button } from "ui";
import React, { FC } from "react";
import { useFileDownload } from "dashboard/hooks/useFileDownload";
import { AggregatedFile } from "dashboard/miter";

type Props = {
  onHide: () => void;
  fileIds: string[];
};

export const FilesModal: FC<Props> = ({ onHide, fileIds }) => {
  const fileDownload = useFileDownload();
  return (
    <BasicModal headerText={`Download files`} button2Text={"Close"} button2Action={onHide}>
      {fileIds.length === 0 && <div>No files submitted </div>}
      <div style={{ display: "flex" }}>
        {fileIds.map((fileId, i) => {
          return (
            <Button
              onClick={() => {
                fileDownload({ file: { data: { _id: fileId } as AggregatedFile } });
              }}
            >
              File {i + 1}
            </Button>
          );
        })}
      </div>
    </BasicModal>
  );
};
