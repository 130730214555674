import AppContext from "dashboard/contexts/app-context";
import React, { useContext } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompanyId, useActiveCompany } from "dashboard/hooks/atom-hooks";
import { SettingsCard } from "ui";

export const SupplementalTaxMethod: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const { fetchUserData } = useContext(AppContext);

  const defaultValue = activeCompany?.settings.payroll.supplemental_tax_calc_method;
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = async (newValue) => {
    if (!activeCompanyId) return;
    setValue(newValue);
    try {
      const response = await MiterAPI.companies.update(activeCompanyId, {
        "settings.payroll.supplemental_tax_calc_method": newValue,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Payroll settings updated successfully.");
      fetchUserData();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
  };

  return (
    <SettingsCard
      title="Supplemental earnings tax calculation"
      info="Default method used to calculate taxes on supplemental earnings."
      contentStyle={{ color: "rgb(51,51,51)" }}
    >
      <div className="flex">
        <input
          type="radio"
          style={{ margin: "0px 12px" }}
          onChange={() => handleChange("flat")}
          checked={value === "flat"}
        />
        <label>
          <span className="bold">Flat</span>:
          <span>{` Supplemental earnings will be taxed at a flat 22%.`}</span>
        </label>
      </div>
      <div className="flex" style={{ marginTop: 7 }}>
        <input
          type="radio"
          style={{ margin: "0px 12px" }}
          onChange={() => handleChange("aggregate")}
          checked={value === "aggregate"}
        />
        <label>
          <span className="bold">Aggregate</span>:
          <span>{` All earnings are aggregated and taxed normally.`}</span>
        </label>
      </div>
    </SettingsCard>
  );
};
