import React from "react";
import { MiterIntegrationForCompany } from "dashboard/miter";
import { Button, Loader } from "ui";
import { SelectProcoreCompanyModal } from "./procore/SelectProcoreCompanyModal";
import { useNavigate } from "react-router-dom";

interface ProcoreAuthenticateIntegrationProps {
  integration: MiterIntegrationForCompany;
  goToSetupLink: () => void;
  setupLink: string | undefined;
}

export const ProcoreAuthenticateIntegration: React.FC<ProcoreAuthenticateIntegrationProps> = ({
  integration,
  goToSetupLink,
  setupLink,
}) => {
  const navigate = useNavigate();
  const integrationMissingUser = !integration.connection?.metadata?.procore?.procore_user_id;
  const integrationMissingCompany = !integration.connection?.metadata?.procore?.procore_company_id;

  if (!setupLink) {
    return <Loader />;
  }

  /**
   * If the integration connection doesn't have a user yet, they have not connected
   * to Procore and we need to give them the setup link
   */
  if (integrationMissingUser) {
    return (
      <>
        <div style={{ maxWidth: 600 }} className="yellow-text-container">
          This integration is not yet set up.
        </div>
        <div className="vertical-spacer"></div>
        <Button text="Connect to Procore" className="button-2 no-margin" onClick={goToSetupLink} />
      </>
    );
  }

  /**
   * If the integration connection has a user but doesn't have a company id,
   * then they need to select the company before moving forward
   */
  if (integrationMissingCompany) {
    return (
      <SelectProcoreCompanyModal
        onSuccess={() => window.location.reload()}
        onCancel={() => navigate("/integrations")}
        integration={integration}
      />
    );
  }

  /**
   * We should never hit this point, but in case we do, let's give a friendly message
   */
  return (
    <div style={{ maxWidth: 600 }} className="yellow-text-container">
      There is a problem with your connection. Please contact support
    </div>
  );
};
