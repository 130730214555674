import { capitalize } from "lodash";

export const EQUIPMENT_STATUS_LIST = [
  "idle",
  "in_use",
  "out_of_order",
  "removed",
  "left_on_site",
  "returned",
];

export const EQUIPMENT_STATUS_OPTIONS = EQUIPMENT_STATUS_LIST.map((option) => ({
  label: capitalize(option.replaceAll("_", " ")),
  value: option,
}));
