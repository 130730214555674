import { Tag } from "dashboard/miter";
import React from "react";
import { Badge } from "ui";

type Props = {
  tag: Tag;
};

export const TagBadge: React.FC<Props> = ({ tag }) => {
  return <Badge backgroundColor={tag.color} text={tag.label} style={{ marginLeft: 0 }} />;
};

type TagBadgesProps = {
  tags: Tag[] | undefined;
};

export const TagBadges: React.FC<TagBadgesProps> = ({ tags }) => {
  return <>{tags?.map((tag) => <TagBadge key={tag._id} tag={tag} />) || "-"}</>;
};
