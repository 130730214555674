import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Toggler } from "ui";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import WcCodesTable from "dashboard/components/tables/WcCodesTable";
import WcGroupsTable from "./WcGroupsTable";
import { useHasEnabledWcGroups } from "dashboard/hooks/atom-hooks";

export const WorkersComp: React.FC = () => {
  const hasEnabledWcGroups = useHasEnabledWcGroups();

  const { can, cannot } = useMiterAbilities();
  const [view, setView] = useState("codes");

  const togglerConfig = [
    { path: "codes", label: "Codes", hide: cannot("lists:workers_comp_codes:read") },
    { path: "groups", label: "Groups", hide: cannot("lists:workers_comp_codes:read") || !hasEnabledWcGroups },
  ];

  const toggle = (path: string) => {
    setView(path);
  };

  return (
    <div className="section-wrapper">
      <Helmet>
        <title>Workers comp | Miter</title>
      </Helmet>
      <Toggler secondary={true} config={togglerConfig} active={view} toggle={toggle} />
      {view === "codes" && can("lists:cost_types:read") && <WcCodesTable />}
      {view === "groups" && can("lists:cost_types:read") && <WcGroupsTable />}
    </div>
  );
};
