import ProgressBar from "dashboard/components/progress-bar/progressBar";
import { MiterAPI, AggregatedJob } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { BasicModal } from "ui";
import { reportList } from "../../reportList";
import { DateTime } from "luxon";
import { BackgroundInfo } from "./BackgroundInfo";
import { ApprenticeInfo } from "./ApprenticeInfo";
import { CompleteAndSend } from "./CompleteAndSend";
import { Helmet } from "react-helmet";
import { useActiveCompany, useActiveCompanyId, useJobs } from "dashboard/hooks/atom-hooks";

type AllFormData = {
  background?: $TSFixMe;
  apprentice?: $TSFixMe;
};

export const CreateDAS142: React.FC = () => {
  const activeCompany = useActiveCompany();
  const activeCompanyId = useActiveCompanyId();

  const backgroundForm = useForm({ shouldUnregister: false });
  const apprenticeForm = useForm({ shouldUnregister: false });

  const navigate = useNavigate();
  const jobs = useJobs();
  const noDirJobs = useMemo(() => jobs.every((j) => !j.cpr_info), [jobs]);

  const [allFormData, setAllFormData] = useState<AllFormData>({});
  const [selectedApprenticeProgram, setSelectedApprenticeProgram] = useState<$TSFixMe | undefined>();
  const [apprenticePrograms, setApprenticePrograms] = useState<$TSFixMe[]>([]);
  const [selectedJob, setSelectedJob] = useState<AggregatedJob | undefined>();
  const [activeStage, setActiveStage] = useState(0);
  const [reportDate, setReportDate] = useState(DateTime.now());
  const [creatingPdf, setCreatingPdf] = useState(false);
  const [fileId, setFileId] = useState<string | undefined>();

  const getApprenticeProgramOptions = async () => {
    try {
      const response = await MiterAPI.apprenticeship_programs.retrieve();
      // @ts-expect-error error cleanup
      if (response.error) throw new Error(response.error);
      setApprenticePrograms(response);
    } catch (e) {
      Notifier.error("There was an error retrieving apprentice program options.");
    }
  };

  const stages = ["Background information", "Apprentice information", "Complete and send"];

  const reportObject = reportList.find((report) => report.slug === "das_142");

  useEffect(() => {
    getApprenticeProgramOptions();
  }, []);

  const backgroundDone = (data) => {
    if (!data.pwc_project_number) {
      Notifier.error("Oops! There was an error. Please contact support@miter.com if the problem persists.");
      return;
    }

    if (!activeCompany) {
      return Notifier.error("Oops! There was an error. Please contact support");
    }

    setAllFormData({
      background: {
        phone: activeCompany.phone,
        pwc_registration_number: activeCompany.ca_info?.pwc_registration_number,
        license_number: activeCompany.ca_info?.license_number,
        ...data,
      },
    });
    setActiveStage(1);
  };

  const apprenticeDone = async (data) => {
    setCreatingPdf(true);
    if (!selectedApprenticeProgram) {
      apprenticeForm.setError("apprenticeship_committee", { message: "Apprentice program is required." });
      return;
    }
    try {
      const payload = {
        type: "das_142",
        params: {
          rawData: {
            ...allFormData,
            apprentice: { ...data, craft_or_trade: selectedApprenticeProgram.occupation },
            selected_committee: selectedApprenticeProgram,
            company: activeCompany!,
            company_id: activeCompanyId!,
            reportDateISO: reportDate.toSeconds(),
          },
        },
        format: "save",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error || "An error occured");
      Notifier.success("Form saved.");
      setFileId(response.file_id);
      setActiveStage(2);
      window.scrollTo(0, 0);
    } catch (e: $TSFixMe) {
      console.error("Error creating the apprentice section of DAS 142 form: ", e.message);
      Notifier.error("There was an error creating the report. We're looking into it.");
    }
    setCreatingPdf(false);
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>DAS 142 Report | Miter</title>
      </Helmet>
      {noDirJobs && (
        <BasicModal
          headerText="Please set up a CA DIR job!"
          button2Action={() => navigate("/jobs")}
          button2Text="Continue"
          button1Action={() => {}}
        >
          <div className="payroll-approval-text-wrapper">
            To create a DAS 142 form, you must first set up a CA DIR job. Please navigate to the{" "}
            <span onClick={() => navigate("/jobs")} className="blue-link">
              Jobs portal
            </span>
            <span> to create a CA DIR job.</span>
          </div>
        </BasicModal>
      )}
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>Create a DAS 142 form</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div style={{ maxWidth: 700 }}>
        <ProgressBar stages={stages} includeNumbers active={activeStage} />
        <div>
          {activeStage === 0 && (
            <BackgroundInfo
              onSubmit={backgroundDone}
              form={backgroundForm}
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
            />
          )}
          {activeStage === 1 && selectedJob && (
            <ApprenticeInfo
              onSubmit={apprenticeDone}
              loading={creatingPdf}
              setReportDate={setReportDate}
              reportDate={reportDate}
              setSelectedApprenticeProgram={setSelectedApprenticeProgram}
              selectedJob={selectedJob}
              form={apprenticeForm}
              programs={apprenticePrograms}
            />
          )}
          {activeStage === 2 && fileId && selectedJob && (
            <CompleteAndSend
              selectedJob={selectedJob}
              selectedApprenticeProgram={selectedApprenticeProgram}
              fileId={fileId}
            />
          )}
        </div>
        <div className="vertical-spacer-large"></div>
      </div>
    </div>
  );
};
