import React, { ReactNode, ErrorInfo } from "react";

import "./error.css";
import ErrorCard from "./errorCard";

type ErrorBoundaryProps = { children: ReactNode };
type ErrorBoundaryState = { hasError: boolean };

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log("This error is located at", errorInfo.componentStack);
    console.error("Uncaught", error);
  }

  override render(): ReactNode {
    if (this.state.hasError) {
      // Error path
      return <ErrorCard header={"Oh no!"} errorMessage={"Something went wrong."} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
