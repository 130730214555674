import React, { useMemo } from "react";
import { OnboardingTaskComponent } from "./ChecklistBuilderScreen";
import { useFormContext } from "react-hook-form";
import { Badge, Formblock } from "ui";
import { MAP_TASK_TO_DESCRIPTION, MAP_TASK_TO_MILESTONE } from "./constants";
import { Lock } from "phosphor-react";
import { styles } from "miter-components/forms";
import { useActiveTeam, useCompanyUserOptions } from "dashboard/hooks/atom-hooks";
import checklistStyles from "./Checklists.module.css";

type MiterChecklistComponentProps = {
  children?: React.ReactNode;
  component: OnboardingTaskComponent;
  index: number;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, component: OnboardingTaskComponent) => void;
  onFieldChange: (
    key: string,
    value: string | boolean | OnboardingTaskComponent["assignee"],
    index: number
  ) => void;
  rules?: {
    assignee: { admin_only: boolean; new_hire_only: boolean };
    description?: { required: boolean };
  };
  options?: {
    teamOnly: boolean;
  };
};

const MiterChecklistComponent: React.FC<MiterChecklistComponentProps> = ({
  children,
  component,
  index,
  onClick,
  onFieldChange,
  rules,
  options,
}) => {
  const key = `components.${component._id}`;
  const nameKey = `${key}.name`;
  const descriptionKey = `${key}.description`;
  const assigneeKey = `${key}.assignee.user_id`;
  const dueAfterKey = `${key}.due_after`;
  const { type } = component;
  const activeTeamMembers = useActiveTeam();

  const form = useFormContext();
  const { register, errors } = form;

  const userOptions = useCompanyUserOptions({
    includeCustomOption: { label: "New hire", value: "new_hire" },
  });

  const usersWithTeamMembersOptions = useMemo(() => {
    return userOptions.filter(
      (u) => activeTeamMembers.some((tm) => tm.user === u.value) || u.value === "new_hire"
    );
  }, [userOptions, activeTeamMembers]);

  const defaultDescription = MAP_TASK_TO_DESCRIPTION[type];

  const onAssigneeChange = (value: string, index: number) => {
    if (value === "new_hire") {
      onFieldChange("assignee", { user_id: "new_hire", type: "new_hire" }, index);
    } else {
      onFieldChange("assignee", { user_id: value, type: "admin" }, index);
    }
  };

  const assigneeOptions = options?.teamOnly ? usersWithTeamMembersOptions : userOptions;

  return (
    <div className={styles["miter-form-component"]} onClick={(e) => onClick(e, component)}>
      <div className={checklistStyles["component-header"]}>
        <div style={{ width: "80%" }} className={styles["miter-form-component-header"]}>
          <Formblock
            className={"modal " + styles["miter-form-component-label-input"]}
            placeholder={"Task name"}
            type="text"
            name={nameKey}
            register={register({ required: "Title is required" })}
            errors={errors}
            editing={true}
            onChange={(e) => onFieldChange("title", e.target.value, index)}
            defaultValue={component?.title}
          />
          <Formblock
            className={"modal " + styles["miter-form-component-sublabel-input"]}
            type="paragraph"
            name={descriptionKey}
            register={
              rules?.description?.required ? register({ required: "Description is required" }) : register()
            }
            errors={errors}
            editing={true}
            rows={1}
            disableResize={true}
            onChange={(e) => onFieldChange("description", e.target.value, index)}
            defaultValue={component?.description || defaultDescription}
          />
        </div>

        <MilestoneBage component={component} />
      </div>
      {children}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <div style={{ display: "flex" }}>
          <div style={{ minWidth: 250, marginRight: 30 }}>
            <Formblock
              type="select"
              className="modal"
              name={assigneeKey}
              label="Assignee"
              errors={errors}
              disabled={false}
              editing={true}
              options={assigneeOptions}
              requiredSelect={true}
              form={form}
              onChange={(e) => onAssigneeChange(e.value, index)}
              defaultValue={component?.assignee?.user_id || "new_hire"}
              rules={{
                validate: (v: { label: string; value: string }) => {
                  if (!rules) return true;
                  if (!v || !v.value) return "An assignee must be selected";
                  if (rules?.assignee.admin_only && v.value === "new_hire")
                    return "Assignee must be an admin.";
                  if (rules?.assignee.new_hire_only && v.value !== "new_hire")
                    return "Assignee must be a new hire.";
                  return true;
                },
              }}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Formblock
              type="number"
              className="modal"
              name={dueAfterKey}
              label="Due"
              errors={errors}
              disabled={false}
              onChange={(e) => onFieldChange("due_after", e.target.value, index)}
              editing={true}
              form={form}
              defaultValue={component?.due_after}
            />
            <div style={{ marginLeft: 10, marginTop: 10 }}>days after assignment</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MilestoneBage: React.FC<{ component: OnboardingTaskComponent }> = ({ component }) => {
  const milestone = MAP_TASK_TO_MILESTONE[component.type];
  if (!milestone || !component.locked) return null;
  return (
    <Badge
      element={
        <div style={{ display: "flex" }}>
          <Lock size={16} style={{ marginRight: 5 }} />
          <div style={{ fontWeight: 600, fontSize: 14 }}>{milestone.replace("_", " ").toUpperCase()}</div>
        </div>
      }
      color="orange"
      style={{ padding: 6 }}
    />
  );
};

export default MiterChecklistComponent;
