import AppContext from "dashboard/contexts/app-context";
import React, { useContext, useState } from "react";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { useActiveCompanyId, useActiveCompany } from "dashboard/hooks/atom-hooks";
import { ConfirmModal, SettingsCard } from "ui";

export const ContractorOvertime: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const { fetchUserData } = useContext(AppContext);

  const defaultValue = activeCompany?.settings.payroll.ot_applies_to_1099s;
  const [value, setValue] = useState(defaultValue);
  const [showModal, setShowModal] = useState(false);

  const handleToggle = async () => {
    if (!activeCompanyId) return;
    const newValue = !value;
    try {
      const response = await MiterAPI.companies.update(activeCompanyId, {
        "settings.payroll.ot_applies_to_1099s": newValue,
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Payroll settings updated successfully.");
      fetchUserData();
      setValue(newValue);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating your settings.");
    }
    setShowModal(false);
  };

  const renderWarningText = () => {
    return (
      <span>
        <span className="bold">Warning</span>:&nbsp;
        <span>
          Team members who are eligible for overtime should usually be classified as W2 employees, not 1099
          contractors.
          <br></br>
          <br></br> Are you sure you want to enable overtime for 1099 contractors? Miter recommends consulting
          with a labor attorney before taking this action.
        </span>
      </span>
    );
  };

  return (
    <>
      <SettingsCard title="Contractor overtime" contentStyle={{ color: "rgb(51,51,51)" }}>
        {showModal && (
          <ConfirmModal
            onYes={() => handleToggle()}
            yellowBodyText
            onNo={() => setShowModal(false)}
            title="Are you sure?"
            body={renderWarningText()}
          />
        )}
        <div className="flex">
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => handleToggle()}
            checked={!value}
          />
          <label>
            <span className="bold">Disabled</span>:
            <span>{` Miter should treat all 1099 contractors as overtime exempt.`}</span>
          </label>
        </div>
        <div className="flex" style={{ marginTop: 7 }}>
          <input
            type="radio"
            style={{ margin: "0px 12px" }}
            onChange={() => setShowModal(true)}
            checked={value}
          />
          <label>
            <span className="bold">Enabled</span>:
            <span>{` Miter should apply overtime to 1099 contractors that trigger overtime thresholds.`}</span>
          </label>
        </div>
      </SettingsCard>
    </>
  );
};
