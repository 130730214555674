import { useCallback, useState } from "react";
import { PreppedBulkEntryTimesheet } from "./pages/timesheets/BulkCreateTimesheets/BulkCreateTimesheets";
import { PayRateItem } from "backend/utils/payroll/types";
import { MiterAPI } from "./miter";
import { Notifier } from "ui";

type PreppedTimesheetPayRateMap = Record<string, PayRateItem>;

type UsePreppedTimesheetPayRates = {
  buildPayRatesMap: (preppedTimesheets: PreppedBulkEntryTimesheet[]) => Promise<PreppedTimesheetPayRateMap>;
  loadingPayRates: boolean;
};

/**
 *  Fetches pay rates for a list of prepped timesheets
 *  - Used primarily for bulk timesheet creation
 */
export const usePreppedTimesheetPayRates = (): UsePreppedTimesheetPayRates => {
  const [loadingPayRates, setLoadingPayRates] = useState(false);

  const buildPayRatesMap = useCallback(
    async (preppedTimesheets: PreppedBulkEntryTimesheet[]): Promise<PreppedTimesheetPayRateMap> => {
      if (!preppedTimesheets.length) return {};
      setLoadingPayRates(true);

      let payRateMap: PreppedTimesheetPayRateMap;
      try {
        const res = await MiterAPI.timesheets.build_draft_pay_rates(preppedTimesheets);
        if (res.error) throw new Error(res.error);

        payRateMap = res;
      } catch (e) {
        console.log("Error fetching pay rates", e);
        Notifier.error("Unable to fetch pay rates");
        payRateMap = {};
      }

      setLoadingPayRates(false);
      return payRateMap;
    },
    []
  );

  return { buildPayRatesMap, loadingPayRates };
};
