import { ClickAwayListener } from "@material-ui/core";
import DataBox from "dashboard/components/dataBox/DataBox";
import { addressString } from "dashboard/utils";
import { getLatLngFromAddressString } from "dashboard/utils/geolocation";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Formblock, ModalHeader, usdString } from "ui";
import { AggregatedJob, Job } from "../../miter";
import Notifier from "../../utils/notifier";
import * as vals from "../../utils/validators";
import { useJobAbilities } from "dashboard/hooks/abilities-hooks/useJobAbilities";

type Props = {
  updateJob: (update: Partial<Job>) => Promise<void>;
  data: AggregatedJob;
  updatingJob: boolean;
};

export const JobCprInfo: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [editingCPRInfo, setEditingCPRInfo] = useState(false);

  const jobAbilities = useJobAbilities();

  return (
    <DataBox
      title="Certified Payroll"
      className={undefined}
      onEdit={jobAbilities.can("update", data) ? () => setEditingCPRInfo(true) : undefined}
      editText={undefined}
      rows={undefined}
    >
      <>
        {editingCPRInfo ? <JobCPRInfoModal {...props} hide={() => setEditingCPRInfo(false)} /> : null}
        {data.is_davis_bacon && (
          <div className={"data-box-section"}>
            <span className={"data-box-section-title font-14"}>Davis-Bacon Job</span>
            <span className={"data-box-section-value font-14"}>{data.is_davis_bacon ? "Yes" : "No"}</span>
          </div>
        )}
        {data.cpr_info ? (
          <>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Project number</span>
              <span className={"data-box-section-value font-14"}>{data.cpr_info?.project_number}</span>
            </div>
            {data.cpr_info?.contract_execution_date && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Contract execution date</span>
                <span className={"data-box-section-value font-14"}>
                  {data.cpr_info.contract_execution_date}
                </span>
              </div>
            )}
            {data.cpr_info?.contract_number && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Contract number</span>
                <span className={"data-box-section-value font-14"}>{data.cpr_info?.contract_number}</span>
              </div>
            )}
            {data.cpr_info?.awarding_body?.name && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Awarding agency</span>
                <span className={"data-box-section-value font-14"}>{data.cpr_info.awarding_body.name}</span>
              </div>
            )}
            {data.cpr_info?.awarding_body?.address && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Awarding agency address</span>
                <span className={"data-box-section-value font-14"}>
                  {addressString(data.cpr_info.awarding_body.address)}
                </span>
              </div>
            )}
            {data.cpr_info?.awarding_body?.phone && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Awarding agency phone</span>
                <span className={"data-box-section-value font-14"}>
                  {data.cpr_info.awarding_body.phone || "-"}
                </span>
              </div>
            )}
            {data.cpr_info?.awarding_body?.contact?.first_name && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Awarding agency contact first name</span>
                <span className={"data-box-section-value font-14"}>
                  {data.cpr_info.awarding_body.contact.first_name}
                </span>
              </div>
            )}
            {data.cpr_info?.awarding_body?.contact?.last_name && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Awarding agency contact last name</span>
                <span className={"data-box-section-value font-14"}>
                  {data.cpr_info.awarding_body.contact.last_name}
                </span>
              </div>
            )}
            {data.cpr_info?.contract_amount && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Contract amount</span>
                <span className={"data-box-section-value font-14"}>
                  {usdString(data.cpr_info.contract_amount)}
                </span>
              </div>
            )}
            {data.cpr_info?.subcontract_amount && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Subcontract amount</span>
                <span className={"data-box-section-value font-14"}>
                  {usdString(data.cpr_info.subcontract_amount)}
                </span>
              </div>
            )}
            {data.cpr_info?.payroll_start_date && (
              <div className={"data-box-section"}>
                <span className={"data-box-section-title font-14"}>Payroll start date</span>
                <span className={"data-box-section-value font-14"}>{data.cpr_info.payroll_start_date}</span>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={"data-box-section"}>
              <span className={"data-box-section-title font-14"}>Certified Payroll enabled</span>
              <span className={"data-box-section-value font-14"}>False</span>
            </div>
          </>
        )}
      </>
    </DataBox>
  );
};

export const JobCPRInfoModal: React.FC<Props & { hide: () => void }> = (props) => {
  const { data: jobData, updateJob } = props;
  // Hooks
  const form = useForm();
  const { register, errors, control, handleSubmit } = form;

  // State
  const [saving, setSaving] = useState(false);
  const [enableCPR, setEnableCPR] = useState<boolean>(jobData.cpr_info ? true : false);
  const [contractExecutionDate, setContractExecutionDate] = useState(
    jobData.cpr_info?.contract_execution_date
      ? DateTime.fromISO(jobData.cpr_info.contract_execution_date)
      : undefined
  );
  const [payrollStartDate, setPayrollStartDate] = useState(
    jobData.cpr_info?.payroll_start_date ? DateTime.fromISO(jobData.cpr_info.payroll_start_date) : undefined
  );

  const submit = async (data) => {
    setSaving(true);
    try {
      let geolocation;
      const cleanAddress = data.address
        ? {
            ...data.address,
            line2: data.address.line2 || undefined,
            state: data.address.state?.value,
          }
        : undefined;
      if (data.address) {
        const stringForAddress = addressString(cleanAddress);
        geolocation = await getLatLngFromAddressString(stringForAddress);
      }
      const body: Partial<Job> = {
        cpr_info: enableCPR
          ? {
              project_number: data.project_number,
              contract_number: data.contract_number,
              awarding_body: {
                name: data.awarding_agency_name,
                address: data.awarding_agency_address?.line1
                  ? {
                      ...data.awarding_agency_address,
                      line2:
                        data.awarding_agency_address.line2 === "" ? null : data.awarding_agency_address.line2,
                      state: data.awarding_agency_address?.state?.value,
                    }
                  : undefined,
                phone: data.awarding_agency_phone,
                contact: {
                  first_name: data.awarding_agency_contact_first_name,
                  last_name: data.awarding_agency_contact_last_name,
                },
              },
              contract_execution_date: contractExecutionDate?.toISODate() || null,
              payroll_start_date: payrollStartDate?.toISODate() || null,
              contract_amount: data.contract_amount,
              subcontract_amount: data.subcontract_amount,
            }
          : null,
        address: cleanAddress,
        geolocation,
        is_davis_bacon: data.is_davis_bacon,
      };
      await updateJob(body);
      Notifier.success("Job updated successfully.");
      props.hide();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error updating the job. We're looking into it.");
    }
    setSaving(false);
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form">
          <ModalHeader heading={"Certified Payroll setup"} onHide={props.hide} />
          <div className="modal-body form" style={{ minHeight: 50 }}>
            <div className="vertical-spacer"></div>
            <Formblock
              text="Is this a Davis-Bacon job?"
              type="checkbox"
              className="modal"
              name="is_davis_bacon"
              form={form}
              editing={true}
              defaultValue={jobData?.is_davis_bacon}
              errors={errors}
            />
            <Formblock
              text={`Enable Certified Payroll reporting for ${jobData.name}.`}
              type="checkbox"
              className="modal"
              editing={true}
              defaultValue={enableCPR}
              errors={errors}
              onChange={(e) => setEnableCPR(e.target.checked)}
            />

            {enableCPR && (
              <div>
                <Formblock
                  label="Official project number*"
                  labelInfo="The identifying number of the project."
                  type="text"
                  className="modal"
                  defaultValue={jobData.cpr_info?.project_number}
                  name="project_number"
                  editing={true}
                  register={register(vals.required)}
                  errors={errors}
                />
                {!jobData.address && (
                  <Formblock
                    label="Job address*"
                    type="address"
                    className="modal"
                    name="address"
                    control={control}
                    editing={true}
                    required={true}
                    notRequiredRegister={register}
                    register={register(vals.required)}
                    errors={errors}
                  />
                )}
                <Formblock
                  label="Contract execution date (optional)"
                  labelInfo="The execution date of the contract."
                  type="datetime"
                  control={control}
                  dateOnly
                  className="modal"
                  name="contract_execution_date"
                  defaultValue={contractExecutionDate}
                  editing={true}
                  errors={errors}
                  onChange={setContractExecutionDate}
                />
                <Formblock
                  label="Contract number (optional)"
                  labelInfo="The identifying number of the contract."
                  type="text"
                  className="modal"
                  defaultValue={jobData.cpr_info?.contract_number}
                  name="contract_number"
                  editing={true}
                  register={register}
                  errors={errors}
                />
                <Formblock
                  label="Awarding agency (optional)"
                  labelInfo="The name of the public agency that awarded the contract."
                  type="text"
                  className="modal"
                  defaultValue={jobData.cpr_info?.awarding_body?.name}
                  name="awarding_agency_name"
                  editing={true}
                  form={form}
                />
                <Formblock
                  label="Awarding agency address (optional)"
                  labelInfo="The address of the public agency that awarded the contract."
                  type="address"
                  control={control}
                  className="modal"
                  defaultValue={jobData.cpr_info?.awarding_body?.address}
                  name="awarding_agency_address"
                  editing={true}
                  register={register}
                  notRequiredRegister={register}
                  errors={errors}
                />
                <Formblock
                  label="Awarding agency phone (optional)"
                  labelInfo="The phone number of the public agency that awarded the contract."
                  type="phone"
                  control={control}
                  className="modal"
                  defaultValue={jobData.cpr_info?.awarding_body?.phone}
                  name="awarding_agency_phone"
                  editing={true}
                  register={register}
                  errors={errors}
                />
                <Formblock
                  label="Awarding agency contact first name (optional)"
                  labelInfo="The first name of the contact for the public agency that awarded the contract."
                  type="text"
                  control={control}
                  className="modal"
                  defaultValue={jobData.cpr_info?.awarding_body?.contact?.first_name}
                  name="awarding_agency_contact_first_name"
                  editing={true}
                  register={register}
                  errors={errors}
                />
                <Formblock
                  label="Awarding agency contact last name (optional)"
                  labelInfo="The last name of the contact for the public agency that awarded the contract."
                  type="text"
                  control={control}
                  className="modal"
                  defaultValue={jobData.cpr_info?.awarding_body?.contact?.last_name}
                  name="awarding_agency_contact_last_name"
                  editing={true}
                  register={register}
                  errors={errors}
                />
                <Formblock
                  label="Contract amount (optional)"
                  labelInfo="Total $ amount of the contract."
                  type="unit"
                  unit="$"
                  className="modal"
                  defaultValue={jobData.cpr_info?.contract_amount}
                  name="contract_amount"
                  editing={true}
                  register={register}
                  errors={errors}
                />
                <Formblock
                  label="Subcontract amount (optional)"
                  labelInfo="Total $ amount of the subcontract."
                  type="unit"
                  unit="$"
                  className="modal"
                  defaultValue={jobData.cpr_info?.subcontract_amount}
                  name="subcontract_amount"
                  editing={true}
                  register={register}
                  errors={errors}
                />
                <Formblock
                  label="Payroll start date (optional)"
                  labelInfo="The first date of payroll for this job."
                  type="datetime"
                  control={control}
                  dateOnly
                  className="modal"
                  name="payroll_start_date"
                  defaultValue={payrollStartDate}
                  editing={true}
                  errors={errors}
                  onChange={setPayrollStartDate}
                  topOfInput
                />
              </div>
            )}
            <div className="vertical-spacer"></div>
          </div>

          <div className="modal-footer form">
            <button className={saving ? "button-1 inactive " : "button-1"} onClick={props.hide}>
              Cancel
            </button>
            <Button onClick={handleSubmit(submit)} className={"button-2"} text="Save" loading={saving} />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
