import { useMemo, useState } from "react";
import ActivitiesTable from "dashboard/components/tables/ActivitiesTable";
import { useAccessFilter } from "dashboard/hooks/access-filters/useAccessFilter";
import { useActivities } from "dashboard/hooks/atom-hooks";
import { AggregatedTeamMember } from "dashboard/miter";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { ActionModal } from "ui";
import { Pencil } from "phosphor-react";
import { TeamMemberActivityAssociations } from "./TeamMemberActivityAssociations";

type TeamMemberAssociationsModalProps = {
  entityName: string;
  teamMember: AggregatedTeamMember;
  setOpenModal: (open: boolean) => void;
};

export const TeamMemberAssociationsModal: React.FC<TeamMemberAssociationsModalProps> = ({
  entityName,
  teamMember,
  setOpenModal,
}) => {
  const [showBulkSelector, setShowBulkSelector] = useState<boolean>(false);
  const accessFilters = useAccessFilter({ entityName: entityName });

  const activities = useActivities();

  const associatedActivityIds = useMemo(() => {
    return accessFilters.entityIds({ teamMember });
  }, [accessFilters, teamMember]);

  const hiddenActivitiesSet = useMemo(() => {
    return new Set(activities.filter((activity) => !associatedActivityIds.has(activity._id)));

    // eslint wants to include teamMembers, but at the risk of being
    // passed an unmemoized teamMember, we check for team_member._id
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associatedActivityIds, activities, teamMember?._id]);

  const overrideStaticActions = useMemo(
    () => [
      {
        label: `Edit accessible ${pluralize(entityName, 2)}`,
        className: "button-2 no-margin table-button",
        action: () => setShowBulkSelector(true),
        important: true,
        icon: <Pencil weight="bold" style={{ marginRight: 5 }} />,
      },
    ],
    [entityName]
  );

  const renderTable = () => {
    switch (entityName) {
      case "activity": {
        return (
          <ActivitiesTable
            hideActions={true}
            hideBulkEdit={true}
            hiddenActivitiesSet={hiddenActivitiesSet}
            overrideStaticActions={overrideStaticActions}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderBulkSelectorModal = () => {
    switch (entityName) {
      case "activity": {
        return (
          <TeamMemberActivityAssociations
            teamMember={teamMember}
            associatedActivityIds={associatedActivityIds}
            showBulkSelector={showBulkSelector}
            setShowBulkSelector={setShowBulkSelector}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <ActionModal
      headerText={`${pluralize(capitalize(entityName), 2)} associated with team member`}
      showSubmit={false}
      showCancel={false}
      onCancel={() => setOpenModal(false)}
      onHide={() => setOpenModal(false)}
      wrapperStyle={{ minWidth: 1000 }}
      bodyStyle={{ padding: 30, paddingTop: 0, overflowY: "visible", maxHeight: 1000 }}
      hideFooter={true}
    >
      <div>
        {renderTable()}
        {renderBulkSelectorModal()}
      </div>
    </ActionModal>
  );
};
