import React from "react";
import { useWatch } from "react-hook-form";
import { Formblock, SettingsCard, SettingsPage } from "ui";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { useSettingsForm } from "dashboard/hooks/useSettingsForm";
import { Company } from "dashboard/miter";

type SettingsForm = NonNullable<Company["settings"]["locations"]> & {};

const SETTINGS_PATH = "settings.locations";

export const LocationSettings: React.FC = () => {
  const activeCompany = useActiveCompany();

  const { form, handleFieldUpdate } = useSettingsForm<SettingsForm>(SETTINGS_PATH);
  useWatch<SettingsForm>({ control: form.control });

  return (
    <SettingsPage
      title="Location settings"
      subtitle="Configure how locations are managed in your company."
      backText="Back to locations"
      backLink="/settings/locations"
    >
      <SettingsCard title={"General"}>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 0 }}>
          <div style={{ marginTop: -6, marginRight: 10 }}>
            <Formblock
              type="checkbox"
              name="show_location_selector_on_mobile"
              className="modal"
              form={form}
              sideBySideInput={false}
              editing={true}
              onChange={(e) => {
                handleFieldUpdate(e.target.checked, "show_location_selector_on_mobile");
              }}
              defaultValue={activeCompany?.settings.locations?.show_location_selector_on_mobile}
            />
          </div>
          {"Show the location selection with any job selection in the mobile app"}
        </div>
      </SettingsCard>
    </SettingsPage>
  );
};
