import React, { useContext, useMemo } from "react";
import { TimesheetPolicyFields, TimeSheetPolicySignOff } from "backend/models/policy";
import { UseFormMethods } from "react-hook-form";
import { LiveTimesheetFormFields } from "./ClockInModal";
import { Formblock, Notifier } from "ui";
import AppContext from "dashboard/contexts/app-context";
import CustomFieldValuesForm from "../custom-fields/CustomFieldValuesForm";
import styles from "./ClockInModal.module.css";

type ClockOutFormProps = {
  form: UseFormMethods<LiveTimesheetFormFields>;
  isFieldRequired: (field: keyof TimesheetPolicyFields) => boolean;
  isFieldVisible: (field: keyof TimesheetPolicyFields) => boolean;
  signOff: TimeSheetPolicySignOff | undefined;
};

const ClockOutForm: React.FC<ClockOutFormProps> = ({ form, isFieldRequired, isFieldVisible, signOff }) => {
  const { customFields } = useContext(AppContext);
  const tsCustomFields = useMemo(
    () => customFields.filter((cf) => cf.parent_type === "timesheet"),
    [customFields]
  );

  return (
    <div className={styles["clock-out-form"]}>
      {isFieldVisible("injury") && (
        <Formblock
          name="injury"
          form={form}
          type="select"
          label="Injured during shift?"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          editing={true}
          requiredSelect={isFieldRequired("injury")}
        />
      )}
      {signOff && (
        <Formblock
          name="sign_off"
          form={form}
          type="esignature"
          label="Sign off"
          editing={true}
          onError={Notifier.error}
          agreement={signOff.agreement || undefined}
        />
      )}
      {tsCustomFields && (
        <CustomFieldValuesForm
          form={form}
          customFields={tsCustomFields}
          defaultValues={[]}
          formblockClassName="timesheet-custom-fields"
          editing={true}
        />
      )}
    </div>
  );
};

export default ClockOutForm;
