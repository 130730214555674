import React from "react";
import { TimesheetSettings } from "./TimesheetSettings";
import { SettingsCard } from "ui";

interface MobileSupervisorTimesheetViewSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const MobileSupervisorTimesheetViewSettings: React.FC<MobileSupervisorTimesheetViewSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <SettingsCard
      title="Mobile supervisors timesheet view"
      info={"Set how supervisors review timesheets on the mobile app."}
    >
      <div className="flex" style={{ marginTop: 5 }}>
        <input
          type="checkbox"
          checked={timesheetSettings.timesheets_grouped_by_tm_for_supervisors}
          onChange={(e) => {
            handleChange({
              timesheets_grouped_by_tm_for_supervisors: e.target.checked,
            });
          }}
        />
        <span style={{ marginLeft: 10 }}>
          Present timesheets grouped by team member for supervisors reviewing hours on the mobile app.
        </span>
      </div>
    </SettingsCard>
  );
};

export default MobileSupervisorTimesheetViewSettings;
