import React from "react";
import { ApprovalItem } from "dashboard/miter";
import { ActionModal } from "ui";
import { ApprovalItemPolicyData } from "./ApprovalItemPolicyData";
import { ApprovalTimeline } from "./ApprovalTimeline";

type Props = {
  item: ApprovalItem;
  onHide: () => void;
};

export const ApprovalItemInfoModal: React.FC<Props> = ({ item, onHide }) => {
  return (
    <ActionModal
      headerText={"Policy and Approvals"}
      onHide={onHide}
      onCancel={onHide}
      cancelText="Close"
      showCancel={true}
    >
      <div style={{ paddingTop: 15, paddingBottom: 15 }}>
        <div style={{ marginBottom: 35, marginTop: 15 }}>
          <ApprovalItemPolicyData item={item} />
        </div>
        <ApprovalTimeline item={item} />
      </div>
    </ActionModal>
  );
};

export default ApprovalItemInfoModal;
