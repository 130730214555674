import { NetSuiteQueryTypes, SuiteAppOptions } from "backend/services/netsuite/types/netsuite-types";
import { Option } from "ui/form/Input";

export const NETSUITE_INPUT_LENGTH = 250;
export const DEFAULT_NETSUITE_LABEL_STYLE = { minWidth: 250 };

export const SUITEAPP_CONFIGURATION_MAP: Record<SuiteAppOptions, NetSuiteQueryTypes> = {
  none: {
    costType: {
      recordName: "costCategory",
    },
    costCode: {
      standard: {
        recordName: "projectTask",
      },
      recordName: "projectTask",
    },
  },
  fsm: {
    costCode: {
      standard: {
        recordName: "item",
      },
      recordName: "item",
    },
  },
  rabb_it: {
    job: {
      fields: {
        jobCodeName: "entitynumber",
      },
    },
    ledgerEntry: {
      fields: {
        jobField: "custcol_r_it_reporting_project",
        standardCostCodeListField: "custcol_r_it_standard_cost_code_list",
        standardCostCodeField: "custcol_r_it_std_cost_code",
        costTypeField: "custcol_hrc_cost_type",
        costCodeField: "custrecord_hrc_pci_procoreid4",
      },
    },
    costType: {
      recordName: "customrecord_hrc_cost_type",
    },
    costCode: {
      standard: {
        recordName: "customrecord_r_it_list_code",
      },
      recordName: "customrecord_hrc_pci_costcode",
    },
  },
  appficiency: {
    job: {
      fields: {
        jobCodeName: "entityid",
      },
    },
    costType: {
      recordName: "customrecord_appf_sov",
    },
    costCode: {
      recordName: "item",
    },
    ledgerEntry: {
      fields: {
        jobField: "custcol_jobcosting_project",
        costTypeField: "custcol_ce2p_sov",
        costCodeField: "item",
      },
    },
    timesheet: {
      recordName: "timebill",
      fields: {
        costTypeField: "custcol_ce2p_sov",
        costCodeField: "item",
        regField: "custcol_appf_regrate",
        otField: "custcol_appf_otrate",
        dotField: "custcol_appf_dotrate",
      },
    },
  },
  blue_collar: {
    job: { recordName: "customrecord_cseg_bc_project" },
    costCode: { recordName: "customrecord_cseg_bc_cost_code" },
    ledgerEntry: {
      fields: {
        jobField: "customrecord_cseg_bc_project",
        costCodeField: "customrecord_cseg_bc_cost_code",
      },
    },
  },
};

export const suiteAppOptions: Option<SuiteAppOptions>[] = [
  { label: "Miter Default", value: "none" },
  { label: "Rabb-IT", value: "rabb_it" },
  { label: "Appficiency", value: "appficiency" },
  { label: "Blue Collar", value: "blue_collar" },
  { label: "FSM", value: "fsm" },
];

export const codeCodeMappingOptions = [
  { label: "Project Tasks", value: "projectTask" },
  { label: "Service Sale Items", value: "item" },
];
