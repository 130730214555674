import React, { useState, useEffect } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Formblock } from "ui";
import { ModalHeader } from "ui";
import { MiterAPI, MiterError, WorkersCompCode } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import * as vals from "dashboard/utils/validators";
import { ModalFooter } from "ui";
import { ConfirmModal } from "ui";
import { useSetWcCodes } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useAuditLogHistory } from "../audit-logs/useAuditLogHistory";

const PREMIUM_RATE_TYPES = [
  { label: "Hourly", value: "hourly" },
  { label: "Percentage", value: "percentage" },
];

// This modal allows users to create or edit workers' comp codes
type Props = {
  hide: () => void;
  code?: WorkersCompCode;
  company: string;
  edit?: boolean;
};

const wcPremiumValidator = vals.numberValidator({ required: false, min: 0, max: 100 });

const WcCodeModal: React.FC<Props> = ({ hide, code, company, edit }) => {
  // Hooks
  const { register, control, errors, handleSubmit, watch } = useForm();
  const setWcCodes = useSetWcCodes();
  const { cannot } = useMiterAbilities();

  const { renderAuditLogHistoryButton, renderAuditLogHistoryModal } = useAuditLogHistory({
    itemId: code?._id || "",
    itemType: "workers_comp_code",
    refreshCounter: 0,
    show: false,
  });

  // State
  const [loading, setLoading] = useState(false);
  const [wcCode, setWcCode] = useState<WorkersCompCode | undefined>(code);
  const [saving, setSaving] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [premiumRateType, setPremiumRateType] = useState<string | undefined>(
    wcCode?.premium_rate_type || "percentage"
  );

  const selectedRateType = watch("premium_rate_type");

  useEffect(() => {
    if (selectedRateType) {
      setPremiumRateType(selectedRateType.value);
    }
  }, [selectedRateType]);

  const save = async (data) => {
    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to manage workers' comp codes.");
      return;
    }

    setSaving(true);
    try {
      // Clean data
      const cleanData = {
        ...data,
        company: company,
        premium_rate: data.premium_rate || null,
        premium_rate_type: data.premium_rate_type.value,
      };
      // Call backend

      let response: WorkersCompCode & MiterError;
      if (edit && wcCode) {
        response = await MiterAPI.wc_codes.update(wcCode._id, cleanData);
      } else {
        response = await MiterAPI.wc_codes.create(cleanData);
      }
      if (response.error) throw Error(response.error);

      setWcCode(response);
      setWcCodes((prev) => prev.concat(response));
      Notifier.success(`Code successfully ${edit ? "updated" : "created"}.`);
    } catch (e) {
      console.log(e);
      Notifier.error(`There was an error ${edit ? "updating" : "creating"} the code. We're looking into it.`);
    }
    hide();
    setSaving(false);
  };

  const archive = async () => {
    if (!wcCode) return;

    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to delete workers' comp codes.");
      return;
    }

    setLoading(true);
    try {
      // Call backend
      const response = await MiterAPI.wc_codes.update(wcCode._id, { archived: true });
      if (response.error) throw new Error(response.error);
      Notifier.success("Code successfully deleted.");
      setWcCodes((prev) => prev.concat(response));
      hide();
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error deleting the code. We're looking into it!");
    }
    setLoading(false);
  };

  const renderHeader = () => {
    return (
      <div className="flex space-between width-100-percent">
        {edit ? "Edit workers' comp code" : "Create workers' comp code"}
        {edit && <div style={{ marginRight: "7px" }}>{renderAuditLogHistoryButton()}</div>}
      </div>
    );
  };

  return (
    <>
      <div className="modal-background">
        <ClickAwayListener onClickAway={() => {}}>
          <div className="modal-wrapper form" style={{ overflowX: "visible" }}>
            {archiving && (
              <ConfirmModal
                title="Delete code?"
                body="Are you sure you want to delete? To restore a deleted code, you'll have to contact Miter support."
                onNo={() => setArchiving(false)}
                onYes={archive}
                loading={loading}
                yesText={null}
                noText={null}
              />
            )}
            <ModalHeader heading={renderHeader()} onHide={hide} />
            <div className="modal-body form" style={{ overflowX: "visible" }}>
              <div className="vertical-spacer"></div>
              <Formblock
                label="Label*"
                labelInfo="A human-friendly label used to identify the code"
                placeholder="5022 - Bricklaying"
                type="text"
                register={register(vals.required)}
                name="label"
                className="modal"
                errors={errors}
                editing={true}
                defaultValue={wcCode ? wcCode.label : null}
                disabled={cannot("lists:workers_comp_codes:manage")}
              />
              <Formblock
                label="Class code"
                labelInfo="3-4 digit workers' compensation code assigned by the NCCI or a state rating bureau"
                placeholder="5022"
                type="text"
                register={register}
                name="code"
                className="modal"
                errors={errors}
                defaultValue={wcCode ? wcCode.code : null}
                disabled={cannot("lists:workers_comp_codes:manage")}
                editing={true}
              />
              <Formblock
                label="Premium Rate Type"
                labelInfo="Either percentage or hourly"
                type="select"
                control={control}
                className="modal"
                options={PREMIUM_RATE_TYPES}
                register={register}
                defaultValue={premiumRateType}
                name="premium_rate_type"
                editing={true}
                errors={errors}
                disabled={cannot("lists:workers_comp_codes:manage")}
              />
              {premiumRateType === "percentage" ? (
                <Formblock
                  label="Premium rate"
                  labelInfo="Workers' comp insurance premiums as a % of gross pay"
                  type="percent"
                  placeholder={"3.4"}
                  register={register(wcPremiumValidator)}
                  name="premium_rate"
                  className="modal"
                  defaultValue={wcCode ? wcCode.premium_rate : null}
                  disabled={cannot("lists:workers_comp_codes:manage")}
                  errors={errors}
                  editing={true}
                />
              ) : (
                <Formblock
                  label="Premium rate"
                  labelInfo="Workers' comp hourly contributions"
                  type="unit"
                  unit="$"
                  register={register(vals.isNumberButNotRequired)}
                  name="premium_rate"
                  className="modal"
                  defaultValue={wcCode ? wcCode.premium_rate : null}
                  disabled={cannot("lists:workers_comp_codes:manage")}
                  errors={errors}
                  editing={true}
                  control={control}
                />
              )}
              <div className="vertical-spacer"></div>
            </div>
            <ModalFooter
              loading={saving}
              submitText="Save"
              onCancel={hide}
              onSubmit={handleSubmit(save)}
              className="form"
              onDelete={() => setArchiving(true)}
              deleteText="Delete"
              showDelete={edit}
              hideSubmit={cannot("lists:workers_comp_codes:manage")}
            />
          </div>
        </ClickAwayListener>
      </div>
      {renderAuditLogHistoryModal()}
    </>
  );
};

export default WcCodeModal;
