import { GridApi, ICellRendererParams } from "ag-grid-community";
import { Badge } from "ui";
import React from "react";

export const verticalCenteredCellStyle = { display: "flex", height: "100%", alignItems: "center" };

export function getRowData<T>(gridApi: GridApi<T> | undefined): T[] | undefined {
  if (!gridApi) return;
  const allRowData: T[] = [];
  gridApi.forEachNode((node) => {
    if (node.data) allRowData.push(node.data);
  });
  return allRowData;
}

export const identityAggFunc = (params: $TSFixMe): string => {
  for (const value of params.values || []) {
    if (value) return value;
  }
  return "-";
};

export const booleanCellRenderer = (params: ICellRendererParams): React.ReactNode => {
  return (
    <Badge
      className="no-margin"
      text={params.value ? "✓" : "✕"}
      table
      color={params.value ? "green" : "lightgray"}
    />
  );
};

export const BooleanCheckmarkCell: React.FC<{ value: boolean }> = ({ value }) => {
  return value ? <Badge className="no-margin" text={"✓"} color={"green"} /> : null;
};
