import React, { FC } from "react";
import { ExpenseReimbursementsTable } from "./ExpenseReimbursementsTable";
import { Helmet } from "react-helmet";
import { Params, useNavigate, useParams } from "react-router-dom";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { Button, Notifier } from "ui";
import { StripeOnboardingButton } from "./StripeOnboardingButton";

const ExpenseReimbursements: FC = () => {
  const { id } = useParams<Params>();
  const navigate = useNavigate();
  const { can } = useMiterAbilities();

  const canViewReimbursements = can("reimbursements:personal:read") || can("reimbursements:others:read");
  if (!canViewReimbursements) {
    navigate("/home");
    Notifier.error("You do not have permission to view this page.");
  }
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Reimbursements | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Reimbursements</h1>
          <div className="flex-1"></div>
          {can("reimbursements:others:process_payment") && (
            <StripeOnboardingButton text="ACH reimbursements" />
          )}
          {can("expenses:settings") && (
            <Button
              className="button-1"
              text="Policies and settings"
              onClick={() => navigate("/spend/settings/reimbursements/policies")}
            />
          )}
        </div>

        <ExpenseReimbursementsTable expenseReimbursementId={id} shouldRedirectURLWhenOpening={true} />
      </div>
    </div>
  );
};

export default ExpenseReimbursements;
