import { IntegrationConnection, MiterIntegrationForCompany } from "dashboard/miter";
import React, { useState } from "react";
import { IntegrationEntity, IntegrationSyncOpDirection } from "backend/models/integrations/integrations";

import styles from "./integrations.module.css";
import { integrationEntityLookup } from "./IntegrationSyncHistory";
import { Button } from "ui";
import { BatchSyncModal } from "./BatchSyncModal";
import { NETSUITE_ENABLED_SYNCS_BY_SUITEAPP } from "./constants";

type Props = {
  entity: IntegrationEntity;
  integration: MiterIntegrationForCompany;
  updateIntegrationConnection: (update: Partial<IntegrationConnection>) => void;
  getSyncs: () => Promise<void>;
};

export const SyncSettingsEntityRow: React.FC<Props> = (props) => {
  const { entity, integration } = props;

  let entityName = integrationEntityLookup[entity];
  if (props.integration.key === "sage_intacct" && entity === "ledger_entries") {
    entityName += " (incl. payrolls)";
  }

  const supportedOps = integration.supported_operations?.[entity] || {};

  const eitherSyncSupported = supportedOps["pull"]?.enabled || supportedOps["push"]?.enabled;

  if (!eitherSyncSupported) {
    return null;
  }

  return (
    <div className={styles["row"]}>
      <div className={`${styles["column-1"]} ${styles["cell"]}`}>{entityName}</div>
      <div className={`${styles["column-2"]} ${styles["cell"]}`}>
        <SyncSettingsSelector {...props} direction="pull" />
      </div>
      <div className={`${styles["column-3"]} ${styles["cell"]}`}>
        <SyncSettingsSelector {...props} direction="push" />
      </div>
    </div>
  );
};

type SelectorProps = Props & { direction: IntegrationSyncOpDirection };

const SyncSettingsSelector: React.FC<SelectorProps> = (props) => {
  const { integration, entity, direction } = props;

  const supportedOps = integration.supported_operations?.[entity] || {};
  const enabledOps = integration?.connection?.enabled_operations?.[entity] || {};

  if (integration.key === "netsuite") {
    const suiteApp = integration?.connection?.metadata?.netsuite?.suiteApp || "none";

    const suiteAppEnabledOperations = NETSUITE_ENABLED_SYNCS_BY_SUITEAPP[suiteApp];

    if (!suiteAppEnabledOperations?.[entity]?.pull?.enabled) delete supportedOps.pull;
    if (!suiteAppEnabledOperations?.[entity]?.push?.enabled) delete supportedOps.push;
  }

  const isSupported = Object.keys(supportedOps[direction] || {}).length;
  const [batchModal, setShowBatchModal] = useState(false);

  const handleDailySyncChange = async (e) => {
    const newOps = {
      ...integration?.connection?.enabled_operations,
      [entity]: { ...enabledOps, [direction]: { daily_sync_enabled: e.target.checked } },
    };
    props.updateIntegrationConnection({ enabled_operations: newOps });
  };

  return (
    <div>
      {isSupported ? (
        <div className="flex">
          {batchModal && <BatchSyncModal {...props} hide={() => setShowBatchModal(false)} />}
          <div className="flex">
            <input
              type="checkbox"
              defaultChecked={enabledOps[direction]?.daily_sync_enabled}
              onChange={handleDailySyncChange}
            />
            <div style={{ marginLeft: 10, marginRight: 25 }}>Daily sync</div>
          </div>
          <Button className="button-1" text="Sync" onClick={() => setShowBatchModal(true)} />
        </div>
      ) : (
        <div style={{ color: "gray" }}>-</div>
      )}
    </div>
  );
};
