import { useParams } from "react-router-dom";
import { CPRSettings } from "./reportPages/CPR/Settings/CPRSettings";
import React from "react";

export const ReportSettings: React.FC = () => {
  const params = useParams<{ report_id: string }>();

  if (params.report_id === "cpr") {
    return <CPRSettings />;
  }

  return <></>;
};

export default ReportSettings;
