import React from "react";
import { ActionModal } from "ui";
import styles from "./FailuresModal.module.css";

export type FailureItem = {
  label: string;
  message: string;
};

type Props = {
  headerText?: string;
  failures: Array<FailureItem>;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string;
};

const FailuresModal: React.FC<Props> = ({ headerText, failures, onClose, onConfirm, confirmText }) => {
  const onSubmit = () => {
    onConfirm?.();
    onClose();
  };

  return (
    <ActionModal
      headerText={headerText || "Failures"}
      onHide={onClose}
      onSubmit={onSubmit}
      submitText={onConfirm ? confirmText || "Confirm" : "Close"}
      showSubmit
      onCancel={onClose}
      showCancel={!!onConfirm}
      cancelText={"Close"}
      wrapperStyle={{ width: 500 }}
    >
      <div className={styles["failures-modal-container"]}>
        {failures.map((failure, index) => {
          return (
            <div className={styles["failures-modal-row"]} key={failure.label + "-" + index}>
              <div className={styles["failures-modal-label"]}>
                <strong>{failure.label}</strong>
              </div>
              <div className={styles["failures-modal-message"]}>{failure.message}</div>
            </div>
          );
        })}
      </div>
    </ActionModal>
  );
};

export default FailuresModal;
