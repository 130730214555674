import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { MiterAPI } from "../../miter";
import { Notifier, Button, Toggler, Loader } from "ui";
import { useActiveCompanyId } from "../../hooks/atom-hooks";
import { Params, useNavigate, useParams } from "react-router-dom";
import CardsLander from "./CardsLander";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import CardProgramTable from "./CardProgramTable";
import { CardTransactionsTable } from "./CardTransactionsTable";
import { useHasAccessToCardManagement } from "dashboard/gating";
import { StripeOnboardingButton } from "./StripeOnboardingButton";

export const CardManagement: React.FC = () => {
  /*********************************************************
   *  Use initial hooks
   **********************************************************/
  const { view, id } = useParams<Params>();
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const [hasExistingCards, setHasExistingCards] = useState<boolean>(true);
  const [isLoadingExistingCards, setIsLoadingExistingCards] = useState(true);

  const { can, cannot } = useMiterAbilities();

  const alwaysHasAccessToCards = useHasAccessToCardManagement();

  const showCardsOnboarding = useMemo(() => {
    // no cards connected
    return !alwaysHasAccessToCards && !hasExistingCards;
  }, [alwaysHasAccessToCards, hasExistingCards]);

  /*********************************************************
   *  Configure toggler
   **********************************************************/
  const togglerConfig = useMemo(() => {
    if (showCardsOnboarding) {
      return [
        {
          path: "transactions",
          label: "Get started with cards",
          hide: cannot("miter_cards:create") && cannot("third_party_cards:create"),
        },
      ];
    } else {
      return [
        {
          path: "transactions",
          label: "Transactions",
          hide: cannot("card_transactions:others:read") && cannot("card_transactions:personal:read"),
        },
        {
          path: "programs",
          label: "Programs",
          hide: cannot("miter_cards:read") && cannot("third_party_cards:read"),
        },
      ];
    }
  }, [showCardsOnboarding, cannot]);

  const toggle = (page: string) => {
    return navigate("/spend/cards/" + page);
  };

  useEffect(() => {
    if (isLoadingExistingCards) return;

    if (!view) {
      navigate(`/spend/cards/transactions`, { replace: true });
    }
  }, [isLoadingExistingCards, togglerConfig, view, navigate]);

  /*********************************************************
   *  Rendering functions
   **********************************************************/

  const renderToggler = () => {
    if (view === "settings") return <></>;
    return <Toggler config={togglerConfig} active={view} toggle={toggle} />;
  };

  const renderView = () => {
    if (view === "transactions") {
      const canReadCardTransactions =
        can("card_transactions:others:read") || can("card_transactions:personal:read");

      if (!canReadCardTransactions) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      if (showCardsOnboarding) {
        return <CardsLander refreshData={getExistingCards} />;
      } else {
        return <CardTransactionsTable shouldRedirectURLWhenOpening={true} />;
      }
    } else if (view === "programs") {
      const canReadCards = can("miter_cards:read") || can("third_party_cards:read");
      if (!canReadCards) {
        navigate("/home");
        Notifier.error("You do not have permission to view this page.");
      }

      if (id) {
        navigate(`/spend/cards/programs/${id}`);
      }
      return <CardProgramTable />;
    }
  };

  const getExistingCards = useCallback(async () => {
    if (!activeCompanyId) return;

    try {
      const [thirdPartyCards, miterCards] = await Promise.all([
        MiterAPI.expenses.third_party_cards.list({
          filter: [{ field: "company_id", value: activeCompanyId }],
        }),
        MiterAPI.expense_cards.list({ filter: [{ field: "company", value: activeCompanyId }] }),
      ]);

      if (thirdPartyCards.error) throw new Error(thirdPartyCards.error);
      if (miterCards.error) throw new Error(miterCards.error);

      setHasExistingCards(thirdPartyCards?.length > 0 || miterCards?.length > 0);
    } catch (e: $TSFixMe) {
      console.error(`Error getting existing cards in cards lander: ${e.message}`);
      Notifier.error(e.message);
    }
    setIsLoadingExistingCards(false);
  }, [activeCompanyId]);

  useEffect(() => {
    getExistingCards();
  }, [activeCompanyId, getExistingCards]);

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Cards | Miter</title>
      </Helmet>
      <div className="page-content">
        <div className="flex">
          <h1>Cards</h1>
          <div className="flex-1"></div>
          {can("miter_cards:create") && <StripeOnboardingButton text="Miter spend cards" />}
          {can("expenses:settings") && (
            <Button
              className="button-1"
              text="Policies and settings"
              onClick={() => navigate("/spend/settings/cards/policies")}
            />
          )}
        </div>
        {isLoadingExistingCards ? (
          <Loader />
        ) : (
          <>
            {renderToggler()}
            {renderView()}
          </>
        )}
      </div>
    </div>
  );
};
