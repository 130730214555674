import React, { useState } from "react";
import { ConfirmModal } from "ui";
import { AggregatedTeamMember, MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { useActiveCompanyId, useRefetchTeam } from "dashboard/hooks/atom-hooks";

type Props = {
  defaultData: AggregatedTeamMember;
  onHide: () => void;
};

const OnboardingInfoForm: React.FC<Props> = ({ defaultData, onHide }) => {
  const activeCompanyId = useActiveCompanyId();
  const refetchTeam = useRefetchTeam();
  const [submitting, setSubmitting] = useState(false);

  const acknowledgeDocs = async () => {
    setSubmitting(true);

    try {
      // Get current onboarding documents ids
      const filter = [
        {
          field: "company",
          type: "string",
          value: activeCompanyId!,
        },
        {
          field: "onboarding_document",
          type: "boolean",
          value: true,
        },
        {
          field: "job",
          comparisonType: "exists",
          value: false,
        },
        {
          field: "team_member",
          comparisonType: "exists",
          value: false,
        },
        {
          field: "timesheet",
          comparisonType: "exists",
          value: false,
        },
      ];

      // @ts-expect-error Filter types are funky
      const fileRes = await MiterAPI.files.list(filter);
      if (fileRes.error) throw new Error(fileRes.error);

      const docIds = fileRes.map((file) => file._id);

      // Update the user's document ids
      const params = { acknowledged_onboarding_docs: docIds };
      const res = await MiterAPI.team_member.update(defaultData._id, params);
      if (res.error) throw new Error(res.error);

      Notifier.success(`${defaultData.full_name} has acknowledged all onboarding documents.`);
      refetchTeam(defaultData._id);
    } catch (err: $TSFixMe) {
      console.error("Error:", err);
      Notifier.error("We are unable to update the team member at this time");
    }

    setSubmitting(false);
  };

  return (
    <ConfirmModal
      title={"Acknowledge onboarding docs"}
      body={`${defaultData.full_name} has acknowledged all onboarding documents.`}
      onYes={acknowledgeDocs}
      onNo={onHide}
      loading={submitting}
    />
  );
};

export default OnboardingInfoForm;
