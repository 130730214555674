import { FrontendModel } from "dashboard/miter";
import {
  CreateMessageTemplateParams as CreateMessageTemplateParams_,
  UpdateMessageTemplateParams as UpdateMessageTemplateParams_,
  ArchiveMessageTemplatesResponse as ArchiveMessageTemplatesResponse_,
} from "backend/services/message-template-service";
import { MessageTemplate as MessageTemplate_ } from "backend/models";
export type MessageTemplate = FrontendModel<MessageTemplate_>;

export type CreateMessageTemplateParams = FrontendModel<CreateMessageTemplateParams_>;
export type UpdateMessageTemplateParams = FrontendModel<UpdateMessageTemplateParams_>;

export type ArchiveMessageTemplatesResponse = FrontendModel<ArchiveMessageTemplatesResponse_>;
export const MessageTemplateSmartFieldArray = [
  "candidate_first_name",
  "candidate_last_name",
  "job_posting_title",
  "sender_first_name",
  "sender_last_name",
];
export type MessageTemplateSmartField = typeof MessageTemplateSmartFieldArray[number];

export type MessageTemplateSmartFieldOption = {
  value: MessageTemplateSmartField;
  title: string;
};
