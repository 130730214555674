import { FlatfileResults } from "@flatfile/react";
import { useActiveCompanyId } from "dashboard/hooks/atom-hooks";
import React, { useMemo } from "react";
import { Notifier } from "ui";
import { LedgerAccount, MiterAPI } from "dashboard/miter";
import { ImportField, Importer } from "dashboard/components/importer/Importer";
import { CreateLedgerAccountParams } from "backend/services/ledger-accounts-service";
import { ledgerAccountClassificationOptions } from "./LedgerAccountModal";

type PrelimLedgerAccountImportRow = {
  label: string;
  externalId?: string;
  classification: LedgerAccount["classification"];
  active?: string;
};

type Props = {
  onFinish: () => void;
};

export const LedgerAccountImporter: React.FC<Props> = ({ onFinish }) => {
  /**********************************************************************************************************
   * Important hooks
   **********************************************************************************************************/
  const activeCompanyId = useActiveCompanyId();

  /**********************************************************************************************************
   * Handlers
   **********************************************************************************************************/
  const buildLedgerAccountParams = (row: PrelimLedgerAccountImportRow): CreateLedgerAccountParams => {
    if (!activeCompanyId) throw new Error("No active role");

    const { label, externalId, classification, active } = row;
    return {
      company_id: activeCompanyId,
      external_id: externalId,
      label,
      classification,
      active: active !== "false",
    };
  };

  const handleSubmit = async (results: FlatfileResults): Promise<void> => {
    try {
      const preppedLedgerAccounts = results.validData.map(buildLedgerAccountParams);

      const response = await MiterAPI.ledger_accounts.import({
        clean_inputs: preppedLedgerAccounts,
        raw_inputs: results.validData,
      });

      if (response.error) throw new Error(response.error);

      const successes = response.results.successes.length;
      const errors = response.results.errors.length;

      if (successes > 0) {
        if (errors > 0) {
          Notifier.error(`Imported ${successes} ledger accounts with ${errors} errors.`);
        } else {
          Notifier.success(`Imported ${successes} ledger accounts.`);
        }
      }

      onFinish();
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error creating the ledger accounts.");
    }
  };

  /**********************************************************************************************************
   * Flatfile configuration
   **********************************************************************************************************/

  const fields = useMemo(() => {
    const fieldList: ImportField[] = [
      {
        label: "Name",
        type: "string",
        key: "label",
        validators: [{ validate: "required" as const }],
      },
      {
        label: "External ID",
        type: "string",
        key: "externalId",
      },
      {
        label: "Type",
        type: "select",
        key: "classification",
        options: ledgerAccountClassificationOptions,
      },
      {
        label: "Active",
        type: "select",
        key: "active",
        description: "Whether the ledger account is active - defaults to true",
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
      },
    ];

    return fieldList;
  }, []);

  return <Importer id="ledger_accounts" resource="ledger accounts" onSave={handleSubmit} fields={fields} />;
};
