import React from "react";
import { SettingsCard, Formblock, Notifier } from "ui";
import { IntegrationConfigProps } from "../IntegrationConfig";
import { DeepPartial } from "utility-types";
import { IntegrationConnection } from "dashboard/miter";
import { buildAtomicMongoUpdateFromNested } from "dashboard/utils";
import { useForm } from "react-hook-form";
import {
  AcumaticaConnectionMetadata,
  ProjectTaskRestrictionBasis,
} from "backend/services/acumatica/acumatica-types";
import { useDebouncedCallback } from "use-debounce";
import { IntegrationEarningTypeConfig } from "../IntegrationEarningTypeConfig";
import { TimesheetEarningType } from "backend/models/timesheet";
import { IntegrationPayTypeConfig } from "../IntegrationPayTypeConfig";
import { TMPayType } from "backend/models/teamMember/team-member";
import { AcumaticaFallbackSelections } from "./AcumaticaFallbackSelections";
import { Option } from "ui/form/Input";
import { useFetchAcumaticaDataForConfig } from "dashboard/hooks/integrations/acumatica/useFetchAcumaticaDataForConfig";
import { AcumaticaBranchSettings } from "./AcumaticaBranchSettings";
import { AcumaticaWbsSettings } from "./AcumaticaWbsSettings";
import { AcumaticaClassificationSync } from "./AcumaticaClassificationSync";

export const ACUMATICA_INPUT_LENGTH = 250;
export const DEFAULT_ACUMATICA_LABEL_STYLE = { minWidth: 230 };

export const basePayTypeOptions = [
  { label: "Hourly", value: "hourly" },
  { label: "Salary", value: "salary" },
  { label: "Default", value: "default" },
];

export const projectTaskRestrictionFieldOptions: Option<ProjectTaskRestrictionBasis>[] = [
  { label: "Visibility Settings", value: "visibility_settings" },
  { label: "Attributes", value: "attributes" },
];

export const AcumaticaConfig: React.FC<IntegrationConfigProps> = ({
  integration,
  updateIntegrationConnection,
}) => {
  const form = useForm();

  const { dataForConfig, loading } = useFetchAcumaticaDataForConfig(integration.connection?._id);

  const { configObject } = integration.connection?.metadata?.acumatica || {};
  const { earningTypeMapping, markTimesheetsAsCompleted } = configObject?.timesheets || {};
  const { employeeClassMapping } = configObject?.team_members || {};

  const endpoint = configObject?.company?.endpoint;
  const { maxRequestsPerMinute, maxConcurrentAPIRequests } = configObject?.company || {};

  const updateCompanyConfig = async (
    value: string | number,
    key: "apiVersion" | "maxRequestsPerMinute" | "maxConcurrentAPIRequests" | "endpoint"
  ) => {
    const update = { configObject: { company: { [key]: value } } };

    await updateAcumaticaMetadata(update);
  };

  const debouncedCompanyConfigUpdate = useDebouncedCallback(updateCompanyConfig, 1000);

  const updateAcumaticaMetadata = async (
    update: DeepPartial<AcumaticaConnectionMetadata>,
    opts?: { collapseCount?: number }
  ) => {
    const raw: DeepPartial<IntegrationConnection> = { metadata: { acumatica: update } };
    const collapseCount = opts?.collapseCount;
    const flattened = buildAtomicMongoUpdateFromNested(raw, {
      collapseCount: collapseCount != null ? collapseCount + 2 : undefined,
    });

    await updateIntegrationConnection(flattened);
  };

  const saveEarningTypeCallback = async (newEarningTypeMap: Record<string, TimesheetEarningType>) => {
    await updateAcumaticaMetadata(
      {
        configObject: { timesheets: { earningTypeMapping: newEarningTypeMap } },
      },
      { collapseCount: 2 }
    );
  };

  const savePayTypeCallback = async (newPayTypeMap: Record<string, TMPayType>) => {
    await updateAcumaticaMetadata(
      {
        configObject: { team_members: { employeeClassMapping: newPayTypeMap } },
      },
      { collapseCount: 2 }
    );
  };

  const updateApiLimits = async (text: string, name: "maxConcurrentAPIRequests" | "maxRequestsPerMinute") => {
    const num = Number(text || undefined);
    if (Number.isNaN(num) || Math.round(num) !== num || num <= 0) {
      Notifier.error("Please input a positive integer");
      return;
    }
    await updateCompanyConfig(num, name);
  };

  const debouncedUpdateApiLimits = useDebouncedCallback(updateApiLimits, 1000);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SettingsCard title="Company Settings">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="apiVersion"
          type="text"
          labelInfo="The release version of your Acumatica Instance."
          label="Release Version"
          defaultValue={configObject?.company?.apiVersion}
          onChange={(e) => debouncedCompanyConfigUpdate(e.target.value, "apiVersion")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="apiVersion"
          type="text"
          labelInfo="The endpoint to use when talking to the Acumatica API. Uses 'Default' if not provided."
          label="Endpoint"
          defaultValue={endpoint}
          onChange={(e) => debouncedCompanyConfigUpdate(e.target.value, "endpoint")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="maxRequestsPerMinute"
          type="number"
          min={1}
          step={1}
          labelInfo="The maximum number of API requests per minute your Acumatica instance supports."
          label="Max Requests Per Minute"
          defaultValue={maxRequestsPerMinute}
          onChange={(e) => debouncedUpdateApiLimits(e.target.value, "maxRequestsPerMinute")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="maxConcurrentAPIRequests"
          type="number"
          min={1}
          step={1}
          labelInfo="The maximum number of concurrent API requests your Acumatica instance supports."
          label="Max Concurrent Requests"
          defaultValue={maxConcurrentAPIRequests}
          onChange={(e) => debouncedUpdateApiLimits(e.target.value, "maxConcurrentAPIRequests")}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Branches">
        <AcumaticaBranchSettings
          integrationConnnection={integration.connection}
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          branches={dataForConfig?.branches}
          loading={loading}
          form={form}
        />
      </SettingsCard>
      <SettingsCard title="Work Breakdown Structure">
        <AcumaticaWbsSettings
          integrationConnnection={integration.connection}
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          form={form}
        />
      </SettingsCard>
      <SettingsCard title="Earning Type Mapping">
        <IntegrationEarningTypeConfig
          saveEarningTypeCallback={saveEarningTypeCallback}
          earningTypeMapping={earningTypeMapping}
        />
      </SettingsCard>
      <SettingsCard title="Pay Type Mapping">
        <IntegrationPayTypeConfig
          savePayTypeCallback={savePayTypeCallback}
          payTypeMapping={employeeClassMapping}
          payTypeOptions={basePayTypeOptions}
        />
      </SettingsCard>
      <SettingsCard title="Fallback selections">
        <AcumaticaFallbackSelections
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          configObject={configObject}
          dataForConfig={dataForConfig}
          loading={loading}
          form={form}
        />
      </SettingsCard>
      <SettingsCard title="Job cost sync">
        <Formblock
          form={form}
          options={[
            { label: "Time entries", value: "timeEntries" },
            { label: "Project transactions", value: "projectTransactions" },
          ]}
          name="hoursSync"
          type="select"
          defaultValue={configObject?.jobCostSync?.hoursSync || "projectTransactions"}
          onChange={(o) => {
            updateAcumaticaMetadata({
              configObject: { jobCostSync: { hoursSync: o?.value } },
            });
          }}
          editing={true}
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          label="Sync approved hours as"
          labelInfo="When timesheets are approved in Miter, Miter will sync them to Acumatica as either time entries or project transactions."
          underlineTooltip
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
        />
      </SettingsCard>
      <SettingsCard title="Timesheets">
        <Formblock
          form={form}
          underlineTooltip={true}
          inputProps={{ style: { width: ACUMATICA_INPUT_LENGTH } }}
          name="markTimesheetsAsCompleted"
          type="checkbox"
          labelInfo="When checked, goes through all timesheets that were pushed in the last 24 hours and marks them as completed on Acumatica."
          label="Mark Timesheets as Completed"
          defaultValue={!!markTimesheetsAsCompleted}
          onChange={(e) =>
            updateAcumaticaMetadata(
              {
                configObject: { timesheets: { markTimesheetsAsCompleted: e.target.checked } },
              },
              { collapseCount: 2 }
            )
          }
          labelStyle={DEFAULT_ACUMATICA_LABEL_STYLE}
          editing={true}
        />
      </SettingsCard>
      <SettingsCard title="Classifications">
        <AcumaticaClassificationSync
          updateAcumaticaMetadata={updateAcumaticaMetadata}
          configObject={configObject}
          dataForConfig={dataForConfig}
          loading={loading}
          form={form}
        />
      </SettingsCard>
      <div className="vertical-spacer"></div>
    </div>
  );
};
