import { ChecklistTaskContentProps } from "dashboard/utils/checklist-utils";
import { Formblock } from "ui";
import { DueDateForm } from "./TeamMemberChecklistDueDateForm";
import styles from "../TeamMemberChecklistBuilder.module.css";
import { Option } from "packages/ui/form/Input";
import { useFormOptions } from "dashboard/hooks/atom-hooks";
import { FormOnboardingTaskForm } from "dashboard/utils/team-member-checklist-utils";

export const FormTaskContent: React.FC<ChecklistTaskContentProps<FormOnboardingTaskForm>> = ({
  task,
  setTask,
}) => {
  const formOptions = useFormOptions();

  const handleFormChange = (option: Option<string> | null) => {
    setTask({ ...task, data: { ...task.data, form_id: option?.value } });
  };

  return (
    <div className={styles["team-member-checklist-task-content"]}>
      <Formblock
        type="select"
        name="form_id"
        label="Select form"
        editing={true}
        defaultValue={task.data.form_id}
        options={formOptions}
        className="modal"
        onChange={handleFormChange}
        style={{ marginBottom: 0 }}
      />
      <DueDateForm task={task} setTask={setTask} />
    </div>
  );
};
