import React from "react";

import underConstructionBlackPng from "dashboard/assets/under-construction-black.png";

type Props = {
  header?: string;
  errorMessage: string;
};
const ErrorCard: React.FC<Props> = ({ header, errorMessage }) => {
  return (
    <div className="error-wrapper">
      <div className="vertical-spacer" />
      <div className="error-card-wrapper flex center">
        <img className="error-icon under-construction" src={underConstructionBlackPng} />
        <div className="error-card-text-wrapper">
          <h4>{header ? header : "Something went wrong!"}</h4>
          <div className="error-card-text">{errorMessage}</div>
        </div>
        <button className="button-2" onClick={() => window.location.replace(process.env.REACT_APP_URL)}>
          Reload Dashboard
        </button>
        <div style={{ height: "25px" }} />
      </div>
    </div>
  );
};

export default ErrorCard;
