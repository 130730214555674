import React from "react";
import { IntegrationConnection, MiterIntegrationForCompany } from "dashboard/miter";
import { Option } from "ui/form/Input";
import { IntacctConfigData, IntacctIntegrationConfig } from "./Intacct/IntacctConfig";
import { XeroIntegrationConfig } from "./Xero/XeroConfig";
import { QboIntegrationConfig } from "./QBO/QboConfig";
import { QbdIntegrationConfig } from "./QBD/QbdConfig";
import { JonasIntegrationConfig } from "./Jonas/JonasIntegrationConfig";
import { EmployeeNavigatorIntegrationConfig } from "./EmployeeNavigator/EmployeeNavigatorConfig";
import { Sage300Config } from "./Sage300/Sage300Config";
import { BambooConfig } from "./Bamboo/BambooConfig";
import { ProcoreConfig } from "./Procore/ProcoreConfig";
import { AcumaticaConfig } from "./Acumatica/AcumaticaConfig";
import { Sage100Config } from "./Sage100/Sage100Config";
import { BuildOpsConfig } from "./BuildOps/BuildOpsConfig";
import { NetSuiteConfig } from "./NetSuite/NetSuiteConfig";
import { FoundationConfig } from "./Foundation/FoundationConfig";
import { PayrollIntegrationsConfig } from "./PayrollIntegrations/PayrollIntegrationsConfig";

export type IntegrationConfigProps = {
  integration: MiterIntegrationForCompany;
  updateIntegrationConnection: (
    update: Partial<IntegrationConnection> | Record<string, $TSFixMe>
  ) => Promise<void>;
  xeroTrackingOptions: Option<string>[] | undefined;
  setXeroTrackingOptions: React.Dispatch<React.SetStateAction<Option<string>[] | undefined>>;
  intacctConfigData: IntacctConfigData | undefined;
  setIntacctConfigData: React.Dispatch<React.SetStateAction<IntacctConfigData | undefined>>;
  loadingConnectionStatus: boolean;
};

export const IntegrationConfig: React.FC<IntegrationConfigProps> = (props) => {
  if (props.integration.key === "xero") {
    return <XeroIntegrationConfig {...props} />;
  } else if (props.integration.key === "qbo") {
    return <QboIntegrationConfig {...props} />;
  } else if (props.integration.key === "qbd") {
    return <QbdIntegrationConfig {...props} />;
  } else if (props.integration.key === "sage_intacct") {
    return <IntacctIntegrationConfig {...props} />;
  } else if (props.integration.key === "employee_navigator") {
    return <EmployeeNavigatorIntegrationConfig {...props} />;
  } else if (props.integration.key === "payroll_integrations") {
    return <PayrollIntegrationsConfig {...props} />;
  } else if (props.integration.key === "jonas") {
    return <JonasIntegrationConfig {...props} />;
  } else if (props.integration.key === "sage_300") {
    return <Sage300Config {...props} />;
  } else if (props.integration.key === "bamboo") {
    return <BambooConfig {...props} />;
  } else if (props.integration.key === "procore") {
    return <ProcoreConfig {...props} />;
  } else if (props.integration.key === "sage_100") {
    return <Sage100Config {...props} />;
  } else if (props.integration.key === "acumatica") {
    return <AcumaticaConfig {...props} />;
  } else if (props.integration.key === "buildops") {
    return <BuildOpsConfig {...props} />;
  } else if (props.integration.key === "netsuite") {
    return <NetSuiteConfig {...props} />;
  } else if (props.integration.key === "foundation") {
    return <FoundationConfig {...props} />;
  } else {
    return <span>There are no configuration options for this integration.</span>;
  }
};
