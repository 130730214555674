import React from "react";
import { TimesheetSettings } from "./TimesheetSettings";
import InfoButton from "dashboard/components/information/information";

interface KioskSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const KioskSettings: React.FC<KioskSettingsProps> = ({ timesheetSettings, handleChange }) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Kiosks</div>
        <InfoButton text="Supervisors can display ;quotkiosk mode;quot from the mobile app. Multiple crew members can clock in and clock out from a kiosk using their 4 - digit pins." />
      </div>
      <div className="vertical-spacer-small"></div>
      <div className="flex" style={{ alignItems: "flex-start" }}>
        <input
          style={{ marginLeft: 10 }}
          type="checkbox"
          name="kiosk.enabled"
          checked={timesheetSettings.kiosk?.enabled}
          onChange={(e) => handleChange({ kiosk: { ...timesheetSettings.kiosk, enabled: e.target.checked } })}
        />
        <span style={{ marginLeft: 15 }}>
          <strong>Enable kiosk mode: </strong>
          {`Allow team members with permissions and all supervisors to set up timesheet kiosk mode from the mobile app. To add this permission for team members, edit the "Can enable kiosk field" from the team member details page.`}
        </span>
      </div>
      {timesheetSettings.kiosk?.enabled && (
        <div className="flex" style={{ alignItems: "flex-start", marginTop: 8 }}>
          <input
            style={{ marginLeft: 10 }}
            type="checkbox"
            name="kiosk.show_timesheets_tab"
            checked={timesheetSettings.kiosk?.show_timesheets_tab}
            onChange={(e) =>
              handleChange({
                kiosk: {
                  ...timesheetSettings.kiosk,
                  enabled: true,
                  show_timesheets_tab: e.target.checked,
                },
              })
            }
          />
          <span style={{ marginLeft: 15 }}>
            {`Allow team members to edit, create, and attest to timesheets within kiosk mode.`}
          </span>
        </div>
      )}
      <div className="flex" style={{ alignItems: "flex-start", marginTop: 8 }}>
        <input
          style={{ marginLeft: 10 }}
          type="checkbox"
          name="kiosk.enable_connected_kiosks"
          checked={timesheetSettings.kiosk?.enable_connected_kiosks}
          onChange={(e) =>
            handleChange({
              kiosk: {
                ...timesheetSettings.kiosk,
                enabled: !!timesheetSettings.kiosk?.enabled,
                enable_connected_kiosks: e.target.checked,
              },
            })
          }
        />
        <span
          style={{ marginLeft: 15 }}
        >{`Allow employees to manage their time clock from any kiosk. Note that this setting requires all kiosks to be connected to the internet.`}</span>
      </div>
    </div>
  );
};

export default KioskSettings;
