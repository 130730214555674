import { Company, PaySchedule } from "dashboard/miter";
import { DateTime } from "luxon";
import { CheckPayFrequency } from "backend/utils/check/check-types";

import { Option } from "ui/form/Input";
import { WORK_HOURS_IN_WEEK } from "miter-utils";
import { getWorkWeeksInYear } from "./utils";

export const isWeeklyOrBiweekly = (freq: string | undefined | null): freq is "weekly" | "biweekly" => {
  return freq === "weekly" || freq === "biweekly";
};

export const payFrequencyLookup: Record<CheckPayFrequency, string> = {
  weekly: "Weekly",
  biweekly: "Bi-weekly",
  semimonthly: "Semi-monthly",
  monthly: "Monthly",
  quarterly: "Quarterly",
  annually: "Yearly",
};

export const frequencyOptions: Option<CheckPayFrequency>[] = Object.entries(payFrequencyLookup).map(
  ([value, label]) => ({ value: value as CheckPayFrequency, label })
);

export const validPayScheduleFreqOptions = frequencyOptions.filter((o) => {
  return isWeeklyOrBiweekly(o.value) || o.value === "semimonthly" || o.value === "monthly";
});

export const getPayFrequencyWeeks = (company: Company, payFrequency: CheckPayFrequency): number => {
  if (payFrequency === "annually") return getWorkWeeksInYear(company);
  else if (payFrequency === "quarterly") return getWorkWeeksInYear(company) / 4;
  else if (payFrequency === "monthly") return getWorkWeeksInYear(company) / 12;
  else if (payFrequency === "semimonthly") return getWorkWeeksInYear(company) / 24;
  else if (payFrequency === "biweekly") return 2;
  else if (payFrequency === "weekly") return 1;
  throw new Error(`Invalid pay schedule frequency: ${payFrequency}`);
};

export const getPayFrequencyHours = (company: Company, payFrequency: CheckPayFrequency): number => {
  return getPayFrequencyWeeks(company, payFrequency) * WORK_HOURS_IN_WEEK;
};

export type PayPeriod = {
  periodStart: string;
  periodEnd: string;
};

/** These functions partially recreate the behavior of Check's pay schedule "GET paydays" endpoint, but it's just about the pay period start/end at this time */
export const getPayPeriodsOfPaySchedule = (
  paySchedule: PaySchedule,
  startDt: DateTime,
  endDt: DateTime
): PayPeriod[] => {
  console.debug("enddt", endDt);
  console.debug("startDt", startDt);
  if (endDt < startDt) return [];
  const freq = paySchedule.check_pay_schedule.pay_frequency;

  if (isWeeklyOrBiweekly(freq)) {
    return getPayPeriodsOfWeeklyOrBiweeklyPaySchedule(paySchedule, startDt, endDt);
  } else if (freq === "semimonthly") {
    return getPayPeriodsOfSemimonthlyPaySchedule(paySchedule, startDt, endDt);
  } else if (freq === "monthly") {
    return getPayPeriodsOfMonthlyPaySchedule(paySchedule, startDt, endDt);
  }
  throw new Error(`Pay frequency ${freq} not implemented`);
};

const getPayPeriodsOfWeeklyOrBiweeklyPaySchedule = (
  paySchedule: PaySchedule,
  startDt: DateTime,
  endDt: DateTime
): PayPeriod[] => {
  const firstPeriodEnd = DateTime.fromISO(paySchedule.check_pay_schedule.first_period_end);

  const periodEndDoW = firstPeriodEnd.weekday;
  let lastPeriodEnd = endDt.plus({ days: (7 + periodEndDoW - endDt.weekday) % 7 });

  const isBiweekly = paySchedule.check_pay_schedule.pay_frequency === "biweekly";

  if (isBiweekly) {
    const daysSinceFirstPeriodEnd = Math.floor(lastPeriodEnd.diff(firstPeriodEnd, "days").days);
    const inMiddleOfBiweeklyPeriod = daysSinceFirstPeriodEnd % 14 !== 0;
    if (inMiddleOfBiweeklyPeriod) {
      lastPeriodEnd = lastPeriodEnd.plus({ week: 1 });
    }
  }

  let periodEnd = lastPeriodEnd;
  const periods: PayPeriod[] = [];
  console.debug("periodend", periodEnd);
  console.debug("startdt", startDt);
  while (periodEnd.toISODate() >= startDt.toISODate()) {
    periods.push({
      periodStart: periodEnd.minus({ days: isBiweekly ? 13 : 6 }).toISODate(),
      periodEnd: periodEnd.toISODate(),
    });
    periodEnd = periodEnd.minus({ week: isBiweekly ? 2 : 1 });
  }

  return periods.reverse();
};

const getPayPeriodsOfSemimonthlyPaySchedule = (
  paySchedule: PaySchedule,
  startDt: DateTime,
  endDt: DateTime
): PayPeriod[] => {
  const firstPeriodEnd = DateTime.fromISO(paySchedule.check_pay_schedule.first_period_end);
  const firstPayday = DateTime.fromISO(paySchedule.check_pay_schedule.first_payday);
  const firstPaydayDay = firstPayday.day;

  // This difference between the period end and the payday is CONSTANT between all period ends and their respective NAIVE paydays (i.e., what the payday would be if it were not a weekend or holiday, though it's still restricted by the last day of the month)
  // This logic only applies to semimonthly pay schedules. For other frequencies, you can just plus/minus 1 week, 2 weeks, 1 month, etc. to get the next period end.
  const firstPaydayGap = firstPayday.diff(firstPeriodEnd, "days").days;

  let secondPayday: DateTime,
    isOn15thAndLastDay = true;
  if (paySchedule.check_pay_schedule.second_payday) {
    secondPayday = DateTime.fromISO(paySchedule.check_pay_schedule.second_payday);
    isOn15thAndLastDay = false;
  } else if (firstPaydayDay === 15) {
    secondPayday = firstPayday.set({ day: firstPayday.daysInMonth });
  } else {
    secondPayday = firstPayday.set({ day: 15 }).plus({ month: 1 });
  }

  const secondPaydayDay = secondPayday.day;
  const firstPaydayIsLOM = isOn15thAndLastDay && secondPaydayDay === 15;
  const secondPaydayIsLOM = isOn15thAndLastDay && firstPaydayDay === 15;

  const firstBeforeSecond = firstPaydayDay < secondPaydayDay;
  const lastDayOfFinalMonth = endDt.daysInMonth;
  const firstPaydayDayOfMonth = Math.min(firstPaydayDay, lastDayOfFinalMonth);
  const secondPaydayDayOfMonth = Math.min(secondPaydayDay, lastDayOfFinalMonth);

  // Let's figure out the next naive payday that has a pay period end AFTER today
  // Just loop through days until we find one that satisfies the condition
  let nextOne = endDt.minus({ days: 1 }),
    onFirst = false;
  // onFirst refers to whether we're on a "first" payday or a "second" payday, since both are specified (implicitly or explicitly) on a semimonthly payroll
  while (true) {
    nextOne = nextOne.plus({ days: 1 });
    const periodEnd = nextOne.minus({ days: firstPaydayGap });
    if (periodEnd < endDt) continue;

    if (isOn15thAndLastDay) {
      if (nextOne.day === 15) {
        if (firstPaydayDay === 15) {
          onFirst = true;
        }
        break;
      }
      if (nextOne.day === lastDayOfFinalMonth) {
        if (secondPaydayDay === 15) {
          onFirst = true;
        }
        break;
      }
    } else {
      if (nextOne.day === firstPaydayDayOfMonth) {
        onFirst = true;
        break;
      }
      if (nextOne.day === secondPaydayDayOfMonth) {
        break;
      }
    }
  }

  // Here's the gist:
  // Loop through naive paydays, which refers to payday that would happen if it were not a weekend or holiday, though it's still restricted by the last day of the month
  // Period end for that payroll is determined by the delta between the first_payday and the first_period_end

  let naivePayday = nextOne;
  const periodEnds: DateTime[] = [];
  while (true) {
    const periodEnd = naivePayday.minus({ days: firstPaydayGap });
    periodEnds.push(periodEnd);
    // Add one more than necessary, so we can calculate period start. That's why we break afterwards.
    if (periodEnd < startDt) break;

    if (onFirst) {
      // If we're currently dealing with a first_payday type, that means the next naive payday is a second_payday type
      // If the first_payday day of the month comes before hte second_payday day of the month, then we need to go back a full month since we'll be setting the actual day to a larger number
      naivePayday = naivePayday.minus({ months: firstBeforeSecond ? 1 : 0 });
      // If the second payday ends on the last of the month, then we need to manually set to the final month's last day, otherwise use the naive payday (restricted by days in the month)
      naivePayday = naivePayday.set({
        day: secondPaydayIsLOM ? naivePayday.daysInMonth : Math.min(secondPaydayDay, naivePayday.daysInMonth),
      });
      onFirst = false;
    } else {
      // Similar logic to the above, but reversed
      naivePayday = naivePayday.minus({ months: firstBeforeSecond ? 0 : 1 });
      naivePayday = naivePayday.set({
        day: firstPaydayIsLOM ? naivePayday.daysInMonth : Math.min(firstPaydayDay, naivePayday.daysInMonth),
      });
      onFirst = true;
    }
  }

  const periods: PayPeriod[] = [];
  for (let i = 0; i < periodEnds.length - 1; i++) {
    periods.push({
      periodStart: periodEnds[i + 1]!.plus({ days: 1 }).toISODate(),
      periodEnd: periodEnds[i]!.toISODate(),
    });
  }

  return periods.reverse();
};

const getPayPeriodsOfMonthlyPaySchedule = (
  paySchedule: PaySchedule,
  startDt: DateTime,
  endDt: DateTime
): PayPeriod[] => {
  const firstPeriodEnd = DateTime.fromISO(paySchedule.check_pay_schedule.first_period_end);
  const lastDayOfFinalMonth = endDt.daysInMonth;
  const periodEndFinalMonth = Math.min(firstPeriodEnd.day, lastDayOfFinalMonth);

  let periodEnd = endDt.plus({ months: endDt.day <= periodEndFinalMonth ? 0 : 1 });
  periodEnd = periodEnd.set({ day: Math.min(firstPeriodEnd.day, periodEnd.daysInMonth) });

  const periodEnds: DateTime[] = [];
  while (true) {
    periodEnds.push(periodEnd);
    // Add one more than necessary, so we can calculate period start. That's why we break afterwards.
    if (periodEnd < startDt) break;

    periodEnd = periodEnd.minus({ months: 1 });
    periodEnd = periodEnd.set({ day: Math.min(firstPeriodEnd.day, periodEnd.daysInMonth) });
  }

  const periods: PayPeriod[] = [];
  for (let i = 0; i < periodEnds.length - 1; i++) {
    periods.push({
      periodStart: periodEnds[i + 1]!.plus({ days: 1 }).toISODate(),
      periodEnd: periodEnds[i]!.toISODate(),
    });
  }

  return periods.reverse();
};
