import { AggregatedLiveTimesheet, LiveTimesheet, MiterAPI } from "dashboard/miter";
import React, { FC, useMemo, useState } from "react";
import { Badge, Button, Formblock, Input, Label, Notifier } from "ui";
import person from "dashboard/assets/user.png";
import {
  creationMethodBadgeLookup,
  cropBreaks,
  handleTmClick,
  LiveTimesheetErrorMessage,
} from "dashboard/utils/timesheetUtils";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { JobInput } from "dashboard/components/shared/JobInput";
import {
  useActiveLiveTimesheetUpdateLocal,
  useJobOptions,
  useSetActiveLiveTimesheetUpdateLocal,
} from "dashboard/hooks/atom-hooks";
import { useActiveCompany, useLookupTimeOffPolicy } from "dashboard/hooks/atom-hooks";
import { earningTypeLookup } from "dashboard/pages/payrolls/viewPayroll/viewPayrollUtils";
import AdvancedBreakTimeForm, {
  Breaks,
  validateAdvancedBreaks,
} from "dashboard/components/break-time/AdvancedBreakTimeForm";
import { convertTimeToSeconds } from "miter-utils";
import { UpdateLiveTimesheetParams } from "backend/services/live-timesheet-service";
import { validateClockTimes } from "dashboard/utils/timesheetUtils";
import Banner from "dashboard/components/shared/Banner";

type Props = {
  ts: AggregatedLiveTimesheet;
  hide: (refretch?: true) => void;
};

const LiveTimesheetDetails: FC<Props> = ({ ts, hide }) => {
  const lookupTimeOffPolicy = useLookupTimeOffPolicy();
  const activeCompany = useActiveCompany();
  const tsSettings = activeCompany?.settings?.timesheets;
  const breakTypesObject = activeCompany?.settings.timesheets.break_types || {};
  const usesConnectedKiosks = tsSettings?.kiosk?.enable_connected_kiosks;
  const [clockingOut, setClockingOut] = useState(false);
  const [clockOutErrorMessage, setClockOutErrorMessage] = useState<string | undefined>();
  const [clockOutWarningMessage, setClockOutWarningMessage] = useState<string | undefined>();
  const [updatedBreaks, setUpdatedBreaks] = useState<LiveTimesheet["breaks"]>();
  const [editingBreak, setEditingBreak] = useState(false);
  const [breakErrorMessage, setBreakErrorMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [breakLoading, setBreakLoading] = useState(false);
  const [cancelBreaks, setCancelBreaks] = useState(false);
  const jobOptions = useJobOptions();
  const clockIn = DateTime.fromSeconds(ts!.clock_in);
  const [updatedClockIn, setUpdatedClockIn] = useState<number>(ts!.clock_in); // validated clock in time to save to the live timesheet
  const [newClockIn, setNewClockIn] = useState<number | null>(ts!.clock_in); // clock in time that is displayed in the formblock
  const liveTimesheetUpdate = useActiveLiveTimesheetUpdateLocal();
  const setActiveLiveTimesheetUpdate = useSetActiveLiveTimesheetUpdateLocal();
  const [liveTimesheetUpdateParams, setLiveTimesheetUpdateParams] = useState<UpdateLiveTimesheetParams>();

  const [clockOutDt, setClockoutDt] = useState<DateTime | null>(DateTime.now());
  const [clockOutTimeString, setClockOutTimeString] = useState<string | undefined>(
    clockOutDt?.toFormat("HH:mm")
  );
  const form = useForm({ shouldUnregister: false });
  let earningTypeText = "Auto (recommended)";
  if (ts.time_off_policy_id) {
    earningTypeText = lookupTimeOffPolicy(ts.time_off_policy_id)?.name || "-";
  } else if (ts.earning_type) {
    earningTypeText = earningTypeLookup[ts.earning_type] || "-";
  }

  // Ensure there's no issues with magnitude, given change we made to the mobile app code where break start/end used to be tracked in milliseconds and now are tracked in seconds
  const liveTimesheetBreaks: LiveTimesheet["breaks"] = useMemo(() => {
    return (
      (ts?.breaks || [])
        // do not display ongoing breaks
        .filter((b) => !!b.end)
        .map((b) => {
          const validatedStart = convertTimeToSeconds(b.start);
          const validatedEnd = b.end ? validatedStart + b.duration : undefined;
          return {
            ...b,
            start: validatedStart,
            end: validatedEnd,
          };
        })
    );
  }, [ts.breaks]);
  const ongoingBreak = useMemo(() => {
    return ts?.breaks?.find((b) => !b.end) || null;
  }, [ts.breaks]);
  const isOnBreak = !!ongoingBreak;

  // only make the breaks editable if the user is not on break and the company uses connected kiosks
  const allowEditingLiveTimesheet = !isOnBreak && usesConnectedKiosks && ts?.created_via_connected_kiosk;

  const clockOut = async () => {
    if (!clockOutDt) {
      Notifier.error("Please enter a clock out time.");
      return;
    }
    setLoading(true);
    try {
      if (liveTimesheetUpdateParams) {
        const updateRes = await MiterAPI.live_timesheets.update(ts._id, liveTimesheetUpdateParams);
        if (updateRes.error) throw Error(updateRes.error);
      }
      const resp = await MiterAPI.live_timesheets.clock_out(ts._id, clockOutDt.toSeconds());
      if (resp.error) throw Error(resp.error);
      if (!!ts.created_via_connected_kiosk) {
        Notifier.success(
          "Clocked out successfully. Employee must discard the stale time session from the mobile app."
        );
      } else {
        Notifier.success("Clocked out successfully.");
      }
      if (ts.creation_method === "dashboard_clock_in") {
        if (liveTimesheetUpdate?.key === ts?.team_member?._id) {
          setActiveLiveTimesheetUpdate({
            key: liveTimesheetUpdate.key,
            value: (liveTimesheetUpdate?.value || 0) + 1,
          });
        }
      }
      hide(true);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setLoading(false);
  };

  const createNewDt = (dt: DateTime, timeString?: string) => {
    const newDtObj = { ...dt.toObject(), second: 0, millisecond: 0 };
    if (timeString) {
      const [hour, minute] = timeString.split(":").map((a) => Number(a));
      if (hour || hour === 0) newDtObj.hour = hour;
      if (minute || minute === 0) newDtObj.minute = minute;
    }
    return DateTime.fromObject(newDtObj);
  };

  const handleBreakTimeChange = (newBreaks: Breaks, formClockIn: number | null) => {
    try {
      validateAdvancedBreaks(
        newBreaks,
        formClockIn ? DateTime.fromSeconds(formClockIn) : clockIn,
        DateTime.now(),
        breakTypesObject
      );
      const liveTimesheetNewBreaks: LiveTimesheet["breaks"] = [];
      for (const b of newBreaks) {
        if (!b.start) {
          setBreakErrorMessage("Break start time is required");
          return;
        }
        liveTimesheetNewBreaks.push({
          start: b.start,
          end: b.end,
          duration: b.duration || 0,
          break_type_id: b.break_type_id,
        });
      }
      setUpdatedBreaks(liveTimesheetNewBreaks);
      setUpdatedClockIn(formClockIn ? formClockIn : ts.clock_in);
    } catch (e: $TSFixMe) {
      setBreakErrorMessage(e.message);
      return;
    }
    setBreakErrorMessage(undefined);
  };

  const saveBreaks = async () => {
    setBreakLoading(true);
    if (!updatedBreaks) {
      setBreakLoading(false);
      setBreakErrorMessage("Breaks are required.");
      return;
    }
    try {
      validateAdvancedBreaks(
        updatedBreaks,
        DateTime.fromSeconds(updatedClockIn),
        DateTime.now(),
        breakTypesObject
      );
      const updateRes = await MiterAPI.live_timesheets.update(ts._id, {
        breaks: updatedBreaks.concat(ongoingBreak || []),
        clock_in: updatedClockIn,
      });
      if (updateRes.error) throw Error(updateRes.error);
      setEditingBreak(false);
    } catch (e: $TSFixMe) {
      Notifier.error(e.message);
    }
    setBreakErrorMessage(undefined);
    setBreakLoading(false);
  };

  const onClockOutChange = async (dt: DateTime | null, timeString?: string) => {
    setClockOutTimeString(timeString);
    if (!dt) {
      setClockoutDt(null);
      return;
    }
    const newClockOut = createNewDt(dt, timeString).setZone(clockIn.zoneName, { keepLocalTime: true });

    if (newClockOut.toSeconds() === clockOutDt?.toSeconds()) return;
    const errorMessage: LiveTimesheetErrorMessage | null = validateClockTimes(
      clockIn,
      newClockOut,
      liveTimesheetBreaks
    );
    if (errorMessage?.type === "error") {
      setClockOutErrorMessage(errorMessage.message);
      setClockOutWarningMessage(undefined);
    } else if (errorMessage?.type === "warning" || isOnBreak) {
      setClockOutErrorMessage(undefined);
      setClockOutWarningMessage(errorMessage?.message);
      const croppedBreaks = cropBreaks(newClockOut, liveTimesheetBreaks.concat(ongoingBreak || []));
      // there should be no ongoing break in the cropped breaks
      setLiveTimesheetUpdateParams({
        breaks: croppedBreaks,
        active_break_time: 0,
        active_break_start: 0,
      });
    } else {
      setClockOutErrorMessage(undefined);
      setClockOutWarningMessage(undefined);
    }
    if (newClockOut) setClockoutDt(newClockOut);
  };

  return (
    <div>
      {isOnBreak && usesConnectedKiosks && ts?.created_via_connected_kiosk && (
        <Banner
          type="warning"
          content="This employee is on break. Clock in and break times cannot be edited while the employee is on break."
          style={{
            marginTop: "-35px",
            width: "115%",
            marginLeft: "-40px",
            borderBottomLeftRadius: "0",
            borderBottomRightRadius: "0",
            borderTop: "none",
            marginBottom: "20px",
          }}
        />
      )}
      <div className="width-100" style={{ overflow: "visible" }}>
        <div className="payment-active-view-header flex space-between">
          <div className="">Details</div>
          {!clockingOut ? (
            <Button text="Set clock out" onClick={() => setClockingOut(true)} />
          ) : (
            <div className="flex">
              <Button text="Cancel" onClick={() => setClockingOut(false)} />
              <Button
                text="Clock out"
                onClick={clockOut}
                loading={loading}
                className="button-2"
                disabled={!!clockOutErrorMessage || !clockOutDt}
              />
            </div>
          )}
        </div>
        <Formblock type="text" locked label="Date">
          <span>{DateTime.fromSeconds(ts.created_at).toLocaleString(DateTime.DATETIME_MED)}</span>
        </Formblock>
        <Formblock type="text" locked label="Team member">
          <div className="tm-link" onClick={() => handleTmClick(ts?.team_member?._id)}>
            <img className="tm-link-img" src={person} />
            <span>{ts?.team_member.full_name}</span>
          </div>
        </Formblock>
        <Formblock type="text" locked label="Activity">
          <span>{ts?.activity?.label || "-"}</span>
        </Formblock>
        <JobInput
          value={{ label: ts.job?.name || "", value: ts.job?._id }}
          defaultValue={ts.job?._id}
          label="Job"
          editing={false}
          type="select"
          options={jobOptions}
        />
        {ts?.creation_method && creationMethodBadgeLookup.hasOwnProperty(ts?.creation_method) && (
          <Formblock type="text" locked label="Creation method">
            <Badge
              text={creationMethodBadgeLookup[ts?.creation_method].label}
              color={creationMethodBadgeLookup[ts?.creation_method].color}
              style={{ margin: 0 }}
              className="no-margin"
            />
          </Formblock>
        )}
        {tsSettings?.always_display_earning_types && earningTypeText && (
          <Formblock type="text" locked label="Earning type">
            <span>{earningTypeText}</span>
          </Formblock>
        )}
        {
          <div className="flex space-between" style={{ paddingTop: "25px" }}>
            <Formblock
              type="datetime"
              label="Clock in"
              editing={editingBreak}
              defaultValue={DateTime.fromSeconds(updatedClockIn)}
              value={!!newClockIn ? DateTime.fromSeconds(newClockIn) : null}
              customFormat={"MMM d, yyyy 'at' h:mm:ss a ZZZZ"}
              max={DateTime.now()}
              onChange={(formClockInTime) => {
                handleBreakTimeChange(updatedBreaks || liveTimesheetBreaks, formClockInTime?.toSeconds());
                setNewClockIn(!!formClockInTime ? formClockInTime.toSeconds() : null);
              }}
              style={{ width: "450px", marginBottom: "0px" }}
              labelStyle={{ width: "290px" }}
            />
            {allowEditingLiveTimesheet &&
              (!editingBreak ? (
                <Button
                  text="Edit times"
                  onClick={() => {
                    setEditingBreak(true);
                    setCancelBreaks(false);
                  }}
                />
              ) : (
                <div className="flex">
                  <Button
                    text="Cancel"
                    onClick={() => {
                      setUpdatedBreaks(liveTimesheetBreaks);
                      setEditingBreak(false);
                      setCancelBreaks(true);
                      setBreakErrorMessage(undefined);
                    }}
                  />
                  <Button
                    text="Save times"
                    onClick={saveBreaks}
                    loading={breakLoading}
                    className="button-2"
                    disabled={!!breakErrorMessage || !newClockIn}
                  />
                </div>
              ))}
          </div>
        }
        {
          <div>
            <div style={{ color: "#505050", width: "175px" }}>{"Breaks"}</div>
            <Controller
              name={"breaks"}
              control={form.control}
              render={({ onChange }) => (
                <div style={{ marginTop: "7px" }}>
                  <AdvancedBreakTimeForm
                    editing={editingBreak && allowEditingLiveTimesheet}
                    onChange={(newValue) => {
                      onChange(newValue);
                      handleBreakTimeChange(newValue, newClockIn);
                    }}
                    defaultBreaks={liveTimesheetBreaks}
                    mode="update"
                    clockIn={DateTime.fromSeconds(ts!.clock_in)}
                    clearNewBreaks={cancelBreaks}
                  />
                </div>
              )}
            />
            {editingBreak && breakErrorMessage && (
              <div style={{ color: "red", marginTop: "-10px", fontSize: 14 }}>{breakErrorMessage}</div>
            )}
          </div>
        }
        {clockingOut && (
          <>
            <div className="formblock-wrapper">
              <Label label="Clock out" />
              <div className="flex" style={{ alignItems: "flex-start", width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <Input
                    type="datetime"
                    defaultValue={clockOutDt}
                    control={form.control}
                    onChange={(e) => onClockOutChange(e, clockOutTimeString)}
                    name="clock_out"
                    dateOnly={true}
                    className="modal"
                  />
                </div>
                <div style={{ width: 20 }}></div>
                <div style={{ width: "100%" }}>
                  <Input
                    type="time"
                    defaultValue={clockOutDt?.toFormat("HH:mm")}
                    register={form.register}
                    onChange={(e) => onClockOutChange(clockOutDt, e.target.value)}
                    name="clock_out_time"
                    className="modal"
                  />
                </div>
              </div>
            </div>
            {clockOutErrorMessage && (
              <div className="flex" style={{ paddingLeft: 175, marginBottom: 10 }}>
                <div style={{ color: "red", fontSize: 14, marginTop: 5 }}>{clockOutErrorMessage}</div>
              </div>
            )}
            {clockOutWarningMessage && (
              <div className="flex" style={{ paddingLeft: 175, marginBottom: 10 }}>
                <div style={{ color: "black", fontSize: 14, marginTop: 5 }}>{clockOutWarningMessage}</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LiveTimesheetDetails;
