import { ReactElement } from "react";
import { useState } from "react";
import { Button } from "ui";
import { AuditLogHistoryModal } from "./AuditLogHistoryModal";
import { AuditLogType } from "dashboard/utils/audit-log-utils";

type UseAuditLogHistory = {
  renderAuditLogHistoryButton: () => ReactElement;
  renderAuditLogHistoryModal: () => ReactElement;
};

export const useAuditLogHistory = (opts: {
  itemId: string;
  itemType: AuditLogType;
  refreshCounter: number;
  show: boolean;
}): UseAuditLogHistory => {
  const { itemId, itemType, refreshCounter } = opts;
  const [showModal, setShowModal] = useState(false);

  const renderAuditLogHistoryButton = () => {
    return <Button onClick={() => setShowModal(true)}>History</Button>;
  };

  const renderAuditLogHistoryModal = () => {
    if (!showModal) return <></>;

    return (
      <AuditLogHistoryModal
        itemId={itemId}
        type={itemType}
        onHide={() => setShowModal(false)}
        refreshCounter={refreshCounter}
      />
    );
  };

  return { renderAuditLogHistoryButton, renderAuditLogHistoryModal };
};
