import React, { useMemo } from "react";
import InfoButton from "dashboard/components/information/information";
import { Notifier, roundTo } from "dashboard/utils";
import { useDebouncedCallback } from "use-debounce";
import { TimesheetSettings } from "./TimesheetSettings";
import Select from "react-select";
import { selectStyles } from "ui/form/styles";
import { BreakTypes, TimesheetPolicyConfig } from "dashboard/miter";
import { AutomaticBreakTimeSettings } from "dashboard/miter";

type Props = {
  updateTimesheetSettings:
    | ((newFields: Partial<TimesheetSettings>) => void)
    | ((newFields: Partial<TimesheetPolicyConfig>) => void);
  automaticBreakTimeSettings: AutomaticBreakTimeSettings;
  breakTypes: BreakTypes | undefined;
  advancedBreakTime: boolean | undefined;
  isPolicySettings?: boolean;
};

export const AutomaticBreakTime: React.FC<Props> = ({
  updateTimesheetSettings,
  automaticBreakTimeSettings,
  breakTypes,
  advancedBreakTime,
  isPolicySettings,
}) => {
  const breakTypeOptions = useMemo(() => {
    return Object.entries(breakTypes || {})
      .filter(([_id, breakType]) => !breakType.archived)
      .map(([id, breakType]) => ({ label: breakType.label, value: id }));
  }, [breakTypes]);

  const defaultThreshold = roundTo((automaticBreakTimeSettings?.for_timesheets_longer_than || 0) / 3600);

  const defaultAutoAdd = roundTo((automaticBreakTimeSettings?.auto_add_break || 0) / 3600);

  const defaultAddAfter =
    automaticBreakTimeSettings?.add_after_hour != null
      ? roundTo(automaticBreakTimeSettings?.add_after_hour / 3600)
      : undefined;

  const handleCheckboxChange = async (e) => {
    const newValue = e.target.checked
      ? {
          for_timesheets_longer_than: 0,
          auto_add_break: 0,
        }
      : null;
    updateTimesheetSettings({ automatic_break_time: newValue });
  };

  const handleDisableForContractorsChange = async (e) => {
    const newSetting = { ...automaticBreakTimeSettings };
    newSetting["disable_for_contractors"] = e.target.checked;
    updateTimesheetSettings({
      automatic_break_time: newSetting as TimesheetSettings["automatic_break_time"],
    });
  };

  const handleDisableForManualTimesheetsChange = async (e) => {
    const newSetting = { ...automaticBreakTimeSettings };
    newSetting["disable_for_manual_timesheets"] = e.target.checked;
    updateTimesheetSettings({
      automatic_break_time: newSetting as TimesheetSettings["automatic_break_time"],
    });
  };

  const handleSelectChange = async (value: string | undefined) => {
    const newSetting = { ...automaticBreakTimeSettings };
    newSetting["break_type_id"] = value;
    updateTimesheetSettings({
      automatic_break_time: newSetting as TimesheetSettings["automatic_break_time"],
    });
  };

  const handleInputChange = async (e) => {
    const newSetting = { ...automaticBreakTimeSettings };
    if (isNaN(Number(e.target.value))) {
      Notifier.error("Please enter a valid number.");
      return;
    }

    newSetting[e.target.name] = Number(e.target.value) * 3600;

    // we want to make sure user cannot auto add breaks after the timesheet end
    // user is specifying a for_timesheets_longer_than that is less than the add_after_hour value
    const userWantsBreakAddedToTimesheetsShorterThanAddAfter =
      e.target.name === "for_timesheets_longer_than" &&
      newSetting.add_after_hour &&
      newSetting.for_timesheets_longer_than &&
      newSetting[e.target.name] < newSetting.add_after_hour;
    // user is specifying an add_after_hour that is after the for_timesheets_longer_than value
    const userWantsToAddBreakAfterTimesheetEnd =
      e.target.name === "add_after_hour" &&
      newSetting.add_after_hour &&
      newSetting.for_timesheets_longer_than &&
      newSetting[e.target.name] > newSetting.for_timesheets_longer_than &&
      newSetting.for_timesheets_longer_than;

    if (userWantsBreakAddedToTimesheetsShorterThanAddAfter || userWantsToAddBreakAfterTimesheetEnd) {
      if (!newSetting.add_after_hour || !newSetting.for_timesheets_longer_than) return;
      Notifier.error(
        "You specified that breaks should be added at hour " +
          newSetting.add_after_hour / 3600 +
          " for timesheets longer than " +
          newSetting.for_timesheets_longer_than / 3600 +
          " hours. Please adjust the values so breaks are added within the timesheet duration."
      );
      return;
    }

    if (newSetting.for_timesheets_longer_than == null || newSetting.auto_add_break == null) {
      return;
    }

    if (newSetting.for_timesheets_longer_than < newSetting.auto_add_break) {
      Notifier.error("Break time cannot exceed gross timesheet duration");
      return;
    }
    updateTimesheetSettings({
      automatic_break_time: newSetting as TimesheetSettings["automatic_break_time"],
    });
  };

  const handleInputChangeDebounced = useDebouncedCallback((e) => {
    handleInputChange(e);
  }, 300);

  const settingsDescription = isPolicySettings
    ? "Add custom automatic break time settings for this policy. If selected, this will override automatic break time settings for timesheets that this policy is applied to."
    : `Add break time automatically to employees' timesheets above a certain duration, upon timesheet creation. When this setting is enabled, break time cannot be edited from the mobile app.`;

  return (
    <div className={!isPolicySettings ? "billing-card-wrapper" : undefined}>
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: isPolicySettings ? 16 : 18 }}>Automatic break time</div>
        <InfoButton text="These settings determine how break time is added to timesheets for employees." />
      </div>

      <div style={{ color: "rgb(51,51,51)", marginTop: 10 }}>
        <div className="flex" style={{ marginTop: 5, alignItems: "flex-start" }}>
          <input
            style={{ marginLeft: 0 }}
            type="checkbox"
            checked={automaticBreakTimeSettings ? true : false}
            onChange={handleCheckboxChange}
          />
          <span style={{ marginLeft: 7 }}>{settingsDescription}</span>
        </div>
        {automaticBreakTimeSettings && (
          <div style={{ marginTop: 15 }}>
            <div className="flex">
              <span>
                <b> Enable for: </b>For timesheets spanning more than
              </span>
              <input
                className="form2-text"
                style={{ width: 50, margin: "0px 8px" }}
                onChange={handleInputChangeDebounced}
                name="for_timesheets_longer_than"
                defaultValue={defaultThreshold}
              />
              <span>{`hour(s), add `}</span>
              <input
                className="form2-text"
                style={{ width: 50, margin: "0px 8px" }}
                onChange={handleInputChangeDebounced}
                name="auto_add_break"
                defaultValue={defaultAutoAdd}
              />
              <span>hour(s) of break time.</span>
            </div>
          </div>
        )}
        {automaticBreakTimeSettings && (
          <div className="flex">
            <input
              style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}
              type="checkbox"
              checked={automaticBreakTimeSettings?.disable_for_contractors ? true : false}
              onChange={handleDisableForContractorsChange}
            />
            <span style={{ marginLeft: 7 }}>{`Disable automatic break time for contractors`}</span>
          </div>
        )}
        {automaticBreakTimeSettings && (
          <>
            <input
              style={{ marginLeft: 0, marginTop: 10, marginBottom: 10 }}
              type="checkbox"
              checked={automaticBreakTimeSettings?.disable_for_manual_timesheets ? true : false}
              onChange={handleDisableForManualTimesheetsChange}
            />
            <span
              style={{ marginLeft: 7 }}
            >{`Only add automatic break time to timesheets created through clock in / clock out.`}</span>
          </>
        )}
        {advancedBreakTime && automaticBreakTimeSettings && (
          <>
            <h3 className="settings-subheader">Advanced automatic break time</h3>
            <div className="flex" style={{ marginBottom: 10 }}>
              <span>Instead of unpaid break time, add the break as a single&nbsp;&nbsp;</span>
              <Select
                name="break_type_id"
                options={breakTypeOptions}
                width="200px"
                height="32px"
                // @ts-expect-error React select typescript issue
                onChange={(option) => handleSelectChange(option?.value)}
                styles={selectStyles}
                isClearable={true}
                menuPlacement={"bottom"}
                placeholder={"Type"}
                defaultValue={breakTypeOptions.find(
                  (option) => option.value === automaticBreakTimeSettings?.break_type_id
                )}
              />
              <span>&nbsp;&nbsp;break&nbsp;</span>
              <input
                className="form2-text"
                style={{ width: 50, margin: "0px 8px" }}
                onChange={handleInputChangeDebounced}
                name="add_after_hour"
                defaultValue={defaultAddAfter}
              />
              <span>{`hour${defaultAddAfter === 1 ? "" : "s"} after clock in.`}</span>
            </div>
            <span>{`The duration of this break and the timesheets for which it will be added are still determined by the details specified under "Enable for" above.`}</span>
          </>
        )}
      </div>
    </div>
  );
};
