import { useActiveCompanyId, useFormTemplates, useRefetchFormTemplates } from "dashboard/hooks/atom-hooks";
import { AggregatedFormTemplate } from "dashboard/types/form-types";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { Plus, Trash } from "phosphor-react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { FormTemplateWizard } from "./FormTemplateWizard";
import { Notifier } from "dashboard/utils";
import { MiterAPI } from "dashboard/miter";
import { useJobPostings, useLookupFormTemplate } from "dashboard/hooks/atom-hooks";
import { joinWithAnd } from "miter-utils";

export const FormTemplatesTable: FC = () => {
  const formTemplates = useFormTemplates();
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const refetchFormTemplates = useRefetchFormTemplates();
  const companyId = useActiveCompanyId();
  const [selectedRows, setSelectedRows] = useState<AggregatedFormTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<AggregatedFormTemplate>();
  const jobPostings = useJobPostings();
  const lookupFormTemplate = useLookupFormTemplate();

  const formTemplateColumns: ColumnConfig<AggregatedFormTemplate>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        dataType: "string" as const,
      },
      {
        field: "updated_at",
        headerName: "Last edited",
        dateType: "timestamp",
        dataType: "date" as const,
      },
    ],
    []
  );

  const closeWizard = () => {
    setIsWizardOpen(false);
    setSelectedTemplate(undefined);
  };

  const rowClick = (row: AggregatedFormTemplate) => {
    setSelectedTemplate(row);
    setIsWizardOpen(true);
  };

  const createFormTemplate = useCallback(async () => {
    try {
      if (!companyId) {
        throw new Error("Company ID is not set");
      }
      const newFormTemplate = await MiterAPI.form_templates.create({
        company_id: companyId,
        module: "recruiting",
        archived: false,
        name: "New Form Template",
      });
      if (newFormTemplate.error) {
        throw new Error(newFormTemplate.error);
      }
      setSelectedTemplate(newFormTemplate);
      setIsWizardOpen(true);
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to create form template: ${e.message}`);
    }
  }, [companyId]);

  const deleteFormTemplates = useCallback(async () => {
    try {
      if (!companyId) {
        throw new Error("Company ID is not set");
      }

      const ids = selectedRows.map((row) => row._id);
      const jobPostingFormTemplateIdsSet = new Set(
        jobPostings
          .filter(
            (jobPosting): jobPosting is typeof jobPosting & { question_form_template: { _id: string } } => {
              return !!jobPosting.question_form_template;
            }
          )
          .map((jobPosting) => jobPosting.question_form_template._id)
      );

      const idsWithAssociatedJobPostings = ids.filter((id) => jobPostingFormTemplateIdsSet.has(id));
      const idsWithoutAssociatedJobPostings = ids.filter((id) => !jobPostingFormTemplateIdsSet.has(id));

      if (idsWithAssociatedJobPostings.length > 0) {
        const namesOfTemplatesWithAssociatedJobPostings = idsWithAssociatedJobPostings
          .map((id) => lookupFormTemplate(id)?.name)
          .filter((name): name is string => !!name);
        Notifier.warning(
          `Cannot delete template${
            namesOfTemplatesWithAssociatedJobPostings.length > 1 ? "s" : ""
          }: ${joinWithAnd(
            namesOfTemplatesWithAssociatedJobPostings
          )} as they are associated with existing job postings.`
        );
      }

      const res = await MiterAPI.form_templates.archive({
        company_id: companyId,
        ids: idsWithoutAssociatedJobPostings,
      });

      if (res.error) {
        throw res.error;
      }
      setSelectedRows([]);
      refetchFormTemplates();
      if (idsWithoutAssociatedJobPostings.length > 0) {
        Notifier.success("Form templates deleted!");
      }
    } catch (e: $TSFixMe) {
      Notifier.error(`Failed to delete form templates: ${e.message}`);
    }
  }, [companyId, selectedRows, jobPostings, refetchFormTemplates, lookupFormTemplate]);

  const staticActions = useMemo(() => {
    return [
      {
        label: "Create template",
        action: createFormTemplate,
        important: true,
        className: "button-2 table-button",
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [createFormTemplate]);

  const dynamicActions = useMemo(() => {
    if (selectedRows.length === 0) return [];
    return [
      {
        label: "Delete",
        action: deleteFormTemplates,
        className: "button-3 table-button",
        icon: <Trash weight="bold" style={{ marginRight: 3 }} />,
      },
    ];
  }, [selectedRows.length, deleteFormTemplates]);

  return (
    <div>
      <TableV2
        id="form-templates-table"
        columns={formTemplateColumns}
        data={formTemplates}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        resource="form templates"
        onSelect={setSelectedRows}
        hideSecondaryActions={true}
        onClick={rowClick}
        hideFooter={true}
      />
      {isWizardOpen && selectedTemplate ? (
        <FormTemplateWizard onExit={closeWizard} formTemplate={selectedTemplate} onComplete={closeWizard} />
      ) : null}
    </div>
  );
};
