/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { PureAbility, AbilityBuilder, AbilityClass, ClaimRawRule } from "@casl/ability";
import { AggregatedJob, AggregatedTeamMember, PermissionGroup, TeamMember, Job } from "dashboard/miter";
import { cloneDeep, merge, pickBy } from "lodash";
import { flattenKeys } from "miter-utils";
import {
  useActiveCompanyId,
  useJobs,
  useSessionPermissionGroups,
  useTeam,
  useUser,
} from "dashboard/hooks/atom-hooks";
import { User } from "dashboard/miter";
import { MITER_COMPANY_ID } from "dashboard/utils";
import {
  PERMISSION_GROUP_SCOPE_MODULES,
  useTeamMemberGroupUnfurler,
} from "dashboard/hooks/abilities-hooks/useTeamMemberScopes";

import { useJobGroupUnfurler } from "dashboard/hooks/abilities-hooks/useJobScopes";
import {
  PermissionGroupModuleScopeType,
  JobScopeGroup,
  TeamMemberScopeGroup,
} from "backend/models/permission-group";
import { AggregatedJobPosting, JobPosting } from "dashboard/types/ats";
import { useJobPostingGroupUnfurler } from "./useJobPostingScopes";

export type MiterAbility = PureAbility<string>;
const MiterAbility = PureAbility as AbilityClass<MiterAbility>;

export type ScopedTeamMembersMap = {
  [appModule: string]: {
    list: AggregatedTeamMember[];
    ids: string[];
    idsSet: Set<string>;
  };
};

export type ScopedJobsMap = {
  [appModule: string]: {
    list: AggregatedJob[];
    ids: string[];
    idsSet: Set<string>;
  };
};

export type ScopedJobPostings = {
  ids: string[];
  idsSet: Set<string>;
};

export type HasScopedTeamMember = (
  appModule: PermissionGroupModuleScopeType,
  teamMember: AggregatedTeamMember | TeamMember | string | null | undefined
) => boolean;

export type HasScopedJob = (
  appModule: PermissionGroupModuleScopeType,
  job: string | AggregatedJob | Job | undefined
) => boolean;

export type HasScopedJobPosting = (
  jobPosting: AggregatedJobPosting | JobPosting | string | null | undefined
) => boolean;

export type HydratedPermissionGroup = {
  permissionGroupId: string;
  permissionGroup: PermissionGroup;
  abilities: MiterAbility;
  scopedTeamMembers: ScopedTeamMembersMap;
  scopedJobs: ScopedJobsMap;
  hasScopedTeamMember: HasScopedTeamMember;
  hasScopedJob: HasScopedJob;
  moduleHelpers: {
    recruiting: {
      hasScopedJobPosting: HasScopedJobPosting;
      scopedJobPostings: ScopedJobPostings;
    };
  };
};

export type HydratedPermissionGroups = HydratedPermissionGroup[];

export type PermissionGroupHydrator = () => HydratedPermissionGroups;

const getJobScopes = (
  group: PermissionGroup,
  appModule: PermissionGroupModuleScopeType
): JobScopeGroup[] | null => {
  if (!group.scopes) return null;

  // @ts-expect-error fix me
  const jobScopes = group.scopes?.[appModule]?.job || group.scopes?.global?.job || [];
  return jobScopes;
};

const getTeamMemberScopes = (
  group: PermissionGroup,
  appModule: PermissionGroupModuleScopeType
): TeamMemberScopeGroup[] | null => {
  if (!group.scopes) return null;

  // @ts-expect-error fix me
  const teamMemberScopes = group.scopes?.[appModule]?.team_member || group.scopes?.global?.team_member || [];
  return teamMemberScopes;
};

/**
 * Hook that hydrates the permission groups with the the following pieces of data
 * - The CASL abilities for the user
 * - The team members that the user has access to based on the permission group scopes
 * - The jobs that the user has access to based on the permission group scopes
 * - Functions to check if a user has access to a specific team member or job
 *
 * This hook is used as a helper to build each of the abilities hooks for the individual modules
 * */
export const usePermissionGroupHydrator = (): PermissionGroupHydrator => {
  const sessionPermissionGroups = useSessionPermissionGroups();
  const activeUser = useUser();
  const activeCompanyId = useActiveCompanyId();
  const teamMembersList = useTeam();
  const jobList = useJobs();

  const unfurlTeamMemberGroup = useTeamMemberGroupUnfurler();
  const unfurlJobGroup = useJobGroupUnfurler();
  const unfurlJobPostingGroup = useJobPostingGroupUnfurler();

  const buidlHydratedPermissionGroups = useCallback(() => {
    // If we don't have the active user or the active company, we can't build the abilities so we return an empty array
    if (!activeUser || !activeCompanyId) return [];

    // Hydrate the permission groups with the abilities and the scoped team members and jobs
    const hydratedPermissionGroups = sessionPermissionGroups.map((group) => {
      const abilities = buildUpdatedAbilities([group], activeUser, activeCompanyId);

      const scopedTeamMembers = PERMISSION_GROUP_SCOPE_MODULES.reduce((acc, appModule) => {
        const teamMemberScopes = getTeamMemberScopes(group, appModule);
        const teamMembers = unfurlTeamMemberGroup(teamMemberScopes);

        // Doing this in one pass to avoid having to loop through the team members twice
        const { teamMemberIds, teamMemberIdsSet } = teamMembers.reduce(
          (acc, tm) => {
            const teamMemberId = tm._id;
            acc.teamMemberIds.push(teamMemberId);
            acc.teamMemberIdsSet.add(teamMemberId);

            return acc;
          },
          { teamMemberIds: [] as string[], teamMemberIdsSet: new Set<string>() }
        );

        return {
          ...acc,
          [appModule]: {
            list: teamMembers,
            ids: teamMemberIds,
            idsSet: teamMemberIdsSet,
          },
        };
      }, {} as ScopedTeamMembersMap);

      const scopedJobs = PERMISSION_GROUP_SCOPE_MODULES.reduce((acc, appModule) => {
        const jobScopes = getJobScopes(group, appModule);
        const jobs = unfurlJobGroup(jobScopes);

        // Doing this in one pass to avoid having to loop through the jobs twice
        const { jobIds, jobIdsSet } = jobs.reduce(
          (acc, job) => {
            const jobId = job._id;
            acc.jobIds.push(jobId);
            acc.jobIdsSet.add(jobId);

            return acc;
          },
          { jobIds: [] as string[], jobIdsSet: new Set<string>() }
        );

        return {
          ...acc,
          [appModule]: {
            list: jobs,
            ids: jobIds,
            idsSet: jobIdsSet,
          },
        };
      }, {} as ScopedJobsMap);

      const hasScopedTeamMember = (
        appModule: PermissionGroupModuleScopeType,
        teamMember: AggregatedTeamMember | TeamMember | string | null | undefined
      ) => {
        // If the module doesn't have scopes, then we can just return true
        if (!scopedTeamMembers[appModule]) return true;

        // If the user has access to all team members, then we can just return true
        if (scopedTeamMembers[appModule]?.list.length === teamMembersList.length) return true;

        // If the team member is not provided, then we can just return false
        if (!teamMember) return false;

        const teamMemberId = typeof teamMember === "string" ? teamMember : teamMember._id;
        return scopedTeamMembers[appModule]?.idsSet.has(teamMemberId) || false;
      };

      const hasScopedJob = (
        appModule: PermissionGroupModuleScopeType,
        job: string | AggregatedJob | Job | undefined
      ) => {
        // If the module doesn't have scopes, then we can just return true
        if (!scopedJobs[appModule]) return true;

        // If the user has access to all jobs, then we can just return true
        if (scopedJobs[appModule]?.list.length === jobList.length) return true;

        // If the job is not provided, then we can just return false
        if (!job) return false;

        const jobId = typeof job === "string" ? job : job._id;
        return scopedJobs[appModule]?.idsSet.has(jobId) || false;
      };

      /** Module specific scopes */

      const scopedJobPostingIds = unfurlJobPostingGroup(
        group.scopes?.modules?.recruiting?.job_postings || null
      ).map((jp) => jp._id);

      const scopedJobPostingIdsSet = new Set(scopedJobPostingIds);

      const hasScopedJobPosting = (
        jobPosting: string | AggregatedJobPosting | JobPosting | undefined | null
      ) => {
        // If no scopes are provided, then we can just return true
        if ((group.scopes?.modules?.recruiting?.job_postings || [])?.length === 0) return true;

        // If the job posting is not provided, then we can just return false
        if (!jobPosting) return false;

        const jobPostingId = typeof jobPosting === "string" ? jobPosting : jobPosting._id;

        return scopedJobPostingIdsSet.has(jobPostingId);
      };

      return {
        permissionGroupId: group._id,
        permissionGroup: group,
        abilities,
        scopedTeamMembers,
        scopedJobs,
        hasScopedTeamMember,
        hasScopedJob,
        moduleHelpers: {
          recruiting: {
            hasScopedJobPosting,
            scopedJobPostings: {
              ids: scopedJobPostingIds,
              idsSet: scopedJobPostingIdsSet,
            },
          },
        },
      };
    });

    return hydratedPermissionGroups;
  }, [
    sessionPermissionGroups,
    activeUser,
    activeCompanyId,
    unfurlTeamMemberGroup,
    unfurlJobGroup,
    unfurlJobPostingGroup,
    teamMembersList,
    jobList,
  ]);

  return buidlHydratedPermissionGroups;
};

/** Function that generates the abilities for a user from a set of permissions groups */
export const buildUpdatedAbilities = (
  permissionGroups: PermissionGroup[],
  activeUser: User | null,
  activeCompanyId: string | null
): MiterAbility => {
  const rules = buildUpdatedAbilityRules(permissionGroups, activeUser, activeCompanyId);
  return new MiterAbility(rules);
};

/** Function that generates the abilities for a role/team member from a set of permissions groups */
export const buildUpdatedAbilityRules = (
  permissionGroups: PermissionGroup[],
  activeUser: User | null,
  activeCompanyId: string | null
): ClaimRawRule<string>[] => {
  const { can, rules } = new AbilityBuilder<MiterAbility>(PureAbility);

  // If the user is in a super admin permission group or they are a miter admin, they can do everything
  const isSuperAdmin = permissionGroups.some((group) => group.super_admin);
  if (isSuperAdmin || (activeUser?.miter_admin && activeCompanyId !== MITER_COMPANY_ID)) {
    return buildSuperAdminRules(can, rules);
  }

  // Merge the permission group permissions into a single object - have to remove all falsy values first and then merge with lodash
  const permissionsList = permissionGroups.map((group) => pickBy(group.permissions, Boolean));
  const permissions: PermissionGroup["permissions"] = merge(cloneDeep(permissionsList));

  // Create the raw rules that we can load into the casl ability creator
  const rawRules = buildRuleList(permissions);
  rawRules.forEach((rule) => can(rule));

  return rules;
};

/** Function that generates the raw list of permission rules */
export const buildRuleList = (permissions: PermissionGroup["permissions"]): string[] => {
  const permissionsKeys = Object.keys(permissions);

  // Flatten the permissions object into a list of paths (i.e. "jobs:create" or "timesheets:personal:update")
  const permissionPaths = permissionsKeys.flatMap((key) => {
    const subPermissions = permissions[key];
    return flattenKeys(subPermissions, ":");
  });

  return permissionPaths;
};

/** Build rules for super admins */
export const buildSuperAdminRules = (
  can: AbilityBuilder<MiterAbility>["can"],
  rules: ClaimRawRule<string>[]
): ClaimRawRule<string>[] => {
  // @ts-expect-error - This is a special case where we want to allow super admins to do everything
  can("manage", "all");
  return rules;
};
