import CustomFieldsTable from "dashboard/components/custom-fields/CustomFieldsTable";

const TimesheetCustomFieldsSettings: React.FC = ({}) => {
  return (
    <div className="billing-card-wrapper">
      <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Custom fields</div>
      <CustomFieldsTable parentType="timesheet" />
    </div>
  );
};

export default TimesheetCustomFieldsSettings;
