import { Timesheet } from "dashboard/miter";
import React, { FC, useState } from "react";
import { Formblock, Label, Notifier } from "ui";
import styles from "./BreakTimeForm.module.css";

type Props = {
  editing?: boolean;
  defaultBreakTime?: Timesheet["unpaid_break_time"];
  onChange?: (unpaidBreakTime: number) => void;
  maxDuration?: number;
  mode?: "create" | "update";
};

const SimpleBreakTimeForm: FC<Props> = ({ editing, defaultBreakTime, onChange, maxDuration }) => {
  const { hours, minutes } = hoursAndMinutesFromSeconds(defaultBreakTime || 0);

  const [breakHours, setBreakHours] = useState<number | undefined>(hours);
  const [breakMinutes, setBreakMinutes] = useState<number | undefined>(minutes);

  const handleChange = (value: number, type: "hours" | "minutes") => {
    if (type === "hours") {
      const duration = value * 3600 + (breakMinutes || 0) * 60;
      if (!hasValidBreakTimeDuration(duration, maxDuration)) return;

      if (value > 23) {
        Notifier.error("Break time cannot be greater than 24 hours.");
        return;
      }

      setBreakHours(value != null ? value : undefined);
      onChange?.(duration);
    } else {
      const duration = (breakHours || 0) * 3600 + value * 60;
      if (!hasValidBreakTimeDuration(duration, maxDuration)) return;

      if (value > 59) return;

      setBreakMinutes(value != null ? value : undefined);
      onChange?.(duration);
    }
  };

  const renderText = () => {
    return (
      <>
        <Label className="modal" label="Unpaid Break Time" />
        <div>
          {breakHours ? breakHours + ` hour${breakHours > 1 ? "s" : ""}` : ""}
          {breakMinutes ? " " + breakMinutes + ` minute${breakMinutes > 1 ? "s" : ""}` : ""}
        </div>
      </>
    );
  };

  const renderForm = () => {
    return (
      <>
        <Formblock
          label="Hours"
          className="modal"
          type="number"
          value={breakHours}
          onChange={(e) => handleChange(parseInt(e.target.value), "hours")}
          disabled={!editing}
          placeholder="Hours"
          min={0}
          max={23}
          editing={editing}
          style={{ width: "49%" }}
          defaultValue={breakHours}
        />
        <Formblock
          label="Minutes"
          className="modal"
          type="number"
          value={breakMinutes}
          onChange={(e) => handleChange(parseInt(e.target.value), "minutes")}
          disabled={!editing}
          placeholder="Minutes"
          min={0}
          max={59}
          editing={editing}
          style={{ width: "49%" }}
          defaultValue={breakMinutes}
        />
      </>
    );
  };

  return (
    <>
      {!editing && renderText()}
      <div className={"flex space-between " + styles["simple-break-time"]}>{editing && renderForm()}</div>
    </>
  );
};

export default SimpleBreakTimeForm;

export const hoursAndMinutesFromSeconds = (seconds: number): { hours: number; minutes: number } => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
};

const hasValidBreakTimeDuration = (duration: number, maxDuration: number | undefined): boolean => {
  if (maxDuration && duration > maxDuration) {
    const { hours, minutes } = hoursAndMinutesFromSeconds(maxDuration);
    Notifier.error(`Break time cannot be greater than ${hours} hours and ${minutes} minutes.`);
    return false;
  }

  return true;
};
