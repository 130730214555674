// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useActiveCompany } from "dashboard/hooks/atom-hooks";
import { ColGroupDef } from "ag-grid-community";
import { JobApplication } from "dashboard/types/ats";
import { EEO_ETHNICITY_OPTIONS, EEO_GENDER_OPTIONS } from "dashboard/pages/team-members/forms/options";
import { useHasAccessToCollectDemographicInfo } from "dashboard/gating";
import { useJobApplicationAbilities } from "./useJobApplicationAbilities";
import { JobPosting } from "dashboard/types/ats";
type Props = {
  jobPosting?: JobPosting;
};

export const useJobApplicationEEOColumns = ({ jobPosting }: Props): ColGroupDef<JobApplication>[] => {
  const company = useActiveCompany();
  const enabledDemographicQuestions = !!company?.settings?.ats?.collect_eeo;
  const hasAccessToEEOColumns = useHasAccessToCollectDemographicInfo();
  const jobApplicationAbilities = useJobApplicationAbilities();

  if (
    !enabledDemographicQuestions ||
    !hasAccessToEEOColumns ||
    !jobApplicationAbilities.can("read_sensitive", jobPosting)
  ) {
    return [];
  }

  return [
    {
      headerName: "EEO",
      children: [
        {
          field: "demographic_info.ethnicity",
          headerName: "Ethnicity",
          dataType: "string",
          filter: "agSetColumnFilter",
          valueGetter: (params) => (params.data ? params.data?.demographic_info?.ethnicity : null),
          editorType: "select",
          cellEditorParams: {
            options: EEO_ETHNICITY_OPTIONS,
            isClearable: true,
          },
        },
        {
          field: "demographic_info.gender",
          headerName: "Gender",
          dataType: "string",
          displayType: "badge",
          filter: "agSetColumnFilter",
          colors: {
            Male: "light-gray",
            Female: "blue",
          },
          valueGetter: (params) => (params.data ? params.data?.demographic_info?.gender : null),
          editorType: "select",
          cellEditorParams: {
            options: EEO_GENDER_OPTIONS,
            isClearable: true,
          },
        },
        {
          field: "demographic_info.veteran_status",
          headerName: "Veteran status",
          dataType: "string",
          filter: "agSetColumnFilter",
          valueGetter: (params) => (params.data ? params.data?.demographic_info?.veteran_status : null),
        },
        {
          field: "demographic_info.marital_status",
          headerName: "Marital status",
          dataType: "string",
          displayType: "badge",
          filter: "agSetColumnFilter",
          colors: {
            Single: "blue",
            Married: "green",
            Separated: "light-gray",
            Divorced: "yellow",
            Widowed: "light-blue",
            "Prefer Not To Disclose": "gray",
          },
          valueGetter: (params) => (params.data ? params.data?.demographic_info?.marital_status : null),
        },
        {
          field: "demographic_info.disability_status",
          headerName: "Disability status",
          dataType: "string",
          displayType: "badge",
          filter: "agSetColumnFilter",
          colors: {
            "Yes, I have a disability (or previously had a disability)": "red",
            "No, I do not have a disability and have not had one in the past": "green",
            "Prefer Not To Disclose": "gray",
          },
          valueGetter: (params) => (params.data ? params.data?.demographic_info?.disability_status : null),
        },
      ],
    },
  ];
};
