import InfoButton from "dashboard/components/information/information";
import React, { useMemo, useState } from "react";
import { Toggler } from "ui";

import AutoCalculateOvertimeExplainer from "./autoCalculate/AutoCalculateOvertimeExplainer";
import PaymentContext from "./paymentContext";
import { isEarningAutoCalculateDisabled } from "dashboard/utils/adjustments";
import { useOtExemptUtils } from "dashboard/hooks/useOtExempt";

export const Overtime: React.FC = () => {
  const { payment, tm } = React.useContext(PaymentContext);
  const [toggleState, setToggleState] = useState<string>("0");
  const { isTmOtExempt } = useOtExemptUtils();

  const autoIsDisabled = isEarningAutoCalculateDisabled("hourly", payment.adjustment);

  const isExempt = isTmOtExempt(tm);

  const timesheetEarningLogs = useMemo(() => {
    return payment.auto_calculate_log?.filter((item) => item.type === "hourly") || [];
  }, [payment]);

  const togglerConfig = useMemo(() => {
    return timesheetEarningLogs.map((_log, index) => {
      return { label: `Week ${index + 1}`, path: index.toString() };
    });
  }, [timesheetEarningLogs]);

  const selectedLogItem = useMemo(() => {
    const selectedIndex = toggleState ? parseInt(toggleState) : 0;
    return timesheetEarningLogs[selectedIndex];
  }, [timesheetEarningLogs, toggleState]);

  const renderContent = () => {
    if (isExempt) {
      return <div className="yellow-text-container">This employee is overtime exempt.</div>;
    } else if (autoIsDisabled) {
      return (
        <div className="yellow-text-container">
          Auto-Calculate is disabled. Overtime hours and earnings must be input manually.
        </div>
      );
    } else if (timesheetEarningLogs.length) {
      return (
        <>
          {timesheetEarningLogs.length > 1 && (
            <>
              <Toggler
                className="modal-with-tabs"
                config={togglerConfig}
                toggle={setToggleState}
                active={toggleState}
              />
              <div className="vertical-spacer"></div>
            </>
          )}
          <AutoCalculateOvertimeExplainer logItem={selectedLogItem} />
        </>
      );
    } else {
      return (
        <div className="yellow-text-container">This team member has not earned overtime this pay period.</div>
      );
    }
  };

  return (
    <div>
      <div className="payment-active-view-header">
        <div className="">Overtime</div>
        <InfoButton text="Breakdown of auto-calculated overtime from timesheets. Excludes hours from (a) manually-added earnings and (b) OT-ineligible activities." />
        <div className="flex-1"></div>
      </div>
      {renderContent()}
    </div>
  );
};
