import { booleanCellRenderer } from "dashboard/components/agGridTable/agGridUtils";
import { UnionRateFringe } from "dashboard/miter";
import React, { useMemo, useState } from "react";
import { BasicModal, TableV2 } from "ui";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { ClassificationTableEntry, unionRateFringeLookup } from "./PayRateGroupModalUtils";
import { useLedgerAccountLabeler } from "dashboard/hooks/atom-hooks";
import { FringeModal } from "./PayRateGroupFringeModal";
import { baseSensitiveCompare } from "miter-utils";
import { INCLUDE_WITH_EARNING_ACCOUNT } from "dashboard/pages/accounting/accountingUtils";
import { benefitLabelLookup, benefitTypeColors } from "dashboard/pages/benefits/benefitsUtils";

type Props = {
  tableData: ClassificationTableEntry[];
  setTableData: React.Dispatch<React.SetStateAction<ClassificationTableEntry[]>>;
  setUnsavedData: React.Dispatch<React.SetStateAction<boolean>>;
  readonly?: boolean;
};

const fringeTypeLabelLookup = {
  non_taxable_contribution: "Non-taxable contribution",
  taxable_contribution: "Taxable contribution",
  post_tax_deduction: "Post-tax deduction",
  taxable_earning: "Cash earning",
  benefit_type_deduction: "Pre-tax deduction",
};

export type RateGroupFringeTableEntry = UnionRateFringe & {
  categoryLabel: string;
  expenseAccountLabel: string;
  liabilityAccountLabel: string;
  typeLabel: string;
  calculationMethodLabel: string;
  benefitTypeLabel?: string;
};

const getCalculationMethodLabel = (fringe: UnionRateFringe) => {
  if (fringe.dynamic_offset_eligible) return "Dynamic offset";
  if (fringe.calculation_method === "per_hour") return "$/hour";
  if (fringe.calculation_method === "percent") return "% of earnings";
  if (fringe.calculation_method === "per_week") return "$/week";
  if (fringe.calculation_method === "per_month_lump_sum") return "$/month (lump sum)";
  return "-";
};

const fringeTypeNumberMap: Record<UnionRateFringe["type"], number> = {
  non_taxable_contribution: 1,
  taxable_contribution: 2,
  taxable_earning: 3,
  post_tax_deduction: 4,
  benefit_type_deduction: 5,
};

export const PayRateGroupFringes: React.FC<Props> = ({
  tableData,
  setTableData,
  setUnsavedData,
  readonly,
}) => {
  const accountLabeler = useLedgerAccountLabeler();
  const [fringeBeingEdited, setFringeBeingEdited] = useState<RateGroupFringeTableEntry>();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedFringeGroupIds, setSelectedFringeGroupIds] = useState<string[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const colData = useMemo(() => {
    const fringeColData: RateGroupFringeTableEntry[] = [];
    for (const rate of tableData) {
      for (const fringe of rate.fringes) {
        if (fringeColData.some((c) => c._id === fringe.fringe_group_id)) continue;
        fringeColData.push({
          ...fringe,
          _id: fringe.fringe_group_id,
          expenseAccountLabel:
            fringe.expense_account_id === INCLUDE_WITH_EARNING_ACCOUNT
              ? "(include with associated earning)"
              : accountLabeler(fringe.expense_account_id),
          liabilityAccountLabel: accountLabeler(fringe.liability_account_id),
          typeLabel: fringeTypeLabelLookup[fringe.type],
          categoryLabel: unionRateFringeLookup[fringe.category],
          calculationMethodLabel: getCalculationMethodLabel(fringe),
          benefitTypeLabel: fringe.check_benefit_type
            ? benefitLabelLookup[fringe.check_benefit_type]
            : undefined,
        });
      }
    }
    return fringeColData.sort((a, b) => {
      const aType = fringeTypeNumberMap[a.type];
      const bType = fringeTypeNumberMap[b.type];
      const tD = aType - bType;
      if (tD) return tD;

      if (a.dynamic_offset_eligible && !b.dynamic_offset_eligible) return 1;
      if (!a.dynamic_offset_eligible && b.dynamic_offset_eligible) return -1;

      return baseSensitiveCompare(a.label, b.label);
    });
  }, [tableData, accountLabeler]);

  const prgHasDynamicOffset = useMemo(() => {
    for (const rate of tableData) {
      for (const fringe of rate.fringes) {
        if (fringe.dynamic_offset_eligible) return true;
      }
    }
    return false;
  }, [tableData]);

  const handleDelete = () => {
    if (readonly) return;

    const newData = tableData.map((row) => {
      return {
        ...row,
        fringes: row.fringes.filter((f) => !selectedFringeGroupIds.includes(f.fringe_group_id)),
        changed: true,
      };
    });
    setTableData(newData);
    setSelectedFringeGroupIds([]);
    setDeleteModal(false);
    setUnsavedData(true);
  };

  const defaultButtons: TableActionLink[] = useMemo(
    () => [
      {
        label: "+ New",
        className: "button-2 no-margin",
        action: () => setIsAdding(true),
        important: true,
        shouldShow: () => !readonly,
      },
    ],
    [readonly]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3",
        action: () => setDeleteModal(true),
        important: true,
        shouldShow: () => !readonly,
      },
    ],
    [readonly]
  );

  const handleHide = () => {
    setIsAdding(false);
    setFringeBeingEdited(undefined);
  };

  const fringeTableColumns = useMemo(() => {
    if (colData.some((c) => c.check_benefit_type)) return allFringeTableColumns;
    return allFringeTableColumns.filter((c) => c.field !== "benefitTypeLabel");
  }, [colData]);

  return (
    <div>
      <TableV2
        columns={fringeTableColumns}
        resource="pay rate group fringes"
        staticActions={defaultButtons}
        dynamicActions={dynamicActions}
        id="prg-fringe-table"
        data={colData}
        onSelect={(selected) => setSelectedFringeGroupIds(selected.map((r) => r._id))}
        onClick={(r) => setFringeBeingEdited(r)}
      />
      {(fringeBeingEdited || isAdding) && (
        <FringeModal
          onHide={handleHide}
          selectedFringe={fringeBeingEdited}
          prgHasDynamicOffset={prgHasDynamicOffset}
          setTableData={setTableData}
          setUnsavedData={setUnsavedData}
        />
      )}
      {deleteModal && (
        <BasicModal
          headerText="Delete fringes?"
          button2Action={handleDelete}
          button2Text="Yes, delete"
          button1Text="Cancel"
          button1Action={() => setDeleteModal(false)}
        >
          <div className="red-text-container">{`Are you sure you want to delete the selected fringe(s)?`}</div>
        </BasicModal>
      )}
    </div>
  );
};

const allFringeTableColumns: ColumnConfig<RateGroupFringeTableEntry>[] = [
  {
    headerName: "Label",
    field: "label",
  },
  {
    headerName: "Type",
    field: "typeLabel",
    displayType: "badge",
    colors: {
      "Post-tax deduction": "green",
      "Pre-tax deduction": "lightgray",
      "Cash earning": "gray",
      "Non-taxable contribution": "blue",
      "Taxable contribution": "orange",
    },
  },
  {
    headerName: "Benefit type",
    field: "benefitTypeLabel",
    displayType: "badge",
    colors: benefitTypeColors,
  },
  {
    headerName: "Category",
    field: "categoryLabel",
  },
  {
    headerName: "Calculation",
    field: "calculationMethodLabel",
  },
  {
    headerName: "GL expense account",
    field: "expenseAccountLabel",
  },
  {
    headerName: "GL liability account",
    field: "liabilityAccountLabel",
  },
  {
    headerName: "OT eligible",
    field: "ot_multipliers",
    cellRenderer: booleanCellRenderer,
    valueGetter: (params) => {
      const otMult = params.data?.ot_multipliers;
      return (otMult?.ot && otMult.ot > 1) || (otMult?.dot && otMult.dot > 1);
    },
  },
  {
    headerName: "Bonafide",
    field: "non_bonafide",
    cellRenderer: booleanCellRenderer,
    valueGetter: (params) => !params.data?.non_bonafide,
    headerTooltip: "Whether this fringe should offset the fringe rate",
  },
];
