import React, { useEffect, useMemo, useState } from "react";
import { useActiveCompanyId } from "../../hooks/atom-hooks";
import { TableV2, ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import { AggregatedCardProgram, MiterAPI, MiterFilterArray } from "../../miter";
import { Notifier } from "ui/notifier";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { connectExternalCards } from "dashboard/utils/expenses";
import { Link } from "phosphor-react";
import PlaidLink from "dashboard/components/banking/PlaidLink";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import { ArrowsIn } from "phosphor-react";
import CombineCardProgramsModal from "./modals/CombineCardProgramsModal";
import LinkNewCardModal from "./modals/LinkNewCardModal";

export type CardProgramTableRow = {
  _id: string;
  dateAdded: number;
  issuer?: string;
  name?: string;
  type: "third_party_card" | "miter_card";
  number_of_cards: number;
};

const CardProgramTable: React.FC = () => {
  const activeCompanyId = useActiveCompanyId();
  const { can, cannot } = useMiterAbilities();

  const [cardPrograms, setCardPrograms] = useState<CardProgramTableRow[]>();
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [refreshCounter, setRefreshCounter] = useState<number>(0);

  const [selectedRows, setSelectedRows] = useState<CardProgramTableRow[]>([]);

  // link new card modal
  const [isLinkCardModalOpen, setIsLinkCardModalOpen] = useState(false);

  // Plaid linking
  const [plaidLinkToken, setPlaidLinkToken] = useState<null | string>(null);

  // merge card program modal
  const [mergeCardProgramModal, setMergeCardProgramModal] = useState(false);

  useEffect(() => {
    const getCardPrograms = async () => {
      if (!activeCompanyId) return;
      setIsTableLoading(true);

      try {
        const filter: MiterFilterArray = [{ field: "company_id", value: activeCompanyId }];

        // remove programs the user doesn't have access to
        if (cannot("third_party_cards:read")) {
          filter.push({ field: "type", value: "third_party_card", comparisonType: "ne" });
        }
        if (cannot("miter_cards:read")) {
          filter.push({ field: "type", value: "miter_card", comparisonType: "ne" });
        }

        const programs =
          can("third_party_cards:read") || can("miter_cards:read")
            ? await MiterAPI.expenses.card_programs.list({ filter })
            : [];

        const tableRows: CardProgramTableRow[] = programs.map((program: AggregatedCardProgram) => {
          return {
            _id: program._id,
            dateAdded: program.created_at,
            name: program.name,
            issuer: program.financial_institution_name,
            type: program.type,
            number_of_cards: program.number_of_cards,
          };
        });

        setCardPrograms(tableRows);
      } catch (e: $TSFixMe) {
        console.error(e.message);
        Notifier.error(e.message);
      }
      setIsTableLoading(false);
    };

    getCardPrograms();
  }, [activeCompanyId, can, cannot, refreshCounter]);

  const columns: ColumnConfig<CardProgramTableRow>[] = useMemo(() => {
    return [
      {
        headerName: "Date added",
        field: "dateAdded",
        dataType: "date",
        dateType: "iso",
        sort: "desc",
      },
      {
        headerName: "Issuer",
        field: "issuer",
        dataType: "string",
      },
      {
        headerName: "Name",
        field: "name",
        dataType: "string",
      },
      {
        field: "number_of_cards",
        headerName: "Number of cards",
        dataType: "number",
      },
    ];
  }, []);

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Combine programs",
        className: "button-1 no-margin table-button",
        action: () => setMergeCardProgramModal(true),
        icon: <ArrowsIn weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () =>
          selectedRows.length > 1 && selectedRows.every((row) => row.type === "third_party_card"), // can only combine third party card programs
      },
    ],
    [selectedRows]
  );

  const staticActions: TableActionLink[] = [
    {
      label: "Link a new credit card",
      className: "button-1",
      action: async () => setIsLinkCardModalOpen(true),
      important: true,
      icon: <Link weight="bold" style={{ marginRight: 5 }} />,
      shouldShow: () => can("third_party_cards:create"),
    },
  ];

  return (
    <>
      <TableV2
        id="card-program-management-table"
        resource="card programs"
        data={cardPrograms}
        columns={columns}
        paginationPageSize={50}
        isLoading={isTableLoading}
        rowHeight={70}
        rowLinkBuilder={(row) => `/spend/cards/programs/${row?._id}`}
        showReportViews={true}
        staticActions={staticActions}
        dynamicActions={dynamicActions}
        onSelect={setSelectedRows}
      />
      {plaidLinkToken && (
        <PlaidLink
          token={plaidLinkToken}
          onSuccess={(public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
            // close modal
            setIsLinkCardModalOpen(false);

            // send new cards to backend
            connectExternalCards({
              company: activeCompanyId || undefined,
              public_token,
              metadata,
              updateMode: false,
              callback: () => setRefreshCounter(refreshCounter + 1),
            });
          }}
          onExit={() => setPlaidLinkToken(null)}
        />
      )}
      {mergeCardProgramModal && (
        <CombineCardProgramsModal
          selectedRows={selectedRows}
          onHide={() => setMergeCardProgramModal(false)}
          onSuccess={() => {
            setRefreshCounter(refreshCounter + 1);
            setMergeCardProgramModal(false);
          }}
        />
      )}
      {isLinkCardModalOpen && (
        <LinkNewCardModal
          onHide={(shouldRefresh?: boolean) => {
            // if new Astrada card was linked, refresh
            if (shouldRefresh) setRefreshCounter(refreshCounter + 1);

            setIsLinkCardModalOpen(false);
          }}
          setPlaidLinkToken={setPlaidLinkToken}
        />
      )}
    </>
  );
};

export default CardProgramTable;
