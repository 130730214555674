import React, { ReactElement } from "react";
import { TeamMember } from "dashboard/miter";

export const createNotOnboardedNotification = (tm: TeamMember, draft: boolean): string | ReactElement => {
  const handleClick = () => {
    const win = window.open("/team-members/" + tm._id, "_blank");
    win?.focus();
  };

  if (draft) {
    return (
      <div>
        <span>{`${tm.full_name}'s onboarding is incomplete.`}</span>&nbsp;
        <span className="blue-link" onClick={handleClick}>
          {"Visit their profile to learn more."}
        </span>
      </div>
    );
  } else {
    return tm.full_name + "'s onboarding was incomplete for this payroll run.";
  }
};
