import React, { useEffect, useState } from "react";
import { AggregatedTeamMember, IntegrationConnection, MiterAPI } from "dashboard/miter";
import DataBox from "dashboard/components/dataBox/DataBox";
import { useTeamAbilities } from "dashboard/hooks/abilities-hooks/useTeamAbilities";
import { ActionModal, Formblock, Loader, Notifier } from "ui";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import { useRefetchTeam } from "dashboard/hooks/atom-hooks";
import { getTmIntacctLocationId } from "../TeamUtils";
import { useFetchIntacctObjects } from "dashboard/hooks/integrations/sage_intacct/useFetchIntacctObjects";

export const IntacctEmployeeFields: React.FC<{ tm: AggregatedTeamMember; ic: IntegrationConnection }> = (
  props
) => {
  const { tm, ic } = props;
  const teamAbilities = useTeamAbilities();

  const [showEditModal, setShowEditModal] = useState(false);

  const locationId = getTmIntacctLocationId(tm);

  // (mchaudhry05): we are just returning for null and leaving the code
  // here in case we want to re-use for classifications
  return null;

  return (
    <>
      {showEditModal && <EditTmIntacctLocationModal tm={tm} ic={ic} onHide={() => setShowEditModal(false)} />}
      <DataBox
        title="Intacct fields"
        className="no-margin"
        onEdit={teamAbilities.can("update", tm) ? () => setShowEditModal(true) : undefined}
      >
        <div className={"data-box-section"}>
          <span className={"data-box-section-title font-14"}>{`Location ID`}</span>
          <span className={"data-box-section-value font-14"}>{locationId || "-"}</span>
        </div>
      </DataBox>
    </>
  );
};

const EditTmIntacctLocationModal: React.FC<{
  ic: IntegrationConnection;
  tm: AggregatedTeamMember;
  onHide: () => void;
}> = (props) => {
  const { ic, tm, onHide } = props;
  const currentLocationId = getTmIntacctLocationId(tm);

  const form = useForm();
  const [saving, setSaving] = useState(false);
  const [locationOptions, setLocationOptions] = useState<Option<string>[]>();
  const [selectedLocation, setSelectedLocation] = useState<Option<string>>();
  const refetchTeam = useRefetchTeam();

  const saveLocation = async () => {
    try {
      if (!selectedLocation?.value || selectedLocation.value === currentLocationId) return;
      setSaving(true);
      const res = await MiterAPI.team_member.update(tm._id, { intacct_location_id: selectedLocation.value });
      if (res.error) throw new Error(res.error);

      await refetchTeam(res._id);
    } catch (err) {
      console.log("Error fetching Intacct locations:", err);
      Notifier.error("There was an issue fetching locations from Sage Intacct.");
    } finally {
      onHide();
      setSaving(false);
    }
  };

  const { loading } = useFetchIntacctObjects({
    integrationConnectionId: ic._id,
    objectTypes: ["locations"],
    onData: (intacctData) => {
      const opts = (intacctData.locations || []).map((l) => ({
        label: `${l.LOCATIONID} - ${l.NAME}`,
        value: l.LOCATIONID,
      }));
      setLocationOptions(opts);
    },
  });

  useEffect(() => {
    setSelectedLocation(() => {
      if (!locationOptions) return;
      const locationId = getTmIntacctLocationId(tm);
      if (!locationId) return;
      const location = locationOptions.find((l) => l.value === locationId);
      if (location) return location;
    });
  }, [locationOptions, tm]);

  const renderModalBody = () => {
    if (loading || !locationOptions) return <Loader />;
    return (
      <>
        <div className="vertical-spacer-medium" />
        <Formblock
          form={form}
          type="select"
          name={"location"}
          label={"Intacct Location"}
          labelInfo="Select the Intacct location you want to associate this team member with."
          editing={true}
          options={locationOptions}
          value={selectedLocation}
          onChange={(o) => setSelectedLocation(o)}
          className="modal"
        />
      </>
    );
  };

  return (
    <ActionModal
      headerText={`Edit ${tm.first_name}'s Intacct Location`}
      onHide={onHide}
      onSubmit={saveLocation}
      loading={saving}
      showSubmit={true}
    >
      {renderModalBody()}
    </ActionModal>
  );
};
