import { useActiveCompanyId, useUser } from "dashboard/hooks/atom-hooks";
import { MiterAPI, Note } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { DateTime } from "luxon";
import React, { FC, useState } from "react";
import { Button, ConfirmModal } from "ui";
import NoteProfileCircle from "./NoteProfileCircle";
import styles from "./Notes.module.css";
import { buildCompanyUserName } from "miter-utils";
import { CreateNoteParams } from "backend/services/note-service";
import { NoteParentType } from "backend/models/note";

type Props = {
  note?: Note;
  parentId: string;
  parentType: NoteParentType;
  onSave: (note: Note) => void;
  readonly?: boolean;
};

const NoteItem: React.FC<Props> = ({ note, parentId, parentType, onSave, readonly }) => {
  const activeCompanyId = useActiveCompanyId();
  const activeUser = useUser();

  const user = useUser();
  const [content, setContent] = useState(note?.content || "");
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(!note);
  const [showEditAndDelete, setShowEdit] = useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const authorName = buildCompanyUserName(user);

  const editNote = () => {
    setEditing(true);
  };

  const cancelNote = () => {
    if (note) {
      setContent(note.content);
      setEditing(false);
    } else {
      setContent("");
    }
  };

  const saveNote = async () => {
    if (!activeUser) return;
    setSaving(true);

    if (note) {
      await updateNote();
    } else {
      await createNote();
    }

    setSaving(false);
  };

  const createNote = async () => {
    try {
      if (!activeUser) throw new Error("No active user");

      const params: CreateNoteParams = {
        content: content,
        parent_id: parentId,
        parent_type: parentType,
        author_user_id: activeUser._id,
        author_name: authorName,
        company_id: activeCompanyId!,
      };

      const res = await MiterAPI.notes.create(params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Note saved.");

      setContent("");
      onSave(res);
    } catch (e: $TSFixMe) {
      console.error("Error creating note:", e);
      Notifier.error(e.message);
    }
    setSaving(false);
  };

  const updateNote = async () => {
    if (!note) return;

    try {
      const params = {
        content: content,
      };

      const res = await MiterAPI.notes.update(note._id, params);
      if (res.error) throw new Error(res.error);

      Notifier.success("Note saved.");
      setEditing(false);
      onSave(res);
    } catch (e: $TSFixMe) {
      console.error("Error updating note:", e);
      Notifier.error(e.message);
    }
  };

  const deleteNote = async () => {
    if (!note || !showDeleteConfirm) return;

    setDeleting(true);
    try {
      const res = await MiterAPI.notes.delete(note._id);
      if (res.error) throw new Error(res.error);

      Notifier.success("Note deleted.");
      onSave(res);
    } catch (e: $TSFixMe) {
      console.error("Error deleting note:", e);
      Notifier.error(e.message);
    }
    setDeleting(false);
  };

  const renderProfileCircle = () => {
    if (!user) return null;
    return <NoteProfileCircle authorName={note?.author_name || authorName} />;
  };

  const renderAuthorInfo = () => {
    if (!note) return;

    const createdAt = DateTime.fromSeconds(note.created_at).toLocaleString(DateTime.DATETIME_MED);
    const edited = note.updated_at > note.created_at;

    return (
      <div className={styles["note-author-info"]}>
        <p className={styles["note-author-name"]}>{note.author_name || authorName}</p>
        <p className={styles["note-created-at"]}>
          {createdAt}
          {edited && <span className={styles["note-edited"]}> &#40;Edited&#41;</span>}
        </p>
      </div>
    );
  };

  const renderDottedLine = () => {
    return <div className={styles["note-dotted-line"]} />;
  };

  const renderNoteInput = () => {
    return (
      <textarea
        className={"form2-paragraph " + styles["note-input"]}
        value={content}
        placeholder="Add a note..."
        onChange={(e) => setContent(e.target.value)}
        maxLength={10000}
        disabled={!editing || readonly}
        style={!editing || readonly ? { resize: "none" } : {}}
      />
    );
  };

  const renderNoteView = () => {
    return (
      <div className={styles["note-view"]}>
        <p className={styles["note-content"]}>{note?.content}</p>
      </div>
    );
  };

  const renderNote = () => {
    const contentChanged = note ? note.content !== content : content !== "";

    return (
      <div className={styles["note-content-container"]}>
        {renderAuthorInfo()}

        {editing ? renderNoteInput() : renderNoteView()}
        <div className={styles["note-actions"]}>
          {note && !readonly && !editing && note.author_user_id === activeUser?._id && (
            <>
              <Button
                className="button-1 no-margin"
                text="Edit"
                onClick={editNote}
                style={
                  !showEditAndDelete
                    ? { opacity: 0, transition: "0.3s ease", marginBottom: -25, marginRight: 5 }
                    : { opacity: 1, transition: "0.3s ease", marginBottom: -25, marginRight: 5 }
                }
              />
              <Button
                className="button-3 no-margin"
                text="Delete"
                onClick={() => setShowDeleteConfirm(true)}
                style={
                  !showEditAndDelete
                    ? { opacity: 0, transition: "0.3s ease", marginBottom: -25 }
                    : { opacity: 1, transition: "0.3s ease", marginBottom: -25 }
                }
                loading={deleting}
              />
            </>
          )}
          {(contentChanged || (note && editing && !readonly)) && (
            <>
              <Button
                className="button-2 no-margin"
                text="Save"
                onClick={saveNote}
                loading={saving}
                style={{ marginRight: 5 }}
              />
              <Button className="button-1  no-margin" text="Cancel" onClick={cancelNote} />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={styles["note-container"]}
        onMouseOver={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}
      >
        {renderDottedLine()}
        {renderProfileCircle()}
        {renderNote()}
      </div>
      {note && showDeleteConfirm && (
        <ConfirmModal
          title={"Delete note"}
          body={"Are you sure you want to delete this note?"}
          onYes={deleteNote}
          onNo={() => setShowDeleteConfirm(false)}
        />
      )}
    </>
  );
};

export default NoteItem;

type NewNoteProps = {
  parentId: string;
  parentType: NoteParentType;
  onCreate: (note: Note) => void;
  readonly?: boolean;
};

export const NewNote: FC<NewNoteProps> = ({ parentId, parentType, onCreate, readonly }) => {
  return (
    <div className={styles["new-note-container"]}>
      <NoteItem parentId={parentId} parentType={parentType} onSave={onCreate} readonly={readonly} />
    </div>
  );
};
