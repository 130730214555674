import React, { ReactNode, useState } from "react";

export const ClickableText: React.FC<{
  text?: string;
  onClick: () => Promise<void> | void;
  children?: ReactNode;
}> = ({ text, onClick, children }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };
  return (
    <span className={loading ? "blue-link inactive" : "blue-link"} onClick={handleClick}>
      {text || children}
    </span>
  );
};
