import React, { FC } from "react";
import styles from "./HTMLDocument.module.css";

type Props = {
  sanitizedHTML: string;
};
const HTMLDocument: FC<Props> = ({ sanitizedHTML }) => {
  return (
    <div className={styles["outer"]}>
      <div className={styles["inner"]} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
    </div>
  );
};

export default HTMLDocument;
