import { AggregatedPerformanceReview } from "dashboard/miter";
import React, { FC, useEffect, useState } from "react";
import { ActionModal, Toggler } from "ui";
import FormSubmissionAnswersTable from "../forms/FormSubmissionAnswersTable";
import { useLookupPerformanceReviewSchedule } from "dashboard/hooks/atom-hooks";

type Props = {
  performanceReview: AggregatedPerformanceReview;
  onExit: () => void;
};

export const EditPerformanceReviewModal: FC<Props> = ({ performanceReview, onExit }) => {
  const { self_review_submission, reviewer_submission } = performanceReview;
  const [view, setView] = useState("direct-report-review");
  const lookUpPerformance = useLookupPerformanceReviewSchedule();
  const reviewSchedule = lookUpPerformance(performanceReview.review_cycle_id);

  useEffect(() => {
    if (reviewSchedule?.self_review.enabled) {
      setView("self-review");
    } else {
      setView("direct-report-review");
    }
  }, [reviewSchedule]);

  const togglerConfig = [
    ...(self_review_submission ? [{ path: "self-review", label: "Self review" }] : []),
    ...(reviewer_submission ? [{ path: "direct-report-review", label: "Manager's review" }] : []),
  ];
  const formSubmission = view === "self-review" ? self_review_submission : reviewer_submission;
  const form =
    view === "self-review" ? reviewSchedule?.self_review_form : reviewSchedule?.direct_report_review_form;

  return (
    <ActionModal
      headerText={`Review of ${performanceReview.reviewee.full_name}`}
      showCancel={true}
      cancelText="Close"
      onCancel={onExit}
      onHide={onExit}
      wrapperStyle={{ width: "80%" }}
      loading={!reviewSchedule || !formSubmission}
    >
      <Toggler config={togglerConfig} active={view} toggle={setView} />
      {formSubmission && form && (
        <FormSubmissionAnswersTable
          form={form}
          formSubmission={formSubmission}
          resource="performance responses"
        />
      )}
    </ActionModal>
  );
};
