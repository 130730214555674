import { AggregatedTeamMember } from "dashboard/miter";
import { UserMinus, UserPlus } from "phosphor-react";
import React, { FC, useMemo, useState } from "react";
import { TableV2 } from "ui/table-v2";
import { ColumnConfig, TableActionLink } from "ui/table-v2/Table";
import BulkTeamMemberSelect, { BulkTeamMemberSelectTeamMember } from "../team-members/BulkTeamMemberSelect";
import { DateTime } from "luxon";
import { getTenureString } from "dashboard/utils";
import { usePerformanceReviewAbilities } from "dashboard/hooks/abilities-hooks/usePerformanceReviewAbilities";

type Props = {
  reviewees: AggregatedTeamMember[];
  onSubmit: (teamMembers: BulkTeamMemberSelectTeamMember[]) => void;
  onRemove: (teamMembers: AggregatedTeamMember[]) => void;
  selectedReviewees: AggregatedTeamMember[];
};

const columns: ColumnConfig<AggregatedTeamMember>[] = [
  {
    headerName: "Name",
    field: "full_name",
    dataType: "string",
  },
  {
    headerName: "Title",
    field: "title",
    dataType: "string",
  },
  {
    headerName: "Department",
    field: "department.name",
    dataType: "string",
  },
  {
    headerName: "Tenure",
    field: "tenure",
    dataType: "string",
  },
];

export const RevieweesTableAndSelector: FC<Props> = ({
  reviewees,
  onSubmit,
  onRemove,
  selectedReviewees,
}) => {
  const [showRevieweeSelector, setShowRevieweeSelector] = useState(false);
  const reviewAbilities = usePerformanceReviewAbilities();

  const finalizedRevieewes = useMemo(() => {
    return reviewees.map((reviewee) => {
      const canDelete = reviewAbilities.teamPredicate("delete")(reviewee);
      if (canDelete) return reviewee;

      return {
        ...reviewee,
        non_removable: true,
      };
    });
  }, [reviewees, reviewAbilities]);

  const staticActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Select reviewees",
        action: () => setShowRevieweeSelector(true),
        important: true,
        className: "button-2 no-margin table-button",
        icon: <UserPlus weight="bold" style={{ marginRight: 5 }} />,
      },
    ];
  }, []);

  const dynamicActions: TableActionLink[] = useMemo(() => {
    return [
      {
        label: "Remove from review cycle",
        action: onRemove,
        className: "button-1 no-margin table-button",
        icon: <UserMinus weight="bold" style={{ marginRight: 5 }} />,
      },
    ];
  }, [onRemove]);

  const onSubmitWithClose = (teamMembers: BulkTeamMemberSelectTeamMember[]) => {
    setShowRevieweeSelector(false);
    onSubmit(teamMembers);
  };

  const revieweesData = useMemo(() => {
    return reviewees.map((reviewee) => {
      return {
        ...reviewee,
        tenure: reviewee.start_date
          ? getTenureString(
              reviewee.end_date ? DateTime.fromISO(reviewee.end_date) : DateTime.now(),
              DateTime.fromISO(reviewee.start_date)
            )
          : "-",
      };
    });
  }, [reviewees]);

  return (
    <div>
      {}
      <TableV2
        id={"performance-review-cycle-reviewees-table"}
        resource="reviewees"
        data={revieweesData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        defaultSelectedRows={selectedReviewees}
        hideFooter={true}
        editable={false}
        hideSecondaryActions={true}
      />
      {showRevieweeSelector && (
        <BulkTeamMemberSelect
          title={`Enroll reviewees`}
          defaultTeamMembers={finalizedRevieewes}
          onHide={() => setShowRevieweeSelector(false)}
          submitText="Submit"
          onSubmit={onSubmitWithClose}
          predicate={reviewAbilities.teamPredicate("create")}
          disableTm={(tm) => {
            if (!tm.start_date) {
              return {
                status: true,
                reason: "Team member has no start date.",
              };
            }
            return { status: false };
          }}
        />
      )}
    </div>
  );
};
