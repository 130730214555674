import React from "react";
import Banner from "dashboard/components/shared/Banner";
import { MiterIntegrationForCompany } from "dashboard/miter";
import { useNavigate } from "react-router-dom";

interface TimesheetSourceSystemBannerProps {
  sourceSystem?: MiterIntegrationForCompany;
}

export const TimesheetSourceSystemBanner: React.FC<TimesheetSourceSystemBannerProps> = ({ sourceSystem }) => {
  const navigate = useNavigate();

  if (!sourceSystem) {
    return null;
  }

  return (
    <Banner
      style={{ borderBottom: "1px solid #d6d6d6" }}
      content=""
      type="modal"
      onClick={() => navigate(`/integrations/${sourceSystem.key}`)}
    >
      {" "}
      <div className="flex">
        Note: this timesheet was imported from&nbsp;
        <span className="blue-link">{sourceSystem.label}.</span>
      </div>
    </Banner>
  );
};
