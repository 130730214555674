import React, { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";

export type Crumb = {
  label: string;
  path: string;
};

type Props = {
  crumbs: Crumb[];
};

const Breadcrumbs: FC<Props> = ({ crumbs }) => {
  return (
    <div className={styles["breadcrumbs"]}>
      {crumbs.map((crumb, index) => (
        <div key={crumb.path} className={styles["crumb"]}>
          <Link to={crumb.path}>{crumb.label}</Link>
          {index < crumbs.length - 1 ? <span className={styles["divider"]}>{`>`}</span> : null}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;
