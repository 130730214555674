import React, { useState, useMemo, useEffect } from "react";

import { MiterAPI, WorkersCompGroup } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import { BasicModal } from "ui";

import { ColumnConfig, TableActionLink, TableV2 } from "ui/table-v2/Table";
import { Plus, TrashSimple } from "phosphor-react";
import { useActiveCompanyId, useRefetchWcGroups, useWcGroups } from "dashboard/hooks/atom-hooks";
import { useMiterAbilities } from "dashboard/hooks/abilities-hooks/useMiterAbilities";
import { useNavigate } from "react-router-dom";
import { ValueGetterParams } from "ag-grid-community";
import { useFailuresModal } from "dashboard/hooks/useFailuresModal";
import { WcGroupModal } from "./WcGroupModal";

type WCGroupRow = WorkersCompGroup;

const WcGroupsTable: React.FC = ({}) => {
  /*********************************************************
   *  Initialize states
   **********************************************************/
  const [loading, setLoading] = useState(false);
  const activeCompanyId = useActiveCompanyId();
  const { can, cannot } = useMiterAbilities();
  const { setFailures, renderFailuresModal } = useFailuresModal({ onClose: () => setFailures([]) });

  const navigate = useNavigate();
  const refetchWcGroups = useRefetchWcGroups();
  const wcGroups = useWcGroups();

  // States related to the table
  const [selectedRows, setSelectedRows] = useState<WCGroupRow[]>([]);

  // States related to table actions
  const [adding, setAdding] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const tableData = useMemo(() => {
    return wcGroups;
  }, [wcGroups]);

  useEffect(() => {
    refetchWcGroups();
  }, [activeCompanyId, refetchWcGroups]);

  /*********************************************************
   *  Handler functions that the table uses
   **********************************************************/

  const handleAdd = () => {
    setAdding(true);
  };

  const handleArchive = async () => {
    if (cannot("lists:workers_comp_codes:manage")) {
      Notifier.error("You do not have permission to delete workers' comp groups.");
      return;
    }

    setLoading(true);
    try {
      const archivedGroups: WorkersCompGroup[] = [];

      await Promise.all(
        selectedRows.map(async (wc_group) => {
          const response = await MiterAPI.wc_groups.update(wc_group._id, { archived: true });
          if (response.error) {
            console.error(response.error);
            Notifier.error(`Error deleting "${wc_group.name}"`);
          } else {
            Notifier.success(`Successfully deleted "${wc_group.name}"`);
            archivedGroups.push(response);
          }
        })
      );

      refetchWcGroups();
      setArchiving(false);
      setSelectedRows([]);
    } catch (e) {
      console.log(e);
      Notifier.error("There was an error deleting one or more groups. We're looking into it.");
    }
    setLoading(false);
  };

  /*********************************************************
    Config variables for the table
  **********************************************************/
  const staticActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Add group",
        className: "button-2 no-margin",
        action: handleAdd,
        important: true,
        icon: <Plus weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:workers_comp_codes:manage"),
      },
    ],
    [can]
  );

  const dynamicActions: TableActionLink[] = useMemo(
    () => [
      {
        label: "Delete",
        className: "button-3 no-margin table-button",
        action: () => setArchiving(true),
        icon: <TrashSimple weight="bold" style={{ marginRight: 3 }} />,
        shouldShow: () => can("lists:workers_comp_codes:manage"),
      },
    ],
    [can]
  );

  /*********************************************************
    Functions to render table components
  **********************************************************/

  const renderTable = () => {
    return (
      <TableV2
        id={"workers-comp-table"}
        resource="workers comp groups"
        data={tableData}
        columns={columns}
        dynamicActions={dynamicActions}
        staticActions={staticActions}
        onSelect={setSelectedRows}
        defaultSelectedRows={selectedRows}
        rowLinkBuilder={(row) => `/settings/workers-comp/groups/${row?._id}`}
        showReportViews={true}
      />
    );
  };

  return (
    <div className="wc-groups-table-wrapper">
      {renderFailuresModal()}
      {adding && (
        <WcGroupModal
          onHide={() => setAdding(false)}
          onSubmit={(wcGroup) => navigate(`/settings/workers-comp/groups/${wcGroup._id}`)}
        />
      )}

      {archiving && (
        <BasicModal
          loading={loading}
          button2Text="Delete"
          button1Action={() => setArchiving(false)}
          button1Text="Cancel"
          button2Action={handleArchive}
          headerText={"Delete group" + (selectedRows.length > 1 ? "s" : "")}
          bodyText={
            "Are you sure you want to delete the selected group" +
            (selectedRows.length > 1 ? "s" : "") +
            "? To restore a deleted group, you must contact Miter Support."
          }
        />
      )}

      {renderTable()}
    </div>
  );
};

export default WcGroupsTable;

const columns: ColumnConfig<WCGroupRow>[] = [
  {
    headerName: "Name",
    field: "name",
    dataType: "string",
  },
  {
    headerName: "Type",
    field: "type",
    dataType: "string",
    displayType: "badge",
    colors: {
      state_and_pay_rate: "blue",
      state: "green",
      job_and_cost_code: "purple",
      pay_rate: "orange",
    },
  },
  {
    headerName: "Mappings",
    field: "mappings",
    dataType: "number",
    valueGetter: (params: ValueGetterParams<WCGroupRow>) => params.data?.mappings.length || 0,
  },
];
