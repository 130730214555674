import React, { useContext, useState } from "react";
import { reportList } from "../../reportList";
import { useNavigate } from "react-router";
import PayrollContext from "dashboard/pages/payrolls/viewPayroll/payrollContext";
import { LegacyFringeReport } from "./LegacyFringeReport";
import { NewFringeReport } from "./NewFringeReport";
import Banner from "dashboard/components/shared/Banner";
import { ActionModal, Formblock } from "ui";
import { useForm } from "react-hook-form";
import { MiterAPI } from "dashboard/miter";
import { useActiveCompany, useBannerNotification, useUser } from "dashboard/hooks/atom-hooks";
import { BANNER_NOTIFICATION, SLACK_CHANNEL } from "dashboard/utils";

export const FringeReport: React.FC = () => {
  const { payroll } = useContext(PayrollContext);
  const form = useForm();
  const activeUser = useUser();
  const activeCompany = useActiveCompany();

  const [showLegacy, setShowLegacy] = useBannerNotification(BANNER_NOTIFICATION.LEGACY_FRINGE_REPORT);
  const [isSwitchingToLegacy, setIsSwitchingToLegacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSwitchToLegacy = async (data) => {
    setLoading(true);
    try {
      const response = await MiterAPI.slack.send_message(
        `User ${activeUser?.email || activeUser?._id} is switching to legacy fringe report for company ${
          activeCompany?.check_company.trade_name
        }. Reason: ${data.reason_for_switch || "[not given]"}`,
        SLACK_CHANNEL.FRINGE_REPORT_FEEDBACK
      );
      if (response.error) throw new Error(response.error);
    } catch (error) {
      console.error("Error sending Slack message:", error);
    }
    setShowLegacy("show");
    setIsSwitchingToLegacy(false);
    setLoading(false);
  };

  const reportObject = reportList.find((report) => report.slug === "fringe_report");

  return (
    <div className={payroll ? "payroll-report-container" : "page-content"}>
      <div className="page-content-header">
        {!payroll && (
          <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
            REPORTS
          </div>
        )}
        <h1 style={{ marginTop: 0 }}>Fringe contributions report</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <div style={{ marginBottom: 10 }}>
        <Banner type="info">
          {showLegacy !== "show" ? (
            <div>
              {`We've recently upgraded and simplified this Fringe Report. If you'd like to temporarily use the legacy
              version, `}
              <span className="blue-link" onClick={() => setIsSwitchingToLegacy(true)}>
                click here
              </span>
              .
            </div>
          ) : (
            <div>
              {`To switch back to the new Fringe Report, `}
              <span className="blue-link" onClick={() => setShowLegacy("hide")}>
                click here
              </span>
              .
            </div>
          )}
        </Banner>
      </div>
      {isSwitchingToLegacy && (
        <ActionModal
          headerText="Switch to legacy fringe report"
          showSubmit={true}
          submitText="Switch"
          onSubmit={form.handleSubmit(handleSwitchToLegacy)}
          onHide={() => setIsSwitchingToLegacy(false)}
          loading={loading}
        >
          <Formblock
            label={`Soon we will remove the legacy report altogether. Why doesn't the new report meet your needs?`}
            type="paragraph"
            form={form}
            name="reason_for_switch"
            editing={true}
            style={{ marginTop: 20 }}
            className="modal"
          />
        </ActionModal>
      )}
      {showLegacy === "show" ? <LegacyFringeReport /> : <NewFringeReport />}
    </div>
  );
};
