import { FC, useCallback, useEffect, useState } from "react";
import { MergeCandidatesWizardProps } from "./MergeCandidatesWizard";
import { Loader, Notifier, WizardScreen } from "ui";
import useWizard from "ui/modal/useWizard";
import styles from "./MergeCandidates.module.css";
import { AggregatedCandidate } from "dashboard/types/ats";
import { MiterAPI } from "dashboard/miter";
import { CandidateOptionCard } from "./CandidateOptionCard";

type Props = Pick<MergeCandidatesWizardProps, "primaryCandidate" | "secondaryCandidates"> & {
  name: string;
};

export const MergeCandidatesWizardScreen: FC<Props> = ({ primaryCandidate, secondaryCandidates }) => {
  const { setCanNext, setNextButtonText, handleComplete } = useWizard();
  const [selectedCandidateId, setSelectedCandidateId] = useState<string>(primaryCandidate._id);

  const [aggregatedCandidates, setAggregatedCandidates] = useState<AggregatedCandidate[]>([]);
  const [loading, setLoading] = useState(false);
  const getAggregatedCandidates = useCallback(async () => {
    try {
      setLoading(true);

      const aggregatedCandidates = await MiterAPI.candidates.retrieve_many([
        {
          field: "_id",
          value: [primaryCandidate._id, ...secondaryCandidates.map((candidate) => candidate._id)],
          comparisonType: "in",
        },
      ]);

      const sortedAggregatedCandidates = aggregatedCandidates.sort((a, b) => {
        if (a._id === primaryCandidate._id) {
          return -1;
        }
        if (b._id === primaryCandidate._id) {
          return 1;
        }
        return a.created_at < b.created_at ? -1 : 1;
      });

      setAggregatedCandidates(sortedAggregatedCandidates);
    } catch (error: $TSFixMe) {
      Notifier.error("Error fetching candidates", error.message);
    } finally {
      setLoading(false);
    }
  }, [primaryCandidate._id, secondaryCandidates]);

  useEffect(() => {
    getAggregatedCandidates();
  }, [getAggregatedCandidates]);

  useEffect(() => {
    setCanNext(true);
  }, [setCanNext]);

  useEffect(() => {
    setNextButtonText("Merge");
  }, [setNextButtonText]);

  const onNext = async () => {
    try {
      const allCandidateIds = [
        primaryCandidate._id,
        ...secondaryCandidates.map((candidate) => candidate._id),
      ];
      const secondaryCandidateIds = allCandidateIds.filter((id) => id !== selectedCandidateId);
      await MiterAPI.candidates.merge({
        primaryCandidateId: selectedCandidateId,
        secondaryCandidateIds,
      });
      Notifier.success("Candidates successfully merged!");
      handleComplete();
    } catch (error: $TSFixMe) {
      Notifier.error("Error merging candidates", error.message);
    }
  };

  return (
    <WizardScreen onNext={onNext} name="Merge candidates">
      {loading ? (
        <Loader />
      ) : (
        <div className={styles["content"]}>
          <div className={styles["header"]}>
            <h2 className={styles["subheader-title"]}>Merge candidates</h2>
            <p className={styles["subheader-description"]}>
              All job applications and offer letters from the selected candidates will be merged into the
              primary candidate. The selected candidates will be deleted after the merge.
            </p>
          </div>
          <div className={styles["options-wrapper"]}>
            <p className={styles["options-title"]}>Select a primary candidate</p>
            {aggregatedCandidates.map((candidate) => (
              <CandidateOptionCard
                key={candidate._id}
                candidate={candidate}
                selected={candidate._id === selectedCandidateId}
                onClick={() => setSelectedCandidateId(candidate._id)}
              />
            ))}
          </div>
        </div>
      )}
    </WizardScreen>
  );
};
