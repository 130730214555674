import React, { useState } from "react";
import {
  FaArrowCircleRight,
  FaCheckSquare,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaPhoneSquareAlt,
  FaSquare,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import HighlightBox from "../shared/HighlightBox";
import styles from "./OnboardingCard.module.css";
import Banner from "../shared/Banner";
import { Loader } from "ui";
import { usePayrollReadiness } from "dashboard/hooks/atom-hooks";
import { useCheckCompanyOnboard } from "dashboard/hooks/useCheckCompanyOnboard";

export const OnboardingCard: React.FC = () => {
  const checkCompanyOnboard = useCheckCompanyOnboard();
  const payrollReadiness = usePayrollReadiness();

  const navigate = useNavigate();

  const [transferDropdownOpened, setTransferDropdownOpened] = useState(false);

  const checkOnboardCompleted = !payrollReadiness?.remaining_onboard_tasks.includes("check_onboard");
  const paymentMethodAdded = !payrollReadiness?.remaining_onboard_tasks.includes("payment_method");
  const payrollDataTransferred = !payrollReadiness?.remaining_onboard_tasks.includes("data_transfer");

  const renderPayrollDataBody = () => {
    return (
      <>
        <p className={styles["payroll-transfer-body"]}>
          As the final step in the onboarding process, Miter will finalize any remaining data uploads and
          double-check your implementation for accuracy. We&apos;ll be in touch as soon as you&apos;re ready
          to process your first payroll!
        </p>
        <p className={styles["payroll-transfer-number"]}>
          <FaPhoneSquareAlt style={{ marginBottom: -3 }} /> (323) 576-4166
        </p>
      </>
    );
  };

  return (
    <div className="page-content">
      <div className="page-content-header flex">
        <h1>Onboarding</h1>
      </div>
      <div style={{ marginBottom: 25, maxWidth: 800 }}>
        <div className="vertical-spacer"></div>
        <Banner content={"Please follow the steps below to complete your onboarding."} type="warning" />
        <div className="vertical-spacer"></div>
        <HighlightBox className="onboarding-card">
          <OnboardingCardRow
            type={"link"}
            title={"Set up company information"}
            completed={checkOnboardCompleted}
            loading={checkCompanyOnboard.loading}
            onClick={checkCompanyOnboard.open}
          ></OnboardingCardRow>
          <OnboardingCardRow
            type={"link"}
            title={"Add a payment method"}
            completed={paymentMethodAdded}
            onClick={() => navigate("/settings/billing")}
          ></OnboardingCardRow>
          <OnboardingCardRow
            type={"dropdown"}
            title={"Sit back while Miter takes it from here"}
            expanded={transferDropdownOpened}
            body={renderPayrollDataBody()}
            completed={payrollDataTransferred}
            onClick={() => setTransferDropdownOpened(!transferDropdownOpened)}
          ></OnboardingCardRow>
        </HighlightBox>
        <div className="vertical-spacer"></div>
        {/*renderSetupDetail()*/}
      </div>
    </div>
  );
};

type RowProps = {
  title: string;
  type: string;
  expanded?: boolean;
  body?: React.ReactNode;
  completed: boolean;
  loading?: boolean;
  onClick?: () => void;
};

const OnboardingCardRow: React.FC<RowProps> = ({
  title,
  type,
  expanded,
  body,
  loading,
  completed,
  onClick,
}) => {
  /***********************************************
   *  Component rendering helper functions
   ***********************************************/

  const renderStatus = () => {
    if (completed) {
      return <FaCheckSquare className={styles["completed-icon"]} />;
    } else {
      return <FaSquare style={{ color: "#E0E0E0" }} />;
    }
  };

  const renderIcon = () => {
    if (type === "link") {
      return <FaArrowCircleRight className={styles["link"]} />;
    } else if (type === "dropdown") {
      if (expanded) {
        return <FaChevronCircleUp className={styles["dropdown-toggle"]}></FaChevronCircleUp>;
      } else {
        return <FaChevronCircleDown className={styles["dropdown-toggle"]}></FaChevronCircleDown>;
      }
    }
  };

  const renderStyle = () => {
    let style = styles["onboarding-card-row"] + " ";
    if (completed) style += styles["onboarding-card-row-completed"];

    return style;
  };

  return (
    <div className={renderStyle()} onClick={completed ? () => {} : onClick}>
      <div className={styles["onboarding-card-status-container"]}>{renderStatus()}</div>
      <div className={styles["onboarding-card-content-container"]}>
        <p className={styles["onboarding-card-content-title"]}>{title}</p>
        {body && expanded && <div className={styles["onboarding-card-content-body"]}>{body}</div>}
      </div>
      <div className="flex-1"></div>
      {loading ? (
        <Loader className="small-text" />
      ) : (
        <div className={styles["onboarding-card-icon-container"]}>{renderIcon()}</div>
      )}
    </div>
  );
};
