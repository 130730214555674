import { TimeOffPolicy } from "dashboard/miter";
import { DateTime } from "luxon";

export type PolicyLevelComputedStartDate = {
  levelId: string;
  computedStartUnix: number;
  computedStartDate: string;
};

export const levelComputedStartDates = (
  timeOffPolicy?: TimeOffPolicy
): PolicyLevelComputedStartDate[] | null | undefined => {
  const today = DateTime.now();

  return timeOffPolicy?.levels
    .map((level) => {
      const computedStartDate = today.minus({
        years: level.auto_enrollment?.tenure_threshold?.years || 0,
        months: level.auto_enrollment?.tenure_threshold?.months || 0,
        days: level.auto_enrollment?.tenure_threshold?.days || 0,
      });

      return {
        levelId: level._id,
        computedStartUnix: computedStartDate.toSeconds(),
        computedStartDate: computedStartDate.toISODate(),
      };
    })
    .sort((a, b) => b.computedStartUnix - a.computedStartUnix);
};

export const assignDefaultLevel = (
  startDate?: string | null,
  levelComputedStartDates?: PolicyLevelComputedStartDate[] | null
): string | undefined => {
  let levelId;

  if (levelComputedStartDates && startDate) {
    for (let i = 0; i < (levelComputedStartDates?.length || 0); i++) {
      const currLevel = levelComputedStartDates[i];
      if (currLevel!.computedStartDate < startDate) {
        break;
      }
      levelId = currLevel!.levelId;
    }
  }

  return levelId;
};
