import FormWizard from "dashboard/components/forms/FormWizard";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export const NewForm: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="page-content">
      <Helmet>
        <title>New Form | Miter</title>
      </Helmet>
      <FormWizard onExit={() => navigate("/forms")} onComplete={() => navigate("/forms")} mode={"create"} />
    </div>
  );
};
