import React, { useState } from "react";
import PayrollContext from "../payrollContext";
import { SetNewPayday } from "../SetNewPayday";
import { DateTime } from "luxon";
import { BasicModal } from "ui";

const PastDueAlert: React.FC<{ hide: () => void }> = ({ hide }) => {
  const [isSetting, setIsSetting] = useState(false);

  const { payroll } = React.useContext(PayrollContext);
  if (!payroll) return null;

  const deadline_string = payroll.check_payroll.approval_deadline
    ? DateTime.fromISO(payroll.check_payroll.approval_deadline).toFormat("MMM d, yyyy 'at' ha ZZZZ")
    : "N/A";

  if (isSetting) {
    return <SetNewPayday hide={() => setIsSetting(false)} />;
  }

  return (
    <BasicModal
      headerText="This payroll is past due."
      button1Action={hide}
      button1Text="Dismiss"
      button2Action={() => setIsSetting(true)}
      button2Text="Set new payday"
    >
      <div className="red-text-container">
        <span>The approval deadline for this payroll was</span>&nbsp;
        <span className="bold">{deadline_string}.</span>
        <br></br>
        <br></br>
        <span>
          In order to configure and run this payroll, please set a new payday and approval deadline. If you do
          not update the payday, you will not be able to approve this payroll.
        </span>
      </div>
    </BasicModal>
  );
};

export default PastDueAlert;
