import React, { useEffect, useState, useCallback, useMemo } from "react";
import { BasicModal, Formblock } from "ui";
import Notifier from "dashboard/utils/notifier";
import { MiterAPI } from "dashboard/miter";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { reportList } from "../reportList";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { Option } from "ui/form/Input";
import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import {
  useActiveCompanyId,
  useActiveCompany,
  useJobPostingOptions,
  useLookupRejectionReason,
} from "dashboard/hooks/atom-hooks";
import { AggregatedJobApplication } from "dashboard/types/ats";
import { ColumnConfig, TableV2 } from "ui/table-v2/Table";
import { APPLICATION_STATUS_VALUE_LABEL } from "dashboard/utils/ats";
import { useJobApplicationAbilities } from "dashboard/hooks/recruiting/useJobApplicationAbilities";
type SearchParams = {
  startDate: DateTime;
  endDate: DateTime;
  jobPostingId?: Option<string>;
};

export const OFCCPReport: React.FC = () => {
  /* Atom and ability hooks */
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const jobPostingOptions = useJobPostingOptions();
  const lookupRejectionReason = useLookupRejectionReason();
  const jobApplicationAbilities = useJobApplicationAbilities();

  /* Navigation */
  const navigate = useNavigate();

  /* Form */
  const form = useForm<SearchParams>({
    defaultValues: {
      startDate: DateTime.now().minus({ years: 1 }),
      endDate: DateTime.now(),
    },
  });

  /* State */
  const [data, setData] = useState<AggregatedJobApplication[] | undefined>();
  const [fetchingData, setFetchingData] = useState(false);
  const { startDate, endDate, jobPostingId } = form.watch();
  const hasAccessToEEOColumns = jobApplicationAbilities.can("read_sensitive", undefined);

  /* Constants */
  const reportObject = reportList.find((report) => report.slug === "ofccp");
  const companyName = activeCompany?.check_company.trade_name;
  const ofccpReportingEnabled = activeCompany?.settings.ats?.collect_eeo;

  /* Columns */

  const formatIsRejected = useCallback((data?: AggregatedJobApplication): string => {
    return data?.status === "rejected" ? "Yes" : "No";
  }, []);

  const formatDispositionDate = useCallback((data?: AggregatedJobApplication): string => {
    if (!data) return "-";
    if (data?.status !== "rejected") return "-";
    if (!data?.rejection_reason_history?.length) return "-";
    const timestamp =
      data?.rejection_reason_history?.[data.rejection_reason_history.length - 1]?.timestamp || "";
    if (!timestamp) return "-";
    return DateTime.fromSeconds(timestamp).toFormat("LLLL d, yyyy");
  }, []);

  const formatDispositionReason = useCallback(
    (data?: AggregatedJobApplication): string => {
      if (!data) return "-";
      if (data?.status !== "rejected") return "-";
      if (!data?.rejection_reason_history?.length) return "-";
      return (
        lookupRejectionReason(
          data.rejection_reason_history[data.rejection_reason_history.length - 1]?.rejection_reason_id
        )?.name || "-"
      );
    },
    [lookupRejectionReason]
  );

  const columns: ColumnConfig<AggregatedJobApplication>[] = useMemo(() => {
    return [
      {
        headerName: "Candidate name",
        field: "candidate.first_name",
        valueFormatter: (params: ValueFormatterParams<AggregatedJobApplication>): string => {
          const { first_name, last_name } = params.data?.candidate || {
            first_name: "Unknown",
            last_name: "Candidate",
          };
          return `${first_name} ${last_name}`;
        },
        dataType: "string",
      },
      {
        headerName: "Candidate email",
        field: "candidate.email",
        dataType: "string",
      },
      {
        headerName: "Job posting title",
        field: "job_posting.title",
        filter: "agSetColumnFilter",
        filterParams: {
          values: jobPostingOptions.map((option) => option.label),
        },
        dataType: "string",
      },
      {
        headerName: "Job address",
        field: "job_posting.address",
        valueGetter: (params: ValueGetterParams<AggregatedJobApplication>): string => {
          if (params.data?.job_posting?.workplace?.work_type === "remote") return "Remote";
          return `${params.data?.job_posting?.workplace?.address?.city}, ${params.data?.job_posting?.workplace?.address?.state}`;
        },
        dataType: "string",
      },
      {
        headerName: "Application date",
        field: "applied_on",
        dataType: "date",
        dateType: "timestamp",
        valueFormatter: (params: ValueFormatterParams<AggregatedJobApplication>): string => {
          if (!params.value) return "-";
          return DateTime.fromSeconds(params.value).toFormat("LLLL d, yyyy");
        },
      },
      {
        headerName: "Gender",
        field: "demographic_info.gender",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Race/Ethnicity",
        field: "demographic_info.ethnicity",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Disability status",
        field: "demographic_info.disability_status",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Veteran status",
        field: "demographic_info.veteran_status",
        dataType: "string",
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Application status",
        field: "status",
        valueGetter: (params: ValueGetterParams<AggregatedJobApplication>): string => {
          return APPLICATION_STATUS_VALUE_LABEL[params.data?.status || ""];
        },
        dataType: "string",
        filter: "agSetColumnFilter",
        filterParams: {
          values: Object.values(APPLICATION_STATUS_VALUE_LABEL),
        },
        enableRowGroup: true,
      },
      {
        headerName: "Rejected",
        field: "rejected",
        valueGetter: (params: ValueGetterParams<AggregatedJobApplication>): string => {
          return formatIsRejected(params.data);
        },
        valueFormatter: (params: ValueFormatterParams<AggregatedJobApplication>): string => {
          return formatIsRejected(params.data);
        },
        dataType: "string",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Rejection reason",
        field: "rejection_reason",
        valueGetter: (params: ValueGetterParams<AggregatedJobApplication>): string => {
          return formatDispositionReason(params.data);
        },
        dataType: "string",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Date of rejection reason",
        field: "rejected_reason_date",
        dataType: "date",
        dateType: "timestamp",
        valueGetter: (params: ValueGetterParams<AggregatedJobApplication>): string => {
          return formatDispositionDate(params.data);
        },
        valueFormatter: (params: ValueFormatterParams<AggregatedJobApplication>): string => {
          return formatDispositionDate(params.data);
        },
      },
    ];
  }, [jobPostingOptions, formatIsRejected, formatDispositionReason, formatDispositionDate]);

  const fetchData = useCallback(async () => {
    try {
      setFetchingData(true);
      const jobApplications: AggregatedJobApplication[] = [];
      let moreResults = true;
      let nextPage: string | undefined;
      while (moreResults) {
        const response = await MiterAPI.job_applications.forage({
          filter: [
            { field: "company_id", value: activeCompanyId },
            { field: "applied_on", value: startDate.toSeconds(), comparisonType: ">=", type: "number" },
            { field: "applied_on", value: endDate.toSeconds(), comparisonType: "<=", type: "number" },
            ...(jobPostingId ? [{ field: "job_posting_id", value: jobPostingId?.value }] : []),
          ],
          sort: [
            {
              field: "applied_on",
              direction: -1,
            },
          ],
          limit: 1000,
          starting_after: nextPage,
        });
        jobApplications.push(...response.data);
        if (!response.next_page) {
          moreResults = false;
        } else {
          nextPage = response.next_page;
        }
      }
      setData(jobApplications);
      setFetchingData(false);
    } catch (error: $TSFixMe) {
      Notifier.error("Error fetching OFCCP report data: " + error.message);
    }
    setFetchingData(false);
  }, [activeCompanyId, startDate, endDate, jobPostingId]);

  useEffect(() => {
    fetchData();
  }, [endDate, fetchData, jobPostingId, startDate]);

  return (
    <div className="page-content">
      <Helmet>
        <title>OFCCP Report | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>OFCCP report</h1>
      </div>
      <div className="report-page-description">{reportObject!.description}</div>
      <div className="vertical-spacer-small"></div>
      <div style={{ maxWidth: 600 }}>
        <div>
          <Formblock
            label="Applied on or after"
            form={form}
            type="datetime"
            dateOnly={true}
            name="startDate"
            max={endDate}
            min={DateTime.now().minus({ years: 5 })}
            editing={true}
            defaultValue={DateTime.now().minus({ years: 1 })}
            isClearable={false}
            disableClearable={true}
          />
          <Formblock
            label="Applied on or before"
            form={form}
            type="datetime"
            dateOnly={true}
            name="endDate"
            max={DateTime.now()}
            min={startDate}
            editing={true}
            defaultValue={DateTime.now()}
            isClearable={false}
            disableClearable={true}
          />
          <Formblock
            label="Job posting"
            type="select"
            name="jobPostingId"
            form={form}
            options={jobPostingOptions}
            editing={true}
            isClearable={true}
          />
        </div>
      </div>
      {!ofccpReportingEnabled && (
        <BasicModal
          yellowBodyText={true}
          headerText="OFCCP Reporting not yet enabled"
          bodyText={`${companyName} has not yet enabled OFCCP Reporting. You can enable OFCCP Reporting within the Recruiting Settings.`}
          button2Action={() => navigate("/recruiting-settings")}
          button2Text="Continue"
          button1Action={() => navigate("/reports")}
          button1Text="Cancel"
        />
      )}
      {!hasAccessToEEOColumns && (
        <BasicModal
          yellowBodyText={true}
          headerText="Missing permission"
          bodyText={`You do not have permission to view sensitive demographic information used for OFCCP reporting purposes. If this is a mistake, please ask your administrator to update your permissions.`}
          button2Action={() => navigate("/reports")}
          button2Text="Continue"
        />
      )}
      <TableV2
        data={data}
        columns={columns}
        id="ofccp-report"
        resource={"job applications"}
        isLoading={fetchingData || !hasAccessToEEOColumns}
      />
    </div>
  );
};
