import {
  AggregatedChangeRequest,
  AggregatedExpense,
  AggregatedExpenseReimbursement,
  MiterAPI,
} from "dashboard/miter";
import { ArrowUDownLeft } from "phosphor-react";
import React, { useMemo, useState } from "react";
import { ActionModal, Button, Formblock, Notifier } from "ui";
import { TakeActionOnItemsResponse, PolicyType } from "backend/services/approvals/types";
import { TableActionLink } from "ui/table-v2/Table";
import { TimesheetTableEntry } from "dashboard/utils/timesheetUtils";
import { ExpenseReimbursementEntry } from "dashboard/pages/expenses/ExpenseReimbursementsTable";
import { usePolicies } from "dashboard/hooks/atom-hooks";
import { RequestTableEntry } from "dashboard/pages/time-off/TimeOffRequestsTable";

type KickBackItem =
  | TimesheetTableEntry
  | ExpenseReimbursementEntry
  | AggregatedExpense
  | AggregatedExpenseReimbursement
  | RequestTableEntry
  | AggregatedChangeRequest;

type Props = {
  items: KickBackItem[];
  itemType: PolicyType;
  onKickBack: (res: TakeActionOnItemsResponse) => void;
};

export const KickBackItemsAction: React.FC<Props> = ({ items, itemType, onKickBack }) => {
  const [notes, setNotes] = useState("");
  const [saving, setSaving] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);

  const kickBackItems = async () => {
    setSaving(true);

    try {
      const params = {
        item_ids: items.map((item) => item._id),
        item_type: itemType,
        notes: notes,
      };
      const res = await MiterAPI.approvable_items.kick_back(params);
      if (res.error) throw res.error;

      Notifier.success("Items successfully kicked back");
      onKickBack(res);
      setOpenNotesModal(false);
    } catch (e: $TSFixMe) {
      console.error(e.message);
      Notifier.error("There was an error kicking back items");
    }

    setSaving(false);
  };

  const renderNotesModal = () => {
    return (
      <ActionModal
        headerText="Add kick back reason"
        onHide={() => setOpenNotesModal(false)}
        onSubmit={kickBackItems}
        showSubmit={true}
        showCancel={true}
        cancelText="Cancel"
        onCancel={() => setOpenNotesModal(false)}
        loading={saving}
      >
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Formblock
            type="paragraph"
            name="phone"
            label="Kick back reason"
            sublabel="Enter the reason for kicking back these items"
            onChange={(e) => setNotes(e.target.value)}
            editing={true}
            className="modal"
            placeholder="Enter reason..."
          />
        </div>
      </ActionModal>
    );
  };

  return (
    <>
      <Button
        className={"table-v2-secondary-action-bar-btn button-1"}
        onClick={() => setOpenNotesModal(true)}
      >
        <ArrowUDownLeft style={{ marginBottom: -2, marginRight: 5 }} />
        Kick back
      </Button>
      {openNotesModal && renderNotesModal()}
    </>
  );
};

export const useKickBackItemsAction = (
  items: KickBackItem[],
  itemType: PolicyType,
  onKickBack: (res: TakeActionOnItemsResponse) => void
): TableActionLink[] => {
  const allPolicies = usePolicies();

  const policies = useMemo(
    () => allPolicies.filter((policy) => policy.type === itemType),
    [allPolicies, itemType]
  );

  return useMemo(() => {
    if (!policies.length) return [];

    return [
      {
        key: "kick-back",
        important: true,
        component: <KickBackItemsAction items={items} itemType={itemType} onKickBack={onKickBack} />,
      },
    ];
  }, [items, itemType, onKickBack, policies]);
};
