import React from "react";
import { useForm } from "react-hook-form";
import { ActionModal, Formblock } from "ui";
import { useFormTemplateOptions } from "dashboard/hooks/atom-hooks";
import { Option } from "ui/form/Input";
import styles from "./RecruitingModals.module.css";
import Banner from "dashboard/components/shared/Banner";
import { useNavigate } from "react-router-dom";

type SelectFormTemplateModalProps = {
  onSubmit: (formId: string) => void;
  onClose: () => void;
  formTemplateId?: string;
};

type FormValues = {
  formTemplateId: Option<string>;
};

export const SelectFormTemplateModal: React.FC<SelectFormTemplateModalProps> = ({
  onSubmit,
  onClose,
  formTemplateId,
}) => {
  const formTemplateOptions = useFormTemplateOptions();
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      formTemplateId: formTemplateOptions.find((option) => option.value === formTemplateId),
    },
  });
  const navigate = useNavigate();

  const onFormSubmit = (values: FormValues) => {
    onSubmit(values.formTemplateId.value);
    onClose();
  };

  const navigateToFormTemplates = () => {
    navigate("/recruiting-settings");
  };

  return (
    <ActionModal
      headerText="Select template"
      showSubmit={true}
      submitText="Select template"
      onSubmit={handleSubmit(onFormSubmit)}
      onHide={onClose}
      showCancel={true}
      onCancel={onClose}
      wrapperClassName={styles["select-form-template-modal"]}
      bodyClassName={styles["select-form-template-modal-body"]}
    >
      {formTemplateOptions.length ? (
        <Banner className={styles["select-form-template-modal-banner"]} type="warning">
          Using this form template will replace the existing application questions for this job posting.
        </Banner>
      ) : (
        <Banner
          onClick={navigateToFormTemplates}
          className={styles["select-form-template-modal-banner"]}
          type="warning"
        >
          It looks like you haven&apos;t created any form templates yet. Click here to create a template from
          the Recruiting Settings.
        </Banner>
      )}
      <Formblock
        name="formTemplateId"
        control={control}
        type="select"
        label="Template"
        options={formTemplateOptions}
        editing={true}
        rules={{ required: "Please select an application form template" }}
        className={"modal"}
      />
    </ActionModal>
  );
};
