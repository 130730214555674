import {
  useActiveCompany,
  useActiveCompanyId,
  useBenefitsEligibilityGroups,
  useLookupBenefitsEligibilityGroups,
} from "dashboard/hooks/atom-hooks";
import React, { useEffect, useState, FC, useMemo, useCallback } from "react";
import { reportList } from "../reportList";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import { TableV2 } from "ui";
import { ColumnConfig } from "ui/table-v2/Table";
import { BenefitsEligibilityPreviewEntry } from "backend/utils/reports/benefitsEligibilityPreview";
import { isActiveClaspCompany } from "dashboard/utils/clasp-utils";

const reportObject = reportList.find((report) => report.slug === "benefits_eligibility_preview");

const BenefitsEligibilityPreview: FC = () => {
  // Hooks
  const navigate = useNavigate();
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();

  const isActiveOnClasp = useMemo(
    () => activeCompany && isActiveClaspCompany(activeCompany),
    [activeCompany]
  );
  const benefitsEligibilityGroups = useBenefitsEligibilityGroups();
  const lookupBenefitsEligibilityGroups = useLookupBenefitsEligibilityGroups();

  // State
  const [data, setData] = useState<BenefitsEligibilityPreviewEntry[]>([]);
  const [loading, setLoading] = useState(false);

  const useBenefitsEligibilityPreviewTableColDefs = (): ColumnConfig<BenefitsEligibilityPreviewEntry>[] => {
    return useMemo(() => {
      return [
        {
          field: "friendly_id",
          headerName: "Employee ID",
        },
        {
          field: "first_name",
          headerName: "First name",
        },
        {
          field: "middle_name",
          headerName: "Middle name",
          initialHide: true,
        },
        {
          field: "last_name",
          headerName: "Last name",
        },
        {
          field: "start_date",
          headerName: "Start date",
        },
        {
          field: "end_date",
          headerName: "End date",
          initialHide: true,
        },
        {
          field: "days_since_hire",
          headerName: "Days since hire",
        },
        {
          field: "approved_hours_worked",
          headerName: "Approved hours worked",
          initialHide: true,
        },
        {
          field: "total_hours_worked",
          headerName: "Total hours worked",
        },
        {
          field: "indicated_average_hours_per_week",
          headerName: "Average hours per week (indicated)",
          initialHide: true,
        },
        {
          field: "calculated_average_hours_per_week",
          headerName: "Average hours per week (calculated)",
        },
        {
          field: "indicated_status",
          headerName: "Status (indicated)",
          initialHide: true,
          dataType: "string",
          displayType: "badge",
          colors: {
            full_time: "green",
            part_time: "yellow",
          },
        },
        {
          field: "calculated_status",
          headerName: "Status (calculated)",
          dataType: "string",
          displayType: "badge",
          colors: {
            full_time: "green",
            part_time: "yellow",
          },
        },
        ...(benefitsEligibilityGroups.length > 0
          ? [
              {
                field: "benefits_eligibility_groups",
                headerName: "Benefits eligibility groups",
                valueFormatter: (params) => {
                  const benefitsEligibilityGroups = params?.data?.benefits_eligibility_groups;
                  if (!benefitsEligibilityGroups) return "-";
                  return benefitsEligibilityGroups
                    .map(
                      (group) =>
                        lookupBenefitsEligibilityGroups(group)?.integrations?.clasp?.clasp_subclass?.name
                    )
                    .join(", ");
                },
              },
            ]
          : []),
      ];
    }, []);
  };

  const columns = useBenefitsEligibilityPreviewTableColDefs();

  const getData = useCallback(async () => {
    if (!activeCompanyId || !activeCompany) return;
    setLoading(true);
    try {
      const payload = {
        type: "benefits_eligibility_preview",
        params: {},
        format: "json",
      };
      const response = await MiterAPI.reports.create(payload);
      if (response.error) throw new Error(response.error);
      setData(response);
    } catch (e) {
      console.error(e);
      Notifier.error("There was an error retrieving data. We're looking into it!");
    }
    setLoading(false);
  }, [activeCompanyId, activeCompany]);

  useEffect(() => {
    getData();
  }, [activeCompanyId, getData]);

  if (!isActiveOnClasp) {
    return null;
  }

  return (
    <div className="page-content">
      <Helmet>
        <title>Benefits Eligibility Preview | Miter</title>
      </Helmet>
      <div className="page-content-header">
        <div onClick={() => navigate("/reports")} className="reports-header-badge pointer">
          REPORTS
        </div>
        <h1 style={{ marginTop: 0 }}>{reportObject!.label}</h1>
      </div>
      <div className="report-page-description">{reportObject?.description}</div>
      <TableV2
        id="benefits-eligibility-preview-table"
        resource="eligibility"
        columns={columns}
        isLoading={loading}
        data={data}
        showReportViews={true}
      />
    </div>
  );
};

export default BenefitsEligibilityPreview;
