import React from "react";
import { ClickableText } from "ui";

const articleLink =
  "https://intercom.help/withfriends/en/articles/5975290-how-to-disable-pop-up-blockers-in-every-browser";

export const TurnOffAdAndPopUpBlockersLink: React.FC = () => {
  const onClick = () => {
    window.open(articleLink, "_blank");
  };

  return (
    <div>
      <span>If you are experiencing issues completing authentication, try</span>
      <ClickableText text=" turning off advertisement and popup blockers." onClick={onClick} />
    </div>
  );
};
