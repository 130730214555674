import { AggregatedPayroll, TeamMember } from "dashboard/miter";
import React from "react";
import { EnhancedMiterPayment } from "../../payrollTypes";

type PaymentContextType = {
  editing: boolean;
  toggle: (e: string) => void;
  payment: EnhancedMiterPayment;
  tm: TeamMember;
  payroll?: AggregatedPayroll;
  isLoading?: boolean;
};

// @ts-expect-error initialization
const PaymentContext = React.createContext<PaymentContextType>({
  editing: false,
  toggle: () => {},
});

export default PaymentContext;
