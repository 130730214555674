import { AggregatedCompany } from "dashboard/miter";
import React, { useState } from "react";
import { ModalHeader, Formblock, Button } from "ui";
import { useForm } from "react-hook-form";
import { ClickAwayListener } from "@material-ui/core";
import { MiterAPI } from "dashboard/miter";
import Notifier from "dashboard/utils/notifier";
import AppContext from "dashboard/contexts/app-context";

type Props = {
  company: AggregatedCompany;
  hide: () => void;
  getCompany: () => void;
};

export const UpdateCaInfoModal: React.FC<Props> = ({ company, hide, getCompany }) => {
  const { register, errors, handleSubmit } = useForm();
  const { fetchUserData } = React.useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const updateCompany = async (data) => {
    setLoading(true);
    try {
      const payload = {
        ca_info: {
          license_number: data.license_number === "" ? null : data.license_number,
          pwc_registration_number: data.pwc_registration_number === "" ? null : data.pwc_registration_number,
        },
      };
      const response = await MiterAPI.companies.update(company._id, payload);
      if (response.error) throw new Error(response.error);
      Notifier.success("Company successfully updated.");
      await fetchUserData();
      await getCompany();
      hide();
    } catch (e) {
      Notifier.error("There was an error updating the company. We're looking into it!");
    }
    setLoading(false);
  };

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => {}}>
        <div className="modal-wrapper form">
          <ModalHeader heading={"Update CA information"} onHide={hide} />
          <div className="modal-body form">
            <div className="vertical-spacer"></div>
            <Formblock
              label="State license number (CLSB)"
              type="text"
              className="modal"
              defaultValue={company.ca_info?.license_number}
              name="license_number"
              editing={true}
              register={register}
              errors={errors}
            />
            <Formblock
              label="PWC registration number"
              type="text"
              className="modal"
              defaultValue={company.ca_info?.pwc_registration_number}
              name="pwc_registration_number"
              editing={true}
              register={register}
              errors={errors}
            />
          </div>
          <div className="modal-footer form">
            <button className={loading ? "button-1 inactive " : "button-1"} onClick={hide}>
              Cancel
            </button>
            <Button
              loading={loading}
              text="Save"
              onClick={handleSubmit(updateCompany)}
              className="button-2"
            />
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};
