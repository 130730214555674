import { DateTimePicker, Formblock } from "ui";
import { TimesheetSettings } from "./TimesheetSettings";
import InfoButton from "dashboard/components/information/information";
import { DateTime } from "luxon";

interface TimesheetNotificationSettingsProps {
  timesheetSettings: TimesheetSettings;
  handleChange: (newFields: Partial<TimesheetSettings>) => void;
}

const TimesheetNotificationSettings: React.FC<TimesheetNotificationSettingsProps> = ({
  timesheetSettings,
  handleChange,
}) => {
  return (
    <div className="billing-card-wrapper">
      <div className="flex">
        <div style={{ fontWeight: 600, fontSize: 18 }}>Notifications</div>
        <InfoButton text="Manage notifications related to timesheets" />
      </div>

      <div className="vertical-spacer-small"></div>
      <div className="flex">
        <input
          style={{ marginLeft: 10 }}
          type="checkbox"
          checked={timesheetSettings.send_daily_unapproved_timesheet_notifications_to_supervisors}
          onChange={(e) => {
            handleChange({
              send_daily_unapproved_timesheet_notifications_to_supervisors: e.target.checked,
            });
          }}
        />
        <span style={{ marginLeft: 15 }}>
          Send unapproved timesheet reminders via SMS to job supervisors every weekday at:
        </span>
        <div style={{ width: 250, marginLeft: 5 }}>
          <DateTimePicker
            onChange={(date) => {
              const formattedDate = date.set({ minute: 0, second: 0, millisecond: 0 });
              handleChange({
                supervisor_notification_hour: formattedDate.hour,
              });
            }}
            timeOnly={true}
            timePlaceholder={"Send at"}
            timeIntervals={60}
            value={DateTime.now().set({
              hour: timesheetSettings.supervisor_notification_hour || 17,
              minute: 0,
              second: 0,
              millisecond: 0,
            })}
          />
        </div>
      </div>
      {timesheetSettings.kiosk?.enable_connected_kiosks && (
        <div>
          <div className="vertical-spacer-small"></div>
          <span>{` The following settings only apply to timesheets created via connected kiosk.`}</span>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <Formblock
              type="checkbox"
              name="send_clock_out_reminders"
              text="Send SMS reminders to clock out if team members have been clocked in for longer than"
              defaultValue={timesheetSettings.send_clock_out_reminders}
              onChange={(e) => {
                handleChange({
                  send_clock_out_reminders: e.target.checked,
                });
              }}
              checked={timesheetSettings.send_clock_out_reminders}
              editing={true}
              style={{ marginLeft: "9px" }}
            />
            <Formblock
              type="number"
              name="clock_out_reminder_time"
              defaultValue={timesheetSettings.clock_out_reminder_time}
              onChange={(e) => handleChange({ clock_out_reminder_time: Number(e.target.value) })}
              editing={true}
              style={{ width: 50, marginLeft: 10, marginRight: 10 }}
            />
            <span style={{ marginBottom: "7px", color: "#2E2E2E" }}>{` hours.`}</span>
          </div>
          <div className="flex">
            <Formblock
              type="checkbox"
              name="send_clock_out_reminders"
              text="Send SMS reminders to end break if team members have been on break for longer than"
              defaultValue={timesheetSettings.send_end_break_reminders}
              onChange={(e) => {
                handleChange({
                  send_end_break_reminders: e.target.checked,
                });
              }}
              checked={timesheetSettings.send_end_break_reminders}
              editing={true}
              style={{ marginLeft: "9px" }}
            />
            <Formblock
              type="select"
              name="clock_out_reminder_time"
              options={[
                { label: "5 minutes", value: 5 },
                { label: "10 minutes", value: 10 },
                { label: "15 minutes", value: 15 },
                { label: "20 minutes", value: 20 },
                { label: "30 minutes", value: 30 },
                { label: "45 minutes", value: 45 },
                { label: "1 hour", value: 60 },
              ]}
              defaultValue={timesheetSettings.end_break_reminder_time || null}
              onChange={(e) => handleChange({ end_break_reminder_time: e.value })}
              editing={true}
              style={{ width: 150, marginLeft: 10, marginRight: 10 }}
              requiredSelect={false}
            />
            <span style={{ marginBottom: "7px", color: "#2E2E2E" }}>{`.`}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimesheetNotificationSettings;
