import React from "react";
import { MiterIntegrationKey } from "backend/models/integrations/integrations";
import { miterGuidesIntegrationPaths } from "../constants";
import { ClickableText } from "ui";
import { goToMiterGuide } from "dashboard/utils/miterGuides";

export const MiterGuidesIntegrationLink: React.FC<{
  integrationKey: MiterIntegrationKey;
  integrationLabel: string;
}> = ({ integrationKey, integrationLabel }) => {
  const miterGuidesLink: string | undefined = miterGuidesIntegrationPaths[integrationKey];

  const onClick = () => {
    if (!miterGuidesLink) return;
    goToMiterGuide(miterGuidesLink);
  };

  // Payroll Integrations label is "401(k) integrations", so transform in order to make the sentence cleaner.
  const connectionText =
    integrationKey === "payroll_integrations" ? "401(k) integration" : `${integrationLabel} integration`;

  return (
    <>
      <span> Check out Miter Guides for details on </span>
      <ClickableText text={`how to set up your ${connectionText}.`} onClick={onClick} />
    </>
  );
};
