import TagsTable from "dashboard/components/tags/TagsTable";
import AppContext from "dashboard/contexts/app-context";
import { MiterAPI } from "dashboard/miter";
import { Notifier } from "dashboard/utils";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { CompanySettings } from "backend/models/company";
import { useActiveCompany } from "dashboard/hooks/atom-hooks";

export const DocumentsSettings: React.FC = () => {
  const navigate = useNavigate();
  const activeCompany = useActiveCompany();

  const { fetchUserData } = useContext(AppContext);
  const [settings, setSettings] = useState(activeCompany!.settings.documents);

  const updateCompany = async () => {
    try {
      if (!activeCompany?._id) throw new Error("No active company");
      const response = await MiterAPI.companies.update(activeCompany._id, {
        $set: { "settings.documents": settings },
      });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");
      fetchUserData();
    } catch (e) {
      Notifier.error("There was an error updating timesheet settings. Our engineers are looking into it!");
    }
  };

  const updateCompanyDebounced = useDebouncedCallback(() => {
    updateCompany();
  }, 1000);

  const handleSettingsChange = (newFields: Partial<CompanySettings["documents"]>) => {
    setSettings((prev) => {
      return { ...prev, ...newFields };
    });
    updateCompanyDebounced();
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Documents settings | Miter</title>
      </Helmet>

      <div className="page-content">
        <div className="blue-link" onClick={() => navigate("/documents")}>
          Back to documents
        </div>
        <div className="flex">
          <h1>Documents settings</h1>
          <div className="flex-1"></div>
        </div>
        <div>Configure documents settings.</div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18 }}>Team members</div>
          <div className="vertical-spacer-small"></div>
          <div className="flex">
            <input
              type="checkbox"
              name="documents.enable_team_member_upload"
              checked={settings?.enable_team_member_upload}
              onChange={(e) => handleSettingsChange({ enable_team_member_upload: e.target.checked })}
            />
            <span style={{ marginLeft: 6 }}>{`Let team member's upload documents from the team portal`}</span>
          </div>
        </div>
        <div className="billing-card-wrapper">
          <div style={{ fontWeight: 600, fontSize: 18, display: "flex" }}>Manage tags</div>
          <TagsTable parentType="file" />
        </div>
      </div>
    </div>
  );
};
