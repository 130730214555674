import { MiterAPI } from "dashboard/miter";
import { get } from "lodash";
import { FieldName, FieldValues, UseFormMethods, useForm, useWatch, SetFieldValue } from "react-hook-form";
import { Notifier } from "ui";
import { useDebouncedCallback } from "use-debounce";
import { useActiveCompanyId, useActiveCompany, useSetActiveCompany } from "./atom-hooks";

type UseSettingsForm<T extends FieldValues> = {
  form: UseFormMethods<T>;
  handleUpdate: (data: T) => void;
  handleFieldUpdate: (data, field: string) => void;
  updateFields: (data) => void;
};

export const useSettingsForm = <T extends FieldValues>(path: string): UseSettingsForm<T> => {
  const activeCompanyId = useActiveCompanyId();
  const activeCompany = useActiveCompany();
  const setActiveCompany = useSetActiveCompany();
  const defaultValues = get(activeCompany, path);

  const form = useForm<T>({ defaultValues });
  useWatch<T>({ control: form.control });

  const updateSettings = async (data) => {
    try {
      const response = await MiterAPI.companies.update(activeCompanyId!, { [path]: data });
      if (response.error) throw new Error(response.error);

      setActiveCompany(response);
      Notifier.success("Settings updated successfully.");
    } catch (e) {
      Notifier.error("There was an error updating settings. We're looking into it!");
    }
  };

  const updateField = async (data, field: string) => {
    try {
      const fieldPath = (path + "." + field) as FieldName<T>;
      const response = await MiterAPI.companies.update(activeCompanyId!, { [fieldPath]: data });
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");

      form.setValue(fieldPath, data);
      setActiveCompany(response);
    } catch (e) {
      Notifier.error("There was an error updating settings. We're looking into it!");
    }
  };

  const updateFields = async (data) => {
    try {
      const cleanParams = Object.keys(data).reduce((updateParams, key) => {
        const fieldPath = path + "." + key;
        updateParams[fieldPath] = data[key];
        return updateParams;
      }, {});

      const response = await MiterAPI.companies.update(activeCompanyId!, cleanParams);
      if (response.error) throw new Error(response.error);
      Notifier.success("Settings updated successfully.");

      Object.entries(data).forEach(([key, value]) => {
        form.setValue(key as FieldName<T>, value as SetFieldValue<T>);
      });

      setActiveCompany(response);
    } catch (e) {
      Notifier.error("There was an error updating settings. We're looking into it!");
    }
  };

  const handleUpdate = useDebouncedCallback(() => form.handleSubmit(updateSettings)(), 500);
  const handleFieldUpdate = useDebouncedCallback(updateField, 300);

  return { form, handleUpdate, handleFieldUpdate, updateFields };
};
