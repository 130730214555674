import { useMemo } from "react";
import { MiterFilterArray } from "backend/utils";
import { useActiveCompany, useTeam } from "./atom-hooks";
import { AggregatedPayroll, AggregatedTeamMember, Company } from "dashboard/miter";
import { DateTime } from "luxon";

export const usePayrollTimesheetFilters = (
  payroll: AggregatedPayroll | undefined,
  statuses: string[]
): MiterFilterArray => {
  const activeCompany = useActiveCompany();
  const teamMembers = useTeam();

  const payrollTeamMembers = useMemo(() => {
    if (!payroll) return [];

    if (payroll.type === "off_cycle") {
      const adjustmentTmIds = payroll.adjustments.map((a) => a.team_member);
      return teamMembers.filter((tm) => adjustmentTmIds.includes(tm._id.toString()));
    }

    return teamMembers.filter(
      (tm) =>
        !tm.archived &&
        (!tm.start_date || tm.start_date <= payroll.check_payroll.period_end) &&
        (!tm.end_date ||
          (tm.ignore_in_last_payroll
            ? tm.end_date > payroll.check_payroll.period_end
            : tm.end_date >= payroll.check_payroll.period_start))
    );
  }, [teamMembers, payroll]);

  const filters = generatePayrollTimesheetFilters(payrollTeamMembers, statuses, activeCompany, payroll);

  return filters;
};

const generatePayrollTimesheetFilters = (
  payrollTeamMembers: AggregatedTeamMember[],
  statuses: string[],
  activeCompany: Company | null,
  payroll?: AggregatedPayroll
): MiterFilterArray => {
  if (!payroll || !activeCompany || !activeCompany._id) return [];

  // Get the payroll start and end times
  const zone = activeCompany.timezone;
  const start = DateTime.fromISO(payroll.check_payroll.period_start, { zone }).startOf("day");

  const end = DateTime.fromISO(payroll.check_payroll.period_end, { zone }).endOf("day");

  // Add base filters
  const activeTeamMemberIds = payrollTeamMembers.map((tm) => tm._id);

  return [
    {
      field: "company",
      value: activeCompany._id,
      type: "string",
    },
    {
      field: "status",
      value: statuses,
      comparisonType: "in",
      type: "string",
    },
    {
      field: "clock_in",
      value: [start.toSeconds(), end.toSeconds()],
      comparisonType: "<+>",
      type: "number",
    },
    // Make sure the timesheet's team member is active
    {
      field: "team_member",
      value: activeTeamMemberIds,
      comparisonType: "in",
      type: "string",
    },
    // If the payroll has a pay schedule id, make sure team member pay schedule id is the same
    ...(payroll.pay_schedule_id
      ? [
          {
            field: "team_member.pay_schedule_id",
            value: payroll.pay_schedule_id,
            type: "string" as const,
          },
        ]
      : []),
  ];
};
